import { KiteIcon } from '@kite/react-kite';
import { Link, useLocation } from 'react-router-dom';
import styles from 'components/nav-tabs/styles/navTabs.module.scss';
import { TrackingHook, useTracking } from 'react-tracking';
import React from 'react';
import { useAppSelector } from 'hooks';
import { appConstants } from 'appConstants';
import { useTarget } from 'components/common/focus/FocusContext';
import { usePageTranslation } from 'translation/hooks';

const NavTabs: React.FC = () => {
  const { t } = usePageTranslation('leftNavigationCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'navigation', component: 'NavTabs' });
  const { signatureError } = useAppSelector((state) => state.signatureSlice);
  const { autoReplyError } = useAppSelector((state) => state.autoReplySlice);
  const focus = useTarget();

  const path = useLocation().pathname;

  const selectedFill = '#0073d1';
  const nonSelectedFill = '#000000';
  const alertFill = '#D6312B';

  const handleClick = (uiName: string, routeName: string): void => {
    trackEvent({
      event: 'navigationTab',
      route: { routeName, uiName },
    });

    if (routeName === 'settings') {
      trackEvent({
        event: 'accountInfoPageViewInit',
      });
    }

    if (routeName === 'mail') {
      trackEvent({ event: 'pageViewInitInbox' });
      focus('composeBtn');
    }

    if (routeName === 'contact') {
      focus('createContactBtn');
    }
  };

  return (
    <div role="region" aria-label="primary webmail navigation" className={styles.navTabs}>
      <Link
        key={'mail'}
        data-id="e2e_main_nav_tab e2e_main_nav_tab_inbox"
        to={{ pathname: appConstants.INBOX_URL }}
        className={`${path.startsWith('/user/mail') && styles.navTabSelectedState} ${styles.navTab} `}
        onClick={() => handleClick(t('email'), 'mail')}
        aria-current={path.startsWith('/user/mail') ? 'page' : undefined}
        role={'link'}
      >
        <div
          className={`${styles.selectedTabIndicator} ${
            path.startsWith('/user/mail') ? styles.indicatorSelectedState : ''
          }`}
        />
        <div className={styles.navIconContainer}>
          <KiteIcon
            size="21px"
            className="navTabIcon"
            icon={'ki-mail'}
            fill={path.startsWith('/user/mail') ? selectedFill : nonSelectedFill}
          />
          <span className={styles.navTabName}>{t('email')}</span>
        </div>
      </Link>
      <Link
        key={'label'}
        data-id="e2e_main_nav_tab e2e_main_nav_tab_contacts"
        to={{ pathname: appConstants.CONTACT_URL }}
        className={`${path.startsWith('/user/label') ? styles.navTabSelectedState : ''} ${styles.navTab} `}
        onClick={() => handleClick(t('contacts'), 'contact')}
        aria-current={path.startsWith('/user/label') ? 'page' : undefined}
        role={'link'}
      >
        <div
          className={`${styles.selectedTabIndicator} ${
            path.startsWith('/user/label') && styles.indicatorSelectedState
          }`}
        />
        <div className={styles.navIconContainer}>
          <KiteIcon
            size="21px"
            className="navTabIcon"
            icon={'ki-person-multi'}
            fill={path.startsWith('/user/label') ? selectedFill : nonSelectedFill}
          />
          <span className={styles.navTabName}>{t('contacts')}</span>
        </div>
      </Link>
      <Link
        key={'setting'}
        data-id="e2e_main_nav_tab e2e_main_nav_tab_settings"
        to={{ pathname: appConstants.SETTING_URL }}
        className={`${path.startsWith('/user/setting') ? styles.navTabSelectedState : ''} ${styles.navTab} `}
        onClick={() => handleClick(t('settings'), 'settings')}
        aria-current={path.startsWith('/user/setting') ? 'page' : undefined}
        role={'link'}
      >
        <div
          className={`${styles.selectedTabIndicator} ${
            path.startsWith('/user/setting') ? styles.indicatorSelectedState : ''
          }`}
        />
        <div className={styles.navIconContainer}>
          <KiteIcon
            size="21px"
            className="navTabIcon"
            icon={'ki-settings'}
            fill={path.startsWith('/user/setting') ? selectedFill : nonSelectedFill}
          />
          {(signatureError || autoReplyError) && (
            <KiteIcon size="21px" className={styles.navSettingIcon} icon="ki-caution-circle-f" fill={alertFill} />
          )}
          <span className={styles.navTabName}>{t('settings')}</span>
        </div>
      </Link>
    </div>
  );
};

export default NavTabs;
