import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import client from 'libs/apollo-client';
import App from 'components/app';
import { store } from 'store';
import AuthProvider from 'providers/AuthProvider';
import AnalyticsProvider from 'providers/AnalyticsProvider';
import TranslationProvider from 'translation/TranslationProvider';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import 'allotment/dist/style.css';

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client()}>
      <Provider store={store}>
        <TranslationProvider>
          <AnalyticsProvider>
            <AuthProvider>
              <DndProvider backend={HTML5Backend}>
                <App />
              </DndProvider>
            </AuthProvider>
          </AnalyticsProvider>
        </TranslationProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
