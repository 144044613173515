import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import { useDeleteLabelMutation } from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import { deleteLabelThunk } from 'thunks/contact/deleteLabelThunk';
import { NavigateFunction } from 'react-router-dom';
import { updateCurrentLabelSelected } from 'slices/contact';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, useDialogTranslation } from 'translation/hooks';

interface DeleteLabelInterface {
  labelId: string;
  labelName?: string;
  currentLabel: string;
  navigate: NavigateFunction;
}

const DeleteLabel: React.FC<DeleteLabelInterface> = ({ labelId, currentLabel, navigate }) => {
  const { t } = useDialogTranslation('deleteLabelCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'labels' });
  const apiError = useAppSelector((state) => state.labelSlice.apiError);
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const focusOpener = useTarget('modal');
  const [deleteLabelHook] = useDeleteLabelMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    trackEvent({ event: 'deleteLabelView' });
  }, []);

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  const isSaved = async (): Promise<boolean> => {
    const result = await dispatch(deleteLabelThunk({ deleteLabelHook, payload: { id: labelId } })).unwrap();

    if (result.success) {
      trackEvent({ event: 'deleteLabelSuccess' });
      dispatch(updateToastMessage({ message: t('successToast'), success: true }));

      if (currentLabel === labelId) {
        navigate(`/user/label/all`);
        dispatch(updateCurrentLabelSelected(''));
      }
      return true;
    }
    trackEvent({ event: 'deleteLabelErrorApi' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'deleteLabelCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    trackEvent({ event: 'deleteLabelSave' });
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t('delete'),
    isDelete: true,
    cancelLabel: t('keepLabel'),
  };

  return (
    <ModalContentWrapper>
      <h5>{t('header')}</h5>
      <p>{t('description')}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default DeleteLabel;
