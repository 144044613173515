import { GlobalFoldersInterface, appConstants } from 'appConstants';
import { MailboxEntity, MailboxStatusEntity } from 'generated/graphql';

export const mapGlobalMailboxNames = (mailboxes: MailboxStatusEntity[]): MailboxStatusEntity[] =>
  mailboxes.map((mailbox) => ({
    ...mailbox,
    name: appConstants.GLOBAL_FOLDER_NAMES[mailbox.name as keyof GlobalFoldersInterface] ?? mailbox.name,
  }));

export const normalizeMailboxCollection = (
  mailboxCollection: MailboxEntity[],
  globalMailboxes: MailboxStatusEntity[]
): MailboxEntity[] =>
  mailboxCollection.map((mailbox) => {
    if (globalMailboxes.find((globalMailboxes) => globalMailboxes.id === mailbox.id)) {
      return {
        ...mailbox,
        name: mailbox.name,
      };
    }

    return mailbox;
  });
