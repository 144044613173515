import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import { useDeleteContactMutation } from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import { deleteContactThunk } from 'thunks/contact/deleteContactThunk';
import { NavigateFunction, useLocation } from 'react-router-dom';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, useDialogTranslation } from 'translation/hooks';

interface DeleteContactPropsInterface {
  contactId: string;
  labelId: string;
  navigate: NavigateFunction;
}

const DeleteContact: React.FC<DeleteContactPropsInterface> = ({ contactId, labelId, navigate }) => {
  const { t } = useDialogTranslation('deleteContactCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'contacts' });
  const apiError = useAppSelector((state) => state.contactSlice.apiError);
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const focusOpener = useTarget('modal');
  const [deleteContactHook] = useDeleteContactMutation();
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  useEffect(() => {
    trackEvent({ event: 'deleteModalView' });
  }, []);

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  const isSaved = async (): Promise<boolean> => {
    const result = await dispatch(
      deleteContactThunk({
        deleteContactHook,
        payload: { id: contactId },
      })
    ).unwrap();

    if (result.success) {
      trackEvent({ event: 'deleteContactSuccess' });
      dispatch(updateToastMessage({ message: t('successToastSingular'), success: true }));
      navigate(`/user/label/${labelId}${search}`);
      return true;
    }

    trackEvent({ event: 'deleteContactErrorApi' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'deleteContactCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    trackEvent({ event: 'deleteContactSave' });
    return contactId.length > 0;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t('delete'),
    isDelete: true,
    cancelLabel: t('cancelBtnTextSingular'),
  };

  return (
    <ModalContentWrapper>
      <h5>{t('headerSingular')}</h5>
      <p>{t('descriptionSingular')}</p>
      <p className={modalStyle.alertMessage}>{t('warning')}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default DeleteContact;
