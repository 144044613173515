import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import styles from 'components/setting-filter-form/styles/SettingFilterForm.module.scss';
import { FilterFormInterface, initialState } from 'components/setting-filter-form/initialState';
import { RuleName, Condition, FilterAction, Clause, Priority } from 'components/setting-filter-form/sections';
import { KiteAlert, KiteCard, KiteProgressIndicator } from '@kite/react-kite';
import { isEqual } from 'lodash';
import useFilterFieldValidatorError from 'hooks/useContactFieldValidatorError';
import BackButton from 'components/common/BackButton';
import { updateFilterThunk } from 'thunks/filter/updateFilterThunk';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useCreateFilterMutation, useCreateMailboxMutation, useDeleteMailboxMutation } from 'generated/graphql';
import { updateNavBlocking, updateToastMessage } from 'slices/app';
import { resetFilterApiError } from 'slices/filter';
import { useNavigate } from 'react-router';
import transformFilterForm, { transformExistingFilters } from 'utils/transformFilterForm';
import { isFilterFormValid } from 'components/setting-filter-form/helpers';
import { createMailboxThunk } from 'thunks/mailbox/createMailboxThunk';
import { TrackingHook, useTracking } from 'react-tracking';
import useNavBlockingDraft from 'hooks/useNavBlockingDraft';
import { PageNSTranslationFileKeys, usePageTranslation } from 'translation/hooks';
import { ApolloError } from '@apollo/client';
import { DeleteMailboxThunkResponse, deleteMailboxThunk } from 'thunks/mailbox/deleteMailboxThunk';

type NewFolderResult = {
  payload: {
    success: {
      id: string;
      name: string;
      delimiter: string;
    };
    error: ApolloError;
  };
};

interface CreateFilterProps {
  getFiltersLoading: boolean;
}

const CreateFilter: React.FC<CreateFilterProps> = ({ getFiltersLoading }) => {
  const { t } = usePageTranslation('filterRuleFormCopy');
  const [formValues, setFormValues] = useNavBlockingDraft(initialState);
  const [alertDescription, setAlertDescription] = useState<PageNSTranslationFileKeys<'filterRuleFormCopy'>>('');
  const apiErrors = useAppSelector((state) => state.mailboxCollectionSlice.apiError);
  const spamFolderId = useAppSelector((state) => state.mailboxCollectionSlice.spamFolderId);
  const [errors, setErrors] = useFilterFieldValidatorError({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { trackEvent }: TrackingHook = useTracking({
    page: 'settings',
  });

  const filters = useAppSelector((state) => state.filterCollectionSlice.filters) || [];
  const mailboxCollection = useAppSelector((state) => state.mailboxCollectionSlice.mailboxCollection);

  const [updateFiltersHook] = useCreateFilterMutation();
  const [createMailboxHook] = useCreateMailboxMutation();
  const [deleteMailboxHook] = useDeleteMailboxMutation();

  useEffect(() => {
    trackEvent({ event: 'filterRulesCreatePageView' });
  }, []);

  useEffect(() => {
    if (apiErrors) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiErrors]);

  const updateFormValues = (
    value: FilterFormInterface[keyof FilterFormInterface],
    field: keyof FilterFormInterface
  ): void => {
    setFormValues({ ...formValues, [field]: value });
  };

  const deleteFolder = async (folderId: string): Promise<DeleteMailboxThunkResponse> => {
    const deleteFolderResult = await dispatch(
      deleteMailboxThunk({
        deleteMailboxHook,
        payload: {
          deleteMessage: true,
          mailboxId: folderId,
          spamFolderId,
        },
      })
    ).unwrap();
    return deleteFolderResult;
  };

  const isSaved = async (): Promise<boolean> => {
    setAlertDescription('');
    let newFolderResult: NewFolderResult | undefined;
    if (formValues.moveFolder && formValues.moveFolder === 'createNew') {
      if (
        mailboxCollection?.find(
          (mailbox) => mailbox.name === formValues.newFolder || formValues.newFolder?.toLowerCase() === 'inbox'
        )
      ) {
        trackEvent({ event: 'filterRulesCreateError' });
        setAlertDescription('validationErr');
        setErrors({ ...errors, newFolder: 'duplicate' });
        return false;
      }
      newFolderResult = (await dispatch(
        createMailboxThunk({
          payload: {
            mailbox: { mailbox: formValues.newFolder! },
          },
          createMailboxHook,
        })
      )) as NewFolderResult;
    }

    if (!newFolderResult || newFolderResult.payload.success) {
      const createFilterResult = await dispatch(
        updateFilterThunk({
          payload: {
            filters: transformExistingFilters(filters).concat(
              transformFilterForm(formValues, newFolderResult?.payload.success.id)
            ),
          },
          updateFiltersHook,
        })
      ).unwrap();

      if (createFilterResult.error && newFolderResult?.payload.success) {
        const { name, id } = newFolderResult?.payload.success;

        const deleteFolderResult = await deleteFolder(id);

        if (deleteFolderResult.error) {
          updateFormValues(btoa(name), 'moveFolder');
        }

        trackEvent({ event: 'filterRulesCreateError' });
        setAlertDescription('unableToSaveErr');
        return false;
      }

      if (createFilterResult.success) {
        dispatch(updateNavBlocking(false));
        trackEvent({ event: 'filterRulesCreateSuccess' });
        dispatch(updateToastMessage({ message: t('createdToast'), success: true }));
        dispatch(resetFilterApiError());
        navigate('/user/setting/filter');
        return true;
      }
    }

    trackEvent({ event: 'filterRulesCreateError' });
    setAlertDescription('unableToSaveErr');
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'filterRulesNewCancel' });
    dispatch(updateNavBlocking(false));
    navigate('/user/setting/filter');
    return true;
  };

  const isValidate = (): boolean => {
    trackEvent({ event: 'filterRulesSaveNew' });
    const [isValid, filterFormErrors] = isFilterFormValid(formValues);
    if (isValid) {
      return true;
    }

    trackEvent({ event: 'filterRulesCreateValidationError' });
    setAlertDescription('validationErr');
    setErrors(filterFormErrors);
    return false;
  };

  const onBackClick = (): void => {
    trackEvent({ event: 'filterRulesBackButton' });
    navigate(location.pathname.split('/').slice(0, -1).join('/'));
  };

  return (
    <div className={styles.filterForm}>
      <BackButton onClick={onBackClick} />
      <KiteCard className={styles.newFilter}>
        <h2 className="kite-type-style--title-6">{t('newRuleTitle')}</h2>
        <p>{t('newRuleDescription')}</p>
        {alertDescription && (
          <KiteAlert
            className={styles.alert}
            type="error"
            description={t(alertDescription)}
            level="page"
            dismissible
            onClose={() => setAlertDescription('')}
          />
        )}
        <form className={styles.formFields}>
          <RuleName
            updateFormValues={updateFormValues}
            values={{ name: formValues.name }}
            errors={errors}
            setErrors={setErrors}
          />
          <Condition
            updateFormValues={updateFormValues}
            values={{ rules: formValues.rules }}
            errors={errors}
            isNew={true}
            setErrors={setErrors}
          />
          <Priority
            updateFormValues={updateFormValues}
            values={{ highPriority: formValues.highPriority, actionPriority: formValues.actionPriority }}
            errors={errors}
            setErrors={setErrors}
          />
          <Clause
            updateFormValues={updateFormValues}
            values={{ clause: formValues.clause }}
            errors={errors}
            setErrors={setErrors}
          />
          <FilterAction
            updateFormValues={updateFormValues}
            values={{
              filterAction: formValues.filterAction,
              moveFolder: formValues.moveFolder,
              newFolder: formValues.newFolder,
            }}
            errors={errors}
            setErrors={setErrors}
          />
        </form>
        <div className={styles.buttonBar}>
          <SaveCancel
            draft={formValues}
            isChanged={() => !isEqual(formValues, initialState)}
            isSaved={isSaved}
            isValidate={isValidate}
            isCancelled={isCancelled}
          />
        </div>
        {(getFiltersLoading || !mailboxCollection) && (
          <KiteProgressIndicator id={'loading'} useOverlay={true} size="md" />
        )}
      </KiteCard>
    </div>
  );
};

export default CreateFilter;
