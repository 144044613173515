const mergeSenderControl = (emails?: string[], domains?: string[]): string[] => {
  const mergedEmailsDomains: string[] = [];
  if (emails && emails.length > 0) {
    mergedEmailsDomains.push(...emails);
  }

  if (domains && domains.length > 0) {
    mergedEmailsDomains.push(...domains);
  }

  return mergedEmailsDomains;
};

export { mergeSenderControl };
