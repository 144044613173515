export const ErrorsCopy = {
  econnrefusedErr: '',
  econnresetErr: '',
  recordsErr: '',
  serverAdminErr: '',
  imapExceptionErr: '',
  smtpExceptionErr: '',
  unableToSendErr: '',
  attachmentErr: '',
  invalidEmailErr: '',
  createSignatureErr: '',
  createSignatureSizeErr: '',
  unableToSaveErr: '',
  unableExportContactErr: '',
  deleteMessageErr: '',
  deleteFolderErr: '',
  validationErr: '',
  invalidEmailDomainErr: '',
  fetchErr: '',
  serverErr: '',
  emptyTrashErr: '',
  signatureNotFoundErr: '',
  maxBlockedListCountError: '',
  maxApprovedListCountError: '',
  saveDraftFailureErr: '',
  loginErrorHeader: '',
  loginErrorDescription: '',
};
