import { KiteAlert, KiteCard } from '@kite/react-kite';
import React, { memo, useEffect, useMemo } from 'react';
import styles from 'components/setting-signature/styles/SettingSignature.module.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  createSignature,
  deleteSignature,
  updateAutoInsertSignature,
  updateSignature,
} from 'thunks/setting/signatureCollectionThunk';
import TextEditor from 'components/text-editor';
import { SignatureState, updateSignatureError } from 'slices/setting/signature';
import {
  useCreateSignatureMutation,
  useDeleteSignatureMutation,
  useUpdateAutoInsertSignatureMutation,
  useUpdateSignatureMutation,
} from 'generated/graphql';
import SettingSwitch from 'components/setting-switch';
import SaveCancel from 'components/save-cancel';
import { TrackingHook, useTracking } from 'react-tracking';
import { HelixSettingsPayloadInterface } from 'libs/tracking/adapters/helix/events/submitSettingsEvent';
import useNavBlockingDraft from 'hooks/useNavBlockingDraft';
import { updateNavBlocking } from 'slices/app';
import { ErrorsKeys, usePageTranslation } from 'translation/hooks';
import { getSignatureMaxSize } from 'app-constants';

const SettingSignature: React.FC = () => {
  const { t } = usePageTranslation('signatureCopy');
  const { trackEvent }: TrackingHook<HelixSettingsPayloadInterface> = useTracking({ page: 'settings' });

  const { signatureError, autoInsertLoading, autoInsertError, signatureEntity } = useAppSelector(
    (state) => state.signatureSlice
  );
  const existingSignatureEntity = signatureEntity ? signatureEntity.signature : '';
  const [draft, setDraft] = useNavBlockingDraft(existingSignatureEntity);

  const dispatch = useAppDispatch();
  const [updateAutoInsertSignatureHook] = useUpdateAutoInsertSignatureMutation();
  const [createSignatureHook] = useCreateSignatureMutation();
  const [updateSignatureHook] = useUpdateSignatureMutation();
  const [deleteSignatureHook] = useDeleteSignatureMutation();

  useMemo(() => {
    trackEvent({ event: 'signaturePageViewInit' });
  }, []);

  useEffect(() => {
    trackEvent({ event: 'signaturePageView' });
  }, []);

  useEffect(() => {
    if (signatureEntity && signatureEntity.signature) {
      setDraft(signatureEntity.signature);
    }
  }, [signatureEntity]);

  useEffect(() => {
    if (signatureError) {
      trackEvent({ event: 'signatureError' });
    }
  }, [signatureError]);

  const handleChange = (signatureHtml: string): void => {
    setDraft(signatureHtml);
  };

  const handleSwitch = (): void => {
    if (signatureEntity) {
      trackEvent({ event: 'signatureToggle', isToggled: !signatureEntity.autoInsert });
      dispatch(
        updateAutoInsertSignature({
          payload: {
            id: signatureEntity.id,
            value: !signatureEntity.autoInsert,
          },
          updateAutoInsertSignatureHook,
        })
      );
    }
  };

  const handleCreate = async (): Promise<boolean> => {
    const result = await dispatch(
      createSignature({
        payload: {
          signature: draft,
          autoInsert: false,
        },
        createSignatureHook,
      })
    );

    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'signatureSaveSuccess' });
      dispatch(updateNavBlocking(false));
      return true;
    }

    trackEvent({ event: 'signatureError' });
    return false;
  };

  const handleDelete = async (id: string): Promise<boolean> => {
    const result = await dispatch(
      deleteSignature({
        payload: { id },
        deleteSignatureHook,
      })
    );

    // @ts-ignore
    if (result.payload.success) {
      dispatch(updateNavBlocking(false));
    }
    // @ts-ignore
    return !!result.payload.success;
  };

  const handleUpdate = async (signatureEntity: NonNullable<SignatureState['signatureEntity']>): Promise<boolean> => {
    const result = await dispatch(
      updateSignature({
        payload: {
          id: signatureEntity.id,
          signature: {
            signature: draft,
            autoInsert: signatureEntity.autoInsert,
          },
        },
        updateSignatureHook,
      })
    );

    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'signatureSaveSuccess' });
      dispatch(updateNavBlocking(false));
      return true;
    }

    trackEvent({ event: 'signatureError' });
    return false;
  };

  const isChanged = (): boolean => {
    if (!signatureEntity?.signature && draft === '') {
      return false;
    }

    if (!signatureEntity) {
      return true;
    }

    return signatureEntity.signature !== draft;
  };

  const isSaved = async (): Promise<boolean> => {
    trackEvent({ event: 'signatureSave' });

    if (!signatureEntity || !signatureEntity.id) {
      return handleCreate();
    }

    if (!draft && signatureEntity.id) {
      return handleDelete(signatureEntity.id);
    }

    return handleUpdate(signatureEntity);
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'signatureCancel' });
    setDraft(signatureEntity ? signatureEntity.signature : '');
    return true;
  };

  const isValidate = (): boolean => {
    const draftBlob = new Blob([draft]);
    if (draftBlob.size > getSignatureMaxSize()) {
      dispatch(updateSignatureError('createSignatureSizeErr'));
      return false;
    }

    return true;
  };

  return (
    <div className={styles.signatureStatusTray}>
      <SettingSwitch
        disabled={!signatureEntity?.id || autoInsertLoading}
        aria-disabled={!signatureEntity?.id || autoInsertLoading}
        loading={autoInsertLoading}
        description={t(signatureEntity?.autoInsert ? 'descriptionOn' : 'descriptionOff')}
        title={t('title')}
        ariaLabel={'signature'}
        selected={signatureEntity?.autoInsert || false}
        handleSwitch={handleSwitch}
        alert={autoInsertError}
      />
      <KiteCard className={`${styles.editorCard} ${signatureError && styles.heighten}`}>
        {signatureError && (
          <KiteAlert
            className={styles.signatureAlert}
            type="error"
            level="page"
            description={t(signatureError as ErrorsKeys)}
            dismissible={true}
            onClose={() => dispatch(updateSignatureError(''))}
          />
        )}
        <p className={styles.signatureText}>{t('label')}</p>
        <div className={styles.textEditorGroup}>
          <TextEditor
            editorId="signatureMessage"
            page="settings"
            initialValue={signatureEntity?.signature || ''}
            handleChange={handleChange}
            value={draft}
          />
        </div>
        <SaveCancel
          draft={draft}
          isChanged={isChanged}
          isSaved={isSaved}
          isValidate={isValidate}
          isCancelled={isCancelled}
          shouldDisableCancel={true}
        />
      </KiteCard>
    </div>
  );
};

export default memo(SettingSignature);
