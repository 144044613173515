import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateApprovedSenderMutationFn } from 'generated/graphql';

export interface UpdateApprovedSenderInterface {
  payload: {
    approvedSender: {
      added: {
        emails: string[];
        domains: string[];
      };
      removed: {
        emails: string[];
        domains: string[];
      };
    };
  };
  updateApprovedSenderHook: UpdateApprovedSenderMutationFn;
}

export interface UpdateApprovedSenderResponse {
  success?: {
    added: {
      emails: string[];
      domains: string[];
    };
    removed: {
      emails: string[];
      domains: string[];
    };
  };
  error?: ApolloError;
}

export const updateApprovedSenderThunk = createAsyncThunk(
  'updateApprovedSenderThunk',
  async (arg: UpdateApprovedSenderInterface): Promise<UpdateApprovedSenderResponse> => {
    try {
      await arg.updateApprovedSenderHook({
        variables: arg.payload,
      });

      return {
        success: arg.payload.approvedSender,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
