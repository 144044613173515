import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import { useMoveMessagesToSpamMutation } from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import { moveMessagesThunk } from 'thunks/message-collection/moveMessagesThunk';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { isInboxFolder } from 'utils/folderNameValidator';
import getUnseenMessagesCount from 'utils/getUnseenMessagesCount';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, useDialogTranslation } from 'translation/hooks';
import useNavToPreviousPage from 'components/common/hooks/useNavToPreviousPage';
import useCloseMessage from 'components/common/hooks/useCloseMessage';
import { reloadMessageCollection } from 'slices/message-collection';

interface MarkAsSpamProps {
  messageUids: number[];
  sourceMailboxId: string;
  currentMailId?: string;
}

const MarkAsSpam: React.FC<MarkAsSpamProps> = ({ messageUids, sourceMailboxId, currentMailId }) => {
  const { t } = useDialogTranslation('markAsSpamCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const focusOpener = useTarget('modal');
  const apiError = useAppSelector((state) => state.messageCollectionSlice.apiError);
  const spamFolderId = useAppSelector((state) => state.mailboxCollectionSlice.spamFolderId);
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const messageCollection = useAppSelector((state) => state.messageCollectionSlice.messageCollection);
  const dispatch = useAppDispatch();
  const [moveMessagesToSpamHook] = useMoveMessagesToSpamMutation();
  const [queryParams, currentPage, navToPreviousPage] = useNavToPreviousPage();
  const closeMessage = useCloseMessage();

  useEffect(() => {
    trackEvent({ event: 'markAsSpamModalView' });
  }, []);

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  const isSaved = async (): Promise<boolean> => {
    let unseenMessagesCount;
    if (isInboxFolder(sourceMailboxId)) {
      unseenMessagesCount = getUnseenMessagesCount(messageCollection[sourceMailboxId]?.data, messageUids);
    }

    const result = await dispatch(
      moveMessagesThunk({
        moveMessagesHook: moveMessagesToSpamHook,
        payload: { sourceMailboxId, destinationMailboxId: spamFolderId, messageUids, unseenMessagesCount },
      })
    ).unwrap();

    if (result.success) {
      trackEvent({ event: 'markAsSpamSuccess' });

      const { limit, total, data } = messageCollection[sourceMailboxId];
      const lastPage = currentPage * limit > total;

      if (result.messageUids.length === data?.length && Number(currentPage) > 1 && lastPage) {
        navToPreviousPage();
      }

      if (messageUids.includes(parseInt(currentMailId || ''))) {
        closeMessage(sourceMailboxId, queryParams);
      }

      dispatch(updateToastMessage({ message: t('successToast'), success: true }));
      dispatch(updateIsOpen(false));
      dispatch(reloadMessageCollection(true));

      return true;
    }
    trackEvent({ event: 'markAsSpamFailure' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'markAsSpamCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    trackEvent({ event: 'markAsSpamConfirm' });
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t(alertDescription ? 'tryAgain' : 'confirmBtnText'),
  };

  return (
    <ModalContentWrapper>
      <h5>{t('header')}</h5>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <p>{t('description')}</p>
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default MarkAsSpam;
