import { KiteAlert, KiteCard, KiteIcon, KiteProgressIndicator } from '@kite/react-kite';
import styles from 'components/compose-form/styles/ComposeForm.module.scss';
import ComposeTextArea from 'components/compose-text-area';
import { useAppDispatch, useAppSelector } from 'hooks';
import ComposeActionsToolbar from 'components/compose-actions-toolbar';
import ToField from 'components/compose-input-field/ToField';
import CcField from 'components/compose-input-field/CcField';
import BccField from 'components/compose-input-field/BccField';
import ComposeTabHeader from 'components/compose-tab-header';
import { useCallback, useState } from 'react';
import SubjectField from 'components/compose-input-field/SubjectField';
import { ComposeFormInput, removeComposeFormError } from 'slices/compose';
import { Editor } from '@tinymce/tinymce-react';
import { useDeleteStorageMutation, useSaveDraftMutation } from 'generated/graphql';
import { deleteAttachmentThunk } from 'thunks/compose/deleteAttachmentThunk';
import fileSizeUnitHelper from 'utils/fileSizeUnitHelper';
import { TrackingHook, useTracking } from 'react-tracking';
import { debounce, DebouncedFunc } from 'lodash';
import { composeMailFormDraftValidator, composeMailFormHelper, isValidEmail } from 'utils/composeMailFormHelper';
import { saveDraftThunk } from 'thunks/compose/saveDraftThunk';
import { EmailAddress } from 'models/Email';
import { appConstants } from 'appConstants';
import { isDraftFolder } from 'utils/folderNameValidator';
import { useParams } from 'react-router-dom';
import { Scrollbar } from 'components/common/scrollbar';
import { updateTotalCountForMailbox } from 'slices/mailbox-collection';
import { usePageTranslation } from 'translation/hooks';

export interface AutoSaveDraftHandler {
  autoSaveDraftHandler: DebouncedFunc<
    (fieldName: string, field: string | EmailAddress[] | undefined, activeComposeWindow: ComposeFormInput) => void
  >;
}

const ComposeForm: React.FC = (): JSX.Element | null => {
  const { t } = usePageTranslation('composeCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'compose' });
  const { folder } = useParams<{ folder: string }>();
  const activeTabIndex = useAppSelector((state) => state.composeSlice.activeTabIndex);
  const activeMinimizedIndex = useAppSelector((state) => state.composeSlice.activeMinimizedIndex);
  const displayName = useAppSelector((state) => state.settingSlice.displayName);
  const activeTab = useAppSelector((state) => state.composeSlice.composeCollection[activeTabIndex] || undefined);
  const isOpenDraftLoading = useAppSelector((state) => state.composeSlice.isOpenDraftLoading);
  const composeCollection = useAppSelector((state) => state.composeSlice.composeCollection);
  const replyToAddress = useAppSelector((state) => state.settingSlice.replyTo);
  const isAutoSaveEnabled = useAppSelector((state) => state.settingSlice.isAutoSaveDraftEnabled);
  const isModalOpen = useAppSelector((state) => state.appSlice.isOpen);
  const isMaximized = useAppSelector((state) => state.composeSlice.isMaximized);
  const [showSignatureError, setShowSignatureError] = useState(false);
  const [editorRef, setEditorRef] = useState<Editor | null>(null);
  const dispatch = useAppDispatch();
  const [deleteAttachmentHook] = useDeleteStorageMutation();
  const [saveDraftHook] = useSaveDraftMutation();
  const isReadReceiptEnabled = activeTab?.isReadReceiptEnabled;
  const attachments = activeTab?.attachments;
  const isCcFieldEnabled = composeCollection[activeTabIndex]?.isCcFieldEnabled;
  const isBccFieldEnabled = composeCollection[activeTabIndex]?.isBccFieldEnabled;
  const isSendPending = composeCollection[activeTabIndex]?.isSendPending;
  const isSavePending = composeCollection[activeTabIndex]?.isSavePending;

  const editorCallback = (ref: Editor): void => {
    setEditorRef(ref);
  };

  const handleComposeFormErrorClose = (index: string): void => {
    dispatch(removeComposeFormError(index));
  };

  const deleteAttachment = (id: string): void => {
    trackEvent({ event: 'deleteAttachment' });
    dispatch(deleteAttachmentThunk({ payload: { id, index: activeTabIndex }, deleteAttachmentHook }));
  };

  const autoSaveDraftHandler = useCallback(
    debounce(async (fieldName, field, activeComposeWindow): Promise<void> => {
      if (!isAutoSaveEnabled) {
        return;
      }
      if (!activeComposeWindow.isSavedToDraft && !activeComposeWindow.pauseAutoSave && activeComposeWindow.isChanged) {
        if (composeMailFormDraftValidator(activeComposeWindow) && isValidEmail(replyToAddress)) {
          const result = await dispatch(
            saveDraftThunk({
              saveDraftHook,
              payload: {
                displayName: displayName || '',
                index: activeTabIndex,
                mail: composeMailFormHelper({ ...activeComposeWindow, [fieldName]: field }, replyToAddress),
                isSelectedFolderDraft: folder ? isDraftFolder(folder) : false,
                isAutoSave: true,
              },
            })
          );
          // @ts-ignore
          if (result?.payload?.success && !activeComposeWindow.isOpenedFromDraft) {
            dispatch(updateTotalCountForMailbox({ mailboxId: appConstants.DRAFT_FOLDER_ID, updateCount: 1 }));
          }
        }

        console.log('Saved Draft: Second:%s Item: %s', new Date().getSeconds(), activeTabIndex);
      }
    }, appConstants.AUTOSAVE_DRAFT_BUFFER),
    [activeTabIndex]
  );

  if (isSendPending || isModalOpen || isSavePending) {
    autoSaveDraftHandler.cancel();
  }

  if (activeMinimizedIndex) {
    return null;
  }

  const handleComposeFormError = (): JSX.Element[] | null => {
    const errors = activeTab?.composeFormErrors;
    if (!errors || Object.keys(errors).length === 0) {
      return null;
    }

    return Object.keys(errors).map((index) => {
      return (
        <KiteAlert
          key={index}
          className={styles.error}
          type={activeTab && activeTab?.composeFormErrors && activeTab?.composeFormErrors[index].type}
          level="page"
          description={
            t(activeTab && activeTab?.composeFormErrors && activeTab?.composeFormErrors[index].message) ||
            'Unknown error'
          }
          dismissible={errors[index].message !== 'replyToInvalidAddress'}
          onClose={() => handleComposeFormErrorClose(index)}
        />
      );
    });
  };

  const handleAttachment = (): JSX.Element[] | null => {
    if (!attachments || attachments.length === 0) {
      return null;
    }

    return attachments?.map((attachment) => {
      return (
        <span key={attachment.id} className={styles.attachmentTag}>
          <a href="#" className={styles.name} tabIndex={-1}>
            <KiteIcon className={styles.attachmentIcon} icon="ki-attachment" size="15px" />
            <span className={styles.span}>{attachment.fileName}</span>
          </a>
          <span className={styles.size}>{fileSizeUnitHelper(attachment.fileSize)}</span>
          <a
            href="#"
            className={styles.deleteAttachment}
            onClick={() => deleteAttachment(attachment.id)}
            role="button"
            aria-label={`delete attachment, ${attachment.fileName}`}
          >
            <KiteIcon className={styles.icon} icon="ki-x-circle" size="14px" />
          </a>
        </span>
      );
    });
  };

  return (
    <div className={`${styles.composeContainer} ${isMaximized ? styles.maximized : ''}`}>
      <ComposeTabHeader index={activeTabIndex} />
      <KiteCard className={`${styles.composeFormContainer}`}>
        <Scrollbar customClass={styles.composeFormScrollable} forceScroll={true}>
          <ToField autoSaveDraftHandler={autoSaveDraftHandler} />
          {isCcFieldEnabled && <CcField autoSaveDraftHandler={autoSaveDraftHandler} />}
          {isBccFieldEnabled && <BccField autoSaveDraftHandler={autoSaveDraftHandler} />}
          <SubjectField autoSaveDraftHandler={autoSaveDraftHandler} />
          <ComposeTextArea editorCallback={editorCallback} autoSaveDraftHandler={autoSaveDraftHandler} />
          {attachments && attachments.length > 0 && (
            <div className={styles.attachmentsContainer}>{handleAttachment()}</div>
          )}
        </Scrollbar>
        {isReadReceiptEnabled && (
          <>
            <p className={`${styles.draftNotification} ${isReadReceiptEnabled ? '' : styles.draftNotificationHide}`}>
              {t('readReceipt')}
            </p>
            <span className="srOnly" aria-live="polite">
              Read receipt enabled
            </span>
          </>
        )}
        <ComposeActionsToolbar
          editorRef={editorRef}
          setShowSignatureError={setShowSignatureError}
          showSignatureError={showSignatureError}
        />
        <div className={styles.draftNotificationCard}>
          {activeTab?.composeFormErrors && Object.keys(activeTab?.composeFormErrors).length > 0 && (
            <div className={styles.alertContainer}>{handleComposeFormError()}</div>
          )}
          {replyToAddress && !isValidEmail(replyToAddress) && (
            <div className={styles.alertContainer}>
              <KiteAlert
                key={'replyTo'}
                className={styles.error}
                type={'error'}
                level="page"
                description={t('replyToInvalidAddress')}
                dismissible={false}
              />
            </div>
          )}
        </div>
        {isOpenDraftLoading && <KiteProgressIndicator id="composeLoader" useOverlay={true} />}
      </KiteCard>
    </div>
  );
};

export default ComposeForm;
