import { KiteGridCell } from '@kite/react-kite';
import SettingAccountInfo from 'components/setting-account-info';
import SettingApproveSender from 'components/setting-approve-sender';
import SettingAutoReply from 'components/setting-auto-reply';
import SettingBlockSender from 'components/setting-block-sender';
import SettingEmailFormat from 'components/setting-email-format';
import SettingReplyTo from 'components/setting-reply-to';
import SettingSignature from 'components/setting-signature';
import SettingSpamManagement from 'components/setting-spam-management';
import SettingTimeZone from 'components/setting-time-zone';
import { Route, Routes } from 'react-router-dom';
import styles from 'containers/styles/SettingLayoutContainer.module.scss';
import BackButton from 'components/common/BackButton';
import SettingForwarding from 'components/setting-forwarding';
import SettingFilter from 'components/setting-filter';
import Footer from 'components/footer';

const SettingViewCell: React.FC<{ showBackButton?: boolean; showFooter?: boolean }> = ({ showBackButton }) => (
  <KiteGridCell ariaLabel="viewing pane" className={styles.settingContentCell} col={10} xs={12} sm={9} md={12} lg={12}>
    <div className={styles.settingContent}>
      {showBackButton && <BackButton path={'/user/setting'} />}
      <Routes>
        <Route path={'/account'} element={<SettingAccountInfo />} />
        <Route path={'/signature'} element={<SettingSignature />} />
        <Route path={'/auto-reply'} element={<SettingAutoReply />} />
        <Route path={'/time-zone'} element={<SettingTimeZone />} />
        <Route path={'/email-format'} element={<SettingEmailFormat />} />
        <Route path={'/block-senders'} element={<SettingBlockSender />} />
        <Route path={'/safe-senders'} element={<SettingApproveSender />} />
        <Route path={'/spam'} element={<SettingSpamManagement />} />
        <Route path={'/filter/*'} element={<SettingFilter />} />
        <Route path={'/reply-to'} element={<SettingReplyTo />} />
        <Route path={'/forwarding'} element={<SettingForwarding />} />
      </Routes>
    </div>
    <div className={styles.footerContainer}>
      <Footer />
    </div>
  </KiteGridCell>
);

export default SettingViewCell;
