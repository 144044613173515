import { KiteButton, KiteCard, KiteIcon, KiteProgressIndicator } from '@kite/react-kite';
import styles from 'components/email-view/styles/EmailView.module.scss';
import { useGetMessageLazyQuery } from 'generated/graphql';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState, useLayoutEffect } from 'react';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { getMessageThunk } from 'thunks/message/messageThunk';
import { mailViewDateFormatter, mailCreatedFormatter } from 'utils/dateFormatter';
import { isDraftFolder, isSentMailFolder } from 'utils/folderNameValidator';
import {
  addDraftToTabCollection,
  addForwardToTabCollection,
  addReplyAllToTabCollection,
  addReplyToTabCollection,
  ComposeFormInput,
  updateIsOpenDraftLoading,
  updateMinimizedIndex,
} from 'slices/compose';
import { TrackingHook, useTracking } from 'react-tracking';
import { isMessageFlagged } from 'utils/messageFlagsUtils';
import AuthenticatedDownload from 'components/common/AuthenticatedDownload';
import ServerError from 'components/common/ServerError';
import { updateIsOpen, updateModalName, updateModalProps } from 'slices/app';
import useAbortExistingRequest from 'hooks/useAbortExistingRequest';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import { getLoggedInEmail } from 'utils/accessTokenLocalStorage';
import AddressDisplay from 'components/email-view/AddressDisplay';
import { useTarget } from 'components/common/focus/FocusContext';
import { Scrollbar } from 'components/common/scrollbar';
import useURLQueryBuilder from 'components/common/hooks/useURLQueryBuilder';
import { usePageTranslation } from 'translation/hooks';
import removeParam from 'utils/removeParam';
import EmailViewPlaceholder from 'components/email-view/EmailViewPlaceHolder';
import { reloadMessageCollection } from 'slices/message-collection';
import isTabInCollection from 'utils/isInTabCollection';
import { appConstants } from 'appConstants';

interface MessageBodyArgs {
  html: boolean;
  noImageHtml: boolean;
}

interface EmailViewInterface {
  alwaysRender?: boolean;
}

const EmailView: React.FC<EmailViewInterface> = ({ alwaysRender }) => {
  const { t } = usePageTranslation('emailViewCopy');
  const { pathname, search: searchQuery } = useLocation();
  const mailId = pathname.split('/').pop();
  const refocus = useTarget(mailId!);

  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const { folder } = useParams<{ folder: string }>();
  const [_searchQueryParam, setSearchQueryParam] = useURLQueryBuilder();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isReadingPaneExpanded = !!query.get('expand');
  const [emailNotFound, setEmailNotFound] = useState(false);

  const isDisplayEmailAsText = useAppSelector((state) => state.settingSlice.isDisplayEmailAsHtmlEnabled);
  const isDisplayImageEnabled = useAppSelector((state) => state.settingSlice.isDisplayImageEnabled);

  const { message, messageLoading, showReadReceiptModal, reloadMessage } = useAppSelector(
    (state) => state.messageSlice
  );
  const timeZone = useAppSelector((state) => state.settingSlice.timeZone);
  const selectedMessages = useAppSelector((state) => state.messageCollectionSlice.selectedMessages) || [];
  const isAutoInsertSignature = useAppSelector((state) => state.signatureSlice.signatureEntity?.autoInsert);
  const signature = useAppSelector((state) => state.signatureSlice.signatureEntity?.signature) || '';
  const mailFetchError = useAppSelector((state) => state.messageSlice.mailFetchError);
  const composeCollection = useAppSelector((state) => state.composeSlice.composeCollection);

  const isDragging = useAppSelector((state) => state.appSlice.isDragging);

  const userEmail = getLoggedInEmail() || '';
  const viewDateTransKey = !mailViewDateFormatter(message?.date || '', timeZone).date ? 'today' : 'notToday';
  const [showAttachments, setShowAttachments] = useState(false);
  const [getMessageLazyQueryHook, { error }] = useGetMessageLazyQuery();
  const abortExistingRequest = useAbortExistingRequest();

  const dispatch = useAppDispatch();

  if (!mailId || !folder) {
    return <Navigate to={'/server-error?component=EmailView'} />;
  }

  useEffect(() => {
    if (showReadReceiptModal && !isDraftFolder(folder) && !isSentMailFolder(folder)) {
      dispatch(updateModalName('sendReadReceipt'));
      dispatch(
        updateModalProps({
          subject: message?.subject,
          readReceiptReplyTo: message?.readReceipt?.readReceiptReplyTo,
          to: message?.to?.filter((email) => {
            return email.address && email.address.toLowerCase() === userEmail.toLowerCase();
          })[0],
        })
      );
      dispatch(updateIsOpen(true));
    }
  }, [showReadReceiptModal]);

  useEffect(() => {
    if (message?.uid === parseInt(mailId) && isDraftFolder(folder)) {
      dispatch(
        addDraftToTabCollection({
          ...message,
          html: message?.body?.html,
          readReceipt: message?.readReceipt?.readReceipt,
          isReadReceiptEnabled: message?.readReceipt?.readReceipt,
          isOpenedFromDraft: true,
        } as ComposeFormInput)
      );
      dispatch(updateMinimizedIndex(''));
    }
  }, [message?.uid]);

  useEffect(() => {
    if (emailNotFound) {
      setEmailNotFound(false);
    }
    if (isDraftFolder(folder) && isTabInCollection(composeCollection, mailId)) {
      return;
    }
    if (isDraftFolder(folder)) {
      dispatch(updateIsOpenDraftLoading(true));
    }
    abortExistingRequest(dispatchGetMessage());
  }, [mailId, reloadMessage]);

  useEffect(() => {
    if (error && error.graphQLErrors?.length) {
      if (error.graphQLErrors[0].extensions?.code === 'DATASOURCE_NOT_FOUND') {
        dispatch(reloadMessageCollection(true));
        setEmailNotFound(true);
      }
    }
  }, [error]);

  useLayoutEffect(() => {
    if (isDraftFolder(folder) && isTabInCollection(composeCollection, mailId)) {
      return;
    }
    if (isDraftFolder(folder)) {
      dispatch(updateIsOpenDraftLoading(true));
    }
    abortExistingRequest(dispatchGetMessage());
  }, [isDisplayImageEnabled]);

  const buildMessageBodyQueryArgs = (): MessageBodyArgs => {
    const messageBodyArgs = {
      html: false,
      noImageHtml: false,
    };

    if (isDraftFolder(folder) || isDisplayImageEnabled) {
      messageBodyArgs.html = true;
      return messageBodyArgs;
    }

    if (isDisplayEmailAsText) {
      return messageBodyArgs;
    }

    messageBodyArgs.noImageHtml = true;
    return messageBodyArgs;
  };

  const dispatchGetMessage = (): any =>
    dispatch(
      getMessageThunk({
        payload: {
          messageUid: Number(mailId),
          mailboxId: folder,
          ...buildMessageBodyQueryArgs(),
        },
        getMessageLazyQueryHook,
      })
    );

  const getPlainTextEmail = (): JSX.Element => {
    if (!message?.body?.fullText) {
      return <p className={styles.noEmailContent}>{t('noEmailContent')}</p>;
    }

    return <div className={`${styles.emailIframe} ${styles.whiteSpaceBreak}`}>{message.body.fullText}</div>;
  };

  const handleEmailIFrame = (): JSX.Element | null => {
    if (!message || !message.body) {
      return null;
    }
    if (isDisplayEmailAsText || (!message.body.html && !message.body.noImageHtml)) {
      return getPlainTextEmail();
    }

    let htmlContent = message.body.html || message.body.noImageHtml;
    if (!isDisplayImageEnabled) {
      htmlContent = message.body.noImageHtml;
    }

    return (
      <div className={styles.emailIframe}>
        <iframe
          srcDoc={`<base target="_blank" /> ${htmlContent}`}
          onLoad={(o) => {
            o.currentTarget.style.height =
              100 + (o.currentTarget?.contentWindow?.document.body.scrollHeight || 0) + 'px';
          }}
        />
      </div>
    );
  };

  const handleCloseMessageTracking = (): void => {
    trackEvent({ event: 'messageClose' });
    refocus();
  };

  const handleToggleReadingPane = (): void => {
    setSearchQueryParam('expand', !isReadingPaneExpanded ? String(true) : '');
  };

  const handleSubject = (): JSX.Element | null => {
    return (
      <div className={styles.subject}>
        <AutoFocusHeader content={message?.subject || 'EMPTY SUBJECT'} customStyles={styles.emailTitle} />
        <div className={styles.actionButtons}>
          <KiteButton
            className={styles.expandToggle}
            icon={!isReadingPaneExpanded ? 'ki-expand' : 'ki-collapse'}
            variant={'borderless'}
            onClick={handleToggleReadingPane}
            aria-label={!isReadingPaneExpanded ? 'shrink reading pane' : 'extend reading pane'}
            aria-expanded={isReadingPaneExpanded}
          />
          <Link
            className={styles.closeContactView}
            to={{ pathname: `/user/mail/${encodeURIComponent(folder)}`, search: removeParam(searchQuery, 'expand') }}
            onClick={handleCloseMessageTracking}
            role={'button'}
            aria-label={'close button'}
          >
            <KiteIcon className={styles.close} icon={'ki-x'} />
          </Link>
        </div>
      </div>
    );
  };

  const handlePriority = (): JSX.Element | null => {
    if (!message || !message.priority || message.priority.toLowerCase() !== 'high') {
      return null;
    }

    return (
      <div className={styles.priority}>
        <KiteIcon icon={'ki-caution-circle'} size={'14px'} className={styles.highPriorityIcon} /> Sent With High
        Priority
      </div>
    );
  };

  const handleProfileIcon = (): JSX.Element | null => {
    if (!message || !message.from || !message.from[0]) {
      return null;
    }

    const email = message.from[0].address || 'X';
    return <div className={styles.profileIcon}>{email.slice(0, 2).toUpperCase()}</div>;
  };

  const handleAttachment = (): JSX.Element[] | null => {
    if (!message || !message.attachments || message.attachments.length === 0) {
      return null;
    }

    const attachments = message.attachments.map((item) => {
      return (
        <AuthenticatedDownload key={item.fileName} id={item.id} fileName={item.fileName} fileSize={item.fileSize} />
      );
    });

    if (showAttachments) {
      return attachments;
    }

    return null;
  };

  const handleShowAttachments = (): void => {
    trackEvent({ event: showAttachments ? 'collapseAttachments' : 'expandAttachments' });
    setShowAttachments(!showAttachments);
  };

  const handleReply = (): void => {
    trackEvent({ event: 'replyButton' });
    trackEvent({ event: 'openCompose' });

    if (message) {
      dispatch(
        addReplyToTabCollection({
          signature: isAutoInsertSignature ? signature : '',
          date: mailCreatedFormatter(message.date ?? '', timeZone),
          message,
          userEmail,
        })
      );
    }
  };

  const handleReplyAll = (): void => {
    trackEvent({ event: 'replyAllButton' });
    trackEvent({ event: 'openCompose' });
    if (message) {
      dispatch(
        addReplyAllToTabCollection({
          signature: isAutoInsertSignature ? signature : '',
          date: mailCreatedFormatter(message.date ?? '', timeZone),
          message,
          userEmail,
        })
      );
    }
  };

  const handleForward = (): void => {
    trackEvent({ event: 'forwardEmail' });
    trackEvent({ event: 'openCompose' });
    if (message) {
      dispatch(
        addForwardToTabCollection({
          signature: isAutoInsertSignature ? signature : '',
          date: mailCreatedFormatter(message.date ?? '', timeZone),
          message,
          userEmail,
        })
      );
    }
  };

  const emailContent = (): JSX.Element | null => {
    if (emailNotFound) {
      return (
        <div className={styles.notFoundContainer}>
          <div className={styles.card}>
            <img className={styles.icon} src={appConstants.EMAIL_NOT_FOUND_IMG} alt={'empty mailbox'} />
            <h2 className="kite-type-style--title-6">{t('notFoundHeader')}</h2>
            <p>{t('notFoundDescription')}</p>
          </div>
        </div>
      );
    }

    if (mailFetchError?.message) {
      return (
        <div className={styles.errorContainer}>
          <ServerError retryFunction={() => abortExistingRequest(dispatchGetMessage())} />
        </div>
      );
    }

    if (messageLoading || !message) {
      return (
        <div className={styles.progressSpinnerContainer}>
          <KiteProgressIndicator id={'loading'} useOverlay={false} size="md" />
        </div>
      );
    }

    return (
      <>
        {handleSubject()}
        {handlePriority()}
        <Scrollbar customClass={styles.simpleBar} forceScroll={false}>
          <div className={styles.emailContent}>
            <div className={styles.header}>
              <div className={styles.row}>
                <div className={styles.columnOne}>{handleProfileIcon()}</div>
                <div className={`${styles.columnTwo} ${styles.paddingNameImage}`}>
                  {message?.from![0] && <AddressDisplay userEmail={userEmail} message={message} field="from" />}
                  <div className={styles.recipient}>
                    {message.to && message.to.length > 0 && (
                      <AddressDisplay userEmail={userEmail} message={message} field="to" />
                    )}
                    {message.cc && message.cc.length > 0 && (
                      <AddressDisplay userEmail={userEmail} message={message} field="cc" />
                    )}
                    {message.bcc && message.bcc.length > 0 && (
                      <AddressDisplay userEmail={userEmail} message={message} field="bcc" />
                    )}
                  </div>
                </div>
                <div className={styles.columnThree}>
                  <div className={styles.timeWithReplyOptions}>
                    <div className={styles.time}>
                      {message.date && t(viewDateTransKey, { ...mailViewDateFormatter(message.date, timeZone) })}
                    </div>
                    <div className={styles.replyOptions}>
                      <ul>
                        <li>
                          <KiteButton
                            className={styles.replyOptionButton}
                            variant={'borderless'}
                            icon={'ki-reply'}
                            onClick={() => handleReply()}
                          >
                            {t('reply')}
                          </KiteButton>
                        </li>
                        <li>
                          <KiteButton
                            className={styles.replyOptionButton}
                            variant={'borderless'}
                            icon={'ki-reply-all'}
                            onClick={() => handleReplyAll()}
                          >
                            {t('replyAll')}
                          </KiteButton>
                        </li>
                        <li>
                          <KiteButton
                            className={styles.replyOptionButton}
                            variant={'borderless'}
                            icon={'ki-forward'}
                            onClick={() => handleForward()}
                          >
                            {t('forward')}
                          </KiteButton>
                        </li>
                      </ul>
                    </div>
                    <div className={styles.flagIcon}>
                      {isMessageFlagged(message) && <KiteIcon icon="ki-flag-f" size="16px" fill="#0073D1" />}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {message && message.attachments && message.attachments.length > 0 && (
              <div className={styles.attachments}>
                <KiteButton
                  legacy
                  variant="borderless"
                  onClick={handleShowAttachments}
                  className={styles.attachmentButton}
                >
                  <KiteIcon icon="ki-attachment" size="16px" />
                  <span className={styles.attachmentCountName}>
                    {`${message.attachments.length} ${
                      message.attachments.length > 1 ? t('attachments') : t('attachment')
                    }`}
                  </span>
                  {showAttachments ? (
                    <KiteIcon icon="ki-chevron-up" size="16px" className={styles.attachmentCountChevron} />
                  ) : (
                    <KiteIcon icon="ki-chevron-down" size="16px" className={styles.attachmentCountChevron} />
                  )}
                </KiteButton>
                <div className={styles.attachmentList}>{handleAttachment()}</div>
              </div>
            )}

            {handleEmailIFrame()}
          </div>
        </Scrollbar>
      </>
    );
  };

  if (selectedMessages.length && !alwaysRender) {
    return <EmailViewPlaceholder />;
  }

  if (isDraftFolder(folder)) {
    return null;
  }

  return (
    <div className={`${styles.emailViewContainer} ${isReadingPaneExpanded ? styles.expandedPadding : ''}`}>
      <KiteCard className={`${styles.emailDetailsCard} ${isDragging ? styles.disablePointerEvents : ''}`}>
        {emailContent()}
      </KiteCard>
    </div>
  );
};

export default EmailView;
