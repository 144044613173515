import { createSlice } from '@reduxjs/toolkit';
import { contactMigrationThunk } from 'thunks/migration/contactMigrationThunk';
import { settingMigrationThunk } from 'thunks/migration/settingMigrationThunk';

interface MigrationState {
  isSettingMigrationLoading: boolean;
  isContactMigrationLoading: boolean;
  settingMigrationApiError?: string;
  contactMigrationApiError?: string;
}

const initialState: MigrationState = {
  isSettingMigrationLoading: false,
  isContactMigrationLoading: false,
};

export const migrationSlice = createSlice({
  name: 'migration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(contactMigrationThunk.pending, (state, action) => {
      if (action.meta.arg.payload?.syncButton) {
        return;
      }
      state.isContactMigrationLoading = true;
    });
    builder.addCase(settingMigrationThunk.pending, (state) => {
      state.isSettingMigrationLoading = true;
    });

    builder.addCase(contactMigrationThunk.fulfilled, (state, action) => {
      state.isContactMigrationLoading = false;
      if (action.payload.success) {
        state.contactMigrationApiError = undefined;
      }

      if (action.payload.error) {
        state.contactMigrationApiError = action.payload.error.message;
      }
    });

    builder.addCase(settingMigrationThunk.fulfilled, (state, action) => {
      state.isSettingMigrationLoading = false;
      if (action.payload.success) {
        state.settingMigrationApiError = undefined;
      }

      if (action.payload.error) {
        state.settingMigrationApiError = action.payload.error.message;
      }
    });
  },
});

export const {} = migrationSlice.actions;

export default migrationSlice.reducer;
