import Modal from 'components/common/root-modal/Modal';
import { useAppSelector } from 'hooks';
import MainDrawerContainer from 'containers/MainDrawerContainer';
import FolderList from 'components/folder-list';
import LabelList from 'components/label-list';

const Drawer: React.FC = (): JSX.Element | null => {
  const drawerName = useAppSelector((state) => state.appSlice.drawerName);
  const isDrawerOpen = useAppSelector((state) => state.appSlice.isDrawerOpen);

  if (!isDrawerOpen) {
    return null;
  }

  const switchDrawerContent = (): JSX.Element => {
    switch (drawerName) {
      case 'navigation':
        return <MainDrawerContainer />;
      case 'folderList':
        return <FolderList isDrawer={true} />;
      case 'labelList':
        return <LabelList isDrawer={true} />;
      default:
        return <>Please be careful here</>;
    }
  };

  return (
    <Modal isShown={isDrawerOpen} isDrawer={true}>
      {switchDrawerContent()}
    </Modal>
  );
};

export default Drawer;
