import { ContactFormInterface, initialEmail } from 'components/contact-form/initialState';
import {
  ContactAddressEntity,
  ContactEmailEntity,
  ContactEntity,
  ContactPhoneEntity,
  ContactWithLabelEntity,
} from 'generated/graphql';
import { contactDateFormatter } from './dateFormatter';

type CommonPlaceTypes = 'HOME' | 'WORK';
type PhoneTypes = CommonPlaceTypes | 'MOBILE';

const findPhoneOrDefault = (type: PhoneTypes, phoneNumbers: ContactPhoneEntity[] | null = []): ContactPhoneEntity => {
  const defaultNumber = {
    phoneNumber: '',
    phoneNumberType: type,
    isDefault: false,
  };
  return {
    ...(phoneNumbers?.find((phoneNumber) => phoneNumber.phoneNumberType === type) || defaultNumber),
  };
};

const findDefaultEmail = (contact: ContactEntity | ContactWithLabelEntity): ContactEmailEntity | null => {
  if (!contact.emails || contact.emails?.length === 0) {
    return null;
  }

  const result = contact.emails?.find((email) => email.isDefault);
  return result ?? null;
};

export const findDefaultOrFirstEmail = (contact: ContactEntity | ContactWithLabelEntity): ContactEmailEntity | null => {
  if (!contact.emails || contact.emails?.length === 0) {
    return null;
  }

  const defaultContact = findDefaultEmail(contact);
  if (defaultContact) {
    return defaultContact;
  }

  return contact.emails[0] ?? null;
};

const findAddressOrDefault = (
  type: CommonPlaceTypes,
  addresses: ContactAddressEntity[] | null = []
): ContactAddressEntity => {
  const defaultAddress = {
    state: '',
    streetAddress: '',
    city: '',
    zipCode: '',
    country: '',
    addressType: type,
  };

  return {
    ...(addresses?.find((address) => address.addressType === type) || defaultAddress),
  };
};

const transformPhoneNumber = (phoneEntity: ContactPhoneEntity): ContactPhoneEntity => {
  const cleaned = ('' + phoneEntity.phoneNumber).replace(/[^\d]/g, '').slice(0, 10);
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const transformed = `${match[1]}-${match[2]}-${match[3]}`;
    return { ...phoneEntity, phoneNumber: transformed };
  }

  return phoneEntity;
};

export const apiToContact = (contact: ContactWithLabelEntity): ContactFormInterface => ({
  firstName: contact.firstName || '',
  lastName: contact.lastName || '',
  displayName: contact.displayName || '',
  nickName: contact.nickName || '',
  title: contact.title || '',
  company: contact.company || '',
  emails: contact.emails?.length
    ? contact.emails.map((email) => {
        return { ...email };
      })
    : [{ ...initialEmail }],
  homePhone: transformPhoneNumber(findPhoneOrDefault('HOME', contact.phoneNumbers)),
  mobilePhone: transformPhoneNumber(findPhoneOrDefault('MOBILE', contact.phoneNumbers)),
  workPhone: transformPhoneNumber(findPhoneOrDefault('WORK', contact.phoneNumbers)),
  homeAddress: findAddressOrDefault('HOME', contact.addresses),
  workAddress: findAddressOrDefault('WORK', contact.addresses),
  dateOfBirth: contactDateFormatter(contact.dateOfBirth || ''),
  url: contact.url || '',
  notes: contact.notes || '',
});
