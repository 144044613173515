import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Success, SettingRollbackMutationFn } from 'generated/graphql';

export interface SettingRollbackResponse {
  success?: Success;
  error?: ApolloError | undefined;
}

export interface SettingRollbackArg {
  settingRollbackHook: SettingRollbackMutationFn;
}

export const settingRollbackThunk = createAsyncThunk(
  'settingRollback',
  async (arg: SettingRollbackArg): Promise<SettingRollbackResponse> => {
    try {
      const response = await arg.settingRollbackHook();
      return {
        success: response.data?.settingRollback,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
