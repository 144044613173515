import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import { useMoveMessagesToTrashMutation } from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import { appConstants } from 'appConstants';
import { moveMessagesThunk } from 'thunks/message-collection/moveMessagesThunk';
import { reloadMessageCollection } from 'slices/message-collection';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, useDialogTranslation } from 'translation/hooks';
import useNavToPreviousPage from 'components/common/hooks/useNavToPreviousPage';
import useCloseMessage from 'components/common/hooks/useCloseMessage';

interface DeleteMessagesProps {
  messageUids: number[];
  sourceMailboxId: string;
  alertDescriptionProp: ErrorsKeys;
  currentMailId?: string;
  unseenMessagesCount?: number;
}

const DeleteMessagesModal: React.FC<DeleteMessagesProps> = ({
  messageUids,
  sourceMailboxId,
  alertDescriptionProp,
  currentMailId,
  unseenMessagesCount,
}) => {
  const { t } = useDialogTranslation('deleteMessagesModalCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'folders' });
  const focusOpener = useTarget('modal');
  const apiError = useAppSelector((state) => state.messageCollectionSlice.apiError);
  const messageCollection = useAppSelector((state) => state.messageCollectionSlice.messageCollection);
  const [queryParams, currentPage, navToPreviousPage] = useNavToPreviousPage();
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>(alertDescriptionProp || '');
  const dispatch = useAppDispatch();
  const [moveMessagesHook] = useMoveMessagesToTrashMutation();
  const closeMessage = useCloseMessage();

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('deleteMessageErr');
    }
  }, [apiError]);

  const isSaved = async (): Promise<boolean> => {
    const result = await dispatch(
      moveMessagesThunk({
        moveMessagesHook,
        payload: {
          messageUids,
          sourceMailboxId,
          destinationMailboxId: appConstants.TRASH_FOLDER_ID,
          unseenMessagesCount,
        },
      })
    ).unwrap();

    if (result.success) {
      trackEvent({ event: 'deleteEmailSuccess' });
      const { limit, total, data } = messageCollection[sourceMailboxId];
      const lastPage = currentPage * limit > total;

      if (result.messageUids.length === data?.length && Number(currentPage) > 1 && lastPage) {
        navToPreviousPage();
      }

      if (messageUids.includes(parseInt(currentMailId || ''))) {
        closeMessage(sourceMailboxId, queryParams);
      }

      dispatch(reloadMessageCollection(true));
      dispatch(updateToastMessage({ message: t('successToast'), success: true }));
      dispatch(updateIsOpen(false));

      return true;
    }

    trackEvent({ event: 'deleteEmailFailure' });
    return false;
  };

  const isCancelled = (): boolean => {
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t(alertDescription ? 'tryAgain' : 'delete'),
    isDelete: true,
  };

  return (
    <ModalContentWrapper>
      <h5>{t('header')}</h5>
      {alertDescription || apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <p>{t('description')}</p>
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default DeleteMessagesModal;
