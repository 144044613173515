import submitLoginEvent from 'libs/tracking/adapters/helix/events/submitLoginEvent';
import submitNavigationEvent from 'libs/tracking/adapters/helix/events/submitNavigationEvent';
import submitComposeEvent from 'libs/tracking/adapters/helix/events/submitComposeEvent';
import submitSettingsEvent from 'libs/tracking/adapters/helix/events/submitSettingsEvent';
import submitContactsEvent from 'libs/tracking/adapters/helix/events/submitContactsEvent';
import submitLabelEvents from 'libs/tracking/adapters/helix/events/submitLabelEvents';
import submitCanaryEvent from 'libs/tracking/adapters/helix/events/submitCanaryEvent';
import submitFolderEvents from 'libs/tracking/adapters/helix/events/submitFolderEvents';
import submitMessageEvent from 'libs/tracking/adapters/helix/events/submitMessageEvent';
import submitUnsavedChangesEvent from 'libs/tracking/adapters/helix/events/submitUnsavedChangesEvent';

export interface HelixPayloadInterface {
  event: string;
  page: string;
}

export type HelixEventFn<T = HelixPayloadInterface> = (payload: T) => null;

interface HelixAdapterReturnInterface {
  [key: string]: HelixEventFn;
}

type HelixAdapterType = () => HelixAdapterReturnInterface;

const helixAdapter: HelixAdapterType = () => ({
  submitLoginEvent,
  submitNavigationEvent,
  submitComposeEvent,
  submitContactsEvent,
  submitLabelEvents,
  submitSettingsEvent,
  submitCanaryEvent,
  submitFolderEvents,
  submitMessageEvent,
  submitUnsavedChangesEvent,
});

export default helixAdapter();
