import { KiteIcon, KiteTextInput } from '@kite/react-kite';
import React from 'react';
import ContactSection from './ContactSection';
import styles from 'components/contact-form/styles/ContactCreateContact.module.scss';
import { ContactSectionProps } from 'components/contact-form/initialState';
import { ContactFormInterface } from 'components/contact-form/initialState';
import validateContactField, { getContactValidationErrorMessage } from 'utils/validateContactField';
import { ContactAddressEntity } from 'generated/graphql';
import { KiteInputElems } from 'libs/kite/types';
import { ValidationKeys, usePageTranslation } from 'translation/hooks';
import {
  ContactValidatorTransKeys,
  cityTrans,
  countryTrans,
  stateTrans,
  streetAddressTrans,
  zipCodeTrans,
} from 'components/contact-form/sections/translationKeys';

type HandleAddressChange = (
  formKey: keyof Pick<ContactFormInterface, 'homeAddress' | 'workAddress'>,
  valueKey: keyof ContactAddressEntity,
  e: React.ChangeEvent<KiteInputElems>
) => void;

export const Address: React.FC<ContactSectionProps<Pick<ContactFormInterface, 'homeAddress' | 'workAddress'>>> = ({
  updateFormValues,
  values,
  errors,
  setErrors,
}) => {
  const { t } = usePageTranslation('contactFormCopy');
  const getHomeValidation = (fieldName: string, translationKeys: ContactValidatorTransKeys): ValidationKeys | '' =>
    getContactValidationErrorMessage(errors, `homeAddress.${fieldName}`, translationKeys);
  const getWorkValidation = (fieldName: string, translationKeys: ContactValidatorTransKeys): ValidationKeys | '' =>
    getContactValidationErrorMessage(errors, `workAddress.${fieldName}`, translationKeys);

  const homeAddressStreetErrorMessage = getHomeValidation('streetAddress', streetAddressTrans);
  const homeAddressCityErrorMessage = getHomeValidation('city', cityTrans);
  const homeAddressStateErrorMessage = getHomeValidation('state', stateTrans);
  const homeAddressZipCodeErrorMessage = getHomeValidation('zipCode', zipCodeTrans);
  const homeAddressCountryErrorMessage = getHomeValidation('country', countryTrans);

  const workAddressStreetErrorMessage = getWorkValidation('streetAddress', streetAddressTrans);
  const workAddressCityErrorMessage = getWorkValidation('city', cityTrans);
  const workAddressStateErrorMessage = getWorkValidation('state', stateTrans);
  const workAddressZipCodeErrorMessage = getWorkValidation('zipCode', zipCodeTrans);
  const workAddressCountryErrorMessage = getWorkValidation('country', countryTrans);

  const handleOnChange: HandleAddressChange = (formKey, valueKey, e) => {
    updateFormValues({ ...values[formKey], [valueKey]: e.target.value }, formKey);
    validateContactField(`${formKey}.${valueKey}`, { [formKey]: { [valueKey]: e.target.value } }, errors, setErrors);
  };

  return (
    <ContactSection isAddress>
      <div className={styles.address}>
        <div className={styles.fieldContainerAddress}>
          <KiteIcon className={styles.fieldIcon} icon="ki-home" />
          <KiteTextInput
            className={styles.fullField}
            placeholder={t('addressPlaceholder')}
            label={t('homeAddress')}
            onChange={(e) => handleOnChange('homeAddress', 'streetAddress', e)}
            value={values?.homeAddress?.streetAddress || ''}
            errorMessage={homeAddressStreetErrorMessage}
            ariaLive="polite"
          />
        </div>
        <div className={styles.cityStateCountry}>
          <KiteTextInput
            className={styles.city}
            label={t('city')}
            onChange={(e) => handleOnChange('homeAddress', 'city', e)}
            value={values?.homeAddress?.city || ''}
            errorMessage={t(homeAddressCityErrorMessage)}
            ariaLive="polite"
          />
          <KiteTextInput
            className={styles.state}
            label={t('state')}
            placeholder={t('statePlaceholder')}
            onChange={(e) => handleOnChange('homeAddress', 'state', e)}
            value={values?.homeAddress?.state || ''}
            errorMessage={t(homeAddressStateErrorMessage)}
            ariaLive="polite"
          />
          <KiteTextInput
            placeholder={t('zipCodePlaceholder')}
            className={styles.zipCode}
            label={t('zipCode')}
            onChange={(e) => handleOnChange('homeAddress', 'zipCode', e)}
            value={values?.homeAddress?.zipCode || ''}
            errorMessage={t(homeAddressZipCodeErrorMessage)}
            ariaLive="polite"
          />
          <KiteTextInput
            className={styles.country}
            label={t('country')}
            onChange={(e) => handleOnChange('homeAddress', 'country', e)}
            value={values?.homeAddress?.country || ''}
            errorMessage={t(homeAddressCountryErrorMessage)}
            ariaLive="polite"
          />
        </div>
      </div>
      <div className={styles.address}>
        <div className={styles.fieldContainerAddress}>
          <KiteIcon className={styles.fieldIcon} icon="ki-office" />
          <KiteTextInput
            className={styles.fullField}
            placeholder={t('addressPlaceholder')}
            label={t('workAddress')}
            onChange={(e) => handleOnChange('workAddress', 'streetAddress', e)}
            value={values?.workAddress?.streetAddress || ''}
            errorMessage={t(workAddressStreetErrorMessage)}
            ariaLive="polite"
          />
        </div>
        <div className={styles.cityStateCountry}>
          <KiteTextInput
            className={styles.city}
            label={t('city')}
            onChange={(e) => handleOnChange('workAddress', 'city', e)}
            value={values?.workAddress?.city || ''}
            errorMessage={t(workAddressCityErrorMessage)}
            ariaLive="polite"
          />
          <KiteTextInput
            className={styles.state}
            label={t('state')}
            placeholder={t('statePlaceholder')}
            onChange={(e) => handleOnChange('workAddress', 'state', e)}
            value={values.workAddress?.state || ''}
            errorMessage={t(workAddressStateErrorMessage)}
            ariaLive="polite"
          />
          <KiteTextInput
            placeholder={t('zipCodePlaceholder')}
            className={styles.zipCode}
            label={t('zipCode')}
            onChange={(e) => handleOnChange('workAddress', 'zipCode', e)}
            value={values?.workAddress?.zipCode || ''}
            errorMessage={t(workAddressZipCodeErrorMessage)}
            ariaLive="polite"
          />
          <KiteTextInput
            className={styles.country}
            label={t('country')}
            onChange={(e) => handleOnChange('workAddress', 'country', e)}
            value={values?.workAddress?.country || ''}
            errorMessage={t(workAddressCountryErrorMessage)}
            ariaLive="polite"
          />
        </div>
      </div>
    </ContactSection>
  );
};
