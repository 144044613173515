export const addressBookCopy = {
  defaultHeader: '',
  errorHeader: '',
  errorDescription: '',
  ok: '',
  filterBy: '',
  allContacts: '',
  searchContacts: '',
  found: '',
  sortBy: '',
  firstName: '',
  lastName: '',
  displayName: '',
  email: '',
  selectAll: '',
  addContacts: '',
  addContact: '',
  cancel: '',
};
