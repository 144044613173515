import styles from 'components/contact-modal/styles/SyncContacts.module.scss';
import { KiteButton, KiteIcon, KiteMeter } from '@kite/react-kite';
import { useTarget } from 'components/common/focus/FocusContext';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useReSyncContactMutation } from 'generated/graphql';
import { useAppDispatch } from 'hooks';
import { useEffect, useState } from 'react';
import { updateIsOpen, updateModalProps } from 'slices/app';
import { postContactReSyncThunk } from 'thunks/contact/postContactResyncThunk';
import { useDialogTranslation } from 'translation/hooks';
import { TrackingHook, useTracking } from 'react-tracking';

const SyncContacts: React.FC = () => {
  const { t } = useDialogTranslation('syncContactsCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'contacts' });
  const focusOpener = useTarget('modal');
  const [contactReSyncHook] = useReSyncContactMutation();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSyncCalled, setIsSyncCalled] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useAppDispatch();

  const progressPercentage = Math.floor(progress * 100);

  useEffect(() => {
    trackEvent({ event: 'contactReSyncModalView' });
  }, []);

  useEffect(() => {
    if (!loading && !isSyncCalled) {
      return;
    }

    const interval = setInterval(() => {
      setProgress(progress + 0.0001);
    }, 5);

    if (progress >= 1) {
      clearInterval(interval);
      dispatch(updateModalProps({ hideCloseButton: false }));
      setLoading(false);
    }

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [loading, progress]);

  const handleSyncContacts = async (): Promise<void> => {
    trackEvent({ event: 'contactReSyncButtonClicked' });
    setLoading(true);
    dispatch(updateModalProps({ hideCloseButton: true }));
    const result = await dispatch(postContactReSyncThunk({ contactReSyncHook })).unwrap();
    setIsSyncCalled(true);
    if (result.success) {
      trackEvent({ event: 'contactReSyncSuccess' });
      setProgress(100);
      setError(false);

      return;
    }

    trackEvent({ event: 'contactReSyncFailure' });

    setProgress(100);
    setLoading(false);
    setError(true);
  };

  const handleCancel = (): void => {
    trackEvent({ event: 'contactReSyncModalCancel' });
    focusOpener();
    dispatch(updateIsOpen(false));
  };

  const handleOk = (): void => {
    trackEvent({ event: 'contactReSyncOkClicked' });
    focusOpener();
    dispatch(updateIsOpen(false));

    if (!error) {
      window.location.reload(); // we'll have to optimize this later
    }
  };

  const getProgressContent = (): JSX.Element => {
    if (loading) {
      return (
        <>
          <h5>{t('loadingHeader')}</h5>
          <p>{t('loadingDescription')}</p>
          <KiteMeter label={t('progressLabel')} value={progress} valueLabel={`${String(progressPercentage)}%`} />
        </>
      );
    }

    if (error) {
      return (
        <div className={styles.resyncModal}>
          <div className={styles.headerIconContainer}>
            <KiteIcon icon={'ki-caution-circle-f'} size={'24px'} fill="#D6312B" />
            <h5>{t('errorHeader')}</h5>
          </div>
          <p className={styles.errorDescription}>{t('errorDescription')}</p>
          <KiteButton className={styles.ok} variant={'primary'} onClick={handleOk}>
            {t('ok')}
          </KiteButton>
        </div>
      );
    }

    return (
      <div className={styles.resyncModal}>
        <div className={styles.headerIconContainer}>
          <KiteIcon icon={'ki-checkmark-circle-f'} size={'24px'} fill="#008516" />
          <h5>{t('successHeader')}</h5>
        </div>
        <KiteButton className={`${styles.ok} ${styles.marginTop}`} variant={'primary'} onClick={handleOk}>
          {t('ok')}
        </KiteButton>
      </div>
    );
  };

  return (
    <ModalContentWrapper>
      {!isSyncCalled && !loading ? (
        <div className={styles.resyncModal}>
          <h5>{t('header')}</h5>
          <p>{t('description')}</p>
          <div className={styles.saveCancel}>
            <KiteButton onClick={handleSyncContacts}>{t('confirmBtnText')}</KiteButton>
            <KiteButton variant={'secondary'} onClick={handleCancel}>
              {t('cancel')}
            </KiteButton>
          </div>
        </div>
      ) : (
        getProgressContent()
      )}
    </ModalContentWrapper>
  );
};

export default SyncContacts;
