export const contactNavigationCopy = {
  createContact: '',
  allContactLabel: '',
  newLabel: '',
  labelsHeader: '',
  contactsUsage: '',
  optionRename: '',
  optionDelete: '',
  emptyTitle: '',
  emptyInstruction: '',
};
