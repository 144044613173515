import { default as assignLabelToContactCopy } from 'translation/en_US/dialogs/assign-label-to-contact.json';
import { default as blockSendersCopy } from 'translation/en_US/dialogs/block-senders.json';
import { default as canaryModalCopy } from 'translation/en_US/dialogs/canary-modal.json';
import { default as deleteContactCopy } from 'translation/en_US/dialogs/delete-contact.json';
import { default as deleteFilterCopy } from 'translation/en_US/dialogs/delete-filter.json';
import { default as deleteFolderCopy } from 'translation/en_US/dialogs/delete-folder.json';
import { default as deleteLabelCopy } from 'translation/en_US/dialogs/delete-label.json';
import { default as deleteMessagesModalCopy } from 'translation/en_US/dialogs/delete-messages-modal.json';
import { default as disableForwardingModalCopy } from 'translation/en_US/dialogs/disable-forwarding-modal.json';
import { default as discardDraftCopy } from 'translation/en_US/dialogs/discard-draft.json';
import { default as emptyTrashCopy } from 'translation/en_US/dialogs/empty-trash.json';
import { default as exportContactCopy } from 'translation/en_US/dialogs/export-contact.json';
import { default as importContactsCopy } from 'translation/en_US/dialogs/import-contacts.json';
import { default as markAsNotSpamCopy } from 'translation/en_US/dialogs/mark-as-not-spam.json';
import { default as markAsSpamCopy } from 'translation/en_US/dialogs/mark-as-spam.json';
import { default as moveMessagesCopy } from 'translation/en_US/dialogs/move-messages.json';
import { default as newFolderCopy } from 'translation/en_US/dialogs/new-folder.json';
import { default as newLabelCopy } from 'translation/en_US/dialogs/new-label.json';
import { default as permanentDeleteMessageCopy } from 'translation/en_US/dialogs/permanent-delete-message.json';
import { default as renameFolderCopy } from 'translation/en_US/dialogs/rename-folder.json';
import { default as renameLabelCopy } from 'translation/en_US/dialogs/rename-label.json';
import { default as saveDraftCopy } from 'translation/en_US/dialogs/save-draft.json';
import { default as readReceiptCopy } from 'translation/en_US/dialogs/read-receipt.json';
import { default as spanishComingSoonCopy } from 'translation/en_US/dialogs/spanish-coming-soon.json';
import { default as unsavedChangesModalCopy } from 'translation/en_US/dialogs/unsaved-changes-modal.json';
import { default as viewHeaderCopy } from 'translation/en_US/dialogs/view-header.json';
import { default as addressBookCopy } from 'translation/en_US/dialogs/address-book.json';
import { default as aupCodeCopy } from 'translation/en_US/dialogs/aup-code.json';
import { default as syncContactsCopy } from 'translation/en_US/dialogs/sync-contacts.json';

const dialogs = {
  assignLabelToContactCopy,
  blockSendersCopy,
  canaryModalCopy,
  deleteContactCopy,
  deleteFilterCopy,
  deleteFolderCopy,
  deleteLabelCopy,
  deleteMessagesModalCopy,
  disableForwardingModalCopy,
  discardDraftCopy,
  emptyTrashCopy,
  exportContactCopy,
  importContactsCopy,
  markAsNotSpamCopy,
  markAsSpamCopy,
  moveMessagesCopy,
  newFolderCopy,
  newLabelCopy,
  permanentDeleteMessageCopy,
  renameFolderCopy,
  renameLabelCopy,
  saveDraftCopy,
  readReceiptCopy,
  spanishComingSoonCopy,
  unsavedChangesModalCopy,
  viewHeaderCopy,
  addressBookCopy,
  aupCodeCopy,
  syncContactsCopy,
};

export default dialogs;
