import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface PostOptInStatusThunkPayload {
  optInType: string;
  isOptInYes: boolean;
}

export interface PostOptInStatusThunkArgs {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optInHook: any;
  payload: PostOptInStatusThunkPayload;
}

export interface PostOptInStatusThunkResponse {
  success?: {
    email: string;
    userId: string;
    optInType: string;
    optInStatus: string;
  };
  error?: ApolloError;
}
export const postOptInStatusThunk = createAsyncThunk(
  'postOptInStatus',
  async (arg: PostOptInStatusThunkArgs): Promise<PostOptInStatusThunkResponse> => {
    try {
      const response = await arg.optInHook({ variables: { ...arg.payload } });
      return {
        success: {
          email: response.data.postOptInStatus.email,
          userId: response.data.postOptInStatus.userId,
          optInType: response.data.postOptInStatus.optInType,
          optInStatus: response.data.postOptInStatus.optInStatus,
        },
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
