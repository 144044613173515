import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FilterForm, UpdateFiltersMutationFn } from 'generated/graphql';

interface UpdateFilterArgInterface {
  payload: any;
  updateFiltersHook: UpdateFiltersMutationFn;
}

interface UpdateFilterResponse {
  success?: boolean;
  filters?: FilterForm[];
  error?: ApolloError;
}

export const updateFilterThunk = createAsyncThunk(
  'updateFilterThunk',
  async (arg: UpdateFilterArgInterface): Promise<UpdateFilterResponse> => {
    try {
      const response = await arg.updateFiltersHook({
        variables: arg.payload,
      });
      return {
        success: response.data?.updateFilters.success,
        filters: arg.payload.filters,
      } as UpdateFilterResponse;
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
