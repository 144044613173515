import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import { useUpdateMailForwardingMutation } from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import { updateMailForwardThunk } from 'thunks/setting/updateMailForwardThunk';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { DialogNSTranslationFileKeys, useDialogTranslation } from 'translation/hooks';

const DisableForwardingModal: React.FC = () => {
  const { t } = useDialogTranslation('disableForwardingModalCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'settings' });
  const apiError = useAppSelector((state) => state.settingSlice.apiError);
  const focusOpener = useTarget('modal');
  const [alertDescription, setAlertDescription] =
    useState<DialogNSTranslationFileKeys<'disableForwardingModalCopy'>>('');

  const [updateMailForwardHook] = useUpdateMailForwardingMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    trackEvent({ event: 'disableForwardingModalView' });
  }, []);

  useEffect(() => {
    if (apiError) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  const isSaved = async (): Promise<boolean> => {
    const result = await dispatch(updateMailForwardThunk({ updateMailForwardHook }));
    //@ts-ignore
    if (result?.payload?.success) {
      trackEvent({ event: 'disableForwardingSuccess' });
      dispatch(updateToastMessage({ message: t('successToast'), success: true }));
      return true;
    }
    trackEvent({ event: 'disableForwardingError' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'disableForwardingModalViewClose' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    isDelete: false,
    saveButtonText: t('confirmBtnText'),
  };

  return (
    <ModalContentWrapper className={`${modalStyle.wrappedContent}`}>
      <AutoFocusHeader content={t('header')} />
      <p className={modalStyle.alertMessage}>{t('description')}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default DisableForwardingModal;
