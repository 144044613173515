import { ApolloError, MutationFunction } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetSettingQuery, GetSettingQueryVariables, SettingEntity } from 'generated/graphql';

interface getSettingArg {
  getSettingHook: MutationFunction<GetSettingQuery, GetSettingQueryVariables>;
}

interface getSettingResponse {
  success?: SettingEntity;
  error?: ApolloError;
}

export const getSettingThunk = createAsyncThunk(
  'getSettingThunk',
  async (arg: getSettingArg): Promise<getSettingResponse> => {
    try {
      const response = await arg.getSettingHook();

      return {
        success: response?.data?.getSetting,
      } as getSettingResponse;
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
