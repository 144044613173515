import styles from 'components/skip-links/styles/SkipLinks.module.scss';
import SkipLink from 'components/skip-links/SkipLink';
import { appConstants } from 'appConstants';
import { KiteCard } from '@kite/react-kite';

const SkipLinks: React.FC = () => {
  return (
    <KiteCard className={`${styles.SkipLinks} srOnly`}>
      {appConstants.SKIP_LINKS.map((link, idx) => (
        <SkipLink key={idx} {...link} />
      ))}
    </KiteCard>
  );
};

export default SkipLinks;
