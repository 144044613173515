import styles from 'components/common/scrollbar/styles/Scrollbar.module.scss';
import { Scrollbars, positionValues } from 'react-custom-scrollbars-2';
import useMobileSize from 'components/common/hooks/useMobileSize';

interface ScrollbarInterface {
  forceScroll: boolean;
  customClass: string;
  handleScrollFrame?: (positionValues: positionValues) => void;
}

const Scrollbar: React.FC<ScrollbarInterface> = ({ forceScroll, customClass, children, handleScrollFrame }) => {
  const isMobile = useMobileSize();
  if (isMobile && !forceScroll) {
    return <div className={`scrollbar-container ${customClass}`}>{children}</div>;
  }

  return (
    <Scrollbars
      className={`scrollbar-container ${styles.reactScrollbar} ${customClass} ${forceScroll ? styles.fitContent : ''}`}
      autoHeight={false}
      onScrollFrame={handleScrollFrame}
    >
      {children}
    </Scrollbars>
  );
};

export { Scrollbar };
