import { KiteButton, KiteIcon } from '@kite/react-kite';
import styles from 'components/folder-list/styles/FolderListItem.module.scss';
import { Link, useParams } from 'react-router-dom';
import { Mailbox } from 'utils/folderCollectionHelper';
import KebabMenu, { KebabMenuItemsInterface } from 'components/common/kebab-menu';
import { updateCanDrop, updateIsOpen, updateModalName, updateModalProps } from 'slices/app';
import { useAppDispatch } from 'hooks';
import { TrackingHook, useTracking } from 'react-tracking';
import { GlobalFoldersInterface, appConstants } from 'appConstants';
import { isDraftFolder, isInboxFolder, isSentMailFolder, isSpamFolder } from 'utils/folderNameValidator';
import { updateModalOpener } from 'components/common/focus/FocusContext';
import { useCallback } from 'react';
import { mailboxStatusThunk } from 'thunks/mailbox-collection/mailboxStatusThunk';
import { useGetMailBoxStatusLazyQuery } from 'generated/graphql';
import { defaultToZeroIfNegative } from 'utils/defaultToZeroIfNegative';
import { PageNSTranslationFileKeys, usePageTranslation } from 'translation/hooks';
import { useDrop } from 'react-dnd';
import isMoveMessagesAllowed from 'utils/isMoveMessagesAllowed';

interface FolderListInterface {
  folderList: Mailbox[];
  selectedFolder: string;
  isGlobal: boolean;
  handleNewFolderButton: (parentFolderId?: string, fullPath?: string) => void;
  handleRenameFolderButton: (folderId?: string, fullPath?: string, isFirstParent?: boolean) => void;
  handleDeleteFolderButton: (folderId?: string, fullPath?: string) => void;
  handleDrawerClose?: () => void;
}

interface RecursiveComponent extends Mailbox {
  isFirstParent: boolean;
}

const FolderListItem: React.FC<FolderListInterface> = ({
  folderList,
  selectedFolder,
  isGlobal,
  handleNewFolderButton,
  handleRenameFolderButton,
  handleDeleteFolderButton,
  handleDrawerClose,
}) => {
  const { t } = usePageTranslation('emailNavigationCopy');
  const dispatch = useAppDispatch();
  const { trackEvent }: TrackingHook = useTracking({ page: 'folders' });
  const { folder } = useParams<{ folder: string }>();
  const updateModalRef = updateModalOpener();
  const [getMailboxStatusHook] = useGetMailBoxStatusLazyQuery();

  const handleEmptyTrash = (e: React.MouseEvent<HTMLButtonElement>): void => {
    trackEvent({ event: 'emptyTrash' });
    dispatch(updateModalProps({ mailboxId: appConstants.TRASH_FOLDER_ID, activeFolderId: folder }));
    dispatch(updateModalName('emptyTrash'));
    dispatch(updateIsOpen(true));
    updateModalRef(e.currentTarget);
    return;
  };

  const handleDrawerCloseClick = (isFirstParent: boolean, folderName: string, id: string): void => {
    if (!isGlobal) {
      isFirstParent ? trackEvent({ event: 'folderClick' }) : trackEvent({ event: 'subFolderClick' });
    }

    if (isDraftFolder(id)) {
      trackEvent({ event: 'draftsFolderClick' });
      trackEvent({ event: 'pageViewInitDrafts' });
    }

    if (isSentMailFolder(id)) {
      trackEvent({ event: 'pageViewInitSentMail' });
    }

    if (isSpamFolder(id)) {
      trackEvent({ event: 'spamFolderClick' });
      trackEvent({ event: 'pageViewInitSpam' });
    }

    if (isInboxFolder(id)) {
      trackEvent({ event: 'inboxFolderClick' });
      trackEvent({ event: 'pageViewInitInbox' });
      dispatch(mailboxStatusThunk({ getMailboxStatusHook, payload: { mailboxId: id } }));
    }

    if (!handleDrawerClose) {
      return;
    }

    handleDrawerClose();
  };

  const onKebabToggle = (expanded: boolean): void => {
    if (expanded) {
      trackEvent({ event: 'folderKebabToggle' });
    }
  };

  const getKebabMenuItems = (
    folderId: string,
    fullPath: string,
    isFirstParent: boolean,
    isGlobal?: boolean
  ): KebabMenuItemsInterface[] => {
    if (isGlobal) {
      return [
        {
          onClick: () => handleDeleteFolderButton(folderId, fullPath),
          icon: 'ki-trash',
          label: t('delete'),
          className: 'deleteMenuItem',
          opensModal: true,
        },
      ];
    }

    return [
      {
        onClick: () => handleNewFolderButton(folderId, fullPath),
        icon: 'ki-plus',
        label: t('newSubfolder'),
        opensModal: true,
      },
      {
        onClick: () => handleRenameFolderButton(folderId, fullPath, isFirstParent),
        icon: 'ki-edit',
        label: t('rename'),
        opensModal: true,
      },
      {
        onClick: () => handleDeleteFolderButton(folderId, fullPath),
        icon: 'ki-trash',
        label: t('delete'),
        className: 'deleteMenuItem',
        opensModal: true,
      },
    ];
  };

  const generateDropRef = (destinationId: string): [React.LegacyRef<HTMLLIElement>, boolean] => {
    const [{ isOver }, dropRef] = useDrop(() => ({
      accept: 'listMessageItem',
      canDrop: (_, monitor) => {
        const { sourceMailboxId }: { messageUids: [number]; sourceMailboxId: string } = monitor.getItem();
        const isAllowed = isMoveMessagesAllowed(sourceMailboxId, destinationId);
        if (monitor.isOver()) {
          dispatch(updateCanDrop(isAllowed));
          return isAllowed;
        }

        dispatch(updateCanDrop(true));
        return true;
      },
      drop: (_, monitor) => {
        const item: { sourceMailboxId: string; messageUids: [number] } = monitor.getItem();

        return {
          destinationMailboxId: destinationId,
          sourceMailboxId: item.sourceMailboxId,
          messageUids: item.messageUids,
        };
      },
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        item: monitor.getItem(),
        isOver: monitor.isOver({ shallow: true }),
      }),
    }));

    return [dropRef, isOver];
  };

  const FolderRecursiveComponent = useCallback(
    ({ children, name, id, count, isFirstParent, icon, isTrash, fullPath }: RecursiveComponent): JSX.Element => {
      const isSelected = selectedFolder === id ? styles.selected : '';
      const [ref, isOver] = generateDropRef(id);

      return (
        <ul key={id} className={styles.listItem}>
          <li key={id} className={`${styles.childItem} ${isFirstParent ? styles.noPadding : ''}`}>
            <div className={`${styles.folderItemWrapper} ${isSelected} ${isOver ? styles.hover : ''}`}>
              <Link
                to={{ pathname: `/user/mail/${encodeURIComponent(id)}` }}
                key={id}
                onClick={() => handleDrawerCloseClick(isFirstParent, name, id)}
                className={`${styles.folderTab} ${styles.customFolderTab} ${
                  isGlobal ? `e2e_global_folder e2e_global_folder_${name}` : 'e2e_user_folder'
                }`}
                aria-current={selectedFolder === id}
                ref={ref as React.Ref<HTMLAnchorElement>}
              >
                <KiteIcon size={'16px'} icon={icon ? icon : 'ki-folder'} className={styles.folderIcon} />
                <span id={`folder-${name}-name`} className={styles.text}>
                  {isFirstParent && isGlobal
                    ? t(
                        appConstants.GLOBAL_FOLDER_NAMES[
                          name as keyof GlobalFoldersInterface
                        ] as PageNSTranslationFileKeys<'emailNavigationCopy'>
                      )
                    : name}
                </span>
                {isInboxFolder(id) && count ? <span className={'srOnly'}>unread messages</span> : null}
              </Link>
              {isTrash && (
                <KiteButton
                  className={`${styles.emptyTrashButton} fixedPosition`}
                  variant={'borderless'}
                  onClick={(e) => handleEmptyTrash(e)}
                  aria-label={'empty trash'}
                >
                  {t('emptyTrash')}
                </KiteButton>
              )}
              {isGlobal && isFirstParent ? (
                <span className={`${styles.folderCount} ${isSelected} fixedPosition`}>
                  {!isSentMailFolder(id) && defaultToZeroIfNegative(count || 0)}
                </span>
              ) : (
                <KebabMenu
                  items={getKebabMenuItems(id, fullPath || '', isFirstParent, isGlobal)}
                  onKebabToggle={onKebabToggle}
                  className={'fixedPosition'}
                  isScrollContainer={true}
                  ariaDescribedBy={`folder-${name}-name`}
                  ariaLabel={`${name} folder submenu`}
                />
              )}
            </div>
            {children &&
              children.length > 0 &&
              children.map((child: Mailbox) => (
                <FolderRecursiveComponent key={`${child.name}`} {...child} isFirstParent={false} />
              ))}
          </li>
        </ul>
      );
    },
    [folderList, selectedFolder]
  );

  return (
    <ul className={`${styles.folderList} ${isGlobal ? styles.globalFolderList : ''}`}>
      {folderList.map((mailbox) => {
        return (
          <li className={`${styles.parentFolder}`} key={`${mailbox.name}`}>
            <FolderRecursiveComponent {...mailbox} isFirstParent={true} />
          </li>
        );
      })}
    </ul>
  );
};

export default FolderListItem;
