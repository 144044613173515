import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { updateNavBlocking } from 'slices/app';
import { useAppDispatch } from 'hooks';
import { isEqual } from 'lodash';

const useNavBlockingDraft = <S>(initialState: S): [S, Dispatch<SetStateAction<S>>] => {
  const [draft, setDraft] = useState(initialState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateNavBlocking(!isEqual(draft, initialState)));
  }, [isEqual(draft, initialState)]);

  return [draft as S, setDraft as Dispatch<SetStateAction<S>>];
};

export default useNavBlockingDraft;
