export const blockSendersCopy = {
  header: '',
  headerPlural: '',
  descriptionA: '',
  descriptionASingle: '',
  descriptionB: '',
  confirmBtnText: '',
  confirmBtnTextSingle: '',
  successToast: '',
  successToastSingle: '',
};
