import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContactForm, ContactWithLabelEntity, CreateContactMutationFn } from 'generated/graphql';

interface CreateContactThunkArg {
  payload: {
    contact: ContactForm;
  };
  createContactHook: CreateContactMutationFn;
}

interface CreateContactThunkResponse {
  success?: ContactWithLabelEntity;
  contact?: ContactForm;
  error?: ApolloError;
}

export const createContactThunk = createAsyncThunk(
  'createContactThunk',
  async (arg: CreateContactThunkArg): Promise<CreateContactThunkResponse> => {
    try {
      const response = await arg.createContactHook({
        variables: arg.payload,
      });

      return {
        success: response?.data?.createContact as ContactWithLabelEntity,
        contact: arg.payload.contact,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
