import React, { useEffect, useState } from 'react';
import { KiteButton, KiteIcon, KiteSelect, KiteTextInput } from '@kite/react-kite';
import {
  FilterFormInterface,
  FilterFormRule,
  FilterSectionProps,
  initialFilterRule,
} from 'components/setting-filter-form/initialState';
import validateFilterField, { getFilterValidationErrorMessage } from 'utils/validateFilterField';
import styles from 'components/setting-filter-form/styles/SettingFilterForm.module.scss';
import FilterSection from 'components/setting-filter-form/sections/FilterSection';
import { TrackingHook, useTracking } from 'react-tracking';
import { isEqual } from 'lodash';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import { usePageTranslation } from 'translation/hooks';
import { emailAddressTrans } from 'components/setting-filter-form/sections/translationKeys';

interface ConditionProps {
  isNew: boolean;
}

export const Condition: React.FC<ConditionProps & FilterSectionProps<Pick<FilterFormInterface, 'rules'>>> = ({
  updateFormValues,
  values,
  isNew,
  errors,
  setErrors,
}) => {
  const { t } = usePageTranslation('filterRuleFormCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'settings' });

  const CONDITION_FIELDS = [
    { label: t('optionEmailFrom'), value: 'from' },
    { label: t('optionEmailTo'), value: 'to' },
    { label: t('optionEmailCC'), value: 'cc' },
    { label: t('optionEmailRecipients'), value: 'recipients' },
    { label: t('optionEmailSubjectLines'), value: 'subject' },
  ];

  const CONDITION_OPERATORS = [
    { label: t('optionContains'), value: 'contains' },
    { label: t('optionDoesNotContain'), value: 'containsNot' },
  ];

  const [conditionFields, setConditionFields] = useState<FilterFormRule[]>(values.rules);
  const [isValueLoaded, setIsValueLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!isValueLoaded && !isNew && values.rules && !isEqual(values.rules, [initialFilterRule])) {
      setConditionFields(values.rules);
      setIsValueLoaded(true);
    }
  }, [values]);

  useEffect(() => {
    updateFormValues(conditionFields, 'rules');
  }, [conditionFields]);

  const editCondition = (value: string, field: string, idx: number): void => {
    const editedFields = [
      ...conditionFields.slice(0, idx),
      { ...conditionFields[idx], [field]: value },
      ...conditionFields.slice(idx + 1),
    ];
    setConditionFields(editedFields);
    validateFilterField(`rules[${idx}].value`, { rules: editedFields }, errors, setErrors);
  };

  const renderConditionFields = (): JSX.Element[] => {
    return conditionFields.map((rule, index) => {
      const conditionValueErrorMessage = getFilterValidationErrorMessage(
        errors,
        `rules[${index}].value`,
        emailAddressTrans
      );

      const headerTitle = t('conditionTitle', { count: index + 1 });

      return (
        <div key={index} role={'region'}>
          {index === 0 ? (
            <div className={styles.fieldContainerHeader}>{headerTitle}</div>
          ) : (
            <AutoFocusHeader content={headerTitle} customStyles={styles.header} />
          )}
          <div className={styles.fieldContainer}>
            <KiteSelect
              id={'conditionFieldListId'}
              className={styles.halfField}
              label={t('conditionIfLabel')}
              name={'conditionFieldList'}
              value={rule.field}
              onBlur={() => validateFilterField(`rules[${index}].field`, { rules: conditionFields }, errors, setErrors)}
              onChange={(e) => editCondition(e.target.value, 'field', index)}
              placeholder={t('actionChoiceDefault')}
              aria-label={t('conditionComboAria')}
            >
              {CONDITION_FIELDS.map((field) => (
                <option key={`conditionField-${field.value}`} value={field.value}>
                  {field.label}
                </option>
              ))}
            </KiteSelect>

            <KiteSelect
              id={'conditionOperatorListId'}
              className={styles.halfField}
              label={t('conditionContainsLabel')}
              name={'conditionOperatorList'}
              value={rule.operator}
              onBlur={() =>
                validateFilterField(`rules[${index}].operator`, { rules: conditionFields }, errors, setErrors)
              }
              onChange={(e) => editCondition(e.target.value, 'operator', index)}
              placeholder={t('actionChoiceDefault')}
              aria-label={t('conditionContainAria')}
            >
              {CONDITION_OPERATORS.map((operator) => (
                <option key={`conditionOperator-${operator.value}`} value={operator.value}>
                  {operator.label}
                </option>
              ))}
            </KiteSelect>
          </div>
          <div className={styles.fieldContainer}>
            <KiteTextInput
              className={styles.fullField}
              placeholder={t('conditionEmailKeywordPlaceholder')}
              label={t('conditionEmailKeywordLabel')}
              onChange={(e) => editCondition(e.target.value, 'value', index)}
              value={rule.value}
              // onBlur={() => validateFilterField(`rules[${index}].value`, { rules: conditionFields }, errors, setErrors)}
              errorMessage={
                conditionValueErrorMessage
                  ? t(conditionValueErrorMessage === 'required' ? 'emailOrKeywordRequired' : conditionValueErrorMessage)
                  : ''
              }
              aria-label={t('conditionEmailAria')}
              ariaLive="polite"
            />

            {conditionFields.length > 1 && (
              <button
                type="button"
                className={styles.xButton}
                onClick={() => removeCondition(index)}
                aria-label={`remove condition`}
              >
                <KiteIcon icon="ki-x" size="20px" />
              </button>
            )}
          </div>
        </div>
      );
    });
  };

  const addRule = (): void => {
    trackEvent({ event: isNew ? 'filterRulesNewAddCondition' : 'filterRulesEditAddCondition' });
    setConditionFields(conditionFields.concat(initialFilterRule));
  };

  const removeCondition = (idx: number): void => {
    setConditionFields([...conditionFields.slice(0, idx), ...conditionFields.slice(idx + 1)]);
    setErrors({ ...errors, [`rules[${idx}].value`]: '' });
  };

  return (
    <FilterSection>
      {renderConditionFields()}
      <KiteButton
        size="sm"
        icon={'ki-plus'}
        onClick={addRule}
        variant="borderless"
        className={styles.addFieldButton}
        disabled={conditionFields.length >= 5}
        aria-disabled={conditionFields.length >= 5}
        aria-label={`add additional condition`}
      >
        {t('conditionAddBtn')}
      </KiteButton>
      <p className={styles.addFieldDescription}>{t('conditionAddDescription')}</p>
    </FilterSection>
  );
};
