import { ApolloError, MutationFunction } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetContactCountQuery, GetContactCountQueryVariables } from 'generated/graphql';

interface ContactCountResponse {
  success?: {
    count: number;
  };
  error?: ApolloError | undefined;
}

interface ContactCountArg {
  getContactCountHook: MutationFunction<GetContactCountQuery, GetContactCountQueryVariables>;
}

export const contactCountThunk = createAsyncThunk(
  'contactCount',
  async (arg: ContactCountArg): Promise<ContactCountResponse> => {
    try {
      const response = await arg.getContactCountHook();
      return {
        success: {
          count: parseInt(response.data?.getContactCount.count ?? '0'),
        },
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
