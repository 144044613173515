import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import styles from 'components/contact-form/styles/ContactCreateContact.module.scss';
import { ContactFormInterface, initialState } from 'components/contact-form/initialState';
import { Address, AltName, DOB, Email, Employment, Name, Notes, Phone, URL } from 'components/contact-form/sections';
import { KiteAlert, KiteCard, KiteIcon } from '@kite/react-kite';
import { isEqual } from 'lodash';
import { useUpdateContactMutation, useGetContactLazyQuery } from 'generated/graphql';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateModalProps, updateNavBlocking, updateToastMessage } from 'slices/app';
import { resetContactApiError } from 'slices/contact';
import useContactFieldValidatorError from 'hooks/useContactFieldValidatorError';
import transformContactForm from 'utils/transformContactForm';
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { contactThunk } from 'thunks/contact/contactThunk';
import { apiToContact } from 'utils/transformContact';
import { isContactFormValid } from 'components/contact-form/helpers';
import { updateContactThunk } from 'thunks/contact/updateContactThunk';
import { TrackingHook, useTracking } from 'react-tracking';
import useNavBlockingDraft from 'hooks/useNavBlockingDraft';
import { createTargetRef } from 'components/common/focus/FocusContext';
import { Scrollbar } from 'components/common/scrollbar';
import { ErrorsKeys, usePageTranslation } from 'translation/hooks';
import { UseLocationState } from 'components/contact-form/Create';

const ContactUpdate: React.FC = () => {
  const { t } = usePageTranslation('contactFormCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'contacts' });

  const contact = useAppSelector((state) => state.contactSlice.contact);

  const [formValues, setFormValues] = useNavBlockingDraft(contact ? apiToContact(contact) : initialState);
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const [errors, setErrors] = useContactFieldValidatorError({});
  const firstChildRef = createTargetRef<HTMLInputElement>('contactUpdate');
  const dispatch = useAppDispatch();
  const { state } = useLocation() as UseLocationState;
  const navigate = useNavigate();
  const { contactId, labelId } = useParams<{ contactId: string; labelId: string }>();
  const [updateContactHook] = useUpdateContactMutation();
  const [getContactHook, { loading }] = useGetContactLazyQuery();
  const navigationTo = `/user/label/${labelId}/${contactId}`;
  const [shouldNavigate, setShouldNavigate] = useState(false);

  if (!contactId || !labelId) {
    return <Navigate to={'/server-error?component=ContactUpdate'} />;
  }

  useEffect(() => {
    trackEvent({ event: 'updateContactPageView' });
    dispatch(
      updateModalProps({
        modalViewEvent: 'updateContactUnsavedChangesModalView',
        okEvent: 'updateContactUnsavedChangesOk',
        cancelEvent: 'updateContactUnsavedChangesCancel',
      })
    );
  }, []);

  useEffect(() => {
    if (!loading && !contact) {
      dispatch(contactThunk({ getContactHook, id: contactId }));
    }

    if (contact) {
      setFormValues(apiToContact(contact));
    }
  }, [contact]);

  useEffect(() => {
    if (shouldNavigate) {
      navigate(`${navigationTo}${state ? state.search : ''}`);
    }
  }, [shouldNavigate]);

  useEffect(() => {
    setAlertDescription('');
  }, [formValues]);

  const updateFormValues = (
    value: ContactFormInterface[keyof ContactFormInterface],
    field: keyof ContactFormInterface
  ): void => {
    setFormValues({ ...formValues, [field]: value });
  };

  const isSaved = async (): Promise<boolean> => {
    trackEvent({ event: 'saveUpdate' });
    const result = await dispatch(
      updateContactThunk({
        payload: {
          contact: transformContactForm(formValues),
          id: contactId,
        },
        updateContactHook,
      })
    );

    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'saveUpdateSuccess' });
      dispatch(updateToastMessage({ message: t('updatedSuccessToast'), success: true }));
      dispatch(resetContactApiError());
      dispatch(updateNavBlocking(false));
      setAlertDescription('');
      setShouldNavigate(true);
      return true;
    }

    setAlertDescription('unableToSaveErr');
    trackEvent({ event: 'saveUpdateError' });
    return false;
  };

  const isValid = (): boolean => {
    const [isValid, contactFormErrors] = isContactFormValid(formValues);

    if (isValid) {
      return true;
    }

    setErrors(contactFormErrors);
    trackEvent({ event: 'saveUpdateError' });
    setAlertDescription('validationErr');
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'cancelUpdate' });
    dispatch(updateNavBlocking(false));
    setShouldNavigate(true);
    return true;
  };

  return (
    <div className={styles.contactFormContainer}>
      <Link to={`${navigationTo}${state ? state.search : ''}`} className={styles.backButton}>
        <KiteIcon icon={'ki-chevron-left'} />
        {t('back')}
      </Link>
      <div className={styles.contactForm}>
        <Scrollbar customClass={styles.scrollbar} forceScroll={false}>
          <KiteCard className={styles.newContact}>
            <h2 className="kite-type-style--title-6">{t('editHeader')}</h2>
            <p>{t('editDesc')}</p>
            {alertDescription && (
              <KiteAlert
                className={styles.alert}
                type="error"
                description={t(alertDescription)}
                level="page"
                dismissible
                onClose={() => setAlertDescription('')}
              />
            )}
            <form className={styles.formFields}>
              <Name
                updateFormValues={updateFormValues}
                values={{ firstName: formValues.firstName, lastName: formValues.lastName }}
                errors={errors}
                setErrors={setErrors}
                ref={firstChildRef}
              />
              <AltName
                updateFormValues={updateFormValues}
                values={{ displayName: formValues.displayName, nickName: formValues.nickName }}
                errors={errors}
                setErrors={setErrors}
              />
              <Employment
                updateFormValues={updateFormValues}
                values={{ title: formValues.title, company: formValues.company }}
                errors={errors}
                setErrors={setErrors}
              />
              <Email
                isNew={false}
                showMore={true}
                updateFormValues={updateFormValues}
                values={{ emails: formValues.emails }}
                errors={errors}
                setErrors={setErrors}
              />
              <Phone
                updateFormValues={updateFormValues}
                values={{
                  homePhone: formValues.homePhone,
                  workPhone: formValues.workPhone,
                  mobilePhone: formValues.mobilePhone,
                }}
                errors={errors}
                setErrors={setErrors}
              />
              <Address
                updateFormValues={updateFormValues}
                values={{
                  homeAddress: formValues.homeAddress,
                  workAddress: formValues.workAddress,
                }}
                errors={errors}
                setErrors={setErrors}
              />
              <DOB
                updateFormValues={updateFormValues}
                values={{
                  dateOfBirth: formValues.dateOfBirth,
                }}
                errors={errors}
                setErrors={setErrors}
              />
              <URL
                updateFormValues={updateFormValues}
                values={{
                  url: formValues.url,
                }}
                errors={errors}
                setErrors={setErrors}
              />
              <Notes
                updateFormValues={updateFormValues}
                values={{
                  notes: formValues.notes,
                }}
                errors={errors}
                setErrors={setErrors}
              />
            </form>
            <div className={styles.buttonBar}>
              <SaveCancel
                draft={formValues}
                isChanged={() => !isEqual(formValues, initialState)}
                isSaved={isSaved}
                isValidate={isValid}
                isCancelled={isCancelled}
              />
            </div>
          </KiteCard>
        </Scrollbar>
      </div>
    </div>
  );
};

export default ContactUpdate;
