import { useEffect } from 'react';
import { useAppDispatch } from 'hooks';
import { updateIsOpen, updateNavBlocking } from 'slices/app';
import { TrackingHook, useTracking } from 'react-tracking';
import SaveCancel from 'components/save-cancel';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { Tx } from 'hooks/useBlocker';
import { useTarget } from 'components/common/focus/FocusContext';
import { useDialogTranslation } from 'translation/hooks';

interface Props {
  okEvent: string;
  cancelEvent: string;
  modalViewEvent: string;
  tx: Tx;
}

const UnsavedChangesModal: React.FC<Props> = ({ okEvent, cancelEvent, modalViewEvent, tx }) => {
  const { t } = useDialogTranslation('unsavedChangesModalCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'unsavedChanges' });
  const focusOpener = useTarget('modal');
  const dispatch = useAppDispatch();

  useEffect(() => {
    trackEvent({ event: modalViewEvent });
  }, []);

  const handleConfirm = async (): Promise<boolean> => {
    trackEvent({ event: okEvent });
    dispatch(updateNavBlocking(false));
    setTimeout(() => {
      tx.retry();
      dispatch(updateIsOpen(false));
    }, 0);

    return true;
  };

  const handleCancel = (): boolean => {
    trackEvent({ event: cancelEvent });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const saveCancelProps = {
    isSaved: handleConfirm,
    isCancelled: handleCancel,
    isValidate: () => true,
    draft: '',
    isChanged: () => true,
    saveButtonText: t('confirmBtnText'),
    cancelLabel: t('cancelBtnText'),
  };

  return (
    <ModalContentWrapper>
      <h5>{t('header')}</h5>
      <p>{t('description')}</p>
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default UnsavedChangesModal;
