export const syncContactsCopy = {
  header: '',
  description: '',
  confirmBtnText: '',
  loadingHeader: '',
  loadingDescription: '',
  progressLabel: '',
  errorHeader: '',
  errorDescription: '',
  successHeader: '',
  ok: '',
};
