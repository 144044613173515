import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from 'components/setting-block-sender/styles/BlockSender.module.scss';
import { KiteAlert, KiteButton, KiteCard, KiteGrid, KiteGridCell, KiteIcon, KiteTextInput } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import isEmail from 'validator/lib/isEmail';
import SaveCancel from 'components/save-cancel';
import { cloneDeep, isEqual, sortBy } from 'lodash';
import { useUpdateBlockedSenderMutation } from 'generated/graphql';
import { UpdateBlockedSenderResponse, updateBlockSenderThunk } from 'thunks/setting/updateBlockedSenderThunk';
import { updateDraftBlockSenderList } from 'slices/setting/blockSender';
import Pagination from 'components/pagination';
import { TrackingHook, useTracking } from 'react-tracking';
import { HelixSettingsPayloadInterface } from 'libs/tracking/adapters/helix/events/submitSettingsEvent';
import { SenderItemInterface } from 'slices/setting/approveSender';
import { getApprovedBlockedSendersRemaining } from 'utils/getBlockedSendersRemaining';
import { checkSenderConflict, isDuplicate, isValidDomainOrEmail } from 'utils/safeBlockSenderCommon';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import useNavBlockingDraft from 'hooks/useNavBlockingDraft';
import { updateNavBlocking } from 'slices/app';
import { ErrorsKeys, PageNSTranslationFileKeys, usePageTranslation } from 'translation/hooks';

const errors: { [key: string | number]: ErrorsKeys } = {
  INTERNAL_SERVER_ERROR: 'unableToSaveErr',
  LDAP_EXCEPTION: 'unableToSaveErr',
  500: 'unableToSaveErr',
  422: 'invalidEmailDomainErr',
};

const SettingBlockSender: React.FC = () => {
  const { t } = usePageTranslation('blockedSendersCopy');
  const { trackEvent }: TrackingHook<HelixSettingsPayloadInterface> = useTracking({ page: 'settings' });

  const { senderList, draftSenderList, error, approvedSenders } = useAppSelector((state) => ({
    ...state.blockSenderSlice,
    approvedSenders: state.approveSenderSlice.senderList,
  }));

  const [initialBlockedSenderList, setInitialBlockedSenderList] = useState<SenderItemInterface>({});
  const [addBlockSenderValue, setBlockSenderValue] = useNavBlockingDraft('');
  const [errorMessage, setErrorMessage] = useState<PageNSTranslationFileKeys<'blockedSendersCopy'>>('');
  const [pageLevelErrorMessage, setPageLevelErrorMessage] = useState<ErrorsKeys | ''>('');
  const [isJustSave, setIsJustSave] = useState(false);
  const [searchedResult, setSearchResult] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState('');

  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const dispatch = useAppDispatch();
  const [updateBlockedSenderHook] = useUpdateBlockedSenderMutation();

  const draftRemainingCount = getApprovedBlockedSendersRemaining(draftSenderList);

  useMemo(() => {
    trackEvent({ event: 'blockedSenderPageViewInit' });
  }, []);

  useEffect(() => {
    trackEvent({ event: 'blockedSenderPageView' });
    return () => {
      dispatch(updateDraftBlockSenderList({}));
    };
  }, []);

  useEffect(() => {
    if (error) {
      setPageLevelErrorMessage(errors[error]);
    }
  }, [error]);

  useEffect(() => {
    if (draftRemainingCount <= 0) {
      trackEvent({ event: 'blockedSenderError', errorMessage: 'maxNumberOfEntriesReached' });
    }
    const hasDraftsToBeDeleted = Object.values(draftSenderList).filter(({ isDeleted }) => isDeleted).length;
    if (Object.keys(draftSenderList).length != Object.keys(senderList).length || hasDraftsToBeDeleted) {
      dispatch(updateNavBlocking(true));
    } else {
      dispatch(updateNavBlocking(!!addBlockSenderValue));
    }
  }, [draftSenderList]);

  useEffect(() => {
    if (senderList.length > 0) {
      const initialTransformedList: SenderItemInterface = {};

      sortBy(senderList).forEach((item) => {
        initialTransformedList[item.toLowerCase()] = {
          value: item,
          isEmail: isEmail(item),
          isDeleted: false,
          isNew: false,
        };
      });

      let blockedSenderListToRender = initialTransformedList;

      if (Object.keys(draftSenderList).length && !isEqual(initialTransformedList, draftSenderList)) {
        blockedSenderListToRender = draftSenderList;
      }
      setTotalItems(Object.keys(blockedSenderListToRender).length);
      setInitialBlockedSenderList(initialTransformedList);
      dispatch(updateDraftBlockSenderList(blockedSenderListToRender));
    }
  }, [senderList, isJustSave]);

  const onPageSelect = (pageNumber: number): void => {
    setCurrentPage(pageNumber);
  };

  const onItemsPerPageChange = (newItemsPerPage: number): void => {
    setCurrentPage(1);
    setItemsPerPage(newItemsPerPage);
  };

  const onNextPage = (): void => {
    setCurrentPage(currentPage + 1);
  };

  const onPrevPage = (): void => {
    setCurrentPage(currentPage - 1);
  };

  const validate = (value: string, track?: boolean): boolean => {
    if (!isValidDomainOrEmail(value)) {
      track && trackEvent({ event: 'blockedSenderError', errorMessage: 'fieldValidationError' });
      setErrorMessage('invalidEmailDomainErr');
      return false;
    }

    if (checkSenderConflict(value, approvedSenders)) {
      track && trackEvent({ event: 'blockedSenderError', errorMessage: 'senderConflict' });
      setErrorMessage('blockSenderConflict');
      return false;
    }

    if (isDuplicate(value, draftSenderList)) {
      track && trackEvent({ event: 'blockedSenderError', errorMessage: 'duplicateSender' });
      setErrorMessage('duplicateSender');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const handleAddBlockSenderChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setBlockSenderValue(e.target.value);
    if (errorMessage) {
      validate(e.target.value);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const isValid = validate(e.target.value);
    if (!isValid && !errorMessage) {
      e.currentTarget.focus();
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInput(e.target.value);
    const senders = Object.keys(draftSenderList);

    if (e.target.value.length > 1) {
      const searchFilter = new RegExp(`${e.target.value}`, 'i');
      const foundItems = senders.filter((item) => searchFilter.test(item));

      setTotalItems(foundItems.length);
      setSearchResult(foundItems);

      if (!foundItems.length) {
        trackEvent({ event: 'blockedSenderNoResults' });
      }
      return;
    }

    setSearchResult([]);
    setTotalItems(senders.length);
  };

  const isChanged = (): boolean => {
    return !isEqual(initialBlockedSenderList, draftSenderList);
  };

  const isValidate = (): boolean => {
    return true;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'blockedSenderCancel' });
    dispatch(updateDraftBlockSenderList(initialBlockedSenderList));

    return true;
  };

  const inputRef = useRef<HTMLInputElement | null>(null);
  const focusInputField = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
    if (e.key === 'Enter' && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const isJustSaved = (): boolean => {
    return isJustSave;
  };

  const isSaved = async (): Promise<boolean> => {
    const addedEmails: string[] = [];
    const addedDomains: string[] = [];
    const removedEmails: string[] = [];
    const removedDomains: string[] = [];

    trackEvent({ event: 'blockedSenderSave' });
    const senders = Object.keys(draftSenderList);

    if (senders.length) {
      senders.forEach((sender) => {
        if (draftSenderList[sender].isNew && !draftSenderList[sender].isDeleted) {
          draftSenderList[sender].isEmail
            ? addedEmails.push(sender.toLowerCase())
            : addedDomains.push(sender.toLowerCase());
        }

        if (draftSenderList[sender].isDeleted && !draftSenderList[sender].isNew) {
          draftSenderList[sender].isEmail
            ? removedEmails.push(sender.toLowerCase())
            : removedDomains.push(sender.toLowerCase());
        }
      });
    }

    const result = (await dispatch(
      updateBlockSenderThunk({
        updateBlockedSenderHook,
        payload: {
          blockedSender: {
            added: { emails: addedEmails, domains: addedDomains },
            removed: { emails: removedEmails, domains: removedDomains },
          },
        },
      })
    )) as { payload: UpdateBlockedSenderResponse };

    if (result.payload.success) {
      setErrorMessage('');
      setPageLevelErrorMessage('');
      setBlockSenderValue('');
      setIsJustSave(true);
      trackEvent({ event: 'blockedSenderSuccessNotification' });
      return true;
    }

    trackEvent({ event: 'blockedSenderError', errorMessage: 'generalUnableToSave' });
    return false;
  };

  const handleAddBlockSender = (): void => {
    trackEvent({ event: 'blockedSenderAdd' });
    if (errorMessage || !addBlockSenderValue) {
      validate(addBlockSenderValue, true);
      return;
    }
    const newDraft: SenderItemInterface = {};
    newDraft[addBlockSenderValue] = {
      value: addBlockSenderValue,
      isEmail: isEmail(addBlockSenderValue),
      isDeleted: false,
      isNew: true,
    };

    dispatch(updateDraftBlockSenderList({ ...newDraft, ...draftSenderList }));
    setIsJustSave(false);
    setBlockSenderValue('');
    setSearchInput('');
    setTotalItems(Object.keys(draftSenderList).length + 1);
    setCurrentPage(1);
  };

  const handleRemoveResetSender = (key: string): void => {
    const draft = cloneDeep(draftSenderList);
    draft[key].isDeleted = !draft[key].isDeleted;
    dispatch(updateDraftBlockSenderList(draft));
    setIsJustSave(false);
    if (draft[key].isDeleted) {
      trackEvent({ event: 'blockedSenderDelete' });
    }
  };

  const hasSavedItemsOrDraft = Object.keys(draftSenderList).length;

  const renderBlockSenderList = (): JSX.Element[] => {
    const items = Object.keys(draftSenderList);
    const senders = items.reduce((elements: JSX.Element[], item) => {
      if (searchInput.length >= 2 && !searchedResult.includes(item)) {
        return elements;
      }

      elements.push(
        <li key={`${item}`}>
          <KiteGrid>
            <KiteGridCell className={styles.emailSection} col={3} xs={3} md={11}>
              <KiteIcon
                className={styles.icon}
                icon={isEmail(draftSenderList[item].value) ? 'ki-mail' : 'ki-internet'}
                size="20px"
              />
              <span
                className={`${styles.emailText} ${
                  (draftSenderList[item].isDeleted && styles.isDeleted) || (draftSenderList[item].isNew && styles.isNew)
                }`}
              >
                {draftSenderList[item].value}
              </span>
              <span className={styles.draftStatus}>
                {(draftSenderList[item].isDeleted && t('isDeleted')) || (draftSenderList[item].isNew && t('isNew'))}
              </span>
            </KiteGridCell>
            <KiteGridCell className={styles.iconSection} col={1} xs={1} md={1}>
              <KiteButton
                variant={'borderless'}
                className={styles.actionButton}
                disabled={draftSenderList[item].isDeleted && draftRemainingCount <= 0}
                aria-disabled={draftSenderList[item].isDeleted && draftRemainingCount <= 0}
                onClick={() => handleRemoveResetSender(item)}
              >
                {draftSenderList[item].isDeleted ? (
                  <KiteIcon ariaLabel={`reset ${item}`} icon="ki-restart" size="20px" />
                ) : (
                  <KiteIcon className={styles.trashIcon} ariaLabel={`remove ${item}`} icon="ki-trash" size="20px" />
                )}
              </KiteButton>
            </KiteGridCell>
          </KiteGrid>
        </li>
      );
      return elements;
    }, []);
    return senders.slice(itemsPerPage * (currentPage - 1), itemsPerPage * currentPage);
  };

  return (
    <div className={styles.blockSenderContainer}>
      <KiteCard className={styles.card}>
        <div className={styles.title}>
          <AutoFocusHeader content={t('title')} />
        </div>
        <span className={styles.description}>{t('description')}</span>
        {pageLevelErrorMessage && (
          <KiteAlert
            className={styles.pageLevelError}
            type={'error'}
            level={'page'}
            description={t(pageLevelErrorMessage)}
          />
        )}
        {draftRemainingCount <= 0 ? (
          <KiteAlert
            className={styles.pageLevelError}
            description={t('maxListCountMessage')}
            level={'page'}
            type={'error'}
          />
        ) : null}
        <div className={styles.form}>
          <div>{t('addLabel')}</div>
          <KiteGrid>
            <KiteGridCell col={6}>
              <KiteTextInput
                aria-label={t('addLabel')}
                value={addBlockSenderValue}
                errorMessage={`${t(errorMessage)}`}
                onChange={handleAddBlockSenderChange}
                onBlur={handleOnBlur}
                disabled={draftRemainingCount === 0}
                aria-disabled={draftRemainingCount === 0}
                placeholder={'e.g. example@domain.com'}
                inputRef={inputRef}
                ariaLive="polite"
              />
            </KiteGridCell>
            <KiteGridCell col={6}>
              <KiteButton
                className={styles.addButton}
                icon={'ki-plus'}
                variant={'borderless'}
                onClick={handleAddBlockSender}
                disabled={draftRemainingCount <= 0}
                aria-disabled={draftRemainingCount <= 0}
                onKeyDown={(e) => {
                  handleAddBlockSender();
                  focusInputField(e);
                }}
              >
                {t('addButton')}
              </KiteButton>
            </KiteGridCell>
          </KiteGrid>
          <p className={styles.maxInputHint}>
            {t('maxListCountHint', { amount: draftRemainingCount ? draftRemainingCount : 'no' })}
          </p>
          <hr />
          {hasSavedItemsOrDraft ? (
            <div className={styles.searchButtonContainer}>
              <input
                className={styles.searchBarInput}
                type="search"
                aria-label={t('searchPlaceholder')}
                role="edit"
                value={searchInput}
                onChange={handleSearchChange}
                placeholder={t('searchPlaceholder')}
              />
              <KiteIcon className={styles.searchIcon} icon="ki-search" fill="#004366" size="24px" />
            </div>
          ) : null}
        </div>
        {hasSavedItemsOrDraft ? (
          <>
            <div className={styles.lists}>
              {searchInput.length >= 2 && searchedResult.length === 0 ? (
                <>
                  <p className={styles.noResultText} aria-live="polite">
                    {t('noResults')}
                  </p>
                  <hr />
                </>
              ) : (
                <ul className={styles.blockSenderList}>
                  {searchInput.length > 2 && (
                    <span aria-live="polite" className={`srOnly`}>
                      search results available below
                    </span>
                  )}
                  {renderBlockSenderList()}
                </ul>
              )}
            </div>
            {searchInput.length >= 2 && searchedResult.length === 0 ? null : (
              <div className={styles.paginationBlockSender}>
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  onPageSelect={onPageSelect}
                  onItemsPerPageChange={onItemsPerPageChange}
                  onNextPage={onNextPage}
                  onPrevPage={onPrevPage}
                />
                <hr />
              </div>
            )}
          </>
        ) : (
          <p className={styles.emptyList}>{t('emptySenderList')}</p>
        )}
        <SaveCancel
          draft={draftSenderList}
          isChanged={isChanged}
          isSaved={isSaved}
          isValidate={isValidate}
          isCancelled={isCancelled}
          isJustSaved={isJustSaved}
          shouldDisableCancel={true}
        />
      </KiteCard>
    </div>
  );
};

export default SettingBlockSender;
