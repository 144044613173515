import { isValidEmail } from 'utils/composeMailFormHelper';

const getReadReceiptReplyToAddress = (replyToString: string): string => {
  if (isValidEmail(replyToString)) {
    return replyToString;
  }

  const matchArr = replyToString.match(/<(.*?)>/) || [];

  return matchArr[1] ?? '';
};

export default getReadReceiptReplyToAddress;
