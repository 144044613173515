import React, { useCallback, useEffect, useRef } from 'react';
import styles from 'components/compose-input-field/styles/ComposeInputField.module.scss';
import { KiteButton, KiteIcon } from '@kite/react-kite';
import { useAppSelector, useAppDispatch } from 'hooks';
import { addBcc, removeBccByIndex, resetBcc, updateComposeFormError, updateIsBccFieldEnabled } from 'slices/compose';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import splitEmailDisplayName from 'utils/splitEmailDisplayName';
import useContactSearch from 'hooks/useContactSearch';
import getSuggestionComponent from 'components/compose-input-field/ContactSuggestion';
import { getDisplayNameFallback } from 'components/compose-input-field/utils';
import ContactTag from 'components/compose-input-field/ContactTag';
import { ReactTagsFocus } from 'components/compose-input-field/ToField';
import { AutoSaveDraftHandler } from 'components/compose-form';
import { composeMailFormBccValidator } from 'utils/composeMailFormHelper';
import { usePageTranslation } from 'translation/hooks';
import { updateIsOpen, updateModalName, updateModalProps } from 'slices/app';
import { updateModalOpener } from 'components/common/focus/FocusContext';

const BccField: React.FC<AutoSaveDraftHandler> = ({ autoSaveDraftHandler }) => {
  const { t } = usePageTranslation('composeCopy');
  const activeTabIndex = useAppSelector((state) => state.composeSlice.activeTabIndex);
  const composeCollection = useAppSelector((state) => state.composeSlice.composeCollection);
  const composeForm = composeCollection[activeTabIndex];
  const dispatch = useAppDispatch();
  const bccFieldRef = useRef<ReactTagsFocus>(null);
  const bccFieldTags = composeCollection[activeTabIndex]?.bcc;
  const [suggestions, suggestionsWithContacts, suggestionsWithLabels, onContactSearch] = useContactSearch(bccFieldTags);
  const updateModalRef = updateModalOpener();

  useEffect(() => {
    if (bccFieldRef) {
      bccFieldRef.current?.focusInput();
    }
  }, []);

  useEffect(() => {
    if (
      composeCollection[activeTabIndex] &&
      composeCollection[activeTabIndex].isChanged &&
      composeMailFormBccValidator(composeForm) &&
      !composeForm.isSavePending
    ) {
      autoSaveDraftHandler('bcc', bccFieldTags, composeCollection[activeTabIndex]);
    }
  }, [bccFieldTags?.length]);

  const onDelete = useCallback((tagIndex) => {
    dispatch(removeBccByIndex(tagIndex));
  }, []);

  const onAddition = useCallback(
    (newTag: Tag): void => {
      if (bccFieldTags?.find((c) => c.address === newTag.name.trim())) {
        dispatch(
          updateComposeFormError({
            type: 'error',
            index: activeTabIndex,
            errorIndex: 'compose_form_duplicate_emails',
            message: 'composeFormDuplicateEmails',
          })
        );
        return;
      }
      const contact = suggestionsWithContacts?.find((c) => c.id === newTag.id);
      if (contact) {
        const updatedTag = {
          name: getDisplayNameFallback(contact),
          address: newTag.name.trim(),
        };
        dispatch(addBcc(updatedTag));
        return;
      }

      const label = suggestionsWithLabels?.find((l) => l.id === newTag.id);
      if (label) {
        label.contacts.forEach((contact) => {
          contact.emails?.forEach((email) => {
            if (!bccFieldTags?.find((c) => c.address === email.emailAddress)) {
              const updatedTag = {
                name: getDisplayNameFallback(contact),
                address: email.emailAddress,
              };
              dispatch(addBcc(updatedTag));
            }
          });
        });

        return;
      }

      const updatedTag = splitEmailDisplayName(newTag.name.trim());

      dispatch(addBcc(updatedTag));
    },
    [suggestions]
  );

  const bccFieldFormatted = bccFieldTags?.map((tag, index) => {
    return { id: index, name: tag.name || tag.address, email: tag.address };
  });

  const closeBccField = (): void => {
    dispatch(resetBcc());
    dispatch(updateIsBccFieldEnabled(false));
  };

  const handleOpenAddressBook = (): void => {
    dispatch(updateModalProps({ inputField: 'bcc', isAddressBook: true }));
    dispatch(updateModalName('addressBook'));
    dispatch(updateIsOpen(true));
  };

  return (
    <span className={styles.inputFieldContainer}>
      <span className={styles.label}>
        <label className={styles.label} htmlFor="input">
          <KiteButton
            type="button"
            variant={'borderless'}
            className={styles.addressBookButton}
            onClick={(e) => {
              updateModalRef(e.currentTarget);
              handleOpenAddressBook();
            }}
            aria-label="import contacts from address book"
          >
            {t('bccField')}
          </KiteButton>
        </label>
      </span>
      <span role="input" className={styles.tagInputList}>
        <ReactTags
          allowNew={true}
          addOnBlur
          tags={bccFieldFormatted}
          placeholderText={''}
          suggestions={suggestions}
          onDelete={onDelete}
          onAddition={onAddition}
          onInput={onContactSearch}
          minQueryLength={3}
          suggestionsFilter={() => true}
          tagComponent={ContactTag}
          suggestionComponent={getSuggestionComponent(suggestionsWithContacts || [], suggestionsWithLabels || [])}
          ref={bccFieldRef}
        />
      </span>
      <span className={styles.actionIcons}>
        <button type="button" className={styles.xButton} onClick={closeBccField} aria-label="remove blind carbon copy">
          <KiteIcon icon="ki-x" size="18px" />
        </button>
      </span>
    </span>
  );
};

export default BccField;
