import { createAsyncThunk } from '@reduxjs/toolkit';
import { getServerEndpoint } from 'app-constants';
import { appConstants } from 'appConstants';
import axios from 'axios';
import { trackCall } from 'utils/apiTracking';
import buildVisitAndTraceIdHeaders from 'utils/buildVisitAndTraceIdHeaders';

interface LoginSsoArgs {
  payload: {
    code: string;
    grantType: 'authorization_code' | 'refresh_token';
  };
}

export interface LoginSsoThunkSuccess {
  accessToken: string;
  refreshToken: string;
  idToken: string;
  email: string;
  namespace: string;
  accountNumber: string;
  accountGuid: string;
  footprint: string;
  expiresIn: number;
  authReportingId: string;
}

interface LoginSsoThunkResponse {
  statusCode: number;
  success?: LoginSsoThunkSuccess;
  error?: Error;
}

export const loginSsoThunk = createAsyncThunk('loginSso', async (arg: LoginSsoArgs): Promise<LoginSsoThunkResponse> => {
  const startTime = new Date().getTime();

  const headers = buildVisitAndTraceIdHeaders();

  try {
    const response = await axios({
      method: 'post',
      url: `${getServerEndpoint()}${appConstants.LOGIN_URL_PATH}`,
      data: arg.payload,
      withCredentials: true,
      headers,
    });

    const responseBodySize = new TextEncoder().encode(JSON.stringify(response.data)).length;
    trackCall(response, startTime, headers['X-WEBMAIL-TRACE-ID'], responseBodySize);

    return {
      statusCode: response.status,
      success: {
        accessToken: response.data.accessToken,
        expiresIn: response.data.expiresIn,
        email: response.data.email,
        footprint: response.data.footprint,
        accountNumber: response.data.accountNumber,
        namespace: response.data.namespace,
        refreshToken: response.data.refreshToken,
        idToken: response.data.idToken,
        authReportingId: response.data.authReportingId,
        accountGuid: response.data.accountGuid,
      },
    };
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const errorResponseSize = new TextEncoder().encode(JSON.stringify(err.response?.data)).length;
      trackCall(err, startTime, headers['X-WEBMAIL-TRACE-ID'], errorResponseSize);
      return {
        statusCode: err.response?.status || 500,
        error: new Error(err.response?.data.message || err.message),
      };
    }

    return {
      statusCode: 500,
      error: err as Error,
    };
  }
});
