import React from 'react';
import { KiteGrid } from '@kite/react-kite';
import styles from 'components/footer/styles/Footer.module.scss';
import Legal from 'components/footer/Legal';
import SpectrumLinks from 'components/footer/SpectrumLinks';

interface FooterInterface {
  largeFooter?: boolean;
  showFooter?: boolean;
}

const Footer: React.FC<FooterInterface> = (props) => {
  const loggedInFooterStyles = !props.largeFooter ? styles.loggedIn : styles.loggedOut;

  return (
    <>
      <aside className={`${styles.footer} ${loggedInFooterStyles}`}>
        <KiteGrid className={styles.pageFooter}>
          {props.largeFooter && <SpectrumLinks />}
          <Legal largeFooter={!props.largeFooter} />
        </KiteGrid>
      </aside>
    </>
  );
};

export default Footer;
