import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Success, ContactRollbackMutationFn } from 'generated/graphql';

export interface ContactRollbackResponse {
  success?: Success;
  error?: ApolloError | undefined;
}

export interface contactRollbackArg {
  contactRollbackHook: ContactRollbackMutationFn;
}

export const contactRollbackThunk = createAsyncThunk(
  'contactRollback',
  async (arg: contactRollbackArg): Promise<ContactRollbackResponse> => {
    try {
      const response = await arg.contactRollbackHook();
      return {
        success: response.data?.contactRollback,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
