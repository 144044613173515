import Helix from '@charter/helix/build/portals/index.min';
import { LinkItemInterface } from 'app-constants';
import { HelixEventFn, HelixPayloadInterface } from 'libs/tracking/adapters/helix/helixAdapter';

export interface HelixLoginPayloadInterface extends HelixPayloadInterface {
  email?: string;
  url?: string;
  errorCode?: string | number;
  rememberMe?: boolean;
  emailErrorMessage?: string;
  passwordErrorMessage?: string;
  apiError?: string | null;
  linkObject?: LinkItemInterface;
  authReportingId?: string;
  accountNumber?: string;
  footprint?: string;
  accountGuid?: string;
  authSource?: string;
}

const submitLoginEvent: HelixEventFn<HelixLoginPayloadInterface> = (payload) => {
  const { event } = payload;
  switch (event) {
    case 'loginStart': {
      Helix.track('WEBMAIL_loginStart_autoAccess');
      break;
    }
    case 'loginStopSuccess': {
      Helix.track('WEBMAIL_userConfigSet', {
        accountNumber: payload.accountNumber,
        accountDetailsMso: payload.footprint,
        accountGUID: payload.accountGuid,
      });
      Helix.track('WEBMAIL_inVisitOauthRefresh_Success', {
        accountOauthToken: payload.authReportingId,
        opSuccess: true,
        visitAdditionalInformation: payload.authSource,
      });
      break;
    }
    case 'loginStopFailure': {
      Helix.track('WEBMAIL_inVisitOauthRefresh_Failure', {
        appErrorCode: payload.errorCode,
        opSuccess: false,
        visitAdditionalInformation: payload.authSource,
      });
      break;
    }
    case 'updateUserConfig': {
      Helix.track('WEBMAIL_userConfigSet', {
        accountNumber: payload.accountNumber,
        accountDetailsMso: payload.footprint,
        accountGUID: payload.accountGuid,
      });
      break;
    }
    case 'loginErrorModalView': {
      Helix.track('WEBMAIL_modalView_unableToLoadEmail', {
        modalText: 'Unable To Load Email',
      });
      break;
    }
    case 'loginErrorOkButton': {
      Helix.track('WEBMAIL_selectAction_unableToLoadEmailModal_okay');
      break;
    }

    default: {
      console.log('Event Case For Login Not Found', event);
      return null;
    }
  }
  return null;
};

export default submitLoginEvent;
