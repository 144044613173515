import { filterSchema } from 'libs/validations/filter';
import { ValidationError } from 'yup';
import { FilterFormInterface } from 'components/setting-filter-form/initialState';

interface FormError {
  [key: string]: string;
}

export const isFilterFormValid = (formValues: FilterFormInterface): [boolean, FormError] => {
  try {
    filterSchema.validateSync(formValues, {
      recursive: true,
      abortEarly: false,
    });
  } catch (err) {
    let formErrors: FormError = {};
    if (err instanceof ValidationError) {
      err.inner.forEach((error) => {
        formErrors = { ...formErrors, [error.path as string]: error.message };
      });
    }

    return [false, formErrors];
  }

  return [true, {}];
};
