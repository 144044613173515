import { getDraggablePanesEnabled } from 'app-constants';

interface GetMinMaxSizesInterface {
  minSize: number;
  maxSize: number;
}

const getMinMaxSizes = (isThreePaneEnabled: boolean): GetMinMaxSizesInterface[] => {
  let firstPaneMin = 260;
  let firstPaneMax = 260;
  let secondPaneMin = 336;
  let secondPaneMax = isThreePaneEnabled ? 336 : Infinity;
  const thirdPaneMin = 260;
  const thirdPaneMax = Infinity;

  if (getDraggablePanesEnabled()) {
    firstPaneMin = 260 / 2;
    firstPaneMax = Infinity;
    secondPaneMin = 336 / 2;
    secondPaneMax = Infinity;
  }

  const firstPaneProps = {
    minSize: firstPaneMin,
    maxSize: firstPaneMax,
  };

  const secondPaneProps = {
    minSize: secondPaneMin,
    maxSize: secondPaneMax,
  };

  const thirdPaneProps = {
    minSize: thirdPaneMin,
    maxSize: thirdPaneMax,
  };

  return [firstPaneProps, secondPaneProps, thirdPaneProps];
};

export default getMinMaxSizes;
