import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { History, Transition } from 'history';

type ExtendNavigator = Navigator & Pick<History, 'block'>;
export type Tx = Transition & { retry: () => void };

const useBlocker = (blocker: (tx: Transition) => void, when = false): void => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unblock = (navigator as any as ExtendNavigator).block((tx) => {
      const autoUnblockingTx: Tx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    /* eslint-disable consistent-return */
    return unblock;
  }, [navigator, blocker, when]);
};

export default useBlocker;
