import React, { useCallback, useEffect, useRef } from 'react';
import styles from 'components/compose-input-field/styles/ComposeInputField.module.scss';
import { useAppSelector, useAppDispatch } from 'hooks';
import {
  updateIsCcFieldEnabled,
  updateIsBccFieldEnabled,
  addTo,
  removeToByIndex,
  updateComposeFormError,
} from 'slices/compose';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import splitEmailDisplayName from 'utils/splitEmailDisplayName';
import useContactSearch from 'hooks/useContactSearch';
import getSuggestionComponent from 'components/compose-input-field/ContactSuggestion';
import { getDisplayNameFallback } from 'components/compose-input-field/utils';
import ContactTag from 'components/compose-input-field/ContactTag';
import { AutoSaveDraftHandler } from 'components/compose-form';
import { composeMailFormToValidator } from 'utils/composeMailFormHelper';
import { usePageTranslation } from 'translation/hooks';
import { KiteButton } from '@kite/react-kite';
import { updateIsOpen, updateModalName, updateModalProps } from 'slices/app';
import { updateModalOpener } from 'components/common/focus/FocusContext';
import { TrackingHook, useTracking } from 'react-tracking';

export interface ReactTagsFocus extends ReactTags {
  focusInput: () => void;
}

const ToField: React.FC<AutoSaveDraftHandler> = ({ autoSaveDraftHandler }) => {
  const { t } = usePageTranslation('composeCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'compose' });

  const activeTabIndex = useAppSelector((state) => state.composeSlice.activeTabIndex);
  const composeCollection = useAppSelector((state) => state.composeSlice.composeCollection);
  const activeMinimizedIndex = useAppSelector((state) => state.composeSlice.activeMinimizedIndex);
  const [lastIndex] = Object.entries(composeCollection).slice(-2);

  const isCcFieldEnabled = composeCollection[activeTabIndex || lastIndex[0]]?.isCcFieldEnabled || false;
  const isBccFieldEnabled = composeCollection[activeTabIndex || lastIndex[0]]?.isBccFieldEnabled || false;
  const toFieldTags = composeCollection[activeTabIndex]?.to;
  const composeForm = composeCollection[activeTabIndex];

  const dispatch = useAppDispatch();
  const toFieldRef = useRef<ReactTagsFocus>(null);

  const [suggestions, suggestionsWithContacts, suggestionsWithLabels, onContactSearch] = useContactSearch(toFieldTags);
  const updateModalRef = updateModalOpener();

  useEffect(() => {
    if (toFieldRef && !activeMinimizedIndex) {
      toFieldRef.current?.focusInput();
    }
  }, [activeTabIndex, activeMinimizedIndex]);

  useEffect(() => {
    if (
      composeCollection[activeTabIndex] &&
      composeCollection[activeTabIndex].isChanged &&
      composeMailFormToValidator(composeForm) &&
      !composeCollection[activeTabIndex].isSavePending
    ) {
      autoSaveDraftHandler('to', toFieldTags, composeCollection[activeTabIndex]);
    }
  }, [toFieldTags?.length]);

  const onDelete = useCallback((tagIndex) => {
    dispatch(removeToByIndex(tagIndex));
  }, []);

  const onAddition = useCallback(
    (newTag: Tag): void => {
      if (toFieldTags?.find((c) => c.address === newTag.name.trim())) {
        dispatch(
          updateComposeFormError({
            type: 'error',
            index: activeTabIndex,
            errorIndex: 'compose_form_duplicate_emails',
            message: 'composeFormDuplicateEmails',
          })
        );
        return;
      }
      const contact = suggestionsWithContacts?.find((c) => c.id === newTag.id);
      if (contact) {
        const updatedTag = {
          name: getDisplayNameFallback(contact),
          address: newTag.name.trim(),
        };
        dispatch(addTo(updatedTag));
        return;
      }

      const label = suggestionsWithLabels?.find((l) => l.id === newTag.id);
      if (label) {
        label.contacts.forEach((contact) => {
          contact.emails?.forEach((email) => {
            if (!toFieldTags?.find((c) => c.address === email.emailAddress)) {
              const updatedTag = {
                name: getDisplayNameFallback(contact),
                address: email.emailAddress,
              };
              dispatch(addTo(updatedTag));
            }
          });
        });

        return;
      }

      const updatedTag = splitEmailDisplayName(newTag.name.trim());

      dispatch(addTo(updatedTag));
    },
    [suggestions]
  );

  const toFieldFormatted = toFieldTags?.map((tag, index) => {
    return { id: index, name: tag.name || tag.address, email: tag.address };
  });

  const enableCcField = (): void => {
    trackEvent({ event: 'addCc' });
    dispatch(updateIsCcFieldEnabled(true));
  };

  const enableBccField = (): void => {
    trackEvent({ event: 'addBcc' });
    dispatch(updateIsBccFieldEnabled(true));
  };

  const handleOpenAddressBook = (): void => {
    dispatch(updateModalProps({ inputField: 'to', isAddressBook: true }));
    dispatch(updateModalName('addressBook'));
    dispatch(updateIsOpen(true));
  };

  return (
    <span className={styles.inputFieldContainer}>
      <span className={styles.label}>
        <label className={styles.label} htmlFor="input">
          <KiteButton
            type="button"
            variant={'borderless'}
            className={styles.addressBookButton}
            onClick={(e) => {
              updateModalRef(e.currentTarget);
              handleOpenAddressBook();
            }}
            aria-label="import contacts from address book"
          >
            {t('toField')}
          </KiteButton>
        </label>
      </span>
      <span role="input" className={styles.tagInputList}>
        <ReactTags
          allowNew={true}
          addOnBlur
          tags={toFieldFormatted}
          suggestions={suggestions}
          onDelete={onDelete}
          onAddition={onAddition}
          placeholderText={''}
          onInput={onContactSearch}
          tagComponent={ContactTag}
          minQueryLength={3}
          suggestionsFilter={() => true}
          suggestionComponent={getSuggestionComponent(suggestionsWithContacts || [], suggestionsWithLabels || [])}
          ref={toFieldRef}
        />
      </span>
      <span className={styles.actionIcons}>
        {!isCcFieldEnabled && (
          <button className={styles.fieldOptions} type="button" onClick={enableCcField} aria-label="carbon copy">
            <p>{t('cc')}</p>
          </button>
        )}
        {!isBccFieldEnabled && (
          <button type="button" className={styles.fieldOptions} onClick={enableBccField} aria-label="blind carbon copy">
            <p>{t('bcc')}</p>
          </button>
        )}
      </span>
    </span>
  );
};

export default ToField;
