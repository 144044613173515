import { KiteButton, KiteIcon } from '@kite/react-kite';
import styles from 'components/contact-list-options/styles/ContactListOptions.module.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import { resetSelectedContacts, updateSelectAllContacts } from 'slices/contact';
import KebabMenu, { KebabMenuItemsInterface } from 'components/common/kebab-menu';
import {
  updateDrawerName,
  updateIsDrawerOpen,
  updateIsOpen,
  updateModalName,
  updateModalProps,
  updateToastMessage,
} from 'slices/app';
import { TrackingHook, useTracking } from 'react-tracking';
import BackButton from 'components/common/BackButton';
import { addTabCollection, addTo, updateMinimizedIndex } from 'slices/compose';
import { useNavigate, useParams } from 'react-router-dom';
import { appConstants } from 'appConstants';
import { findDefaultOrFirstEmail } from 'utils/transformContact';
import { useCallback, useRef, useState } from 'react';
import ContactPrint from 'components/contact-print';
import { useReactToPrint } from 'react-to-print';
import { ContactEntity, useExportContactLazyQuery } from 'generated/graphql';
import { exportContactThunk } from 'thunks/contact/exportContactThunk';
import { updateModalOpener, useTarget } from 'components/common/focus/FocusContext';
import { usePageTranslation } from 'translation/hooks';
import isCharterDomain from 'utils/isCharterDomain';

const ContactListOptionSelect: React.FC = () => {
  const { t } = usePageTranslation('contactActionsCopy');
  const { trackEvent }: TrackingHook = useTracking({
    page: 'contacts',
    component: 'ContactListOptionSelect',
  });

  const selectedContacts = useAppSelector((state) => state.contactSlice.selectedContacts);
  const contactCollection = useAppSelector((state) => state.contactSlice.contactCollection?.data);
  const labelCollection = useAppSelector((state) => state.labelSlice.labelCollection);
  const isAutoInsertSignature = useAppSelector((state) => state.signatureSlice.signatureEntity?.autoInsert);
  const signature = useAppSelector((state) => state.signatureSlice.signatureEntity?.signature) || '';
  const enableSyncButton = useAppSelector((state) => state.canarySlice.isContactReSyncAllowed);
  const focusPrintOpener = useTarget('modal');
  const [getContactExportHook] = useExportContactLazyQuery();
  const { '*': contactId } = useParams();
  const isReadingPaneEnabled = !!contactId;
  const [maxItems, setMaxItems] = useState(0);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const updateModalRef = updateModalOpener();
  const contactPrintRef = useRef(null);

  let isSelectedAll = false;

  const contactIdList = contactCollection?.map((contact) => contact.id);
  if (selectedContacts && contactIdList && selectedContacts.length) {
    isSelectedAll = contactIdList.every((contact) => {
      return selectedContacts.includes(contact);
    });
  }

  const getSelectedContacts = (): ContactEntity[] => {
    if (!selectedContacts || selectedContacts.length === 0) {
      return [];
    }

    if (!contactCollection || contactCollection.length === 0) {
      return [];
    }

    return contactCollection.filter((contact) => {
      return selectedContacts.includes(contact.id);
    });
  };

  const handleSelectAllContacts = (): void => {
    trackEvent({ event: 'selectAllContacts' });
    dispatch(updateSelectAllContacts(isSelectedAll));
  };

  const handleBulkDeleteContacts = (): void => {
    trackEvent({ event: 'deleteModalView' });
    dispatch(updateModalName('deleteBulkContacts'));
    dispatch(updateIsOpen(true));
  };

  const handleSendMail = (): void => {
    if (!contactCollection || contactCollection.length === 0) {
      return;
    }

    if (!selectedContacts || selectedContacts.length === 0) {
      return;
    }

    const contacts = contactCollection.filter((contact) => {
      return selectedContacts.includes(contact.id);
    });

    if (contacts.length === 0) {
      return;
    }

    trackEvent({ event: 'sendEmail' });
    dispatch(addTabCollection(isAutoInsertSignature ? signature : null));
    dispatch(updateMinimizedIndex(''));

    contacts.forEach((contact) => {
      const defaultOrFirstContact = findDefaultOrFirstEmail(contact);
      if (defaultOrFirstContact) {
        dispatch(addTo({ address: defaultOrFirstContact.emailAddress, name: contact.displayName as string }));
      }
    });

    dispatch(resetSelectedContacts());
    navigate(appConstants.INBOX_URL);
  };

  const handleExport = async (): Promise<boolean> => {
    trackEvent({ event: 'exportContacts' });
    const result = await dispatch(
      exportContactThunk({ getContactExportHook, payload: { ids: selectedContacts ?? [] } })
    );

    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'exportContactSuccess' });
      dispatch(updateToastMessage({ message: t('exportSuccessToast'), success: true }));
      return true;
    }

    trackEvent({ event: 'exportContactApiError' });
    dispatch(
      updateModalProps({
        selectedContacts: selectedContacts ?? [],
      })
    );
    dispatch(updateModalName('exportContact'));
    dispatch(updateIsOpen(true));

    return false;
  };

  const handlePrint = useReactToPrint({
    content: () => {
      trackEvent({ event: 'printContacts' });
      return contactPrintRef.current;
    },
    onAfterPrint: () => {
      focusPrintOpener();
    },
  });

  const handleBackClick = (): void => {
    dispatch(updateDrawerName('labelList'));
    dispatch(updateIsDrawerOpen(true));
  };

  const handleImportContacts = (): void => {
    trackEvent({ event: 'importContactsNav' });
    dispatch(updateModalName('importContacts'));
    dispatch(updateIsOpen(true));
  };

  const handleSyncContacts = (): void => {
    trackEvent({ event: 'contactReSyncClicked' });
    dispatch(updateModalName('syncContacts'));
    dispatch(updateIsOpen(true));
  };

  const handleAssignLabels = (): void => {
    trackEvent({ event: 'assignLabelNav' });
    dispatch(updateModalName('assignLabelToBulkContact'));
    dispatch(updateModalProps({ contacts: selectedContacts }));
    dispatch(updateIsOpen(true));
  };

  const selectIcon = isSelectedAll
    ? 'ki-square-checkbox-f'
    : selectedContacts?.length
    ? 'ki-square-checkbox-indeterminate-f'
    : 'ki-square-checkbox';

  const kebabMenuItems: KebabMenuItemsInterface[] = [
    {
      onClick: handleSendMail,
      icon: 'ki-mail',
      label: t('sendEmail'),
      className: '',
      ariaLabel: `send email to selected contacts`,
    },
    {
      onClick: handleAssignLabels,
      icon: 'ki-label-f',
      label: t('assignLabels'),
      className: '',
      ariaLabel: `assign new labels to selected contacts`,
      isMenuItemDisabled: labelCollection.length === 0,
      opensModal: true,
    },
    {
      onClick: handleExport,
      icon: 'ki-export',
      label: t('export'),
      className: '',
      ariaLabel: `export selected contacts`,
    },
    {
      onClick: handlePrint,
      icon: 'ki-print',
      label: t('print'),
      className: '',
      ariaLabel: 'print selected contacts',
      opensModal: true,
    },
  ];

  const handleResize = (optionsContainer: HTMLDivElement): void => {
    if (optionsContainer) {
      const { width } = optionsContainer.getBoundingClientRect();
      switch (true) {
        case width < 200:
          setMaxItems(0);
          break;
        case width < 404:
          setMaxItems(kebabMenuItems.length - 4);
          break;
        case width < 563:
          setMaxItems(kebabMenuItems.length - 3);
          break;
        case width < 730:
          setMaxItems(kebabMenuItems.length - 2);
          break;
        default:
          setMaxItems(kebabMenuItems.length);
      }
    }
  };

  const handleResizeRef = useCallback(
    (node: HTMLDivElement) => {
      handleResize(node);
      window.addEventListener('resize', () => handleResize(node));
      return () => window.removeEventListener('resize', () => handleResize(node));
    },
    [contactId, selectedContacts?.length]
  );

  return (
    <>
      <div style={{ display: 'none' }}>
        <ContactPrint ref={contactPrintRef} selectedContacts={getSelectedContacts()} />
      </div>
      <div className={`${styles.contactOptions} ${contactId && !selectedContacts?.length ? styles.fixedWidth : ''}`}>
        <div className={styles.mobileSection}>
          <BackButton
            isFolderDrawer
            onClick={handleBackClick}
            disableLabel={true}
            customStyle={styles.labelListButton}
          />
          <div className={styles.selectButtonBorder} />
        </div>
        <KiteButton
          variant="borderless"
          size="shrinkwrap"
          onClick={handleSelectAllContacts}
          aria-label={'select all contacts'}
        >
          <div className={styles.contactOptionsButton}>
            <KiteIcon icon={selectIcon} />
            <span>{selectedContacts?.length ? t('selected', { amount: selectedContacts?.length }) : t('select')}</span>
          </div>
        </KiteButton>
        <div className={styles.selectButtonBorder} />
        {isSelectedAll ||
          (!!selectedContacts?.length && (
            <span className={'srOnly'} aria-live={'polite'}>
              {`Selected contacts, ${selectedContacts?.length}, checkbox, checked`}
            </span>
          ))}
        {selectedContacts && selectedContacts?.length > 0 ? (
          <div className={styles.contactOptionsSelected}>
            <div
              className={`${styles.menuList} ${!isReadingPaneEnabled ? styles.noSpacing : ''}`}
              ref={handleResizeRef}
            >
              <KiteButton
                className={styles.deleteButton}
                variant="borderless"
                size="shrinkwrap"
                onClick={handleBulkDeleteContacts}
              >
                <div className={styles.contactOptionsButton}>
                  <KiteIcon icon={'ki-trash'} />
                  <span className={styles.label}>{t('delete')}</span>
                </div>
              </KiteButton>

              {kebabMenuItems.slice(0, maxItems).map((kebabMenuItem, idx) => (
                <KiteButton
                  key={`emailoptions-button__${kebabMenuItem.label}-${idx}`}
                  variant="borderless"
                  size="shrinkwrap"
                  onClick={(e) => {
                    kebabMenuItem.onClick();
                    if (kebabMenuItem.opensModal) {
                      updateModalRef(e.currentTarget);
                    }
                  }}
                  disabled={kebabMenuItem.isMenuItemDisabled}
                  aria-disabled={kebabMenuItem.isMenuItemDisabled}
                >
                  <div className={styles.contactOptionsButton}>
                    <KiteIcon icon={kebabMenuItem.icon || ''} />
                    <span>{kebabMenuItem.label}</span>
                  </div>
                </KiteButton>
              ))}

              <div className={styles.moreOptions}>
                {maxItems < kebabMenuItems.length && (
                  <label className={styles.contactListOptionsButton}>
                    <KebabMenu items={kebabMenuItems.slice(maxItems)} />
                    {selectedContacts?.length && <span className={styles.label}>{t('otherActions')}</span>}
                  </label>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <KiteButton
              variant="borderless"
              size="shrinkwrap"
              onClick={(e) => {
                updateModalRef(e.currentTarget);
                handleImportContacts();
              }}
              aria-label={'import contacts'}
            >
              <div className={styles.contactOptionsButton}>
                <KiteIcon icon={'ki-import'} size={'18px'} />
                <span className={styles.importLabel}>{t('import')}</span>
              </div>
            </KiteButton>
            {enableSyncButton && !isCharterDomain() && (
              <KiteButton
                className={styles.syncButton}
                variant="borderless"
                size="shrinkwrap"
                onClick={(e) => {
                  updateModalRef(e.currentTarget);
                  handleSyncContacts();
                }}
                aria-label={'sync contacts'}
              >
                <div className={styles.contactOptionsButton}>
                  <img className={styles.syncIcon} src={appConstants.CONTACTS_SYNC_IMG} />
                  <span className={styles.syncLabel}>{t('sync')}</span>
                </div>
              </KiteButton>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ContactListOptionSelect;
