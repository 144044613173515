import React from 'react';
import { KiteGridCell, KiteIcon } from '@kite/react-kite';
import styles from 'components/footer/styles/Footer.module.scss';
import SocialMediaIcons from 'components/footer/SocialMediaIcon';
import { footerColumns, LinkItemInterface } from 'app-constants';
import FooterColumnLink from 'components/footer/FooterColumnLink';

const SocialMedia: React.FC = () => {
  const [LOCATION, WEATHER, CONTACT] = footerColumns.SOCIAL_MEDIA.links;

  return (
    <KiteGridCell col={12} lg={2} className={`${styles.socialMediaColumn} ${styles.column}`}>
      <div className={styles.row}>
        <div className={styles.space} />
        <div className={styles.socialColumn}>
          <div className={styles.iconLink}>
            <KiteIcon className={`${styles.linkIcon} ${styles.mapPoint}`} icon="ki-map-point-f" />
            <FooterColumnLink {...(LOCATION as LinkItemInterface)} />
          </div>
          <div className={styles.iconLink}>
            <KiteIcon className={`${styles.linkIcon} ${styles.lightning}`} icon="ki-lightning-f" />
            <FooterColumnLink {...(WEATHER as LinkItemInterface)} />
          </div>
          <div className={styles.iconLink}>
            <KiteIcon className={`${styles.linkIcon} ${styles.chat}`} icon="ki-chat-f" />
            <FooterColumnLink {...(CONTACT as LinkItemInterface)} />
          </div>
          <div className={styles.footerSocialIcons}>
            <SocialMediaIcons />
          </div>
        </div>
        <div className={styles.space} />
      </div>
    </KiteGridCell>
  );
};

export default SocialMedia;
