import { KiteToast } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateToastMessage } from 'slices/app';
import { debounce } from 'lodash';

const Toast: React.FC = () => {
  const toast = useAppSelector((state) => state.appSlice.toastMessage);
  const toastIcon = useAppSelector((state) => state.appSlice.toastIcon);
  const dispatch = useAppDispatch();

  return (
    <KiteToast
      icon={toastIcon}
      visible={!!toast}
      onExpire={debounce(() => {
        dispatch(updateToastMessage({ message: '', success: true }));
      }, 200)}
      position="bottom-left"
      duration="long"
    >
      {toast}
    </KiteToast>
  );
};

export default Toast;
