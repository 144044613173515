import isMobileSize from 'components/common/hooks/useMobileSize';
import SettingViewCell from 'components/setting-menu/SettingViewCell';
import SettingMenu from 'components/setting-menu';
import SettingContainerMobile from 'containers/SettingContainerMobile';

const SettingContainer: React.FC = () => {
  const isMobile = isMobileSize();
  if (isMobile) {
    return <SettingContainerMobile />;
  }

  return (
    <>
      <SettingMenu />
      <SettingViewCell showFooter={true} />
    </>
  );
};

export default SettingContainer;
