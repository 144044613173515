import { getLoggedInEmail } from 'utils/accessTokenLocalStorage';

const chtrDomains = ['charter.net', 'chartermi.net', 'chartermi.com', 'chartertn.net', 'spectrum.net', 'bresnan.net'];

//Idenity charter namespace from email domain
const isCharterDomain = (): boolean => {
  const userEmail = getLoggedInEmail();
  if (userEmail === null || userEmail.length === 0) {
    return false;
  }

  const usernameSplit = userEmail.split('@');
  return chtrDomains.includes(usernameSplit?.[1] ? usernameSplit[1].toLowerCase() : '');
};

export default isCharterDomain;
