import React from 'react';
import ContactSection from 'components/contact-form/sections/ContactSection';
import { KiteIcon, KiteTextInput } from '@kite/react-kite';
import { ContactSectionProps } from 'components/contact-form/initialState';
import { ContactFormInterface } from 'components/contact-form/initialState';
import styles from 'components/contact-form/styles/ContactCreateContact.module.scss';
import validateContactField, { getContactValidationErrorMessage } from 'utils/validateContactField';
import { KiteInputElems } from 'libs/kite/types';
import { notesTrans } from './translationKeys';
import { notesMaxContactForm } from 'appConstants';
import { usePageTranslation } from 'translation/hooks';

export const Notes: React.FC<ContactSectionProps<Pick<ContactFormInterface, 'notes'>>> = ({
  updateFormValues,
  values,
  errors,
  setErrors,
}) => {
  const { t } = usePageTranslation('contactFormCopy');

  const noteErrorMessage = getContactValidationErrorMessage(errors, 'notes', notesTrans);

  const handleNoteChange = (e: React.ChangeEvent<KiteInputElems>): void => {
    updateFormValues(e.target.value, 'notes');
    validateContactField('notes', { notes: e.target.value }, errors, setErrors);
  };

  return (
    <ContactSection>
      <KiteIcon className={styles.fieldIcon} icon="ki-notes" />
      <div className={styles.fieldContainer}>
        <KiteTextInput
          className={styles.fullField}
          label={t('note')}
          onChange={(e) => handleNoteChange(e)}
          value={values.notes || ''}
          type="multiline"
          errorMessage={t(noteErrorMessage, { limit: notesMaxContactForm })}
          ariaLive="polite"
        />
      </div>
    </ContactSection>
  );
};
