import { ValidationError } from 'yup';
import React from 'react';
import { filterSchema } from 'libs/validations/filter';
import { FieldValidatorInitialStateInterface } from 'hooks/useContactFieldValidatorError';
import { FilterRulesEntity } from 'generated/graphql';
import { ValidationKeys } from 'translation/hooks';
import { FilterValidatorTransKeys } from 'components/setting-filter-form/sections/translationKeys';

interface FilterMultiInputValidationInterface {
  [key: string]: FilterRulesEntity[] | string | undefined | null;
}

export const getFilterValidationErrorMessage = (
  errors: FieldValidatorInitialStateInterface,
  fieldName: string,
  translationKeys: FilterValidatorTransKeys
): ValidationKeys | '' => {
  return translationKeys[errors[fieldName] as keyof FilterValidatorTransKeys] || '';
};

const validateFilterField = (
  field: string,
  value: FilterMultiInputValidationInterface,
  errors: FieldValidatorInitialStateInterface,
  setErrors: React.Dispatch<React.SetStateAction<FieldValidatorInitialStateInterface>>
): void => {
  try {
    filterSchema.validateSyncAt(field, value);
    setErrors({ ...errors, [field]: '' });
  } catch (err) {
    if (err instanceof ValidationError) {
      setErrors({ ...errors, [field]: err.errors[0] });
    }
  }
};

export default validateFilterField;
