import { useState } from 'react';

type UseAbortExistingRequestInterface = () => (newRequest: any) => void;

const useAbortExistingRequest: UseAbortExistingRequestInterface = () => {
  const [existingRequest, _setExistingRequest] = useState<any>();

  const setExistingRequest = (newRequest: any): void => {
    if (existingRequest) {
      existingRequest.abort();
    }

    _setExistingRequest(newRequest);
  };

  return setExistingRequest;
};

export default useAbortExistingRequest;
