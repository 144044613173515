export const safeSendersCopy = {
  title: '',
  description: '',
  addLabel: '',
  addButton: '',
  searchPlaceholder: '',
  isNew: '',
  isDeleted: '',
  maxListCountMessage: '',
  maxListCountHint: '',
  emptySenderList: '',
};
