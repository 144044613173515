import { getAccessToken } from './accessTokenLocalStorage';

export const isLoggedInLocalStorage = (): boolean => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return false;
  }

  return accessToken.length > 0;
};

export const logoutLocalStorage = (): void => {
  localStorage.removeItem('isLoggedIn');
};

export const setRememberMeLocalStorage = (email: string): void => {
  localStorage.setItem('rememberMe', email);
};

export const getRememberMeLocalStorage = (): string => {
  return localStorage.getItem('rememberMe') as string;
};
