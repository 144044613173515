import React from 'react';
import { KiteLink } from '@kite/react-kite';
import styles from 'components/footer/styles/Footer.module.scss';
import concatUrl from 'utils/concatUrl';
import { TrackingHook, useTracking } from 'react-tracking';
import { LinkItemInterface } from 'app-constants';
import { usePageTranslation } from 'translation/hooks';

const FooterColumnLink: React.FC<LinkItemInterface> = ({ baseUrl, href, uiName, stdName }) => {
  const { trackEvent }: TrackingHook = useTracking({ page: 'login', component: 'FooterColumLink' });
  const { t } = usePageTranslation('linksCopy');

  return (
    <KiteLink
      className="footerColumnLink"
      href={concatUrl(baseUrl, href)}
      type="inline"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() =>
        trackEvent({
          event: 'loginFooterLink',
          linkObject: { baseUrl, href, uiName: t(uiName), stdName },
          url: concatUrl(baseUrl, href),
        })
      }
    >
      {t(uiName)}
      <span className={styles.srOnly}>{t('openInNewWindow')}</span>
    </KiteLink>
  );
};

export default FooterColumnLink;
