import styles from 'components/section-tab/styles/SectionTab.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { SectionTabInterface } from 'appConstants';
import { TrackingHook, useTracking } from 'react-tracking';
import { useAppDispatch } from 'hooks';
import { updateAutoFocus } from 'slices/app';

export interface SectionTabInterfaceWithRef extends SectionTabInterface {
  linkRef?: React.RefObject<HTMLAnchorElement> | null;
}

const SectionTab: React.FC<SectionTabInterfaceWithRef> = ({
  uiName,
  preview,
  href,
  trackingEvent,
  linkRef,
  autoFocus,
  pageViewInitEvent,
}) => {
  const location = useLocation();
  const { trackEvent }: TrackingHook = useTracking({ page: 'settings' });

  const dispatch = useAppDispatch();

  const handleTracking = (): void => {
    trackEvent({ event: trackingEvent });
    pageViewInitEvent && trackEvent({ event: pageViewInitEvent });
  };

  const autoFocusOnEnter = (event: React.KeyboardEvent<HTMLAnchorElement>): void => {
    if (autoFocus && event.key === 'Enter') {
      dispatch(updateAutoFocus(true));
    }
  };

  const isActive = location.pathname === href;

  return (
    <li className={`${styles.SectionTab} ${isActive ? styles.active : ''}`} aria-label={`${uiName} tab`}>
      <Link
        className={styles.SectionTabLink}
        to={href}
        role={'button'}
        onKeyDown={autoFocusOnEnter}
        onClick={handleTracking}
        aria-current={isActive}
        ref={linkRef}
      >
        {uiName}
        {preview && <span className={`${styles.PreviewDisplay} ${styles[preview]}`}>{preview}</span>}
      </Link>
    </li>
  );
};

export default SectionTab;
