import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LabelEntity, PatchBulkContactLabelMutationFn } from 'generated/graphql';

interface PatchBulkContactArg {
  payload: {
    contacts: string[];
    labels: string[];
    updatedLabels: LabelEntity[];
  };
  patchBulkContactLabelHook: PatchBulkContactLabelMutationFn;
}

interface PatchBulkContactResponse {
  success?: boolean;
  error?: ApolloError;
  contacts: string[];
  labels: string[];
  updatedLabels: LabelEntity[];
}

export const patchBulkContactLabelThunk = createAsyncThunk(
  'patchBulkContactLabelThunk',
  async (arg: PatchBulkContactArg): Promise<PatchBulkContactResponse> => {
    try {
      const response = await arg.patchBulkContactLabelHook({
        variables: arg.payload,
      });
      return {
        success: !!response.data?.putBulkContactLabel.success,
        contacts: arg.payload.contacts,
        labels: arg.payload.labels,
        updatedLabels: arg.payload.updatedLabels,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
        contacts: arg.payload.contacts,
        labels: arg.payload.labels,
        updatedLabels: arg.payload.updatedLabels,
      };
    }
  }
);
