import { useLocation } from 'react-router-dom';
import useURLQueryBuilder from './useURLQueryBuilder';

const useNavToPreviousPage = (): [string, number, () => void] => {
  const { search: queryParams } = useLocation();
  const query = new URLSearchParams(queryParams);
  const currentPage = parseInt(query.get('page') || '') || 1;
  const [_searchQueryParam, setSearchQueryParam] = useURLQueryBuilder();

  const navToPreviousPage = (): void => setSearchQueryParam('page', String(Number(currentPage) - 1));

  return [queryParams, currentPage, navToPreviousPage];
};

export default useNavToPreviousPage;
