import { KiteAlert, KiteCard } from '@kite/react-kite';
import React, { useEffect, useMemo, useState } from 'react';
import { TrackingHook, useTracking } from 'react-tracking';
import { useAppDispatch, useAppSelector } from 'hooks';
import SettingSwitch from 'components/setting-switch';
import styles from 'components/setting-forwarding/styles/SettingForwarding.module.scss';
import { updateIsOpen, updateModalName } from 'slices/app';
import { usePageTranslation } from 'translation/hooks';

const SettingForwarding: React.FC = () => {
  const { t } = usePageTranslation('forwardingCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'settings' });

  const [hasError, setHasError] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');

  const mailForwardingToggleLoading = useAppSelector((state) => state.settingSlice.mailForwardingToggleLoading);
  const isMailForwardingEnabled = useAppSelector((state) => state.settingSlice.isMailForwardingEnabled);
  const mailForwardingAddresses = useAppSelector((state) => state.settingSlice.mailForwardingAddress);
  const apiError = useAppSelector((state) => state.settingSlice.apiError);

  const dispatch = useAppDispatch();

  useMemo(() => {
    trackEvent({ event: 'forwardingPageViewInit' });
  }, []);

  useEffect(() => {
    trackEvent({ event: 'forwardingPageView' });
  }, []);

  useEffect(() => {
    if (!apiError) {
      handleCloseAlert();
    }
  }, [apiError]);

  const handleCloseAlert = (): void => {
    setHasError(false);
    setAlertDescription('');
  };

  const getForwardedEmailAddress = (): JSX.Element | null => {
    if (!isMailForwardingEnabled) {
      return null;
    }
    const forwardingEmails = mailForwardingAddresses.map((address, index) => {
      return (
        <li key={index} className={styles.emailItem}>
          {address}
        </li>
      );
    });

    return <ul className={styles.emailList}>{forwardingEmails}</ul>;
  };

  const handleSwitch = async (): Promise<void> => {
    dispatch(updateModalName('disableForwarding'));
    dispatch(updateIsOpen(true));
  };

  const getForwardingDeprecationAlert = (): JSX.Element => {
    return (
      <KiteAlert
        level="page"
        className={styles.settingDeprecationAlert}
        type="caution"
        description={t(`warning${!isMailForwardingEnabled ? 'Off' : 'On'}`)}
      />
    );
  };

  return (
    <div className={styles.forwardingTray}>
      {getForwardingDeprecationAlert()}
      <SettingSwitch
        disabled={!isMailForwardingEnabled}
        aria-disabled={!isMailForwardingEnabled}
        loading={mailForwardingToggleLoading}
        description={t('description')}
        title={t('title')}
        ariaLabel={t('title')}
        selected={isMailForwardingEnabled}
        handleSwitch={handleSwitch}
        alert={hasError ? alertDescription : ''}
        onClose={handleCloseAlert}
      />

      {mailForwardingAddresses.length > 0 && (
        <KiteCard className={styles.mailForwardingListCard}>{getForwardedEmailAddress()}</KiteCard>
      )}
    </div>
  );
};

export default SettingForwarding;
