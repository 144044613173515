import { KiteButton, KiteIcon } from '@kite/react-kite';
import { createTargetRef } from 'components/common/focus/FocusContext';
import KebabMenu, { KebabMenuItemsInterface } from 'components/common/kebab-menu';
import styles from 'components/setting-filter/styles/SettingFilter.module.scss';
import { FilterEntity } from 'generated/graphql';
import { usePageTranslation } from 'translation/hooks';
import { getFilterDescription } from 'utils/filter';

type Props = {
  handleEditFilter: (index: number) => void;
  handleDeleteFilter: (index: number) => void;
  handleMoveFilterItemTo: (from: number, to: number) => void;
  filter: FilterEntity;
  index: number;
  filtersLength: number;
};

const Filter: React.FC<Props> = ({
  handleEditFilter,
  handleDeleteFilter,
  handleMoveFilterItemTo,
  filter,
  index,
  filtersLength,
}) => {
  const { t } = usePageTranslation('filterRulesCopy');
  const filterRef = createTargetRef<HTMLButtonElement>(`filter-${index}`);

  const kebabMenuItems = (filter: FilterEntity, index: number): KebabMenuItemsInterface[] => [
    {
      onClick: () => handleEditFilter(index),
      icon: 'ki-edit',
      label: t('edit'),
      className: '',
      ariaDescribedBy: filter.name,
      ariaLabel: `edit ${filter.name}`,
      isMenuItemHidden: filter.actionForward === 'yes',
    },
    {
      onClick: () => handleDeleteFilter(index),
      icon: 'ki-trash',
      label: t('delete'),
      className: 'deleteMenuItem',
      ariaDescribedBy: filter.name,
      opensModal: true,
      ariaLabel: `delete ${filter.name}`,
    },
  ];

  const assignFilterRef = (condition: boolean): null | typeof filterRef => {
    if (condition) {
      return filterRef;
    }
    return null;
  };

  return (
    <li className={styles.rulesListItem}>
      <div className={styles.rulesSort}>
        <KiteButton
          onClick={() => handleMoveFilterItemTo(index, index - 1)}
          className={styles.ruleChevron}
          disabled={index === 0}
          aria-disabled={index === 0}
          variant={'borderless'}
          role={'spinbutton'}
          ref={assignFilterRef(index !== 0)}
        >
          <KiteIcon icon={'ki-chevron-up'} />
        </KiteButton>
        <KiteButton
          onClick={() => handleMoveFilterItemTo(index, index + 1)}
          className={styles.ruleChevron}
          disabled={index === filtersLength - 1}
          aria-disabled={index === filtersLength - 1}
          variant={'borderless'}
          role={'spinbutton'}
          ref={assignFilterRef(index === 0)}
        >
          <KiteIcon icon={'ki-chevron-down'} />
        </KiteButton>
      </div>
      <div className={styles.rulesContent}>
        <div className={styles.rulesTitle}>
          <span className={styles.rulesTitleName} id={filter.name}>
            {filter.name}
          </span>
          <span className={styles.actionButtons}>
            <KebabMenu items={kebabMenuItems(filter, index)} isScrollContainer={true} />
          </span>
        </div>
        <p className={styles.description}>{getFilterDescription(filter, t)}</p>
      </div>
    </li>
  );
};

export default Filter;
