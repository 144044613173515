import { ApolloError } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactEntity, ContactWithLabelEntity, LabelEntity } from 'generated/graphql';
import { contactCollectionThunk } from 'thunks/contact/contactCollectionThunk';
import { contactThunk } from 'thunks/contact/contactThunk';
import { createContactThunk } from 'thunks/contact/createContactThunk';
import { deleteBulkContactThunk } from 'thunks/contact/deleteBulkContactThunk';
import { deleteContactThunk } from 'thunks/contact/deleteContactThunk';
import { updateContactThunk } from 'thunks/contact/updateContactThunk';
import { patchContactsLabelThunk } from 'thunks/contact/patchContactsLabelThunk';
import { contactCountThunk } from 'thunks/contact/contactCountThunk';
import { exportContactThunk } from 'thunks/contact/exportContactThunk';
import FileSaver from 'file-saver';
import { createBulkContactThunk } from 'thunks/contact/createBulkContactThunk';
import { uniq, uniqBy } from 'lodash';
import { updateIsOpen } from 'slices/app';
import { patchBulkContactLabelThunk } from 'thunks/contact/patchBulkContactLabelThunk';

interface ContactCollectionState {
  totalContactCount: number;
  contactCollection?: {
    total: number;
    currentPage: number;
    limit: number;
    data: ContactEntity[];
  };
  contact?: ContactWithLabelEntity;
  apiError?: Error | null;
  currentPage: number;
  itemsPerPage: number;
  totalPages: number;
  collectionLabelId?: string;
  currentLabelSelected?: string | undefined;
  selectedContacts?: string[];
  forceReload: boolean;
  isSearchActive: boolean;
  previousViewedContact?: string | undefined;
}

const initialState: ContactCollectionState = {
  totalContactCount: 0,
  currentPage: 1,
  itemsPerPage: 5,
  totalPages: 1,
  forceReload: false,
  isSearchActive: true,
};

export const contactCollectionSlice = createSlice({
  name: 'contactCollection',
  initialState,
  reducers: {
    resetContactApiError: (state: ContactCollectionState) => {
      state.apiError = null;
    },
    updateCurrentLabelSelected: (state: ContactCollectionState, action: PayloadAction<string | undefined>) => {
      state.currentLabelSelected = action.payload;
    },
    resetSelectedContacts: (state: ContactCollectionState) => {
      state.selectedContacts = [];
    },
    removeContactFromCollection: (state: ContactCollectionState, action: PayloadAction<string>) => {
      const newContacts = state.contactCollection?.data.filter((item) => {
        return item.id !== action.payload;
      });

      state.contactCollection = {
        total: state.contactCollection?.total ? state.contactCollection?.total - 1 : 0,
        currentPage: state.contactCollection?.currentPage ?? 0,
        limit: state.contactCollection?.limit ?? 0,
        data: newContacts as ContactEntity[],
      };
    },
    updateSelectedContacts: (state: ContactCollectionState, action: PayloadAction<string>) => {
      if (!state.selectedContacts?.length) {
        state.selectedContacts = [action.payload];
        return;
      }

      if (state.selectedContacts.includes(action.payload)) {
        state.selectedContacts = state.selectedContacts.filter((contact) => contact !== action.payload);
        return;
      }

      state.selectedContacts = [...state.selectedContacts, action.payload];
    },
    updateSelectAllContacts: (state: ContactCollectionState, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.selectedContacts = undefined;
        return;
      }
      state.selectedContacts = state?.contactCollection?.data.map((contact) => {
        return contact.id;
      });
    },
    updateSelectMultipleContacts: (state: ContactCollectionState, action: PayloadAction<string[]>) => {
      if (!state.selectedContacts?.length) {
        state.selectedContacts = action.payload;
        return;
      }

      state.selectedContacts = uniq([...state.selectedContacts, ...action.payload]);
    },
    updatePreviousViewedContact: (state: ContactCollectionState, action: PayloadAction<string | undefined>) => {
      state.previousViewedContact = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(contactCollectionThunk.fulfilled, (state, action) => {
      if (action.payload.success && !action.payload.isPrefetch) {
        state.contactCollection = {
          total: action.payload.success.total,
          currentPage: action.payload.success.currentPage,
          limit: action.payload.success.limit,
          data: action.payload.success.data,
        };
        state.totalPages = Math.ceil(action.payload.success.total / state.itemsPerPage);
        state.currentPage = action.payload.success.currentPage;
        state.itemsPerPage = action.payload.success.limit;
        state.collectionLabelId = action.payload.success.labelId || 'all';
        state.forceReload = false;
        state.isSearchActive = action.payload.isSearchActive as boolean;
        state.selectedContacts = [];
      }
      if (action.payload.error && !action.payload.isPrefetch) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(contactThunk.fulfilled, (state, action) => {
      state.forceReload = false;

      if (action.payload.success) {
        state.contact = action.payload.success;
        return;
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(updateContactThunk.fulfilled, (state, action) => {
      if (action.payload.success && state.contactCollection) {
        const index = state.contactCollection.data.findIndex((contact) => {
          return contact.id === action.payload.id;
        });

        let labels: string[] = [];
        if (action.payload.success.labels && action.payload.success.labels.length > 0) {
          labels = action.payload.success.labels.map((label) => {
            return label.id;
          });
        }

        state.contactCollection.data[index] = { ...action.payload.success, labels } as ContactEntity;
        state.forceReload = true;
        return;
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(createContactThunk.fulfilled, (state, action) => {
      state.forceReload = true;

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
      }
    });

    builder.addCase(contactCountThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.totalContactCount = action.payload.success.count;
      }
    });

    builder.addCase(patchContactsLabelThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.contact = action.payload.success;

        const labels = action.payload.success.labels.map((label) => {
          return label.id;
        });

        const newContactEntity = { ...action.payload.success, labels };
        const newContacts = state.contactCollection?.data.map((contact) => {
          if (contact.id === action.payload.id) {
            return newContactEntity;
          }

          return contact;
        });

        state.contactCollection = {
          total: state.contactCollection?.total ?? 0,
          currentPage: state.contactCollection?.currentPage ?? 0,
          limit: state.contactCollection?.limit ?? 0,
          data: newContacts as ContactEntity[],
        };

        state.forceReload = true;
        return;
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(patchBulkContactLabelThunk.fulfilled, (state, action) => {
      if (action.payload.success && state.contact) {
        // update existing contact if it is selected
        const existingLabels = state.contact.labels || ([] as LabelEntity[]);

        state.contact = {
          ...state.contact,
          labels: uniqBy([...existingLabels, ...action.payload.updatedLabels], (label) => label.id),
        } as ContactWithLabelEntity;

        return;
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(createBulkContactThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.apiError = null;
        state.forceReload = true;
        return;
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(deleteContactThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        const filteredContacts = state.contactCollection?.data.filter((contact) => {
          return !action.payload.id.includes(contact.id);
        });

        if (state.contactCollection) {
          const initialTotal = state.contactCollection.total;
          const total = state.contactCollection.total - 1;
          state.contactCollection = {
            ...state.contactCollection,
            data: filteredContacts as ContactEntity[],
            total,
          };

          state.selectedContacts = [];
          if (initialTotal >= state.itemsPerPage && total >= 1) {
            state.forceReload = true;
          }
        }
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
      }
    });

    builder.addCase(deleteBulkContactThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        const filteredContacts = state.contactCollection?.data.filter((contact) => {
          return !action.payload.ids.includes(contact.id);
        });

        if (state.contactCollection) {
          const initialTotal = state.contactCollection.total;
          const total = state.contactCollection.total - action.payload.ids.length;
          state.contactCollection = {
            ...state.contactCollection,
            data: filteredContacts as ContactEntity[],
            total: total,
          };

          state.selectedContacts = [];
          if (initialTotal >= state.itemsPerPage && total >= 1) {
            state.forceReload = true;
          }
        }
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
      }
    });

    builder.addCase(exportContactThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        const vCardBlob = new Blob(action.payload.success.vCard, { type: 'text/x-vCard;charset=iso-8859-1' });
        FileSaver.saveAs(vCardBlob, 'vcard.vcf');
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
      }
    });
    builder.addCase(updateIsOpen, (state, action) => {
      if (action.payload === false) {
        state.apiError = undefined;
      }
    });
  },
});

export const {
  resetContactApiError,
  updateCurrentLabelSelected,
  updateSelectedContacts,
  updateSelectAllContacts,
  updateSelectMultipleContacts,
  resetSelectedContacts,
  removeContactFromCollection,
  updatePreviousViewedContact,
} = contactCollectionSlice.actions;

export default contactCollectionSlice.reducer;
