import React, { memo, useEffect } from 'react';
import styles from 'components/setting-spam-management/styles/SpamManagement.module.scss';
import { KiteCard, KiteRadio, KiteRadioButton } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import SaveCancel from 'components/save-cancel';
import { updateSpamSettingThunk } from 'thunks/setting/updateSpamSetting';
import { useUpdateSpamSettingMutation } from 'generated/graphql';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import useNavBlockingDraft from 'hooks/useNavBlockingDraft';
import { updateNavBlocking } from 'slices/app';
import { usePageTranslation } from 'translation/hooks';

const SettingSpamManagement: React.FC = () => {
  const { t } = usePageTranslation('spamManagementCopy');

  const spam = useAppSelector((state) => state.spamSlice.spam);
  const [draft, setDraft] = useNavBlockingDraft(spam);

  const dispatch = useAppDispatch();
  const [updateSpamSettingHook] = useUpdateSpamSettingMutation();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setDraft(e.target.value);
  };

  const isChanged = (): boolean => {
    return spam !== draft;
  };

  const isValidate = (): boolean => {
    return true;
  };

  const isCancelled = (): boolean => {
    setDraft(spam || '');
    return true;
  };

  const isSaved = async (): Promise<boolean> => {
    const result = (await dispatch(
      updateSpamSettingThunk({ updateSpamSettingHook, payload: { value: draft || '' } })
    )) as {
      payload: { spam: string };
    };

    if (result.payload.spam) {
      dispatch(updateNavBlocking(false));
    }

    return !!result.payload.spam;
  };

  useEffect(() => {
    spam && setDraft(spam);
  }, [spam]);

  return (
    <div className={styles.spanManagementContainer}>
      <KiteCard className={styles.kiteCardSpam}>
        <div className={styles.title}>
          <AutoFocusHeader content={t('title')} />
        </div>
        <fieldset>
          <legend className={styles.spamDescription}>{t('description')}</legend>
          <KiteRadio disabled={false} onChange={handleOnChange} className={styles.spamForm}>
            <KiteRadioButton
              id={'quarantineJunkmail'}
              checked={draft === 'quarantine Junkmail'}
              label={t('optionSpam')}
              name={'spam'}
              value={'quarantine Junkmail'}
            />
            <KiteRadioButton
              id={'accept'}
              checked={draft === 'accept'}
              label={t('optionInbox')}
              name={'spam'}
              value={'accept'}
            />
            <KiteRadioButton
              id={'tagSpam'}
              checked={draft === 'tag SPAM:'}
              label={t('optionTagSpam')}
              name={'spam'}
              value={'tag SPAM:'}
            />
            <KiteRadioButton
              id={'delete'}
              checked={draft === 'delete'}
              label={t('optionTrash')}
              name={'spam'}
              value={'delete'}
            />
          </KiteRadio>
        </fieldset>

        <SaveCancel
          draft={draft}
          isChanged={isChanged}
          isSaved={isSaved}
          isValidate={isValidate}
          isCancelled={isCancelled}
          shouldDisableCancel={true}
        />
      </KiteCard>
    </div>
  );
};

export default memo(SettingSpamManagement);
