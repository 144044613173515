interface printOptions {
  onBeforePrint?: WindowEventHandlers['onbeforeprint'];
  onAfterPrint?: WindowEventHandlers['onafterprint'];
}
// const addRule = (newRule: string): string => ' ' + newRule;

export const printIframe = (iframe: HTMLIFrameElement, { onBeforePrint, onAfterPrint }: printOptions): void => {
  if (onBeforePrint) {
    iframe.contentWindow!.onbeforeprint = onBeforePrint;
  }
  if (onAfterPrint) {
    iframe.contentWindow!.onafterprint = onAfterPrint;
  }
  const css = `
    @page { 
      margin: .5in; 
    }
    img { 
      max-width: 100%; 
    }
  `;
  // css += addRule('@page { margin: .5in; }');
  // css += addRule('img { max-width: 100%; }');
  const head = iframe.contentWindow!.document.head;
  const style = document.createElement('style');

  head.appendChild(style);
  style.appendChild(document.createTextNode(css));

  iframe.contentWindow!.print();
};
