import { EmailAddressEntity, Maybe, MessageEntity } from 'generated/graphql';

const addressesToString = (prefix: string, arr?: Maybe<EmailAddressEntity[]>): string => {
  if (arr && arr.length) {
    const addressString = arr.map((to) => `${to.name ? `"${to.name}" ` : ''}&lt;${to.address}&gt;`).join(', ');
    return `${prefix}${addressString} <br />`;
  }
  return '';
};

const getMessageBody = (message: MessageEntity): string => {
  if (message.body?.html) {
    return message.body.html;
  }
  if (message.body?.noImageHtml) {
    return message.body.noImageHtml;
  }
  const fixedFullText = message.body?.fullText?.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace('\n', '<br>');
  return fixedFullText || '';
};

export const formatReplyMessageBody = (message: MessageEntity, date: string, signature?: string): string =>
  `
  ${signature ? `<div><br><br><br>${signature}</div>` : ''} <br />
  -------------------- <br /><br />
  ${addressesToString('From: ', message.from)}
  ${addressesToString('Reply-To: ', message.replyTo)}
  ${addressesToString('To: ', message.to)}
  ${addressesToString('Cc: ', message.cc)}
  Sent: ${date} <br />
  Subject: ${message.subject} <br />
  ${getMessageBody(message)}
  `;

export const formatForwardMessageBody = (message: MessageEntity, date: string, signature?: string): string =>
  `
  ${signature ? `<div><br><br><br>${signature}</div>` : ''} <br />
  ----------Forwarded Message---------- <br /><br />
  ${addressesToString('From: ', message.from)}
  ${addressesToString('Reply-To: ', message.replyTo)}
  ${addressesToString('To: ', message.to)}
  ${addressesToString('Cc: ', message.cc)}
  Sent: ${date} <br />
  Subject: ${message.subject} <br />
  ${getMessageBody(message)}
  `;
