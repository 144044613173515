import React from 'react';
import styles from 'components/pagination/styles/Pagination.module.scss';
import { KitePagination } from '@kite/react-kite';
import { RkPaginationVariant } from '@kite/react-kite/dist/pagination/KitePagination/KitePagination';

export interface PaginationPropsInterface {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  onPageSelect: (pageNumber: number) => void;
  onItemsPerPageChange: (newItemsPerPage: number) => void;
  onNextPage: () => void;
  onPrevPage: () => void;
  variant?: RkPaginationVariant;
}

const Pagination: React.FC<PaginationPropsInterface> = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageSelect,
  onItemsPerPageChange,
  onNextPage,
  onPrevPage,
  variant,
}) => {
  if (!totalItems) {
    return null;
  }

  const totalPages = itemsPerPage === 0 ? 0 : Math.ceil(totalItems / itemsPerPage);
  return (
    <div className={styles.paginationContainer}>
      <KitePagination
        expandUp
        variant={variant}
        className={styles.paginationBar}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        itemsPerPageOptions={[10, 20, 30, 40, 50, 75, 100]}
        totalItems={totalItems}
        totalPages={totalPages}
        onPageSelect={onPageSelect}
        onItemsPerPageChange={onItemsPerPageChange}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
      />
    </div>
  );
};

export default Pagination;
