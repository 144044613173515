import { useAppSelector } from 'hooks';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PreFetchSetting from 'components/pre-fetch-setting';
import { KiteProgressIndicator } from '@kite/react-kite';
import { AuthContext } from 'providers/AuthProvider';

interface AuthenticationProps {
  children: JSX.Element[] | JSX.Element | null;
  isCanaryRedirect: boolean;
}

const Authentication: React.FC<AuthenticationProps> = ({ children, isCanaryRedirect }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const { isLoginLoading } = useAppSelector((state) => state.loginSlice);
  const { isCanary } = useAppSelector((state) => state.appSlice);
  const { optInStatus, isOptInStatusCalled } = useAppSelector((state) => state.canarySlice);
  const { isContactMigrationLoading, isSettingMigrationLoading } = useAppSelector((state) => state.migrationSlice);
  const navigate = useNavigate();
  const isMigrationLoading = isCanaryRedirect && !isCanary && (isContactMigrationLoading || isSettingMigrationLoading);

  useEffect(() => {
    if (isLoggedIn && isCanaryRedirect && isOptInStatusCalled && optInStatus !== 'yes') {
      navigate('/user/canary');
    }
  }, [optInStatus, isLoggedIn, isOptInStatusCalled]);

  useEffect(() => {
    if (!isLoggedIn && !isLoginLoading) {
      navigate('/login');
    }
  }, [isLoggedIn, isLoginLoading]);

  if (!isLoggedIn) {
    return null;
  }
  return (
    <>
      {isCanaryRedirect && <PreFetchSetting />}
      {isMigrationLoading ? <KiteProgressIndicator id={'loading'} useOverlay={true} size="md" /> : children}
    </>
  );
};

export default Authentication;
