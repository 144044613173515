import { KiteIcon, KiteMenu, KiteMenuItem } from '@kite/react-kite';
import { appConstants } from 'appConstants';
import useURLQueryBuilder from 'components/common/hooks/useURLQueryBuilder';
import styles from 'components/message-options/styles/SortOptions.module.scss';
import { Sort } from 'generated/graphql';
import { useParams } from 'react-router-dom';
import { TrackingHook, useTracking } from 'react-tracking';
import { usePageTranslation } from 'translation/hooks';

interface SortOptionsInterface {
  getMessageCollection: (sort: Sort) => void;
  selected: Sort;
  messageCollectionLoading: boolean;
  messageCollectionFetchError: boolean;
}

interface SortItemInterface {
  query: Sort;
  label: string;
  ariaSort: 'ascending' | 'descending';
  event: string;
}

const SortOptions: React.FC<SortOptionsInterface> = ({
  getMessageCollection,
  selected,
  messageCollectionLoading,
  messageCollectionFetchError,
}) => {
  const { t } = usePageTranslation('emailActionsCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });

  const { folder } = useParams();

  const [_searchQueryParam, setSearchQueryParam] = useURLQueryBuilder();

  const sortOptions: SortItemInterface[] = [
    {
      query: Sort.ArrivalDesc,
      label: t('newToOld'),
      ariaSort: 'descending',
      event: 'sortNewToOld',
    },
    {
      query: Sort.ArrivalAsc,
      label: t('oldToNew'),
      ariaSort: 'ascending',
      event: 'sortOldToNew',
    },
    {
      query: Sort.FromAsc,
      label: t('sender'),
      ariaSort: 'ascending',
      event: 'sortSender',
    },
    {
      query: Sort.ToAsc,
      label: t('recipient'),
      ariaSort: 'ascending',
      event: 'sortRecipient',
    },
    {
      query: Sort.SubjectAsc,
      label: t('subject'),
      ariaSort: 'ascending',
      event: 'sortSubject',
    },
    {
      query: Sort.SizeDesc,
      label: t('size'),
      ariaSort: 'descending',
      event: 'sortSize',
    },
    {
      query: Sort.FlaggedAsc,
      label: t('flagged'),
      ariaSort: 'ascending',
      event: 'sortFlagged',
    },
  ];

  sortOptions.splice(
    sortOptions.findIndex((item) =>
      folder === appConstants.DRAFT_FOLDER_ID || folder === appConstants.SENT_MAIL_FOLDER_ID
        ? item.query === Sort.FromAsc
        : item.query === Sort.ToAsc
    ),
    1
  );

  const handleSelectItem = (sortString: Sort, event: string): void => {
    trackEvent({ event });
    setSearchQueryParam('sort', sortString);
    setSearchQueryParam('page', '1');
    getMessageCollection(sortString);
  };

  return (
    <div
      className={`${styles.sortOptionsContainer} ${
        messageCollectionLoading || messageCollectionFetchError ? styles.disabled : ''
      }`}
    >
      <KiteMenu
        label={t('sort')}
        variant={'flow'}
        disabled={messageCollectionLoading || messageCollectionFetchError}
        aria-disabled={messageCollectionLoading || messageCollectionFetchError}
        data-id="e2e_sort_button"
        onToggle={(expanded) => (expanded ? trackEvent({ event: 'sortMenuClick' }) : null)}
      >
        {sortOptions.map((item, index) => {
          const isSelected = selected === item.query;

          return (
            <KiteMenuItem
              key={index}
              className={styles.listItem}
              onClick={() => handleSelectItem(item.query, item.event)}
              aria-sort={item.ariaSort}
            >
              {isSelected ? <KiteIcon icon={'ki-checkmark'} className={styles.checkIcon} /> : <></>}
              <span className={isSelected ? styles.selected : ''}>{item.label}</span>
            </KiteMenuItem>
          );
        })}
      </KiteMenu>
    </div>
  );
};

export default SortOptions;
