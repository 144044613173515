import { MailForm, PriorityEnum } from 'generated/graphql';
import { ComposeFormInput } from 'slices/compose';
import { ValidationKeys } from 'translation/hooks';
import * as yup from 'yup';

const composeMailFormHelper = (compose: ComposeFormInput, replyTo?: string): MailForm => {
  const form: MailForm = {};
  if (compose.to && compose.to.length > 0) {
    form.to = compose.to?.map((to) => {
      return {
        address: to.address,
        name: to.name,
      };
    });
  }

  if (compose.cc && compose.cc.length > 0) {
    form.cc = compose.cc?.map((cc) => {
      return {
        address: cc.address,
        name: cc.name,
      };
    });
  }

  if (compose.bcc && compose.bcc.length > 0) {
    form.bcc = compose.bcc?.map((bcc) => {
      return {
        address: bcc.address,
        name: bcc.name,
      };
    });
  }

  if (compose.html) {
    form.html = compose.html;
  }

  if (replyTo) {
    form.replyTo = {
      address: replyTo,
    };
  }

  if (compose.subject) {
    form.subject = compose.subject;
  }

  if (compose.priority) {
    form.priority = compose.priority as PriorityEnum;
  }

  if (compose.messageId) {
    form.messageId = compose.messageId;
  }

  if (compose.attachments && compose.attachments.length > 0) {
    form.attachments = compose.attachments.map(({ fileName, fileSize, externalRef, id }) => {
      return {
        fileName,
        fileSize,
        externalRef,
        id,
      };
    });
  }

  if (compose.headers && compose.headers.length > 0) {
    form.headers = compose.headers;
  }

  if (compose.uid) {
    form.uid = parseInt(compose.uid);
  }

  if (compose.isReadReceiptEnabled) {
    form.readReceipt = true;
  }

  return form;
};

const composeMailFormDraftValidator = (compose: ComposeFormInput): boolean => {
  return !!compose.to?.length || !!compose.cc?.length || !!compose.bcc?.length || !!compose.subject || !!compose.html;
};

const composeMailFormSendValidator = (compose: ComposeFormInput): ValidationKeys | '' => {
  const hasEmailAddress = compose.to?.length || compose.cc?.length || compose.bcc?.length;

  if (!hasEmailAddress && !compose.html) {
    return `composeFormMissingAddressAndBody`;
  }

  if (!hasEmailAddress) {
    return `composeFormMissingAddress`;
  }

  if (!compose.html) {
    return `composeFormMissingBody`;
  }

  return '';
};

const isValidEmail = (email: string): boolean => yup.string().email().isValidSync(email);

const composeMailFormToValidator = (compose: ComposeFormInput): boolean => {
  return !!compose.to?.every((to) => isValidEmail(to.address));
};

const composeMailFormCcValidator = (compose: ComposeFormInput): boolean => {
  return !!compose.cc?.every((cc) => isValidEmail(cc.address));
};

const composeMailFormBccValidator = (compose: ComposeFormInput): boolean => {
  return !!compose.bcc?.every((bcc) => isValidEmail(bcc.address));
};

export {
  composeMailFormHelper,
  composeMailFormDraftValidator,
  composeMailFormSendValidator,
  composeMailFormToValidator,
  composeMailFormCcValidator,
  composeMailFormBccValidator,
  isValidEmail,
};
