import { ApolloError } from '@apollo/client';
import { createSlice } from '@reduxjs/toolkit';
import { LabelEntity } from 'generated/graphql';
import { deleteLabelThunk } from 'thunks/contact/deleteLabelThunk';
import { createLabelThunk } from 'thunks/contact/createLabelThunk';
import { labelCollectionThunk } from 'thunks/contact/labelCollectionThunk';
import { updateLabelThunk } from 'thunks/contact/updateLabelThunk';
import { updateIsOpen } from 'slices/app';

interface LabelCollectionState {
  labelCollection: LabelEntity[];
  apiError?: Error | null;
}

const initialState: LabelCollectionState = {
  labelCollection: [],
};

export const labelCollectionSlice = createSlice({
  name: 'labelCollection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(labelCollectionThunk.fulfilled, (state, action) => {
      if (action.payload.success && !action.payload.isPrefetch) {
        state.labelCollection = action.payload.success;
        return;
      }
      if (action.payload.error && !action.payload.isPrefetch) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(deleteLabelThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.labelCollection = state.labelCollection?.filter((label) => {
          return ![action.payload.labelId].includes(label.id);
        });
      }
      if (action.payload.error) {
        state.apiError = action.payload.error;
      }
      return;
    });

    builder.addCase(createLabelThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.labelCollection.push(action.payload.success as LabelEntity);
        return;
      }
      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(updateLabelThunk.fulfilled, (state, action) => {
      const index = state.labelCollection?.findIndex((label) => label.id === action.payload.success?.id);
      if (action.payload.success) {
        state.labelCollection = state.labelCollection?.map((label, idx) => {
          if (idx === index) {
            return action.payload.success;
          }
          return label;
        }) as LabelEntity[];
      }
      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });
    builder.addCase(updateIsOpen, (state, action) => {
      if (action.payload === false) {
        state.apiError = undefined;
      }
    });
  },
});

export default labelCollectionSlice.reducer;
