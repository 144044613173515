import { ApolloLink, GraphQLRequest } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAccessToken, getIdToken } from 'utils/accessTokenLocalStorage';
import { getAppEnvironment } from 'app-constants/appConfig';
import buildVisitAndTraceIdHeaders from 'utils/buildVisitAndTraceIdHeaders';

interface SetContextInterface extends Record<string, unknown> {
  authorization?: string;
  'X-USER-JWT'?: string;
}

type ContextType<T = Record<string, unknown>> = { headers: T };

const contextSetter = (_request: GraphQLRequest, { headers }: ContextType): ContextType<SetContextInterface> => {
  let authorizationToken = getAccessToken();
  if (getAppEnvironment() === 'dev') {
    authorizationToken = getIdToken();
  }

  if (authorizationToken && authorizationToken.length > 0) {
    return getAppEnvironment() === 'dev'
      ? {
          headers: {
            ...headers,
            ...buildVisitAndTraceIdHeaders(),
            'X-USER-JWT': authorizationToken,
          },
        }
      : {
          headers: {
            ...headers,
            ...buildVisitAndTraceIdHeaders(),
            authorization: `bearer ${authorizationToken}`,
          },
        };
  }

  return {
    headers,
  };
};

export const authLink = (): ApolloLink => {
  return setContext(contextSetter);
};
