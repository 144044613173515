import React from 'react';
import ContactSection from 'components/contact-form/sections/ContactSection';
import { KiteIcon, KiteRadio, KiteRadioButton, KiteTextInput } from '@kite/react-kite';
import styles from 'components/contact-form/styles/ContactCreateContact.module.scss';
import { ContactSectionProps } from 'components/contact-form/initialState';
import { ContactFormInterface } from 'components/contact-form/initialState';
import { forEach } from 'lodash';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import validateContactField, { getContactValidationErrorMessage } from 'utils/validateContactField';
import { KiteInputElems } from 'libs/kite/types';
import { usePageTranslation } from 'translation/hooks';
import { phoneNumberTrans } from './translationKeys';

type PhoneNumberFields = Pick<ContactFormInterface, 'homePhone' | 'workPhone' | 'mobilePhone'>;
type PhoneNumberKeys = keyof PhoneNumberFields;
type HandleNumberChange = (value: NumberFormatValues, formKey: keyof PhoneNumberFields, error: string) => void;
type HandleNumberBlur = (e: React.FocusEvent<KiteInputElems>, formKey: keyof PhoneNumberFields, error: string) => void;

export const Phone: React.FC<ContactSectionProps<PhoneNumberFields>> = ({
  updateFormValues,
  values,
  errors,
  setErrors,
}) => {
  const { t } = usePageTranslation('contactFormCopy');

  const selectDefault = (event: React.ChangeEvent<HTMLInputElement>): void => {
    forEach(values, (phoneNumber, key) => {
      if (phoneNumber) {
        phoneNumber.isDefault = phoneNumber.phoneNumberType?.toLowerCase() === event.target.value;
      }

      updateFormValues(phoneNumber, key as PhoneNumberKeys);
    });
  };

  const homePhoneErrorMessage = getContactValidationErrorMessage(errors, 'homePhone.phoneNumber', phoneNumberTrans);
  const mobilePhoneErrorMessage = getContactValidationErrorMessage(errors, 'mobilePhone.phoneNumber', phoneNumberTrans);
  const workPhoneErrorMessage = getContactValidationErrorMessage(errors, 'workPhone.phoneNumber', phoneNumberTrans);
  const validate = (value: string, formKey: keyof PhoneNumberFields): boolean =>
    validateContactField(`${formKey}.phoneNumber`, { [formKey]: { phoneNumber: value } }, errors, setErrors);

  const handleNumberChange: HandleNumberChange = ({ formattedValue }, formKey, error) => {
    updateFormValues({ ...values[formKey], phoneNumber: formattedValue }, formKey);
    if (error) {
      validate(formattedValue, formKey);
    }
  };
  const handleOnBlur: HandleNumberBlur = (e, formKey, error): void => {
    const isValid = validate(e.currentTarget.value, formKey);
    if (!isValid && !error) {
      e.currentTarget.focus();
    }
  };

  return (
    <ContactSection>
      <KiteRadio onChange={(e) => selectDefault(e)}>
        <div className={styles.fieldContainerWithRadio}>
          <KiteIcon className={styles.fieldIcon} icon="ki-phone" />
          <NumberFormat
            format="###-###-####"
            placeholder={t('phonePlaceholder')}
            label={t('homePhone')}
            className={styles.fullField}
            customInput={KiteTextInput}
            onValueChange={(value) => handleNumberChange(value, 'homePhone', homePhoneErrorMessage)}
            onBlur={(e: React.FocusEvent<KiteInputElems>) => handleOnBlur(e, 'homePhone', homePhoneErrorMessage)}
            value={values?.homePhone?.phoneNumber || ''}
            errorMessage={t(homePhoneErrorMessage)}
            aria-label={'enter home phone number'}
            ariaLive="polite"
          />
          <div className={styles.fieldRadio}>
            <KiteRadioButton
              id="homePhoneRadio"
              label={`${t('primary')}?`}
              name={'defaultPhone'}
              value={'home'}
              checked={values.homePhone?.isDefault || false}
              aria-label={'assign home phone as default'}
            />
          </div>
        </div>
        <div className={styles.fieldContainerWithRadio}>
          <KiteIcon className={styles.fieldIcon} icon="ki-mobile" />
          <NumberFormat
            format="###-###-####"
            placeholder={t('phonePlaceholder')}
            label={t('mobilePhone')}
            className={styles.fullField}
            customInput={KiteTextInput}
            onValueChange={(value) => handleNumberChange(value, 'mobilePhone', mobilePhoneErrorMessage)}
            onBlur={(e: React.FocusEvent<KiteInputElems>) => handleOnBlur(e, 'mobilePhone', mobilePhoneErrorMessage)}
            value={values?.mobilePhone?.phoneNumber || ''}
            errorMessage={t(mobilePhoneErrorMessage)}
            aria-label={'enter mobile phone number'}
            ariaLive="polite"
          />
          <div className={styles.fieldRadio}>
            <KiteRadioButton
              id="mobilePhoneRadio"
              label={`${t('primary')}?`}
              name={'defaultPhone'}
              value={'mobile'}
              checked={values.mobilePhone?.isDefault || false}
              aria-label={'assign mobile phone as default'}
            />
          </div>
        </div>
        <div className={styles.fieldContainerWithRadio}>
          <KiteIcon className={styles.fieldIcon} icon="ki-phone-office" />
          <NumberFormat
            format="###-###-####"
            placeholder={t('phonePlaceholder')}
            label={t('workPhone')}
            className={styles.fullField}
            customInput={KiteTextInput}
            onValueChange={(value) => handleNumberChange(value, 'workPhone', workPhoneErrorMessage)}
            onBlur={(e: React.FocusEvent<KiteInputElems>) => handleOnBlur(e, 'workPhone', workPhoneErrorMessage)}
            value={values?.workPhone?.phoneNumber || ''}
            errorMessage={t(workPhoneErrorMessage)}
            aria-label={'enter work phone number'}
            ariaLive="polite"
          />

          <div className={styles.fieldRadio}>
            <KiteRadioButton
              id="workPhoneRadio"
              label={`${t('primary')}?`}
              name={'defaultPhone'}
              value={'work'}
              checked={values.workPhone?.isDefault || false}
              aria-label={'assign work phone as default'}
            />
          </div>
        </div>
      </KiteRadio>
    </ContactSection>
  );
};
