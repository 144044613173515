import { KiteCard } from '@kite/react-kite';
import LabelList from 'components/label-list';
import styles from 'containers/styles/ContactLabelListContainer.module.scss';

const ContactLabelListContainer: React.FC = () => {
  return (
    <KiteCard className={styles.labelContainer}>
      <section className={styles.labelItemList}>
        <LabelList />
      </section>
    </KiteCard>
  );
};

export default ContactLabelListContainer;
