import { MessageCollectionType } from 'slices/message-collection';

const fullPathRename = (oldName: string[], newName: string[], currentMailbox: string[]): string[] => {
  const sourceMailbox = currentMailbox.join('/');
  let counter = 0;

  currentMailbox.every((item, idx) => {
    if (item !== oldName[idx]) {
      return false;
    }
    if (item === oldName[idx]) {
      counter += 1;
      if (counter === oldName.length) {
        currentMailbox[idx] = newName[idx];
        return false;
      }
    }
    return true;
  });

  const newMailboxName = currentMailbox.join('/');

  return [sourceMailbox, newMailboxName];
};

const updateFolderNames = (
  newName: string,
  oldName: string,
  collection: MessageCollectionType
): MessageCollectionType => {
  const oldMailboxName = oldName.split('/');
  const newMailboxName = newName.split('/');
  const transformed = { ...collection };

  for (const mailbox in transformed) {
    const currentMailboxNames = atob(mailbox).split('/');
    const [original, renamed] = fullPathRename(oldMailboxName, newMailboxName, currentMailboxNames);

    if (original !== renamed) {
      transformed[btoa(renamed)] = { ...transformed[btoa(original)] };
      delete transformed[btoa(original)];
    }
  }

  return transformed;
};

export default updateFolderNames;
