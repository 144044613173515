import Helix from '@charter/helix/build/portals/index.min';
import { v4 as uuidv4 } from 'uuid';

interface BuildVisitAndTraceIdHeaders {
  'X-VISIT-ID': string;
  'X-WEBMAIL-TRACE-ID': string;
}

const buildVisitAndTraceIdHeaders = (): BuildVisitAndTraceIdHeaders => {
  return {
    'X-VISIT-ID': Helix.get('visitId'),
    'X-WEBMAIL-TRACE-ID': uuidv4(),
  };
};

export default buildVisitAndTraceIdHeaders;
