import React, { useEffect } from 'react';
import 'libs/globals';
import 'components/app/App.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  useGetContactCollectionLazyQuery,
  useGetContactCountLazyQuery,
  useGetContactReSyncAllowedLazyQuery,
  useGetLabelCollectionLazyQuery,
  useGetLdapSettingLazyQuery,
  useGetQuotaLazyQuery,
  useGetSettingLazyQuery,
  useGetSignatureCollectionLazyQuery,
} from 'generated/graphql';
import { signatureCollectionGetThunk } from 'thunks/setting/signatureCollectionThunk';
import { getLdapSettingThunk } from 'thunks/setting/getLdapSettingThunk';
import { getSettingThunk } from 'thunks/setting/getSettingThunk';
import { mailboxQuotaThunk } from 'thunks/mailbox-collection/mailboxQuotaThunk';
import { TrackingHook, useTracking } from 'react-tracking';
import { contactCountThunk } from 'thunks/contact/contactCountThunk';
import { contactCollectionThunk } from 'thunks/contact/contactCollectionThunk';
import { labelCollectionThunk } from 'thunks/contact/labelCollectionThunk';
import { appConstants } from 'appConstants';
import { getContactReSyncAllowedThunk } from 'thunks/canary/getContactResyncAllowedThunk';

const PreFetchSetting: React.FC = () => {
  const isSettingDownloaded = useAppSelector((state) => state.settingSlice.isSettingDownloaded);
  const isAutoReplyDownloaded = useAppSelector((state) => state.autoReplySlice.isAutoReplyDownloaded);
  const signature = useAppSelector((state) => state.signatureSlice.signatureEntity);
  const { quota, usage } = useAppSelector((state) => state.quotaSlice);
  const totalContactCount = useAppSelector((state) => state.contactSlice.totalContactCount);
  const autocompleteContacts = useAppSelector((state) => state.contactAutocompleteSlice.contacts);
  const labels = useAppSelector((state) => state.contactAutocompleteSlice.labels);
  const optInStatus = useAppSelector((state) => state.canarySlice.optInStatus);
  const { isContactMigrationLoading, isSettingMigrationLoading } = useAppSelector((state) => state.migrationSlice);
  const { isCanary } = useAppSelector((state) => state.appSlice);
  const reloadContactsCollection = useAppSelector((state) => state.contactAutocompleteSlice.reloadContactsCollection);

  const dispatch = useAppDispatch();
  const [getSignatureCollection, { loading: signatureLoading }] = useGetSignatureCollectionLazyQuery();
  const [getLdapSettingHook] = useGetLdapSettingLazyQuery();
  const [getSettingHook] = useGetSettingLazyQuery();
  const [getMailboxQuotaHook] = useGetQuotaLazyQuery();
  const [getContactCountHook, { loading: contactCountLoading }] = useGetContactCountLazyQuery();
  const [getContactCollectionHook, { loading: autocompleteContactsLoading }] = useGetContactCollectionLazyQuery();
  const [getLabelCollectionHook, { loading: labelsLoading }] = useGetLabelCollectionLazyQuery();
  const [getContactReSyncAllowedHook, { loading: contactReSyncAllowedLoading }] = useGetContactReSyncAllowedLazyQuery();

  const { trackEvent }: TrackingHook = useTracking({ page: 'settings' });

  useEffect(() => {
    if (optInStatus !== 'yes' || isContactMigrationLoading || isSettingMigrationLoading || isCanary) {
      return;
    }

    if (!contactReSyncAllowedLoading) {
      dispatch(getContactReSyncAllowedThunk({ getContactReSyncAllowedHook }));
    }

    if (!signature && !signatureLoading) {
      dispatch(signatureCollectionGetThunk({ getSignatureCollection }));
    }

    if (!isSettingDownloaded) {
      dispatch(getSettingThunk({ getSettingHook }));
    }

    if (!isAutoReplyDownloaded) {
      dispatch(getLdapSettingThunk({ getLdapSettingHook }));
    }

    if (!quota) {
      dispatch(mailboxQuotaThunk({ getMailboxQuotaHook }));
    }

    if (quota) {
      trackEvent({ event: 'emailStorage', usage });
    }
    if (!totalContactCount && !contactCountLoading) {
      dispatch(contactCountThunk({ getContactCountHook }));
    }

    if (reloadContactsCollection || (!autocompleteContacts && !autocompleteContactsLoading)) {
      dispatch(
        contactCollectionThunk({
          getContactCollectionHook,
          limit: appConstants.CONTACT_MAX_COUNT,
          isPrefetch: true,
        })
      );
    }

    if (!labels && !labelsLoading) {
      dispatch(labelCollectionThunk({ getLabelCollectionHook, isPrefetch: true }));
    }
  }, [optInStatus, isContactMigrationLoading, isSettingMigrationLoading, isCanary, reloadContactsCollection]);

  return <></>;
};

export default PreFetchSetting;
