import { appConstants } from 'appConstants';
import styles from 'components/header/styles/CanaryLandingPageHeaderStyles.module.scss';

const CanaryLandingPageHeader: React.FC = () => {
  return (
    <div className={styles.headerContainer}>
      <img alt="logo" src={appConstants.ui.CHARTER_LOGO_LINK} className={styles.spectrumLogo} />
    </div>
  );
};

export default CanaryLandingPageHeader;
