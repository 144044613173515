import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import en from 'translation/en_US/index';

i18next.init({
  interpolation: { escapeValue: false, prefix: '*{', suffix: '}*' }, // React already does escaping
  fallbackLng: 'en',
  resources: { en },
});

const TranslationProvider: React.FC = ({ children }) => {
  return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
};

export default TranslationProvider;
