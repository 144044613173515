export const linksCopy = {
  openInNewWindow: '',
  accountSupport: '',
  ccpaYourPrivacyRights: '',
  ccpaConsumerPrivacyRights: '',
  ccpaDoNotSell: '',
  ccpaLimitUse: '',
  contact: '',
  dvrWatchTv: '',
  facebookLabel: '',
  guideWatchTv: '',
  homeSupport: '',
  instagramLabel: '',
  internetSupport: '',
  legalPolicies: '',
  libraryWatchTv: '',
  liveWatchTv: '',
  location: '',
  mobileSupport: '',
  ondemandWatchTv: '',
  tvSupport: '',
  twitterLabel: '',
  voiceSupport: '',
  weather: '',
  youtubeLabel: '',
  supportLink: '',
  accountSupportBurger: '',
  getSupport: '',
  watchTv: '',
  account: '',
  support: '',
  watch: '',
  accountAria: '',
  supportAria: '',
  socialMediaDescription: '',
};
