import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MailboxEntity, MailboxForm, RenameMailboxMutationFn } from 'generated/graphql';

interface RenameMailboxThunkArgs {
  payload: {
    mailbox: MailboxForm;
    oldMailboxId: string;
    fullPath: string;
  };
  renameMailboxHook: RenameMailboxMutationFn;
}

interface RenameMailboxThunkResponse {
  success?: MailboxEntity;
  oldMailboxId: string;
  error?: ApolloError;
  fullPath: string;
}

export const renameMailboxThunk = createAsyncThunk(
  'renameMailboxThunk',
  async (arg: RenameMailboxThunkArgs): Promise<RenameMailboxThunkResponse> => {
    try {
      const response = await arg.renameMailboxHook({
        variables: arg.payload,
      });
      return {
        success: response.data?.renameMailbox,
        oldMailboxId: arg.payload.oldMailboxId,
        fullPath: arg.payload.fullPath,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
        oldMailboxId: arg.payload.oldMailboxId,
        fullPath: arg.payload.fullPath,
      };
    }
  }
);
