import { ApolloError } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appConstants, GlobalFoldersInterface } from 'appConstants';
import { MailboxEntity, MailboxStatusEntity } from 'generated/graphql';
import { updateIsOpen } from 'slices/app';
import { globalMailboxStatusThunk } from 'thunks/mailbox-collection/globalMailboxStatusThunk';
import { mailboxCollectionGetThunk } from 'thunks/mailbox-collection/mailboxCollectionThunk';
import { mailboxStatusThunk } from 'thunks/mailbox-collection/mailboxStatusThunk';
import { createMailboxThunk } from 'thunks/mailbox/createMailboxThunk';
import { deleteMailboxThunk } from 'thunks/mailbox/deleteMailboxThunk';
import { renameMailboxThunk } from 'thunks/mailbox/renameMailboxThunk';
import { moveMessagesThunk } from 'thunks/message-collection/moveMessagesThunk';
import { permanentDeleteMessageBulkThunk } from 'thunks/message-collection/permanentDeleteMessageBulkThunk';
import { permanentDeleteMessageThunk } from 'thunks/message-collection/permanentDeleteMessageThunk';
import { getMessageThunk } from 'thunks/message/messageThunk';
import { getSpamFolderId, isInboxFolder } from 'utils/folderNameValidator';
import { mapGlobalMailboxNames, normalizeMailboxCollection } from 'utils/mailboxCollectionHelper';
import { isMessageUnread } from 'utils/messageFlagsUtils';

interface MailBoxCollectionState {
  mailboxCollection?: MailboxEntity[];
  globalMailboxStatus?: MailboxStatusEntity[];
  apiError?: Error | null;
  mailboxFetchError?: Error | null;
  globalMailboxFetchError?: Error | null;
  mailboxLoading: boolean;
  mailboxCollectionId?: number;
  currentMailbox?: string;
  globalMailboxStatusLoading: boolean;
  spamFolderId: string;
}

const initialState: MailBoxCollectionState = {
  globalMailboxStatusLoading: false,
  mailboxLoading: false,
  spamFolderId: '',
};

export const mailboxCollectionSlice = createSlice({
  name: 'mailBoxCollection',
  initialState,
  reducers: {
    addToMailCollection: (state: MailBoxCollectionState, action: PayloadAction<MailboxEntity[]>) => {
      state.mailboxCollection = action.payload;
    },
    updateMailBoxLoading: (state: MailBoxCollectionState, action: PayloadAction<boolean>) => {
      state.mailboxLoading = action.payload;
    },
    resetMailboxApiError: (state) => {
      state.apiError = null;
    },
    updateUnreadCountForMailbox: (
      state: MailBoxCollectionState,
      action: PayloadAction<{ mailboxId: string; updateCount: number }>
    ) => {
      if (state.globalMailboxStatus) {
        const mailboxStatusIndex = state.globalMailboxStatus.findIndex(
          (mailboxStatus) => mailboxStatus.id === action.payload.mailboxId
        );

        if (mailboxStatusIndex >= 0) {
          state.globalMailboxStatus[mailboxStatusIndex].unseenMessageCount += action.payload.updateCount;

          if (state.globalMailboxStatus[mailboxStatusIndex].unseenMessageCount < 0) {
            state.globalMailboxStatus[mailboxStatusIndex].unseenMessageCount = 0;
          }
        }
      }
    },
    updateTotalCountForMailbox: (
      state: MailBoxCollectionState,
      action: PayloadAction<{ mailboxId: string; updateCount: number }>
    ) => {
      if (state.globalMailboxStatus) {
        const mailboxStatusIndex = state.globalMailboxStatus.findIndex(
          (mailboxStatus) => mailboxStatus.id === action.payload.mailboxId
        );

        if (mailboxStatusIndex >= 0) {
          state.globalMailboxStatus[mailboxStatusIndex].totalMessageCount += action.payload.updateCount;

          if (state.globalMailboxStatus[mailboxStatusIndex].totalMessageCount < 0) {
            state.globalMailboxStatus[mailboxStatusIndex].totalMessageCount = 0;
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(mailboxCollectionGetThunk.pending, (state) => {
      state.mailboxLoading = true;
    });
    builder.addCase(globalMailboxStatusThunk.pending, (state) => {
      state.globalMailboxStatusLoading = true;
    });
    builder.addCase(mailboxCollectionGetThunk.fulfilled, (state, action) => {
      state.mailboxLoading = false;
      if (action.payload.success && state.globalMailboxStatus) {
        state.mailboxFetchError = null;
        const mailboxes = normalizeMailboxCollection(action.payload.success, state.globalMailboxStatus);
        state.mailboxCollection = mailboxes.sort((a, b) => {
          return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
        });
        return;
      }
      if (action.payload.error) {
        state.mailboxFetchError = action.payload.error as ApolloError;
        return;
      }
    });
    builder.addCase(getMessageThunk.fulfilled, (state, action) => {
      if (
        action.payload.success &&
        state.globalMailboxStatus &&
        isInboxFolder(action.payload.mailboxId!) &&
        isMessageUnread(action.payload.success)
      ) {
        const inboxStatusIndex = state.globalMailboxStatus.findIndex(
          (mailboxStatus) => mailboxStatus.id === action.payload.mailboxId
        );
        state.globalMailboxStatus[inboxStatusIndex].unseenMessageCount -= 1;
      }
    });
    builder.addCase(globalMailboxStatusThunk.fulfilled, (state, action) => {
      state.globalMailboxStatusLoading = false;
      if (action.payload.success) {
        state.globalMailboxStatus = mapGlobalMailboxNames(action.payload.success);
        state.spamFolderId = getSpamFolderId(action.payload.success);
        state.globalMailboxFetchError = null;
        return;
      }
      if (action.payload.error) {
        state.globalMailboxFetchError = action.payload.error;
      }
    });
    builder.addCase(createMailboxThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.mailboxCollection?.push(action.payload.success);
        state.apiError = null;
      }
      if (action.payload.error) {
        state.apiError = action.payload.error;
      }
    });
    builder.addCase(renameMailboxThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        const index = state.mailboxCollection?.findIndex((mailbox) => mailbox.id === action.payload.oldMailboxId);
        if (index && action.payload.success) {
          const newMailboxCollection: MailboxEntity[] = state.mailboxCollection?.map((mailbox, idx) => {
            if (idx === index) {
              return action.payload.success;
            }
            if (mailbox.name.startsWith(action.payload.fullPath) && action.payload.success) {
              const newName = mailbox.name.replace(action.payload.fullPath, action.payload.success.name);
              return {
                ...mailbox,
                name: newName,
                id: btoa(newName),
              };
            }
            return mailbox;
          }) as MailboxEntity[];

          state.mailboxCollection = newMailboxCollection;
        }
        state.apiError = null;
      }
      if (action.payload.error) {
        state.apiError = action.payload.error;
      }
    });
    builder.addCase(deleteMailboxThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        const deletedMailboxList = action.payload.success.mailboxDeleteStatus.map((mailbox): string => {
          return !mailbox.isGlobal ? mailbox.mailbox : '';
        });

        state.globalMailboxStatus?.map((mailbox) => {
          if (mailbox.id === appConstants.TRASH_FOLDER_ID) {
            mailbox.totalMessageCount = 0;
          }
        });

        state.mailboxCollection = state.mailboxCollection?.filter((mailbox) => {
          return !deletedMailboxList.includes(mailbox.name);
        });

        state.apiError = null;
      }

      if (action.payload.error) {
        state.apiError = action.payload.error;
      }
    });
    builder.addCase(updateIsOpen, (state, action) => {
      if (action.payload === false) {
        state.apiError = undefined;
      }
    });
    builder.addCase(mailboxStatusThunk.fulfilled, (state, action) => {
      if (action.payload.mailboxId && appConstants.GLOBAL_FOLDERS.includes(action.payload.mailboxId)) {
        state.globalMailboxStatus = state.globalMailboxStatus?.map((mailbox) => {
          if (mailbox.id === action.payload.mailboxId && action.payload.success) {
            return {
              ...action.payload.success,
              name: appConstants.GLOBAL_FOLDER_NAMES[action.payload.success.name as keyof GlobalFoldersInterface],
            };
          }
          return mailbox;
        });
      }
    });
    builder.addCase(permanentDeleteMessageBulkThunk.fulfilled, (state, action) => {
      if (state.globalMailboxStatus) {
        const mailboxStatusIndex = state.globalMailboxStatus.findIndex(
          (mailboxStatus) => mailboxStatus.id === action.payload.mailboxId
        );

        if (mailboxStatusIndex >= 0) {
          state.globalMailboxStatus[mailboxStatusIndex].totalMessageCount -= action.payload.messageUids.length;
        }
      }
    });
    builder.addCase(permanentDeleteMessageThunk.fulfilled, (state, action) => {
      if (state.globalMailboxStatus) {
        const mailboxStatusIndex = state.globalMailboxStatus.findIndex(
          (mailboxStatus) => mailboxStatus.id === action.payload.mailboxId
        );

        if (mailboxStatusIndex >= 0) {
          state.globalMailboxStatus[mailboxStatusIndex].totalMessageCount -= 1;
        }
      }
    });
    builder.addCase(moveMessagesThunk.fulfilled, (state, action) => {
      if (state.globalMailboxStatus) {
        const { sourceMailboxId, destinationMailboxId, unseenMessagesCount, messageUids } = action.payload;
        const destinationMailboxStatusIndex = state.globalMailboxStatus.findIndex(
          (mailboxStatus) => mailboxStatus.id === destinationMailboxId
        );
        const sourceMailboxStatusIndex = state.globalMailboxStatus.findIndex(
          (mailboxStatus) => mailboxStatus.id === sourceMailboxId
        );

        if (sourceMailboxId && isInboxFolder(sourceMailboxId)) {
          state.globalMailboxStatus[sourceMailboxStatusIndex].unseenMessageCount -= unseenMessagesCount || 0;

          if (destinationMailboxStatusIndex >= 0) {
            state.globalMailboxStatus[destinationMailboxStatusIndex].totalMessageCount += messageUids.length;
          }
          return;
        }

        if (destinationMailboxId && isInboxFolder(destinationMailboxId)) {
          state.globalMailboxStatus[destinationMailboxStatusIndex].unseenMessageCount += unseenMessagesCount || 0;

          if (sourceMailboxStatusIndex >= 0) {
            state.globalMailboxStatus[sourceMailboxStatusIndex].totalMessageCount -= messageUids.length;
          }
          return;
        }

        if (destinationMailboxStatusIndex >= 0) {
          state.globalMailboxStatus[destinationMailboxStatusIndex].totalMessageCount += messageUids.length;
        }

        if (sourceMailboxStatusIndex >= 0) {
          state.globalMailboxStatus[sourceMailboxStatusIndex].totalMessageCount -= messageUids.length;
        }
      }
    });
  },
});

export const {
  addToMailCollection,
  updateMailBoxLoading,
  resetMailboxApiError,
  updateUnreadCountForMailbox,
  updateTotalCountForMailbox,
} = mailboxCollectionSlice.actions;

export default mailboxCollectionSlice.reducer;
