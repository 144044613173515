import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert, KiteTextInput } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import { createMailboxThunk } from 'thunks/mailbox/createMailboxThunk';
import { useCreateMailboxMutation } from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { isValidFolderName, isDuplicate } from 'utils/folderNameValidator';
import { TrackingHook, useTracking } from 'react-tracking';
import { resetMailboxApiError } from 'slices/mailbox-collection';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { appConstants } from 'appConstants';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, ValidationKeys, useDialogTranslation } from 'translation/hooks';

interface NewFolderInterface {
  parentFolder?: string;
  fullPath?: string;
}

const NewFolder: React.FC<NewFolderInterface> = ({ parentFolder, fullPath }) => {
  const { t } = useDialogTranslation('newFolderCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'folders' });
  const apiError = useAppSelector((state) => state.mailboxCollectionSlice.apiError);
  const mailboxes = useAppSelector((state) => state.mailboxCollectionSlice.mailboxCollection);
  const [validationErrorMessage, setValidationErrorMessage] = useState<ValidationKeys | ''>('');
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const [draft, setDraft] = useState('');
  const focusOpener = useTarget('modal');
  const dispatch = useAppDispatch();
  const [createMailboxHook] = useCreateMailboxMutation();

  useEffect(() => {
    !parentFolder ? trackEvent({ event: 'viewNewFolder' }) : trackEvent({ event: 'viewNewSubFolder' });
  }, []);

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  const handleInputTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setDraft(e.target.value);
    validateInput(e.target.value);
  };

  const isSaved = async (): Promise<boolean> => {
    !parentFolder ? trackEvent({ event: 'newFolderSave' }) : trackEvent({ event: 'newSubFolderSave' });
    const result = await dispatch(
      createMailboxThunk({
        payload: {
          mailbox: { mailbox: draft, parentMailboxId: parentFolder },
        },
        createMailboxHook,
      })
    );

    // @ts-ignore
    if (result.payload.success) {
      !parentFolder ? trackEvent({ event: 'newFolderSuccess' }) : trackEvent({ event: 'newSubFolderSuccess' });
      setDraft('');
      dispatch(updateToastMessage({ message: t('successToast'), success: true }));
      return true;
    }

    !parentFolder ? trackEvent({ event: 'newFolderErrorApi' }) : trackEvent({ event: 'newSubFolderErrorApi' });
    return false;
  };

  const isCancelled = (): boolean => {
    !parentFolder ? trackEvent({ event: 'newFolderCancel' }) : trackEvent({ event: 'newSubFolderCancel' });
    setDraft('');
    setValidationErrorMessage('');
    dispatch(resetMailboxApiError());
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const validateInput = (input: string): void => {
    if (!isValidFolderName(input)) {
      trackEvent({ event: 'newFolderErrorValidation' });
      setValidationErrorMessage('invalidCharacters');
      return;
    }

    if (isDuplicate(fullPath ? `${fullPath}/${input}` : input, mailboxes)) {
      trackEvent({ event: 'newFolderErrorDuplicate' });
      setValidationErrorMessage('folderDuplicate');
      return;
    }

    if (input.length > appConstants.MAX_FOLDER_NAME_LENGTH) {
      setValidationErrorMessage('folderCharacterLimit');
      return;
    }

    setValidationErrorMessage('');
  };

  const isChanged = (): boolean => {
    return draft.length > 0;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate: () => !validationErrorMessage,
    draft,
    isChanged,
    saveButtonText: t('confirmBtnText'),
  };

  return (
    <ModalContentWrapper>
      <h5>{parentFolder ? t('subfolderHeader') : t('folderHeader')}</h5>
      <p>{t('description')}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <KiteTextInput
        className={modalStyle.textInput}
        label={parentFolder ? t('subfolderLabel') : t('folderLabel')}
        placeholder={parentFolder ? t('subfolderPlaceholder') : t('folderPlaceholder')}
        onChange={handleInputTextChange}
        value={draft}
        errorMessage={t(validationErrorMessage)}
        ariaLive="polite"
      />
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default NewFolder;
