import track from 'react-tracking';
import helixAdapter, { HelixPayloadInterface } from 'libs/tracking/adapters/helix/helixAdapter';
import submitMessageEvent from './adapters/helix/events/submitMessageEvent';

const {
  submitLoginEvent,
  submitNavigationEvent,
  submitComposeEvent,
  submitContactsEvent,
  submitLabelEvents,
  submitSettingsEvent,
  submitCanaryEvent,
  submitFolderEvents,
  submitUnsavedChangesEvent,
} = helixAdapter;

const tracker = track(
  // app-level tracking data
  { app: 'crazyMail' },

  // top-level options
  {
    dispatch: (payload: HelixPayloadInterface) => {
      switch (payload.page) {
        case 'login':
          submitLoginEvent(payload);
          break;
        case 'navigation':
          submitNavigationEvent(payload);
          break;
        case 'compose':
          submitComposeEvent(payload);
          break;
        case 'contacts':
          submitContactsEvent(payload);
          break;
        case 'labels':
          submitLabelEvents(payload);
          break;
        case 'settings':
          submitSettingsEvent(payload);
          break;
        case 'folders':
          submitFolderEvents(payload);
          break;
        case 'canary':
          submitCanaryEvent(payload);
          break;
        case 'messages':
          submitMessageEvent(payload);
          break;
        case 'unsavedChanges':
          submitUnsavedChangesEvent(payload);
          break;
        default:
          console.log('Invalid Helix top level case', payload);
      }
    },
  }
);

export default tracker;
