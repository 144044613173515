export const deleteContactCopy = {
  header: '',
  headerSingular: '',
  description: '',
  descriptionSingular: '',
  warning: '',
  cancelBtnText: '',
  cancelBtnTextSingular: '',
  successToast: '',
  successToastSingular: '',
};
