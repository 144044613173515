import React, { useEffect, useRef } from 'react';
import styles from 'components/setting-switch/styles/SettingSwitch.module.scss';
import { KiteAlert, KiteCard, KiteSwitch } from '@kite/react-kite';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import { useSharedTranslation } from 'translation/hooks';

interface Props {
  disabled: boolean;
  description: string;
  title: string;
  selected: boolean;
  loading: boolean;
  handleSwitch: () => void;
  alert: string;
  onClose?: () => void;
  ariaLabel?: string;
}

const SettingSwitch: React.FC<Props> = ({
  selected,
  handleSwitch,
  description,
  title,
  alert,
  disabled,
  loading,
  onClose,
  ariaLabel,
}) => {
  const { t } = useSharedTranslation();
  const switchRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (switchRef.current && document.activeElement === document.body) {
      switchRef.current.focus();
    }
  }, [loading]);

  const loadingSelected = loading ? !selected : selected;
  return (
    <KiteCard className={styles.statusCard}>
      <div className={styles.status}>
        <AutoFocusHeader content={title} />
        <KiteSwitch
          disabled={disabled}
          aria-disabled={disabled}
          label={`${t(loadingSelected ? 'on' : 'off')} ${loading ? `- ${t('saving')}` : ''}`}
          selected={loadingSelected}
          onClick={handleSwitch}
          aria-label={ariaLabel}
          ref={switchRef}
        />
      </div>
      <span>{description}</span>
      {alert && (
        <KiteAlert
          className={styles.alert}
          type="error"
          description={alert}
          level="page"
          dismissible
          onClose={onClose}
        />
      )}
    </KiteCard>
  );
};

export default SettingSwitch;
