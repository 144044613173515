import React from 'react';
import useMobileSize from 'components/common/hooks/useMobileSize';
import MobileLayout from 'components/layout/contact/MobileLayout';
import DesktopLayout from 'components/layout/contact/DesktopLayout';

const ContactLayout: React.FC = () => {
  const isMobile = useMobileSize();

  return isMobile ? <MobileLayout /> : <DesktopLayout />;
};

export default ContactLayout;
