import React, { useEffect } from 'react';
import styles from 'components/login-error/styles/LoginError.module.scss';
import { KiteButton, KiteIcon } from '@kite/react-kite';
import { useSharedTranslation } from 'translation/hooks';
import { redirectToPortal } from 'utils/logoutUtil';
import { TrackingHook, useTracking } from 'react-tracking';

const LoginError: React.FC = () => {
  const { t } = useSharedTranslation();
  const { trackEvent }: TrackingHook = useTracking({ page: 'login' });

  useEffect(() => {
    trackEvent({ event: 'loginErrorModalView' });
  }, []);

  const handleOkButton = (): void => {
    trackEvent({ event: 'loginErrorOkButton' });
    redirectToPortal();
  };

  return (
    <div className={styles.errorContainer}>
      <div className={styles.content}>
        <KiteIcon className={styles.icon} icon={'ki-caution-circle-f'} size="46px" fill="#D6312B" />
        <h1 className={styles.header}>{t('loginErrorHeader')}</h1>
        <p className={styles.description}>{t('loginErrorDescription')}</p>
        <KiteButton variant={'primary'} className={styles.okButton} onClick={handleOkButton}>
          {t('ok')}
        </KiteButton>
      </div>
    </div>
  );
};

export default LoginError;
