import React from 'react';
import styles from 'components/contact-form/styles/ContactCreateContact.module.scss';

type ContactSectionProps = {
  isAddress?: boolean;
};

const ContactSection: React.FC<ContactSectionProps> = ({ children, isAddress }) => {
  return <div className={isAddress ? styles.fieldSectionAddress : styles.fieldSection}>{children}</div>;
};

export default ContactSection;
