import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useMobileSize from 'components/common/hooks/useMobileSize';
import MobileLayout from 'components/layout/inbox/MobileLayout';
import DesktopLayout from 'components/layout/inbox/DesktopLayout';
import { useAppDispatch } from 'hooks';
import { clearMessageSlice } from 'slices/message';
import { updateMaximizeCompose } from 'slices/compose';

const InboxLayout: React.FC = () => {
  const isMobile = useMobileSize();
  const { '*': mailId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isMobile) {
      dispatch(updateMaximizeCompose(false));
    }
  }, [isMobile]);

  useEffect(() => {
    if (!mailId) {
      dispatch(clearMessageSlice());
    }
  }, [mailId]);

  return isMobile ? <MobileLayout /> : <DesktopLayout />;
};

export default InboxLayout;
