import { FieldValidatorInitialStateInterface } from 'hooks/useContactFieldValidatorError';
import React from 'react';
import { ContactAddressEntity, ContactEmailEntity, ContactPhoneEntity } from 'generated/graphql';

export interface ContactSectionProps<T> {
  updateFormValues: (
    value: ContactFormInterface[keyof ContactFormInterface],
    field: keyof ContactFormInterface
  ) => void;
  values: T;
  errors: FieldValidatorInitialStateInterface;
  setErrors: React.Dispatch<React.SetStateAction<FieldValidatorInitialStateInterface>>;
}

export interface ContactFormInterface {
  firstName: string;
  lastName: string;
  displayName: string;
  nickName: string;
  title: string;
  company: string;
  emails: ContactEmailEntity[];
  homePhone?: ContactPhoneEntity;
  mobilePhone?: ContactPhoneEntity;
  workPhone?: ContactPhoneEntity;
  homeAddress?: ContactAddressEntity;
  workAddress?: ContactAddressEntity;
  dateOfBirth: string;
  url: string;
  notes: string;
}

export const initialEmail: ContactEmailEntity = {
  emailAddress: '',
  isDefault: false,
};

export const initialState: ContactFormInterface = {
  firstName: '',
  lastName: '',
  displayName: '',
  nickName: '',
  title: '',
  company: '',
  emails: [initialEmail],
  homePhone: { phoneNumber: '', phoneNumberType: 'HOME', isDefault: false },
  mobilePhone: { phoneNumber: '', phoneNumberType: 'MOBILE', isDefault: false },
  workPhone: { phoneNumber: '', phoneNumberType: 'WORK', isDefault: false },
  homeAddress: {
    state: '',
    streetAddress: '',
    city: '',
    zipCode: '',
    country: '',
  },
  workAddress: {
    state: '',
    streetAddress: '',
    city: '',
    zipCode: '',
    country: '',
  },
  dateOfBirth: '',
  url: '',
  notes: '',
};
