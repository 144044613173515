import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import styles from 'components/folder-list/styles/FolderList.module.scss';
import { KiteButton, KiteIcon, KiteProgressIndicator } from '@kite/react-kite';
import { useGetGlobalMailBoxStatusLazyQuery, useGetMailBoxCollectionLazyQuery } from 'generated/graphql';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { mailboxCollectionGetThunk } from 'thunks/mailbox-collection/mailboxCollectionThunk';
import folderCollectionHelper, { FolderCollectionHelperInterface } from 'utils/folderCollectionHelper';
import { updateIsOpen, updateModalName, updateModalProps } from 'slices/app';
import FolderListItem from 'components/folder-list/FolderListItem';
import { TrackingHook, useTracking } from 'react-tracking';
import { globalMailboxStatusThunk } from 'thunks/mailbox-collection/globalMailboxStatusThunk';
import ServerError from 'components/common/ServerError';
import { Scrollbar } from 'components/common/scrollbar';
import { updateModalOpener } from 'components/common/focus/FocusContext';
import { usePageTranslation } from 'translation/hooks';

interface FolderListPropsInterface {
  isDrawer?: boolean;
  handleDrawerClose?: () => void;
}

const FolderList: React.FC<FolderListPropsInterface> = ({ isDrawer, handleDrawerClose }) => {
  const { t } = usePageTranslation('emailNavigationCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'folders' });
  const { mailboxCollection, globalMailboxStatus, globalMailboxStatusLoading, mailboxLoading } = useAppSelector(
    ({ mailboxCollectionSlice }) => mailboxCollectionSlice
  );
  const [folders, setFolders] = useState<FolderCollectionHelperInterface>();
  const updateModalRef = updateModalOpener();
  const [getMailBoxCollectionHook] = useGetMailBoxCollectionLazyQuery();
  const [getGlobalMailboxStatusHook] = useGetGlobalMailBoxStatusLazyQuery();
  const { mailboxFetchError, globalMailboxFetchError } = useAppSelector((state) => state.mailboxCollectionSlice);

  const dispatch = useAppDispatch();

  let { folder } = useParams<{ folder: string }>();

  if (isDrawer) {
    folder = useLocation().pathname.split('/').pop();
  }

  if (!folder) {
    return <Navigate to={'/server-error?component=FolderList'} />;
  }

  const selectedFolder = decodeURIComponent(folder);

  useEffect(() => {
    if (!mailboxCollection?.length) {
      dispatchMailboxCollection();
    }
  }, [mailboxCollection?.length]);

  useEffect(() => {
    if (mailboxCollection && globalMailboxStatus) {
      setFolders(folderCollectionHelper(mailboxCollection, globalMailboxStatus));
    }
  }, [mailboxCollection, globalMailboxStatus]);

  const dispatchMailboxCollection = async (): Promise<void> => {
    await dispatch(globalMailboxStatusThunk({ getGlobalMailboxStatusHook }));
    await dispatch(mailboxCollectionGetThunk({ getMailBoxCollectionHook }));
  };

  const handleNewFolderButton = (parentFolder?: string, fullPath?: string, isFirstParent?: boolean): void => {
    isFirstParent ? trackEvent({ event: 'newFolderClick' }) : trackEvent({ event: 'newSubFolderClick' });
    dispatch(updateModalName('newFolder'));
    dispatch(updateModalProps({ parentFolder, fullPath }));
    dispatch(updateIsOpen(true));
  };

  const handleRenameFolderButton = (folderId?: string, fullPath?: string): void => {
    trackEvent({ event: 'renameSubFolderClick' });
    dispatch(updateModalName('renameFolder'));
    dispatch(updateModalProps({ folderId, fullPath }));
    dispatch(updateIsOpen(true));
  };

  const handleDeleteFolderButton = (folderId?: string, fullPath?: string): void => {
    trackEvent({ event: 'deleteSubFolderClick' });
    dispatch(updateModalName('deleteFolder'));
    dispatch(updateModalProps({ folderId, fullPath }));
    dispatch(updateIsOpen(true));
  };

  const renderMailboxes = (): JSX.Element | undefined => {
    if (mailboxLoading || globalMailboxStatusLoading) {
      return <KiteProgressIndicator id={'loading'} useOverlay={false} size="md" />;
    }

    if (mailboxFetchError?.message || globalMailboxFetchError?.message) {
      return <ServerError retryFunction={dispatchMailboxCollection} />;
    }

    return (
      folders && (
        <>
          <FolderListItem
            folderList={folders.globalFolder}
            selectedFolder={selectedFolder}
            isGlobal={true}
            handleNewFolderButton={handleNewFolderButton}
            handleRenameFolderButton={handleRenameFolderButton}
            handleDeleteFolderButton={handleDeleteFolderButton}
            handleDrawerClose={handleDrawerClose}
          />
          <div className={styles.customFolderList}>
            <div className={styles.header}>
              <h2>{t('folders')}</h2>
              <KiteButton
                className={styles.newFolderBtn}
                variant="borderless"
                onClick={(e) => {
                  updateModalRef(e.currentTarget);
                  handleNewFolderButton(undefined, undefined, true);
                }}
              >
                <KiteIcon icon="ki-plus" className={styles.icon} />
                <span>{t('newFolder')}</span>
              </KiteButton>
            </div>
            <FolderListItem
              folderList={folders.userFolder}
              selectedFolder={selectedFolder}
              isGlobal={false}
              handleNewFolderButton={handleNewFolderButton}
              handleRenameFolderButton={handleRenameFolderButton}
              handleDeleteFolderButton={handleDeleteFolderButton}
              handleDrawerClose={handleDrawerClose}
            />
          </div>
        </>
      )
    );
  };

  return (
    <div className={styles.folderListContainer}>
      <Scrollbar customClass={styles.simpleBar} forceScroll={true}>
        {renderMailboxes()}
      </Scrollbar>
    </div>
  );
};

export default FolderList;
