import { KiteButton } from '@kite/react-kite';
import styles from 'components/email-view/styles/EmailView.module.scss';
import { EmailAddressEntity, MessageEntity } from 'generated/graphql';
import { useState } from 'react';
import { usePageTranslation } from 'translation/hooks';

type AddressField = 'to' | 'from' | 'bcc' | 'cc';
interface Props {
  userEmail: string;
  message: MessageEntity;
  field: AddressField;
}
const AddressDisplay: React.FC<Props> = ({ userEmail, message, field }) => {
  const [showAll, setShowAll] = useState<boolean>(message[field]!.length < 2);
  const { t } = usePageTranslation('emailViewCopy');

  const renderAddress = ({ address, name }: EmailAddressEntity, field: string, index: number): JSX.Element => {
    const isFrom = field === 'from';
    const userDisplayName = !isFrom && userEmail === address && <span>{t('me')}</span>;
    return (
      <span className={`${styles.address} ${styles[field]}`} key={index}>
        {userDisplayName || (
          <>
            <span className={styles.itemMain}>{name || address}</span>
            {name && <span className={styles.itemSecondary}>{`<${address}>`}</span>}
          </>
        )}
      </span>
    );
  };

  const renderAddresses = (): JSX.Element[] => {
    return message[field]!.map((item, idx) => renderAddress(item, field, idx));
  };

  if (field === 'from') {
    return renderAddress(message.from![0], 'from', 0);
  }

  return (
    <div className={`${styles.row}`}>
      <div className={styles.columnOne}>
        <span className={styles.recipientLabel}>{field}</span>
      </div>
      <div className={styles[`recipientListBox${showAll ? 'Show' : ''}`]}>
        <div className={styles[`recipientList${showAll ? 'Show' : ''}`]}>{renderAddresses()}</div>
        {message[field]!.length > 1 && (
          <KiteButton
            onClick={() => setShowAll(!showAll)}
            className={styles.inlineToggle}
            variant="borderless"
            size="shrinkwrap"
          >
            {showAll ? t('hideAll') : t('showAll', { amount: message[field]!.length })}
          </KiteButton>
        )}
      </div>
    </div>
  );
};

export default AddressDisplay;
