import SaveCancel from 'components/save-cancel';
import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { useAppDispatch } from 'hooks';
import { TrackingHook, useTracking } from 'react-tracking';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import { KiteAlert } from '@kite/react-kite';
import { exportContactThunk } from 'thunks/contact/exportContactThunk';
import { useExportContactLazyQuery } from 'generated/graphql';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { useDialogTranslation } from 'translation/hooks';

interface ExportContactInterface {
  selectedContacts: string[];
}

const ExportContact: React.FC<ExportContactInterface> = ({ selectedContacts }) => {
  const { trackEvent }: TrackingHook = useTracking({ page: 'contacts' });
  const { t } = useDialogTranslation('exportContactCopy');
  const focusOpener = useTarget('modal');
  const dispatch = useAppDispatch();
  const [getContactExportHook] = useExportContactLazyQuery();

  const isCancelled = (): boolean => {
    trackEvent({ event: 'exportContactErrorCancel' });
    focusOpener();
    dispatch(updateIsOpen(false));
    return true;
  };

  const isValidate = (): boolean => {
    trackEvent({ event: 'exportContactTryAgain' });
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const isSaved = async (): Promise<boolean> => {
    const result = await dispatch(
      exportContactThunk({
        getContactExportHook,
        payload: { ids: selectedContacts },
      })
    );

    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'exportContactSuccess' });
      dispatch(updateToastMessage({ message: t('successToast'), success: true }));
      dispatch(updateIsOpen(false));
      return true;
    }

    trackEvent({ event: 'exportContactApiError' });
    return false;
  };

  const saveCancelProps = {
    isSaved: isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t('tryAgain'),
  };

  return (
    <ModalContentWrapper>
      <h5>{t('header')}</h5>
      <KiteAlert className={modalStyle.alert} description={t('unableToSaveErr')} level={'page'} type={'error'} />
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default ExportContact;
