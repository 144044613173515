import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import styles from 'components/layout/styles/Layout.module.scss';
import Compose from 'components/compose';
import ContactListViewContainer from 'containers/ContactListViewContainer';
import ContactView from 'components/contact-view';
import ContactCreate from 'components/contact-form/Create';
import ContactUpdate from 'components/contact-form/Update';
import ContactListOptionSelect from 'components/contact-list-options/ContactListOptionSelect';
import ContactViewOption from 'components/contact-list-options/ContactViewOption';
import ContactHeader from 'components/header/sub-headers/ContactHeader';

const MobileLayout: React.FC = () => {
  const location = useLocation();
  const showListView: boolean = location.pathname.split('/').length < 5;

  return (
    <>
      <div className={showListView ? styles.layoutContainerWithSearch : styles.layoutContainer}>
        <div className={styles.contentLayout}>
          <div className={`${!showListView ? styles.hideMe : ''}`}>
            <ContactHeader />
            <ContactListOptionSelect />
            <ContactListViewContainer />
          </div>
          <Routes>
            <Route path={'/new-contact'} element={<ContactCreate />} />
            <Route path={'/:contactId/update'} element={<ContactUpdate />} />
            <Route
              path={'/:contactId'}
              element={
                <>
                  <ContactViewOption />
                  <ContactView />
                </>
              }
            />
          </Routes>
        </div>
        <Compose />
      </div>
    </>
  );
};

export default MobileLayout;
