import { KiteIcon } from '@kite/react-kite';
import { getServerEndpoint } from 'app-constants';
import React, { useState } from 'react';
import { TrackingHook, useTracking } from 'react-tracking';
import buildAccessTokenHeader from 'utils/buildAccessTokenHeader';
import axios from 'axios';
import { trackCall } from 'utils/apiTracking';
import buildVisitAndTraceIdHeaders from 'utils/buildVisitAndTraceIdHeaders';
import fileSizeUnitHelper from 'utils/fileSizeUnitHelper';
import styles from 'components/common/styles/AuthenticatedDownload.module.scss';

interface AuthenticatedDownloadTypes {
  id: string;
  fileName: string;
  fileSize: number;
}

const AuthenticatedDownload: React.FC<AuthenticatedDownloadTypes> = ({ id, fileName, fileSize }) => {
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const [apiError, setApiError] = useState(false);
  let startTime: number;

  const handleTrackDownloadAttachments = (): void => {
    trackEvent({ event: 'downloadAttachment' });
  };

  const handleTrackViewAttachments = (): void => {
    trackEvent({ event: 'viewAttachment' });
  };

  const handleDownloadAction = async (e: React.SyntheticEvent<HTMLAnchorElement>): Promise<void> => {
    handleTrackDownloadAttachments();
    const link = e.currentTarget;
    if (link.href === window.location.href) {
      e.preventDefault();
    }
    if (link.href !== window.location.href) {
      return;
    }
    setApiError(false);

    const visitAndTraceId = buildVisitAndTraceIdHeaders();

    try {
      startTime = new Date().getTime();

      const result = await axios({
        method: 'get',
        url: `${getServerEndpoint()}/api/v1/storage/${id}`,
        headers: { ...buildAccessTokenHeader(), ...visitAndTraceId },
        withCredentials: true,
        responseType: 'blob',
      });

      trackCall(result, startTime, visitAndTraceId['X-WEBMAIL-TRACE-ID'], result.data.size);
      const href = window.URL.createObjectURL(result.data);

      link.setAttribute('download', fileName);
      link.setAttribute('href', href);

      link.click();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorResponseSize = new TextEncoder().encode(JSON.stringify(err.response)).length;
        trackCall(err, startTime, visitAndTraceId['X-WEBMAIL-TRACE-ID'], errorResponseSize);
      }

      trackEvent({ event: 'downloadAttachmentError' });
      setApiError(true);
    }
  };

  const handlePreviewAction = async (e: React.SyntheticEvent<HTMLAnchorElement>): Promise<void> => {
    handleTrackViewAttachments();
    const link = e.currentTarget;
    if (link.href === window.location.href) {
      e.preventDefault();
    }
    if (link.href !== window.location.href) {
      return;
    }
    setApiError(false);

    const visitAndTraceId = buildVisitAndTraceIdHeaders();

    try {
      startTime = new Date().getTime();

      const result = await axios({
        method: 'get',
        url: `${getServerEndpoint()}/api/v1/storage/${id}`,
        headers: { ...buildAccessTokenHeader(), ...visitAndTraceId },
        withCredentials: true,
        responseType: 'blob',
      });

      trackCall(result, startTime, visitAndTraceId['X-WEBMAIL-TRACE-ID'], result.data.size);

      const href = window.URL.createObjectURL(result.data);

      window.open(href);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorResponseSize = new TextEncoder().encode(JSON.stringify(err.response?.data)).length;
        trackCall(err, startTime, visitAndTraceId['X-WEBMAIL-TRACE-ID'], errorResponseSize);
      }

      trackEvent({ event: 'downloadAttachmentError' });
      setApiError(true);
    }
  };

  return (
    <div className={styles.chip}>
      <a role="button" href="" onClick={(e) => handleDownloadAction(e)} className={styles.chipDownload} target="_blank">
        <KiteIcon icon={'ki-download'} className={styles.attachmentIcon} size={'14px'} />
      </a>
      <a role="button" href="" onClick={(e) => handlePreviewAction(e)} className={styles.chipPreview} target="_blank">
        <span className={styles.name}>{fileName}</span>
        <span className={styles.size}>{fileSizeUnitHelper(fileSize)}</span>
        {apiError && <KiteIcon icon="ki-info-circle" size="1rem" fill="#D6312B" />}
      </a>
    </div>
  );
};

export default AuthenticatedDownload;
