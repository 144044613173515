import { get } from 'lodash';

export type OrNull<T> = T | null;
interface GetConfigInterface<T> {
  configName: string;
  defaultValue?: T;
  isRequired?: boolean;
}
interface GetConfigInterfaceDefault<T> extends GetConfigInterface<T> {
  defaultValue: T;
}
interface GetConfigInterfaceRequired<T> extends GetConfigInterface<T> {
  isRequired: true;
}

function getConfig<T>({ configName, defaultValue, isRequired }: GetConfigInterfaceRequired<T>): T;
function getConfig<T>({ configName, defaultValue, isRequired }: GetConfigInterfaceDefault<T>): T;
function getConfig<T>({ configName, defaultValue, isRequired }: GetConfigInterface<T>): OrNull<T>;
function getConfig<T>({ configName, defaultValue, isRequired }: GetConfigInterface<T>): OrNull<T> {
  const value = get(window.overrides_data, configName, null);

  if (isRequired && value === null) {
    window.location.assign(`/error?type=config&name=${configName}`);
  }

  if (value === null && defaultValue !== null) {
    return defaultValue as T;
  }

  return value as OrNull<T>;
}

export default getConfig;
