import { MessageEntity } from 'generated/graphql';
import { usePageTranslation } from 'translation/hooks';
import styles from 'components/message-options/styles/EmailOptions.module.scss';
import { useAppSelector } from 'hooks';
import { mailCreatedFormatter } from 'utils/dateFormatter';
import { forwardRef, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';

interface props {
  message: MessageEntity | undefined;
}

const EmailPrint = forwardRef<HTMLDivElement | HTMLIFrameElement, props>(({ message }, ref) => {
  const { t } = usePageTranslation('printCopy');
  const timeZone = useAppSelector((state) => state.settingSlice.timeZone);
  const isDisplayEmailAsText = useAppSelector((state) => state.settingSlice.isDisplayEmailAsHtmlEnabled);
  const isDisplayImageEnabled = useAppSelector((state) => state.settingSlice.isDisplayImageEnabled);
  const headerRef = useRef<HTMLDivElement>(null);

  if (!message) {
    return null;
  }

  const handleFrom = (): JSX.Element | null => {
    if (!message.from || !message.from[0]) {
      return null;
    }

    return (
      <div>
        <span>{t('from')} </span>
        <span className={styles.fromEmailName}>{message.from[0].name || message.from[0].address}</span>
        <span className={styles.fromEmailEmail}>{`<${message.from[0].address}>`}</span>
      </div>
    );
  };

  const handleTo = (): JSX.Element | null => {
    if (!message.to || message.to.length === 0) {
      return null;
    }

    return (
      <div>
        <span>{t('to')} </span>
        <span>{message.to.map((item) => item.address).join(', ')}</span>
      </div>
    );
  };

  const handleCc = (): JSX.Element | null => {
    if (!message.cc || message.cc.length === 0) {
      return null;
    }

    return (
      <div>
        <span>{t('cc')} </span>
        <span>{message.cc.map((item) => item.address).join(', ')}</span>
      </div>
    );
  };

  const handleBcc = (): JSX.Element | null => {
    if (!message.bcc || message.bcc.length === 0) {
      return null;
    }

    return (
      <div>
        <span>{t('bcc')} </span>
        <span>{message.bcc.map((item) => item.address).join(', ')}</span>
      </div>
    );
  };

  const handleSubject = (): JSX.Element | null => {
    return <div>{t('subject', { subject: message?.subject || 'EMPTY SUBJECT' })}</div>;
  };

  const handleDate = (): JSX.Element | null => {
    return <div>{`${t('date')} ${message?.date && mailCreatedFormatter(message.date, timeZone)}`}</div>;
  };

  const headerBuilder = (): JSX.Element => {
    return (
      <div ref={headerRef}>
        {handleSubject()}
        {handleDate()}
        {handleFrom()}
        {handleTo()}
        {handleCc()}
        {handleBcc()}
      </div>
    );
  };

  const attachHeader = (src: string): string => ReactDOMServer.renderToString(headerBuilder()) + '<br/>' + src;

  if (!message?.body) {
    return (
      <div ref={ref} className={styles.iframe}>
        {headerBuilder()}
        <p className={styles.plainText}>{t('noEmailContent')}</p>
      </div>
    );
  }

  if (isDisplayEmailAsText || (!message.body.html && !message.body.noImageHtml)) {
    return (
      <div ref={ref} className={styles.iframe}>
        {headerBuilder()}
        <p className={styles.plainText}>{message.body.fullText}</p>
      </div>
    );
  }

  let srcDoc: string = (message.body.html || message.body.noImageHtml) as string;

  if (!isDisplayImageEnabled) {
    srcDoc = message.body.noImageHtml as string;
  }

  return (
    <iframe
      srcDoc={attachHeader(srcDoc)}
      onLoad={(o) => {
        o.currentTarget.style.height = 120 + (o.currentTarget?.contentWindow?.document.body.scrollHeight || 0) + 'px';
      }}
      className={styles.iframe}
      ref={ref as React.ForwardedRef<HTMLIFrameElement>}
      data-iframe={true}
    />
  );
});

export default EmailPrint;
