import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateMailForwardingMutationFn } from 'generated/graphql';

interface UpdateMailForwardArgInterface {
  updateMailForwardHook: UpdateMailForwardingMutationFn;
}

interface UpdateMailForwardResponseInterface {
  success?: boolean;
  error?: ApolloError;
}

export const updateMailForwardThunk = createAsyncThunk(
  'updateMailForwardThunk',
  async (arg: UpdateMailForwardArgInterface): Promise<UpdateMailForwardResponseInterface> => {
    try {
      const result = await arg.updateMailForwardHook({
        variables: {
          mailForwarding: false,
          mailForwardingAddress: [],
        },
      });

      return {
        success: result.data?.updateMailForwarding.success || false,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
