import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createSignature,
  deleteSignature,
  signatureCollectionGetThunk,
  updateAutoInsertSignature,
  updateSignature,
} from 'thunks/setting/signatureCollectionThunk';
import { ErrorsKeys } from 'translation/hooks';

type ErrorOrEmpty = ErrorsKeys | '';

export interface SignatureState {
  signatureEntity?: {
    id: string;
    signature: string;
    autoInsert: boolean;
  };
  isSignatureDownloaded: boolean;
  signatureError: ErrorOrEmpty;
  autoInsertError: ErrorOrEmpty;
  autoInsertLoading: boolean;
  signatureLoading: boolean;
}

const initialState: SignatureState = {
  isSignatureDownloaded: false,
  signatureError: '',
  autoInsertError: '',
  autoInsertLoading: false,
  signatureLoading: false,
};

export const SignatureSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    updateSignatureError: (state: SignatureState, action: PayloadAction<ErrorOrEmpty>) => {
      state.signatureError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signatureCollectionGetThunk.fulfilled, (state, action) => {
      state.isSignatureDownloaded = true;
      state.signatureError = '';
      if (action.payload.success && action.payload.success?.length > 0) {
        state.signatureEntity = action.payload.success[0];
      }
    });

    builder.addCase(updateAutoInsertSignature.fulfilled, (state, action) => {
      state.autoInsertLoading = false;
      if (!state.signatureEntity) {
        return;
      }

      state.signatureEntity.autoInsert = action.payload.success || false;
      if (action.payload.error) {
        state.autoInsertError = 'unableToSaveErr';
      }
    });

    builder.addCase(updateAutoInsertSignature.pending, (state) => {
      state.autoInsertLoading = true;
    });

    builder.addCase(updateSignature.fulfilled, (state, action) => {
      state.signatureLoading = false;
      if (action.payload.success) {
        state.signatureEntity = {
          id: action.payload.success.id,
          signature: action.payload.success.signature,
          autoInsert: action.payload.success.autoInsert,
        };

        state.signatureError = '';
        return;
      }
      if (action.payload.error) {
        state.signatureError = 'unableToSaveErr';
        return;
      }
    });

    builder.addCase(updateSignature.pending, (state) => {
      state.signatureLoading = true;
    });

    builder.addCase(createSignature.fulfilled, (state, action) => {
      state.signatureLoading = false;
      if (action.payload.success) {
        state.signatureEntity = {
          id: action.payload.success.id,
          signature: action.payload.success.signature,
          autoInsert: action.payload.success.autoInsert,
        };

        state.signatureError = '';
        return;
      }
      if (action.payload.error) {
        state.signatureError = 'unableToSaveErr';
        return;
      }
    });

    builder.addCase(createSignature.pending, (state) => {
      state.signatureLoading = true;
    });

    builder.addCase(deleteSignature.fulfilled, (state) => {
      state.signatureLoading = false;
      state.signatureEntity = undefined;
    });

    builder.addCase(deleteSignature.pending, (state) => {
      state.signatureLoading = true;
    });
  },
});

export const { updateSignatureError } = SignatureSlice.actions;

export default SignatureSlice.reducer;
