import { useNavigate } from 'react-router-dom';
import removeParam from 'utils/removeParam';

const useCloseMessage = (): ((destinationFolder: string, queryParams: string) => void) => {
  const navigate = useNavigate();

  const closeMessageHook = (destinationFolder: string, queryParams: string): void => {
    navigate({
      pathname: `/user/mail/${encodeURIComponent(destinationFolder)}`,
      search: removeParam(queryParams, 'expand'),
    });
  };

  return closeMessageHook;
};

export default useCloseMessage;
