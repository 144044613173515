import { ApolloError, MutationFunction } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetContactQuery, ContactWithLabelEntity, GetContactQueryVariables } from 'generated/graphql';

interface ContactResponse {
  success?: ContactWithLabelEntity;
  error?: ApolloError | undefined;
}

interface ContactArg {
  getContactHook: MutationFunction<GetContactQuery, GetContactQueryVariables>;
  id: string;
}

export const contactThunk = createAsyncThunk('contact', async (arg: ContactArg): Promise<ContactResponse> => {
  try {
    const response = await arg.getContactHook({
      variables: {
        id: arg.id,
      },
    });
    return {
      success: response?.data?.getContact as ContactWithLabelEntity,
    };
  } catch (e) {
    return {
      error: e as ApolloError,
    };
  }
});
