import Helix from '@charter/helix/build/portals/index.min';
import { createSlice } from '@reduxjs/toolkit';
import { appConstants } from 'appConstants';
import { loginNonSsoThunk } from 'thunks/login/loginNonSsoThunk';
import { loginSsoThunk } from 'thunks/login/loginSsoThunk';
import {
  getLoggedInEmail,
  setAccessToken,
  setExpiresIn,
  setIdToken,
  setLoggedInAccountGuid,
  setLoggedInAccountNumber,
  setLoggedInEmail,
  setLoggedInFootprint,
  setLoggedInNamespace,
  setRefreshToken,
  setLoggedInVisitIdStorage,
} from 'utils/accessTokenLocalStorage';
import appRedirect from 'utils/appRedirect';
import { getRememberMeLocalStorage, isLoggedInLocalStorage, setRememberMeLocalStorage } from 'utils/loginLocalStorage';

export interface LoginFormInput {
  email: string;
  password: string;
  rememberMe: boolean;
}

interface LoginState extends LoginFormInput {
  emailErrorMessage: string;
  passwordErrorMessage: string;
  isFormValid: boolean;
  isLoggedIn: boolean;
  apiError: Error | null;
  isLoginLoading: boolean;
}

const initialState: LoginState = {
  email: getLoggedInEmail() || '',
  emailErrorMessage: '',
  password: '',
  passwordErrorMessage: '',
  rememberMe: getRememberMeLocalStorage() !== null,
  isFormValid: true,
  isLoggedIn: isLoggedInLocalStorage(),
  apiError: null,
  isLoginLoading: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginNonSsoThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isLoggedIn = true;

        setAccessToken(action.payload.success.accessToken);
        if (state.rememberMe) {
          setRememberMeLocalStorage(state.email);
        }
        appRedirect(appConstants.LOGIN_SUCCESSFUL_REDIRECT);
        return;
      }
      if (action.payload.error) {
        state.isLoggedIn = false;
        state.apiError = action.payload.error;
        setAccessToken('');
        return;
      }
    });
    builder.addCase(loginSsoThunk.pending, (state) => {
      state.isLoginLoading = true;
    });
    builder.addCase(loginSsoThunk.fulfilled, (state, action) => {
      state.isLoginLoading = false;

      if (action.payload.success) {
        state.isLoggedIn = true;

        setAccessToken(action.payload.success.accessToken);
        setRefreshToken(action.payload.success.refreshToken);
        setIdToken(action.payload.success.idToken);
        setLoggedInEmail(action.payload.success.email);
        setLoggedInFootprint(action.payload.success.footprint);
        setLoggedInAccountNumber(action.payload.success.accountNumber);
        setLoggedInNamespace(action.payload.success.namespace);
        setLoggedInVisitIdStorage(Helix.get('visitId'));
        setLoggedInAccountGuid(action.payload.success.accountGuid);
        setExpiresIn(action.payload.success.expiresIn);
        return;
      }
      if (action.payload.error) {
        state.isLoggedIn = false;
        state.apiError = action.payload.error;

        setAccessToken('');
        return;
      }
    });
  },
});

export default loginSlice.reducer;
