import { KiteCheckbox, KiteProgressIndicator } from '@kite/react-kite';
import styles from 'components/contact-list/styles/ContactList.module.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import { contactCollectionThunk } from 'thunks/contact/contactCollectionThunk';
import { useGetContactCollectionLazyQuery } from 'generated/graphql';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { updatePreviousViewedContact, updateSelectedContacts, updateSelectMultipleContacts } from 'slices/contact';
import { TrackingHook, useTracking } from 'react-tracking';
import Pagination from 'components/pagination';
import useURLQueryBuilder from 'components/common/hooks/useURLQueryBuilder';
import Highlighted from 'components/common/Highlighted';
import { Scrollbar } from 'components/common/scrollbar';
import { usePageTranslation } from 'translation/hooks';
import Footer from 'components/footer';
import useNavToPreviousPage from 'components/common/hooks/useNavToPreviousPage';
import { transformToNewFormat } from 'utils/transformPhoneFormat';

const ContactList: React.FC = () => {
  const { t } = usePageTranslation('contactViewCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'contacts', component: 'ContactList' });

  const contactCollection = useAppSelector((state) => state.contactSlice.contactCollection);
  const selectedContacts = useAppSelector((state) => state.contactSlice.selectedContacts) || [];
  const labelCollection = useAppSelector((state) => state.labelSlice.labelCollection);
  const previousViewedContact = useAppSelector((state) => state.contactSlice.previousViewedContact);
  const forceReload = useAppSelector((state) => state.contactSlice.forceReload);
  const totalPages = useAppSelector((state) => state.contactSlice.totalPages);

  const [_searchQueryParam, setSearchQueryParam] = useURLQueryBuilder();

  const { search: searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const limit = parseInt(query.get('limit') || '') || 20;
  const search = query.get('search');
  const [_queryParams, currentPage, navToPreviousPage] = useNavToPreviousPage();

  const dispatch = useAppDispatch();
  const [getContactCollectionHook, { loading }] = useGetContactCollectionLazyQuery();

  const { labelId, '*': contactId } = useParams<{ '*': string; labelId: string }>();

  const isThreePaneEnabled = !!contactId;

  const [lastChecked, setLastChecked] = useState<number>(0);

  if (!labelId) {
    return <Navigate to={'/server-error?component=ContactList'} />;
  }

  useMemo(() => {
    trackEvent({ event: 'allContactsPageViewInit' });
  }, []);

  useEffect(() => {
    trackEvent({ event: 'allContactsPageView' });
  }, []);

  useEffect(() => {
    if (!contactCollection?.data.length && totalPages > 1 && currentPage > 1) {
      navToPreviousPage();
      return;
    }

    if (forceReload || !contactCollection?.data) {
      dispatch(
        contactCollectionThunk({
          ...contactCollectionPayload,
        })
      );
    }
  }, [contactCollection, forceReload]);

  useEffect(() => {
    if (search?.length) {
      contactCollection?.data?.length
        ? trackEvent({
            event: 'searchResultReturned',
            payload: { searchResultCount: contactCollection?.data?.length, keyword: search },
          })
        : trackEvent({
            event: 'searchNoResults',
            payload: { keyword: search },
          });
    }

    if (contactCollection?.data) {
      dispatch(
        contactCollectionThunk({
          ...contactCollectionPayload,
        })
      );
    }
  }, [labelId, currentPage, limit, search, labelCollection.length]);

  useEffect(() => {
    if (contactItemRef) {
      contactItemRef.current?.focus();
    }

    return () => {
      dispatch(updatePreviousViewedContact());
    };
  }, [previousViewedContact]);

  const contactItemRef = useRef<HTMLAnchorElement>(null);

  const contactCollectionPayload = {
    getContactCollectionHook,
    labelId: labelId !== 'all' ? labelId : '',
    limit,
    page: currentPage,
    search: search ?? undefined,
  };

  const handleContactOpen = (): void => {
    if (search?.length) {
      trackEvent({ event: 'searchResultSelected' });
      return;
    }
    trackEvent({ event: 'contactViewSelect' });
    trackEvent({ event: 'contactViewLinkClicked' });
  };

  const onItemsPerPageChange = (newItemsPerPage: number): void => {
    let normalizedCurrentPage = contactCollection && newItemsPerPage > contactCollection?.total ? 1 : currentPage;
    if (currentPage * newItemsPerPage - newItemsPerPage > (contactCollection?.total || 0)) {
      normalizedCurrentPage = 1;
    }

    setSearchQueryParam('limit', String(newItemsPerPage));
    setSearchQueryParam('page', String(normalizedCurrentPage));
  };

  const onPageSelect = (pageNumber: number): void => {
    setSearchQueryParam('page', String(pageNumber));
  };

  const onNextPage = (): void => {
    setSearchQueryParam('page', String(currentPage + 1));
  };

  const onPrevPage = (): void => {
    setSearchQueryParam('page', String(currentPage - 1));
  };

  const isItemChecked = (item: string): boolean => selectedContacts.includes(item) ?? false;
  const isItemSelected = (item: string): boolean => (selectedContacts.length > 0 ? false : contactId === item);

  const handleContactCheckbox = (contactId: string): void => {
    dispatch(updateSelectedContacts(contactId));
  };

  const handleMultiSelect = (e: React.MouseEvent<any>, index: number): void => {
    if (e.shiftKey) {
      const newContactSelection = contactCollection?.data
        .slice(Math.min(index, lastChecked) + 1, Math.max(index, lastChecked))
        .map((contact) => contact.id);
      dispatch(updateSelectMultipleContacts(newContactSelection || []));
      setLastChecked(index);
      return;
    }

    setLastChecked(index);
    return;
  };

  const contactItems = (): JSX.Element[] | JSX.Element => {
    if ((!contactCollection || !contactCollection?.data || contactCollection?.data?.length === 0) && search?.length) {
      trackEvent({ event: 'searchNoResults', payload: { keyword: search } });
      return (
        <div className={styles.emptyListPlaceHolder}>
          <h2 className="kite-type-style--title-6">{t('noResults')}</h2>
          <p>{t('noResultsDescription')}</p>
        </div>
      );
    }

    if (!contactCollection || contactCollection?.data?.length === 0) {
      return (
        <div className={styles.emptyListPlaceHolder}>
          <img
            className={styles.emptyListPlaceHolderIcon}
            src={'/assets/images/add-a-contact.svg'}
            alt={t('emptyAlt')}
          />
          <h2 className="kite-type-style--title-6">{t(labelId === 'all' ? 'emptyContacts' : 'emptyHeader')}</h2>
          <p>{t(labelId === 'all' ? 'emptyContactsDesc' : 'emptyDesc')}</p>
        </div>
      );
    }

    return contactCollection?.data?.map((contact, idx) => {
      return (
        <li
          className={`${styles.contactWrapper}  ${isItemSelected(contact.id) ? styles.itemSelected : ''}  ${
            isItemChecked(contact.id) ? styles.checked : ''
          }
        `}
          key={contact.id}
        >
          <Link
            to={{
              pathname: `/user/label/${labelId}/${contact.id}`,
              search: searchQuery,
            }}
            onClick={handleContactOpen}
            className={styles.contactListItem}
            role="button"
            tabIndex={selectedContacts?.length ? -1 : undefined}
            aria-label={`${contact.firstName} ${contact.lastName} ${
              contact.emails && contact.emails[0] && contact.emails[0].emailAddress
            }, ${(contact.phoneNumbers && contact.phoneNumbers[0]) || ''}`}
            aria-pressed={isItemSelected(contact.id)}
            ref={previousViewedContact === contact.id ? contactItemRef : null}
          >
            <div className={styles.row}>
              <div className={`${styles.columnTwo} ${styles.contactNameColumn}`}>
                <div className={styles.contactWithLabelsCount}>
                  <div className={styles.name}>
                    <Highlighted
                      text={`${contact.firstName ?? ''} ${contact.lastName ?? ''}`}
                      highlight={search || ''}
                    />
                  </div>
                  <div className={styles.labelsCount}>
                    {t(contact?.labels?.length === 1 ? 'labelCountSingular' : 'labelCount', {
                      count: contact?.labels?.length || 0,
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.columnOne}></div>
              {contact?.emails?.length && contact.emails[0].emailAddress ? (
                <div className={styles.columnTwo}>
                  <div className={styles.email}>
                    <div>
                      <Highlighted text={contact.emails[0].emailAddress} highlight={search || ''} />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.row}>
              <div className={styles.columnOne}></div>
              {contact?.phoneNumbers?.length && contact.phoneNumbers[0].phoneNumber ? (
                <div className={styles.columnTwo}>
                  <div className={styles.phoneNumber}>{transformToNewFormat(contact.phoneNumbers[0].phoneNumber)}</div>
                </div>
              ) : null}
            </div>
            <span className={'srOnly'}>contact details</span>
          </Link>
          <KiteCheckbox
            key={contact.id}
            className={styles.contactSelect}
            id={contact.id}
            name={contact.firstName ?? ''}
            onChange={() => handleContactCheckbox(contact.id)}
            onClick={(e) => handleMultiSelect(e, idx)}
            checked={selectedContacts?.includes(contact.id) ?? false}
            label={''}
          />
        </li>
      );
    });
  };

  return (
    <>
      <div className={styles.contactList}>
        <Scrollbar customClass={styles.simpleBar} forceScroll={false}>
          <div className={styles.flexContainer}>
            {loading ? (
              <div className={styles.loaderContainer}>
                <KiteProgressIndicator id={'loading'} useOverlay={true} size="md" />
              </div>
            ) : (
              <div className={styles.listContainer}>
                <ul
                  className={`${
                    contactCollection?.data && contactCollection?.data?.length === 0 ? styles.fullWidth : ''
                  }`}
                >
                  {contactItems()}
                </ul>
              </div>
            )}
            {contactCollection && contactCollection?.data?.length !== 0 && (
              <div className={styles.pagination}>
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={limit}
                  totalItems={contactCollection.total}
                  onPageSelect={onPageSelect}
                  onItemsPerPageChange={onItemsPerPageChange}
                  onNextPage={onNextPage}
                  onPrevPage={onPrevPage}
                />
              </div>
            )}
            {!isThreePaneEnabled && <Footer />}
          </div>
        </Scrollbar>
      </div>
    </>
  );
};

export default ContactList;
