import { ApolloError } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteAttachmentThunk } from 'thunks/compose/deleteAttachmentThunk';
import { saveDraftThunk } from 'thunks/compose/saveDraftThunk';
import { uploadAttachmentThunk } from 'thunks/compose/uploadAttachmentThunk';
import normalizeFormErrors from 'utils/updateFormErrors';
import autoSaveDraftTimerHelper from 'utils/autoSaveDraftTimerHelper';
import { RkAlertType } from '@kite/react-kite/dist/alert/KiteAlert/KiteAlert';
import { sentMailThunk } from 'thunks/compose/sentMailThunk';
import { getMessageThunk } from 'thunks/message/messageThunk';
import { EmailAddressEntity, Maybe, MessageEntity, SmtpResponseRejectedErrorEntity } from 'generated/graphql';
import { formatForwardMessageBody, formatReplyMessageBody } from 'utils/formatReplyForwardMessageBody';
import { differenceBy, uniqBy } from 'lodash';
import { PageNSTranslationFileKeys } from 'translation/hooks';

interface EmailAddress {
  name?: string;
  address: string;
}

export enum PriorityEnum {
  high = 'high',
  normal = 'normal',
  low = 'low',
}

interface ImapAttachment {
  fileName: string;
  fileSize: number;
  externalRef: string;
  id: string;
}

interface ImapHeader {
  key: string;
  value: string;
}

interface ComposeError {
  [key: string]: {
    type: RkAlertType;
    message: PageNSTranslationFileKeys<'composeCopy'>;
  };
}

const composeErrs = {
  imap_exception: 'imapExceptionErr',
  smtp_exception: 'smtpExceptionErr',
  IMAP_CONNECTION: 'unableToSendErr',
  INTERNAL_SERVER_ERROR: 'serverAdminErr',
  ERR_CONNECTION_REFUSED: 'unableToSendErr',
  httpError: 'attachmentErr',
  UNPROCESSABLE_ENTITY: 'invalidEmailErr',
  DATASOURCE: 'unableToSendErr',
};

export interface ComposeFormInput {
  to?: EmailAddress[];
  cc?: EmailAddress[];
  bcc?: EmailAddress[];
  replyTo?: EmailAddress;
  subject?: string;
  priority?: string;
  messageId?: string;
  references?: string[];
  html?: string;
  attachments?: ImapAttachment[];
  headers?: ImapHeader[];
  index: string;
  isCcFieldEnabled?: boolean;
  isBccFieldEnabled?: boolean;
  isToolbarEnabled?: boolean;
  isSavedToDraft?: boolean;
  isReadReceiptEnabled?: boolean;
  isSavePending?: boolean;
  isSendPending?: boolean;
  isChanged: boolean;
  uid?: string;
  timestamp: number;
  apiError?: string;
  pauseAutoSave: boolean;
  composeFormErrors: ComposeError;
  readReceipt: boolean;
  isFromDraft?: boolean;
  isOpenedFromDraft?: boolean;
}

type ComposeFormError = {
  type: string;
  message: string;
};

export interface ComposeState {
  composeCollection: {
    [key: string]: ComposeFormInput;
  };
  activeTabIndex: string;
  activeMinimizedIndex: string;
  isAttachmentLoading: boolean;
  composeFormError: ComposeFormError;
  newComposeCounter: number;
  isOpenDraftLoading: boolean;
  isMaximized: boolean;
  smtpRejectedErrors?: SmtpResponseRejectedErrorEntity[];
  smtpResponseCode?: Maybe<number> | undefined;
  smptResponseMessage?: string;
}

const initialState: ComposeState = {
  composeCollection: {},
  activeTabIndex: '',
  activeMinimizedIndex: '',
  composeFormError: {
    type: '',
    message: '',
  },
  isAttachmentLoading: false,
  newComposeCounter: 0,
  isOpenDraftLoading: false,
  isMaximized: false,
};

export const composeSlice = createSlice({
  name: 'compose',
  initialState,
  reducers: {
    addTo: (state: ComposeState, action: PayloadAction<EmailAddress>) => {
      if (!state.composeCollection[state.activeTabIndex].to) {
        state.composeCollection[state.activeTabIndex].to = [];
      }

      if (!state.composeCollection[state.activeTabIndex].to?.some((tag) => tag.address === action.payload.address)) {
        state.composeCollection[state.activeTabIndex].to?.push(action.payload);
        state.composeCollection[state.activeTabIndex].isSavedToDraft = false;
        state.composeCollection[state.activeTabIndex].isChanged = true;
      }
    },
    removeToByIndex: (state: ComposeState, action: PayloadAction<number>) => {
      state.composeCollection[state.activeTabIndex].to?.splice(action.payload, 1);
      state.composeCollection[state.activeTabIndex].isSavedToDraft = false;
      state.composeCollection[state.activeTabIndex].isChanged = true;
    },
    addCc: (state: ComposeState, action: PayloadAction<EmailAddress>) => {
      if (!state.composeCollection[state.activeTabIndex].cc) {
        state.composeCollection[state.activeTabIndex].cc = [];
      }
      state.composeCollection[state.activeTabIndex].cc?.push(action.payload);
      state.composeCollection[state.activeTabIndex].isSavedToDraft = false;
      state.composeCollection[state.activeTabIndex].isChanged = true;
    },
    removeCcByIndex: (state: ComposeState, action: PayloadAction<number>) => {
      state.composeCollection[state.activeTabIndex].cc?.splice(action.payload, 1);
      state.composeCollection[state.activeTabIndex].isSavedToDraft = false;
      state.composeCollection[state.activeTabIndex].isChanged = true;
    },
    resetCc: (state: ComposeState) => {
      state.composeCollection[state.activeTabIndex].cc = [];
      state.composeCollection[state.activeTabIndex].isSavedToDraft = false;
      state.composeCollection[state.activeTabIndex].isChanged = true;
    },
    addBcc: (state: ComposeState, action: PayloadAction<EmailAddress>) => {
      if (!state.composeCollection[state.activeTabIndex].bcc) {
        state.composeCollection[state.activeTabIndex].bcc = [];
      }
      state.composeCollection[state.activeTabIndex].bcc?.push(action.payload);
      state.composeCollection[state.activeTabIndex].isSavedToDraft = false;
      state.composeCollection[state.activeTabIndex].isChanged = true;
    },
    removeBccByIndex: (state: ComposeState, action: PayloadAction<number>) => {
      state.composeCollection[state.activeTabIndex].bcc?.splice(action.payload, 1);
      state.composeCollection[state.activeTabIndex].isSavedToDraft = false;
      state.composeCollection[state.activeTabIndex].isChanged = true;
    },
    resetBcc: (state: ComposeState) => {
      state.composeCollection[state.activeTabIndex].bcc = [];
      state.composeCollection[state.activeTabIndex].isSavedToDraft = false;
      state.composeCollection[state.activeTabIndex].isChanged = true;
    },
    updateSubject: (state: ComposeState, action: PayloadAction<string>) => {
      if (state.composeCollection[state.activeTabIndex].subject !== action.payload) {
        state.composeCollection[state.activeTabIndex].isSavedToDraft = false;
        state.composeCollection[state.activeTabIndex].isChanged = true;
      }
      state.composeCollection[state.activeTabIndex].subject = action.payload;
    },
    updatePriority: (state: ComposeState, action: PayloadAction<string>) => {
      state.composeCollection[state.activeTabIndex].priority = action.payload;
      state.composeCollection[state.activeTabIndex].isSavedToDraft = false;
      state.composeCollection[state.activeTabIndex].isChanged = true;
    },
    updateHtml: (state: ComposeState, action: PayloadAction<string>) => {
      if (action.payload && state.composeCollection[state.activeTabIndex].html !== action.payload) {
        if (state.composeCollection[state.activeTabIndex].isFromDraft) {
          state.composeCollection[state.activeTabIndex].isFromDraft = false;
        } else {
          state.composeCollection[state.activeTabIndex].isSavedToDraft = false;
          state.composeCollection[state.activeTabIndex].isChanged = true;
        }
      }

      if (state.composeCollection[state.activeTabIndex] && (action.payload || action.payload === '')) {
        state.composeCollection[state.activeTabIndex].html = action.payload;
      }
    },
    updateIsCcFieldEnabled: (state: ComposeState, action: PayloadAction<boolean>) => {
      state.composeCollection[state.activeTabIndex].isCcFieldEnabled = action.payload;
    },
    updateIsBccFieldEnabled: (state: ComposeState, action: PayloadAction<boolean>) => {
      state.composeCollection[state.activeTabIndex].isBccFieldEnabled = action.payload;
    },
    updateIsReadReceiptEnabled: (state: ComposeState, action: PayloadAction<boolean>) => {
      state.composeCollection[state.activeTabIndex].isReadReceiptEnabled = action.payload;
      state.composeCollection[state.activeTabIndex].isSavedToDraft = false;
      state.composeCollection[state.activeTabIndex].isChanged = true;
    },
    updateIsSavePending: (state: ComposeState, action: PayloadAction<{ index: string; isPending: boolean }>) => {
      state.composeCollection[action.payload.index].isSavePending = action.payload.isPending;
    },
    updateIsSendPending: (state: ComposeState, action: PayloadAction<{ index: string; isPending: boolean }>) => {
      state.composeCollection[action.payload.index].isSendPending = action.payload.isPending;
    },
    updatePauseAutoSave: (state: ComposeState, action: PayloadAction<boolean>) => {
      if (state.composeCollection[state.activeTabIndex]) {
        state.composeCollection[state.activeTabIndex].pauseAutoSave = action.payload;
      }
    },
    updateIsOpenDraftLoading: (state: ComposeState, action: PayloadAction<boolean>) => {
      state.isOpenDraftLoading = action.payload;
    },
    addDraftToTabCollection: (state: ComposeState, action?: PayloadAction<ComposeFormInput | null>) => {
      state.isOpenDraftLoading = false;
      state.composeFormError.message = '';

      let isExistingTab = false;
      Object.keys(state.composeCollection).forEach((field, index) => {
        if (state.composeCollection[field].uid == action?.payload?.uid) {
          state.activeTabIndex = (index + 1).toString();
          state.composeCollection[state.activeTabIndex] = {
            index: state.activeTabIndex,
            timestamp: autoSaveDraftTimerHelper(),
            isChanged: false,
            pauseAutoSave: false,
            composeFormErrors: {},
            readReceipt: false,
            isFromDraft: true,
            ...action?.payload,
          };
          isExistingTab = true;
        }
      });

      if (!isExistingTab) {
        state.activeTabIndex = (state.newComposeCounter + 1).toString();
        state.newComposeCounter = state.newComposeCounter + 1;
        state.composeCollection[state.activeTabIndex] = {
          index: state.activeTabIndex,
          timestamp: autoSaveDraftTimerHelper(),
          isChanged: false,
          pauseAutoSave: false,
          composeFormErrors: {},
          readReceipt: false,
          isFromDraft: true,
          isCcFieldEnabled: action?.payload?.cc && action?.payload?.cc?.length > 0,
          isBccFieldEnabled: action?.payload?.bcc && action?.payload?.bcc?.length > 0,
          ...action?.payload,
        };
      }
    },
    addTabCollection: (state: ComposeState, action?: PayloadAction<string | null>) => {
      state.activeTabIndex = (state.newComposeCounter + 1).toString();
      state.newComposeCounter = state.newComposeCounter + 1;
      state.composeCollection[state.activeTabIndex] = {
        index: state.activeTabIndex,
        timestamp: autoSaveDraftTimerHelper(),
        isChanged: false,
        pauseAutoSave: false,
        composeFormErrors: {},
        readReceipt: false,
        html: action?.payload ? `<div><br><br><br>${action?.payload}</div>` : '',
      };

      state.composeFormError.message = '';
    },
    removeFromTabCollection: (
      state: ComposeState,
      action: PayloadAction<{ activeTabIndex: string; isDraftFolder: boolean }>
    ) => {
      delete state.composeCollection[action.payload.activeTabIndex];
      state.activeTabIndex = '';
      const collection = Object.entries(state.composeCollection);
      if (collection.length) {
        const [lastIndex] = collection.slice(-1);
        state.activeMinimizedIndex = lastIndex[0];
        return;
      }
      state.activeMinimizedIndex = '';
    },
    updateActiveTab: (state: ComposeState, action: PayloadAction<string>) => {
      state.activeTabIndex = action.payload;
    },
    updateMinimizedIndex: (state: ComposeState, action: PayloadAction<string>) => {
      state.activeMinimizedIndex = action.payload;
    },
    removeComposeFormError: (state: ComposeState, action: PayloadAction<string>) => {
      const errors = state.composeCollection[state.activeTabIndex].composeFormErrors;
      if (!errors || Object.keys(errors).length === 0) {
        return;
      }

      delete errors[action.payload];
    },
    updateComposeFormError: (
      state: ComposeState,
      action: PayloadAction<{
        index: string;
        errorIndex: string;
        type: string;
        message: PageNSTranslationFileKeys<'composeCopy'>;
      }>
    ) => {
      if (!state.composeCollection[action.payload.index]) {
        return;
      }

      state.composeCollection[action.payload.index].composeFormErrors[action.payload.errorIndex] = {
        type: action.payload.type as RkAlertType,
        message: action.payload.message,
      };
    },
    updateIsAttachmentLoading: (state: ComposeState, action: PayloadAction<boolean>) => {
      state.isAttachmentLoading = action.payload;
    },
    addReplyToTabCollection: (
      state: ComposeState,
      action: PayloadAction<{
        signature?: string;
        message: MessageEntity;
        date: string;
        userEmail: string;
      }>
    ) => {
      state.activeTabIndex = (state.newComposeCounter + 1).toString();
      state.newComposeCounter = state.newComposeCounter + 1;

      const { message, date, signature } = action.payload;

      const isReplyTo = !!(message.replyTo && message.replyTo.length);

      const messageBody = formatReplyMessageBody(message, date, signature);

      state.composeCollection[state.activeTabIndex] = {
        index: state.activeTabIndex,
        timestamp: autoSaveDraftTimerHelper(),
        isChanged: false,
        pauseAutoSave: false,
        composeFormErrors: {},
        to: (isReplyTo ? message.replyTo : message.from) as EmailAddress[],
        cc: undefined,
        html: messageBody,
        subject: `RE: ${message.subject}`,
        isCcFieldEnabled: message.cc && message.cc.length ? true : undefined,
        isBccFieldEnabled: message.bcc && message.bcc.length ? true : undefined,
        readReceipt: false,
      };
    },
    addReplyAllToTabCollection: (
      state: ComposeState,
      action: PayloadAction<{
        signature?: string;
        message: MessageEntity;
        date: string;
        userEmail: string;
      }>
    ) => {
      state.activeTabIndex = (state.newComposeCounter + 1).toString();
      state.newComposeCounter = state.newComposeCounter + 1;

      const { message, date, signature, userEmail } = action.payload;

      const messageBody = formatReplyMessageBody(message, date, signature);

      const filterUserEmail = (address: EmailAddressEntity): boolean =>
        address.address?.toLowerCase() !== userEmail.toLowerCase();

      const uniqueReplyAllAddresses = uniqBy(
        message.replyTo && message.replyTo.length > 0
          ? (message.to || []).concat(message.replyTo || [])
          : (message.from || []).concat(message.to || []),
        (addr) => addr.address
      ) as EmailAddress[];

      const replyAllAddressesFiltered = uniqueReplyAllAddresses.filter(filterUserEmail) as EmailAddress[];

      const toField = !replyAllAddressesFiltered.length ? uniqueReplyAllAddresses : replyAllAddressesFiltered;

      state.composeCollection[state.activeTabIndex] = {
        index: state.activeTabIndex,
        timestamp: autoSaveDraftTimerHelper(),
        isChanged: false,
        pauseAutoSave: false,
        composeFormErrors: {},
        to: toField,
        cc: differenceBy(message.cc as EmailAddress[], toField, (addr) => addr.address) || undefined,
        html: messageBody,
        subject: `RE: ${message.subject}`,
        isCcFieldEnabled: message.cc && message.cc.length ? true : undefined,
        isBccFieldEnabled: message.bcc && message.bcc.length ? true : undefined,
        readReceipt: false,
      };
    },
    addForwardToTabCollection: (
      state: ComposeState,
      action: PayloadAction<{
        signature?: string;
        message: MessageEntity;
        date: string;
        userEmail: string;
      }>
    ) => {
      state.activeTabIndex = (state.newComposeCounter + 1).toString();
      state.newComposeCounter = state.newComposeCounter + 1;

      const { message, date, signature } = action.payload;

      const messageBody = formatForwardMessageBody(message, date, signature);

      state.composeCollection[state.activeTabIndex] = {
        index: state.activeTabIndex,
        timestamp: autoSaveDraftTimerHelper(),
        isChanged: false,
        pauseAutoSave: false,
        composeFormErrors: {},
        to: [],
        cc: undefined,
        html: messageBody,
        subject: `FW: ${message.subject}`,
        isCcFieldEnabled: message.cc && message.cc.length ? true : undefined,
        isBccFieldEnabled: message.bcc && message.bcc.length ? true : undefined,
        readReceipt: false,
        attachments: message.attachments as ImapAttachment[],
      };
    },
    updateMaximizeCompose: (state: ComposeState, action: PayloadAction<boolean>) => {
      state.isMaximized = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sentMailThunk.fulfilled, (state, action) => {
      if (action.payload.isReadReceipt) {
        return;
      }
      if (action.payload.success) {
        delete state.composeCollection[action.payload.index];
        state.activeTabIndex = '';
        return;
      }

      if (action.payload.smtpResponse) {
        state.smtpResponseCode = action.payload.smtpResponse.responseCode;
        state.smtpRejectedErrors = action.payload.smtpResponse.rejectedErrors || [];
        state.smptResponseMessage = action.payload.smtpResponse.response;
        return;
      }

      state.composeCollection[action.payload.index].composeFormErrors = {};
      let errorCode = normalizeFormErrors(action.payload.error as ApolloError).errorCode;

      if (errorCode.startsWith('DATASOURCE')) {
        errorCode = 'DATASOURCE';
      }

      state.composeCollection[action.payload.index].composeFormErrors['sent_mail_http_error'] = {
        type: 'error',
        message: composeErrs[errorCode as keyof typeof composeErrs] as PageNSTranslationFileKeys<'composeCopy'>,
      };
    });

    builder.addCase(saveDraftThunk.fulfilled, (state, action) => {
      state.composeCollection[action.payload.index].isSavedToDraft = true;

      if (action.payload.isAutoSave) {
        state.composeCollection[action.payload.index].isOpenedFromDraft = true;
      }

      if (!state.composeCollection[action.payload.index]) {
        return;
      }

      state.composeCollection[action.payload.index].isSavePending = false;
      state.composeCollection[action.payload.index].pauseAutoSave = false;

      if (action.payload.success) {
        state.composeCollection[action.payload.index].uid = String(action.payload.draftUid);
        state.composeCollection[action.payload.index].isChanged = false;
        state.composeFormError.message = '';
        return;
      }

      if (action.payload.error) {
        state.composeCollection[action.payload.index].isSavedToDraft = false;
        state.composeCollection[action.payload.index].apiError = normalizeFormErrors(
          action.payload.error as ApolloError
        ).errorCode;

        return;
      }
    });

    builder.addCase(uploadAttachmentThunk.fulfilled, (state, action) => {
      if (!state.composeCollection[action.payload.index]) {
        return;
      }

      state.isAttachmentLoading = false;
      if (action.payload.success) {
        if (!state.composeCollection[action.payload.index].attachments) {
          state.composeCollection[action.payload.index].attachments = [];
        }
        state.composeCollection[action.payload.index].attachments?.push(action.payload.success);
        state.composeFormError.message = '';
        state.composeCollection[action.payload.index].isSavedToDraft = false;
        state.composeCollection[action.payload.index].isChanged = true;
        return;
      }

      if (action.payload.error) {
        state.composeCollection[action.payload.index].composeFormErrors['upload_attachment_error'] = {
          type: 'error',
          message: composeErrs[
            (action.payload.error.code || 'httpError') as keyof typeof composeErrs
          ] as PageNSTranslationFileKeys<'composeCopy'>,
        };

        state.composeCollection[action.payload.index].apiError = action.payload.error.response?.data;
        state.composeFormError.message = '';
        return;
      }
    });

    builder.addCase(deleteAttachmentThunk.fulfilled, (state, action) => {
      if (!state.composeCollection[action.payload.index]) {
        return;
      }

      if (action.payload.success) {
        state.composeCollection[action.payload.index].attachments = state.composeCollection[
          state.activeTabIndex
        ].attachments?.filter((attachment) => attachment.id !== action.payload.success?.id);

        state.composeCollection[action.payload.index].isSavedToDraft = false;
        state.composeCollection[action.payload.index].isChanged = true;

        return;
      }

      if (action.payload.error) {
        state.composeCollection[action.payload.index].apiError = normalizeFormErrors(
          action.payload.error as ApolloError
        ).errorCode;
        state.composeFormError.message = '';
        return;
      }
    });

    builder.addCase(getMessageThunk.fulfilled, (state, action) => {
      if (!action.payload.success && state.isOpenDraftLoading) {
        state.isOpenDraftLoading = false;
        return;
      }
    });
  },
});

export const {
  addTo,
  addCc,
  addBcc,
  updateHtml,
  updatePriority,
  updateSubject,
  updateIsBccFieldEnabled,
  updateIsCcFieldEnabled,
  updateActiveTab,
  updateMinimizedIndex,
  updateIsOpenDraftLoading,
  addDraftToTabCollection,
  addTabCollection,
  removeToByIndex,
  removeCcByIndex,
  removeBccByIndex,
  removeFromTabCollection,
  removeComposeFormError,
  updateIsReadReceiptEnabled,
  updateComposeFormError,
  resetCc,
  resetBcc,
  updateIsAttachmentLoading,
  updateIsSavePending,
  updateIsSendPending,
  updatePauseAutoSave,
  addReplyToTabCollection,
  addReplyAllToTabCollection,
  addForwardToTabCollection,
  updateMaximizeCompose,
} = composeSlice.actions;

export default composeSlice.reducer;
