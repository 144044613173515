import Modal from 'components/common/root-modal/Modal';
import { useAppSelector } from 'hooks';
import DeleteBulkContact from 'components/contact-modal/deleteBulkContact';
import DeleteContact from 'components/contact-modal/deleteContact';
import NewFolder from 'components/inbox-folder-modal/NewFolder';
import RenameFolder from 'components/inbox-folder-modal/RenameFolder';
import DeleteFolder from 'components/inbox-folder-modal/DeleteFolder';
import NewLabel from 'components/label-modal/newLabel';
import DeleteLabel from 'components/label-modal/deleteLabel';
import RenameLabel from 'components/label-modal/renameLabel';
import AssignLabelToContact from 'components/contact-modal/AssignLabelToContact';
import MoveMessages from 'components/inbox-messages-modal/MoveMessages';
import ExportContact from 'components/contact-modal/ExportContact';
import EmptyTrash from 'components/empty-trash/EmptyTrash';
import PermanentDeleteMessage from 'components/inbox-messages-modal/PermanentDeleteMessage';
import ImportContacts from 'components/contact-modal/ImportContacts';
import DeleteFilter from 'components/setting-filter-modal/DeleteFilter';
import BlockSenders from 'components/inbox-messages-modal/BlockSender';
import CanaryModal from 'components/canary/CanaryModal';
import DeleteMessagesModal from 'components/delete-messages-modal/DeleteMessagesModal';
import MarkAsSpam from 'components/inbox-messages-modal/MarkAsSpam';
import PermanentDeleteMessageBulk from 'components/inbox-messages-modal/PermanentDeleteMessageBulk';
import MarkAsNotSpam from 'components/inbox-messages-modal/MarkAsNotSpam';
import ReadReceipt from 'components/inbox-messages-modal/ReadReceipt';
import DiscardDraft from 'components/inbox-messages-modal/DiscardDraft';
import ViewHeader from 'components/inbox-messages-modal/ViewHeader';
import DisableForwardingModal from 'components/setting-forwarding-modal/DisableForwardingModal';
import SaveDraft from 'components/inbox-messages-modal/SaveDraft';
import AssignLabelToBulkContact from 'components/contact-modal/AssignLabelToBulkContact';
import UnsavedChangesModal from 'components/unsaved-changes-modal';
import useBlocker from 'hooks/useBlocker';
import { updateIsOpen, updateModalName } from 'slices/app';
import { Transition } from 'history';
import { useState, useEffect } from 'react';
import { useAppDispatch } from 'hooks';
import SpanishComingSoon from 'components/spanish-coming-soon';
import AddressBook from 'components/inbox-compose-modal/AddressBook';
import AUPCode from 'components/inbox-compose-modal/AUPCode';
import SyncContacts from 'components/contact-modal/SyncContacts';

const RootModal: React.FC = (): JSX.Element | null => {
  const modalName = useAppSelector((state) => state.appSlice.modalName);
  const modalProps = useAppSelector((state) => state.appSlice.modalProps);
  const isNavBlocking = useAppSelector((state) => state.appSlice.isNavBlocking);
  const isOpen = useAppSelector((state) => state.appSlice.isOpen);
  const dispatch = useAppDispatch();
  const [tx, setTx] = useState<Transition | null>(null);

  useBlocker((tx) => {
    setTx(tx);
    dispatch(updateModalName('unsavedChanges'));
    dispatch(updateIsOpen(true));
  }, isNavBlocking);

  useEffect(() => {
    if (!isNavBlocking) {
      setTx(null);
    }
  }, [isNavBlocking]);

  if (!isOpen) {
    return null;
  }

  const switchModalContent = (): JSX.Element => {
    switch (modalName) {
      case 'deleteBulkContacts':
        return <DeleteBulkContact />;
      case 'deleteContact':
        return (
          <DeleteContact contactId={modalProps.contactId} labelId={modalProps.labelId} navigate={modalProps.navigate} />
        );
      case 'assignLabelToContact':
        return <AssignLabelToContact contactId={modalProps.contactId} labelId={modalProps.labelId} />;
      case 'assignLabelToBulkContact':
        return <AssignLabelToBulkContact contacts={modalProps.contacts} />;
      case 'importContacts':
        return <ImportContacts />;
      case 'newFolder':
        return <NewFolder parentFolder={modalProps.parentFolder} fullPath={modalProps.fullPath} />;
      case 'renameFolder':
        return <RenameFolder folderId={modalProps.folderId} fullPath={modalProps.fullPath} />;
      case 'deleteFolder':
        return <DeleteFolder folderId={modalProps.folderId} fullPath={modalProps.fullPath} />;
      case 'newLabel':
        return <NewLabel />;
      case 'renameLabel':
        return <RenameLabel labelId={modalProps.labelId} labelName={modalProps.labelName} />;
      case 'deleteLabel':
        return (
          <DeleteLabel
            labelId={modalProps.labelId}
            currentLabel={modalProps.currentLabel}
            navigate={modalProps.navigate}
          />
        );
      case 'moveMessages':
        return (
          <MoveMessages
            sourceMailboxId={modalProps.folder}
            messageUids={modalProps.messageUids}
            currentMailId={modalProps.currentMailId}
            destinationMailboxId={modalProps.destinationMailboxId}
          />
        );
      case 'sendReadReceipt':
        return (
          <ReadReceipt
            subject={modalProps.subject}
            readReceiptReplyTo={modalProps.readReceiptReplyTo}
            to={modalProps.to}
          />
        );
      case 'exportContact':
        return <ExportContact selectedContacts={modalProps.selectedContacts} />;
      case 'emptyTrash':
        return <EmptyTrash folderId={modalProps.mailboxId} activeFolderId={modalProps.activeFolderId} />;
      case 'permanentDeleteMessage':
        return (
          <PermanentDeleteMessage
            messageUid={modalProps.messageUid}
            mailboxId={modalProps.mailboxId}
            currentMailId={modalProps.currentMailId}
          />
        );
      case 'deleteMessages':
        return (
          <DeleteMessagesModal
            messageUids={modalProps.messageUids}
            sourceMailboxId={modalProps.folder}
            alertDescriptionProp={modalProps.alertDescriptionProp}
            currentMailId={modalProps.currentMailId}
            unseenMessagesCount={modalProps.unseenMessagesCount}
          />
        );
      case 'permanentDeleteMessageBulk':
        return (
          <PermanentDeleteMessageBulk
            messageUids={modalProps.messageUids}
            mailboxId={modalProps.mailboxId}
            currentMailId={modalProps.currentMailId}
          />
        );
      case 'deleteFilter':
        return <DeleteFilter filterId={modalProps.filterId} />;
      case 'disableForwarding':
        return <DisableForwardingModal />;
      case 'blockSenders':
        return (
          <BlockSenders isBlockSendersBulk={modalProps.isBlockSendersBulk} currentFolder={modalProps.currentFolder} />
        );
      case 'canaryModal':
        return <CanaryModal />;
      case 'markAsSpam':
        return (
          <MarkAsSpam
            messageUids={modalProps.messageUids}
            sourceMailboxId={modalProps.sourceMailboxId}
            currentMailId={modalProps.currentMailId}
          />
        );
      case 'markAsNotSpam':
        return <MarkAsNotSpam messageUids={modalProps.messageUids} currentMailId={modalProps.currentMailId} />;
      case 'discardDraft':
        return <DiscardDraft folder={modalProps.folder} />;
      case 'viewHeader':
        return <ViewHeader mailboxId={modalProps.mailboxId} messageUid={modalProps.messageUid} />;
      case 'saveDraft':
        return <SaveDraft folder={modalProps.folder} />;
      case 'unsavedChanges':
        return <UnsavedChangesModal {...modalProps} tx={tx} />;
      case 'spanishComingSoon':
        return <SpanishComingSoon {...modalProps} />;
      case 'addressBook':
        return <AddressBook {...modalProps} />;
      case 'AUPCode':
        return <AUPCode {...modalProps} />;
      case 'syncContacts':
        return <SyncContacts {...modalProps} />;

      default:
        return <>Please be careful here.</>;
    }
  };

  return (
    <Modal isShown={isOpen} isDrawer={false} enableTracking={modalProps.enableTracking}>
      {switchModalContent()}
    </Modal>
  );
};

export default RootModal;
