import {
  ContactAddressEntity,
  ContactEmailEntity,
  ContactPhoneEntity,
  LabelEntity,
  useGetContactLazyQuery,
} from 'generated/graphql';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useRef } from 'react';
import styles from 'components/contact-view/styles/ContactView.module.scss';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { contactThunk } from 'thunks/contact/contactThunk';
import { KiteCard, KiteChip, KiteIcon, KiteProgressIndicator } from '@kite/react-kite';
import { contactDateFormatter } from 'utils/dateFormatter';
import { TrackingHook, useTracking } from 'react-tracking';
import { updatePreviousViewedContact } from 'slices/contact';
import { Scrollbar } from 'components/common/scrollbar';
import { usePageTranslation } from 'translation/hooks';
import { transformToNewFormat } from 'utils/transformPhoneFormat';

type PhoneNumberTypes = 'work' | 'home';

const ContactView: React.FC = () => {
  const { t } = usePageTranslation('contactViewCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'contacts', component: 'ContactView' });

  const dispatch = useAppDispatch();

  const contact = useAppSelector((state) => state.contactSlice.contact);
  const selectedContacts = useAppSelector((state) => state.contactSlice.selectedContacts) || [];
  const labelCollection = useAppSelector((state) => state.labelSlice.labelCollection);
  const { search } = useLocation();

  const [getContactHook, { loading }] = useGetContactLazyQuery();

  const { contactId, labelId } = useParams<{ contactId: string; labelId: string }>();

  if (!contactId) {
    return <Navigate to={'/server-error?component=ContactView'} />;
  }

  const handleCloseView = (): void => {
    trackEvent({ event: 'closeContactView' });
    dispatch(updatePreviousViewedContact(contactId));
  };

  useEffect(() => {
    if (contactDetailsRef) {
      contactDetailsRef.current?.focus();
    }
    dispatch(contactThunk({ getContactHook, id: contactId }));
  }, [contactId, labelCollection.length]);

  const contactDetailsRef = useRef<HTMLSpanElement>(null);

  const buildLabelChip = (labels: LabelEntity[]): JSX.Element[] | null => {
    return labels.map((item) => {
      return <KiteChip key={item.id} label={item.name} className={styles.labelChip}></KiteChip>;
    });
  };

  const buildEmail = (emails: ContactEmailEntity[]): JSX.Element[] => {
    return emails.map((item, index) => {
      return (
        <li key={index}>
          <span className={styles.fieldValueEmail}>{item.emailAddress}</span>
          {item.isDefault && <span className={styles.fieldLabelDefault}>{t('primary')}</span>}
        </li>
      );
    });
  };

  const buildPhone = (phoneNumbers: ContactPhoneEntity[]): JSX.Element[] => {
    return phoneNumbers.map((item, index) => {
      return (
        <li key={index}>
          <span className={styles.fieldLabel}>
            {t(`${item.phoneNumberType?.toLowerCase() as PhoneNumberTypes}FieldLabel`)}
          </span>
          <span className={styles.fieldValue}>{transformToNewFormat(item.phoneNumber)}</span>
          {item.isDefault && <span className={styles.fieldLabelDefault}>{t('primary')}</span>}
        </li>
      );
    });
  };

  const buildAddress = (addresses: ContactAddressEntity[]): JSX.Element[] => {
    return addresses.map((item, index) => {
      return (
        <li key={index}>
          <span className={styles.fieldLabel}>{item.addressType}</span>
          <span className={styles.fieldValue}>
            {item.streetAddress}, {item.city}, {item.state} {item.zipCode} {item.country}
          </span>
        </li>
      );
    });
  };

  const buildCompanyName = (company: string, title: string): JSX.Element => {
    if (company && title) {
      return <span>{t('companyField', { company, title })}</span>;
    }

    return <span>{title || company}</span>;
  };

  return (
    <div className={`${styles.contactViewContainer} ${selectedContacts?.length > 0 ? styles.hideContactView : ''}`}>
      <div className={styles.contactViewDetails}>
        {loading ? (
          <div className={styles.progressSpinnerContainer}>
            <KiteProgressIndicator id={'loading'} useOverlay={false} size="md" />
          </div>
        ) : (
          <KiteCard className={styles.contactCard}>
            <div className={styles.contactSection}>
              <div className={styles.header}>
                <span role="heading" aria-level={2} className={styles.headerText} tabIndex={-1} ref={contactDetailsRef}>
                  {t('detailsHeader')}
                </span>
                <Link
                  aria-label="Close Contact Info"
                  className={styles.closeContactView}
                  to={`/user/label/${labelId}${search}`}
                  onClick={handleCloseView}
                >
                  <KiteIcon icon={'ki-x-alt'} />
                </Link>
              </div>
            </div>

            <Scrollbar customClass={styles.simpleBar} forceScroll={false}>
              <div className={`${styles.contactSection} ${styles.displayNameSection}`}>
                <div className={`${styles.header} ${styles.displayNameHeader}`}>
                  <span role="heading" aria-level={3} className={styles.headerText}>
                    {contact?.firstName} {contact?.lastName}
                  </span>
                  <div className={styles.subHeader}>
                    <span className={styles.headerSubTitle}>{contact?.displayName}</span>
                    {contact?.nickName && (
                      <>
                        <span className={styles.headerSubTitleSeparator}></span>
                        <span className={styles.headerSubTitle}>{contact?.nickName}</span>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.header}>
                  <span className={`${styles.headerSubTitle} ${styles.floatRight}`}>
                    {t('addedOn', {
                      date: contactDateFormatter(contact?.createdAt),
                    })}
                  </span>
                </div>
              </div>

              {contact?.labels && contact?.labels.length > 0 && (
                <div className={styles.contactSection}>
                  <div className={`${styles.row} ${styles.labelRow}`}>
                    <div className={styles.columnOne}>
                      <KiteIcon icon={'ki-folder'} className={styles.icon} size={'21px'} />
                    </div>
                    <div className={styles.columnTwo}>
                      <span role="heading" aria-level={3} className={styles.labelHeader}>
                        {t('labelSubHeader')}
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.columnOne}></div>
                    <div className={styles.columnTwo}>{buildLabelChip(contact.labels)}</div>
                  </div>
                </div>
              )}

              {(contact?.company || contact?.title) && (
                <div className={styles.contactSection}>
                  <div className={`${styles.row} ${styles.labelRow}`}>
                    <div className={styles.columnOne}>
                      <KiteIcon icon={'ki-pdf'} className={styles.icon} size={'21px'} />
                    </div>
                    <div className={styles.columnTwo}>
                      <span role="heading" aria-level={3} className={styles.labelHeader}>
                        {t('companySubHeader')}
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.columnOne}></div>
                    <div className={styles.columnTwo}>
                      {buildCompanyName(contact.company || '', contact.title || '')}
                    </div>
                  </div>
                </div>
              )}

              {contact?.emails && contact?.emails.length > 0 && (
                <div className={styles.contactSection}>
                  <div className={`${styles.row} ${styles.labelRow}`}>
                    <div className={styles.columnOne}>
                      <KiteIcon icon={'ki-mail'} className={styles.icon} size={'21px'} />
                    </div>
                    <div className={styles.columnTwo}>
                      <span role="heading" aria-level={3} className={styles.labelHeader}>
                        {t('emailSubHeader')}
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.columnOne}></div>
                    <div className={styles.columnTwo}>
                      <ul>{buildEmail(contact.emails)}</ul>
                    </div>
                  </div>
                </div>
              )}

              {contact?.phoneNumbers && contact?.phoneNumbers.length > 0 && (
                <div className={styles.contactSection}>
                  <div className={`${styles.row} ${styles.labelRow}`}>
                    <div className={styles.columnOne}>
                      <KiteIcon icon={'ki-phone'} className={styles.icon} size={'21px'} />
                    </div>
                    <div className={styles.columnTwo}>
                      <span role="heading" aria-level={3} className={styles.labelHeader}>
                        {t('phoneSubHeader')}
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.columnOne}></div>
                    <div className={styles.columnTwo}>
                      <ul>{buildPhone(contact.phoneNumbers)}</ul>
                    </div>
                  </div>
                </div>
              )}

              {contact?.addresses && contact?.addresses.length > 0 && (
                <div className={styles.contactSection}>
                  <div className={`${styles.row} ${styles.labelRow}`}>
                    <div className={styles.columnOne}>
                      <KiteIcon icon={'ki-map-point'} className={styles.icon} size={'21px'} />
                    </div>
                    <div className={styles.columnTwo}>
                      <span role="heading" aria-level={3} className={styles.labelHeader}>
                        {t('addressSubHeader')}
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.columnOne}></div>
                    <div className={styles.columnTwo}>
                      <ul>{buildAddress(contact.addresses)}</ul>
                    </div>
                  </div>
                </div>
              )}

              {contact?.dateOfBirth && (
                <div className={styles.contactSection}>
                  <div className={`${styles.row} ${styles.labelRow}`}>
                    <div className={styles.columnOne}>
                      <KiteIcon icon={'ki-date-of-birth'} className={styles.icon} size={'21px'} />
                    </div>
                    <div className={styles.columnTwo}>
                      <span role="heading" aria-level={3} className={styles.labelHeader}>
                        {t('dateOfBirthSubHeader')}
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.columnOne}></div>
                    <div className={styles.columnTwo}>{contactDateFormatter(contact?.dateOfBirth)}</div>
                  </div>
                </div>
              )}

              {contact?.url && (
                <div className={styles.contactSection}>
                  <div className={`${styles.row} ${styles.labelRow}`}>
                    <div className={styles.columnOne}>
                      <KiteIcon icon={'ki-internet'} className={styles.icon} size={'21px'} />
                    </div>
                    <div className={styles.columnTwo}>
                      <span role="heading" aria-level={3} className={styles.labelHeader}>
                        {t('urlSubHeader')}
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.columnOne}></div>
                    <div className={styles.columnTwo}>{contact?.url}</div>
                  </div>
                </div>
              )}

              {contact?.notes && (
                <div className={styles.contactSection}>
                  <div className={`${styles.row} ${styles.labelRow}`}>
                    <div className={styles.columnOne}>
                      <KiteIcon icon={'ki-notes'} className={styles.icon} size={'21px'} />
                    </div>
                    <div className={styles.columnTwo}>
                      <span role="heading" aria-level={3} className={styles.labelHeader}>
                        {t('noteSubHeader')}
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.columnOne}></div>
                    <div className={styles.columnTwo}>{contact?.notes}</div>
                  </div>
                </div>
              )}
            </Scrollbar>
          </KiteCard>
        )}
      </div>
    </div>
  );
};

export default ContactView;
