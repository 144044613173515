import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import FilterList from 'components/setting-filter/FilterList';
import CreateFilter from 'components/setting-filter-form/CreateFilter';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  useGetFiltersLazyQuery,
  useGetGlobalMailBoxStatusLazyQuery,
  useGetMailBoxCollectionLazyQuery,
} from 'generated/graphql';
import { getFilterThunk } from 'thunks/filter/getFilterThunk';
import UpdateFilter from 'components/setting-filter-form/UpdateFilter';
import { globalMailboxStatusThunk } from 'thunks/mailbox-collection/globalMailboxStatusThunk';
import { mailboxCollectionGetThunk } from 'thunks/mailbox-collection/mailboxCollectionThunk';

const SettingFilterRouter: React.FC = () => {
  const dispatch = useAppDispatch();
  const [getFiltersHook, { loading, error }] = useGetFiltersLazyQuery();
  const [getGlobalMailboxStatusHook] = useGetGlobalMailBoxStatusLazyQuery();
  const [getMailBoxCollectionHook, { loading: mailboxesLoading }] = useGetMailBoxCollectionLazyQuery();
  const filters = useAppSelector((state) => state.filterCollectionSlice.filters);
  const mailboxCollection = useAppSelector((state) => state.mailboxCollectionSlice.mailboxCollection);

  const dispatchMailboxCollection = async (): Promise<void> => {
    await dispatch(globalMailboxStatusThunk({ getGlobalMailboxStatusHook }));
    await dispatch(mailboxCollectionGetThunk({ getMailBoxCollectionHook }));
  };

  const getFilters = (): void => {
    dispatch(getFilterThunk({ getFiltersHook }));
  };

  useEffect(() => {
    if (!mailboxCollection?.length) {
      dispatchMailboxCollection();
    }
  }, [mailboxCollection]);

  useEffect(() => {
    if (!filters) {
      getFilters();
    }
  }, [filters]);

  return (
    <Routes>
      <Route
        path={'/'}
        element={<FilterList getFiltersLoading={loading || mailboxesLoading} error={error} getFilters={getFilters} />}
      />
      <Route path={'/new'} element={<CreateFilter getFiltersLoading={loading || mailboxesLoading} />} />
      <Route path={'/:filterId/update'} element={<UpdateFilter getFiltersLoading={loading || mailboxesLoading} />} />
    </Routes>
  );
};

export default SettingFilterRouter;
