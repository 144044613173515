import { AddressBookContact } from 'components/inbox-compose-modal/AddressBook';
import { ContactEntity } from 'generated/graphql';

export const getDisplayNameFallback = (contact: ContactEntity): string => {
  if (contact.displayName) {
    return contact.displayName;
  }

  if (contact.firstName && contact.lastName) {
    return `${contact.firstName} ${contact.lastName}`;
  }

  if (contact.firstName) {
    return contact.firstName;
  }

  if (contact.lastName) {
    return contact.lastName;
  }

  if (contact.emails && contact.emails[0] && contact.emails[0].emailAddress) {
    return contact.emails[0].emailAddress;
  }

  return '';
};

export const getDisplayNameAddressBook = (contact: AddressBookContact): string => {
  if (contact.displayName) {
    return contact.displayName;
  }

  if (contact.firstName && contact.lastName) {
    return `${contact.firstName} ${contact.lastName}`;
  }

  if (contact.firstName) {
    return contact.firstName;
  }

  if (contact.lastName) {
    return contact.lastName;
  }

  if (contact.email) {
    return contact.email;
  }

  return '';
};
