import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteLabelMutationFn } from 'generated/graphql';

interface DeleteLabelThunkArgs {
  payload: {
    id: string;
  };
  deleteLabelHook: DeleteLabelMutationFn;
}

interface DeleteLabelThunkResponse {
  success?: boolean;
  error?: ApolloError;
  labelId: string;
}

export const deleteLabelThunk = createAsyncThunk(
  'deleteLabelThunk',
  async (arg: DeleteLabelThunkArgs): Promise<DeleteLabelThunkResponse> => {
    try {
      const response = await arg.deleteLabelHook({
        variables: arg.payload,
      });
      return {
        success: response.data?.deleteLabel.success,
        labelId: arg.payload.id,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
        labelId: arg.payload.id,
      };
    }
  }
);
