import { KiteButton } from '@kite/react-kite';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import styles from 'components/spanish-coming-soon/SpanishComingSoon.module.scss';
import { useAppDispatch } from 'hooks';
import { updateIsOpen } from 'slices/app';
import { TrackingHook, useTracking } from 'react-tracking';
import { useEffect } from 'react';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import { useDialogTranslation } from 'translation/hooks';

const SpanishComingSoon: React.FC = () => {
  const { t } = useDialogTranslation('spanishComingSoonCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'navigation' });
  const dispatch = useAppDispatch();

  useEffect(() => {
    trackEvent({ event: 'spanishModalView' });
  }, []);

  const handleClose = (): void => {
    trackEvent({ event: 'spanishOk' });
    dispatch(updateIsOpen(false));
  };

  return (
    <ModalContentWrapper>
      <AutoFocusHeader customStyles={styles.title} content={t('header')} />
      <p className={styles.description}>{t('description')}</p>
      <KiteButton className={styles.okButton} onClick={handleClose}>
        {t('confirmBtnText')}
      </KiteButton>
    </ModalContentWrapper>
  );
};

export default SpanishComingSoon;
