import Helix from '@charter/helix/build/portals/index.min';
import { getIsCanaryCompleted } from 'app-constants/appConfig';
import { HelixEventFn, HelixPayloadInterface } from 'libs/tracking/adapters/helix/helixAdapter';

interface HelixCanaryPayloadInterface extends HelixPayloadInterface {
  url?: string;
  success?: boolean;
  errorMessage?: string;
  tutorialStepName?: string;
}

const submitCanaryEvent: HelixEventFn<HelixCanaryPayloadInterface> = (payload) => {
  const { event } = payload;
  switch (event) {
    case 'landingPageViewInit': {
      !getIsCanaryCompleted()
        ? Helix.track('WEBMAIL_pageViewInit_email_newWebmailLandingPage', {
            msgEventType: 'pageViewInit',
            currPageUrl: payload.url,
          })
        : Helix.track('WEBMAIL_pageViewInit_welcomeToNewSpectrumEmail', {
            msgEventType: 'pageViewInit',
            currPageUrl: payload.url,
          });
      break;
    }
    case 'landingPageViewCompleted': {
      !getIsCanaryCompleted()
        ? Helix.track('WEBMAIL_pageView_email_newWebmailLandingPage', {
            msgEventType: 'pageViewCompleted',
          })
        : Helix.track('WEBMAIL_pageView_welcomeToNewSpectrumEmail', {
            msgEventType: 'pageViewCompleted',
          });
      break;
    }
    case 'optIn': {
      !getIsCanaryCompleted()
        ? Helix.track('WEBMAIL_selectAction_canaryLandingPage_upgradeNow')
        : Helix.track('WEBMAIL_selectAction_newSpectrumEmail_takeATour');
      break;
    }
    case 'optOut': {
      Helix.track('WEBMAIL_selectAction_canaryLandingPage_noThanks');
      break;
    }
    case 'canaryIntroductionModalOptOut': {
      Helix.track('WEBMAIL_selectAction_spectrumEmailUpgradeAvailableModal_noThanks');
      break;
    }
    case 'canaryModalView': {
      Helix.track('WEBMAIL_modalView_spectrumEmailUpgradeAvailable');
      break;
    }

    case 'canaryIntroductionModalOptIn': {
      Helix.track('WEBMAIL_selectAction_spectrumEmailUpgradeAvailableModal_learnMore');
      break;
    }
    case 'canaryIntroductionModalClose': {
      Helix.track('WEBMAIL_modalClose');
      break;
    }

    case 'canaryTutorialStart': {
      Helix.track('WEBMAIL_featureStart_spectrumEmail_customerTutorial');
      break;
    }
    case 'canaryTutorialStop': {
      Helix.track('WEBMAIL_featureStop_spectrumEmail_customerTutorial');
      break;
    }
    case 'canaryTutorialStopAbandon': {
      Helix.track('WEBMAIL_featureStop_spectrumEmail_customerTutorial_abandon', {
        msgFeatureStepName: payload.tutorialStepName,
      });
      break;
    }
    case 'canaryTutorialModal': {
      Helix.track('WEBMAIL_modalView_emailTutorial', {
        modalName: payload.tutorialStepName,
      });
      break;
    }
    case 'canaryTutorialSkip': {
      Helix.track('WEBMAIL_selectAction_emailTutorial_skipTour', {
        modalName: payload.tutorialStepName,
      });
      Helix.track('WEBMAIL_featureStop_spectrumEmail_customerTutorial_abandon', {
        msgFeatureStepName: payload.tutorialStepName,
      });
      break;
    }
    case 'canaryTutorialNextStep': {
      Helix.track('WEBMAIL_selectAction_emailTutorial_next', {
        msgFeatureStepName: payload.tutorialStepName,
      });
      break;
    }
    case 'canaryTutorialXIcon': {
      Helix.track('WEBMAIL_selectAction_emailTutorial_x_icon', {
        msgFeatureStepName: payload.tutorialStepName,
      });
      Helix.track('WEBMAIL_featureStop_spectrumEmail_customerTutorial_abandon', {
        msgFeatureStepName: payload.tutorialStepName,
      });
      break;
    }
    case 'canaryTutorialPrevStep': {
      Helix.track('WEBMAIL_selectAction_emailTutorial_previousStep', {
        msgFeatureStepName: payload.tutorialStepName,
      });
      break;
    }
    case 'canaryTutorialFinish': {
      Helix.track('WEBMAIL_selectAction_emailTutorial_finish');
      Helix.track('WEBMAIL_featureStop_spectrumEmail_customerTutorial');
      break;
    }
    case 'contactMigrationStatus': {
      Helix.track('WEBMAIL_applicationActivity_contactMigrationStatus', {
        opSuccess: payload.success,
        visitAdditionalInformation: payload.errorMessage || null,
      });
      break;
    }
    case 'settingMigrationStatus': {
      Helix.track('WEBMAIL_applicationActivity_settingsMigrationStatus', {
        opSuccess: payload.success,
        visitAdditionalInformation: payload.errorMessage || null,
      });
      break;
    }

    default: {
      console.log('Event Case For Canary Not Found', event);
      return null;
    }
  }
  return null;
};

export default submitCanaryEvent;
