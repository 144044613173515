import getConfig from 'libs/get.config';

export const getSpectrumWatchBaseUrl = (): string =>
  getConfig({ configName: 'spectrum.SPECTRUM_WATCH_BASE_URL', isRequired: true });
export const getSpectrumComBaseUrl = (): string =>
  getConfig({ configName: 'spectrum.SPECTRUM_COM_BASE_URL', isRequired: true });
export const getSpectrumNetBaseUrl = (): string =>
  getConfig({ configName: 'spectrum.SPECTRUM_NET_BASE_URL', isRequired: true });
export const getLoginRedirectDomains = (): string[] =>
  getConfig({ configName: 'spectrum.LOGIN_REDIRECT_DOMAINS', isRequired: true });
export const getUsernameRecoveryLink = (): string =>
  getConfig({ configName: 'spectrum.USERNAME_RECOVERY_URL', isRequired: true });
export const getPasswordRecoveryLink = (): string =>
  getConfig({ configName: 'spectrum.PASSWORD_RECOVERY_URL', isRequired: true });
export const getCHTRPasswordChangeLink = (): string =>
  getConfig({ configName: 'spectrum.PASSWORD_CHANGE_URL_CHTR', isRequired: true });
