import styles from 'components/common/styles/PaginationDots.module.scss';

interface PaginationDotsInterface {
  currPage: number;
  totalPages: number;
}

const PaginationDots: React.FC<PaginationDotsInterface> = ({ currPage, totalPages }) => {
  return (
    <div className={styles.container}>
      {Array.from(Array(totalPages), (_v, idx) => {
        return <div key={idx} className={`${styles.dot} ${idx === currPage ? styles.currPage : ''}`} />;
      })}
    </div>
  );
};

export default PaginationDots;
