const fileSizeUnitHelper = (bytes: number): string => {
  const megaBytes = bytes / 1000000;

  if (megaBytes >= 0.01) {
    return `${megaBytes.toFixed(2).toString()} MB`;
  }
  const kiloBytes = bytes / 1000;

  return `${kiloBytes.toFixed(2).toString()} KB`;
};

export default fileSizeUnitHelper;
