import { ComposeFormInput } from 'slices/compose';

const isTabInCollection = (
  collection: {
    [key: string]: ComposeFormInput;
  },
  uid: string
): boolean => {
  for (const composeTab in collection) {
    if (String(collection[composeTab as string].uid) === uid) {
      return true;
    }
  }
  return false;
};

export default isTabInCollection;
