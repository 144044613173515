import React, { useEffect, useRef, useState } from 'react';
import styles from 'components/compose-text-area/styles/ComposeTextArea.module.scss';
import { KiteButton, KiteIcon } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Editor } from '@tinymce/tinymce-react';
import { removeComposeFormError, updateComposeFormError, updateHtml, updateIsAttachmentLoading } from 'slices/compose';
import TextEditor from 'components/text-editor';
import { uploadAttachmentThunk } from 'thunks/compose/uploadAttachmentThunk';
import { appConstants } from 'appConstants';
import { TrackingHook, useTracking } from 'react-tracking';
import { AutoSaveDraftHandler } from 'components/compose-form';
import { usePageTranslation } from 'translation/hooks';

interface ComposeTextArea extends AutoSaveDraftHandler {
  editorCallback: (ref: Editor) => void;
}

const ComposeTextArea: React.FC<ComposeTextArea> = ({ editorCallback, autoSaveDraftHandler }) => {
  const { t } = usePageTranslation('composeCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'compose' });

  const isAttachmentLoading = useAppSelector((state) => state.composeSlice.isAttachmentLoading);
  const activeTabIndex = useAppSelector((state) => state.composeSlice.activeTabIndex);
  const composeCollection = useAppSelector((state) => state.composeSlice.composeCollection);
  const [composeErrorIndex, setComposeErrorIndex] = useState('');

  const [draft, setDraft] = useState('');

  const dispatch = useAppDispatch();

  const attachments = composeCollection[activeTabIndex]?.attachments || [];
  const emailBody = composeCollection[activeTabIndex]?.html || '';

  useEffect(() => {
    setDraft(emailBody);
    dispatch(updateHtml(emailBody));
  }, [activeTabIndex]);

  let currentAttachmentSize = 0;

  if (attachments && Array.isArray(attachments)) {
    attachments.forEach((attachment) => {
      currentAttachmentSize += attachment.fileSize;
    });
  }

  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const handleAttachmentClick = (): void => {
    hiddenFileInputRef.current && hiddenFileInputRef.current.click();
  };

  const formData = new FormData();

  const handleAttachmentChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const fileUploaded = event.target.files && event?.target?.files[0];
    if (fileUploaded) {
      const fileExtension = fileUploaded.name.split('.').pop();
      if (!fileExtension || appConstants.BLACKLISTED_FILE_EXTENSIONS.includes(fileExtension as string)) {
        setComposeErrorIndex('attachment_file_type');

        trackEvent({
          event: 'addAttachmentFailure',
          triggeredBy: 'user',
          errorCode: 'userError',
          errorMessage: 'invalidFileType',
        });

        dispatch(
          updateComposeFormError({
            type: 'error',
            index: activeTabIndex,
            errorIndex: 'attachment_file_type',
            message: 'fileTypeInvalid',
          })
        );
        return;
      }

      if (currentAttachmentSize + fileUploaded.size >= 20000000) {
        setComposeErrorIndex('attachment_file_size');

        trackEvent({
          event: 'addAttachmentFailure',
          triggeredBy: 'user',
          errorCode: 'userError',
          errorMessage: 'maxSizeLimit',
        });

        dispatch(
          updateComposeFormError({
            type: 'error',
            index: activeTabIndex,
            errorIndex: 'attachment_file_size',
            message: 'fileSizeExceeded',
          })
        );
        return;
      }

      dispatch(updateIsAttachmentLoading(true));
      formData.append('file', fileUploaded, fileUploaded.name);
      const result = await dispatch(uploadAttachmentThunk({ payload: { formData, index: activeTabIndex } })).unwrap();
      hiddenFileInputRef.current!.value = '';

      if (result.success) {
        dispatch(removeComposeFormError(composeErrorIndex));
        trackEvent({ event: 'addAttachmentSuccess' });
        return;
      }
      trackEvent({
        event: 'addAttachmentFailure',
        errorCode: result.error?.response?.status,
        errorMessage: 'applicationError',
      });
      return;
    }
  };

  const handleChange = (html: string): void => {
    setDraft(html);
    dispatch(updateHtml(html));

    if (
      Object.keys(composeCollection).length > 0 &&
      html !== emailBody &&
      !composeCollection[activeTabIndex].isFromDraft &&
      !composeCollection[activeTabIndex].isSavePending
    ) {
      autoSaveDraftHandler('html', html, composeCollection[activeTabIndex]);
    }
  };

  return (
    <div className={styles.textAreaContainer}>
      <span className={styles.messageLabelContainer}>
        <span className={styles.label}>
          <label className={styles.label} htmlFor="composeMessage">
            {t('message')}
          </label>
        </span>
        <span className={styles.actionIcons}>
          <KiteButton
            variant="borderless"
            loading={isAttachmentLoading}
            disabled={isAttachmentLoading}
            aria-disabled={isAttachmentLoading}
            className={`${styles.attachmentButton} ${isAttachmentLoading ? styles.loadingMargin : ''}`}
            onClick={handleAttachmentClick}
            aria-label={'attach files'}
          >
            <KiteIcon className={styles.icon} icon="ki-attachment" size="18px" />
            <span className={styles.attachLabel}>{t('attachFiles')}</span>
          </KiteButton>
          <input
            className={styles.hiddenAttachmentIcon}
            type="file"
            ref={hiddenFileInputRef}
            onChange={handleAttachmentChange}
          />
        </span>
      </span>
      <div className={styles.textEditorContainer}>
        <TextEditor
          editorId="composeMessage"
          page="compose"
          value={draft}
          editorCallback={editorCallback}
          handleChange={handleChange}
        />
      </div>
    </div>
  );
};

export default ComposeTextArea;
