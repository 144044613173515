import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteMailboxMutationFn, MailboxDeleteEntity } from 'generated/graphql';

interface DeleteMailboxThunkArgs {
  payload: {
    mailboxId: string;
    deleteMessage: boolean;
    spamFolderId: string;
  };
  deleteMailboxHook: DeleteMailboxMutationFn;
}

export interface DeleteMailboxThunkResponse {
  success?: MailboxDeleteEntity;
  mailboxId: string;
  error?: ApolloError;
  spamFolderId?: string;
}

export const deleteMailboxThunk = createAsyncThunk(
  'deleteMailboxThunk',
  async (arg: DeleteMailboxThunkArgs): Promise<DeleteMailboxThunkResponse> => {
    try {
      const response = await arg.deleteMailboxHook({
        variables: arg.payload,
      });
      return {
        success: response.data?.deleteMailbox,
        mailboxId: arg.payload.mailboxId,
        spamFolderId: arg.payload.spamFolderId,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
        mailboxId: arg.payload.mailboxId,
      };
    }
  }
);
