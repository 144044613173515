export const contactViewCopy = {
  allContacts: '',
  listHeader: '',
  labelCount: '',
  labelCountSingular: '',
  placeholderHeader: '',
  placeholderDesc: '',
  emptyContacts: '',
  emptyContactsDesc: '',
  emptyHeader: '',
  emptyDesc: '',
  detailsHeader: '',
  addedOn: '',
  labelSubHeader: '',
  companySubHeader: '',
  companyField: '',
  emailSubHeader: '',
  phoneSubHeader: '',
  workFieldLabel: '',
  homeFieldLabel: '',
  mobileFieldLabel: '',
  addressSubHeader: '',
  dateOfBirthSubHeader: '',
  urlSubHeader: '',
  noteSubHeader: '',
  emptyAlt: '',
  selectAContactTitle: '',
  selectAContactInstruction: '',
  selectedContacts: '',
  exportSuccessToast: '',
};
