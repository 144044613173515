import { createSlice } from '@reduxjs/toolkit';
import { MxosMailReceiptEntity } from 'generated/graphql';
import { updateAutoReplyMessageThunk, updateAutoReplyToggleThunk } from 'thunks/setting/autoReplyThunk';
import { getLdapSettingThunk } from 'thunks/setting/getLdapSettingThunk';

export interface AutoReplyState {
  autoReplyEntity: Partial<MxosMailReceiptEntity>;
  autoReplyToggleLoading: boolean;
  isAutoReplyDownloaded: boolean;
  autoReplyError: Error | null;
}

const initialState: AutoReplyState = {
  autoReplyEntity: {
    autoReplyMode: '',
    autoReplyMessage: '',
  },
  isAutoReplyDownloaded: false,
  autoReplyToggleLoading: false,
  autoReplyError: null,
};

export const autoReplySlice = createSlice({
  name: 'autoReply',
  initialState,
  reducers: {
    resetAutoReplyError: (state: AutoReplyState) => {
      state.autoReplyError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLdapSettingThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        const { autoReplyMessage, autoReplyMode } = action.payload.success.mxos;
        state.isAutoReplyDownloaded = true;
        state.autoReplyEntity.autoReplyMessage = autoReplyMessage;
        state.autoReplyEntity.autoReplyMode = autoReplyMode;
      }
    });

    builder.addCase(updateAutoReplyToggleThunk.pending, (state) => {
      state.autoReplyToggleLoading = true;
    });

    builder.addCase(updateAutoReplyToggleThunk.fulfilled, (state, action) => {
      state.autoReplyToggleLoading = false;
      if (action.payload.success) {
        state.autoReplyEntity.autoReplyMode = action.payload.isVacationModeEnabled ? 'vacation' : 'none';
        state.autoReplyEntity.autoReplyMessage = action.payload.autoReplyMessage;
        state.autoReplyError = null;
      }
      if (action.payload.error) {
        state.autoReplyError = action.payload.error;
      }
    });

    builder.addCase(updateAutoReplyMessageThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.autoReplyEntity.autoReplyMode = action.payload.isVacationModeEnabled ? 'vacation' : 'none';
        state.autoReplyEntity.autoReplyMessage = action.payload.autoReplyMessage;
        state.autoReplyError = null;
      }
      if (action.payload.error) {
        state.autoReplyError = action.payload.error;
      }
    });
  },
});

export const { resetAutoReplyError } = autoReplySlice.actions;

export default autoReplySlice.reducer;
