import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteBulkContactMutationFn } from 'generated/graphql';

interface DeleteBulkContactArg {
  payload: {
    id: string[];
  };
  deleteBulkContactHook: DeleteBulkContactMutationFn;
}

interface DeleteBulkContactResponse {
  success?: {
    deleteCount: number;
  };
  error?: ApolloError;
  ids: string[];
}

export const deleteBulkContactThunk = createAsyncThunk(
  'deleteBulkContactThunk',
  async (arg: DeleteBulkContactArg): Promise<DeleteBulkContactResponse> => {
    try {
      const response = await arg.deleteBulkContactHook({
        variables: arg.payload,
      });
      return {
        success: {
          deleteCount: response.data?.deleteBulkContact.deleteCount || 0,
        },
        ids: arg.payload.id,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
        ids: arg.payload.id,
      };
    }
  }
);
