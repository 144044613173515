import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateSignatureMutationFn,
  DeleteSignatureMutationFn,
  SignatureEntity,
  UpdateAutoInsertSignatureMutationFn,
  UpdateSignatureMutationFn,
} from 'generated/graphql';

interface SignatureCollectionArg {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSignatureCollection: any;
}

export interface updateAutoInsertSignatureArg {
  payload: {
    id: string;
    value: boolean;
  };
  updateAutoInsertSignatureHook: UpdateAutoInsertSignatureMutationFn;
}

export interface deleteSignatureArg {
  payload: {
    id: string;
  };
  deleteSignatureHook: DeleteSignatureMutationFn;
}

export interface createSignatureArg {
  payload: {
    signature: string;
    autoInsert: boolean;
  };
  createSignatureHook: CreateSignatureMutationFn;
}

export interface updateSignatureArg {
  payload: {
    id: string;
    signature: {
      signature: string;
      autoInsert: boolean;
    };
  };
  updateSignatureHook: UpdateSignatureMutationFn;
}

export interface SignatureCollectionResponse {
  success?: SignatureEntity[];
  error?: ApolloError;
}

export interface updateAutoInsertSignatureResponse {
  success?: boolean;
  error?: ApolloError;
}

export interface createSignatureResponse {
  success?: {
    signature: string;
    id: string;
    autoInsert: boolean;
  };
  error?: ApolloError;
}

export interface updateSignatureResponse {
  success?: {
    signature: string;
    id: string;
    autoInsert: boolean;
  };
  error?: ApolloError;
}

export interface deleteSignatureResponse {
  success?: boolean;
  error?: ApolloError;
}

export const signatureCollectionGetThunk = createAsyncThunk(
  'signatureCollection',
  async (arg: SignatureCollectionArg): Promise<SignatureCollectionResponse> => {
    try {
      const signatureCollection = await arg.getSignatureCollection();
      return {
        success: signatureCollection.data.getSignatureCollection,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);

export const updateAutoInsertSignature = createAsyncThunk(
  'updateAutoInsertSignature',
  async (arg: updateAutoInsertSignatureArg): Promise<updateAutoInsertSignatureResponse> => {
    try {
      const response = await arg.updateAutoInsertSignatureHook({
        variables: {
          id: arg.payload.id,
          value: arg.payload.value ? 'yes' : 'no',
        },
      });

      return {
        success: response?.data?.patchAutoInsertSignature.autoInsert || false,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);

export const createSignature = createAsyncThunk(
  'createSignature',
  async (arg: createSignatureArg): Promise<createSignatureResponse> => {
    try {
      const response = await arg.createSignatureHook({
        variables: arg.payload,
      });

      return {
        success: {
          signature: response?.data?.createSignature.signature || '',
          id: response?.data?.createSignature.id || '',
          autoInsert: response?.data?.createSignature.autoInsert || false,
        },
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);

export const updateSignature = createAsyncThunk(
  'updateSignature',
  async (arg: updateSignatureArg): Promise<updateSignatureResponse> => {
    try {
      const response = await arg.updateSignatureHook({
        variables: arg.payload,
      });

      return {
        success: {
          signature: response?.data?.updateSignature.signature || '',
          id: response?.data?.updateSignature.id || '',
          autoInsert: response?.data?.updateSignature.autoInsert || false,
        },
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);

export const deleteSignature = createAsyncThunk(
  'deleteSignature',
  async (arg: deleteSignatureArg): Promise<deleteSignatureResponse> => {
    try {
      await arg.deleteSignatureHook({
        variables: arg.payload,
      });

      return {
        success: true,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
