import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LabelEntity } from 'generated/graphql';

interface LabelCollectionResponse {
  success?: LabelEntity[];
  isPrefetch: boolean;
  error?: ApolloError | undefined;
}

interface labelCollectionArg {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getLabelCollectionHook?: any;
  isPrefetch?: boolean;
}

export const labelCollectionThunk = createAsyncThunk(
  'labelCollection',
  async (arg: labelCollectionArg): Promise<LabelCollectionResponse> => {
    try {
      const response = await arg.getLabelCollectionHook();
      return {
        success: response.data.getLabelCollection,
        isPrefetch: arg.isPrefetch || false,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
        isPrefetch: arg.isPrefetch || false,
      };
    }
  }
);
