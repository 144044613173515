import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import refreshLink from 'libs/apollo-client/links/refreshLink';
import httpLink from 'libs/apollo-client/links/httpLink';
import loggerLink from 'libs/apollo-client/links/loggerLink';
import { authLink } from 'libs/apollo-client/links/authLink';

const createApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    link: ApolloLink.from([refreshLink(), authLink(), loggerLink(), httpLink()]),
    connectToDevTools: true,
    cache: new InMemoryCache(),
  });
};

export default createApolloClient;
