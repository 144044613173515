import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateBlockedSenderMutationFn } from 'generated/graphql';

export interface UpdateBlockedSenderInterface {
  payload: {
    blockedSender: {
      added: {
        emails: string[];
        domains: string[];
      };
      removed: {
        emails: string[];
        domains: string[];
      };
    };
  };
  updateBlockedSenderHook: UpdateBlockedSenderMutationFn;
  fromInbox?: boolean;
}

export interface UpdateBlockedSenderResponse {
  success?: {
    added: {
      emails: string[];
      domains: string[];
    };
    removed: {
      emails: string[];
      domains: string[];
    };
  };
  fromInbox?: boolean;
  error?: ApolloError;
}

export const updateBlockSenderThunk = createAsyncThunk(
  'updateBlockSenderThunk',
  async (arg: UpdateBlockedSenderInterface): Promise<UpdateBlockedSenderResponse> => {
    try {
      await arg.updateBlockedSenderHook({
        variables: arg.payload,
      });

      return {
        success: arg.payload.blockedSender,
        fromInbox: arg.fromInbox,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
        fromInbox: arg.fromInbox,
      };
    }
  }
);
