import React from 'react';
import { KiteCheckbox, KiteSelect } from '@kite/react-kite';
import { FilterFormInterface, FilterSectionProps } from 'components/setting-filter-form/initialState';
import styles from 'components/setting-filter-form/styles/SettingFilterForm.module.scss';
import { usePageTranslation } from 'translation/hooks';

export const Priority: React.FC<FilterSectionProps<Pick<FilterFormInterface, 'highPriority' | 'actionPriority'>>> = ({
  updateFormValues,
  values,
}) => {
  const { t } = usePageTranslation('filterRuleFormCopy');

  const priorityTypeCheckbox = (): JSX.Element => (
    <KiteSelect
      id={'priorityTypeListId'}
      label={''}
      className={styles.inlineSelect}
      name={'priorityTypeList'}
      value={values.highPriority || ''}
      onChange={(e) => updateFormValues(e.target.value, 'highPriority')}
      placeholder={t('actionChoiceDefault')}
      aria-label={'priority is or is not marked as high, combo box, to change the selection use the arrow keys'}
    >
      <option key={`priorityType-yes`} value={'yes'}>
        {t('conditionIs')}
      </option>
      <option key={`priorityType-no`} value={'no'}>
        {t('conditionIsNot')}
      </option>
    </KiteSelect>
  );

  return (
    <KiteCheckbox
      checked={values.actionPriority === 'yes'}
      id={'priorityCheckbox'}
      className={styles.checkboxField}
      /* @ts-ignore */
      label={
        <div className={styles.inlineField}>
          {t('conditionPriority')} {priorityTypeCheckbox()} {t('conditionPriorityMarkedAs')}
        </div>
      }
      name={'priorityCheckbox'}
      onChange={(e) => updateFormValues(e.target.checked ? 'yes' : 'no', 'actionPriority')}
    />
  );
};
