import Helix from '@charter/helix/build/portals/index.min';
import { HelixEventFn, HelixPayloadInterface } from 'libs/tracking/adapters/helix/helixAdapter';

export interface HelixSettingsPayloadInterface extends HelixPayloadInterface {
  uiName?: string;
  type?: string;
  isToggled?: boolean;
  subPage?: string;
  usage?: string;
  errorMessage?: Error['message'];
}

const submitSettingsEvent: HelixEventFn<HelixSettingsPayloadInterface> = (payload) => {
  const { event } = payload;
  switch (event) {
    case 'accountInfo': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_accountInfo');
      break;
    }
    case 'accountInfoPageView': {
      Helix.track('WEBMAIL_pageView_settingsAccountInfo', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'accountInfoDisplayNameSave': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_displayNameSave');
      break;
    }
    case 'accountInfoDisplayNameCancel': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_displayNameCancel');
      break;
    }
    case 'accountInfoChangeEmailPassword': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_changeEmailPassword');
      break;
    }
    case 'accountInfoError': {
      Helix.track('WEBMAIL_error_updateDisplayName_unableToSaveChanges');
      break;
    }
    case 'accountInfoSaveSuccess': {
      Helix.track('WEBMAIL_applicationActivity_updateDisplayName_success');
      break;
    }
    case 'timeZone': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_timeZone');
      break;
    }
    case 'timeZonePageViewInit': {
      Helix.track('WEBMAIL_pageViewInit_settingsTimeZone', {
        currPageUrl: '/user/setting/time-zone',
      });
      break;
    }
    case 'timeZonePageView': {
      Helix.track('WEBMAIL_pageView_settingsTimeZone', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'timeZoneSave': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_timeZoneSave');
      break;
    }
    case 'timeZoneCancel': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_timeZoneCancel');
      break;
    }
    case 'timeZoneSaveError': {
      Helix.track('WEBMAIL_error_timeZoneError');
      break;
    }
    case 'timeZoneSaveSuccess': {
      Helix.track('WEBMAIL_applicationActivity_timeZoneUpdate_success');
      break;
    }
    case 'emailFormat': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_emailFormat');
      break;
    }
    case 'emailFormatPageViewInit': {
      Helix.track('WEBMAIL_pageViewInit_settingsEmailFormat', {
        currPageUrl: '/user/setting/email-format',
      });
      break;
    }
    case 'emailFormatPageView': {
      Helix.track('WEBMAIL_pageView_settingsEmailFormat', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'emailFormatAutoSave': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_emailFormat_saveDraftsToggle', {
        opToggleState: payload.isToggled,
      });
      break;
    }
    case 'emailFormatDisplayAsText': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_emailFormat_showEmailPlainTextToggle', {
        opToggleState: payload.isToggled,
      });
      break;
    }
    case 'emailFormatDisplayImage': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_emailFormat_showImagesInEmailToggle', {
        opToggleState: payload.isToggled,
      });
      break;
    }
    case 'emailFormatAutoMailboxReload': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_emailFormat_autoRefreshEmailFolderToggle', {
        opToggleState: payload.isToggled,
      });
      break;
    }
    case 'emailFormatPermanentlyDelete': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_emailFormat_permanentlyDeleteEmailToggle', {
        opToggleState: payload.isToggled,
      });
      break;
    }
    case 'emailFormatError': {
      Helix.track('WEBMAIL_error_settings_emailFormat_toggleError');
      break;
    }
    case 'signature': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_signature');
      break;
    }
    case 'autoReply': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_autoReply');
      break;
    }
    case 'forwarding': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_forwarding');
      break;
    }
    case 'replyTo': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_replyTo');
      break;
    }
    case 'replyToPageViewInit': {
      Helix.track('WEBMAIL_pageViewInit_settingsReplyTo', {
        currPageUrl: 'user/setting/reply-to',
      });
      break;
    }
    case 'replyToPageView': {
      Helix.track('WEBMAIL_pageView_settingsReplyTo', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'replyToSave': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_replyToSave');
      break;
    }
    case 'replyToSaveSuccess': {
      Helix.track('WEBMAIL_assetDisplayed_replyToSuccess');
      break;
    }
    case 'replyToSaveError': {
      Helix.track('WEBMAIL_error_replyToError', {
        appErrorMessage: 'unableToSave',
      });
      break;
    }
    case 'replyToValidationError': {
      Helix.track('WEBMAIL_error_replyToError', {
        appErrorMessage: 'validationError',
      });
      break;
    }
    case 'replyToCancel': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_replyToCancel');
      break;
    }
    case 'filterRules': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_filterRules');
      break;
    }
    case 'filterRulesPageViewInit': {
      Helix.track('WEBMAIL_pageViewInit_settingsFilterRules', {
        currPageUrl: '/user/setting/filter',
      });
      break;
    }
    case 'filterRulesPageView': {
      Helix.track('WEBMAIL_pageView_settingsFilterRules', {
        msgEventType: 'pageViewCompleted',
        currPageName: 'settingsFilterRules',
      });
      break;
    }
    case 'filterRulesNewFilter': {
      Helix.track('WEBMAIL_selectAction_filterRules_createNewRule');
      Helix.track('WEBMAIL_pageViewInit_settingsNewRule', {
        currPageUrl: '/user/setting/filter/new',
      });
      break;
    }
    case 'filterRulesCreatePageView': {
      Helix.track('WEBMAIL_pageView_settingsNewRule', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'filterRulesEdit': {
      Helix.track('WEBMAIL_selectAction_filterRules_edit');
      Helix.track('WEBMAIL_pageViewInit_settingsEditRule', {
        currPageUrl: '/user/setting/filter/:filterId/update',
      });
      break;
    }
    case 'filterRulesEditPageView': {
      Helix.track('WEBMAIL_pageView_settingsEditRule', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'filterRulesDelete': {
      Helix.track('WEBMAIL_selectAction_filterRules_delete');
      break;
    }
    case 'filterRulesDeleteModalView': {
      Helix.track('WEBMAIL_modalView_deleteRule', {
        modalText: 'Delete Rule',
      });
      break;
    }
    case 'filterRulesDeleteModalViewDelete': {
      Helix.track('WEBMAIL_selectAction_filterRules_deleteRuleModal_delete');
      break;
    }
    case 'filterRulesDeleteModalViewClose': {
      Helix.track('WEBMAIL_selectAction_filterRules_deleteRuleModal_close');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'filterRulesBackButton': {
      Helix.track('WEBMAIL_selectAction_filterRules_backButton');
      break;
    }
    case 'filterRulesNewAddCondition': {
      Helix.track('WEBMAIL_selectAction_filterRules_addCondition', {
        currPageElemStdName: 'newRule-addAdditionalCondition',
      });
      break;
    }
    case 'filterRulesEditAddCondition': {
      Helix.track('WEBMAIL_selectAction_filterRules_addCondition', {
        currPageElemStdName: 'editRule-addAdditionalCondition',
      });
      break;
    }
    case 'filterRulesSaveNew': {
      Helix.track('WEBMAIL_selectAction_filterRules_save', {
        currPageElemStdName: 'newRule-save',
      });
      break;
    }
    case 'filterRulesSaveEdit': {
      Helix.track('WEBMAIL_selectAction_filterRules_save', {
        currPageElemStdName: 'editRule-save',
      });
      break;
    }
    case 'filterRulesNewCancel': {
      Helix.track('WEBMAIL_selectAction_filterRules_cancel', {
        currPageElemStdName: 'newRule-cancel',
      });
      break;
    }
    case 'filterRulesEditCancel': {
      Helix.track('WEBMAIL_selectAction_filterRules_cancel', {
        currPageElemStdName: 'editRule-cancel',
      });
      break;
    }
    case 'filterRulesCreateSuccess': {
      Helix.track('WEBMAIL_applicationActivity_filterRules_success', {
        opType: 'newRule-success',
      });
      break;
    }
    case 'filterRulesEditSuccess': {
      Helix.track('WEBMAIL_applicationActivity_filterRules_success', {
        opType: 'editRule-success',
      });
      break;
    }
    case 'filterRuleSorted': {
      Helix.track('WEBMAIL_selectAction_filterRules_moveRule');
      break;
    }
    case 'filterRulesDeleteSuccess': {
      Helix.track('WEBMAIL_applicationActivity_filterRules_success', {
        opType: 'deleteRule-success',
      });
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'filterRulesDeleteError': {
      Helix.track('WEBMAIL_error_filterRules', {
        appErrorMessage: 'deleteRule-unableToSave',
      });
      break;
    }
    case 'filterRulesCreateError': {
      Helix.track('WEBMAIL_error_filterRules', {
        appErrorMessage: 'newRule-unableToSave',
      });
      break;
    }
    case 'filterRulesCreateValidationError': {
      Helix.track('WEBMAIL_error_filterRules', {
        appErrorMessage: 'newRule-validation',
      });
      break;
    }
    case 'filterRulesEditError': {
      Helix.track('WEBMAIL_error_filterRules', {
        appErrorMessage: 'editRule-unableToSave',
      });
      break;
    }
    case 'filterRulesEditValidationError': {
      Helix.track('WEBMAIL_error_filterRules', {
        appErrorMessage: 'editRule-validation',
      });
      break;
    }
    case 'filterRulesUnableToMoveError': {
      Helix.track('WEBMAIL_error_filterRules', {
        appErrorMessage: 'moveRule-unableToSave',
      });
      break;
    }
    case 'filterRulesMoveSuccess': {
      Helix.track('WEBMAIL_applicationActivity_filterRules_success', {
        opType: 'moveRule-success',
      });
      break;
    }
    case 'spamManagement': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_spamManagement');
      break;
    }
    case 'blockedSenders': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_blockedSenders');
      break;
    }
    case 'safeSenders': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_safeSenders');
      break;
    }
    case 'emailStorage': {
      Helix.track('WEBMAIL_assetDisplayed_emailStorageAlert', {
        currPageElemStrValue: payload.usage,
      });
      break;
    }
    case 'supportLink': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_supportPageLinkOut');
      break;
    }
    case 'signatureToggle': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_signatureToggle', {
        opToggleState: payload.isToggled,
      });
      break;
    }
    case 'signatureSave': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_editSignatureSave');
      break;
    }
    case 'signatureCancel': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_editSignatureCancel');
      break;
    }
    case 'signatureSaveSuccess': {
      Helix.track('WEBMAIL_assetDisplayed_signatureSaveSuccessToast');
      break;
    }
    case 'signatureError': {
      Helix.track('WEBMAIL_error_unableToSaveSignature');
      break;
    }
    case 'signaturePageViewInit': {
      Helix.track('WEBMAIL_pageViewInit_settingsSignature', {
        currPageUrl: '/user/setting/signature',
      });
      break;
    }
    case 'signaturePageView': {
      Helix.track('WEBMAIL_pageView_settingsSignature', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'autoReplyToggle': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_autoReplyToggle', {
        opToggleState: payload.isToggled,
      });
      break;
    }
    case 'autoReplySave': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_editAutoReplySave');
      break;
    }
    case 'autoReplyCancel': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_editAutoReplyCancel');
      break;
    }
    case 'autoReplyError': {
      Helix.track('WEBMAIL_error_unableToSaveAutoReply');
      break;
    }
    case 'autoReplyPageViewInit': {
      Helix.track('WEBMAIL_pageViewInit_settingsAutoReply', {
        currPageUrl: '/user/setting/auto-reply',
      });
      break;
    }
    case 'autoReplyPageView': {
      Helix.track('WEBMAIL_pageView_settingsAutoReply', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'editorFormatChange': {
      Helix.track('WEBMAIL_selectAction_textEditingIcons', {
        type: payload.type,
        uiName: payload.uiName,
      });
      break;
    }
    case 'safeSenderPageViewInit': {
      Helix.track('WEBMAIL_pageViewInit_settingsSafeSenders', {
        currPageUrl: '/user/setting/safe-senders',
      });
      break;
    }
    case 'safeSenderPageView': {
      Helix.track('WEBMAIL_pageView_settingsSafeSenders', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'safeSenderAdd': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_safeSenders_addAddressOrDomain');
      break;
    }
    case 'safeSenderDelete': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_safeSenders_deleteSafeSenderTrashIcon');
      break;
    }
    case 'safeSenderSave': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_safeSenders_saveSafeSender');
      break;
    }
    case 'safeSenderError': {
      Helix.track('WEBMAIL_error_safeSenderError', {
        appErrorMessage: payload.errorMessage,
        appErrorType: payload.errorMessage,
      });
      break;
    }
    case 'safeSenderSuccess': {
      Helix.track('WEBMAIL_applicationActivity_addSafeSenderSuccess');
      break;
    }
    case 'blockedSenderPageViewInit': {
      Helix.track('WEBMAIL_pageViewInit_settingsBlockedSenders', {
        currPageUrl: '/user/setting/block-senders',
      });
      break;
    }
    case 'blockedSenderPageView': {
      Helix.track('WEBMAIL_pageView_settingsBlockedSenders', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'blockedSenderAdd': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_blockedSenders_addBlockedAddressOrDomain');
      break;
    }
    case 'blockedSenderDelete': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_blockedSenders_deleteBlockedSender_trashIcon');
      break;
    }
    case 'blockedSenderSave': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_blockedSenders_saveBlockedSender');
      break;
    }
    case 'blockedSenderCancel': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_blockedSenders_cancelBlockedSender');
      break;
    }
    case 'blockedSenderUndo': {
      Helix.track('WEBMAIL_selectAction_settingsNavigation_blockedSenders_undoDeleteBlockedSender');
      break;
    }
    case 'blockedSenderError': {
      Helix.track('WEBMAIL_error_settings_blockedSendersError', {
        appErrorMessage: payload.errorMessage,
        appErrorType: payload.errorMessage,
      });
      break;
    }
    case 'blockedSenderNoResults': {
      Helix.track('WEBMAIL_assetDisplayed_blockedSenders_noResultsFound');
      break;
    }
    case 'blockedSenderSuccessNotification': {
      Helix.track('WEBMAIL_assetDisplayed_senderBlockedNotification');
      break;
    }
    case 'forwardingPageViewInit': {
      Helix.track('WEBMAIL_pageViewInit_settingsForwarding', {
        currPageUrl: '/user/setting/forwarding',
      });
      break;
    }
    case 'forwardingPageView': {
      Helix.track('WEBMAIL_pageView_settingsForwarding', {
        msgEventType: 'pageViewCompleted',
      });
      Helix.track('WEBMAIL_assetDisplayed_forwardingEmailCeasedCopy');
      break;
    }
    case 'disableForwardingModalView': {
      break;
    }
    case 'disableForwardingSuccess': {
      break;
    }
    case 'disableForwardingError': {
      break;
    }
    case 'disableForwardingModalViewClose': {
      break;
    }
    default: {
      console.log('Event Case For Settings Not Found', event);
      return null;
    }
  }
  return null;
};

export default submitSettingsEvent;
