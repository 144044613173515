import { getAppEnvironment } from 'app-constants/appConfig';
import { PageNSTranslationFileKeys } from 'translation/hooks';

type PreviewValueType = string | number;

export interface SkipLinkI {
  href: string;
  section: PageNSTranslationFileKeys<'topNavigationCopy'>;
}
export interface SectionTabInterface {
  uiName: string;
  href: string;
  preview?: PreviewValueType;
  trackingEvent: string;
  autoFocus?: boolean;
  pageViewInitEvent?: string;
}

export interface GlobalFoldersInterface {
  INBOX: string;
  Junkmail: string;
  'Junk Mail': string;
  SentMail: string;
  Trash: string;
  Drafts: string;
}

const COMPOSE_WINDOW_WIDTH = 614;
const AUTOSAVE_DRAFT_BUFFER = 5000;
const LOGIN_SUCCESSFUL_REDIRECT = '/user/mail/SU5CT1g%3D';
const INBOX_URL = '/user/mail/SU5CT1g%3D';
const CONTACT_URL = '/user/label/all';
const SETTING_URL = '/user/setting/account';
const LOGIN_URL_PATH = '/api/v1/sso/access-token';

const DRAFT_FOLDER_ID = 'RHJhZnRz';
const TRASH_FOLDER_ID = 'VHJhc2g=';
const SENT_MAIL_FOLDER_ID = 'U2VudE1haWw=';
const INBOX_MAIL_FOLDER_ID = 'SU5CT1g=';
const SPAM_FOLDER_IDS = ['SnVuayBNYWls', 'SnVua21haWw='];

const MESSAGE_FLAG_FLAGGED = '\\Flagged';
const MESSAGE_FLAG_READ = '\\Seen';

const GLOBAL_FOLDERS = [
  INBOX_MAIL_FOLDER_ID,
  DRAFT_FOLDER_ID,
  TRASH_FOLDER_ID,
  SENT_MAIL_FOLDER_ID,
  ...SPAM_FOLDER_IDS,
];

const GLOBAL_FOLDER_NAMES: GlobalFoldersInterface = {
  INBOX: 'Inbox',
  Junkmail: 'Spam',
  'Junk Mail': 'Spam',
  Trash: 'Trash',
  SentMail: 'Sent Mail',
  Drafts: 'Drafts',
};

const SETTINGS_TABS: SectionTabInterface[] = [
  {
    uiName: 'accountInfo',
    href: '/user/setting/account',
    trackingEvent: 'accountInfo',
    autoFocus: true,
  },
  { uiName: 'timeZone', href: '/user/setting/time-zone', trackingEvent: 'timeZone' },
  {
    uiName: 'emailFormat',
    href: '/user/setting/email-format',
    trackingEvent: 'emailFormat',
  },
  {
    uiName: 'signature',
    href: '/user/setting/signature',
    preview: 'autoInsert',
    trackingEvent: 'signature',
  },
  {
    uiName: 'autoReply',
    href: '/user/setting/auto-reply',
    preview: 'autoReply',
    trackingEvent: 'autoReply',
  },
  {
    uiName: 'forwarding',
    href: '/user/setting/forwarding',
    preview: 'forwarding',
    trackingEvent: 'forwarding',
  },
  { uiName: 'replyTo', href: '/user/setting/reply-to', trackingEvent: 'replyTo' },
  {
    uiName: 'filterRules',
    href: '/user/setting/filter',
    trackingEvent: 'filterRules',
    pageViewInitEvent: 'filterRulesPageViewInit',
  },
  {
    uiName: 'spamManagement',
    href: '/user/setting/spam',
    trackingEvent: 'spamManagement',
  },
  {
    uiName: 'blockedSenders',
    href: '/user/setting/block-senders',
    trackingEvent: 'blockedSenders',
  },
  {
    uiName: 'safeSenders',
    href: '/user/setting/safe-senders',
    trackingEvent: 'safeSenders',
  },
];

const SUPPORTED_MIME_TYPES: string[] = [
  'application/msword',
  'image/gif',
  'text/calendar',
  'image/jpeg',
  'text/html',
  'application/pdf',
  'image/png',
  'text/plain',
  'application/x-msexcel',
  'text/xml',
  'application/x-compressed',
  'video/x-wmv',
  'octet/stream',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

const BLACKLISTED_FILE_EXTENSIONS: string[] = [
  'ade',
  'adp',
  'apk',
  'appx',
  'appxbundle',
  'bat',
  'cab',
  'chm',
  'cmd',
  'com',
  'cpl',
  'diagcab',
  'diagcfg',
  'diagpack',
  'dll',
  'dmg',
  'ex',
  'ex_',
  'exe',
  'hta',
  'img',
  'ins',
  'iso',
  'isp',
  'jar',
  'jnlp',
  'js',
  'jse',
  'lib',
  'lnk',
  'mde',
  'msc',
  'msi',
  'msix',
  'msixbundle',
  'msp',
  'mst',
  'nsh',
  'pif',
  'ps1',
  'scr',
  'sct',
  'shb',
  'sys',
  'vb',
  'vbe',
  'vbs',
  'vhd',
  'vxd',
  'wsc',
  'wsf',
  'wsh',
  'xll',
];

const APPROVE_BLOCK_SENDER_LIST_MAX_COUNT = 250;
const CONTACT_MAX_COUNT = 500;
const MAX_FOLDER_NAME_LENGTH = 63;
const MAX_LABEL_NAME_LENGTH = 25;

const SELECT_CONTACT_IMG = '/assets/images/select-a-contact.svg';
const SELECT_EMAIL_IMG = '/assets/images/select-an-email.svg';
const CONTACT_CARD_IMG = '/assets/images/contacts-card.svg';
const EMPTY_LABELS_IMG = '/assets/images/empty-labels.svg';
const EMAIL_NOT_FOUND_IMG = '/assets/images/inbox-empty-state.svg';
const CONTACTS_SYNC_IMG = '/assets/images/ki-autopay.svg';

const app = {
  AUTO_SAVE_DRAFT_INTERVAL: 15,
  LOGIN_REDIRECT_URL: '/login',
  LOGIN_API_ERROR_MESSAGES: ['GRAPHQL_VALIDATION_FAILED', 'UNPROCESSABLE_ENTITY', 'GODLY_ERROR'],
  ACCESS_TOKEN_BUFFER: 120000,
  LEGACY_WEBMAIL_URL: 'https://webmail.spectrum.net',
};

const ui = {
  DEFAULT_OUTER_PANE_SIZES: [260, 952],
  DEFAULT_INNER_PANE_SIZES: [336, 624],
  MENU_ANIMATION_BUFFER: 470,
  DEBOUNCE_SEARCH_DELAY: 500,
  LOGIN_REDIRECT_DOMAINS: ['@charter.net', '@spectrum.net', '@spectrum.com'],
  CHARTER_COMMUNICATION_LOGO_LINK: '/assets/images/charter-logo-footer.svg',
  MADE_FOR_MOBILE_LINK_1: '/assets/images/mobile-1.svg',
  MADE_FOR_MOBILE_LINK_2: '/assets/images/mobile-2.svg',
  MADE_FOR_MOBILE_LINK_3: '/assets/images/mobile-3.svg',
  UPDATED_PERFORMANCE_LINK: '/assets/images/updated-performance.svg',
  EMAIL_INTRODUCTION_LINK: '/assets/images/email-introduction-new.svg',
  CHARTER_LOGO_LINK: '/assets/images/spectrum-logo.svg',
  HEADER_MENU_BACKGROUND: '/assets/images/grey-texture.png',
  MAIN_NAVIGATION_TABS: ['mail', 'contacts', 'settings'],
  TUTORIAL_DESKTOP_STEP_1: '/assets/images/tutorial-desktop-1.svg',
  TUTORIAL_DESKTOP_STEP_2: '/assets/images/tutorial-desktop-2.svg',
  TUTORIAL_DESKTOP_STEP_3: '/assets/images/tutorial-desktop-3.svg',
  TUTORIAL_DESKTOP_STEP_4: '/assets/images/tutorial-desktop-4.svg',
  TUTORIAL_DESKTOP_STEP_5: '/assets/images/tutorial-desktop-5.svg',
  TUTORIAL_MOBILE_STEP_1: '/assets/images/tutorial-mobile-step-1.svg',
  TUTORIAL_MOBILE_STEP_2: '/assets/images/tutorial-mobile-step-2.svg',
  TUTORIAL_MOBILE_STEP_3: '/assets/images/tutorial-mobile-step-3.svg',
  TUTORIAL_MOBILE_STEP_4: '/assets/images/tutorial-mobile-step-4.svg',
  TUTORIAL_MOBILE_STEP_5: '/assets/images/tutorial-mobile-step-5.svg',
  TUTORIAL_MOBILE_STEP_6: '/assets/images/tutorial-mobile-step-6.svg',
};

const helix = {
  HELIX_REQ_VERSION: '2.664',
  HELIX_DOMAIN: 'portals',
  HELIX_APP_NAME: 'WebMail',
  HELIX_APP_TYPE: 'Web',
  HELIX_APP_VERSION: process.env.REACT_APP_VERSION || 'dev',
  HELIX_START_SESSION: 'WEBMAIL_startSession_generic',
  HELIX_ENDPOINT:
    getAppEnvironment() === 'prod'
      ? 'https://collector.pi.spectrum.net/topics/sspp?bulk=true'
      : 'https://collector.pi-charter.net/topics/sspp?bulk=true',
  HELIX_ENV: getAppEnvironment() === 'prod' ? 'prod' : 'staging',
};

const SKIP_LINKS: SkipLinkI[] = [{ section: 'skipMainContent', href: '#mainContent' }];

export const streetAddressMaxContactForm = 250;
export const cityMaxContactForm = 120;
export const stateMaxContactForm = 100;
export const zipCodeMaxContactForm = 20;
export const countryMaxContactForm = 120;
export const firstNameMaxContactForm = 120;
export const lastNameMaxContactForm = 120;
export const displayNameMaxContactForm = 120;
export const nickNameMaxContactForm = 120;
export const titleMaxContactForm = 200;
export const companyMaxContactForm = 120;
export const notesMaxContactForm = 1048576;
export const urlMaxContactForm = 120;

export const appConstants = {
  COMPOSE_WINDOW_WIDTH,
  AUTOSAVE_DRAFT_BUFFER,
  LOGIN_SUCCESSFUL_REDIRECT,
  MESSAGE_FLAG_FLAGGED,
  MESSAGE_FLAG_READ,
  GLOBAL_FOLDERS,
  SETTINGS_TABS,
  SUPPORTED_MIME_TYPES,
  BLACKLISTED_FILE_EXTENSIONS,
  APPROVE_BLOCK_SENDER_LIST_MAX_COUNT,
  CONTACT_MAX_COUNT,
  SELECT_CONTACT_IMG,
  CONTACT_CARD_IMG,
  EMPTY_LABELS_IMG,
  SELECT_EMAIL_IMG,
  EMAIL_NOT_FOUND_IMG,
  CONTACTS_SYNC_IMG,
  INBOX_URL,
  CONTACT_URL,
  SETTING_URL,
  TRASH_FOLDER_ID,
  DRAFT_FOLDER_ID,
  SENT_MAIL_FOLDER_ID,
  INBOX_MAIL_FOLDER_ID,
  SPAM_FOLDER_IDS,
  GLOBAL_FOLDER_NAMES,
  MAX_FOLDER_NAME_LENGTH,
  MAX_LABEL_NAME_LENGTH,
  LOGIN_URL_PATH,
  helix,
  ui,
  app,
  SKIP_LINKS,
} as const;
