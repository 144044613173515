import reactDOM from 'react-dom';
import styles from 'components/common/root-modal/styles/RootModal.module.scss';
import { TrackingHook, useTracking } from 'react-tracking';
import FocusTrap from 'focus-trap-react';
import { KiteButton, KiteCard, KiteIcon } from '@kite/react-kite';
import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateDrawerName, updateIsDrawerOpen, updateIsOpen, updateModalName } from 'slices/app';
import { useTarget } from '../focus/FocusContext';
import { appConstants } from 'appConstants';

interface IModalProps {
  isShown: boolean | null | undefined;
  isDrawer: boolean;
  children: React.ReactNode;
  enableTracking?: boolean;
}

const Modal: React.FC<IModalProps> = ({ isShown, children, isDrawer, enableTracking }): React.ReactElement | null => {
  const { trackEvent }: TrackingHook = useTracking({ page: 'login', component: 'Modal' });
  const modalRef = useRef<HTMLDivElement>(null);
  const focusOpener = useTarget('modal');
  const isModalOpen = useAppSelector((state) => state.appSlice.isOpen);
  const { hideCloseButton, isCanary, isWide, isAddressBook } = useAppSelector((state) => state.appSlice.modalProps);
  const [isDrawerDelayClosed, setIsDrawerDelayClosed] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleModalClose = (): void => {
    dispatch(updateIsOpen(false));
    dispatch(updateModalName(''));
    focusOpener();
    if (enableTracking) {
      trackEvent({ event: 'modalClose' });
    }
  };

  const handleDrawerClose = (): void => {
    if (isModalOpen) {
      return;
    }
    setIsDrawerDelayClosed(true);

    setTimeout(() => {
      dispatch(updateIsDrawerOpen(false));
      dispatch(updateDrawerName(''));
    }, appConstants.ui.MENU_ANIMATION_BUFFER);
  };

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, []);

  const getKiteCard = (children: React.ReactNode): JSX.Element => {
    return (
      <div onKeyDown={(e) => e.key === 'Escape' && handleModalClose()}>
        <KiteCard
          className={`${styles.modalCard} ${isCanary ? styles.canaryCard : ''} ${isWide ? styles.wideModal : ''} ${
            isCanary ? styles.canaryCard : ''
          } ${isAddressBook ? styles.addressBookCard : ''}`}
        >
          {hideCloseButton === true ? null : (
            <KiteButton
              aria-label="click to close modal"
              className={styles.closeButton}
              onClick={handleModalClose}
              variant={'borderless'}
            >
              <KiteIcon icon="ki-x" size="20px" />
            </KiteButton>
          )}

          {children}
        </KiteCard>
      </div>
    );
  };

  const getDrawerKiteCard = (children: React.ReactNode): JSX.Element => {
    const cardClass = isDrawerDelayClosed ? styles.closeCard : styles.openCard;

    const drawerChildren = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { handleDrawerClose });
      }

      return child;
    });

    return (
      <KiteCard className={`${styles.drawerCard} ${cardClass}`}>
        <KiteButton className={styles.closeButton} onClick={handleDrawerClose} variant={'borderless'}>
          <KiteIcon icon="ki-x" size="20px" />
        </KiteButton>

        {drawerChildren}
      </KiteCard>
    );
  };

  const modal = (
    <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true, returnFocusOnDeactivate: false }}>
      <div ref={modalRef} className={styles.modalContainer}>
        <div className={`${styles.overlay} ${!isDrawer ? styles.modalOverlay : ''}`}></div>
        {isDrawer ? getDrawerKiteCard(children) : getKiteCard(children)}
      </div>
    </FocusTrap>
  );

  return isShown ? reactDOM.createPortal(modal, document.body) : null;
};

export default Modal;
