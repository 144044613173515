import { KiteIcon } from '@kite/react-kite';
import React from 'react';
import { TrackingHook, useTracking } from 'react-tracking';
import { useAppDispatch } from 'hooks';
import { updateDrawerName, updateIsDrawerOpen } from 'slices/app';
import { useSharedTranslation } from 'translation/hooks';

interface Props {
  styles: { readonly [key: string]: string };
  authenticated: boolean;
}

const Hamburger: React.FC<Props> = ({ styles, authenticated }) => {
  const dispatch = useAppDispatch();
  const { headerMenuText, loginHeaderMenu } = styles;
  const { t } = useSharedTranslation();
  const { trackEvent }: TrackingHook = useTracking({
    page: authenticated ? 'navigation' : 'login',
    component: 'Hamburger',
  });

  const handleClick = (): void => {
    trackEvent({
      event: 'hamburgerOpen',
    });

    dispatch(updateIsDrawerOpen(true));
    dispatch(updateDrawerName('navigation'));
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={`${loginHeaderMenu} icon-button nav-button ${authenticated && styles.mainHeaderHamburger}`}
      id="navigation-menu"
    >
      <KiteIcon icon="ki-menu" size="1.5rem" />
      {!authenticated && <span className={headerMenuText}>{t('menu').toUpperCase()}</span>}
    </button>
  );
};

export default Hamburger;
