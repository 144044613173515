export const emailFormatCopy = {
  emailFormatTitle: '',
  saveDraftSubtitle: '',
  saveDraftDescriptionOn: '',
  saveDraftDescriptionOff: '',
  plainTextSubtitle: '',
  plainTextDescriptionOn: '',
  plainTextDescriptionOff: '',
  showImagesSubtitle: '',
  showImagesDescriptionOn: '',
  showImagesDescriptionOff: '',
  autoRefreshSubtitle: '',
  autoRefreshDescriptionOff: '',
  autoRefreshDescriptionOn: '',
};
