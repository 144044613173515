import { KiteGrid, KiteGridCell, KiteLink } from '@kite/react-kite';
import { SUPPORT_LINK } from 'app-constants';
import concatUrl from 'utils/concatUrl';
import styles from 'components/header/styles/LoginHeader.module.scss';
import useModal from 'hooks/modalHook';
import LoginDrawerContainer from 'containers/LoginDrawerContainer';
import { TrackingHook, useTracking } from 'react-tracking';
import Hamburger from 'components/common/Hamburger';
import { appConstants } from 'appConstants';
import { usePageTranslation } from 'translation/hooks';

const LoginHeader: React.FC = () => {
  const { toggle, isShown } = useModal();
  const { t } = usePageTranslation('linksCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'login', component: 'LoginHeader' });
  return (
    <nav className={styles.loginHeader}>
      <KiteGrid>
        <KiteGridCell col={4} className={`${styles.headerMenu} ${styles.headerCell}`}>
          <Hamburger styles={styles} authenticated={false} />
        </KiteGridCell>
        <KiteGridCell col={4} className={`${styles.headerLogo} ${styles.headerCell}`}>
          <a id="loginLogoLink" href="/" onClick={() => trackEvent({ event: 'spectrumLogoLink' })}>
            <img src={appConstants.ui.CHARTER_LOGO_LINK} alt="Spectrum" />
          </a>
        </KiteGridCell>
        <KiteGridCell col={4} className={`${styles.headerLinks} ${styles.headerCell}`}>
          <KiteLink
            type="inline"
            href={concatUrl(SUPPORT_LINK.baseUrl, SUPPORT_LINK.href)}
            className={styles.supportLink}
            target="_blank"
            onClick={() => trackEvent({ event: 'loginSupportLinkNav' })}
          >
            {t(SUPPORT_LINK.uiName)}
          </KiteLink>
        </KiteGridCell>
        <LoginDrawerContainer toggle={toggle} isShown={isShown} />
      </KiteGrid>
    </nav>
  );
};

export default LoginHeader;
