import { KiteButton, KiteIcon } from '@kite/react-kite';
import BackButton from 'components/common/BackButton';
import styles from 'components/message-options/styles/EmailOptions.module.scss';
import { useAppDispatch } from 'hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { updateIsOpen, updateModalName, updateModalProps, updateToastMessage } from 'slices/app';
import KebabMenu, { KebabMenuItemsInterface } from 'components/common/kebab-menu';
import { markMessagesFlagThunk } from 'thunks/message-collection/markMessageFlagThunk';
import {
  MessageListEntity,
  useMoveMessagesMutation,
  useMoveMessagesToTrashMutation,
  usePostMarkMessagesAsFlagMutation,
  usePostMarkMessagesAsSeenMutation,
  usePostMarkMessagesAsUnflagMutation,
  usePostMarkMessagesAsUnseenMutation,
} from 'generated/graphql';
import { markMessagesUnflagThunk } from 'thunks/message-collection/markMessageUnflagThunk';
import { markMessagesReadThunk } from 'thunks/message-collection/markMessagesReadThunk';
import { markMessagesUnreadThunk } from 'thunks/message-collection/markMessageUnreadThunk';
import { TrackingHook, useTracking } from 'react-tracking';
import { useReactToPrint } from 'react-to-print';
import { useAppSelector } from 'hooks';
import { useCallback, useRef, useState } from 'react';
import { appConstants } from 'appConstants';
import { moveMessagesThunk } from 'thunks/message-collection/moveMessagesThunk';
import { reloadMessageCollection, updateDeleteButtonLoading } from 'slices/message-collection';
import { isDraftFolder, isInboxFolder, isSentMailFolder, isSpamFolder, isTrashFolder } from 'utils/folderNameValidator';
import { isMessageFlagged, isMessageRead, isMessageUnflagged, isMessageUnread } from 'utils/messageFlagsUtils';
import { updateUnreadCountForMailbox } from 'slices/mailbox-collection';
import getUnseenMessagesCount from 'utils/getUnseenMessagesCount';
import { updateModalOpener, useTarget } from 'components/common/focus/FocusContext';
import { usePageTranslation } from 'translation/hooks';
import EmailPrint from 'components/email-print';
import useMobileSize from 'components/common/hooks/useMobileSize';
import { printIframe } from 'utils/printIframe';
import useNavToPreviousPage from 'components/common/hooks/useNavToPreviousPage';
import useCloseMessage from 'components/common/hooks/useCloseMessage';

const EmailOptions: React.FC = () => {
  const { t } = usePageTranslation('emailActionsCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const updateModalRef = updateModalOpener();
  const deleteButtonLoading = useAppSelector((state) => state.messageCollectionSlice.deleteButtonLoading);
  const messageCollection = useAppSelector((state) => state.messageCollectionSlice.messageCollection);
  const spamFolderId = useAppSelector((state) => state.mailboxCollectionSlice.spamFolderId);
  const message = useAppSelector((state) => state.messageSlice.message);
  const [markMessagesFlagHook] = usePostMarkMessagesAsFlagMutation();
  const [markMessagesUnflagHook] = usePostMarkMessagesAsUnflagMutation();
  const [markMessagesReadHook] = usePostMarkMessagesAsSeenMutation();
  const [markMessagesUnreadHook] = usePostMarkMessagesAsUnseenMutation();
  const [moveMessagesHook] = useMoveMessagesMutation();
  const [moveMessagesToTrashHook] = useMoveMessagesToTrashMutation();
  const focusPrintOpener = useTarget('modal');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useMobileSize();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isReadingPaneExpanded = !!query.get('expand');
  const [queryParams, currentPage, navToPreviousPage] = useNavToPreviousPage();
  const closeMessage = useCloseMessage();

  const emailPrintRef = useRef<HTMLIFrameElement | HTMLDivElement>(null);

  const [maxItems, setMaxItems] = useState(0);

  const { folder, '*': mailId } = useParams<{ folder: string; mailId: string; '*': string }>();

  if (!folder || !mailId) {
    return null;
  }

  const mailboxId = decodeURIComponent(folder);

  const handleMoveMessage = (): void => {
    trackEvent({ event: 'moveEmailTo' });
    dispatch(updateModalName('moveMessages'));
    dispatch(updateModalProps({ folder: mailboxId, messageUids: [parseInt(mailId)], currentMailId: mailId }));
    dispatch(updateIsOpen(true));
  };

  const handlePermanentDelete = (): void => {
    dispatch(updateModalName('permanentDeleteMessage'));
    dispatch(updateModalProps({ messageUid: parseInt(mailId), mailboxId, currentMailId: mailId }));
    dispatch(updateIsOpen(true));
  };

  const handleDeleteMessage = async (): Promise<boolean> => {
    trackEvent({ event: 'deleteEmail' });
    dispatch(updateDeleteButtonLoading(true));
    let unseenMessagesCount;

    if (isInboxFolder(mailboxId)) {
      unseenMessagesCount = getUnseenMessagesCount(messageCollection[mailboxId]?.data, [parseInt(mailId)]);
    }

    const result = await dispatch(
      moveMessagesThunk({
        payload: {
          sourceMailboxId: mailboxId || '',
          destinationMailboxId: appConstants.TRASH_FOLDER_ID,
          messageUids: [parseInt(mailId)],
          unseenMessagesCount,
        },
        moveMessagesHook: moveMessagesToTrashHook,
      })
    ).unwrap();

    if (result.success) {
      dispatch(updateToastMessage({ message: t('deleteMessagesSuccess'), success: true }));

      const { limit, total, data } = messageCollection[mailboxId];
      const lastPage = currentPage * limit > total;

      if (data?.length === 1 && Number(currentPage) > 1 && lastPage) {
        navToPreviousPage();
      }

      closeMessage(folder, queryParams);
      return true;
    }

    dispatch(updateModalName('deleteMessages'));

    dispatch(
      updateModalProps({
        folder: mailboxId,
        alertDescriptionProp: t('deleteMessageErr'),
        messageUids: [parseInt(mailId)],
        currentMailId: mailId,
        unseenMessagesCount,
      })
    );
    dispatch(updateIsOpen(true));
    return false;
  };

  const handleFlagMessage = async (): Promise<boolean> => {
    trackEvent({ event: 'flagMessage' });
    const result = await dispatch(
      markMessagesFlagThunk({
        payload: {
          mailboxId: mailboxId || '',
          messageUids: [parseInt(mailId)],
        },
        markMessagesFlagHook,
      })
    ).unwrap();

    return !!result.success;
  };

  const handleUnflagMessage = async (): Promise<boolean> => {
    trackEvent({ event: 'unflagMessage' });
    const result = await dispatch(
      markMessagesUnflagThunk({
        payload: {
          mailboxId: mailboxId || '',
          messageUids: [parseInt(mailId)],
        },
        markMessagesUnflagHook,
      })
    );
    //@ts-ignore
    if (result?.payload?.success) {
      return true;
    }
    return false;
  };

  const handleMarkAsRead = async (): Promise<boolean> => {
    trackEvent({ event: 'markAsRead' });
    let shouldSubtractUnread = false;
    if (isMessageUnread(message as MessageListEntity)) {
      shouldSubtractUnread = true;
    }
    const result = await dispatch(
      markMessagesReadThunk({
        payload: {
          mailboxId: mailboxId || '',
          messageUids: [parseInt(mailId)],
        },
        markMessagesReadHook,
      })
    );
    //@ts-ignore
    if (result?.payload?.success) {
      if (shouldSubtractUnread) {
        dispatch(
          updateUnreadCountForMailbox({
            mailboxId,
            updateCount: -1,
          })
        );
      }
      return true;
    }
    return false;
  };

  const handleMarkAsUnread = async (): Promise<boolean> => {
    trackEvent({ event: 'markAsUnread' });
    let shouldAddUnread = false;
    if (isMessageRead(message as MessageListEntity)) {
      shouldAddUnread = true;
    }
    const result = await dispatch(
      markMessagesUnreadThunk({
        payload: {
          mailboxId: mailboxId || '',
          messageUids: [parseInt(mailId)],
        },
        markMessagesUnreadHook,
      })
    );
    //@ts-ignore
    if (result?.payload?.success) {
      if (shouldAddUnread) {
        dispatch(
          updateUnreadCountForMailbox({
            mailboxId,
            updateCount: 1,
          })
        );
      }
      return true;
    }
    return false;
  };

  const handleBlockSender = (): void => {
    trackEvent({ event: 'blockSender' });
    dispatch(updateModalProps({ isBlockSendersBulk: false }));
    dispatch(updateModalName('blockSenders'));
    dispatch(updateIsOpen(true));
  };

  const handleMarkAsSpam = (): void => {
    trackEvent({ event: 'markAsSpam' });
    dispatch(
      updateModalProps({
        sourceMailboxId: folder,
        messageUids: [parseInt(mailId)],
        navigate,
        currentMailId: parseInt(mailId),
      })
    );
    dispatch(updateModalName('markAsSpam'));
    dispatch(updateIsOpen(true));
  };

  const handleMarkAsNotSpam = async (): Promise<void> => {
    trackEvent({ event: 'markAsNotSpam' });
    const unseenMessagesCount = getUnseenMessagesCount(messageCollection[folder]?.data, [parseInt(mailId)]);
    const result = await dispatch(
      moveMessagesThunk({
        moveMessagesHook,
        payload: {
          messageUids: [parseInt(mailId)],
          sourceMailboxId: spamFolderId,
          destinationMailboxId: appConstants.INBOX_MAIL_FOLDER_ID,
          unseenMessagesCount,
        },
      })
    ).unwrap();

    if (result.success) {
      dispatch(updateToastMessage({ message: t('markAsNotSpamSuccess'), success: true }));

      const { limit, total, data } = messageCollection[spamFolderId];
      const lastPage = currentPage * limit > total;

      if (result.messageUids.length === data?.length && Number(currentPage) > 1 && lastPage) {
        navToPreviousPage();
      }

      closeMessage(folder, queryParams);
      dispatch(reloadMessageCollection(true));

      return;
    }

    dispatch(updateModalProps({ messageUids: [parseInt(mailId)], currentMailId: mailId }));
    dispatch(updateModalName('markAsNotSpam'));
    dispatch(updateIsOpen(true));
  };

  const printReact = useReactToPrint({
    content: () => {
      trackEvent({ event: 'printEmail' });
      return emailPrintRef.current;
    },
    pageStyle: `@media print {
      html, body {
        height: 100vh !important;
        width: 100vw !important;
        }
      }
    }`,
    onAfterPrint: () => {
      focusPrintOpener();
    },
  });

  const onBeforePrint = (): void => {
    trackEvent({ event: 'printEmail' });
  };
  const onAfterPrint = (): void => {
    focusPrintOpener();
  };

  const handlePrint = (): void => {
    if (!emailPrintRef.current) {
      return;
    }

    if (emailPrintRef.current.dataset.iframe) {
      printIframe(emailPrintRef.current as HTMLIFrameElement, { onAfterPrint, onBeforePrint });
      return;
    }
    printReact();
  };

  const handleViewHeader = (): void => {
    trackEvent({ event: 'viewHeader' });
    dispatch(updateModalName('viewHeader'));
    dispatch(updateModalProps({ mailboxId, messageUid: message?.uid, isWide: true, enableTracking: true }));
    dispatch(updateIsOpen(true));
  };

  const kebabMenuItems: KebabMenuItemsInterface[] = [
    {
      onClick: handleMarkAsSpam,
      icon: 'ki-spam',
      label: t('markAsSpam'),
      isMenuItemHidden: isSentMailFolder(folder) || isSpamFolder(folder),
      opensModal: true,
    },
    {
      onClick: handleMarkAsNotSpam,
      icon: 'ki-spam',
      label: t('markAsNotSpam'),
      isMenuItemHidden: !isSpamFolder(folder),
      opensModal: true,
    },
    {
      onClick: handlePrint,
      icon: 'ki-print',
      label: t('print'),
      opensModal: true,
    },
    {
      onClick: handleBlockSender,
      icon: 'ki-block-sender',
      label: t('blockSender'),
      isMenuItemHidden: isSentMailFolder(folder),
      opensModal: true,
    },
    {
      onClick: handleFlagMessage,
      icon: 'ki-flag',
      label: t('flag'),
      isMenuItemHidden: message && isMessageFlagged(message),
    },
    {
      onClick: handleUnflagMessage,
      icon: 'ki-flag',
      label: t('unflag'),
      isMenuItemHidden: message && isMessageUnflagged(message),
    },
    {
      onClick: handleMarkAsRead,
      icon: 'ki-mail-mark-read',
      label: t('markAsRead'),
      isMenuItemHidden: isSentMailFolder(folder) || (message && isMessageRead(message)),
    },
    {
      onClick: handleMarkAsUnread,
      icon: 'ki-mail-mark-unread',
      label: t('markAsUnread'),
      isMenuItemHidden: isSentMailFolder(folder) || (message && isMessageUnread(message)),
    },
    {
      onClick: handleViewHeader,
      opensModal: true,
      icon: 'ki-document',
      label: t('viewHeader'),
    },
  ].filter((item) => !item.isMenuItemHidden);

  const handleResize = (current: HTMLDivElement): void => {
    const handleMobileOffset = (width: number): number => (isMobile ? width + 100 : width);
    if (current && !isSentMailFolder(folder)) {
      const { width } = current.getBoundingClientRect();
      switch (true) {
        case width < handleMobileOffset(620):
          setMaxItems(kebabMenuItems.length - 6);
          break;
        case width < handleMobileOffset(650):
          setMaxItems(kebabMenuItems.length - 5);
          break;
        case width < handleMobileOffset(700):
          setMaxItems(kebabMenuItems.length - 5);
          break;
        case width < handleMobileOffset(815):
          setMaxItems(kebabMenuItems.length - 4);
          break;
        case width < handleMobileOffset(840):
          setMaxItems(kebabMenuItems.length - 4);
          break;
        case width < handleMobileOffset(930):
          setMaxItems(kebabMenuItems.length - 3);
          break;
        case width < handleMobileOffset(1150):
          setMaxItems(kebabMenuItems.length - 2);
          break;
        default:
          setMaxItems(kebabMenuItems.length);
      }
    } else if (current) {
      const { width } = current.getBoundingClientRect();
      switch (true) {
        case width < handleMobileOffset(500):
          setMaxItems(kebabMenuItems.length - 4);
          break;
        case width < handleMobileOffset(580):
          setMaxItems(kebabMenuItems.length - 3);
          break;
        case width < handleMobileOffset(600):
          setMaxItems(kebabMenuItems.length - 2);
          break;
        default:
          setMaxItems(kebabMenuItems.length);
      }
    }
  };

  const handleResizeRef = useCallback(
    (node: HTMLDivElement) => {
      handleResize(node);
      window.addEventListener('resize', () => handleResize(node));
      return () => window.removeEventListener('resize', () => handleResize(node));
    },
    [isReadingPaneExpanded, mailId, folder, message]
  );

  if (!message) {
    return (
      <div className={`${styles.section} ${isDraftFolder(folder) ? styles.hide : ''}`} ref={handleResizeRef}>
        <div className={styles.sectionLeft}>
          <div className={styles.mobileSection}>
            <BackButton />
            <div className={styles.selectButtonBorder} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles.section} ${isDraftFolder(folder) ? styles.hide : ''}`} ref={handleResizeRef}>
      <div className={styles.sectionLeft}>
        <div className={styles.mobileSection}>
          <BackButton />
          <div className={styles.selectButtonBorder} />
        </div>

        <KiteButton
          className={styles.kiteButton}
          variant="borderless"
          size="shrinkwrap"
          disabled={deleteButtonLoading}
          aria-disabled={deleteButtonLoading}
          loading={deleteButtonLoading}
          onClick={isTrashFolder(folder) || isSentMailFolder(folder) ? handlePermanentDelete : handleDeleteMessage}
        >
          <div className={`${styles.messageOptionsButton} ${styles.deleteButton}`}>
            <KiteIcon icon={'ki-trash'} />
            <span>{t('delete')}</span>
          </div>
        </KiteButton>
        <KiteButton variant="borderless" size="shrinkwrap" onClick={handleMoveMessage}>
          <div className={styles.messageOptionsButton}>
            <KiteIcon icon={'ki-move-to'} />
            <span>{t('moveTo')}</span>
          </div>
        </KiteButton>
        {kebabMenuItems.slice(0, maxItems < 0 ? 0 : maxItems).map((kebabMenuItem, idx) => {
          return (
            <KiteButton
              key={`emailoptions-button__${kebabMenuItem.label}-${idx}`}
              variant="borderless"
              size="shrinkwrap"
              onClick={(e) => {
                kebabMenuItem.onClick();
                if (kebabMenuItem.opensModal) {
                  updateModalRef(e.currentTarget);
                }
              }}
            >
              <div className={styles.messageOptionsButton}>
                <KiteIcon icon={kebabMenuItem.icon || ''} />
                <span>{kebabMenuItem.label}</span>
              </div>
            </KiteButton>
          );
        })}
      </div>
      <div className={styles.sectionRight}>
        {maxItems < kebabMenuItems.length && (
          <label className={styles.messageOptionsButton}>
            <KebabMenu aria-label={t('otherActions')} items={kebabMenuItems.slice(maxItems)} />
            <span className={styles.label}>{t('otherActions')}</span>
          </label>
        )}
      </div>
      <div style={{ visibility: 'hidden', position: 'fixed', width: 0, height: 0, overflow: 'hidden' }}>
        <EmailPrint message={message} ref={emailPrintRef} />
      </div>
    </div>
  );
};

export default EmailOptions;
