import React from 'react';
import styles from 'components/contact-print/styles/ContactPrint.module.scss';
import { ContactEntity, ContactWithLabelEntity } from 'generated/graphql';
import { usePageTranslation } from 'translation/hooks';
import { contactDateFormatter } from 'utils/dateFormatter';
import { transformToNewFormat } from 'utils/transformPhoneFormat';

interface ContactPrintProps {
  selectedContacts: ContactWithLabelEntity[] | ContactEntity[];
}

const ContactPrint = React.forwardRef<HTMLElement, ContactPrintProps>((props, ref) => {
  const { selectedContacts } = props;
  const { t } = usePageTranslation('contactFormCopy');

  const buildEmail = (contact: ContactEntity | ContactWithLabelEntity): JSX.Element[] | null => {
    if (!contact.emails || contact.emails?.length === 0) {
      return null;
    }

    return contact.emails?.map((email, index) => {
      return (
        <div className={styles.labelRow} key={index}>
          <span className={styles.label}>{`${t('email')} ${index + 1}`}: </span>
          {`${email.emailAddress} ${email.isDefault ? `(${t('default')})` : ''}`}
        </div>
      );
    });
  };

  const buildPhoneNumber = (contact: ContactEntity | ContactWithLabelEntity): JSX.Element[] | null => {
    if (!contact.phoneNumbers || contact.phoneNumbers?.length === 0) {
      return null;
    }

    return contact.phoneNumbers?.map((phoneNumber, index) => {
      return (
        <div className={styles.labelRow} key={index}>
          <span className={styles.label}>{`${t('phoneNumber')} (${phoneNumber.phoneNumberType})`}: </span>
          {`${transformToNewFormat(phoneNumber.phoneNumber)} ${phoneNumber.isDefault ? `(${t('default')})` : ''}`}
        </div>
      );
    });
  };

  const buildAddress = (contact: ContactEntity | ContactWithLabelEntity): JSX.Element[] | null => {
    if (!contact.addresses || contact.addresses?.length === 0) {
      return null;
    }

    return contact.addresses?.map((address, index) => {
      return (
        <div className={styles.labelRow} key={index}>
          <span className={styles.label}>{`${t('address')} (${address.addressType})`}: </span>
          {`${address.streetAddress || ''}, ${address.city || ''}, ${address.state || ''} ${address.zipCode || ''}, ${
            address.country || ''
          }`}
        </div>
      );
    });
  };

  const buildContactList = (): JSX.Element[] => {
    return selectedContacts.map((contact, index) => {
      return (
        <li key={contact.id}>
          <div className={`${styles.labelRow} ${styles.nameLabel}`}>{`${index + 1}. ${contact.firstName ?? ''} ${
            contact.lastName ?? ''
          }`}</div>
          <div className={styles.divider}></div>
          {contact.displayName && (
            <div className={styles.labelRow}>
              <span className={styles.label}>{t('displayName')}: </span> {contact.displayName}
            </div>
          )}
          {contact.nickName && (
            <div className={styles.labelRow}>
              <span className={styles.label}>{t('nickname')}: </span> {contact.nickName}
            </div>
          )}
          {contact.company && (
            <div className={styles.labelRow}>
              <span className={styles.label}>{t('company')}: </span> {contact.company}
            </div>
          )}
          {contact.title && (
            <div className={styles.labelRow}>
              <span className={styles.label}>{t('title')}: </span> {contact.title}
            </div>
          )}
          {contact.dateOfBirth && (
            <div className={styles.labelRow}>
              <span className={styles.label}>{t('dateOfBirth')}: </span> {contactDateFormatter(contact.dateOfBirth)}
            </div>
          )}
          {contact.url && (
            <div className={styles.labelRow}>
              <span className={styles.label}>{t('url')}: </span> {contact.url}
            </div>
          )}
          {contact.notes && (
            <div className={styles.labelRow}>
              <span className={styles.label}>{t('note')}: </span> {contact.notes}
            </div>
          )}
          {buildEmail(contact)}
          {buildPhoneNumber(contact)}
          {buildAddress(contact)}
        </li>
      );
    });
  };

  if (selectedContacts.length === 0) {
    return <div className={styles.noContactWarning}>No contact selected</div>;
  }

  return (
    <>
      {/*@ts-ignore */}
      <div className={styles.contactPrintContainer} ref={ref}>
        <ul>{buildContactList()}</ul>
      </div>
    </>
  );
});

export default ContactPrint;
