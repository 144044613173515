import { ApolloError } from '@apollo/client';
import { createSlice } from '@reduxjs/toolkit';
import { MessageEntity } from 'generated/graphql';
import { getMessageThunk } from 'thunks/message/messageThunk';
import { markMessagesFlagThunk } from 'thunks/message-collection/markMessageFlagThunk';
import { markMessagesUnflagThunk } from 'thunks/message-collection/markMessageUnflagThunk';
import { isMessageFlagged, isMessageRead, isMessageUnflagged, isMessageUnread } from 'utils/messageFlagsUtils';
import { appConstants } from 'appConstants';
import { updateBlockSenderThunk } from 'thunks/setting/updateBlockedSenderThunk';
import { getRawMessageThunk } from 'thunks/message/rawMessageThunk';
import { sentMailThunk } from 'thunks/compose/sentMailThunk';
import { updateIsOpen } from 'slices/app';
import { markMessagesReadThunk } from 'thunks/message-collection/markMessagesReadThunk';
import { markMessagesUnreadThunk } from 'thunks/message-collection/markMessageUnreadThunk';
import { rawMessageStreamThunk } from 'thunks/message/rawMessageStreamThunk';
import { removeFromTabCollection } from 'slices/compose';

interface MessageState {
  message?: MessageEntity;
  apiError?: Error | null;
  messageLoading: boolean;
  showReadReceiptModal: boolean;
  rawMessage?: string;
  mailFetchError?: Error | null;
  reloadMessage?: boolean;
}

const initialState: MessageState = {
  messageLoading: false,
  showReadReceiptModal: false,
  reloadMessage: false,
};

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    reloadMessage: (state: MessageState, action) => {
      state.reloadMessage = action.payload;
    },
    clearMessageSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getMessageThunk.pending, (state) => {
      state.message = initialState.message;
      state.mailFetchError = initialState.mailFetchError;
      state.messageLoading = true;
      state.rawMessage = undefined;
    });

    builder.addCase(getMessageThunk.fulfilled, (state, action) => {
      state.messageLoading = false;
      state.reloadMessage = false;
      state.mailFetchError = undefined;

      if (action.payload.success && action.payload.success.readReceipt) {
        state.mailFetchError = undefined;
        state.showReadReceiptModal =
          action.payload.success.readReceipt.readReceipt && !!isMessageUnread(action.payload.success);
        state.message = action.payload.success;

        if (isMessageUnread(action.payload.success) && state.message.flags?.length) {
          state.message.flags.push(appConstants.MESSAGE_FLAG_READ);
        }
        return;
      }
      if (action.payload.error) {
        state.mailFetchError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(markMessagesFlagThunk.fulfilled, (state, action) => {
      if (action.payload.success && state.message && isMessageUnflagged(state.message)) {
        state.message?.flags?.push(appConstants.MESSAGE_FLAG_FLAGGED);
        return;
      }
      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(markMessagesUnflagThunk.fulfilled, (state, action) => {
      if (action.payload.success && state.message && isMessageFlagged(state.message)) {
        state.message.flags = state.message.flags?.filter((flag) => flag !== appConstants.MESSAGE_FLAG_FLAGGED);
        return;
      }
      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(markMessagesReadThunk.fulfilled, (state, action) => {
      if (
        action.payload.success &&
        state.message &&
        isMessageUnread(state.message) &&
        action.payload.messageUids?.includes(state.message.uid!)
      ) {
        state.message?.flags?.push(appConstants.MESSAGE_FLAG_READ);
        return;
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(markMessagesUnreadThunk.fulfilled, (state, action) => {
      if (
        action.payload.success &&
        state.message &&
        isMessageRead(state.message) &&
        action.payload.messageUids?.includes(state.message.uid!)
      ) {
        state.message.flags = state.message.flags?.filter((flag) => flag !== appConstants.MESSAGE_FLAG_READ);
        return;
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(updateBlockSenderThunk.fulfilled, (state, action) => {
      if (action.payload.error && action.payload.fromInbox) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });
    builder.addCase(getRawMessageThunk.pending, (state) => {
      state.apiError = undefined;
    });

    builder.addCase(rawMessageStreamThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.rawMessage = action.payload.success.data;
        state.apiError = undefined;
        return;
      }

      state.apiError = action.payload.error;
    });

    builder.addCase(getRawMessageThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.rawMessage = action.payload.success.raw;
        state.apiError = undefined;
        return;
      }

      state.apiError = action.payload.error;
    });
    builder.addCase(sentMailThunk.fulfilled, (state, action) => {
      if (action.payload.isReadReceipt && action.payload.success) {
        state.apiError = undefined;
      }

      if (action.payload.isReadReceipt && action.payload.error) {
        state.apiError = action.payload.error;
      }
    });
    builder.addCase(updateIsOpen, (state, action) => {
      if (action.payload === false) {
        state.apiError = undefined;
      }
    });
    builder.addCase(removeFromTabCollection, (state, action) => {
      if (action.payload.isDraftFolder) {
        state.message = undefined;
      }
    });
  },
});

export const { reloadMessage, clearMessageSlice } = messageSlice.actions;

export default messageSlice.reducer;
