import { getServerEndpoint } from 'app-constants';
import axios, { AxiosError, AxiosResponse } from 'axios';
import submitApiEvent from 'libs/tracking/adapters/helix/events/submitApiEvent';
import submitLoginEvent from 'libs/tracking/adapters/helix/events/submitLoginEvent';

type APIResponse = ResponseWMethod | AxiosError | AxiosResponse;
type isAxios = AxiosError | AxiosResponse;

interface ResponseWMethod extends Response {
  method: string;
}

const isAxios = (response: APIResponse): response is isAxios => (<isAxios>response).config !== undefined;

const isFailure = (response: APIResponse): string =>
  axios.isAxiosError(response) || response.status > 400 ? 'restApiFailure' : 'restApiSuccess';

const calculateElapsed = (startTime: number): number => new Date().getTime() - startTime;

const getValue = (response: APIResponse, key: string): string =>
  isAxios(response) ? response.config[key as keyof APIResponse] : response[key as keyof APIResponse];

export const trackCall = (response: APIResponse, startTime: number, traceId: string, responseSize: number): void => {
  const responseCode = axios.isAxiosError(response) ? `${response.response?.status}` : response.status;

  submitApiEvent({
    event: isFailure(response),
    page: 'app',
    apiPath: getValue(response, 'url').replace(getServerEndpoint(), ''),
    apiHost: getServerEndpoint(),
    httpVerb: getValue(response, 'method').toUpperCase(),
    responseCode: `${responseCode}`,
    responseTime: calculateElapsed(startTime),
    responseSize,
    traceId,
  });
};

interface TrackingLoginStatusPayload {
  accountNumber?: string;
  accountGuid?: string;
  authReportingId?: string;
  footprint?: string;
}

export const trackRefreshTokenLogin = (isSuccess: boolean, payload?: TrackingLoginStatusPayload): void => {
  submitLoginEvent({
    event: isSuccess ? 'loginStopSuccess' : 'loginStopFailure',
    page: 'login',
    authSource: 'WebMail',
    errorCode: isSuccess ? 200 : 401,
    ...payload,
  });
};
