import Helix from '@charter/helix/build/portals/index.min';
import { HelixEventFn, HelixPayloadInterface } from 'libs/tracking/adapters/helix/helixAdapter';

interface HelixLabelsPayloadInterface extends HelixPayloadInterface {
  contacts?: 'example';
  errorMessage?: string;
}

const submitLabelEvents: HelixEventFn<HelixLabelsPayloadInterface> = (payload) => {
  const { event } = payload;
  switch (event) {
    case 'labelKebabButton': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_labelsThreeDotMenu');
      break;
    }
    case 'newLabelCreate': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_newLabelCreate');
      break;
    }
    case 'newLabelCancel': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_newLabelCancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'newLabelButtonClick': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_addNewLabel');
      break;
    }
    case 'viewNewLabel': {
      Helix.track('WEBMAIL_modalView_newLabel');
      break;
    }
    case 'newLabelApiUnableToSave': {
      Helix.track('WEBMAIL_error_newLabelError', { appErrorMessage: 'unableToSave' });
      break;
    }
    case 'newLabelErrorDuplicate': {
      Helix.track('WEBMAIL_error_newLabelError', { appErrorMessage: 'duplicateName' });
      break;
    }
    case 'newLabelErrorValidation': {
      Helix.track('WEBMAIL_error_newLabelError', { appErrorMessage: 'validationError' });
      break;
    }
    case 'addNewLabelSuccess': {
      Helix.track('WEBMAIL_modalClose');
      Helix.track('WEBMAIL_applicationActivity_addNewLabelSuccess');
      break;
    }
    case 'deleteLabelView': {
      Helix.track('WEBMAIL_modalView_deleteLabel');
      break;
    }
    case 'deleteLabelButton': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_deleteLabel');
      break;
    }
    case 'deleteLabelSave': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_deleteLabelDelete');
      break;
    }
    case 'deleteLabelCancel': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_deleteLabelCancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'deleteLabelSuccess': {
      Helix.track('WEBMAIL_modalClose');
      Helix.track('WEBMAIL_applicationActivity_deleteLabelSuccess');
      break;
    }
    case 'deleteLabelErrorApi': {
      Helix.track('WEBMAIL_error_deleteLabelError');
      break;
    }
    case 'renameLabelButton': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_renameLabel');
      break;
    }
    case 'renameLabelSave': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_renameLabelRename');
      break;
    }
    case 'renameLabelCancel': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_renameLabelCancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'viewRenameLabel': {
      Helix.track('WEBMAIL_modalView_renameLabel');
      break;
    }
    case 'renameErrorValidation': {
      Helix.track('WEBMAIL_error_renameLabelError', { appErrorMessage: 'validationError' });
      break;
    }
    case 'renameErrorApi': {
      Helix.track('WEBMAIL_error_renameLabelError', { appErrorMessage: 'unableToSave' });
      break;
    }
    case 'renameErrorDuplicate': {
      Helix.track('WEBMAIL_error_renameLabelError', { appErrorMessage: 'duplicateName' });
      break;
    }
    case 'renameLabelSuccess': {
      Helix.track('WEBMAIL_modalClose');
      Helix.track('WEBMAIL_applicationActivity_renameLabelSuccess');
      break;
    }
    case 'modalClose': {
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'addNewContact': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_addNewContact');
      Helix.track('WEBMAIL_pageViewInit_contacts_addNewContact', {
        currPageUrl: '/user/label/all/new-contact',
      });
      break;
    }
    default: {
      console.log('Event Case For Labels Not Found', event);
      return null;
    }
  }
  return null;
};

export default submitLabelEvents;
