import { KiteButton, KiteIcon } from '@kite/react-kite';
import styles from 'components/contact-list-options/styles/ContactViewOptions.module.scss';
import KebabMenu, { KebabMenuItemsInterface } from 'components/common/kebab-menu';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { updateIsOpen, updateModalName, updateModalProps, updateToastMessage } from 'slices/app';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TrackingHook, useTracking } from 'react-tracking';
import { useCallback, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import ContactPrint from 'components/contact-print';
import { addTabCollection, addTo, updateMinimizedIndex } from 'slices/compose';
import { findDefaultOrFirstEmail } from 'utils/transformContact';
import { resetSelectedContacts } from 'slices/contact';
import { appConstants } from 'appConstants';
import BackButton from 'components/common/BackButton';
import { exportContactThunk } from 'thunks/contact/exportContactThunk';
import { useExportContactLazyQuery } from 'generated/graphql';
import { useTarget } from '../common/focus/FocusContext';
import { updateModalOpener } from 'components/common/focus/FocusContext';
import { usePageTranslation } from 'translation/hooks';
import useMobileSize from 'components/common/hooks/useMobileSize';

const ContactViewOption: React.FC = () => {
  const { t } = usePageTranslation('contactActionsCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'contacts' });
  const updateModalRef = updateModalOpener();
  const contact = useAppSelector((state) => state.contactSlice.contact);
  const isAutoInsertSignature = useAppSelector((state) => state.signatureSlice.signatureEntity?.autoInsert);
  const signature = useAppSelector((state) => state.signatureSlice.signatureEntity?.signature) || '';
  const labelCollection = useAppSelector((state) => state.labelSlice.labelCollection);
  const focusFirstField = useTarget('contactUpdate');
  const focusPrintOpener = useTarget('modal');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useMobileSize();
  const hasSelectedContacts = !!useAppSelector((state) => state.contactSlice.selectedContacts)?.length;
  const { search } = useLocation();

  const [maxItems, setMaxItems] = useState(0);

  const contactPrintRef = useRef(null);
  const { labelId, '*': contactId } = useParams<{ '*': string; labelId: string }>();

  const [getContactExportHook] = useExportContactLazyQuery();

  const handleSendMail = (): void => {
    if (!contact) {
      return;
    }

    trackEvent({ event: 'sendEmail' });

    dispatch(addTabCollection(isAutoInsertSignature ? signature : null));
    dispatch(updateMinimizedIndex(''));

    const defaultOrFirstContact = findDefaultOrFirstEmail(contact);
    if (defaultOrFirstContact) {
      dispatch(addTo({ address: defaultOrFirstContact.emailAddress, name: contact.displayName as string }));
    }

    dispatch(resetSelectedContacts());
    navigate(appConstants.INBOX_URL);
  };

  const handleDelete = (): void => {
    trackEvent({ event: 'deleteContacts' });
    dispatch(updateModalName('deleteContact'));
    dispatch(updateModalProps({ contactId, labelId, navigate }));
    dispatch(updateIsOpen(true));
  };

  const handleAssignLabel = (): void => {
    trackEvent({ event: 'assignLabelNav' });
    dispatch(updateModalName('assignLabelToContact'));
    dispatch(updateModalProps({ contactId, labelId }));
    dispatch(updateIsOpen(true));
  };

  const handlePrint = useReactToPrint({
    content: () => {
      trackEvent({ event: 'printContacts' });
      return contactPrintRef.current;
    },
    onAfterPrint: () => {
      focusPrintOpener();
    },
  });

  const handleExport = async (): Promise<boolean> => {
    trackEvent({ event: 'exportContactNavigation' });
    const result = await dispatch(
      exportContactThunk({
        getContactExportHook,
        payload: { ids: [contactId as string] },
      })
    );

    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'exportContactSuccess' });
      dispatch(updateToastMessage({ message: t('exportSuccessToast'), success: true }));
      dispatch(updateIsOpen(false));
      return true;
    }

    trackEvent({ event: 'exportContactApiError' });
    dispatch(
      updateModalProps({
        selectedContacts: [contactId as string],
      })
    );
    dispatch(updateModalName('exportContact'));
    dispatch(updateIsOpen(true));

    return false;
  };

  const handleEdit = (): void => {
    trackEvent({ event: 'editContact' });
    trackEvent({ event: 'updateContactPageViewInit' });
    focusFirstField();
    navigate(`/user/label/${labelId}/${contactId}/update`, { state: { search } });
  };

  const kebabMenuItems: KebabMenuItemsInterface[] = [
    {
      onClick: handleSendMail,
      icon: 'ki-mail',
      label: t('sendEmail'),
      className: '',
    },
    {
      onClick: handleAssignLabel,
      icon: 'ki-label-f',
      label: t('assignLabels'),
      className: '',
      isMenuItemDisabled: labelCollection.length === 0,
      opensModal: true,
    },
    {
      onClick: handleExport,
      icon: 'ki-export',
      label: t('export'),
      className: '',
    },
    {
      onClick: handlePrint,
      icon: 'ki-print',
      label: t('print'),
      className: '',
      opensModal: true,
    },
  ];

  const handleResize = (optionsContainer: HTMLDivElement): void => {
    const handleMobileOffset = (width: number): number => (isMobile ? width + 90 : width);

    if (optionsContainer) {
      const { width } = optionsContainer.getBoundingClientRect();
      switch (true) {
        case width < handleMobileOffset(460):
          setMaxItems(0);
          break;
        case width < handleMobileOffset(500):
          setMaxItems(kebabMenuItems.length - 4);
          break;
        case width < handleMobileOffset(650):
          setMaxItems(kebabMenuItems.length - 3);
          break;
        case width < handleMobileOffset(720):
          setMaxItems(kebabMenuItems.length - 2);
          break;
        default:
          setMaxItems(kebabMenuItems.length);
      }
    }
  };

  const handleResizeRef = useCallback(
    (node: HTMLDivElement) => {
      handleResize(node);
      window.addEventListener('resize', () => handleResize(node));
      return () => window.removeEventListener('resize', () => handleResize(node));
    },
    [contactId, hasSelectedContacts]
  );

  if (!contact) {
    return null;
  }

  return (
    <>
      <div style={{ display: 'none' }}>
        <ContactPrint ref={contactPrintRef} selectedContacts={[contact]} />
      </div>
      <div className={styles.section} ref={handleResizeRef}>
        <div className={styles.sectionLeft}>
          <div className={styles.mobileSection}>
            <BackButton />
            <div className={styles.selectButtonBorder} />
          </div>

          {kebabMenuItems.slice(0, maxItems).map((kebabMenuItem, idx) => (
            <KiteButton
              key={`emailoptions-button__${kebabMenuItem.label}-${idx}`}
              variant="borderless"
              size="shrinkwrap"
              onClick={(e) => {
                kebabMenuItem.onClick();
                if (kebabMenuItem.opensModal) {
                  updateModalRef(e.currentTarget);
                }
              }}
              disabled={kebabMenuItem.isMenuItemDisabled}
              aria-disabled={kebabMenuItem.isMenuItemDisabled}
            >
              <div className={styles.contactOptionsButton}>
                <KiteIcon icon={kebabMenuItem.icon || ''} />
                <span>{kebabMenuItem.label}</span>
              </div>
            </KiteButton>
          ))}

          <KiteButton variant="borderless" size="shrinkwrap" onClick={handleEdit}>
            <div className={styles.contactOptionsButton}>
              <KiteIcon icon={'ki-edit'} />
              <span>{t('edit')}</span>
            </div>
          </KiteButton>
          <div className={styles.selectButtonBorder} />
          <KiteButton className={styles.deleteButton} variant="borderless" size="shrinkwrap" onClick={handleDelete}>
            <div className={styles.contactOptionsButton}>
              <KiteIcon icon={'ki-trash'} />
              <span>{t('delete')}</span>
            </div>
          </KiteButton>
        </div>
        <div className={styles.sectionRight}>
          <div className={styles.moreOptions}>
            {maxItems < kebabMenuItems.length && (
              <label className={styles.messageOptionsButton}>
                <KebabMenu items={kebabMenuItems.slice(maxItems)} />
                <span className={styles.label}>{t('otherActions')}</span>
              </label>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactViewOption;
