import { KiteButton, KiteCard, KiteProgressIndicator } from '@kite/react-kite';
import { memo, useCallback, useEffect } from 'react';
import styles from 'components/setting-filter/styles/SettingFilter.module.scss';
import { useAppSelector } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useUpdateFiltersMutation } from 'generated/graphql';
import { useAppDispatch } from 'hooks';
import { updateIsOpen, updateModalName, updateModalProps } from 'slices/app';
import { transformExistingFilters } from 'utils/transformFilterForm';
import { updateFilterThunk } from 'thunks/filter/updateFilterThunk';
import { TrackingHook, useTracking } from 'react-tracking';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import Filter from 'components/setting-filter/Filter';
import { ApolloError } from '@apollo/client';
import ServerError from 'components/common/ServerError';
import { resetFilterApiError } from 'slices/filter';
import { usePageTranslation } from 'translation/hooks';

interface FilterListProps {
  error: ApolloError | undefined;
  getFiltersLoading: boolean;
  getFilters: () => void;
}

const SettingFilter: React.FC<FilterListProps> = ({ getFiltersLoading, getFilters, error }) => {
  const { t } = usePageTranslation('filterRulesCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'settings' });

  const isModalOpen = useAppSelector((state) => state.appSlice.isOpen);
  const filters = useAppSelector((state) => state.filterCollectionSlice.filters) || [];
  const [updateFiltersHook, { loading }] = useUpdateFiltersMutation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isModalOpen) {
      trackEvent({ event: 'filterRulesPageView' });
    }
  }, []);

  const handleEditFilter = (index: number): void => {
    trackEvent({ event: 'filterRulesEdit' });
    navigate(`/user/setting/filter/${index}/update`);
  };

  const handleDeleteFilter = (index: number): void => {
    trackEvent({ event: 'filterRulesDelete' });
    dispatch(resetFilterApiError());
    dispatch(updateModalName('deleteFilter'));
    dispatch(updateModalProps({ filterId: index }));
    dispatch(updateIsOpen(true));
  };

  const handleCreateNewFilter = (): void => {
    trackEvent({ event: 'filterRulesNewFilter' });
    navigate('/user/setting/filter/new');
  };

  const handleMoveFilterItemTo = async (from: number, to: number): Promise<boolean> => {
    if (!filters[from] || !filters[to]) {
      return false;
    }
    trackEvent({ event: 'filterRuleSorted' });

    const filtersToSend = transformExistingFilters(filters);

    const filterTo = filtersToSend[to];
    filtersToSend[to] = filtersToSend[from];
    filtersToSend[from] = filterTo;

    const result = await dispatch(
      updateFilterThunk({
        payload: {
          filters: filtersToSend,
        },
        updateFiltersHook,
      })
    ).unwrap();

    if (result.success) {
      trackEvent({ event: 'filterRulesMoveSuccess' });
      return true;
    }

    trackEvent({ event: 'filterRulesUnableToMoveError' });

    return false;
  };

  const filterRulesList = useCallback((): JSX.Element[] | null => {
    if (filters.length === 0) {
      return null;
    }

    return filters.map((filter, index) => {
      return (
        <Filter
          key={index}
          filter={filter}
          handleEditFilter={handleEditFilter}
          handleDeleteFilter={handleDeleteFilter}
          handleMoveFilterItemTo={handleMoveFilterItemTo}
          index={index}
          filtersLength={filters.length}
        />
      );
    });
  }, [filters]);

  return (
    <div className={styles.filterContainer}>
      {loading && !getFiltersLoading && (
        <KiteProgressIndicator id={'loading'} useOverlay={true} size="md" className={styles.overlayLoader} />
      )}
      <KiteCard>
        <div className={styles.title}>
          <AutoFocusHeader content={t('title')} />
        </div>
        <p className={styles.subTitle}>{t('action')}</p>
        <p className={styles.subTitle}>{t('details')}</p>
        <div className={styles.filterListContainer}>
          {getFiltersLoading ? (
            <div className={styles.spinnerContainer}>
              <KiteProgressIndicator id={'loading'} useOverlay={true} size="md" />
            </div>
          ) : error ? (
            <ul>
              <ServerError retryFunction={getFilters} />
            </ul>
          ) : !filters.length ? (
            <p className={styles.noRulesTitle}></p>
          ) : (
            <ul className={styles.rulesList}>{filterRulesList()}</ul>
          )}
        </div>

        <div className={styles.createSection}>
          <KiteButton
            legacy={true}
            variant={'secondary'}
            aria-label={'create new filter'}
            onClick={handleCreateNewFilter}
          >
            {t('createNewRule')}
          </KiteButton>
        </div>
      </KiteCard>
    </div>
  );
};

export default memo(SettingFilter);
