import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PatchSettingMutationFn, SettingEntity } from 'generated/graphql';

interface UpdateSettingInterface {
  payload: {
    field: string;
    value: boolean;
  };
  updateSettingHook: PatchSettingMutationFn;
}

interface UpdateSettingResponse {
  success?: SettingEntity;
  error?: ApolloError;
}

export const updateSettingThunk = createAsyncThunk(
  'updateSettingThunk',
  async (arg: UpdateSettingInterface): Promise<UpdateSettingResponse> => {
    try {
      const response = await arg.updateSettingHook({
        variables: {
          field: arg.payload.field,
          value: arg.payload.value ? 'yes' : 'no',
        },
      });
      return {
        success: response.data?.patchSetting,
      } as UpdateSettingResponse;
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
