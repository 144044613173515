import { appConstants } from 'appConstants';
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: 'required',
  },
});

export const filterSchema = yup.object().shape({
  name: yup.string().required(),
  clause: yup.string().required(),
  highPriority: yup.string().optional().nullable(),
  actionPriority: yup.string().optional().nullable(),
  filterAction: yup.string().required(),
  moveFolder: yup.string().when('filterAction', {
    is: 'move',
    then: yup.string().required(),
    otherwise: yup.string().optional().nullable(),
  }),
  newFolder: yup.string().when('moveFolder', {
    is: 'createNew',
    then: yup
      .string()
      .required()
      .matches(/^[ A-Za-z0-9-_,']*$/, 'invalid')
      .max(appConstants.MAX_FOLDER_NAME_LENGTH, 'folderCharacterLimit'),
    otherwise: yup.string().optional().nullable(),
  }),
  forwardList: yup.array().of(yup.string()).optional().nullable(),
  rules: yup
    .array()
    .of(
      yup.object({
        field: yup.string().required(),
        operator: yup.string().required(),
        value: yup.string().required(),
      })
    )
    .min(1),
});
