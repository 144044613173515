import styles from 'components/compose-input-field/styles/ContactSuggestion.module.scss';
import { TagComponentProps } from 'react-tag-autocomplete';
import { isValidEmail } from 'utils/composeMailFormHelper';

const ContactTag: React.FC<TagComponentProps & { tag: { email?: string } }> = ({ tag, removeButtonText, onDelete }) => {
  const isValid = isValidEmail(tag.email!);

  return (
    <button
      type="button"
      className={`react-tags__selected-tag ${!isValid ? styles.invalidTag : ''}`}
      title={removeButtonText}
      onClick={onDelete}
    >
      <span className="react-tags__selected-tag-name">{tag.name}</span>
    </button>
  );
};

export default ContactTag;
