import { useEffect, useRef } from 'react';

interface AutoFocusProps {
  content: string;
  customStyles?: string;
}

const AutoFocusHeader: React.FC<AutoFocusProps> = ({ content, customStyles }) => {
  const headerRef = useRef<HTMLHeadingElement | null>(null);

  useEffect(() => {
    if (headerRef && headerRef.current) {
      headerRef.current.focus();
    }
  }, []);

  return (
    <h2 tabIndex={-1} ref={headerRef} className={`kite-type-style--title-6 ${customStyles || ''}`}>
      {content}
    </h2>
  );
};

export default AutoFocusHeader;
