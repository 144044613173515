import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateMailboxMutationFn, MailboxEntity, MailboxForm } from 'generated/graphql';

interface CreateMailboxThunkArgs {
  payload: {
    mailbox: MailboxForm;
  };
  createMailboxHook: CreateMailboxMutationFn;
}

interface CreateMailboxThunkResponse {
  success?: MailboxEntity;
  error?: ApolloError;
}

export const createMailboxThunk = createAsyncThunk(
  'createMailboxThunk',
  async (arg: CreateMailboxThunkArgs): Promise<CreateMailboxThunkResponse> => {
    try {
      const response = await arg.createMailboxHook({
        variables: arg.payload,
      });
      return {
        success: response.data?.createMailbox,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
