import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { QuotaEntity } from 'generated/graphql';

interface MailboxQuotaResponse {
  success?: QuotaEntity;
  error?: ApolloError | undefined;
}

interface MailboxQuotaArg {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMailboxQuotaHook?: any;
}

export const mailboxQuotaThunk = createAsyncThunk(
  'mailboxQuotaThunk',
  async (arg: MailboxQuotaArg): Promise<MailboxQuotaResponse> => {
    try {
      const response = await arg.getMailboxQuotaHook();
      return {
        success: response.data.getQuota,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
