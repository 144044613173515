export const renameFolderCopy = {
  headerFolder: '',
  headerSubfolder: '',
  description: '',
  folderLabel: '',
  subfolderLabel: '',
  folderPlaceholder: '',
  subfolderPlaceholder: '',
  confirmBtnText: '',
  successToast: '',
};
