import React from 'react';
import { RouteInterface } from 'components/router-switch/mainRoutes';
import { Link } from 'react-router-dom';
import styles from 'components/header/styles/NavigationLinkGroup.module.scss';
import { TrackingHook, useTracking } from 'react-tracking';
import concatUrl from 'utils/concatUrl';
import { KiteButton, KiteLink } from '@kite/react-kite';
import { useAppDispatch } from 'hooks';
import { updateIsOpen, updateModalName } from 'slices/app';
import { getSpectrumNetBaseUrl } from 'app-constants';
import { usePageTranslation } from 'translation/hooks';

interface Props {
  delayedClose: () => void;
}

const NavigationLinkGroup: React.FC<Props> = ({ delayedClose }) => {
  const { trackEvent }: TrackingHook = useTracking({ page: 'navigation', component: 'NavigationLinkGroup' });
  const { t } = usePageTranslation('leftNavigationCopy');
  const dispatch = useAppDispatch();

  const handleClick = (_e: React.MouseEvent, route: RouteInterface): void => {
    trackEvent({ event: 'hamburgerNavLink', linkObject: { ...route } });
    delayedClose();
  };

  const handleSpanishComingSoon = (): void => {
    dispatch(updateIsOpen(true));
    dispatch(updateModalName('spanishComingSoon'));
  };

  const getAppMenu = (): JSX.Element => {
    return (
      <>
        <li>
          <Link
            key={`mobile-inbox`}
            to={'/user/mail'}
            className={styles.mobileNavLink}
            onClick={(e) => handleClick(e, { uiName: t('email'), name: 'mail' })}
          >
            {t('email')}
          </Link>
        </li>
        <li>
          <Link
            key={`mobile-contacts`}
            to={'/user/label'}
            className={styles.mobileNavLink}
            onClick={(e) => handleClick(e, { uiName: t('contacts'), name: 'contacts' })}
          >
            {t('contacts')}
          </Link>
        </li>
        <li>
          <Link
            key={`mobile-settings`}
            to={'/user/setting'}
            className={styles.mobileNavLink}
            onClick={(e) => handleClick(e, { uiName: t('settings'), name: 'settings' })}
          >
            {t('settings')}
          </Link>
        </li>
      </>
    );
  };

  const getSpectrumMenu = (): JSX.Element => {
    return (
      <>
        <li>
          <KiteLink
            key={`mobile-manage-account`}
            href={concatUrl(getSpectrumNetBaseUrl(), '/support/category/my-account')}
            className={styles.mobileNavLink}
            onClick={(e) => handleClick(e, { uiName: t('accountSupportBurger'), name: 'manageAccount' })}
            aria-label={t('accountSupportBurger')}
            target="_blank"
          >
            {t('accountSupportBurger')}
          </KiteLink>
        </li>
        <li>
          <KiteLink
            key={`mobile-get-support`}
            href={concatUrl(getSpectrumNetBaseUrl(), '/support')}
            className={styles.mobileNavLink}
            onClick={(e) => handleClick(e, { uiName: t('getSupport'), name: 'getSupport' })}
            aria-label={t('getSupport')}
            target="_blank"
          >
            {t('getSupport')}
          </KiteLink>
        </li>
        <li>
          <KiteButton
            variant={'borderless'}
            className={styles.mobileNavLink}
            onClick={handleSpanishComingSoon}
            aria-label={t('spanishAria')}
            lang="es"
            role="link"
          >
            {t('spanish')}
          </KiteButton>
        </li>
      </>
    );
  };

  return (
    <div className={styles.mobileNavLinkGroup}>
      <ul className={styles.navigationList}>{getAppMenu()}</ul>
      <ul className={styles.navigationList}>{getSpectrumMenu()}</ul>
    </div>
  );
};

export default NavigationLinkGroup;
