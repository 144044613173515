import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert, KiteTextInput } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import { useCreateLabelMutation } from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { TrackingHook, useTracking } from 'react-tracking';
import { createLabelThunk } from 'thunks/contact/createLabelThunk';
import { isValidLabelName, isDuplicate } from 'utils/labelNameValidator';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { appConstants } from 'appConstants';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, ValidationKeys, useDialogTranslation } from 'translation/hooks';

const NewLabel: React.FC = () => {
  const { t } = useDialogTranslation('newLabelCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'labels' });
  const apiError = useAppSelector((state) => state.labelSlice.apiError);
  const labelCollection = useAppSelector((state) => state.labelSlice.labelCollection);
  const [validationErrorMessage, setValidationErrorMessage] = useState<ValidationKeys | ''>('');
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const [draft, setDraft] = useState('');
  const focusOpener = useTarget('modal');
  const dispatch = useAppDispatch();
  const [createLabelHook] = useCreateLabelMutation();

  useEffect(() => {
    trackEvent({ event: 'viewNewLabel' });
  }, []);

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  const handleInputTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setDraft(e.target.value);
    validateInput(e.target.value);
  };

  const isSaved = async (): Promise<boolean> => {
    trackEvent({ event: 'newLabelCreate' });
    const result = await dispatch(
      createLabelThunk({
        payload: {
          name: draft,
        },
        createLabelHook,
      })
    );

    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'addNewLabelSuccess' });
      setDraft('');
      dispatch(updateToastMessage({ message: t('successToast'), success: true }));
      return true;
    }

    trackEvent({ event: 'newLabelApiUnableToSave' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'newLabelCancel' });
    setDraft('');
    setValidationErrorMessage('');
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const validateInput = (input: string): void => {
    if (!isValidLabelName(input)) {
      trackEvent({ event: 'renameErrorValidation' });
      setValidationErrorMessage('invalidCharacters');
      return;
    }

    if (isDuplicate(input, labelCollection)) {
      trackEvent({ event: 'renameErrorDuplicate' });
      setValidationErrorMessage('labelDuplicate');
      return;
    }

    if (input.length > appConstants.MAX_LABEL_NAME_LENGTH) {
      setValidationErrorMessage('labelCharacterLimit');
      return;
    }

    setValidationErrorMessage('');
  };

  const isChanged = (): boolean => {
    return draft.length > 0;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate: () => !validationErrorMessage,
    draft,
    isChanged,
    saveButtonText: t('confirmBtnText'),
  };

  return (
    <ModalContentWrapper>
      <h5>{t('header')}</h5>
      <p>{t('description')}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <KiteTextInput
        className={modalStyle.textInput}
        label={t('inputLabel')}
        onChange={handleInputTextChange}
        value={draft}
        errorMessage={t(validationErrorMessage)}
        ariaLive="polite"
      />
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default NewLabel;
