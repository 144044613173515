import { DateTime } from 'luxon';

enum DateFormats {
  TIME = 'hh:mm a',
  FULL_DATE = 'MM/dd/yyyy',
  MONTH_DAY = 'LLL dd',
}

const dateFormatter = (format: DateFormats, date?: string, timeZone?: string): string => {
  if (!date) {
    return '';
  }
  const ISOdate = DateTime.fromISO(date.trim());

  return ISOdate.setZone(timeZone).toFormat(format);
};

const mailCreatedFormatter = (date: string, timeZone: string): string => {
  const ISOdate = DateTime.fromISO(date.trim());

  return ISOdate.setZone(timeZone).toLocaleString(DateTime.DATETIME_FULL);
};

const mailViewDateFormatter = (date: string, timeZone: string): { time: string; date?: string } => {
  const ISOdate = DateTime.fromISO(date.trim());
  const isCurrentYear = ISOdate.year === DateTime.local().year;
  const time = dateFormatter(DateFormats.TIME, date, timeZone);

  if (ISOdate.toISODate() === DateTime.local().toISODate()) {
    return { time };
  }

  if (isCurrentYear) {
    return {
      date: dateFormatter(DateFormats.MONTH_DAY, date, timeZone),
      time: time,
    };
  }

  return {
    date: dateFormatter(DateFormats.FULL_DATE, date, timeZone),
    time: time,
  };
};

const mailListDateFormatter = (date: string, timeZone: string): string => {
  const ISOdate = DateTime.fromISO(date.trim());

  if (ISOdate.toISODate() === DateTime.local().toISODate()) {
    return dateFormatter(DateFormats.TIME, date, timeZone);
  }

  return ISOdate.setZone(timeZone).toFormat(DateFormats.FULL_DATE);
};

const contactDateFormatter = (date = ''): string => dateFormatter(DateFormats.FULL_DATE, date, 'utc');

export { mailCreatedFormatter, contactDateFormatter, mailViewDateFormatter, mailListDateFormatter };
