import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MessageRawEntity } from 'generated/graphql';

interface RawMessageResponse {
  success?: MessageRawEntity;
  error?: ApolloError | undefined;
}

interface RawMessageArg {
  payload: {
    mailboxId: string;
    messageUid: number;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRawMessageLazyQueryHook: any;
}

export const getRawMessageThunk = createAsyncThunk(
  'getRawMessage',
  async (arg: RawMessageArg): Promise<RawMessageResponse> => {
    try {
      const response = await arg.getRawMessageLazyQueryHook({
        variables: {
          mailboxId: arg.payload.mailboxId,
          messageUid: arg.payload.messageUid,
        },
      });
      return {
        success: response.data.getRawMessage,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
