import styles from 'components/canary/styles/CanaryTutorialMobile.module.scss';
import { KiteButton, KiteCard } from '@kite/react-kite';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TrackingHook, useTracking } from 'react-tracking';
import { useAppDispatch } from 'hooks';
import { updateIsCanary } from 'slices/app';
import { TutorialAsset, TutorialObject } from 'components/canary/CanaryTutorialDesktop';
import PaginationDots from 'components/common/PaginationDots';
import { appConstants } from 'appConstants';
import { usePageTranslation } from 'translation/hooks';

const CanaryTutorialMobile: React.FC = () => {
  const { t } = usePageTranslation('canaryCopy');
  const [currentTutorialPage, setCurrentTutorialPage] = useState(0);
  const { trackEvent }: TrackingHook = useTracking({ page: 'canary', component: 'CanaryLandingPage' });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const headerFocusRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    trackEvent({ event: 'canaryTutorialStart' });
  }, []);

  useEffect(() => {
    if (headerFocusRef?.current) {
      headerFocusRef.current.focus();
      trackEvent({
        event: 'canaryTutorialModal',
        tutorialStepName: tutorialAssets[currentTutorialPage].stepName,
      });
    }
  }, [currentTutorialPage]);

  const tutorialAssets: TutorialAsset[] = [
    {
      image: appConstants.ui.TUTORIAL_MOBILE_STEP_1,
      stepName: 'tutorialStep1',
      ariaInstructions: t(`mobileOneAria`),
      text: {
        header: t(`mobileOneHeader`),
        secondary: t(`mobileOneSecondary`),
      },
    },
    {
      image: appConstants.ui.TUTORIAL_MOBILE_STEP_2,
      stepName: 'tutorialStep2',
      ariaInstructions: t(`mobileTwoAria`),
      text: {
        header: t(`mobileTwoHeader`),
        secondary: t(`mobileTwoSecondary`),
      },
    },
    {
      image: appConstants.ui.TUTORIAL_MOBILE_STEP_3,
      stepName: 'tutorialStep3',
      ariaInstructions: t(`mobileThreeAria`),
      text: {
        header: t(`mobileThreeHeader`),
        secondary: t(`mobileThreeSecondary`),
      },
    },
    {
      image: appConstants.ui.TUTORIAL_MOBILE_STEP_4,
      stepName: 'tutorialStep4',
      ariaInstructions: t(`mobileFourAria`),
      text: {
        header: t(`mobileFourHeader`),
        secondary: t(`mobileFourSecondary`),
      },
    },
    {
      image: appConstants.ui.TUTORIAL_MOBILE_STEP_5,
      stepName: 'tutorialStep5',
      ariaInstructions: t(`mobileFiveAria`),
      text: {
        header: t(`mobileFiveHeader`),
        secondary: t(`mobileFiveSecondary`),
      },
    },
    {
      image: appConstants.ui.TUTORIAL_MOBILE_STEP_6,
      stepName: 'tutorialStep6',
      ariaInstructions: t(`mobileSixAria`),
      text: {
        header: t(`mobileSixHeader`),
        secondary: t(`mobileSixSecondary`),
      },
    },
  ];

  const firstPage = currentTutorialPage < 1;
  const lastPage = currentTutorialPage === tutorialAssets.length - 1;

  const handleSkip = (): void => {
    trackEvent({
      event: 'canaryTutorialSkip',
      tutorialStepName: tutorialAssets[currentTutorialPage].stepName,
    });
    dispatch(updateIsCanary(false));
    navigate('/user/mail');
    return;
  };

  const handlePrevStep = (): void => {
    trackEvent({
      event: 'canaryTutorialPrevStep',
      tutorialStepName: tutorialAssets[currentTutorialPage].stepName,
    });
    setCurrentTutorialPage(currentTutorialPage - 1);
  };

  const handleNextStep = (): void => {
    trackEvent({
      event: 'canaryTutorialNextStep',
      tutorialStepName: tutorialAssets[currentTutorialPage].stepName,
    });
    setCurrentTutorialPage(currentTutorialPage + 1);
  };

  const handleFinish = (): void => {
    trackEvent({
      event: 'canaryTutorialFinish',
    });
    dispatch(updateIsCanary(false));
    navigate('/user/mail');
    return;
  };

  const handleGoToSettings = (): void => {
    trackEvent({
      event: 'canaryTutorialFinish',
    });
    dispatch(updateIsCanary(false));
    navigate('/user/setting/signature');
  };

  const handleExit = (): void => {
    trackEvent({
      event: 'canaryTutorialXIcon',
      tutorialStepName: tutorialAssets[currentTutorialPage].stepName,
    });

    dispatch(updateIsCanary(false));
    navigate('/user/mail');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): null | void => {
    if (event.key === 'Escape') {
      handleExit();
    }
  };

  const TutorialDialog: React.FC<TutorialObject> = ({ tutorialObject }) => {
    return (
      <div className={styles.dialogContainer} role="dialog" aria-labelledby="myHeader">
        <KiteCard className={`${styles.dialogCard}`}>
          <h3 id="myHeader" tabIndex={-1} ref={headerFocusRef} className={styles.header}>
            {tutorialObject.text.header}
          </h3>
          <p className={styles.hidden} aria-label={tutorialAssets[currentTutorialPage].ariaInstructions} />
          <p className={styles.secondary}> {tutorialObject.text.secondary}</p>
          <div className={styles.actionButtonsContainer}>
            <div className={`${styles.actionButtons} ${firstPage ? styles.firstPage : ''}`}>
              {!firstPage && !lastPage && (
                <KiteButton
                  className={styles.prevButton}
                  variant={'secondary'}
                  onClick={() => {
                    firstPage ? handleSkip() : handlePrevStep();
                  }}
                >
                  {t(`previous`)}
                </KiteButton>
              )}
              {lastPage && (
                <KiteButton
                  className={styles.prevButton}
                  variant={'secondary'}
                  onClick={() => {
                    handleFinish();
                  }}
                >
                  {t(`finish`)}
                </KiteButton>
              )}
              <KiteButton
                className={styles.nextButton}
                onClick={() => (lastPage ? handleGoToSettings() : handleNextStep())}
              >
                {lastPage ? t(`settings`) : t(`next`)}
              </KiteButton>
            </div>
            <PaginationDots currPage={currentTutorialPage} totalPages={tutorialAssets.length} />
          </div>
        </KiteCard>
      </div>
    );
  };

  return (
    <div role="application" className={styles.mainPage} onKeyDown={handleKeyDown}>
      <div className={`${styles.contentWrapper} ${tutorialAssets[currentTutorialPage].contentWrapperStyle || ''}`}>
        <KiteButton variant={'borderless'} onClick={handleSkip} className={`${styles.skipButton}`}>
          {t('skip')}
        </KiteButton>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={tutorialAssets[currentTutorialPage].image} alt={'tutorial image'} />
        </div>
        <TutorialDialog tutorialObject={tutorialAssets[currentTutorialPage]} />
      </div>
    </div>
  );
};

export default CanaryTutorialMobile;
