import { KiteAlert, KiteCard, KiteSelect } from '@kite/react-kite';
import React, { useEffect, useMemo } from 'react';
import styles from 'components/setting-time-zone/styles/Timezone.module.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import SaveCancel from 'components/save-cancel';
import { updateTimeZoneThunk } from 'thunks/setting/updateTimeZoneThunk';
import { usePatchTimeZoneMutation } from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import useNavBlockingDraft from 'hooks/useNavBlockingDraft';
import { updateNavBlocking } from 'slices/app';
import { ErrorsKeys, usePageTranslation } from 'translation/hooks';

const SettingTimeZone: React.FC = () => {
  const { t } = usePageTranslation('timeZoneCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'settings' });

  const userTimeZone = useAppSelector((state) => state.settingSlice.timeZone);
  const apiError = useAppSelector((state) => state.settingSlice.apiError);
  const [draft, setDraft] = useNavBlockingDraft(userTimeZone);

  const dispatch = useAppDispatch();
  const [updateTimeZoneHook] = usePatchTimeZoneMutation();

  useMemo(() => {
    trackEvent({ event: 'timeZonePageViewInit' });
  }, []);

  useEffect(() => {
    trackEvent({ event: 'timeZonePageView' });
  }, []);

  useEffect(() => {
    if (userTimeZone) {
      setDraft(userTimeZone);
    }
  }, [userTimeZone]);

  const isTimeZoneChanged = (): boolean => {
    return draft !== userTimeZone;
  };

  const handleCancel = (): boolean => {
    trackEvent({ event: 'timeZoneCancel' });
    setDraft(userTimeZone);
    return true;
  };

  const handleSave = async (): Promise<boolean> => {
    trackEvent({ event: 'timeZoneSave' });
    const result = await dispatch(updateTimeZoneThunk({ updateTimeZoneHook, payload: { value: draft } }));

    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'timeZoneSaveSuccess' });
      dispatch(updateNavBlocking(false));
      return true;
    }

    trackEvent({ event: 'timeZoneSaveError' });
    return false;
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setDraft(e.target.value);
  };

  return (
    <div className={styles.timeZoneContainer}>
      <KiteCard className={styles.timeZoneCard}>
        <div className={styles.title}>
          <AutoFocusHeader content={t('title')} />
        </div>
        <span className={styles.description}>{t('description')}</span>
        {apiError && (
          <KiteAlert className={styles.alert} type="error" description={t(apiError as ErrorsKeys)} level="page" />
        )}
        <div className={styles.form}>
          <KiteSelect
            value={draft}
            id={'time-zone'}
            onChange={(e) => handleSelect(e)}
            name="timezone"
            label={t('title')}
          >
            <option value="US/Eastern">{t('tZUsEastern')}</option>
            <option value="America/Indiana/Indianapolis">{t('tZAmericaIndiana')}</option>
            <option value="US/Central">{t('tZUsCentral')}</option>
            <option value="US/Mountain">{t('tZUsMountain')}</option>
            <option value="America/Phoenix">{t('tZAmericaPhoenix')}</option>
            <option value="US/Pacific">{t('tZUsPacific')}</option>
            <option value="US/Alaska">{t('tZUsAlaska')}</option>
            <option value="US/Hawaii">{t('tZUsHawaii')}</option>
          </KiteSelect>
        </div>

        <SaveCancel
          draft={draft}
          isChanged={isTimeZoneChanged}
          isSaved={handleSave}
          isValidate={() => true}
          isCancelled={handleCancel}
          shouldDisableCancel={true}
        />
      </KiteCard>
    </div>
  );
};

export default SettingTimeZone;
