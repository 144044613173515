import { ApolloError, MutationFunction } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetContactReSyncAllowedQuery, ContactResyncAllowedEntity, GetFiltersQueryVariables } from 'generated/graphql';

interface ContactResponse {
  success?: ContactResyncAllowedEntity;
  error?: ApolloError | undefined;
}

interface ContactReSyncAllowedArg {
  getContactReSyncAllowedHook: MutationFunction<GetContactReSyncAllowedQuery, GetFiltersQueryVariables>;
}

export const getContactReSyncAllowedThunk = createAsyncThunk(
  'getContactReSyncAllowedThunk',
  async (arg: ContactReSyncAllowedArg): Promise<ContactResponse> => {
    try {
      const response = await arg.getContactReSyncAllowedHook();

      return {
        success: response?.data?.getContactReSyncAllowed,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
