import { getSpectrumComBaseUrl, getSpectrumNetBaseUrl, getSpectrumWatchBaseUrl } from 'app-constants/spectrum-config';
import { PageNSTranslationFileKeys } from 'translation/hooks';

interface ItemInterface {
  uiName: string | PageNSTranslationFileKeys<'linksCopy'>;
  href?: string;
  baseUrl?: string;
  label?: PageNSTranslationFileKeys<'linksCopy'>;
  stdName?: string;
  icon?: string;
  name?: string;
}

export interface LinkItemInterface extends ItemInterface {
  uiName: PageNSTranslationFileKeys<'linksCopy'>;
  label?: PageNSTranslationFileKeys<'linksCopy'>;
  href: string;
}

export interface LogoItemInterface extends ItemInterface {
  href: string;
  label?: PageNSTranslationFileKeys<'linksCopy'>;
}

export type LinkGroupType = {
  title?: PageNSTranslationFileKeys<'linksCopy'>;
  links: (LinkItemInterface | ItemInterface | LogoItemInterface)[];
  perColumn?: number;
};

type LinkGroupsType = {
  [key: string]: LinkGroupType;
};

const currentYear = new Date().getFullYear();

export const ACCOUNT_SUPPORT: LinkItemInterface = {
  uiName: 'accountSupport',
  href: '/',
  baseUrl: getSpectrumNetBaseUrl(),
  stdName: 'footer-getSupport-accountSupport',
};

export const CCPA_YOUR_PRIVACY_RIGHTS: LinkItemInterface = {
  uiName: 'ccpaYourPrivacyRights',
  href: '/policies/your-privacy-rights',
  baseUrl: getSpectrumComBaseUrl(),
  stdName: 'footer-legal-privacyRights',
};

export const FOOTER_LOGO: LogoItemInterface = {
  href: getSpectrumNetBaseUrl(),
  uiName: 'icon-logo',
  stdName: 'footer-logo',
};

export const CCPA_CONSUMER_PRIVACY_RIGHTS: LinkItemInterface = {
  uiName: 'ccpaConsumerPrivacyRights',
  href: '/policies/california',
  baseUrl: getSpectrumComBaseUrl(),
  stdName: 'footer-legal-californiaPrivacyRights',
};

export const CCPA_DO_NOT_SELL_OR_SHARE: LinkItemInterface = {
  uiName: 'ccpaDoNotSell',
  href: '/policies/your-privacy-rights-opt-out',
  baseUrl: getSpectrumComBaseUrl(),
  stdName: 'footer-legal-californiaResidentDontSellMyInfo',
};

export const CCPA_LIMIT_USE: LinkItemInterface = {
  uiName: 'ccpaLimitUse',
  href: '/policies/your-privacy-rights-opt-out',
  baseUrl: getSpectrumComBaseUrl(),
  stdName: 'footer-legal-californiaResidentDontSellMyInfo',
};

export const CONTACT: LinkItemInterface = {
  uiName: 'contact',
  href: '/contact-us',
  baseUrl: getSpectrumNetBaseUrl(),
  stdName: 'footer-contact-contactUs',
};

export const DVR_WATCH_TV: LinkItemInterface = {
  uiName: 'dvrWatchTv',
  href: '/rdvr/my-recordings?sessionOverride=true',
  baseUrl: getSpectrumWatchBaseUrl(),
  stdName: 'footer-watchTV-dvr',
};

export const FACEBOOK: LogoItemInterface = {
  label: 'facebookLabel',
  icon: 'facebook',
  uiName: 'icon-facebook',
  href: 'https://www.faceboook.com',
  stdName: 'footer-social-facebook',
};

export const GUIDE_WATCH_TV: LinkItemInterface = {
  uiName: 'guideWatchTv',
  href: '/guide?sessionOverride=true',
  baseUrl: getSpectrumWatchBaseUrl(),
  stdName: 'footer-watchTV-guide',
};

export const HOME_SUPPORT: LinkItemInterface = {
  uiName: 'homeSupport',
  href: '/support',
  baseUrl: getSpectrumNetBaseUrl(),
  stdName: 'footer-getSupport-supportHome',
};

export const INSTAGRAM: LogoItemInterface = {
  label: 'instagramLabel',
  icon: 'instagram',
  uiName: 'icon-instagram',
  href: 'https://www.instagram.com/getspectrum',
  stdName: 'footer-social-instagram',
};

export const INTERNET_SUPPORT: LinkItemInterface = {
  uiName: 'internetSupport',
  href: '/support/category/internet',
  baseUrl: getSpectrumNetBaseUrl(),
  stdName: 'footer-getSupport-internet',
};

export const LEGAL_POLICIES: LinkItemInterface = {
  uiName: 'legalPolicies',
  href: '/policies/terms-of-service',
  baseUrl: getSpectrumComBaseUrl(),
  stdName: 'footer-legal-policies',
};

export const LIBRARY_WATCH_TV: LinkItemInterface = {
  uiName: 'libraryWatchTv',
  href: '/watchlater?sessionOverride=true',
  baseUrl: getSpectrumWatchBaseUrl(),
  stdName: 'footer-watchTV-myLibrary',
};

export const LIVE_WATCH_TV: LinkItemInterface = {
  uiName: 'liveWatchTv',
  href: '/livetv?sessionOverride=true',
  baseUrl: getSpectrumWatchBaseUrl(),
  stdName: 'footer-watchTV-liveTV',
};

export const LOCATION: LinkItemInterface = {
  uiName: 'location',
  href: '/support/locations',
  baseUrl: getSpectrumNetBaseUrl(),
  stdName: 'footer-contact-findStore',
};

export const MOBILE_SUPPORT: LinkItemInterface = {
  uiName: 'mobileSupport',
  href: 'support/category/mobile/',
  baseUrl: getSpectrumNetBaseUrl(),
  stdName: 'footer-manageAccount-mobile',
};

export const ONDEMAND_WATCH_TV: LinkItemInterface = {
  uiName: 'ondemandWatchTv',
  href: '/ondemand/featured?sessionOverride=true',
  baseUrl: getSpectrumWatchBaseUrl(),
  stdName: 'footer-watchTV-onDemand',
};

export const TV_SUPPORT: LinkItemInterface = {
  uiName: 'tvSupport',
  href: '/support/category/tv',
  baseUrl: getSpectrumNetBaseUrl(),
  stdName: 'footer-getSupport-tv',
};

export const TWITTER: LogoItemInterface = {
  label: 'twitterLabel',
  icon: 'twitter',
  uiName: 'icon-twitter',
  href: 'https://twitter.com/getspectrum',
  stdName: 'footer-social-twitter',
};

export const VOICE_SUPPORT: LinkItemInterface = {
  uiName: 'voiceSupport',
  href: '/support/category/voice',
  baseUrl: getSpectrumNetBaseUrl(),
  stdName: 'footer-getSupport-voice',
};

export const WEATHER: LinkItemInterface = {
  uiName: 'weather',
  href: '/support/general/charter-storm-center',
  baseUrl: getSpectrumNetBaseUrl(),
  stdName: 'footer-contact-weatherOutage',
};

export const YOUTUBE: LogoItemInterface = {
  label: 'youtubeLabel',
  icon: 'youtube',
  uiName: 'icon-youtube',
  href: 'https://www.youtube.com/c/getspectrum',
  stdName: 'footer-social-youtube',
};

export const SUPPORT_LINK: LinkItemInterface = {
  uiName: 'supportLink',
  href: '/support/',
  baseUrl: getSpectrumNetBaseUrl(),
  stdName: 'footer-getSupport-supportHome',
};

export const ACCOUNT_SUPPORT_BURGER: LinkItemInterface = {
  uiName: 'accountSupportBurger',
  icon: 'ki-person',
  href: '/account-summary',
  baseUrl: getSpectrumNetBaseUrl(),
  name: 'manageAccount',
};
export const ACCOUNT: LinkItemInterface = {
  ...ACCOUNT_SUPPORT_BURGER,
  uiName: 'account',
  label: 'accountAria',
};
export const GET_SUPPORT: LinkItemInterface = {
  uiName: 'getSupport',
  href: '/support',
  icon: 'ki-support',
  name: 'getSupport',
  baseUrl: getSpectrumNetBaseUrl(),
};
export const SUPPORT: LinkItemInterface = {
  ...GET_SUPPORT,
  uiName: 'support',
  label: 'supportAria',
};
export const WATCH_TV_BURGER: LinkItemInterface = {
  uiName: 'watchTv',
  icon: 'ki-tv',
  name: 'watchTV',
  href: '/livetv?sessionOverride=true',
  baseUrl: getSpectrumWatchBaseUrl(),
};

export const COPY_RIGHT: ItemInterface = {
  uiName: `© ${currentYear} Charter Communications`,
};

const footerColumns: LinkGroupsType = {
  SOCIAL_MEDIA: {
    links: [LOCATION, WEATHER, CONTACT],
  },
  SOCIAL_MEDIA_ICONS: {
    links: [TWITTER, FACEBOOK, INSTAGRAM, YOUTUBE],
  },
  SUPPORT: {
    title: 'getSupport',
    links: [ACCOUNT_SUPPORT, INTERNET_SUPPORT, TV_SUPPORT, VOICE_SUPPORT, MOBILE_SUPPORT],
  },
  WATCH_TV: {
    title: 'watchTv',
    links: [LIVE_WATCH_TV, GUIDE_WATCH_TV, LIBRARY_WATCH_TV, ONDEMAND_WATCH_TV, DVR_WATCH_TV],
  },
  LEGAL: {
    links: [
      COPY_RIGHT,
      CCPA_YOUR_PRIVACY_RIGHTS,
      CCPA_CONSUMER_PRIVACY_RIGHTS,
      CCPA_DO_NOT_SELL_OR_SHARE,
      CCPA_LIMIT_USE,
      LEGAL_POLICIES,
    ],
  },
};

export { footerColumns };
