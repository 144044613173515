import { ApolloError } from '@apollo/client';
import { createSlice } from '@reduxjs/toolkit';
import { QuotaEntity } from 'generated/graphql';
import { mailboxQuotaThunk } from 'thunks/mailbox-collection/mailboxQuotaThunk';

interface MailboxQuotaState {
  quota?: QuotaEntity;
  limit?: string;
  usage?: string;
  percentageUsed?: number;
  apiError?: Error | null;
}

const initialState: MailboxQuotaState = {
  limit: '0',
  usage: '0',
  percentageUsed: 0,
};

export const quotaSlice = createSlice({
  name: 'mailboxQuota',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(mailboxQuotaThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.quota = action.payload.success;
        state.percentageUsed = parseInt(action.payload.success.usage) / parseInt(action.payload.success.limit);
        state.usage = (parseInt(action.payload.success.usage) / 1000000).toFixed(2);
        state.limit = (parseInt(action.payload.success.limit) / 1000000).toFixed(2);
        return;
      }
      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });
  },
});

export default quotaSlice.reducer;
