import {
  contactViewCopy,
  contactFormCopy,
  contactActionsCopy,
  contactNavigationCopy,
} from 'translation/en_US/pages/contacts';
import {
  emailViewCopy,
  composeCopy,
  emailActionsCopy,
  emailNavigationCopy,
  printCopy,
} from 'translation/en_US/pages/email';
import {
  accountInfoCopy,
  autoReplyCopy,
  blockedSendersCopy,
  emailFormatCopy,
  filterRulesCopy,
  filterRuleFormCopy,
  forwardingCopy,
  replyToCopy,
  safeSendersCopy,
  settingsTabsCopy,
  signatureCopy,
  spamManagementCopy,
  timeZoneCopy,
} from 'translation/en_US/pages/settings';
import { default as topNavigationCopy } from 'translation/en_US/pages/top-navigation.json';
import { default as leftNavigationCopy } from 'translation/en_US/pages/left-navigation.json';
import { default as linksCopy } from 'translation/en_US/pages/links.json';
import { default as canaryCopy } from 'translation/en_US/pages/canary.json';

const pages = {
  canaryCopy,
  linksCopy,
  topNavigationCopy,
  leftNavigationCopy,
  accountInfoCopy,
  autoReplyCopy,
  blockedSendersCopy,
  emailFormatCopy,
  filterRulesCopy,
  filterRuleFormCopy,
  forwardingCopy,
  replyToCopy,
  safeSendersCopy,
  settingsTabsCopy,
  signatureCopy,
  spamManagementCopy,
  timeZoneCopy,
  contactViewCopy,
  contactFormCopy,
  contactActionsCopy,
  contactNavigationCopy,
  emailViewCopy,
  composeCopy,
  emailActionsCopy,
  emailNavigationCopy,
  printCopy,
};

export default pages;
