import React from 'react';
import FilterSection from 'components/setting-filter-form/sections/FilterSection';
import { KiteTextInput } from '@kite/react-kite';
import { FilterFormInterface, FilterSectionProps } from 'components/setting-filter-form/initialState';
import styles from 'components/setting-filter-form/styles/SettingFilterForm.module.scss';

import validateFilterField, { getFilterValidationErrorMessage } from 'utils/validateFilterField';
import { KiteInputElems } from 'libs/kite/types';
import { usePageTranslation } from 'translation/hooks';
import { nameTrans } from 'components/setting-filter-form/sections/translationKeys';

export const RuleName: React.FC<FilterSectionProps<Pick<FilterFormInterface, 'name'>>> = ({
  updateFormValues,
  values,
  errors,
  setErrors,
}) => {
  const { t } = usePageTranslation('filterRuleFormCopy');

  const ruleNameErrorMessage = getFilterValidationErrorMessage(errors, 'name', nameTrans);

  const handleRuleNameChange = (e: React.ChangeEvent<KiteInputElems>): void => {
    updateFormValues(e.target.value, 'name');
    validateFilterField('name', { name: e.target.value }, errors, setErrors);
  };

  return (
    <FilterSection>
      <div className={styles.fieldContainer}>
        <KiteTextInput
          className={styles.halfField}
          placeholder={t('ruleNamePlaceholder')}
          label={t('ruleNameLabel')}
          onChange={(e) => handleRuleNameChange(e)}
          value={values.name || ''}
          errorMessage={ruleNameErrorMessage ? t(ruleNameErrorMessage) : ''}
          aria-label={`rule name, edit, type in text.`}
          ariaLive="polite"
        />
      </div>
    </FilterSection>
  );
};
