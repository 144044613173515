import { getSpectrumNetBaseUrl } from 'app-constants';
import { removeSessionTokens } from 'utils/accessTokenLocalStorage';
import { logoutLocalStorage } from 'utils/loginLocalStorage';

const logoutUtil = (): void => {
  removeSessionTokens();
  logoutLocalStorage();

  return window.location.replace(`${getSpectrumNetBaseUrl()}/logout`);
};

export const redirectToPortal = (): void => {
  window.location.replace(`${getSpectrumNetBaseUrl()}/?targetUrl=webmail3`);
};

export default logoutUtil;
