import {
  Sort,
  useGetMailBoxStatusLazyQuery,
  useGetMessageCollectionLazyQuery,
  usePatchEmailPerPageMutation,
} from 'generated/graphql';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getMessageCollectionThunk } from 'thunks/message-collection/messageCollectionThunk';
import styles from 'components/list-messages/styles/ListMessagesStyles.module.scss';
import containerStyles from 'containers/styles/ListViewContainer.module.scss';
import { KiteProgressIndicator } from '@kite/react-kite';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TrackingHook, useTracking } from 'react-tracking';
import { appConstants, GlobalFoldersInterface } from 'appConstants';
import Pagination from 'components/pagination';
import useURLQueryBuilder from 'components/common/hooks/useURLQueryBuilder';
import ServerError from 'components/common/ServerError';
import useAbortExistingRequest from 'hooks/useAbortExistingRequest';
import {
  isDraftFolder,
  isGlobalFolder,
  isInboxFolder,
  isParentFolder,
  isSentMailFolder,
  isSpamFolder,
} from 'utils/folderNameValidator';
import { updateEmailPerPageThunk } from 'thunks/setting/updateEmailPerPageThunk';
import { updateSelectAllMessages, updateSelectMultipleMessages } from 'slices/message-collection';
import ListMessagesItem from 'components/list-messages/ListMessagesItem';
import { Scrollbar } from 'components/common/scrollbar';
import { mailboxStatusThunk } from 'thunks/mailbox-collection/mailboxStatusThunk';
import { usePageTranslation } from 'translation/hooks';
import Footer from 'components/footer';

const ListMessages: React.FC = () => {
  const { t } = usePageTranslation('emailViewCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const emailPerPage = useAppSelector((state) => state.settingSlice.emailPerPage);
  const selectedMessages = useAppSelector((state) => state.messageCollectionSlice.selectedMessages) || [];
  const currentSearch = useAppSelector((state) => state.messageCollectionSlice.messageCollectionQuery.search);
  const { reloadMessageCollection, messageCollection, messageCollectionLoading } = useAppSelector(
    (state) => state.messageCollectionSlice
  );
  const getMailLoading = useAppSelector((state) => state.messageCollectionSlice.getMailLoading);
  const spamFolderId = useAppSelector((state) => state.mailboxCollectionSlice.spamFolderId);
  const messageCollectionFetchError = useAppSelector(
    (state) => state.messageCollectionSlice.messageCollectionFetchError
  );
  const [lastChecked, setLastChecked] = useState<number>(0);

  const dispatch = useAppDispatch();
  const [getMessageCollectionHook] = useGetMessageCollectionLazyQuery();
  const [getMailboxStatusHook] = useGetMailBoxStatusLazyQuery();
  const [updateEmailPerPageHook] = usePatchEmailPerPageMutation();
  const [_searchQueryParam, setSearchQueryParam] = useURLQueryBuilder();
  const abortExistingRequest = useAbortExistingRequest();
  const { folder, '*': currentMailId } = useParams<{ folder: string; '*': string }>();
  const { search: searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const queryParamLimit = parseInt(query.get('limit') || '') || emailPerPage;
  const limit = queryParamLimit || emailPerPage;
  const currentPage = parseInt(query.get('page') || '') || 1;
  const search = query.get('search');
  const sort = query.get('sort') as Sort;
  const isThreePaneEnabled = !!currentMailId;

  if (!folder) {
    return <Navigate to={'/server-error?component=ListMessages'} />;
  }

  let folderName = atob(folder as string)
    .split('/')
    .pop();

  appConstants.GLOBAL_FOLDERS.forEach((globalFolderId) => {
    if (globalFolderId === folder && folderName) {
      folderName = appConstants.GLOBAL_FOLDER_NAMES[folderName as keyof GlobalFoldersInterface];
    }
  });

  const reloadMessages = (): void => {
    const mailboxId = decodeURIComponent(folder);
    abortExistingRequest(
      dispatch(
        getMessageCollectionThunk({
          payload: {
            mailboxId,
            query: {
              limit,
              page: currentPage,
              search: search ?? undefined,
              sort: sort ?? Sort.ArrivalDesc,
            },
          },
          getMessageCollectionHook,
        })
      )
    );

    if (isGlobalFolder(mailboxId, spamFolderId) && !isSentMailFolder(mailboxId)) {
      dispatch(mailboxStatusThunk({ getMailboxStatusHook, payload: { mailboxId: mailboxId } }));
    }
  };

  useEffect(() => {
    if (isSpamFolder(folder)) {
      trackEvent({ event: 'pageViewCompleteSpam' });
      return;
    }

    if (isDraftFolder(folder)) {
      trackEvent({ event: 'pageViewCompleteDrafts' });
      return;
    }

    if (isSentMailFolder(folder)) {
      trackEvent({ event: 'pageViewCompleteSentMail' });
      return;
    }

    if (isInboxFolder(folder)) {
      trackEvent({ event: 'pageViewCompleteInbox' });
      return;
    }

    isParentFolder(folder)
      ? trackEvent({ event: 'pageViewCompleteUserFolder' })
      : trackEvent({ event: 'pageViewCompleteUserSubFolder' });
  }, [folder]);

  useEffect(() => {
    if (search?.length) {
      messageCollection[folder]?.data.length
        ? trackEvent({
            event: 'searchResultReturned',
            payload: { searchResultCount: messageCollection?.length, keyword: search },
          })
        : trackEvent({
            event: 'searchNoResults',
            payload: { keyword: search },
          });
    }
  }, [messageCollection[folder]]);

  useEffect(() => {
    if (reloadMessageCollection) {
      reloadMessages();
    }
  }, [reloadMessageCollection]);

  useEffect(() => {
    dispatch(updateSelectAllMessages({ clearSelected: true, currentFolder: folder }));
    if (
      messageCollection[folder]?.data.length &&
      (messageCollection[folder]?.currentPage !== 1 || messageCollection[folder]?.limit !== emailPerPage)
    ) {
      reloadMessages();
      return;
    }
    if (emailPerPage && !messageCollection[folder]) {
      reloadMessages();
    }
  }, [folder]);

  useEffect(() => {
    if (search && messageCollection[folder]?.data && emailPerPage && search !== currentSearch) {
      reloadMessages();
    }
  }, [search]);

  useEffect(() => {
    if (messageCollection[folder]?.data && currentPage !== messageCollection[folder]?.currentPage) {
      reloadMessages();
      return;
    }

    if (emailPerPage !== queryParamLimit) {
      reloadMessages();
    }
  }, [queryParamLimit, currentPage, sort]);

  useEffect(() => {
    if (isDraftFolder(folder) && messageCollection[folder]?.data && messageCollection[folder]?.data.length === 0) {
      trackEvent({ event: 'draftsEmpty' });
    }
  }, [messageCollection[folder]?.data]);

  const handleMultiSelect = (e: React.MouseEvent<any>, index: number): void => {
    if (e.shiftKey) {
      const newMessageSelection = messageCollection[folder].data
        .slice(Math.min(index, lastChecked) + 1, Math.max(index, lastChecked))
        .map((message) => message.uid.toString());
      dispatch(updateSelectMultipleMessages(newMessageSelection));
      setLastChecked(index);
      return;
    }

    setLastChecked(index);
    return;
  };

  const isItemChecked = (item: string): boolean => (selectedMessages && selectedMessages?.includes(item)) ?? false;
  const isMultipleSelected = selectedMessages?.length > 0;

  const onItemsPerPageChange = (newItemsPerPage: number): void => {
    if (newItemsPerPage !== limit) {
      setSearchQueryParam('page', String(1));
      setSearchQueryParam('limit', String(newItemsPerPage));

      dispatch(
        updateEmailPerPageThunk({
          updateEmailPerPageHook,
          payload: { value: String(newItemsPerPage) },
        })
      );
    }
  };

  const onPageSelect = (pageNumber: number): void => {
    if (pageNumber !== currentPage) {
      setSearchQueryParam('page', String(pageNumber));
    }
  };

  const onNextPage = (): void => {
    setSearchQueryParam('page', String(currentPage + 1));
  };

  const onPrevPage = (): void => {
    setSearchQueryParam('page', String(currentPage - 1));
  };

  const messageItems = (): JSX.Element[] | JSX.Element => {
    if (messageCollectionFetchError?.message) {
      return (
        <div className={styles.errorContainer}>
          <ServerError retryFunction={reloadMessages} />
        </div>
      );
    }

    if ((folder && messageCollection[folder]?.data.length === 0) || !messageCollection[folder]) {
      return (
        <div className={styles.emptyFolderListPlaceHolder}>
          <img
            className={styles.emptyFolderListPlaceHolderIcon}
            src={appConstants.EMAIL_NOT_FOUND_IMG}
            alt={'empty mailbox'}
          />
          <h2 className="kite-type-style--title-6">{t('emptyMailbox')}</h2>
        </div>
      );
    }

    return messageCollection[folder]?.data.map((message, idx) => {
      return (
        <ListMessagesItem
          key={idx}
          idx={idx}
          message={message}
          currentFolder={folder}
          isMultipleSelected={isMultipleSelected}
          isSelected={isItemChecked(message.uid.toString())}
          search={search}
          handleMultiSelect={handleMultiSelect}
        />
      );
    });
  };

  return (
    <>
      <div className={containerStyles.headerContainer}>
        {!isThreePaneEnabled && !messageCollectionLoading && !getMailLoading && !messageCollectionFetchError && (
          <div className={containerStyles.columnLabels}>
            <div className={containerStyles.fromToColumn}>
              <div className={containerStyles.label}>
                {isSentMailFolder(folder) || isDraftFolder(folder) ? t('to') : t('from')}
              </div>
            </div>
            <div className={containerStyles.subjectAndDateColumn}>
              <div className={`${containerStyles.label} ${containerStyles.subject}`}>{t('subject')}</div>
              <div className={`${containerStyles.label} ${containerStyles.date}`}>{t('date')}</div>
            </div>
          </div>
        )}
      </div>
      <div className={containerStyles.listViewCardContainer}>
        <Scrollbar customClass={styles.messageList} forceScroll={false}>
          <div
            className={`${styles.simpleBar} ${
              isThreePaneEnabled || messageCollectionLoading || messageCollectionFetchError?.message
                ? styles.threePaneHeight
                : ''
            }`}
          >
            {messageCollectionLoading && !getMailLoading ? (
              <div className={`${styles.spinnerContainer} ${styles.threePaneHeight}`}>
                <KiteProgressIndicator className={styles.loader} id={'loading'} useOverlay={false} size="md" />
              </div>
            ) : (
              <div className={styles.listContainer}>
                <ul
                  className={`${
                    messageCollectionFetchError?.message ||
                    messageCollection[folder]?.data.length === 0 ||
                    !messageCollection[folder]
                      ? styles.fullWidth
                      : ''
                  }`}
                >
                  {messageItems()}
                </ul>
              </div>
            )}
            {!messageCollectionFetchError?.message &&
            !messageCollectionLoading &&
            messageCollection[folder]?.data.length !== 0 ? (
              <div className={styles.paginationContainer}>
                <Pagination
                  variant={'default'}
                  currentPage={currentPage}
                  itemsPerPage={limit}
                  totalItems={messageCollection[folder]?.total || 0}
                  onPageSelect={onPageSelect}
                  onItemsPerPageChange={onItemsPerPageChange}
                  onNextPage={onNextPage}
                  onPrevPage={onPrevPage}
                />
              </div>
            ) : null}
            {!isThreePaneEnabled && <Footer />}
          </div>
        </Scrollbar>
      </div>
    </>
  );
};

export default ListMessages;
