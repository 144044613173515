import styles from 'components/inbox-compose-modal/styles/AUPCode.module.scss';
import { KiteButton, KiteIcon } from '@kite/react-kite';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useAppDispatch, useAppSelector } from 'hooks';
import SaveCancel from 'components/save-cancel';
import { SentMailThunkResponse } from 'thunks/compose/sentMailThunk';
import { useTarget } from 'components/common/focus/FocusContext';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import { useDialogTranslation } from 'translation/hooks';
import { updateIsSendPending, updateMaximizeCompose } from 'slices/compose';
import { updateTotalCountForMailbox } from 'slices/mailbox-collection';
import { isDraftFolder, isSentMailFolder } from 'utils/folderNameValidator';
import { useNavigate } from 'react-router-dom';
import { reloadMessageCollection, removeMessageFromCollection } from 'slices/message-collection';
import { appConstants } from 'appConstants';
import { useEffect } from 'react';
import { TrackingHook, useTracking } from 'react-tracking';
import { Scrollbar } from 'components/common/scrollbar';

interface AUCodeInterface {
  sendMail: () => Promise<SentMailThunkResponse>;
  folder: string | undefined;
  uid: number | undefined;
}

const AUPCode: React.FC<AUCodeInterface> = ({ sendMail, uid, folder }) => {
  const { t } = useDialogTranslation('aupCodeCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'compose' });
  const activeTabIndex = useAppSelector((state) => state.composeSlice.activeTabIndex);
  const composeCollection = useAppSelector((state) => state.composeSlice.composeCollection);
  const smtpRejectedErrors = useAppSelector((state) => state.composeSlice.smtpRejectedErrors);
  const smtpResponseCode = useAppSelector((state) => state.composeSlice.smtpResponseCode);
  const smtpResponseMessage = useAppSelector((state) => state.composeSlice.smptResponseMessage);
  const navigate = useNavigate();
  const focusOpener = useTarget('modal');
  const dispatch = useAppDispatch();

  const errorResponseList: string[] = [];
  const is500error = smtpResponseCode && smtpResponseCode >= 500;

  if (smtpRejectedErrors?.length) {
    smtpRejectedErrors.forEach((error) => {
      errorResponseList.push(error.response);
    });
  }

  useEffect(() => {
    if (smtpResponseCode) {
      trackEvent({ event: 'aupModalView', errorCode: smtpResponseCode, errorType: smtpResponseMessage });
    }
  }, []);

  const isValidate = (): boolean => {
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: is500error ? 'aupOk' : 'aupCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const handleTryAgain = async (): Promise<boolean> => {
    trackEvent({ event: 'aupTryAgain' });
    const result = await sendMail();

    dispatch(updateIsSendPending({ isPending: false, index: activeTabIndex }));

    if (result.success) {
      if (composeCollection[activeTabIndex].isOpenedFromDraft) {
        dispatch(updateTotalCountForMailbox({ mailboxId: appConstants.DRAFT_FOLDER_ID, updateCount: -1 }));
      }
      if (folder && isDraftFolder(folder) && uid) {
        navigate(`/user/mail/${encodeURIComponent(folder)}`);
        dispatch(removeMessageFromCollection({ id: uid, currentFolder: folder }));
      }
      if (folder && isSentMailFolder(folder)) {
        dispatch(reloadMessageCollection(true));
      }

      dispatch(updateMaximizeCompose(false));
      dispatch(updateToastMessage({ message: t('messageSent'), success: true }));
      dispatch(updateIsOpen(false));
      return true;
    }

    trackEvent({ event: 'aupModalView', errorCode: smtpResponseCode, errorType: smtpResponseMessage });
    return false;
  };

  const getErrorContent = (): JSX.Element => {
    const errorMessage = errorResponseList.length ? errorResponseList.join(', ') : smtpResponseMessage;

    if (is500error) {
      return (
        <>
          <Scrollbar forceScroll={false} customClass={styles.errorMessageScroll}>
            <p className={styles.errorMessage}>{t('description500', { errorMessage })}</p>
          </Scrollbar>
          <KiteButton className={styles.ok} onClick={isCancelled}>
            {t('ok')}
          </KiteButton>
        </>
      );
    }

    return (
      <>
        <Scrollbar forceScroll={false} customClass={styles.errorMessageScroll}>
          <p>{t('description400', { errorMessage })}</p>
        </Scrollbar>
        <SaveCancel
          isSaved={handleTryAgain}
          isValidate={isValidate}
          isChanged={isChanged}
          isCancelled={isCancelled}
          draft={''}
          saveButtonText={t('tryAgain')}
        />
      </>
    );
  };

  return (
    <ModalContentWrapper>
      <div className={styles.headerContainer}>
        <KiteIcon className={styles.errorIcon} icon={'ki-caution-circle-f'} size="24px" fill="#d6312b" />
        <AutoFocusHeader content={t('header')} />
      </div>
      {getErrorContent()}
    </ModalContentWrapper>
  );
};

export default AUPCode;
