import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

interface MarkMessagesAsReadResponse {
  success?: boolean;
  error?: ApolloError | undefined;
  messageUids?: number[];
  mailboxId?: string;
}

interface MarkMessagesArg {
  payload: {
    mailboxId: string;
    messageUids: number[];
  };
  markMessagesReadHook: any;
}

export const markMessagesReadThunk = createAsyncThunk(
  'markMessagesRead',
  async (arg: MarkMessagesArg): Promise<MarkMessagesAsReadResponse> => {
    try {
      const response = await arg.markMessagesReadHook({
        variables: {
          mailboxId: arg.payload.mailboxId,
          messageUids: arg.payload.messageUids,
        },
      });
      return {
        success: response?.data?.markMessagesSeen.success || false,
        messageUids: arg.payload.messageUids,
        mailboxId: arg.payload.mailboxId,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
