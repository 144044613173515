import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import { TrackingHook, useTracking } from 'react-tracking';
import { removeFromTabCollection } from 'slices/compose';
import { useNavigate } from 'react-router-dom';
import { appConstants } from 'appConstants';
import { isDraftFolder } from 'utils/folderNameValidator';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { useDialogTranslation } from 'translation/hooks';

interface DiscardDraftInterface {
  folder: string;
}

const DiscardDraft: React.FC<DiscardDraftInterface> = ({ folder }) => {
  const { t } = useDialogTranslation('discardDraftCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'compose' });
  const focusOpener = useTarget('modal');
  const activeTabIndex = useAppSelector((state) => state.composeSlice.activeTabIndex);
  const activeMinimizeIndex = useAppSelector((state) => state.composeSlice.activeMinimizedIndex);
  const composeCollection = useAppSelector((state) => state.composeSlice.composeCollection);

  const composeCollectionLength = Object.keys(composeCollection).length;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isSaved = async (): Promise<boolean> => {
    trackEvent({ event: 'discardDraftDiscard' });
    if (composeCollectionLength) {
      if (activeTabIndex || activeMinimizeIndex) {
        dispatch(removeFromTabCollection({ activeTabIndex, isDraftFolder: isDraftFolder(folder) }));
      }
    }

    if (isDraftFolder(folder)) {
      navigate(`/user/mail/${encodeURIComponent(appConstants.DRAFT_FOLDER_ID)}`);
    }

    dispatch(updateIsOpen(false));
    return true;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'discardDraftCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t('confirmBtnText'),
  };

  return (
    <ModalContentWrapper>
      <h5>{t('header')}</h5>
      <p>{t('description')}</p>
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default DiscardDraft;
