import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from 'components/label-list/styles/LabelList.module.scss';
import { KiteButton, KiteIcon, KiteMeter } from '@kite/react-kite';
import { useGetLabelCollectionLazyQuery } from 'generated/graphql';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useEffect } from 'react';
import { labelCollectionThunk } from 'thunks/contact/labelCollectionThunk';
import { updateCurrentLabelSelected } from 'slices/contact';
import { appConstants } from 'appConstants';
import { updateIsOpen, updateModalName, updateModalProps } from 'slices/app';
import { TrackingHook, useTracking } from 'react-tracking';
import KebabMenu, { KebabMenuItemsInterface } from 'components/common/kebab-menu';
import { createTargetRef } from 'components/common/focus/FocusContext';
import { Scrollbar } from 'components/common/scrollbar';
import { usePageTranslation } from 'translation/hooks';

interface LabelListPropsInterface {
  isDrawer?: boolean;
  handleDrawerClose?: () => void;
}

const LabelList: React.FC<LabelListPropsInterface> = ({ isDrawer, handleDrawerClose }) => {
  const { t } = usePageTranslation('contactNavigationCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'labels' });
  const labelCollection = useAppSelector((state) => state.labelSlice.labelCollection);
  const totalContactCount = useAppSelector((state) => state.contactSlice.totalContactCount);
  const createButtonRef = createTargetRef<HTMLButtonElement>('createContactBtn');
  const [getLabelCollectionHook] = useGetLabelCollectionLazyQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const params = useParams<{ labelId: string; '*': string }>();

  let labelId = params.labelId;
  const contactId = params['*'];

  if (isDrawer) {
    labelId = useLocation().pathname.split('/').pop();
  }

  if (!labelId) {
    return <Navigate to={'/server-error?component=LabelList'} />;
  }

  useEffect(() => {
    dispatch(labelCollectionThunk({ getLabelCollectionHook }));
  }, []);

  const contactCountPercentage = totalContactCount / appConstants.CONTACT_MAX_COUNT;

  const onKebabToggle = (expanded: boolean): void => {
    if (expanded) {
      trackEvent({ event: 'labelKebabButton' });
    }
  };

  const handleLabelClick = (labelName?: string): void => {
    handleDrawerClose && handleDrawerClose();
    trackEvent({ event: 'contactLabelClick' });
    dispatch(updateCurrentLabelSelected(labelName));
  };

  const handleRenameLabelButton = (labelId: string, labelName: string): void => {
    trackEvent({ event: 'renameLabelButton' });
    dispatch(updateModalName('renameLabel'));
    dispatch(updateModalProps({ labelId, labelName }));
    dispatch(updateIsOpen(true));
  };

  const handleDeleteLabelButton = (labelIdToDelete: string, labelName: string): void => {
    trackEvent({ event: 'deleteLabelButton' });
    dispatch(updateModalName('deleteLabel'));
    dispatch(
      updateModalProps({
        labelId: labelIdToDelete,
        labelName,
        currentLabel: labelId,
        navigate,
      })
    );
    dispatch(updateIsOpen(true));
  };

  const handleCreateClick = (): void => {
    trackEvent({ event: 'addNewContact' });
    handleDrawerClose && handleDrawerClose();
    navigate(`/user/label/${labelId}/new-contact`, {
      state: { previousPage: `/user/label/${labelId}/${contactId}`, search },
    });
  };

  const allContactList = (): JSX.Element => (
    <ul className={styles.userLabelList}>
      <li
        className={`${styles.labelItem} ${labelId === 'all' ? styles.selected : ''}`}
        aria-current={labelId === 'all' ? 'location' : undefined}
      >
        <Link to={`/user/label/all`} className={styles.labelTab} onClick={() => handleLabelClick()}>
          <KiteIcon size="16px" icon="ki-folder" />
          <span className={styles.text}>{t('allContactLabel')}</span>
        </Link>
      </li>
    </ul>
  );

  const handleNewLabel = (): void => {
    trackEvent({ event: 'newLabelButtonClick' });
    dispatch(updateModalName('newLabel'));
    dispatch(updateIsOpen(true));
  };

  const sortedCollection = labelCollection.length
    ? [...labelCollection].sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
    : [];

  const getKebabMenuItems = (labelId: string, labelName: string): KebabMenuItemsInterface[] => [
    {
      onClick: () => handleRenameLabelButton(labelId, labelName),
      icon: 'ki-edit',
      label: t('optionRename'),
      opensModal: true,
    },
    {
      onClick: () => handleDeleteLabelButton(labelId, labelName),
      icon: 'ki-trash',
      label: t('optionDelete'),
      className: 'deleteMenuItem',
      opensModal: true,
    },
  ];

  const userLabelList = (): JSX.Element => {
    return (
      <>
        <div className={styles.customLabelList} aria-label={'Labels'} role="region">
          <div className={styles.header}>
            <h2>{t('labelsHeader')}</h2>
            <KiteButton
              onClick={handleNewLabel}
              className={styles.newLabelBtn}
              variant="borderless"
              aria-label={'add new label'}
            >
              <KiteIcon icon="ki-plus" className={styles.icon} />
              <span>New Label</span>
            </KiteButton>
          </div>
          <ul className={styles.userLabelList}>
            {sortedCollection.length ? (
              sortedCollection.map((label): JSX.Element => {
                return (
                  <li
                    key={label.id}
                    className={`${styles.labelItem} ${labelId === label.id ? styles.selected : ''}`}
                    aria-current={labelId === label.id ? 'location' : undefined}
                  >
                    <Link
                      to={`/user/label/${label.id}`}
                      className={styles.labelTab}
                      onClick={() => handleLabelClick(label.name)}
                    >
                      <KiteIcon size="16px" icon={'ki-folder'} className={styles.labelIcon} />
                      <span className={styles.text}>{label.name}</span>
                    </Link>
                    <KebabMenu
                      items={getKebabMenuItems(label.id, label.name)}
                      className={'fixedPosition'}
                      onKebabToggle={onKebabToggle}
                      isScrollContainer={true}
                      ariaLabel={`options for ${label.name}`}
                    />
                  </li>
                );
              })
            ) : (
              <div className={styles.labelsPlaceholder}>
                <img className={styles.placeholderIcon} src="/assets/images/empty-labels.svg" alt="empty labels icon" />
                <h2 className="kite-type-style--title-6">{t('emptyTitle')}</h2>
                <p>{t('emptyInstruction')}</p>
              </div>
            )}
          </ul>
        </div>
      </>
    );
  };

  return (
    <div className={styles.LabelListContainer}>
      <div className={styles.createButton} onClick={handleCreateClick}>
        <KiteButton ref={createButtonRef} type="button">
          {t('createContact')}
        </KiteButton>
      </div>
      <Scrollbar customClass={styles.simpleBar} forceScroll={true}>
        {allContactList()}
        {userLabelList()}
      </Scrollbar>
      <div className={styles.labelMeter} title={`Total Contacts: ${totalContactCount}`}>
        <KiteMeter
          type="linear"
          size="small"
          label={t('contactsUsage')}
          status="neutral"
          value={contactCountPercentage}
          valueLabel={`${Math.round(contactCountPercentage * 100)}%`}
        />
      </div>
    </div>
  );
};

export default LabelList;
