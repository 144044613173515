import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { HelixLoginPayloadInterface } from 'libs/tracking/adapters/helix/events/submitLoginEvent';
import { TrackingHook } from 'react-tracking';
import { LoginFormInput } from 'slices/login';

interface LoginNonSsoArgs {
  payload: LoginFormInput;
  trackEvent: TrackingHook<HelixLoginPayloadInterface>['trackEvent'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loginNonSso: any;
}

interface LoginNonSsoThunkResponse {
  success?: {
    accessToken: string;
  };
  error?: ApolloError;
}
export const loginNonSsoThunk = createAsyncThunk(
  'loginNonSso',
  async (arg: LoginNonSsoArgs): Promise<LoginNonSsoThunkResponse> => {
    try {
      const response = await arg.loginNonSso({
        variables: arg.payload,
      });

      arg.trackEvent({ event: 'loginStopSuccess' });
      return {
        success: { accessToken: response.data.loginNonSso.accessToken },
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
