import { MailboxEntity, MailboxStatusEntity } from 'generated/graphql';
import { appConstants } from 'appConstants';

const isValidFolderName = (folderName: string): boolean => {
  return new RegExp(/^[ A-Za-z0-9-_,']*$/).test(folderName);
};

const isDuplicate = (folderName: string, mailboxCollection?: MailboxEntity[]): boolean => {
  if (!mailboxCollection) return false;
  return mailboxCollection.some((mailbox) => mailbox.name.trim() === folderName.trim());
};

const isInboxFolder = (folderId: string): boolean => {
  return folderId === appConstants.INBOX_MAIL_FOLDER_ID;
};

const isTrashFolder = (folderId: string): boolean => {
  return folderId === appConstants.TRASH_FOLDER_ID;
};

const isDraftFolder = (folderId: string): boolean => {
  return folderId === appConstants.DRAFT_FOLDER_ID;
};

const isSentMailFolder = (folderId: string): boolean => {
  return folderId === appConstants.SENT_MAIL_FOLDER_ID;
};

const isSpamFolder = (folderId: string): boolean => {
  return appConstants.SPAM_FOLDER_IDS.some((folder) => folder === folderId);
};

const isParentFolder = (folderId: string): boolean => {
  return atob(folderId).split('/').length < 2;
};

const isGlobalFolder = (folderId: string, spamFolderId: string): boolean => {
  const globalFolders = [
    appConstants.INBOX_MAIL_FOLDER_ID,
    appConstants.TRASH_FOLDER_ID,
    appConstants.SENT_MAIL_FOLDER_ID,
    appConstants.SPAM_FOLDER_IDS.find((id) => id === spamFolderId),
    appConstants.DRAFT_FOLDER_ID,
  ];

  return globalFolders.some((mailbox) => mailbox === folderId);
};

const getSpamFolderId = (globalMailboxStatus: MailboxStatusEntity[]): string => {
  return appConstants.SPAM_FOLDER_IDS.find((id) => globalMailboxStatus.find((folder) => folder.id === id)) || '';
};

export {
  isValidFolderName,
  isDuplicate,
  isInboxFolder,
  isTrashFolder,
  isDraftFolder,
  isSentMailFolder,
  isSpamFolder,
  isParentFolder,
  isGlobalFolder,
  getSpamFolderId,
};
