export const settingsTabsCopy = {
  accountInfo: '',
  timeZone: '',
  emailFormat: '',
  signature: '',
  autoReply: '',
  forwarding: '',
  replyTo: '',
  filterRules: '',
  spamManagement: '',
  blockedSenders: '',
  safeSenders: '',
  supportPage: '',
  storage: '',
  filled: '',
};
