import { ApolloError } from '@apollo/client';

export interface ErrorCheckInterface {
  isChecked: boolean;
  errorList: ErrorListInterface[] | ValidationErrorMessageInterface[] | null;
  errorCode: string | null;
}

interface UnknownErrorListInterface {
  [key: string]: {
    [key: string]: string;
  };
}
export type ErrorListInterface = UnknownErrorListInterface & {
  path?: string[];
};

export interface ValidationErrorMessageInterface {
  message: string;
  type: string;
  path: string[];
}

type GraphQLErrorExceptionType = {
  validationErrors: ValidationErrorMessageInterface[];
  messages: ErrorListInterface[] | null;
};

const parseGraphQLError = (error: ApolloError): ErrorCheckInterface => {
  if (error.message === 'Failed to fetch') {
    return {
      isChecked: true,
      errorList: [],
      errorCode: 'ERR_CONNECTION_REFUSED',
    };
  }

  const code = error?.graphQLErrors[0]?.extensions.code;
  const { validationErrors, messages } = error.graphQLErrors[0].extensions as GraphQLErrorExceptionType;
  const errorsArray = code === 'GRAPHQL_VALIDATION_FAILED' ? validationErrors : messages;

  return {
    isChecked: true,
    errorList: errorsArray,
    errorCode: code,
  };
};

export default parseGraphQLError;
