import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReSyncContactMutationFn, ContactReSyncEntity } from 'generated/graphql';

interface ContactResponse {
  success?: ContactReSyncEntity;
  error?: ApolloError | undefined;
}

interface ContactReSyncAllowedArg {
  contactReSyncHook: ReSyncContactMutationFn;
}

export const postContactReSyncThunk = createAsyncThunk(
  'postContactReSyncThunk',
  async (arg: ContactReSyncAllowedArg): Promise<ContactResponse> => {
    try {
      const response = await arg.contactReSyncHook();

      return {
        success: response?.data?.reSyncContact,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
