import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import { useDeleteMailboxMutation, useUpdateFiltersMutation } from 'generated/graphql';
import { deleteMailboxThunk } from 'thunks/mailbox/deleteMailboxThunk';
import { TrackingHook, useTracking } from 'react-tracking';
import { transformExistingFilters } from 'utils/transformFilterForm';
import { updateFilterThunk } from 'thunks/filter/updateFilterThunk';
import { resetMailboxApiError } from 'slices/mailbox-collection';
import { useNavigate } from 'react-router-dom';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, useDialogTranslation } from 'translation/hooks';

interface DeleteFolderInterface {
  folderId?: string;
  fullPath?: string;
}

const DeleteFolder: React.FC<DeleteFolderInterface> = ({ folderId, fullPath }) => {
  const { t } = useDialogTranslation('deleteFolderCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'folders' });
  const focusOpener = useTarget('modal');
  const apiError = useAppSelector((state) => state.mailboxCollectionSlice.apiError);
  const filters = useAppSelector((state) => state.filterCollectionSlice.filters) || [];
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const spamFolderId = useAppSelector((state) => state.mailboxCollectionSlice.spamFolderId);

  const [updateFiltersHook] = useUpdateFiltersMutation();
  const navigate = useNavigate();

  const [deleteMailboxHook] = useDeleteMailboxMutation();
  const dispatch = useAppDispatch();

  const isSubFolder = fullPath && fullPath?.split('/').length > 1;

  useEffect(() => {
    !isSubFolder ? trackEvent({ event: 'deleteFolderView' }) : trackEvent({ event: 'deleteSubFolderView' });
  }, []);

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  const isSaved = async (): Promise<boolean> => {
    const result = await dispatch(
      deleteMailboxThunk({
        deleteMailboxHook,
        payload: { mailboxId: folderId ?? '', deleteMessage: false, spamFolderId },
      })
    );
    // @ts-ignore
    if (result.payload.success) {
      const removedFilters = filters.filter((filter) => !filter.moveFolder || filter.moveFolder !== folderId);
      if (removedFilters.length !== filters.length) {
        const updateFiltersResult = await dispatch(
          updateFilterThunk({
            payload: {
              filters: transformExistingFilters(removedFilters),
            },
            updateFiltersHook,
          })
        );
        // @ts-ignore
        if (updateFiltersResult.payload.success) {
          return isSavedSuccess(true);
        }
        return isSavedSuccess(false);
      }

      return isSavedSuccess(true);
    }

    return isSavedSuccess(false);
  };

  const isSavedSuccess = (bool: boolean): boolean => {
    if (bool) {
      !isSubFolder ? trackEvent({ event: 'deleteFolderSuccess' }) : trackEvent({ event: 'deleteSubFolderSuccess' });

      dispatch(updateToastMessage({ message: t('successToast'), success: true }));
      navigate('/user/mail');
      return true;
    }

    !isSubFolder ? trackEvent({ event: 'deleteFolderErrorApi' }) : trackEvent({ event: 'deleteSubFolderErrorApi' });
    return false;
  };

  const isCancelled = (): boolean => {
    !isSubFolder ? trackEvent({ event: 'deleteFolderCancel' }) : trackEvent({ event: 'deleteSubFolderCancel' });

    dispatch(resetMailboxApiError());
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    !isSubFolder ? trackEvent({ event: 'deleteFolderClick' }) : trackEvent({ event: 'deleteSubFolderClick' });
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t('delete'),
    isDelete: true,
  };

  return (
    <ModalContentWrapper>
      <h5>{isSubFolder ? t('subFolderHeader') : t('folderHeader')}</h5>
      <p>{t('description')}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default DeleteFolder;
