import Helix from '@charter/helix/build/portals/index.min';
import { HelixEventFn, HelixPayloadInterface } from 'libs/tracking/adapters/helix/helixAdapter';

interface HelixMessagePayloadInterface extends HelixPayloadInterface {
  payload?: {
    totalSelected?: number;
    keyword?: string;
    searchResultCount?: number;
    errorMessage?: string;
  };
}

const submitMessageEvent: HelixEventFn<HelixMessagePayloadInterface> = ({ event, payload }) => {
  switch (event) {
    case 'selectEmailCheckbox': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_selectEmailCheckbox');
      break;
    }
    case 'messageClose': {
      Helix.track('WEBMAIL_selectAction_close');
      break;
    }
    case 'flagMessage': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_flagEmail');
      break;
    }
    case 'unflagMessage': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_unflagEmail');
      break;
    }
    case 'markAsRead': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_markAsRead');
      break;
    }
    case 'markAsUnread': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_markAsUnread');
      break;
    }
    case 'moveEmailTo': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_moveEmailTo');
      break;
    }
    case 'printEmail': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_printEmail');
      break;
    }
    case 'moveEmailsModal': {
      Helix.track('WEBMAIL_modalView_moveEmails');
      break;
    }
    case 'moveEmailCancel': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_moveEmailModalCancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'moveEmailModalMove': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_moveEmailModalMove');
      break;
    }
    case 'moveEmailSuccess': {
      Helix.track('WEBMAIL_modalClose');
      Helix.track('WEBMAIL_applicationActivity_moveEmailSuccess');
      break;
    }
    case 'moveEmailFail': {
      Helix.track('WEBMAIL_error_unableToMoveEmail', {
        appErrorMessage: payload?.errorMessage,
      });
      break;
    }
    case 'blockSender': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_blockSender');
      break;
    }
    case 'blockSenderModalView': {
      Helix.track('WEBMAIL_modalView_blockSender');
      break;
    }
    case 'blockSenderSave': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_blockSenderModalBlockSenders');
      break;
    }
    case 'blockSenderTryAgain': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_blockSenderModalTryAgain');
      break;
    }
    case 'blockSenderCancel': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_blockSenderModalCancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'blockSenderSuccess': {
      Helix.track('WEBMAIL_modalClose');
      Helix.track('WEBMAIL_assetDisplayed_senderBlockedNotification');
      break;
    }
    case 'blockSenderFailure': {
      Helix.track('WEBMAIL_error_blockSenderModal_unableToSaveChanges');
      break;
    }
    case 'openCompose': {
      Helix.track('WEBMAIL_featureStart_composeEmail', {
        msgCategory: 'composeEmail',
      });
      break;
    }
    case 'forwardEmail': {
      Helix.track('WEBMAIL_selectAction_composeEmail_forwardButton');
      break;
    }
    case 'deleteEmail': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_deleteEmail');
      break;
    }
    case 'deleteEmailSuccess': {
      Helix.track('WEBMAIL_applicationActivity_deleteEmailSuccess');
      break;
    }
    case 'deleteEmailFailure': {
      Helix.track('WEBMAIL_error_unableToDeleteEmail');
      break;
    }
    case 'draftsPageViewInit': {
      Helix.track('WEBMAIL_pageViewInit_email_drafts', {
        currPageUrl: 'user/mail/RHJhZnRz',
      });
      break;
    }
    case 'draftsEmpty': {
      Helix.track('WEBMAIL_assetDisplayed_draftsFolderEmpty');
      break;
    }
    case 'markAsSpam': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_markAsSpam');
      break;
    }
    case 'viewAttachment': {
      Helix.track('WEBMAIL_selectAction_viewAttachment');
      break;
    }
    case 'downloadAttachment': {
      Helix.track('WEBMAIL_selectAction_downloadAttachment');
      break;
    }
    case 'expandAttachments': {
      Helix.track('WEBMAIL_selectAction_expandAttachements');
      break;
    }
    case 'collapseAttachments': {
      Helix.track('WEBMAIL_selectAction_collapseAttachements');
      break;
    }
    case 'downloadAttachmentError': {
      Helix.track('WEBMAIL_error_unableToDownloadAttachement');
      break;
    }
    case 'pageViewCompleteSpam': {
      Helix.track('WEBMAIL_pageView_email_spam', {
        msgEventType: 'pageViewCompleted',
        currPageName: 'spam folder',
        currPageSeqNumber: 1,
      });
      break;
    }
    case 'pageViewCompleteDrafts': {
      Helix.track('WEBMAIL_pageView_email_drafts', {
        msgEventType: 'pageViewCompleted',
        currPageName: 'drafts folder',
        currPageSeqNumber: 1,
      });
      break;
    }
    case 'pageViewCompleteSentMail': {
      Helix.track('WEBMAIL_pageView_email_sentMail', {
        msgEventType: 'pageViewCompleted',
        currPageName: 'sent folder',
        currPageSeqNumber: 1,
      });
      break;
    }
    case 'markAsNotSpam': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_markAsNotSpam');
      break;
    }
    case 'markAsNotSpamSuccess': {
      Helix.track('WEBMAIL_applicationActivity_markAsNotSpamSuccess');
      break;
    }
    case 'markAsNotSpamFailure': {
      Helix.track('WEBMAIL_error_unableToMarkAsNotSpam');
      break;
    }
    case 'clickMessage': {
      Helix.track('WEBMAIL_selectAction_emailSelect');
      break;
    }
    case 'viewHeader': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_viewHeader');
      break;
    }
    case 'markAsSpamModalView': {
      Helix.track('WEBMAIL_modalView_moveEmailsToSpam');
      break;
    }
    case 'markAsSpamConfirm': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_markAsSpamFromModal');
      break;
    }
    case 'markAsSpamSuccess': {
      Helix.track('WEBMAIL_modalClose');
      Helix.track('WEBMAIL_applicationActivity_markAsSpamSuccess');
      break;
    }
    case 'markAsSpamFailure': {
      Helix.track('WEBMAIL_error_unableToMoveEmailToSpam');
      break;
    }
    case 'markAsSpamCancel': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_cancelMarkAsSpamFromModal');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'readReceiptModalView': {
      Helix.track('WEBMAIL_modalView_readReceiptRequested');
      break;
    }
    case 'readReceiptSent': {
      Helix.track('WEBMAIL_selectAction_readReceiptRequestedModal_yes');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'readReceiptCancel': {
      Helix.track('WEBMAIL_selectAction_readReceiptRequestedModal_no');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'deleteMessagesModalView': {
      Helix.track('WEBMAIL_modalView_deleteMessages');
      break;
    }
    case 'permanentDeleteButton': {
      Helix.track('WEBMAIL_selectAction_deleteMessagesModal_delete');
      break;
    }
    case 'permanentDeleteSuccess': {
      Helix.track('WEBMAIL_modalClose');
      Helix.track('WEBMAIL_applicationActivity_deleteEmailSuccess');
      break;
    }
    case 'permanentDeleteFailure': {
      Helix.track('WEBMAIL_error_unableToDeleteMessages');
      break;
    }
    case 'permanentDeleteCancel': {
      Helix.track('WEBMAIL_selectAction_deleteMessagesModal_cancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'sortMenuClick': {
      Helix.track('WEBMAIL_selectAction_inbox_sort');
      break;
    }
    case 'sortNewToOld': {
      Helix.track('WEBMAIL_selectAction_inbox_sortNewToOld');
      break;
    }
    case 'sortOldToNew': {
      Helix.track('WEBMAIL_selectAction_inbox_sortOldToNew');
      break;
    }
    case 'sortSender': {
      Helix.track('WEBMAIL_selectAction_inbox_sortSender');
      break;
    }
    case 'sortFlagged': {
      Helix.track('WEBMAIL_selectAction_inbox_sortFlag');
      break;
    }
    case 'sortSize': {
      Helix.track('WEBMAIL_selectAction_inbox_sortSize');
      break;
    }
    case 'sortSubject': {
      Helix.track('WEBMAIL_selectAction_inbox_sortSubject');
      break;
    }
    case 'sortRecipient': {
      Helix.track('WEBMAIL_selectAction_inbox_sortRecipient');
      break;
    }
    case 'searchStart': {
      Helix.track('WEBMAIL_selectAction_searchStarted', {
        searchType: 'keyword',
        searchId: payload?.keyword,
        currPageAppSection: 'inbox',
      });
      break;
    }
    case 'searchResultReturned': {
      Helix.track('WEBMAIL_searched_keyword', {
        searchElem: payload?.searchResultCount,
        searchText: payload?.keyword,
        searchResultsMs: 10,
      });
      break;
    }
    case 'searchResultSelected': {
      Helix.track('WEBMAIL_selectAction_searchResultSelected_keyword');
      break;
    }
    case 'searchNoResults': {
      Helix.track('WEBMAIL_searched_keyword_noResults', {
        searchElem: 0,
        searchText: payload?.keyword,
        searchResultsMs: 10,
      });
      Helix.track('WEBMAIL_assetDisplayed_searched_noResultsFound');
      break;
    }
    case 'pageViewCompleteInbox': {
      Helix.track('WEBMAIL_pageView_email_inbox', {
        msgEventType: 'pageViewCompleted',
        currPageName: 'inbox folder',
        currPageSeqNumber: 1,
      });
      break;
    }
    case 'pageViewCompleteUserFolder': {
      Helix.track('WEBMAIL_pageView_email_folder', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'pageViewCompleteUserSubFolder': {
      Helix.track('WEBMAIL_pageView_email_subFolder', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'searchClose': {
      Helix.track('WEBMAIL_searchClosed');
      break;
    }
    case 'getMailButton': {
      Helix.track('WEBMAIL_selectAction_inbox_getMail');
      break;
    }
    case 'selectAllMessages': {
      Helix.track('WEBMAIL_selectAction_email_selectAllMessages');
      break;
    }
    case 'refreshButton': {
      Helix.track('WEBMAIL_selectAction_refresh');
      break;
    }
    case 'viewHeaderModalView': {
      Helix.track('WEBMAIL_modalView_emailViewHeader');
      break;
    }
    case 'viewHeadeModalClose': {
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'replyButton': {
      Helix.track('WEBMAIL_selectAction_composeEmail_replyButton');
      break;
    }
    case 'replyAllButton': {
      Helix.track('WEBMAIL_selectAction_composeEmail_replyAllButton');
      break;
    }
    default: {
      console.log('Event case for Messages not found', event);
      return null;
    }
  }
  return null;
};

export default submitMessageEvent;
