import styles from 'components/canary/styles/CanaryTutorialDesktop.module.scss';
import { KiteButton, KiteCard, KiteIcon } from '@kite/react-kite';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TrackingHook, useTracking } from 'react-tracking';
import { useAppDispatch } from 'hooks';
import { updateIsCanary } from 'slices/app';
import { appConstants } from 'appConstants';
import { usePageTranslation } from 'translation/hooks';

export interface TutorialAsset {
  image: string;
  styling?: string;
  contentWrapperStyle?: string;
  ariaInstructions: string;
  stepName: string;
  arrowStyling?: string;
  text: {
    header: string;
    secondary: string;
  };
  page?: number;
}

export type TutorialObject = {
  tutorialObject: TutorialAsset;
};

const CanaryTutorialDesktop: React.FC = () => {
  const { t } = usePageTranslation('canaryCopy');
  const [currentTutorialPage, setCurrentTutorialPage] = useState(0);
  const { trackEvent }: TrackingHook = useTracking({ page: 'canary', component: 'CanaryLandingPage' });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const headerFocusRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    trackEvent({ event: 'canaryTutorialStart' });
  }, []);

  useEffect(() => {
    if (headerFocusRef?.current) {
      headerFocusRef.current.focus();
      trackEvent({
        event: 'canaryTutorialModal',
        tutorialStepName: tutorialAssets[currentTutorialPage].stepName,
      });
    }
  }, [currentTutorialPage]);

  const tutorialAssets: TutorialAsset[] = [
    {
      image: appConstants.ui.TUTORIAL_DESKTOP_STEP_1,
      stepName: 'tutorialStep1',
      styling: styles.sideNavPos,
      ariaInstructions: t(`sideNavAria`),
      text: {
        header: t(`sideNavHeader`),
        secondary: t(`sideNavSecondary`),
      },
    },
    {
      image: appConstants.ui.TUTORIAL_DESKTOP_STEP_2,
      stepName: 'tutorialStep2',
      styling: styles.bulkActionsPos,
      ariaInstructions: t(`bulkActionsAria`),
      arrowStyling: styles.arrowRight,
      text: {
        header: t(`bulkActionsHeader`),
        secondary: t(`bulkActionsSecondary`),
      },
    },
    {
      image: appConstants.ui.TUTORIAL_DESKTOP_STEP_3,
      stepName: 'tutorialStep3',
      styling: styles.settingsPos,
      ariaInstructions: t(`settingsAria`),
      contentWrapperStyle: styles.contentWrapperSmall,
      text: {
        header: t(`settingsHeader`),
        secondary: t(`settingsSecondary`),
      },
    },
    {
      image: appConstants.ui.TUTORIAL_DESKTOP_STEP_4,
      stepName: 'tutorialStep4',
      styling: styles.spamPos,
      ariaInstructions: t(`spamAria`),
      contentWrapperStyle: styles.contentWrapperSmall,
      text: {
        header: t(`spamHeader`),
        secondary: t(`spamSecondary`),
      },
    },
    {
      image: appConstants.ui.TUTORIAL_DESKTOP_STEP_5,
      stepName: 'tutorialStep5',
      styling: styles.signaturePos,
      ariaInstructions: t(`signatureAria`),
      contentWrapperStyle: styles.contentWrapperSmall,
      text: {
        header: t(`signatureHeader`),
        secondary: t(`signatureSecondary`),
      },
    },
  ];

  const firstPage = currentTutorialPage < 1;
  const lastPage = currentTutorialPage === tutorialAssets.length - 1;

  const handleSkip = (): void => {
    trackEvent({
      event: 'canaryTutorialSkip',
      tutorialStepName: tutorialAssets[currentTutorialPage].stepName,
    });
    dispatch(updateIsCanary(false));
    navigate('/user/mail');
    return;
  };

  const handlePrevStep = (): void => {
    trackEvent({
      event: 'canaryTutorialPrevStep',
      tutorialStepName: tutorialAssets[currentTutorialPage].stepName,
    });
    setCurrentTutorialPage(currentTutorialPage - 1);
  };

  const handleNextStep = (): void => {
    trackEvent({
      event: 'canaryTutorialNextStep',
      tutorialStepName: tutorialAssets[currentTutorialPage].stepName,
    });
    setCurrentTutorialPage(currentTutorialPage + 1);
  };

  const handleFinish = (): void => {
    trackEvent({
      event: 'canaryTutorialFinish',
    });
    dispatch(updateIsCanary(false));
    navigate('/user/mail');
    return;
  };

  const handleExit = (): void => {
    trackEvent({
      event: 'canaryTutorialXIcon',
      tutorialStepName: tutorialAssets[currentTutorialPage].stepName,
    });

    dispatch(updateIsCanary(false));
    navigate('/user/mail');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): null | void => {
    if (event.key === 'Escape') {
      handleExit();
    }
  };

  const handleGoToSettings = (): void => {
    trackEvent({
      event: 'canaryTutorialFinish',
    });
    dispatch(updateIsCanary(false));
    navigate('/user/setting/signature');
  };

  const handleCloseButtonClick = (): void => {
    trackEvent({
      event: 'canaryTutorialXIcon',
      tutorialStepName: tutorialAssets[currentTutorialPage].stepName,
    });

    lastPage
      ? trackEvent({
          event: 'canaryTutorialStop',
          tutorialStepName: tutorialAssets[currentTutorialPage].stepName,
        })
      : trackEvent({
          event: 'canaryTutorialStopAbandon',
          tutorialStepName: tutorialAssets[currentTutorialPage].stepName,
        });

    dispatch(updateIsCanary(false));
    navigate('/user/mail');
  };

  const TutorialDialog: React.FC<TutorialObject> = ({ tutorialObject }) => {
    return (
      <div role="dialog" aria-labelledby="myHeader">
        <KiteCard className={`${styles.dialogContainer} ${tutorialAssets[currentTutorialPage].styling}`}>
          <button type="button" onClick={handleCloseButtonClick} className={`${styles.exitButton}`}>
            <KiteIcon icon="ki-x" size="18px" className={styles.exitIcon} />
          </button>
          <div className={`${tutorialAssets[currentTutorialPage].arrowStyling || styles.arrowLeft}`} />
          <h3 id="myHeader" tabIndex={-1} ref={headerFocusRef} className={styles.header}>
            {tutorialObject.text.header}
          </h3>
          <p className={styles.hidden} aria-label={tutorialAssets[currentTutorialPage].ariaInstructions} />
          <p className={styles.secondary}> {tutorialObject.text.secondary}</p>
          <p className={styles.pageCount}>{`${currentTutorialPage + 1} of ${tutorialAssets.length}`}</p>
          <div className={styles.actionButtons}>
            {!lastPage ? (
              <KiteButton
                className={styles.prevButton}
                onClick={() => {
                  firstPage ? handleSkip() : handlePrevStep();
                }}
              >
                {firstPage ? t(`skip`) : t(`previous`)}
              </KiteButton>
            ) : (
              <KiteButton
                className={styles.prevButton}
                variant={'secondary'}
                onClick={() => {
                  handleFinish();
                }}
              >
                {t(`finish`)}
              </KiteButton>
            )}
            <KiteButton
              className={styles.nextButton}
              onClick={() => (lastPage ? handleGoToSettings() : handleNextStep())}
            >
              {lastPage ? t(`settings`) : t(`next`)}
            </KiteButton>
          </div>
        </KiteCard>
      </div>
    );
  };

  return (
    <div role="application" className={styles.mainPage} onKeyDown={handleKeyDown}>
      <div className={`${styles.contentWrapper} ${tutorialAssets[currentTutorialPage].contentWrapperStyle || ''}`}>
        <TutorialDialog tutorialObject={tutorialAssets[currentTutorialPage]} />
        <img
          aria-hidden="true"
          className={styles.screenshot}
          alt="tutorial screenhot"
          src={tutorialAssets[currentTutorialPage].image}
        />
      </div>
    </div>
  );
};

export default CanaryTutorialDesktop;
