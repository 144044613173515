import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContactForm, CreateBulkContactMutationFn } from 'generated/graphql';

interface CreateBulkContactArg {
  payload: {
    contacts: ContactForm[];
  };
  createBulkContactHook: CreateBulkContactMutationFn;
}

interface CreateBulkContactResponse {
  success?: {
    createCount: number;
  };
  error?: ApolloError;
}

export const createBulkContactThunk = createAsyncThunk(
  'CreateBulkContactThunk',
  async (arg: CreateBulkContactArg): Promise<CreateBulkContactResponse> => {
    try {
      const response = await arg.createBulkContactHook({
        variables: arg.payload,
      });
      return {
        success: {
          createCount: response.data?.createBulkContact.createCount || 0,
        },
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
