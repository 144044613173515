import { ApolloError, MutationFunction } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FilterEntity, GetFiltersQuery, GetFiltersQueryVariables } from 'generated/graphql';

interface GetFilterArgInterface {
  getFiltersHook: MutationFunction<GetFiltersQuery, GetFiltersQueryVariables>;
}

interface GetFilterResponse {
  success?: FilterEntity[];
  error?: ApolloError;
}

export const getFilterThunk = createAsyncThunk(
  'filterThunk',
  async (arg: GetFilterArgInterface): Promise<GetFilterResponse> => {
    try {
      const response = await arg.getFiltersHook();
      return {
        success: response.data?.getFilters,
      } as GetFilterResponse;
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
