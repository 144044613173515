import { KiteButton } from '@kite/react-kite';
import CanaryModalStyles from 'components/canary/styles/CanaryModal.module.scss';
import appRedirect from 'utils/appRedirect';
import { TrackingHook, useTracking } from 'react-tracking';
import { useAppDispatch } from 'hooks';
import { updateIsOpen } from 'slices/app';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { getWebmail2URL } from 'app-constants/appConfig';
import { usePageTranslation } from 'translation/hooks';

const CanaryModal: React.FC = () => {
  const { trackEvent }: TrackingHook = useTracking({ page: 'canary', component: 'CanaryModal' });
  const { t } = usePageTranslation('canaryCopy');

  trackEvent({ event: 'canaryModalView' });

  const dispatch = useAppDispatch();

  const handleGetItLater = async (): Promise<void> => {
    trackEvent({ event: 'canaryModalModalOptOut' });
    trackEvent({ event: 'canaryModalModalClose' });

    appRedirect(getWebmail2URL());
  };

  const handleLearnMore = (): void => {
    dispatch(updateIsOpen(false));
  };

  return (
    <ModalContentWrapper className={`${CanaryModalStyles.modalContainer}`}>
      <h4 tabIndex={0} className={CanaryModalStyles.header}>
        {t('modalHeader')}
      </h4>
      <p className={CanaryModalStyles.bodyText}>{t('modalDescription')}</p>
      <div className={CanaryModalStyles.actionButtons}>
        <KiteButton className={CanaryModalStyles.getItLater} variant="secondary" onClick={handleGetItLater}>
          {t('getItLater')}
        </KiteButton>
        <KiteButton className={CanaryModalStyles.learnMore} onClick={handleLearnMore}>
          {t('upgradeNow')}
        </KiteButton>
      </div>
    </ModalContentWrapper>
  );
};

export default CanaryModal;
