import { createSlice } from '@reduxjs/toolkit';
import { postOptInStatusThunk } from 'thunks/canary/canaryPostThunk';
import { getOptInStatusThunk } from 'thunks/canary/getOptInStatusThunk';
import {
  getOptInStatusStorage,
  getOptInTypeStorage,
  setOptInStatusStorage,
  setOptInTypeStorage,
} from 'utils/accessTokenLocalStorage';
import { getContactReSyncAllowedThunk } from 'thunks/canary/getContactResyncAllowedThunk';
import { postContactReSyncThunk } from 'thunks/contact/postContactResyncThunk';

interface CanaryFormInput {
  optInStatus: string;
  optInType: string;
  isContactReSyncAllowed?: boolean;
  lastReSyncDate?: string;
}

export interface CanaryState extends CanaryFormInput {
  isEligible: boolean;
  isOptInStatusCalled: boolean;
  apiError: Error | null;
}

const initialState: CanaryState = {
  optInStatus: getOptInStatusStorage() || '',
  optInType: getOptInTypeStorage() || '',
  apiError: null,
  isEligible: false,
  isOptInStatusCalled: false,
};

export const canarySlice = createSlice({
  name: 'canary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postOptInStatusThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.optInStatus = action.payload.success.optInStatus;
        state.optInType = action.payload.success.optInType;
        setOptInStatusStorage(action.payload.success.optInStatus);
        setOptInTypeStorage(action.payload.success.optInType);
        return;
      }
      if (action.payload.error) {
        state.apiError = action.payload.error;

        return;
      }
    });

    builder.addCase(getOptInStatusThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isOptInStatusCalled = true;
        state.optInStatus = action.payload.success.status?.optInStatus as string;
        setOptInStatusStorage(action.payload.success.status?.optInStatus as string);
        return;
      }
      if (action.payload.error) {
        state.apiError = action.payload.error;
        return;
      }
    });

    builder.addCase(getContactReSyncAllowedThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isContactReSyncAllowed = action.payload?.success?.isAllowed;
        state.lastReSyncDate = action.payload?.success?.lastReSyncDate as string;
      }

      if (action.payload.error) {
        state.apiError = action.payload.error;
        return;
      }
    });

    builder.addCase(postContactReSyncThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isContactReSyncAllowed = false;
        state.lastReSyncDate = new Date().toISOString();
      }
    });
  },
});

export default canarySlice.reducer;
