export const emailActionsCopy = {
  select: '',
  getMail: '',
  refresh: '',
  markAsRead: '',
  markAsUnread: '',
  moveTo: '',
  blockSenders: '',
  blockSender: '',
  markAsSpam: '',
  markAsNotSpam: '',
  viewHeader: '',
  sort: '',
  flag: '',
  unflag: '',
  delete: '',
  print: '',
  newToOld: '',
  oldToNew: '',
  sender: '',
  recipient: '',
  size: '',
  subject: '',
  flagged: '',
  priority: '',
  selected: '',
  deleteMessagesSuccess: '',
  markAsNotSpamSuccess: '',
  otherActions: '',
};
