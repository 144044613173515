import { assignLabelToContactCopy } from 'translation/models/dialogs/assign-label-to-contact.model';
import { blockSendersCopy } from 'translation/models/dialogs/block-senders.model';
import { canaryModalCopy } from 'translation/models/dialogs/canary-modal.model';
import { deleteContactCopy } from 'translation/models/dialogs/delete-contact.model';
import { deleteFilterCopy } from 'translation/models/dialogs/delete-filter.model';
import { deleteFolderCopy } from 'translation/models/dialogs/delete-folder.model';
import { deleteLabelCopy } from 'translation/models/dialogs/delete-label.model';
import { deleteMessagesModalCopy } from 'translation/models/dialogs/delete-messages-modal.model';
import { disableForwardingModalCopy } from 'translation/models/dialogs/disable-forwarding-modal.model';
import { discardDraftCopy } from 'translation/models/dialogs/discard-draft.model';
import { emptyTrashCopy } from 'translation/models/dialogs/empty-trash.model';
import { exportContactCopy } from 'translation/models/dialogs/export-contact.model';
import { importContactsCopy } from 'translation/models/dialogs/import-contacts.model';
import { markAsNotSpamCopy } from 'translation/models/dialogs/mark-as-not-spam.model';
import { markAsSpamCopy } from 'translation/models/dialogs/mark-as-spam.model';
import { moveMessagesCopy } from 'translation/models/dialogs/move-messages.model';
import { newFolderCopy } from 'translation/models/dialogs/new-folder.model';
import { newLabelCopy } from 'translation/models/dialogs/new-label.model';
import { permanentDeleteMessageCopy } from 'translation/models/dialogs/permanent-delete-message.model';
import { renameFolderCopy } from 'translation/models/dialogs/rename-folder.model';
import { renameLabelCopy } from 'translation/models/dialogs/rename-label.model';
import { saveDraftCopy } from 'translation/models/dialogs/save-draft.model';
import { readReceiptCopy } from 'translation/models/dialogs/read-receipt.model';
import { spanishComingSoonCopy } from 'translation/models/dialogs/spanish-coming-soon.model';
import { unsavedChangesModalCopy } from 'translation/models/dialogs/unsaved-changes-modal.model';
import { viewHeaderCopy } from 'translation/models/dialogs/view-header.model';
import { addressBookCopy } from 'translation/models/dialogs/address-book.model';
import { aupCodeCopy } from 'translation/models/dialogs/aup-code.model';
import { syncContactsCopy } from 'translation/models/dialogs/sync-contacts.model';

export const dialogs = {
  assignLabelToContactCopy,
  blockSendersCopy,
  canaryModalCopy,
  deleteContactCopy,
  deleteFilterCopy,
  deleteFolderCopy,
  deleteLabelCopy,
  deleteMessagesModalCopy,
  disableForwardingModalCopy,
  discardDraftCopy,
  emptyTrashCopy,
  exportContactCopy,
  importContactsCopy,
  markAsNotSpamCopy,
  markAsSpamCopy,
  moveMessagesCopy,
  newFolderCopy,
  newLabelCopy,
  permanentDeleteMessageCopy,
  renameFolderCopy,
  renameLabelCopy,
  saveDraftCopy,
  readReceiptCopy,
  spanishComingSoonCopy,
  unsavedChangesModalCopy,
  viewHeaderCopy,
  addressBookCopy,
  aupCodeCopy,
  syncContactsCopy,
};
