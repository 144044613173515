import React, { useEffect, useRef } from 'react';
import styles from 'components/contact-view/styles/ContactView.module.scss';
import { KiteCard } from '@kite/react-kite';
import { useAppSelector } from 'hooks';
import { appConstants } from 'appConstants';
import { TrackingHook, useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom';
import { usePageTranslation } from 'translation/hooks';

/**
 * @todo Please do it in better way useLocation()
 */
const ContactViewPlaceholder: React.FC = () => {
  const { t } = usePageTranslation('contactViewCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'contacts' });

  const selectedContacts = useAppSelector((state) => state.contactSlice.selectedContacts) || [];
  const prevSelected = useRef<number>(0);
  let isContactOpened = false;

  const location = useLocation().pathname.split('/');
  if (location.length > 4) {
    isContactOpened = true;
  }

  useEffect(() => {
    if (selectedContacts.length > 0) {
      trackEvent({
        event: 'selectContacts',
        payload: { totalSelected: selectedContacts.length },
      });
    }
    if (selectedContacts.length < prevSelected.current) {
      trackEvent({
        event: 'deselectContacts',
        payload: { totalSelected: prevSelected.current - selectedContacts.length },
      });
    }
    prevSelected.current = selectedContacts.length;
  }, [selectedContacts]);

  const noContactSelectedPreview = (): JSX.Element => {
    return (
      <div className={styles.contactViewContainer}>
        <KiteCard className={`${styles.contactCard} ${styles.noneSelected}`}>
          <div className={styles.selectAContactPlaceholder}>
            <img className={styles.placeholderIcon} src={appConstants.SELECT_CONTACT_IMG} alt="select a contact icon" />
            <h2 className="kite-type-style--title-6">{t('selectAContactTitle')}</h2>
            <p>{t('selectAContactInstruction')}</p>
          </div>
        </KiteCard>
      </div>
    );
  };

  const contactSelectedPreview = (): JSX.Element => {
    return (
      <div className={styles.contactViewContainer}>
        <div className={`${styles.contactCard} ${styles.selectedContacts}`}>
          <div className={styles.selectAContactPlaceholder}>
            <img className={styles.selectedContactsIcon} src={appConstants.CONTACT_CARD_IMG} alt="selected contacts" />
            <h2 className={`${styles.selectedContactsDescription} kite-type-style--title-6`}>
              {t('selectedContacts', { count: selectedContacts.length })}
            </h2>
          </div>
        </div>
      </div>
    );
  };

  if (selectedContacts && selectedContacts.length > 0) {
    return contactSelectedPreview();
  }

  if (!isContactOpened) {
    return noContactSelectedPreview();
  }

  return null;
};

export default ContactViewPlaceholder;
