import React, { useEffect } from 'react';
import { KiteProgressIndicator, KiteSelect, KiteTextInput } from '@kite/react-kite';
import { FilterFormInterface, FilterSectionProps } from 'components/setting-filter-form/initialState';
import { useAppSelector } from 'hooks';
import validateFilterField, { getFilterValidationErrorMessage } from 'utils/validateFilterField';
import styles from 'components/setting-filter-form/styles/SettingFilterForm.module.scss';
import FilterSection from 'components/setting-filter-form/sections/FilterSection';
import { mailboxCollectionGetThunk } from 'thunks/mailbox-collection/mailboxCollectionThunk';
import { useAppDispatch } from 'hooks';
import { useGetMailBoxCollectionLazyQuery } from 'generated/graphql';
import { useGetGlobalMailBoxStatusLazyQuery } from 'generated/graphql';
import { globalMailboxStatusThunk } from 'thunks/mailbox-collection/globalMailboxStatusThunk';
import { isDraftFolder, isGlobalFolder, isSentMailFolder, isTrashFolder } from 'utils/folderNameValidator';
import { KiteInputElems } from 'libs/kite/types';
import { PageNSTranslationFileKeys, usePageTranslation } from 'translation/hooks';
import {
  filterActionTrans,
  moveFolderTrans,
  newFolderTrans,
} from 'components/setting-filter-form/sections/translationKeys';
import { GlobalFoldersInterface, appConstants } from 'appConstants';
import transformNestedGlobalFolderPath from 'utils/transformNestedGlobalFolderPath';

export const FilterAction: React.FC<
  FilterSectionProps<Pick<FilterFormInterface, 'filterAction' | 'moveFolder' | 'newFolder'>>
> = ({ updateFormValues, values, errors, setErrors }) => {
  const { t } = usePageTranslation('filterRuleFormCopy');
  const dispatch = useAppDispatch();
  const spamFolderId = useAppSelector((state) => state.mailboxCollectionSlice.spamFolderId);
  const [getMailBoxCollectionHook, { loading }] = useGetMailBoxCollectionLazyQuery();
  const [getGlobalMailboxStatusHook] = useGetGlobalMailBoxStatusLazyQuery();
  const mailboxCollection = useAppSelector((state) => state.mailboxCollectionSlice.mailboxCollection);

  const moveFolderErrorMessage = getFilterValidationErrorMessage(errors, 'moveFolder', moveFolderTrans);
  const filterActionErrorMessage = getFilterValidationErrorMessage(errors, 'filterAction', filterActionTrans);
  const newFolderErrorMessage = getFilterValidationErrorMessage(errors, 'newFolder', newFolderTrans);

  const dispatchMailboxCollection = async (): Promise<void> => {
    await dispatch(mailboxCollectionGetThunk({ getMailBoxCollectionHook }));
    await dispatch(globalMailboxStatusThunk({ getGlobalMailboxStatusHook }));
  };

  useEffect(() => {
    if (!(mailboxCollection && mailboxCollection.length)) {
      dispatchMailboxCollection();
    }
  }, [mailboxCollection]);

  const customFolders = mailboxCollection?.filter((mailbox) => !isGlobalFolder(mailbox.id, spamFolderId));

  const globalFolders = mailboxCollection?.filter(
    (mailbox) =>
      isGlobalFolder(mailbox.id, spamFolderId) &&
      !isDraftFolder(mailbox.id) &&
      !isSentMailFolder(mailbox.id) &&
      !isTrashFolder(mailbox.id)
  );

  const handleCreateChange = (e: React.ChangeEvent<KiteInputElems>): void => {
    updateFormValues(e.target.value, 'newFolder');
    validateFilterField('newFolder', { moveFolder: values.moveFolder, newFolder: e.target.value }, errors, setErrors);
  };

  return (
    <FilterSection>
      <div className={styles.fieldContainer}>
        <KiteSelect
          id={'filterActionListId'}
          className={styles.halfField}
          label={t('actionThenLabel')}
          name={'filterActionList'}
          value={values.filterAction}
          onChange={(e) => {
            updateFormValues(e.target.value, 'filterAction');
            validateFilterField('filterAction', { filterAction: e.target.value }, errors, setErrors);
          }}
          placeholder={t('actionChoiceDefault')}
          errorMessage={filterActionErrorMessage ? t(filterActionErrorMessage) : ''}
          aria-label={'condition, combo box, to change the selection use the arrow keys.'}
        >
          <option key={'thenMoveFolderId'} value={'move'}>
            Move Messages to Folder
          </option>
          <option key={'thenDeleteMessageId'} value={'delete'}>
            Delete Message
          </option>
        </KiteSelect>

        {values.filterAction === 'move' && (
          <div className={`${styles.destinationFolderContainer} ${styles.halfField}`}>
            <KiteSelect
              id={'destinationFolderListId'}
              label={t('actionDestinationLabel')}
              name={'destinationFolderList'}
              value={values.moveFolder || ''}
              onChange={(e) => {
                updateFormValues(e.target.value, 'moveFolder');
                validateFilterField(
                  'moveFolder',
                  { moveFolder: e.target.value, newFolder: values.newFolder },
                  errors,
                  setErrors
                );
              }}
              placeholder={t('actionChoiceDefault')}
              errorMessage={moveFolderErrorMessage ? t(moveFolderErrorMessage) : ''}
              aria-label={'Folder, combo box, to change the selection use the arrow keys.'}
            >
              {globalFolders?.map(({ id, name }) => (
                <option key={id} value={id}>
                  {t(
                    appConstants.GLOBAL_FOLDER_NAMES[
                      name as keyof GlobalFoldersInterface
                    ] as PageNSTranslationFileKeys<'filterRuleFormCopy'>
                  )}
                </option>
              ))}
              {customFolders?.map(({ id, name }) => (
                <option key={id} value={id}>
                  {transformNestedGlobalFolderPath(name, spamFolderId)}
                </option>
              ))}
              <option key={'createNewFolderOption'} value={'createNew'}>
                {t('optionCreateNewFolder')}
              </option>
            </KiteSelect>
            {loading && <KiteProgressIndicator id={'folders-select-loading'} useOverlay={true} size="md" />}

            {values.moveFolder === 'createNew' && (
              <KiteTextInput
                placeholder={t('folderName')}
                label={t('folderName')}
                onChange={(e) => handleCreateChange(e)}
                value={values.newFolder || ''}
                errorMessage={newFolderErrorMessage ? t(newFolderErrorMessage) : ''}
                aria-label={'new folder name, edit, type in text.'}
                ariaLive="polite"
              />
            )}
          </div>
        )}
      </div>
    </FilterSection>
  );
};
