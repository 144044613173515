import { KiteLink } from '@kite/react-kite';
import { ACCOUNT, LinkItemInterface, SUPPORT } from 'app-constants';
import styles from 'components/nav-support-links/styles/navSupportLinks.module.scss';
import concatUrl from 'utils/concatUrl';
import { TrackingHook, useTracking } from 'react-tracking';
import React from 'react';
import { usePageTranslation } from 'translation/hooks';

const NavSupportLinks: React.FC = () => {
  const { trackEvent }: TrackingHook = useTracking({ page: 'navigation', component: 'NavSupportLinks' });
  const { t } = usePageTranslation('linksCopy');
  const links = [ACCOUNT, SUPPORT];
  const linksList = links.map((link: LinkItemInterface) => {
    return (
      <React.Fragment key={link.uiName}>
        <KiteLink
          target="_blank"
          className={styles.navSupportLink}
          href={concatUrl(link.baseUrl, link.href)}
          onClick={() =>
            trackEvent({
              event: 'navSupportLink',
              linkObject: { ...link, uiName: t(link.uiName) },
            })
          }
          aria-label={link.label}
        >
          {t(link.uiName)}
        </KiteLink>
        <span key={`${link.uiName}-separator`} className={styles.verticalSeparator} />
      </React.Fragment>
    );
  });

  return <div className={styles.navSupportLinks}>{linksList}</div>;
};

export default NavSupportLinks;
