import { ValidationKeys } from 'translation/hooks';

export type ContactValidatorTransKeys = { invalid?: ValidationKeys; required?: ValidationKeys };

const typeTranslationKey = (translationKeys: ContactValidatorTransKeys): ContactValidatorTransKeys => translationKeys;

export const streetAddressTrans = typeTranslationKey({ invalid: 'invalidStreetAddress' });
export const cityTrans = typeTranslationKey({ invalid: 'invalidCity' });
export const stateTrans = typeTranslationKey({ invalid: 'invalidState' });
export const zipCodeTrans = typeTranslationKey({ invalid: 'invalidZip' });
export const countryTrans = typeTranslationKey({ invalid: 'invalidCountry' });
export const firstNameTrans = typeTranslationKey({ invalid: 'invalidFirstName', required: 'contactFirstNameReq' });
export const lastNameTrans = typeTranslationKey({ invalid: 'invalidLastName', required: 'contactLastNameReq' });
export const displayNameTrans = typeTranslationKey({
  invalid: 'invalidDisplayName',
  required: 'contactDisplayNameReq',
});
export const nickNameTrans = typeTranslationKey({ invalid: 'invalidNickname' });
export const titleTrans = typeTranslationKey({ invalid: 'invalidTitle' });
export const companyTrans = typeTranslationKey({ invalid: 'invalidCompany' });
export const phoneNumberTrans = typeTranslationKey({ invalid: 'invalidPhoneNumber' });
export const emailsTrans = typeTranslationKey({ invalid: 'invalidEmail', required: 'invalidEmail' });
export const dateOfBirthTrans = typeTranslationKey({ invalid: 'invalidDOB' });
export const notesTrans = typeTranslationKey({ invalid: 'notesLimit' });
export const urlTrans = typeTranslationKey({ invalid: 'invalidURL' });
