import { EmailAddressEntity } from 'generated/graphql';

const getDisplayNameOrAddress = ({ name, address }: EmailAddressEntity): string => {
  if (name && name.length > 0) {
    return name;
  }

  return address ?? '';
};

export default getDisplayNameOrAddress;
