import styles from 'components/setting-menu/styles/SettingMenu.module.scss';
import { KiteCard, KiteGridCell, KiteIcon, KiteLink, KiteMeter } from '@kite/react-kite';
import { appConstants } from 'appConstants';
import SectionTab from 'components/section-tab';
import tabStyles from 'components/section-tab/styles/SectionTab.module.scss';
import { useAppSelector } from 'hooks';
import { TrackingHook, useTracking } from 'react-tracking';
import { useEffect, useRef } from 'react';
import concatUrl from 'utils/concatUrl';
import { SUPPORT_LINK } from 'app-constants';
import { Scrollbar } from 'components/common/scrollbar';
import { PageNSTranslationFileKeys, usePageTranslation } from 'translation/hooks';

interface PreviewInterface {
  autoInsert: boolean;
  autoReply: boolean;
  forwarding: boolean;
}

const SettingMenu: React.FC = () => {
  const { t } = usePageTranslation('settingsTabsCopy');
  const { limit, percentageUsed, usage } = useAppSelector((state) => state.quotaSlice);
  const { trackEvent }: TrackingHook = useTracking({ page: 'settings' });
  const linkRef = useRef<HTMLAnchorElement>(null);

  const previews: PreviewInterface = useAppSelector((state) => ({
    autoInsert: state.signatureSlice.signatureEntity?.autoInsert as boolean,
    autoReply: state.autoReplySlice.autoReplyEntity.autoReplyMode === 'vacation',
    forwarding: state.settingSlice.isMailForwardingEnabled || false,
  }));

  useEffect(() => {
    if (linkRef) {
      linkRef.current?.focus();
    }
  }, []);

  const createTabs = (): JSX.Element[] => {
    return appConstants.SETTINGS_TABS.map(
      ({ href, preview, uiName, trackingEvent, autoFocus, pageViewInitEvent }, idx) => {
        return (
          <SectionTab
            key={`${idx}-${uiName}`}
            uiName={t(uiName as PageNSTranslationFileKeys<'settingsTabsCopy'>)}
            href={href}
            preview={preview && (t(`${previews[preview as keyof PreviewInterface] ? 'on' : 'off'}`) as string)}
            trackingEvent={trackingEvent}
            linkRef={autoFocus ? linkRef : null}
            autoFocus={autoFocus}
            pageViewInitEvent={pageViewInitEvent}
          />
        );
      }
    );
  };

  return (
    <KiteGridCell ariaLabel="settings navigation" className={styles.settingMenuCell} col={12} sm={12} md={12} lg={12}>
      <KiteCard className={styles.settingContainer}>
        <section className={styles.sectionItemList}>
          <Scrollbar customClass={styles.simpleBar} forceScroll={false}>
            <ul>
              {createTabs()}
              <li className={tabStyles.SectionTab}>
                <KiteLink
                  target="_blank"
                  className={tabStyles.SectionTabLink}
                  href={concatUrl(SUPPORT_LINK.baseUrl, SUPPORT_LINK.href)}
                  onClick={() => trackEvent({ event: 'supportLink' })}
                >
                  <span className={styles.supportLink}>
                    <KiteIcon icon="ki-linkout" className={styles.supportIcon} />
                    {t('supportPage')}
                  </span>
                </KiteLink>
              </li>
            </ul>
          </Scrollbar>
        </section>
        <div className={styles.inboxMeter}>
          <KiteMeter
            type="linear"
            size="small"
            label={t('storage')}
            status="neutral"
            value={percentageUsed}
            valueLabel={t('filled', { usage, limit })}
          />
        </div>
      </KiteCard>
    </KiteGridCell>
  );
};

export default SettingMenu;
