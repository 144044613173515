export const composeCopy = {
  defaultHeader: '',
  emailPlaceholder: '',
  to: '',
  cc: '',
  bcc: '',
  subject: '',
  subjectField: '',
  toField: '',
  ccField: '',
  bccField: '',
  message: '',
  attachFiles: '',
  send: '',
  saveDraft: '',
  discard: '',
  markAsPriority: '',
  requestReadReceipt: '',
  removeAsPriority: '',
  textEditor: '',
  signature: '',
  removeReadReceipt: '',
  draftSuccess: '',
  saving: '',
  sending: '',
  readReceipt: '',
  priorityAria: '',
  messageSent: '',
};
