export const contactFormCopy = {
  editHeader: '',
  editDesc: '',
  newHeader: '',
  newDesc: '',
  backBtn: '',
  newShowMore: '',
  firstName: '',
  lastName: '',
  displayName: '',
  nickname: '',
  title: '',
  company: '',
  email: '',
  emailReq: '',
  addEmail: '',
  homePhone: '',
  mobilePhone: '',
  workPhone: '',
  homeAddress: '',
  workAddress: '',
  phoneNumber: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  dateOfBirth: '',
  url: '',
  note: '',
  urlPlaceholder: '',
  dateOfBirthPlaceholder: '',
  addressPlaceholder: '',
  statePlaceholder: '',
  zipCodePlaceholder: '',
  phonePlaceholder: '',
  emailPlaceholder: '',
  companyPlaceholder: '',
  titlePlaceholder: '',
  firstNamePlaceholder: '',
  lastNamePlaceholder: '',
  displayNamePlaceholder: '',
  createdSuccessToast: '',
  updatedSuccessToast: '',
  removeEmailAria: '',
};
