import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MailForm, SaveDraftMutationFn } from 'generated/graphql';
import { RootState } from 'store';
import { updateIsSavePending } from 'slices/compose';

interface saveDraftThunkArgs {
  payload: {
    index: string;
    mail: MailForm;
    displayName: string;
    isSelectedFolderDraft: boolean;
    isAutoSave?: boolean;
  };
  saveDraftHook: SaveDraftMutationFn;
}

interface saveDraftThunkResponse {
  success?: boolean;
  error?: ApolloError;
  draftUid?: number;
  index: string;
  isSelectedFolderDraft: boolean;
  isAutoSave?: boolean;
}

export const saveDraftThunk = createAsyncThunk(
  'saveDraftThunk',
  async (arg: saveDraftThunkArgs, { dispatch }): Promise<saveDraftThunkResponse> => {
    try {
      dispatch(updateIsSavePending({ isPending: true, index: arg.payload.index }));
      const response = await arg.saveDraftHook({
        variables: { ...arg.payload },
      });
      dispatch(updateIsSavePending({ isPending: false, index: arg.payload.index }));
      return {
        index: arg.payload.index,
        success: response.data?.saveDraft.isSavedToImap || false,
        draftUid: response.data?.saveDraft.uid as number,
        isSelectedFolderDraft: arg.payload.isSelectedFolderDraft,
        isAutoSave: arg.payload.isAutoSave,
      };
    } catch (e) {
      dispatch(updateIsSavePending({ isPending: false, index: arg.payload.index }));
      return {
        index: arg.payload.index,
        error: e as ApolloError,
        isSelectedFolderDraft: arg.payload.isSelectedFolderDraft,
        isAutoSave: arg.payload.isAutoSave,
      };
    }
  },
  {
    condition: (arg, { getState }) => {
      const state = <RootState>getState();
      const { composeCollection, activeTabIndex } = state.composeSlice;
      if (composeCollection[activeTabIndex] && composeCollection[activeTabIndex].isSavePending) {
        return false;
      }
      return true;
    },
  }
);
