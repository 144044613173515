import { useEffect, useState } from 'react';
import { ContactEntity, LabelEntity } from 'generated/graphql';
import { Tag } from 'react-tag-autocomplete';
import { EmailAddress } from 'models/Email';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateContactsCollection } from 'slices/contact/autocomplete';

export interface LabelWithContacts extends LabelEntity {
  contacts: ContactEntity[];
}

type UseContactSearchInterface = (
  existingContacts?: EmailAddress[]
) => [Tag[] | undefined, ContactEntity[] | undefined, LabelWithContacts[] | undefined, (query: string) => void];

const useContactSearch: UseContactSearchInterface = (existingContacts) => {
  const [contacts, setContacts] = useState<Array<ContactEntity>>();
  const [labels, setLabels] = useState<Array<LabelWithContacts>>();
  const [suggestions, setSuggestions] = useState<Tag[]>();

  const contactsCollection = useAppSelector((state) => state.contactAutocompleteSlice.contacts) || [];
  const labelsCollection = useAppSelector((state) => state.contactAutocompleteSlice.labels) || [];
  const reloadContactsCollection = useAppSelector((state) => state.contactAutocompleteSlice.reloadContactsCollection);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (contactsCollection.length || reloadContactsCollection) {
      setContacts(contactsCollection);
      dispatch(updateContactsCollection(false));
    }
    const labelsWithContacts = labelsCollection.map((label) => ({
      ...label,
      contacts: contactsCollection.filter((contact) => contact.labels?.includes(label.id) && contact.emails?.length),
    }));

    setLabels(labelsWithContacts);
  }, [contactsCollection.length, labelsCollection.length, reloadContactsCollection]);

  const clearSearchResults = (): void => {
    setSuggestions([]);
  };

  const onContactSearch = (query: string): void => {
    clearSearchResults();

    const labelTags: Tag[] = labelsCollection
      // matches query
      .filter((label) => label.name.toLowerCase().includes(query.toLowerCase()))
      // contains at least one member
      .filter((label) => contactsCollection?.find((contact) => contact.labels?.includes(label.id)))
      // contains at least one member that is not already a tag
      .filter((label) => {
        const labelWithContacts = labels?.find((l) => label.id === l.id);
        const labelWithNewContacts = labelWithContacts?.contacts.filter(
          (contact) =>
            !existingContacts?.find((tag) => contact.emails?.find((email) => email.emailAddress === tag.address))
        );
        return labelWithNewContacts?.length;
      })
      .map((label) => ({ id: label.id, name: label.name }));

    const contactTags: Tag[] = contactsCollection
      // matches query
      .filter(
        (contact) =>
          `${contact.firstName} ${contact.lastName}`.toLowerCase().includes(query.toLowerCase()) ||
          contact.displayName?.toLowerCase().includes(query.toLowerCase()) ||
          contact.emails?.find((email) => email.emailAddress.toLowerCase().includes(query.toLowerCase()))
      )
      // flatten out emails as individual suggestions
      .flatMap((contact) =>
        contact.emails && contact.emails.length
          ? contact.emails.map((email) => ({ id: contact.id, name: email.emailAddress }))
          : []
      )
      // email is not already a tag
      .filter((contact) => !existingContacts?.find((tag) => tag.address === contact.name));

    const filteredLabelsAndContacts = labelTags.concat(contactTags).slice(0, 5);

    setSuggestions(filteredLabelsAndContacts);
    return;
  };

  return [suggestions, contacts, labels, onContactSearch];
};

export default useContactSearch;
