import getConfig from 'libs/get.config';

export const getSignatureMaxSize = (): number =>
  getConfig({ configName: 'app.SIGNATURE_MAX_SIZE', defaultValue: 2227000 });
export const getWebmail2URL = (): string => getConfig({ configName: 'app.WEBMAIL_2_URL', isRequired: true });
export const getAppEnvironment = (): string => getConfig({ configName: 'environment', defaultValue: 'prod' });
export const getIsCanaryCompleted = (): boolean =>
  getConfig({ configName: 'app.IS_CANARY_COMPLETED', defaultValue: false });
export const isWebmail2MigrationEnabled = (): string =>
  getConfig({ configName: 'app.WEBMAIL2_MIGRATION_ENABLED', defaultValue: 'yes' });
export const getDraggablePanesEnabled = (): boolean =>
  getConfig({ configName: 'app.DRAGGABLE_PANES_ENABLED', defaultValue: false });
