import { useLocation } from 'react-router-dom';
import SettingMenu from 'components/setting-menu';
import SettingViewCell from 'components/setting-menu/SettingViewCell';

const SettingContainerMobile: React.FC = () => {
  const { pathname } = useLocation();
  const isSettingsRoot = pathname === '/user/setting';
  const isSettingsFilterForm = pathname.startsWith('/user/setting/filter') && pathname.split('/').length >= 5;

  if (isSettingsRoot) {
    return <SettingMenu />;
  }

  return <SettingViewCell showBackButton={!isSettingsFilterForm} />;
};

export default SettingContainerMobile;
