import { KiteAlert, KiteButton, KiteCard, KiteLink, KiteTextInput } from '@kite/react-kite';
import styles from 'components/setting-account-info/styles/AccountInfo.module.scss';
import SaveCancel from 'components/save-cancel';
import {
  useContactMigrationMutation,
  useContactRollbackMutation,
  useSettingMigrationMutation,
  useSettingRollbackMutation,
  useUpdateDisplayNameMutation,
} from 'generated/graphql';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useEffect, useState } from 'react';
import { TrackingHook, useTracking } from 'react-tracking';
import { getLoggedInEmail } from 'utils/accessTokenLocalStorage';
import { isValidDisplayName } from 'utils/displayNameHelper';
import { resetApiError } from 'slices/setting/setting';
import useNavBlockingDraft from 'hooks/useNavBlockingDraft';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import { updateAutoFocus } from 'slices/app';
import { updateNavBlocking } from 'slices/app';
import { updateDisplayNameThunk } from 'thunks/setting/updateDisplayNameThunk';
import { contactMigrationThunk } from 'thunks/migration/contactMigrationThunk';
import { settingMigrationThunk } from 'thunks/migration/settingMigrationThunk';
import { contactRollbackThunk } from 'thunks/rollback/contactRollbackThunk';
import { settingRollbackThunk } from 'thunks/rollback/settingRollbackThunk';
import { KiteInputElems } from 'libs/kite/types';
import { getCHTRPasswordChangeLink } from 'app-constants';
import { ValidationKeys, usePageTranslation } from 'translation/hooks';

const SettingAccountInfo: React.FC = () => {
  const { t } = usePageTranslation('accountInfoCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'settings' });

  const { apiError, displayName } = useAppSelector((state) => state.settingSlice);
  const [inputError, setInputError] = useState<ValidationKeys | ''>('');
  const [draft, setDraft] = useNavBlockingDraft(displayName);
  const [headerContent, setHeaderContent] = useState<string>(t('displayNameDefault'));
  const autoFocus = useAppSelector((state) => state.appSlice.autoFocus);

  const [updateDisplayNameHook] = useUpdateDisplayNameMutation();
  const dispatch = useAppDispatch();

  const [contactMigrationHook] = useContactMigrationMutation();
  const [settingMigrationHook] = useSettingMigrationMutation();
  const [settingRollbackHook] = useSettingRollbackMutation();
  const [contactRollbackHook] = useContactRollbackMutation();

  useEffect(() => {
    trackEvent({ event: 'accountInfoPageView' });

    return () => {
      dispatch(updateAutoFocus(false));
    };
  }, []);

  useEffect(() => {
    setDraft(displayName);
    setHeaderContent(displayName || t('displayNameDefault'));
  }, [displayName]);

  const email = getLoggedInEmail();

  const passwordChangeUrl = getCHTRPasswordChangeLink();

  const handleDraftChange = (e: React.ChangeEvent<KiteInputElems>): void => {
    setDraft(e.target.value);
    isValid(e.target.value);
  };

  const isDisplayNameChanged = (): boolean => {
    return draft !== displayName;
  };

  const isValid = (value: string): void => {
    setInputError('');
    if (!isValidDisplayName(value)) {
      trackEvent({ event: 'accountInfoError' });
      setInputError('alphaNumeric');
    }
  };

  const handleSave = async (): Promise<boolean> => {
    trackEvent({ event: 'accountInfoDisplayNameSave' });
    const result = await dispatch(
      updateDisplayNameThunk({
        payload: {
          displayName: draft,
        },
        updateDisplayNameHook,
      })
    );
    // @ts-ignore
    if (!result.payload.error) {
      dispatch(updateNavBlocking(false));
      trackEvent({ event: 'accountInfoSaveSuccess' });
      return true;
    }

    trackEvent({ event: 'accountInfoError' });
    return false;
  };

  const handleCancel = (): boolean => {
    trackEvent({ event: 'accountInfoDisplayNameCancel' });
    setDraft(displayName);
    setInputError('');
    dispatch(resetApiError());
    return true;
  };

  const handleContactMigration = (): void => {
    dispatch(contactMigrationThunk({ contactMigrationHook }));
  };

  const handleSettingMigration = (): void => {
    dispatch(settingMigrationThunk({ settingMigrationHook }));
  };

  const handleContactRollback = (): void => {
    dispatch(contactRollbackThunk({ contactRollbackHook }));
  };

  const handleSettingRollback = (): void => {
    dispatch(settingRollbackThunk({ settingRollbackHook }));
  };

  return (
    <div className={styles.displayNameContainer}>
      <KiteCard className={styles.displayNameCard}>
        {autoFocus ? (
          <AutoFocusHeader content={headerContent} />
        ) : (
          <div className={styles.title}>
            <h2 className="kite-type-style--title-6">{headerContent}</h2>
          </div>
        )}
        <span className={styles.displayNameDescription}>{t('displayNameDescription')}</span>
        {apiError && <KiteAlert className={styles.alert} type="error" description={t(apiError)} level="page" />}
        <div className={styles.displayNameInput}>
          <KiteTextInput
            className={styles.inputText}
            type="text"
            label={t('displayNameLabel')}
            autoComplete="off"
            value={draft}
            errorMessage={t(inputError)}
            onChange={handleDraftChange}
            ariaLive="polite"
          />
        </div>

        <SaveCancel
          draft={draft}
          isChanged={isDisplayNameChanged}
          isValidate={() => !inputError}
          isCancelled={handleCancel}
          isSaved={handleSave}
          shouldDisableCancel={true}
        />
      </KiteCard>
      <KiteCard className={styles.displayNameCard}>
        <div className={styles.title}>
          <h2 className="kite-type-style--title-6">{t('passwordTitle')}</h2>
        </div>
        <span className={styles.displayNameDescription}>{t('passwordDescription')}</span>
        <KiteLink
          className={styles.changePasswordLink}
          type="standalone"
          href={passwordChangeUrl as string}
          onClick={() => {
            trackEvent({
              event: 'accountInfoChangeEmailPassword',
            });
          }}
        >
          {t('passwordLinkText')}
        </KiteLink>
      </KiteCard>
      <KiteCard className={styles.displayNameCard}>
        <div className={styles.title}>
          <h2 className="kite-type-style--title-6">{t('emailAddressTitle')}</h2>
        </div>
        <span className={styles.displayNameDescription}>{t('emailDescription')}</span>
        <p className={styles.email}>{email}</p>
      </KiteCard>
      <KiteCard className={`${styles.displayNameCard} ${styles.hidden}`}>
        <div className={styles.title}>
          <h2 className="kite-type-style--title-6">Migration Tools</h2>
        </div>
        <div className={styles.buttonsGroup}>
          <KiteButton
            className={styles.buttons}
            onClick={handleContactMigration}
            size={'sm'}
            variant={'primary'}
            legacy={true}
          >
            {t('migrateContacts')}
          </KiteButton>

          <KiteButton onClick={handleSettingMigration} variant={'secondary'} legacy={true}>
            {t('migrateSettings')}
          </KiteButton>
        </div>
      </KiteCard>

      <KiteCard className={`${styles.displayNameCard} ${styles.hidden}`}>
        <div className={styles.title}>
          <h2 className="kite-type-style--title-6">Rollback Tools</h2>
        </div>
        <div className={styles.buttonsGroup}>
          <KiteButton
            className={styles.buttons}
            onClick={handleContactRollback}
            size={'sm'}
            variant={'primary'}
            legacy={true}
          >
            {t('rollbackContacts')}
          </KiteButton>

          <KiteButton onClick={handleSettingRollback} variant={'secondary'} legacy={true}>
            {t('rollbackSettings')}
          </KiteButton>
        </div>
      </KiteCard>
    </div>
  );
};

export default SettingAccountInfo;
