import ContactList from 'components/contact-list';
import styles from 'containers/styles/ContactViewContainer.module.scss';

const ContactViewContainer: React.FC = () => {
  return (
    <div className={styles.contactViewContainer}>
      <div className={styles.contactCardContainer}>
        <div className={styles.contactViewCard}>
          <ContactList />
        </div>
      </div>
    </div>
  );
};

export default ContactViewContainer;
