import { ApolloLink, createHttpLink } from '@apollo/client';
import { getServerEndpoint } from 'app-constants';

const httpLink = (): ApolloLink =>
  createHttpLink({
    uri: `${getServerEndpoint()}/graphql`,
    credentials: 'include',
  });

export default httpLink;
