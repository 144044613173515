export const filterRuleFormCopy = {
  newRuleTitle: '',
  emailOrKeywordRequired: '',
  newRuleDescription: '',
  editRuleTitle: '',
  editRuleDescription: '',
  ruleNameLabel: '',
  ruleNamePlaceholder: '',
  createdToast: '',
  updatedToast: '',
  deletedToast: '',
  conditionTitle: '',
  conditionIfLabel: '',
  conditionContainsLabel: '',
  conditionEmailKeywordLabel: '',
  conditionEmailKeywordPlaceholder: '',
  conditionAddBtn: '',
  optionEmailFrom: '',
  optionEmailTo: '',
  optionEmailCC: '',
  optionEmailRecipients: '',
  optionEmailSubjectLines: '',
  optionContains: '',
  optionDoesNotContain: '',
  optionMoveMessages: '',
  optionDeleteMessages: '',
  optionCreateNewFolder: '',
  conditionAddDescription: '',
  conditionPriority: '',
  conditionIsNot: '',
  conditionIs: '',
  conditionPriorityMarkedAs: '',
  matchingTitle: '',
  matchingAllLabel: '',
  matchingAllDescription: '',
  matchingAnyLabel: '',
  matchingAnyDescription: '',
  actionThenLabel: '',
  actionDestinationLabel: '',
  folderName: '',
  actionChoiceDefault: '',
  conditionComboAria: '',
  conditionEmailAria: '',
  conditionContainAria: '',
};
