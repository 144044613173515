import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MailSearchQueryForm, MessageCollection } from 'generated/graphql';

interface MessageCollectionResponse {
  success?: MessageCollection;
  error?: ApolloError | undefined;
  mailboxId?: string;
  search?: string;
}

interface MessageCollectionArg {
  payload: {
    mailboxId: string;
    query?: MailSearchQueryForm;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMessageCollectionHook?: any;
}

export const getMessageCollectionThunk = createAsyncThunk(
  'messageCollection',
  async (arg: MessageCollectionArg): Promise<MessageCollectionResponse> => {
    try {
      const response = await arg.getMessageCollectionHook({
        variables: {
          mailboxId: arg.payload.mailboxId,
          query: { ...arg.payload.query },
        },
      });

      return {
        success: response.data.getMessageCollection,
        mailboxId: arg.payload.mailboxId,
        search: arg.payload.query?.search || '',
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
