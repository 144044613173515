import { RefObject, forwardRef } from 'react';
import ContactSection from 'components/contact-form/sections/ContactSection';
import { KiteIcon, KiteTextInput } from '@kite/react-kite';
import { ContactFormInterface, ContactSectionProps } from 'components/contact-form/initialState';
import styles from 'components/contact-form/styles/ContactCreateContact.module.scss';
import validateContactField, { getContactValidationErrorMessage } from 'utils/validateContactField';
import { KiteInputElems } from 'libs/kite/types';
import { usePageTranslation } from 'translation/hooks';
import { firstNameTrans, lastNameTrans } from './translationKeys';

type NameFields = Pick<ContactFormInterface, 'firstName' | 'lastName'>;
type Props = ContactSectionProps<NameFields>;

type HandleNameChange = (e: React.ChangeEvent<KiteInputElems>, formKey: keyof NameFields) => void;

export const Name = forwardRef<HTMLInputElement, Props>(({ updateFormValues, values, errors, setErrors }, ref) => {
  const { t } = usePageTranslation('contactFormCopy');

  const firstNameErrorMessage = getContactValidationErrorMessage(errors, 'firstName', firstNameTrans);
  const lastNameErrorMessage = getContactValidationErrorMessage(errors, 'lastName', lastNameTrans);

  const validateField = (formKey: keyof NameFields, value: string): void => {
    validateContactField(formKey, { [formKey]: value }, errors, setErrors);
  };

  const handleNameChange: HandleNameChange = (e, formKey) => {
    updateFormValues(e.target.value, formKey);
    validateField(formKey, e.target.value);
  };

  return (
    <ContactSection>
      <KiteIcon className={styles.fieldIcon} icon="ki-person" />
      <div className={styles.fieldContainer}>
        <KiteTextInput
          className={styles.halfField}
          placeholder={t('firstNamePlaceholder')}
          label={t('firstName')}
          onChange={(e) => handleNameChange(e, 'firstName')}
          onBlur={(e) => validateField('firstName', e.target.value)}
          value={values.firstName || ''}
          errorMessage={firstNameErrorMessage ? t(firstNameErrorMessage) : ''}
          aria-required={true}
          aria-label={'enter first name'}
          inputRef={ref as RefObject<HTMLInputElement>}
          ariaLive="polite"
        />

        <KiteTextInput
          className={styles.halfField}
          placeholder={t('lastNamePlaceholder')}
          label={t('lastName')}
          onChange={(e) => handleNameChange(e, 'lastName')}
          onBlur={(e) => validateField('lastName', e.target.value)}
          value={values.lastName || ''}
          errorMessage={lastNameErrorMessage ? t(lastNameErrorMessage) : ''}
          aria-required={true}
          aria-label={'enter last name'}
          ariaLive="polite"
        />
      </div>
    </ContactSection>
  );
});
