import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteMessageMutationFn } from 'generated/graphql';

interface permanentDeleteMessageResponse {
  success?: boolean;
  error?: ApolloError | undefined;
  messageUid: number;
  mailboxId?: string;
}

interface PermanentDeleteMessageArgs {
  payload: {
    mailboxId: string;
    messageUid: number;
  };
  permanentDeleteMessageHook: DeleteMessageMutationFn;
}

export const permanentDeleteMessageThunk = createAsyncThunk(
  'permanentDeleteMessage',
  async (arg: PermanentDeleteMessageArgs): Promise<permanentDeleteMessageResponse> => {
    try {
      const response = await arg.permanentDeleteMessageHook({
        variables: {
          mailboxId: arg.payload.mailboxId,
          messageUid: arg.payload.messageUid,
        },
      });
      return {
        success: response?.data?.deleteMessage.success || false,
        messageUid: arg.payload.messageUid,
        mailboxId: arg.payload.mailboxId,
      };
    } catch (e) {
      return {
        messageUid: arg.payload.messageUid,
        error: e as ApolloError,
      };
    }
  }
);
