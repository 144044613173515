export const accountInfoCopy = {
  displayNameDefault: '',
  displayNameDescription: '',
  displayNameLabel: '',
  passwordTitle: '',
  passwordDescription: '',
  passwordLinkText: '',
  emailAddressTitle: '',
  emailDescription: '',
  migrateContacts: '',
  migrateSettings: '',
  rollbackContacts: '',
  rollbackSettings: '',
};
