import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import styles from 'components/layout/styles/Layout.module.scss';
import ListViewContainer from 'containers/ListViewContainer';
import EmailView from 'components/email-view';
import Compose from 'components/compose';
import { KiteButton, KiteIcon } from '@kite/react-kite';
import { TrackingHook, useTracking } from 'react-tracking';
import { useAppDispatch, useAppSelector } from 'hooks';
import { addTabCollection, updateMinimizedIndex } from 'slices/compose';
import EmailViewPlaceholder from 'components/email-view/EmailViewPlaceHolder';
import MessageListOptions from 'components/message-options/MessageListOptions';
import EmailOptions from 'components/message-options/EmailOptions';
import EmailHeader from 'components/header/sub-headers/EmailHeader';

const MobileLayout: React.FC = () => {
  const { trackEvent }: TrackingHook = useTracking({ page: 'compose' });

  const isComposeOpen = useAppSelector(
    (state) => !!state.composeSlice.activeTabIndex && !state.composeSlice.activeMinimizedIndex
  );
  const isAutoInsertSignature = useAppSelector((state) => state.signatureSlice.signatureEntity?.autoInsert);
  const signature = useAppSelector((state) => state.signatureSlice.signatureEntity?.signature) || '';
  const messageLoading = useAppSelector((state) => state.messageSlice.messageLoading);
  const mailFetchError = useAppSelector((state) => state.messageSlice.mailFetchError);

  const dispatch = useAppDispatch();
  const location = useLocation();

  const showListView: boolean = location.pathname.split('/').length < 5;

  const handleOpenCompose = (): void => {
    trackEvent({ event: 'openCompose' });
    dispatch(addTabCollection(isAutoInsertSignature ? signature : null));
    dispatch(updateMinimizedIndex(''));
  };

  return (
    <>
      <div className={showListView ? styles.layoutContainerWithSearch : styles.layoutContainer}>
        <div className={styles.contentLayout}>
          {!showListView && (messageLoading || mailFetchError) && (
            <div className={styles.fixed}>
              <EmailViewPlaceholder />
            </div>
          )}
          <div className={`${!showListView ? styles.hideMe : ''}`}>
            <EmailHeader />
            <MessageListOptions />
            <ListViewContainer />
          </div>
          <Routes>
            <Route
              path={'/:mailId'}
              element={
                <>
                  <EmailOptions />
                  <EmailView alwaysRender={true} />
                </>
              }
            />
          </Routes>
        </div>
        <Compose />
      </div>
      <KiteButton
        type="button"
        tabIndex={-1}
        className={`${styles.composeButton} ${isComposeOpen ? styles.hideMe : ''}`}
        onClick={handleOpenCompose}
      >
        <KiteIcon icon={'ki-compose'} size="1em" />
      </KiteButton>
    </>
  );
};

export default MobileLayout;
