import { createSlice } from '@reduxjs/toolkit';
import { updateSpamSettingThunk } from 'thunks/setting/updateSpamSetting';
import { getLdapSettingThunk } from 'thunks/setting/getLdapSettingThunk';

interface SpamState {
  spam?: string;
  apiError?: Error | null;
}

const initialState: SpamState = {};

export const spamSlice = createSlice({
  name: 'spam',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLdapSettingThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.spam = action.payload.success.mailUserPreferences.asfDeliveryActionDirty;
      }
    });

    builder.addCase(updateSpamSettingThunk.fulfilled, (state, action) => {
      if (action.payload.spam) {
        state.spam = action.payload.spam;
      }

      if (action.payload.error) {
        state.apiError = action.payload.error;
        return;
      }
    });
  },
});

export default spamSlice.reducer;
