import Helix from '@charter/helix/build/portals/index.min';
import { HelixEventFn, HelixPayloadInterface } from 'libs/tracking/adapters/helix/helixAdapter';

const submitUnsavedChangesEvent: HelixEventFn<HelixPayloadInterface> = (payload) => {
  const { event } = payload;
  switch (event) {
    case 'updateContactUnsavedChangesModalView': {
      Helix.track('WEBMAIL_modalView_editContact_unsavedChanges');
      break;
    }
    case 'updateContactUnsavedChangesOk': {
      Helix.track('WEBMAIL_selectAction_editContact_unsavedChangesModal_okay');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'updateContactUnsavedChangesCancel': {
      Helix.track('WEBMAIL_selectAction_editContact_unsavedChangesModalCancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }

    default: {
      console.log('Event Case For Unsaved Changes Modal Not Found', event);
      return null;
    }
  }
  return null;
};

export default submitUnsavedChangesEvent;
