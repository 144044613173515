import { useLocation, useSearchParams } from 'react-router-dom';

type UseURLQueryBuilderInterface = () => [URLSearchParams, (queryParamKey: string, queryParamValue: string) => void];

const useURLQueryBuilder: UseURLQueryBuilderInterface = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [searchParams, _setSearchParams] = useSearchParams(query);

  const setSearchParams = (queryParamKey: string, queryParamValue: string): void => {
    if (query.has(queryParamKey)) {
      query.delete(queryParamKey);
    }

    if (queryParamValue.length > 0) {
      query.append(queryParamKey, queryParamValue);
    }

    _setSearchParams(query);
  };

  return [searchParams, setSearchParams];
};

export default useURLQueryBuilder;
