export const newFolderCopy = {
  folderHeader: 'New Folder',
  subfolderHeader: 'New Subfolder',
  description: 'Folder names are limited to 63 characters or less and are sorted alphabetically.',
  subfolderLabel: 'Subfolder Name',
  folderLabel: 'Folder Name',
  folderPlaceholder: 'Enter Folder Name',
  subfolderPlaceholder: 'Enter Subfolder Name',
  confirmBtnText: 'Create',
  successToast: 'Folder Created',
};
