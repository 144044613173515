export const contactActionsCopy = {
  sendEmail: '',
  assignLabels: '',
  export: '',
  print: '',
  edit: '',
  delete: '',
  selected: '',
  select: '',
  import: '',
  exportSuccessToast: '',
  otherActions: '',
  sync: '',
};
