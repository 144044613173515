import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContactForm, ContactWithLabelEntity, UpdateContactMutationFn } from 'generated/graphql';

interface UpdateContactThunkArg {
  payload: {
    id: string;
    contact: ContactForm;
  };
  updateContactHook: UpdateContactMutationFn;
}

interface UpdateContactThunkResponse {
  success?: ContactWithLabelEntity;
  error?: ApolloError;
  id: string;
}

export const updateContactThunk = createAsyncThunk(
  'updateContactThunk',
  async (arg: UpdateContactThunkArg): Promise<UpdateContactThunkResponse> => {
    try {
      const response = await arg.updateContactHook({
        variables: arg.payload,
      });

      return {
        success: response?.data?.updateContact as ContactWithLabelEntity,
        id: arg.payload.id,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
        id: arg.payload.id,
      };
    }
  }
);
