import { KiteButton, KiteIcon } from '@kite/react-kite';
import styles from 'components/common/styles/ServerError.module.scss';
import { useSharedTranslation } from 'translation/hooks';

interface ServerErrorInterface {
  retryFunction?: () => void;
}

const ServerError: React.FC<ServerErrorInterface> = ({ retryFunction }) => {
  const { t } = useSharedTranslation();

  return (
    <div className={styles.card}>
      <KiteIcon icon={'ki-caution-circle-f'} size="60px" fill="red" />
      <h2 className="kite-type-style--title-6">{t('serverErr')}</h2>
      <p>{t('fetchErr')}</p>
      <p>
        {t('please') + ' '}
        <span>
          <KiteButton variant={'borderless'} className={styles.tryAgain} onClick={retryFunction}>
            {t('tryAgain')}
          </KiteButton>
        </span>
      </p>
    </div>
  );
};

export default ServerError;
