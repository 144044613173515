import Helix from '@charter/helix/build/portals/index.min';
import { HelixEventFn, HelixPayloadInterface } from 'libs/tracking/adapters/helix/helixAdapter';

interface HelixFolderPayloadInterface extends HelixPayloadInterface {
  error?: Error;
}

const submitFolderEvents: HelixEventFn<HelixFolderPayloadInterface> = ({ event, error }) => {
  switch (event) {
    case 'folderClick': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_folderClick');
      Helix.track('WEBMAIL_pageViewInit_email_folder', {
        currPageUrl: '/user/mail/:userFolderID',
      });
      break;
    }
    case 'subFolderClick': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_subFolderClick');
      Helix.track('WEBMAIL_pageViewInit_email_subFolder', {
        currPageUrl: '/user/mail/:userSubFolderID',
      });
      break;
    }
    case 'folderKebabToggle': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_folderThreeDotMenu');
      break;
    }
    case 'newFolderSave': {
      Helix.track('WEBMAIL_selectAction_newFolderCreate');
      break;
    }
    case 'newSubFolderSave': {
      Helix.track('WEBMAIL_selectAction_newSubFolderCreate');
      break;
    }
    case 'newFolderCancel': {
      Helix.track('WEBMAIL_selectAction_newFolderCancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'newSubFolderCancel': {
      Helix.track('WEBMAIL_selectAction_newSubFolderCancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'viewNewFolder': {
      Helix.track('WEBMAIL_modalView_newFolder');
      break;
    }
    case 'viewNewSubFolder': {
      Helix.track('WEBMAIL_modalView_newSubFolder');
      break;
    }
    case 'newFolderErrorApi': {
      Helix.track('WEBMAIL_error_newFolderError', { appErrorMessage: 'unableToSave' });
      break;
    }
    case 'newSubFolderErrorApi': {
      Helix.track('WEBMAIL_error_newSubFolderError', { appErrorMessage: 'unableToSave' });
      break;
    }
    case 'newFolderErrorValidation': {
      Helix.track('WEBMAIL_error_newFolderError', { appErrorMessage: 'validationError' });
      break;
    }
    case 'newFolderErrorDuplicate': {
      Helix.track('WEBMAIL_error_newFolderError', { appErrorMessage: 'folderNameAlreadyInUse' });
      break;
    }
    case 'newFolderSuccess': {
      Helix.track('WEBMAIL_applicationActivity_newFolderSuccess');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'newSubFolderSuccess': {
      Helix.track('WEBMAIL_applicationActivity_newSubFolderSuccess');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'kebabButtonClick': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_folderThreeDotMenu');
      break;
    }
    case 'newFolderClick': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_addNewFolder');
      break;
    }
    case 'newSubFolderClick': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_folders_newSubFolder');
      break;
    }
    case 'renameSubFolderClick': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_folders_renameSubFolder');
      break;
    }
    case 'deleteSubFolderClick': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_folders_deleteSubFolder');
      break;
    }
    case 'renameFolderUpdate': {
      Helix.track('WEBMAIL_selectAction_renameFolderUpdate');
      break;
    }
    case 'renameFolderCancel': {
      Helix.track('WEBMAIL_selectAction_renameFolderCancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'renameFolderView': {
      Helix.track('WEBMAIL_modalView_renameFolder');
      break;
    }
    case 'renameFolderSuccess': {
      Helix.track('WEBMAIL_applicationActivity_renameFolderSuccess');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'deleteFolderErrorApi': {
      Helix.track('WEBMAIL_error_newFolderError', { appErrorMessage: 'unableToSave' });
      break;
    }
    case 'deleteSubFolderErrorApi': {
      Helix.track('WEBMAIL_error_deleteSubFolderError', { appErrorMessage: 'unableToSave' });
      break;
    }
    case 'deleteFolderErrorValidation': {
      Helix.track('WEBMAIL_error_newFolderError', { appErrorMessage: 'validationError' });
      break;
    }
    case 'deleteFolderErrorDuplicate': {
      Helix.track('WEBMAIL_error_newFolderError', { appErrorMessage: 'folderNameAlreadyInUse' });
      break;
    }
    case 'renameSubFolderUpdate': {
      Helix.track('WEBMAIL_selectAction_renameSubFolderUpdate');
      break;
    }
    case 'renameSubFolderCancel': {
      Helix.track('WEBMAIL_selectAction_renameSubFolderCancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'renameSubFolderView': {
      Helix.track('WEBMAIL_modalView_renameSubFolder');
      break;
    }
    case 'renameSubFolderError': {
      Helix.track('WEBMAIL_error_renameSubFolderError', { type: error?.name, errorMessage: error?.message });
      break;
    }
    case 'renameSubFolderSuccess': {
      Helix.track('WEBMAIL_applicationActivity_renameSubFolderSuccess');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'deleteFolderClick': {
      Helix.track('WEBMAIL_selectAction_deleteFolder');
      break;
    }
    case 'deleteFolderCancel': {
      Helix.track('WEBMAIL_selectAction_deleteFolderCancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'deleteFolderView': {
      Helix.track('WEBMAIL_modalView_deleteFolder');
      break;
    }
    case 'renameFolderErrorApi': {
      Helix.track('WEBMAIL_error_newFolderError', { appErrorMessage: 'unableToSave' });
      break;
    }
    case 'renameSubFolderErrorApi': {
      Helix.track('WEBMAIL_error_renameSubFolderError', { appErrorMessage: 'unableToSave' });
      break;
    }
    case 'deleteFolderSuccess': {
      Helix.track('WEBMAIL_applicationActivity_deleteFolderSuccess');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'deleteSubFolder': {
      Helix.track('WEBMAIL_selectAction_deleteSubFolder');
      break;
    }
    case 'deleteSubFolderCancel': {
      Helix.track('WEBMAIL_selectAction_deleteSubFolderCancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'deleteSubFolderView': {
      Helix.track('WEBMAIL_modalView_deleteSubFolder');
      break;
    }
    case 'deleteSubFolderError': {
      Helix.track('WEBMAIL_error_deleteSubFolderError', { type: error?.name, errorMessage: error?.message });
      break;
    }
    case 'deleteSubFolderSuccess': {
      Helix.track('WEBMAIL_applicationActivity_deleteSubFolderSuccess');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'emptyTrash': {
      Helix.track('WEBMAIL_selectAction_trash_emptyTrash');
      Helix.track('WEBMAIL_modalView_emptyTrash');
      break;
    }
    case 'emptyTrashOk': {
      Helix.track('WEBMAIL_selectAction_trash_emptyTrashModal_ok');
      break;
    }
    case 'emptyTrashSuccess': {
      Helix.track('WEBMAIL_applicationActivity_emptyTrash_success');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'emptyTrashErrorApi': {
      Helix.track('WEBMAIL_error_unableToEmptyTrash');
      break;
    }
    case 'emptyTrashTryAgain': {
      Helix.track('WEBMAIL_selectAction_trash_emptyTrashModal_tryAgain');
      break;
    }
    case 'emptyTrashCancel': {
      Helix.track('WEBMAIL_selectAction_trash_emptyTrashModal_cancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'modalClose': {
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'draftsFolderClick': {
      Helix.track('WEBMAIL_selectAction_drafts');
      break;
    }
    case 'spamFolderClick': {
      Helix.track('WEBMAIL_selectAction_emailNavigation_spam');
      break;
    }
    case 'pageViewInitSpam': {
      Helix.track('WEBMAIL_pageViewInit_email_spam', {
        msgEventType: 'pageViewInit',
        currPageUrl: '/user/mail/spam',
        currPageName: 'spam folder',
      });
      break;
    }
    case 'pageViewInitDrafts': {
      Helix.track('WEBMAIL_pageViewInit_email_drafts', {
        msgEventType: 'pageViewInit',
        currPageUrl: '/user/mail/drafts',
        currPageName: 'drafts folder',
      });
      break;
    }
    case 'pageViewInitSentMail': {
      Helix.track('WEBMAIL_pageViewInit_email_sentMail', {
        msgEventType: 'pageViewInit',
        currPageUrl: '/user/mail/sent-mail',
        currPageName: 'sent mail folder',
      });
      break;
    }
    case 'pageViewInitInbox': {
      Helix.track('WEBMAIL_pageViewInit_email_inbox', {
        currPageUrl: '/user/mail/SU5CT1g%3D',
      });
      break;
    }
    case 'inboxFolderClick': {
      Helix.track('WEBMAIL_selectAction_inbox');
      break;
    }
    default: {
      console.log('Event Case For Folders Not Found', event);
      return null;
    }
  }
  return null;
};

export default submitFolderEvents;
