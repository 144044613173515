import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import { useDeleteBulkContactMutation } from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import { deleteBulkContactThunk } from 'thunks/contact/deleteBulkContactThunk';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, useDialogTranslation } from 'translation/hooks';

const DeleteBulkContact: React.FC = () => {
  const { t } = useDialogTranslation('deleteContactCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'contacts' });
  const apiError = useAppSelector((state) => state.contactSlice.apiError);
  const selectedContacts = useAppSelector((state) => state.contactSlice.selectedContacts) || [];
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const location = useLocation();
  const navigate = useNavigate();
  const focusOpener = useTarget('modal');

  const contactId: string = location.pathname.split('/').length >= 5 ? location.pathname.split('/')[4] : '';

  const [deleteBulkContactHook] = useDeleteBulkContactMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  if (selectedContacts && selectedContacts.length <= 0) {
    return null;
  }

  const isSaved = async (): Promise<boolean> => {
    const result = await dispatch(
      deleteBulkContactThunk({
        deleteBulkContactHook,
        payload: { id: selectedContacts ? selectedContacts : [] },
      })
    );

    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'deleteContactSuccess' });
      dispatch(
        updateToastMessage({
          message: t(selectedContacts.length > 1 ? 'successToast' : 'successToastSingular'),
          success: true,
        })
      );
      if (contactId && selectedContacts?.includes(contactId)) {
        const backUrl = location.pathname.split('/').slice(0, -1).join('/');
        navigate(`${backUrl}${location.search}`);
      }
      return true;
    }

    trackEvent({ event: 'deleteContactErrorApi' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'deleteContactCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    trackEvent({ event: 'deleteContactSave' });

    return !(selectedContacts && selectedContacts.length <= 0);
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t('delete'),
    cancelLabel: t(selectedContacts && selectedContacts.length > 1 ? 'cancelBtnText' : 'cancelBtnTextSingular'),
    isDelete: true,
  };

  return (
    <ModalContentWrapper>
      <h5>{t(selectedContacts.length > 1 ? 'header' : 'headerSingular')}</h5>
      <p>{t(selectedContacts.length > 1 ? 'description' : 'descriptionSingular')}</p>
      <p className={modalStyle.alertMessage}>{t('warning')}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default DeleteBulkContact;
