import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import { TrackingHook, useTracking } from 'react-tracking';
import {
  removeFromTabCollection,
  updateActiveTab,
  updateComposeFormError,
  updateMaximizeCompose,
  updateMinimizedIndex,
  updatePauseAutoSave,
} from 'slices/compose';
import {
  composeMailFormBccValidator,
  composeMailFormCcValidator,
  composeMailFormHelper,
  composeMailFormToValidator,
  isValidEmail,
} from 'utils/composeMailFormHelper';
import { saveDraftThunk } from 'thunks/compose/saveDraftThunk';
import { useSaveDraftMutation } from 'generated/graphql';
import { useEffect, useState } from 'react';
import { KiteAlert } from '@kite/react-kite';
import { useNavigate } from 'react-router-dom';
import { appConstants } from 'appConstants';
import { isDraftFolder } from 'utils/folderNameValidator';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { updateTotalCountForMailbox } from 'slices/mailbox-collection';
import { ValidationKeys, useDialogTranslation } from 'translation/hooks';

interface SaveDraftInterface {
  folder: string;
}

const SaveDraft: React.FC<SaveDraftInterface> = ({ folder }) => {
  const { t } = useDialogTranslation('saveDraftCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'compose' });

  const activeTabIndex = useAppSelector((state) => state.composeSlice.activeTabIndex);
  const displayName = useAppSelector((state) => state.settingSlice.displayName);
  const composeCollection = useAppSelector((state) => state.composeSlice.composeCollection);
  const activeMinimizedIndex = useAppSelector((state) => state.composeSlice.activeMinimizedIndex);
  const replyToAddress = useAppSelector((state) => state.settingSlice.replyTo);
  const [replyToError, setReplyToError] = useState(false);

  const [apiError, setApiError] = useState(false);

  const [saveDraftHook] = useSaveDraftMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const composeCollectionLength = Object.keys(composeCollection).length;

  useEffect(() => {
    if (replyToAddress && !isValidEmail(replyToAddress)) {
      setReplyToError(true);
    }
  }, [replyToAddress]);

  useEffect(() => {
    trackEvent({ event: 'modalViewSaveDraft' });

    return () => {
      dispatch(updatePauseAutoSave(false));
    };
  }, []);

  const handleValidationError = (validationMessage: ValidationKeys): void => {
    trackEvent({ event: 'saveDraftValidationError', errorMessage: 'invalidEmailInToCcOrBcc' });
    dispatch(
      updateComposeFormError({
        type: 'error',
        index: activeTabIndex,
        errorIndex: 'compose_form_invalid',
        message: validationMessage,
      })
    );
  };

  const isSaved = async (): Promise<boolean> => {
    trackEvent({ event: 'saveDraftModal' });

    const activeTab = composeCollection[activeTabIndex];

    if (replyToError) {
      dispatch(updateIsOpen(false));
      trackEvent({ event: 'modalClose' });
      return false;
    }

    if (activeTab.to?.length && !composeMailFormToValidator(activeTab)) {
      handleValidationError('composeFormToEmailsInvalid');
      dispatch(updateIsOpen(false));
      return false;
    }
    if (activeTab.cc?.length && !composeMailFormCcValidator(activeTab)) {
      handleValidationError('composeFormCcEmailsInvalid');
      dispatch(updateIsOpen(false));
      return false;
    }
    if (activeTab.bcc?.length && !composeMailFormBccValidator(activeTab)) {
      handleValidationError('composeFormBccEmailsInvalid');
      dispatch(updateIsOpen(false));
      return false;
    }

    const isResponseSuccess = await dispatch(
      saveDraftThunk({
        payload: {
          mail: composeMailFormHelper(activeTab, replyToAddress),
          index: activeTabIndex,
          displayName: displayName || '',
          isSelectedFolderDraft: folder ? isDraftFolder(folder) : false,
        },
        saveDraftHook,
      })
    ).unwrap();

    if (isResponseSuccess.success) {
      dispatch(updateActiveTab(''));
      dispatch(updateMaximizeCompose(false));
      dispatch(updateMinimizedIndex(String(Object.keys(composeCollection).lastIndexOf('index'))));
      dispatch(removeFromTabCollection({ activeTabIndex, isDraftFolder: isDraftFolder(folder) }));
      dispatch(updateToastMessage({ message: t('successToast'), success: true }));
      dispatch(updateIsOpen(false));
      trackEvent({ event: 'modalClose' });

      if (!activeTab.isOpenedFromDraft) {
        dispatch(updateTotalCountForMailbox({ mailboxId: appConstants.DRAFT_FOLDER_ID, updateCount: 1 }));
      }

      if (isDraftFolder(folder)) {
        navigate(`/user/mail/${encodeURIComponent(appConstants.DRAFT_FOLDER_ID)}`);
      }

      return true;
    }

    trackEvent({ event: 'saveDraftAPIError' });
    setApiError(true);
    return false;
  };

  const isDiscarded = (): boolean => {
    trackEvent({ event: 'discardDraftModal' });

    if (composeCollectionLength && activeTabIndex) {
      dispatch(removeFromTabCollection({ activeTabIndex, isDraftFolder: isDraftFolder(folder) }));
    } else if (activeMinimizedIndex) {
      dispatch(removeFromTabCollection({ activeTabIndex: activeMinimizedIndex, isDraftFolder: isDraftFolder(folder) }));
    }

    dispatch(updateIsOpen(false));

    if (isDraftFolder(folder)) {
      navigate(`/user/mail/${encodeURIComponent(appConstants.DRAFT_FOLDER_ID)}`);
    }

    dispatch(updateMaximizeCompose(false));

    return true;
  };

  const isValidate = (): boolean => {
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled: isDiscarded,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: replyToError ? t('goBack') : apiError ? t('tryAgain') : t('confirmBtnText'),
    cancelLabel: t('cancelBtnText'),
  };

  return (
    <ModalContentWrapper>
      <h5>{t('header')}</h5>
      <p>{t('description')}</p>
      {replyToError && (
        <KiteAlert className={modalStyle.alert} type="error" description={t('replyToInvalidAddress')} level="page" />
      )}
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t('unableToSaveErr')} level="page" />
      ) : null}
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default SaveDraft;
