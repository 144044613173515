export const topNavigationCopy = {
  emailSearch: '',
  contactSearch: '',
  contactLabelDefault: '',
  account: '',
  support: '',
  inSpanish: '',
  inEnglish: '',
  signOut: '',
  skipLinkPrefix: '',
  skipMainContent: '',
  clearSearch: '',
};
