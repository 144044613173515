import styles from 'components/compose-input-field/styles/ContactSuggestion.module.scss';
import { ContactEntity } from 'generated/graphql';
import { LabelWithContacts } from 'hooks/useContactSearch';
import { Tag } from 'react-tag-autocomplete';
import Highlighted from 'components/common/Highlighted';

const getSuggestionComponent =
  (contacts: ContactEntity[], labels: LabelWithContacts[]) =>
  ({ item, query }: { item: Tag; query: string }): JSX.Element | null => {
    const fullLabel = labels.find((label) => label.id === item.id);

    const ariaAnnouncement = (
      <span className="srOnly" aria-live={'polite'}>
        use the up and down arrows for auto suggestions
      </span>
    );

    if (fullLabel) {
      const memberCount = fullLabel.contacts.length;
      return (
        <div className={styles.contactSuggestion}>
          <Highlighted text={fullLabel.name} highlight={query} />
          <span>{memberCount} member(s)</span>
          {ariaAnnouncement}
        </div>
      );
    }

    const fullContact = contacts.find((contact) => contact.id === item.id);
    if (fullContact) {
      const contactFullName = `${fullContact?.firstName}${fullContact?.lastName ? ` ${fullContact?.lastName}` : ''}`;
      return (
        <div className={styles.contactSuggestion}>
          {contactFullName && <Highlighted text={contactFullName} highlight={query} />}
          <Highlighted text={item.name} highlight={query} />
          {ariaAnnouncement}
        </div>
      );
    }

    return null;
  };

export default getSuggestionComponent;
