import common from 'translation/en_US/common.json';
import validation from 'translation/en_US/validation.json';
import errors from 'translation/en_US/errors.json';
import pages from 'translation/en_US/pages';
import dialogs from 'translation/en_US/dialogs';

const en = {
  dialogs,
  pages,
  common,
  validation,
  errors,
};

export default en;
