import { useEffect } from 'react';
import { KiteCheckbox, KiteIcon } from '@kite/react-kite';
import Highlighted from 'components/common/Highlighted';
import { createTargetRef } from 'components/common/focus/FocusContext';
import useMobileSize from 'components/common/hooks/useMobileSize';
import styles from 'components/list-messages/styles/ListMessagesStyles.module.scss';
import { MessageListEntity } from 'generated/graphql';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Link, useLocation, useParams } from 'react-router-dom';
import { TrackingHook, useTracking } from 'react-tracking';
import { updateSelectedMessages } from 'slices/message-collection';
import { usePageTranslation } from 'translation/hooks';
import { mailListDateFormatter } from 'utils/dateFormatter';
import { isDraftFolder, isSentMailFolder } from 'utils/folderNameValidator';
import getDisplayNameOrAddress from 'utils/getDisplayNameOrAddress';
import { isMessageFlagged, isMessageRead, isMessageUnread } from 'utils/messageFlagsUtils';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

interface Props {
  idx: number;
  message: MessageListEntity;
  currentFolder: string;
  isMultipleSelected: boolean;
  isSelected: boolean;
  search: string | null;
  handleMultiSelect: (e: React.MouseEvent<any>, index: number) => void;
}

const ListMessagesItem: React.FC<Props> = ({
  idx,
  message,
  currentFolder,
  isMultipleSelected,
  isSelected,
  search,
  handleMultiSelect,
}) => {
  const { t } = usePageTranslation('emailViewCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const { pathname, search: searchQuery } = useLocation();
  const isMobile = useMobileSize();
  const mailId = pathname.split('/').pop();
  const isMessageOpened = message.uid.toString() === mailId;
  const emailOpenerRef = createTargetRef<HTMLAnchorElement>(message.uid);
  const timeZone = useAppSelector((state) => state.settingSlice.timeZone);
  const selectedMessages = useAppSelector((state) => state.messageCollectionSlice.selectedMessages)?.map((string) =>
    Number(string)
  );
  const dispatch = useAppDispatch();
  const { '*': currentMailId } = useParams();
  const isThreePaneEnabled = !!currentMailId;

  const [_props, drag, dragPreview] = useDrag(
    () => ({
      type: 'listMessageItem',
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
        item: monitor.getItem(),
      }),
      item: {
        messageUids: selectedMessages?.length ? selectedMessages : [message.uid],
        sourceMailboxId: currentFolder,
      },
    }),
    [selectedMessages]
  );

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const displayRecipientOrSender = (message: MessageListEntity): React.ReactNode => {
    if (isDraftFolder(currentFolder) || isSentMailFolder(currentFolder)) {
      return message.to?.length ? getDisplayNameOrAddress(message.to[0]) : t('noRecipient');
    }

    return message.from?.length ? (
      <Highlighted text={getDisplayNameOrAddress(message.from[0])} highlight={search || ''} />
    ) : (
      ''
    );
  };

  const handleMessageCheckbox = (messageId: string): void => {
    trackEvent({ event: 'selectEmailCheckbox' });
    dispatch(updateSelectedMessages(messageId));
  };

  const handleClickMessage = (): void => {
    if (search?.length) {
      trackEvent({ event: 'searchResultSelected' });
      return;
    }
    trackEvent({ event: 'clickMessage' });
  };

  return (
    <li
      key={idx}
      className={`${styles.emailCardThreePane} ${!isMultipleSelected && isMessageOpened ? styles.itemSelected : ''} ${
        isSelected ? styles.checked : ''
      } ${!isThreePaneEnabled && !isMobile ? styles.emailCardTwoPane : ''}`}
      ref={drag}
    >
      <div className={styles.selectedBorder} />
      <Link
        to={{ pathname: `/user/mail/${encodeURIComponent(currentFolder)}/${message.uid}`, search: searchQuery }}
        key={message.uid}
        className={`${styles.messageDetails}`}
        onClick={() => handleClickMessage()}
        ref={emailOpenerRef}
        tabIndex={selectedMessages?.length ? -1 : undefined}
        aria-label={t('emailLinkAria', {
          sender: isDraftFolder(currentFolder) || isSentMailFolder(currentFolder) ? t('to') : t('from'),
          recipient: (message.to?.length && message.to[0].address) || t('noRecipient'),
          date: mailListDateFormatter(message.date, timeZone),
          read: isMessageRead(message) ? t('read') : t('unread'),
          flagged: isMessageFlagged(message) ? t('flagged') : '',
        })}
      >
        <div className={`${styles.row} ${styles.titleRow}`}>
          <div className={`${styles.unreadIconColumn} ${isThreePaneEnabled || isMobile ? styles.hide : ''}`}>
            {isMessageUnread(message) && !isSentMailFolder(currentFolder) && (
              <KiteIcon icon="ki-stop-circle-f" size="15px" className={styles.unreadIcon} />
            )}
          </div>
          <div className={styles.columnTwo}>
            <div className={styles.fromNameWithIcons}>
              <div
                className={`${styles.name} ${
                  isMessageUnread(message) && !isSentMailFolder(currentFolder) ? '' : styles.nameRead
                }`}
              >
                {displayRecipientOrSender(message)}
              </div>
              <div className={styles.icons}>
                {message.attachmentCount > 0 && <KiteIcon icon="ki-attachment" size="16px" fill="#001019" />}
                {message && message.priority === 'high' && (
                  <KiteIcon ariaLabel={t('priority')} icon={'ki-exclamation-mark'} size="16px" fill="#D6312B" />
                )}
                {isMessageFlagged(message) && <KiteIcon icon="ki-flag-f" size="16px" fill="#0073D1" />}
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.row} ${styles.subjectRow}`}>
          <div className={styles.unreadIconColumn}>
            {isMessageUnread(message) && !isSentMailFolder(currentFolder) && (
              <KiteIcon icon="ki-stop-circle-f" size="15px" className={styles.unreadIcon} />
            )}
          </div>
          <div className={styles.columnTwo}>
            <div className={styles.subjectWithDate}>
              <div
                className={`${styles.subject} ${
                  isMessageUnread(message) && !isSentMailFolder(currentFolder) ? styles.unreadSubject : null
                }`}
              >
                {message.subject ? <Highlighted text={message.subject} highlight={search || ''} /> : t('noSubject')}
              </div>
              <div className={styles.date}>{message.date && mailListDateFormatter(message.date, timeZone)}</div>
            </div>
          </div>
        </div>
      </Link>
      <KiteCheckbox
        className={styles.messageCheckbox}
        id={`messageSelect-${idx}`}
        name={`messageSelect-${idx}`}
        onChange={() => handleMessageCheckbox(message.uid.toString())}
        onClick={(e) => handleMultiSelect(e, idx)}
        checked={isSelected}
        label={''}
        aria-label={'select email checkbox'}
      />
    </li>
  );
};

export default ListMessagesItem;
