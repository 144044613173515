import tracker from 'libs/tracking';
import { createContext, useEffect, useState } from 'react';
import AnalyticsService, { helixConfig } from 'libs/tracking/adapters/helix/helixService';
import { KiteProgressIndicator } from '@kite/react-kite';

import {
  getLoggedInAccountGuid,
  getLoggedInAccountNumber,
  getLoggedInFootprint,
  getLoggedInVisitIdStorage,
  setLoggedInVisitIdStorage,
} from 'utils/accessTokenLocalStorage';
import { TrackingHook, useTracking } from 'react-tracking';
import { getIsLoggedIn } from 'providers/AuthProvider';

export const AnalyticsContext = createContext({});

const AnalyticsProvider: React.FC = ({ children }) => {
  const [isAnalyticsReady, setIsAnalyticsReady] = useState<null | { success: boolean; visitId?: string }>(null);
  const analytics = new AnalyticsService(helixConfig);
  const { trackEvent }: TrackingHook = useTracking({ page: 'login' });

  const initializeAnalytics = async (): Promise<void> => {
    const result = await analytics.init();

    if (getIsLoggedIn() && result.visitId !== getLoggedInVisitIdStorage()) {
      trackEvent({
        event: 'updateUserConfig',
        accountNumber: getLoggedInAccountNumber(),
        footprint: getLoggedInFootprint(),
        accountGuid: getLoggedInAccountGuid(),
      });
      setLoggedInVisitIdStorage(result.visitId || '');
    }

    setIsAnalyticsReady(result);
  };

  useEffect(() => {
    if (!isAnalyticsReady) {
      initializeAnalytics();
    }
  }, [isAnalyticsReady]);

  if (!isAnalyticsReady) {
    return <KiteProgressIndicator id={'analytics'} />;
  }

  return <AnalyticsContext.Provider value={{}}>{children}</AnalyticsContext.Provider>;
};

export default tracker(AnalyticsProvider);
