import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLdapSettingThunk } from 'thunks/setting/getLdapSettingThunk';
import { updateApprovedSenderThunk } from 'thunks/setting/updateApprovedSenderThunk';
import normalizeFormErrors from 'utils/updateFormErrors';
import { mergeSenderControl } from '../../utils/mergeSenderControl';

export interface SenderItemInterface {
  [key: string]: {
    value: string;
    isEmail: boolean;
    isDeleted: boolean;
    isNew: boolean;
  };
}

export interface SenderState {
  draftSenderList: SenderItemInterface;
  senderList: string[];
  error: string | null;
}

const initialState: SenderState = {
  draftSenderList: {},
  senderList: [],
  error: null,
};

export const approveSenderSlice = createSlice({
  name: 'approveSender',
  initialState,
  reducers: {
    updateDraftApproveSenderList: (state, action: PayloadAction<SenderItemInterface>): void => {
      state.draftSenderList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLdapSettingThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.senderList = action.payload.success.senderControl.approvedSenders;
      }
    });

    builder.addCase(updateApprovedSenderThunk.fulfilled, (state, action) => {
      if (action.payload.error) {
        const error = normalizeFormErrors(action.payload.error);
        state.error = error.errorCode;
        return;
      }

      if (action.payload.success) {
        state.error = null;

        if (
          !action.payload.success?.added.domains.length &&
          !action.payload.success?.added.emails.length &&
          !action.payload.success?.removed.domains.length &&
          !action.payload.success?.removed.emails.length
        ) {
          const newDraft = { ...state.draftSenderList };

          Object.keys(newDraft).forEach((sender) => {
            if (state.draftSenderList[sender].isDeleted && state.draftSenderList[sender].isNew) {
              delete newDraft[sender];
            }
          });

          state.draftSenderList = newDraft;
          return;
        }

        const mergedAdded = mergeSenderControl(
          action.payload.success?.added?.emails,
          action.payload.success?.added?.domains
        );

        const mergedRemoved = mergeSenderControl(
          action.payload.success?.removed?.emails,
          action.payload.success?.removed?.domains
        );

        if (mergedAdded && mergedAdded.length > 0) {
          state.senderList = [...state.senderList, ...mergedAdded];
        }

        if (mergedRemoved && mergedRemoved.length > 0) {
          state.senderList = state.senderList.filter((item) => !mergedRemoved.includes(item));
        }

        state.draftSenderList = {};
      }
    });
  },
});

export const { updateDraftApproveSenderList } = approveSenderSlice.actions;

export default approveSenderSlice.reducer;
