import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MailboxStatusEntity } from 'generated/graphql';

interface MailboxStatusThunkResponse {
  success?: MailboxStatusEntity;
  error?: ApolloError | undefined;
  mailboxId?: string;
}

interface MailboxStatusThunkArg {
  payload: {
    mailboxId: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMailboxStatusHook?: any;
}

export const mailboxStatusThunk = createAsyncThunk(
  'mailboxStatus',
  async (arg: MailboxStatusThunkArg): Promise<MailboxStatusThunkResponse> => {
    try {
      const response = await arg.getMailboxStatusHook({
        variables: {
          mailboxId: arg.payload.mailboxId,
        },
      });
      return {
        success: response.data.getMailboxStatus,
        mailboxId: arg.payload.mailboxId,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
