import loginSlice from 'slices/login';
import canarySlice from 'slices/canary';
import composeSlice from 'slices/compose';
import appSlice from 'slices/app';
import signatureSlice from 'slices/setting/signature';
import messageCollectionSlice from 'slices/message-collection';
import autoReplySlice from 'slices/setting/autoReply';
import blockSenderSlice from 'slices/setting/blockSender';
import approveSenderSlice from 'slices/setting/approveSender';
import mailboxCollectionSlice from 'slices/mailbox-collection';
import settingSlice from 'slices/setting/setting';
import labelSlice from 'slices/label';
import contactSlice from 'slices/contact';
import contactAutocompleteSlice from 'slices/contact/autocomplete';
import quotaSlice from 'slices/quota';
import spamSlice from 'slices/setting/spam';
import messageSlice from 'slices/message';
import filterCollectionSlice from 'slices/filter';
import migrationSlice from 'slices/migration';

export const combinedSlices = {
  loginSlice,
  canarySlice,
  composeSlice,
  appSlice,
  signatureSlice,
  messageCollectionSlice,
  autoReplySlice,
  mailboxCollectionSlice,
  settingSlice,
  labelSlice,
  contactSlice,
  contactAutocompleteSlice,
  quotaSlice,
  blockSenderSlice,
  approveSenderSlice,
  spamSlice,
  messageSlice,
  filterCollectionSlice,
  migrationSlice,
} as const;
