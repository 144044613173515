import { appConstants } from 'appConstants';
import { get } from 'lodash';

const getAppVersion = async (): Promise<string> => {
  try {
    const res = await fetch('/version.txt');
    const versionObj = await res.json();
    return get(versionObj, 'Version').split('/').pop();
  } catch (err) {
    console.log(`No version file found. Setting helix app version to default.`);
    return appConstants.helix.HELIX_APP_VERSION;
  }
};

export default getAppVersion;
