import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert, KiteTextInput } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import { useRenameMailboxMutation, useUpdateFiltersMutation } from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { isValidFolderName, isDuplicate } from 'utils/folderNameValidator';
import { renameMailboxThunk } from 'thunks/mailbox/renameMailboxThunk';
import { TrackingHook, useTracking } from 'react-tracking';
import { updateFilterThunk } from 'thunks/filter/updateFilterThunk';
import { transformExistingFilters } from 'utils/transformFilterForm';
import { resetMailboxApiError } from 'slices/mailbox-collection';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { appConstants } from 'appConstants';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, ValidationKeys, useDialogTranslation } from 'translation/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

interface RenameFolderInterface {
  folderId?: string;
  fullPath: string;
}

const RenameFolder: React.FC<RenameFolderInterface> = ({ folderId, fullPath }) => {
  const { t } = useDialogTranslation('renameFolderCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'folders' });
  const focusOpener = useTarget('modal');
  const apiError = useAppSelector((state) => state.mailboxCollectionSlice.apiError);
  const mailboxes = useAppSelector((state) => state.mailboxCollectionSlice.mailboxCollection);
  const filters = useAppSelector((state) => state.filterCollectionSlice.filters) || [];
  const currentFolderName = fullPath?.split('/').pop();
  const [draft, setDraft] = useState(currentFolderName || '');
  const [validationErrorMessage, setValidationErrorMessage] = useState<ValidationKeys | ''>('');
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const activeFolderName = decodeURIComponent(useLocation().pathname.split('/')[3] || '');
  const navigate = useNavigate();

  const [updateFiltersHook] = useUpdateFiltersMutation();

  const dispatch = useAppDispatch();
  const [renameMailboxHook] = useRenameMailboxMutation();

  const isSubFolder = fullPath && fullPath?.split('/').length > 1;

  useEffect(() => {
    !isSubFolder ? trackEvent({ event: 'renameFolderView' }) : trackEvent({ event: 'renameSubFolderView' });
  }, []);

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  const handleInputTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setDraft(e.target.value);
    validateInput(e.target.value);
  };

  const isSaved = async (): Promise<boolean> => {
    !isSubFolder ? trackEvent({ event: 'renameFolderUpdate' }) : trackEvent({ event: 'renameSubFolderUpdate' });
    const result = await dispatch(
      renameMailboxThunk({
        payload: {
          mailbox: { mailbox: draft },
          oldMailboxId: folderId ?? '',
          fullPath: fullPath ?? '',
        },
        renameMailboxHook,
      })
    ).unwrap();

    if (result.success) {
      let hasUpdatedFilters = false;
      const updatedFilters = filters.map((filter) => {
        if (filter.moveFolder && filter.moveFolder === folderId) {
          hasUpdatedFilters = true;
          return {
            ...filter,
            moveFolder: result.success?.id,
          };
        }
        return filter;
      });

      if (hasUpdatedFilters) {
        const updatedFiltersResult = await dispatch(
          updateFilterThunk({
            payload: {
              filters: transformExistingFilters(updatedFilters),
            },
            updateFiltersHook,
          })
        ).unwrap();

        if (updatedFiltersResult.success) {
          return isSavedSuccess(true, result.success?.id);
        }
        return isSavedSuccess(false);
      }
      return isSavedSuccess(true, result.success?.id);
    }
    return isSavedSuccess(false);
  };

  const isSavedSuccess = (bool: boolean, newFolderName?: string): boolean => {
    if (bool) {
      !isSubFolder ? trackEvent({ event: 'renameFolderSuccess' }) : trackEvent({ event: 'renameSubFolderSuccess' });
      setDraft('');

      dispatch(updateToastMessage({ message: t('successToast'), success: true }));

      if (activeFolderName === folderId) {
        navigate(`/user/mail/${newFolderName}`);
      }

      return true;
    }

    !isSubFolder ? trackEvent({ event: 'renameFolderErrorApi' }) : trackEvent({ event: 'renameSubFolderErrorApi' });
    return false;
  };

  const isCancelled = (): boolean => {
    !isSubFolder ? trackEvent({ event: 'renameFolderCancel' }) : trackEvent({ event: 'renameSubFolderCancel' });

    setDraft('');
    setValidationErrorMessage('');
    dispatch(resetMailboxApiError());
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const validateInput = (input: string): void => {
    if (!isValidFolderName(input)) {
      trackEvent({ event: 'renameFolderErrorValidation' });
      setValidationErrorMessage('invalidCharacters');
      return;
    }

    const subFolderDraft = `${fullPath?.split('/').slice(0, -1).join('/')}/${input}`;
    if (isDuplicate(isSubFolder ? subFolderDraft : input, mailboxes)) {
      trackEvent({ event: 'renameFolderErrorDuplicate' });
      setValidationErrorMessage('folderDuplicate');
      return;
    }

    if (input.length > appConstants.MAX_FOLDER_NAME_LENGTH) {
      setValidationErrorMessage('folderCharacterLimit');
      return;
    }

    setValidationErrorMessage('');
  };

  const isChanged = (): boolean => {
    return draft.length > 0 && draft !== fullPath?.split('/').pop();
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate: () => !validationErrorMessage,
    draft,
    isChanged,
    saveButtonText: t('confirmBtnText'),
  };

  return (
    <ModalContentWrapper>
      <h5>{isSubFolder ? t('headerSubfolder') : t('headerFolder')}</h5>
      <p>{t('description')}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <KiteTextInput
        className={modalStyle.textInput}
        label={isSubFolder ? t('subfolderLabel') : t('folderLabel')}
        onChange={handleInputTextChange}
        value={draft}
        errorMessage={t(validationErrorMessage)}
        ariaLive="polite"
        placeholder={isSubFolder ? t('subfolderPlaceholder') : t('folderPlaceholder')}
      />
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default RenameFolder;
