export default function concatUrl(baseUrl = '', path = ''): string {
  if (!path) {
    return baseUrl;
  }

  if (!baseUrl) {
    return path;
  }

  let _path = path;
  let _baseUrl = baseUrl;
  const urlFirstCharIsInvalid = path.charAt(0) === '/';
  const baseLastCharIsInvalid = baseUrl.charAt(baseUrl.length - 1) === '/';

  if (urlFirstCharIsInvalid) {
    _path = path.substring(1);
  }

  if (baseLastCharIsInvalid) {
    _baseUrl = baseUrl.substring(0, baseUrl.length - 1);
  }

  return _baseUrl.concat('/', _path);
}
