import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContactCollection } from 'generated/graphql';

interface ContactCollectionResponse {
  success?: ContactCollectionWithLabelId;
  isPrefetch: boolean;
  error?: ApolloError | undefined;
  isSearchActive?: boolean;
}

interface ContactCollectionWithLabelId extends ContactCollection {
  labelId: string;
}

interface ContactCollectionArg {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getContactCollectionHook: any;
  labelId?: string;
  limit?: number;
  search?: string;
  page?: number;
  isPrefetch?: boolean;
}

export const contactCollectionThunk = createAsyncThunk(
  'contactCollection',
  async (arg: ContactCollectionArg): Promise<ContactCollectionResponse> => {
    try {
      const response = await arg.getContactCollectionHook({
        variables: {
          search: {
            search: arg.search,
            limit: arg.limit,
            page: arg.page,
          },
          labelId: arg.labelId,
        },
      });

      return {
        success: { ...response.data.getContactCollection, labelId: arg.labelId },
        isPrefetch: arg.isPrefetch || false,
        isSearchActive: !!arg.search,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
        isPrefetch: arg.isPrefetch || false,
      };
    }
  }
);
