import styles from 'containers/styles/NavContainer.module.scss';
import NavTabs from 'components/nav-tabs';

const NavContainer: React.FC = () => {
  const { navContainer } = styles;

  return (
    <div className={navContainer}>
      <NavTabs />
    </div>
  );
};

export default NavContainer;
