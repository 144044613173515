import styles from 'components/search/styles/Search.module.scss';
import { KiteButton, KiteIcon, KiteTextInput } from '@kite/react-kite';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useURLQueryBuilder from 'components/common/hooks/useURLQueryBuilder';
import { appConstants, GlobalFoldersInterface } from 'appConstants';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TrackingHook, useTracking } from 'react-tracking';
import { usePageTranslation } from 'translation/hooks';
import { reloadMessageCollection } from 'slices/message-collection';

type SearchProps = {
  isMobile?: boolean;
};

const Search: React.FC<SearchProps> = ({ isMobile }) => {
  const { t } = usePageTranslation('topNavigationCopy');

  const currentLabelSelected = useAppSelector((state) => state.contactSlice.currentLabelSelected);

  const { pathname, search } = useLocation();
  const [searchQueryParam, setSearchQueryParam] = useURLQueryBuilder();
  const [draft, setDraft] = useState(searchQueryParam.get('search') || '');
  const dispatch = useAppDispatch();

  const renderSearch = !pathname.includes('/user/setting');
  const isContactSearch = pathname.includes('/user/label');

  const { trackEvent }: TrackingHook = useTracking({ page: isContactSearch ? 'contacts' : 'messages' });

  const renderSearchMobile = !(
    isMobile &&
    (pathname.includes('/user/mail') || pathname.includes('/user/label')) &&
    pathname.split('/').length >= 5
  );

  const handleSearch = (): void => {
    if (draft.length === 0) {
      trackEvent({ event: 'searchClose' });
    }

    setSearchQueryParam('search', '');
    trackEvent({ event: 'searchStart', payload: { keyword: draft } });
    setSearchQueryParam('search', draft);
    setSearchQueryParam('page', '1');
  };

  const handleClearSearch = (): void => {
    dispatch(reloadMessageCollection(true));
    setDraft('');
    trackEvent({ event: 'searchClose' });
    setSearchQueryParam('search', '');
  };

  useEffect(() => {
    if (!search) {
      setDraft('');
    }
  }, [search, renderSearch]);

  if (!renderSearch || !renderSearchMobile) {
    return null;
  }

  const handleInboxSearchChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setDraft(event.target.value);
  };

  const handleContactSearchChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setDraft(event.target.value);
  };

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (e.code === 'Enter') {
      handleSearch();
    }
  };

  let placeholderText = atob(decodeURIComponent(pathname.split('/')[3] || ''));

  if (Object.keys(appConstants.GLOBAL_FOLDER_NAMES).includes(placeholderText)) {
    placeholderText = appConstants.GLOBAL_FOLDER_NAMES[placeholderText as keyof GlobalFoldersInterface];
  }

  if (placeholderText.split('/').length > 1) {
    placeholderText = placeholderText.split('/').pop() || '';
  }

  return (
    <div className={styles.searchBarContainer}>
      <KiteButton
        variant={'borderless'}
        className={styles.searchButton}
        onClick={handleSearch}
        aria-label={t('emailSearch', { folder: placeholderText })}
      >
        <KiteIcon className={styles.searchIcon} icon="ki-search" fill="#004366" size="24px" />
      </KiteButton>
      {!isContactSearch && (
        <KiteTextInput
          className={styles.searchBarInput}
          aria-label={t('emailSearch', { folder: placeholderText })}
          role="search"
          value={draft}
          onChange={handleInboxSearchChange}
          placeholder={t('emailSearch', { folder: placeholderText })}
          onKeyDown={handleEnterKey}
        />
      )}
      {isContactSearch && (
        <KiteTextInput
          className={styles.searchBarInput}
          aria-label={t('contactSearch', { label: currentLabelSelected })}
          role="search"
          value={draft}
          onChange={handleContactSearchChange}
          placeholder={t('contactSearch', { label: currentLabelSelected || t('contactLabelDefault') })}
          onKeyDown={handleEnterKey}
        />
      )}
      <button
        className={styles.searchOptionButton}
        onClick={() => console.log('search options clicked')}
        type="button"
        role="button"
      >
        <div className={styles.searchOptionsButtonContent}>
          <span>{t('options')}</span>
          <KiteIcon className={styles.optionsChevron} icon="ki-chevron-down" fill="#0073D1" size="13" />
        </div>
      </button>
      {(!!draft.length || searchQueryParam.get('search')) && (
        <KiteButton
          className={styles.cancelSearch}
          variant={'borderless'}
          aria-label={t('clearSearch')}
          onClick={handleClearSearch}
        >
          <KiteIcon className={styles.optionsChevron} icon="ki-x" fill="#0073D1" size="16px" />
        </KiteButton>
      )}
    </div>
  );
};
export default Search;
