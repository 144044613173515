import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContactWithLabelEntity, PatchContactLabelMutationFn } from 'generated/graphql';

interface UpdateContactsLabelsArg {
  payload: {
    id: string;
    labels: string[];
  };
  patchContactLabelHook: PatchContactLabelMutationFn;
}

interface UpdateContactsLabelResponse {
  success?: ContactWithLabelEntity;
  error?: ApolloError;
  id: string;
}

export const patchContactsLabelThunk = createAsyncThunk(
  'patchContactsLabel',
  async (arg: UpdateContactsLabelsArg): Promise<UpdateContactsLabelResponse> => {
    try {
      const response = await arg.patchContactLabelHook({
        variables: arg.payload,
      });

      return {
        success: response?.data?.patchContactLabel as ContactWithLabelEntity,
        id: arg.payload.id,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
        id: arg.payload.id,
      };
    }
  }
);
