import isMobileSize from 'components/common/hooks/useMobileSize';
import CanaryTutorialMobile from 'components/canary/CanaryTutorialMobile';
import CanaryTutorialDesktop from 'components/canary/CanaryTutorialDesktop';

const CanaryTutorialContainer: React.FC = () => {
  const isMobile = isMobileSize();

  if (isMobile) {
    return <CanaryTutorialMobile />;
  }

  return <CanaryTutorialDesktop />;
};

export default CanaryTutorialContainer;
