import styles from 'components/header/styles/Subheader.module.scss';
import { GlobalFoldersInterface, appConstants } from 'appConstants';
import { useLocation, useParams } from 'react-router-dom';
import { usePageTranslation } from 'translation/hooks';
import { isGlobalFolder } from 'utils/folderNameValidator';
import { useAppSelector } from 'hooks';

const EmailHeader: React.FC = () => {
  const { t } = usePageTranslation('contactViewCopy');
  const { folder: folderId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isSearchEnabled = !!query.get('search');
  const spamFolderId = useAppSelector((state) => state.mailboxCollectionSlice.spamFolderId);

  let folderName = atob(folderId || '');

  if (isGlobalFolder(folderId || '', spamFolderId)) {
    folderName = appConstants.GLOBAL_FOLDER_NAMES[folderName as keyof GlobalFoldersInterface];
  }

  if (folderName.split('/').length > 1) {
    folderName = folderName.split('/').pop() || '';
  }

  if (isSearchEnabled) {
    folderName = t('searchResults');
  }

  return <div className={styles.subheader}>{folderName}</div>;
};

export default EmailHeader;
