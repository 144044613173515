import { useRef, useEffect, useState } from 'react';

type UseDocumentTitleType = (prevailOnUnmount?: boolean) => React.Dispatch<React.SetStateAction<string>>;

const useDocumentTitle: UseDocumentTitleType = (prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);
  const [title, setTitle] = useState(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    []
  );

  return setTitle;
};

export default useDocumentTitle;
