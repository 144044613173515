import { KiteButton, KiteIcon } from '@kite/react-kite';
import styles from 'components/message-options/styles/MessageListOptions.module.scss';
import { useAppSelector } from 'hooks';
import BackButton from 'components/common/BackButton';
import { useAppDispatch } from 'hooks';
import {
  reloadMessageCollection,
  updateDeleteButtonLoading,
  updateMessageCollectionSort,
  updateSelectAllMessages,
} from 'slices/message-collection';
import KebabMenu, { KebabMenuItemsInterface } from 'components/common/kebab-menu';
import {
  updateDrawerName,
  updateIsDrawerOpen,
  updateIsOpen,
  updateModalName,
  updateModalProps,
  updateToastMessage,
} from 'slices/app';
import { useLocation, useParams } from 'react-router-dom';
import { markMessagesReadThunk } from 'thunks/message-collection/markMessagesReadThunk';
import { markMessagesUnreadThunk } from 'thunks/message-collection/markMessageUnreadThunk';
import {
  Sort,
  useGetMailBoxStatusLazyQuery,
  useMoveMessagesToNotSpamMutation,
  useMoveMessagesToTrashMutation,
  usePostMarkMessagesAsFlagMutation,
  usePostMarkMessagesAsSeenMutation,
  usePostMarkMessagesAsUnflagMutation,
  usePostMarkMessagesAsUnseenMutation,
} from 'generated/graphql';
import { markMessagesFlagThunk } from 'thunks/message-collection/markMessageFlagThunk';
import { markMessagesUnflagThunk } from 'thunks/message-collection/markMessageUnflagThunk';
import { TrackingHook, useTracking } from 'react-tracking';
import { appConstants } from 'appConstants';
import SortOptions from 'components/message-options/SortOptions';
import { moveMessagesThunk } from 'thunks/message-collection/moveMessagesThunk';
import {
  isDraftFolder,
  isGlobalFolder,
  isInboxFolder,
  isSentMailFolder,
  isSpamFolder,
  isTrashFolder,
} from 'utils/folderNameValidator';
import {
  hasEveryFlagFromSelectedMessages,
  isMessageFlagged,
  isMessageRead,
  isMessageUnflagged,
  isMessageUnread,
} from 'utils/messageFlagsUtils';
import { updateUnreadCountForMailbox } from 'slices/mailbox-collection';
import getUnseenMessagesCount from 'utils/getUnseenMessagesCount';
import { mailboxStatusThunk } from 'thunks/mailbox-collection/mailboxStatusThunk';
import { usePageTranslation } from 'translation/hooks';
import { useCallback, useState } from 'react';
import useMobileSize from 'components/common/hooks/useMobileSize';
import { updateModalOpener } from 'components/common/focus/FocusContext';
import useNavToPreviousPage from 'components/common/hooks/useNavToPreviousPage';
import useCloseMessage from 'components/common/hooks/useCloseMessage';

const MessageListOptions: React.FC = () => {
  const { t } = usePageTranslation('emailActionsCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const updateModalRef = updateModalOpener();
  const [getMailboxStatusHook] = useGetMailBoxStatusLazyQuery();

  const selectedMessages = useAppSelector((state) => state.messageCollectionSlice.selectedMessages) || [];
  const { messageCollection, messageCollectionLoading } = useAppSelector((state) => state.messageCollectionSlice);
  const getMailLoading = useAppSelector((state) => state.messageCollectionSlice.getMailLoading);
  const deleteButtonLoading = useAppSelector((state) => state.messageCollectionSlice.deleteButtonLoading);
  const messageCollectionFetchError = useAppSelector(
    (state) => state.messageCollectionSlice.messageCollectionFetchError
  );
  const spamFolderId = useAppSelector((state) => state.mailboxCollectionSlice.spamFolderId);
  const [maxItems, setMaxItems] = useState(0);
  const { folder: currentFolder } = useParams();
  const { search: searchQuery } = useLocation();
  const dispatch = useAppDispatch();
  const messageIdList = messageCollection[currentFolder || '']?.data.map((message) => message.uid.toString());
  const { folder, '*': currentMailId } = useParams<{ folder: string; '*': string }>();
  const currentMessageCollection = messageCollection[currentFolder || '']?.data;

  const query = new URLSearchParams(searchQuery);
  const sort = query.get('sort') as Sort;
  const isMobile = useMobileSize();
  const [queryParams, currentPage, navToPreviousPage] = useNavToPreviousPage();
  const closeMessage = useCloseMessage();
  const isReadingPaneEnabled = !!currentMailId;

  let isSelectedAll = false;

  const [markMessagesReadHook] = usePostMarkMessagesAsSeenMutation();
  const [markMessagesUnreadHook] = usePostMarkMessagesAsUnseenMutation();
  const [markMessagesFlagHook] = usePostMarkMessagesAsFlagMutation();
  const [markMessagesUnflagHook] = usePostMarkMessagesAsUnflagMutation();
  const [moveMessagesToTrashHook] = useMoveMessagesToTrashMutation();
  const [moveMessagesToNotSpam] = useMoveMessagesToNotSpamMutation();

  if (!folder) {
    return null;
  }

  if (messageCollection && messageIdList && messageCollection[currentFolder || '']?.data) {
    isSelectedAll = messageIdList.every((message) => {
      return selectedMessages.includes(message);
    });
  }

  const messageUids = selectedMessages.map((id) => parseInt(id));

  const handleSelectAllMessages = (): void => {
    trackEvent({ event: 'selectAllMessages' });
    dispatch(updateSelectAllMessages({ clearSelected: isSelectedAll, currentFolder: folder }));
  };

  const getMessageCollection = (sort: Sort): void => {
    dispatch(updateMessageCollectionSort(sort));
  };

  const handleRefreshMessages = (): void => {
    isInboxFolder(folder) ? trackEvent({ event: 'getMailButton' }) : trackEvent({ event: 'refreshButton' });
    dispatch(reloadMessageCollection(true));

    if (isGlobalFolder(folder, spamFolderId) && !isSentMailFolder(folder)) {
      dispatch(mailboxStatusThunk({ getMailboxStatusHook, payload: { mailboxId: folder } }));
    }
  };

  const handleDeleteMessages = async (): Promise<boolean> => {
    let unseenMessagesCount;
    trackEvent({ event: 'deleteEmail' });
    dispatch(updateDeleteButtonLoading(true));
    const mailboxId = decodeURIComponent(folder);

    if (isInboxFolder(mailboxId)) {
      unseenMessagesCount = getUnseenMessagesCount(currentMessageCollection, messageUids);
    }

    const result = await dispatch(
      moveMessagesThunk({
        payload: {
          sourceMailboxId: mailboxId || '',
          destinationMailboxId: appConstants.TRASH_FOLDER_ID,
          messageUids,
          unseenMessagesCount,
        },
        moveMessagesHook: moveMessagesToTrashHook,
      })
    ).unwrap();

    if (result.success) {
      trackEvent({ event: 'deleteEmailSuccess' });

      const { limit, total, data } = messageCollection[mailboxId];
      const lastPage = currentPage * limit > total;

      if (result.messageUids.length === data?.length && Number(currentPage) > 1 && lastPage) {
        navToPreviousPage();
      }

      dispatch(reloadMessageCollection(true));
      dispatch(updateToastMessage({ message: t('deleteMessagesSuccess'), success: true }));

      if (messageUids.includes(parseInt(currentMailId || ''))) {
        closeMessage(folder, queryParams);
      }

      return true;
    }

    trackEvent({ event: 'deleteEmailFailure' });
    dispatch(updateModalName('deleteMessages'));
    dispatch(
      updateModalProps({
        folder,
        alertDescriptionProp: t('deleteMessageErr'),
        messageUids,
        currentMailId,
        unseenMessagesCount,
      })
    );
    dispatch(updateIsOpen(true));
    return false;
  };

  const handlePermanentDeleteMessageBulk = async (): Promise<boolean> => {
    trackEvent({ event: 'deleteEmail' });
    dispatch(updateModalName('permanentDeleteMessageBulk'));
    dispatch(
      updateModalProps({
        mailboxId: folder,
        messageUids,
        currentMailId,
      })
    );
    dispatch(updateIsOpen(true));
    return false;
  };

  const handleMoveMessages = (): void => {
    trackEvent({ event: 'moveEmailTo' });
    dispatch(updateModalName('moveMessages'));
    dispatch(updateModalProps({ folder, messageUids, currentMailId }));
    dispatch(updateIsOpen(true));
  };

  const handleFlagMessages = async (): Promise<boolean> => {
    trackEvent({ event: 'flagMessage' });
    const mailboxId = decodeURIComponent(folder);
    const result = await dispatch(
      markMessagesFlagThunk({
        payload: {
          mailboxId: mailboxId || '',
          messageUids,
        },
        markMessagesFlagHook,
      })
    );
    //@ts-ignore
    if (result?.payload?.success) {
      return true;
    }
    return false;
  };

  const handleUnflagMessages = async (): Promise<boolean> => {
    trackEvent({ event: 'unflagMessage' });
    const mailboxId = decodeURIComponent(folder);
    const result = await dispatch(
      markMessagesUnflagThunk({
        payload: {
          mailboxId: mailboxId || '',
          messageUids,
        },
        markMessagesUnflagHook,
      })
    );
    //@ts-ignore
    if (result?.payload?.success) {
      return true;
    }
    return false;
  };

  const handleMarkAsRead = async (): Promise<boolean> => {
    trackEvent({ event: 'markAsRead' });
    const mailboxId = decodeURIComponent(folder);
    const messagesToBeRead = messageUids
      .map((messageId) => currentMessageCollection.find((message) => message.uid === messageId))
      .filter((message) => message && !isMessageRead(message)).length;
    const result = await dispatch(
      markMessagesReadThunk({
        payload: {
          mailboxId: mailboxId || '',
          messageUids,
        },
        markMessagesReadHook,
      })
    );
    //@ts-ignore
    if (result?.payload?.success) {
      if (messagesToBeRead > 0 && isInboxFolder(mailboxId)) {
        dispatch(
          updateUnreadCountForMailbox({
            mailboxId,
            updateCount: -messagesToBeRead,
          })
        );
      }
      return true;
    }
    return false;
  };

  const handleMarkAsUnread = async (): Promise<boolean> => {
    trackEvent({ event: 'markAsUnread' });
    const mailboxId = decodeURIComponent(folder);
    const messagesToBeUnread = messageUids
      .map((messageId) => currentMessageCollection.find((message) => message.uid === messageId))
      .filter((message) => message && isMessageRead(message)).length;
    const result = await dispatch(
      markMessagesUnreadThunk({
        payload: {
          mailboxId: mailboxId || '',
          messageUids,
        },
        markMessagesUnreadHook,
      })
    );
    //@ts-ignore
    if (result?.payload?.success) {
      if (messagesToBeUnread > 0 && isInboxFolder(mailboxId)) {
        dispatch(
          updateUnreadCountForMailbox({
            mailboxId,
            updateCount: messagesToBeUnread,
          })
        );
      }
      return true;
    }
    return false;
  };

  const handleBlockSendersBulk = (): void => {
    trackEvent({ event: 'blockSender' });
    dispatch(updateModalProps({ isBlockSendersBulk: true, currentFolder: folder }));
    dispatch(updateModalName('blockSenders'));
    dispatch(updateIsOpen(true));
  };

  const handleMarkAsSpam = (): void => {
    trackEvent({ event: 'markAsSpam' });
    dispatch(updateModalProps({ sourceMailboxId: folder, messageUids, currentMailId }));
    dispatch(updateModalName('markAsSpam'));
    dispatch(updateIsOpen(true));
  };

  const handleMarkAsNotSpam = async (): Promise<void> => {
    trackEvent({ event: 'markAsNotSpam' });
    const unseenMessagesCount = getUnseenMessagesCount(currentMessageCollection, messageUids);

    const result = await dispatch(
      moveMessagesThunk({
        moveMessagesHook: moveMessagesToNotSpam,
        payload: {
          messageUids,
          sourceMailboxId: spamFolderId,
          destinationMailboxId: appConstants.INBOX_MAIL_FOLDER_ID,
          unseenMessagesCount,
        },
      })
    ).unwrap();

    if (result.success) {
      trackEvent({ event: 'markAsNotSpamSuccess' });
      dispatch(updateToastMessage({ message: t('markAsNotSpamSuccess'), success: true }));

      const { limit, total, data } = messageCollection[folder];
      const lastPage = currentPage * limit > total;

      if (result.messageUids.length === data?.length && Number(currentPage) > 1 && lastPage) {
        navToPreviousPage();
      }

      if (messageUids.includes(parseInt(currentMailId || ''))) {
        closeMessage(folder, queryParams);
      }

      dispatch(reloadMessageCollection(true));

      return;
    }

    trackEvent({ event: 'markAsNotSpamFailure' });
    dispatch(updateModalProps({ messageUids, currentMailId }));
    dispatch(updateModalName('markAsNotSpam'));
    dispatch(updateIsOpen(true));
  };

  const handleBackClick = (): void => {
    dispatch(updateDrawerName('folderList'));
    dispatch(updateIsDrawerOpen(true));
  };

  const kebabMenuItems: KebabMenuItemsInterface[] = [
    {
      onClick: handleMoveMessages,
      icon: 'ki-move-to',
      label: t('moveTo'),
      opensModal: true,
      isMenuItemHidden: isDraftFolder(folder),
      ariaLabel: `Move ${selectedMessages.length} selected emails`,
    },
    {
      onClick: handleFlagMessages,
      icon: 'ki-flag',
      label: t('flag'),
      isMenuItemHidden: hasEveryFlagFromSelectedMessages(selectedMessages, currentMessageCollection, isMessageFlagged),
    },
    {
      onClick: handleUnflagMessages,
      icon: 'ki-flag',
      label: t('unflag'),
      isMenuItemHidden: hasEveryFlagFromSelectedMessages(
        selectedMessages,
        currentMessageCollection,
        isMessageUnflagged
      ),
    },
    {
      onClick: handleMarkAsRead,
      icon: 'ki-mail-mark-read',
      label: t('markAsRead'),
      isMenuItemHidden:
        isSentMailFolder(folder) ||
        isDraftFolder(folder) ||
        hasEveryFlagFromSelectedMessages(selectedMessages, currentMessageCollection, isMessageRead),
    },
    {
      onClick: handleMarkAsUnread,
      icon: 'ki-mail-mark-unread',
      label: t('markAsUnread'),
      isMenuItemHidden:
        isSentMailFolder(folder) ||
        isDraftFolder(folder) ||
        hasEveryFlagFromSelectedMessages(selectedMessages, currentMessageCollection, isMessageUnread),
    },
    {
      onClick: handleBlockSendersBulk,
      icon: 'ki-block-sender',
      label: selectedMessages.length > 1 ? t('blockSenders') : t('blockSender'),
      opensModal: true,
      isMenuItemHidden: isSentMailFolder(folder) || isDraftFolder(folder) || isSpamFolder(folder),
      ariaLabel: 'add to blocked senders',
    },
    {
      onClick: handleMarkAsSpam,
      opensModal: true,
      icon: 'ki-spam',
      label: t('markAsSpam'),
      isMenuItemHidden: isSentMailFolder(folder) || isDraftFolder(folder) || isSpamFolder(folder),
    },
    {
      onClick: handleMarkAsNotSpam,
      icon: 'ki-spam',
      label: t('markAsNotSpam'),
      isMenuItemHidden: !isSpamFolder(folder),
      opensModal: true,
    },
  ].filter((item) => !item.isMenuItemHidden);

  const selectIcon = isSelectedAll
    ? 'ki-square-checkbox-f'
    : selectedMessages?.length
    ? 'ki-square-checkbox-indeterminate-f'
    : 'ki-square-checkbox';

  const handleResize = (current: HTMLDivElement): void => {
    const handleMobileOffset = (width: number): number => (isMobile ? width + 152 : width);

    if (current && !isDraftFolder(folder) && !isSentMailFolder(folder)) {
      const { width } = current.getBoundingClientRect();
      switch (true) {
        case width < handleMobileOffset(534):
          setMaxItems(kebabMenuItems.length - 7);
          break;
        case width < handleMobileOffset(740):
          setMaxItems(kebabMenuItems.length - 5);
          break;
        case width < handleMobileOffset(888):
          setMaxItems(kebabMenuItems.length - 4);
          break;
        case width < handleMobileOffset(1070):
          setMaxItems(kebabMenuItems.length - 3);
          break;
        case width < handleMobileOffset(1240):
          setMaxItems(kebabMenuItems.length - 2);
          break;
        default:
          setMaxItems(kebabMenuItems.length);
      }
    } else if (current) {
      const { width } = current.getBoundingClientRect();
      switch (true) {
        case width < handleMobileOffset(300):
          setMaxItems(kebabMenuItems.length - 4);
          break;
        case width < handleMobileOffset(450):
          setMaxItems(kebabMenuItems.length - 3);
          break;
        case width < handleMobileOffset(500):
          setMaxItems(kebabMenuItems.length - 2);
          break;
        default:
          setMaxItems(kebabMenuItems.length);
      }
    }
  };

  const handleResizeRef = useCallback(
    (node: HTMLDivElement) => {
      handleResize(node);
      window.addEventListener('resize', () => handleResize(node));
      return () => window.removeEventListener('resize', () => handleResize(node));
    },
    [currentMailId, selectedMessages.length, folder, isMobile]
  );

  const messageOptions = (): JSX.Element => {
    return (
      <div className={styles.menuList}>
        <KiteButton
          className={styles.deleteButton}
          variant="borderless"
          size="shrinkwrap"
          onClick={
            isTrashFolder(folder) || isSentMailFolder(folder) || isDraftFolder(folder)
              ? handlePermanentDeleteMessageBulk
              : handleDeleteMessages
          }
          disabled={messageCollectionLoading || deleteButtonLoading || !!messageCollectionFetchError}
          aria-disabled={messageCollectionLoading || deleteButtonLoading || !!messageCollectionFetchError}
          loading={deleteButtonLoading}
          aria-label={'delete contacts'}
        >
          <div className={styles.messageOptionsButton}>
            <KiteIcon icon={'ki-trash'} />
            <span className={styles.label}>{t('delete')}</span>
          </div>
        </KiteButton>
        {kebabMenuItems.slice(0, maxItems < 0 ? 0 : maxItems).map((kebabMenuItem, idx) => {
          return (
            <KiteButton
              key={`emailoptions-button__${kebabMenuItem.label}-${idx}`}
              variant="borderless"
              size="shrinkwrap"
              onClick={(e) => {
                kebabMenuItem.onClick();
                if (kebabMenuItem.opensModal) {
                  updateModalRef(e.currentTarget);
                }
              }}
            >
              <div className={styles.messageOptionsButton}>
                <KiteIcon icon={kebabMenuItem.icon || ''} />
                <span>{kebabMenuItem.label}</span>
              </div>
            </KiteButton>
          );
        })}
        <div className={styles.moreOptions}>
          {maxItems < kebabMenuItems.length && (
            <label className={styles.messageListOptionsButton}>
              <KebabMenu
                aria-label={t('otherActions')}
                items={kebabMenuItems.slice(maxItems)}
                disabled={(messageCollectionLoading && !getMailLoading) || !!messageCollectionFetchError}
                aria-disabled={messageCollectionLoading && !getMailLoading}
              />
              {(!isReadingPaneEnabled || selectedMessages.length) && (
                <span className={styles.label}>{t('otherActions')}</span>
              )}
            </label>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${styles.messageOptions} ${currentMailId && !selectedMessages.length ? styles.fixedWidth : ''} ${
        messageCollectionLoading && !getMailLoading ? styles.messageOptionsDisable : ''
      }`}
      ref={handleResizeRef}
    >
      <div className={styles.mobileSection}>
        <BackButton
          isFolderDrawer
          onClick={handleBackClick}
          disableLabel={true}
          customStyle={styles.folderListButton}
        />
        <div className={styles.selectButtonBorder} />
      </div>
      {messageCollection[currentFolder || '']?.data.length ? (
        <>
          <KiteButton
            variant="borderless"
            size="shrinkwrap"
            onClick={handleSelectAllMessages}
            disabled={(messageCollectionLoading && !getMailLoading) || !!messageCollectionFetchError}
            aria-disabled={(messageCollectionLoading && !getMailLoading) || !!messageCollectionFetchError}
            className="e2e_select_button"
          >
            <div className={styles.messageOptionsButton}>
              <KiteIcon icon={selectIcon} />
              <span>
                {selectedMessages?.length ? t('selected', { amount: selectedMessages?.length }) : t('select')}
              </span>
            </div>
          </KiteButton>
          <div className={styles.selectButtonBorder} />
        </>
      ) : null}
      {selectedMessages && selectedMessages?.length > 0 ? (
        <div className={styles.messageOptionsSelected}>{messageOptions()}</div>
      ) : (
        <>
          <KiteButton
            variant="borderless"
            size="shrinkwrap"
            onClick={handleRefreshMessages}
            disabled={(messageCollectionLoading && !getMailLoading) || !!messageCollectionFetchError}
            aria-disabled={(messageCollectionLoading && !getMailLoading) || !!messageCollectionFetchError}
            loading={messageCollectionLoading && getMailLoading}
            className="e2e_refresh_button"
            aria-label={isInboxFolder(folder) ? 'get mail' : 'refresh'}
          >
            <div className={`${styles.messageOptionsButton} ${styles.refreshButton}`}>
              <KiteIcon icon={'ki-refresh'} />
              <span className={styles.messageSortText}>{isInboxFolder(folder) ? t('getMail') : t('refresh')}</span>
            </div>
          </KiteButton>
          {messageCollection[currentFolder || '']?.data.length ? (
            <div className={styles.sortMessagesButton}>
              <div className={styles.selectButtonBorder} />
              <SortOptions
                selected={sort || Sort.ArrivalDesc}
                getMessageCollection={getMessageCollection}
                messageCollectionLoading={messageCollectionLoading && !getMailLoading}
                messageCollectionFetchError={!!messageCollectionFetchError}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default MessageListOptions;
