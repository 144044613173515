import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import styles from 'components/inbox-messages-modal/styles/ViewHeader.module.scss';
import { KiteProgressIndicator } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { rawMessageStreamThunk } from 'thunks/message/rawMessageStreamThunk';
import ServerError from 'components/common/ServerError';
import { TrackingHook, useTracking } from 'react-tracking';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { Scrollbar } from 'components/common/scrollbar';
import { useDialogTranslation } from 'translation/hooks';

interface ViewHeaderProps {
  mailboxId: string;
  messageUid: number;
}

const ViewHeader: React.FC<ViewHeaderProps> = ({ mailboxId, messageUid }) => {
  const { t } = useDialogTranslation('viewHeaderCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const apiError = useAppSelector((state) => state.messageSlice.apiError);
  const rawMessage = useAppSelector((state) => state.messageSlice.rawMessage);
  const [message, setMessage] = useState('');
  const [isFocusVisible, setIsFocusVisible] = useState<boolean>(false);
  const isLoading = !apiError?.message && !rawMessage;

  const dispatch = useAppDispatch();

  const getRawMessage = (): void => {
    dispatch(
      rawMessageStreamThunk({
        payload: {
          folderId: mailboxId,
          messageUid,
        },
      })
    );
  };

  useEffect(() => {
    if (!rawMessage) {
      getRawMessage();
      trackEvent({ event: 'viewHeaderModalView' });
    } else {
      setMessage(rawMessage);
    }
  }, [rawMessage]);

  const toggleFocus = (e: React.FocusEvent<HTMLDivElement>): void => {
    if (e.currentTarget.classList.contains('focus-visible')) {
      setIsFocusVisible(true);
      return;
    }
    setIsFocusVisible(false);
  };

  return (
    <ModalContentWrapper className={`${modalStyle.wide}`}>
      <h5>{t('header')}</h5>

      <Scrollbar customClass={`${styles.messageBody} ${isFocusVisible ? 'focus-visible' : ''}`} forceScroll={true}>
        {apiError?.message && <ServerError retryFunction={getRawMessage} />}
        {isLoading && <KiteProgressIndicator id={'loading'} useOverlay={false} size="md" />}
        <div
          onBlur={(e) => toggleFocus(e)}
          onFocus={(e) => toggleFocus(e)}
          className={styles.rawContainer}
          tabIndex={0}
        >
          {message}
        </div>
      </Scrollbar>
    </ModalContentWrapper>
  );
};

export default ViewHeader;
