import React from 'react';
import { KiteIcon, KiteLink } from '@kite/react-kite';
import styles from 'components/footer/styles/Footer.module.scss';
import { footerColumns } from 'app-constants';
import { TrackingHook, useTracking } from 'react-tracking';
import { PageNSTranslationFileKeys, usePageTranslation } from 'translation/hooks';

const SocialMediaIcon: React.FC = () => {
  const { trackEvent }: TrackingHook = useTracking({ page: 'login', component: 'SocialMediaIcon' });
  const { t } = usePageTranslation('linksCopy');
  const itemsList = footerColumns.SOCIAL_MEDIA_ICONS.links.map(({ href, icon, label, uiName, stdName }, index) => {
    return (
      <span className={styles.socialMediaIcon} key={index}>
        <KiteLink
          target="_blank"
          href={href as string}
          type="inline"
          onClick={() =>
            trackEvent({
              event: 'loginFooterLink',
              linkObject: { uiName: uiName, stdName },
              url: href,
            })
          }
        >
          <KiteIcon
            className={styles.iconSizeKiteOverride}
            ariaLabel={t(label as PageNSTranslationFileKeys<'linksCopy'>)}
            icon={`ki-${icon}`}
          />
        </KiteLink>
      </span>
    );
  });

  return (
    <div className={styles.socialIconsContainer}>
      <h2 className={styles.srOnly}>{t('socialMediaDescription')}</h2>
      {itemsList}
    </div>
  );
};

export default SocialMediaIcon;
