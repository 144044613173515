import { FilterFormInterface } from 'components/setting-filter-form/initialState';
import { FilterEntity, FilterForm, YesOrNoEnum } from 'generated/graphql';
import { omit } from 'lodash';

export const apiToFilter = (filter: FilterEntity): FilterFormInterface => {
  const { actionPriority, clause, highPriority, name, rules } = filter;

  let filterAction, moveFolder;

  if (filter.actionDelete === 'yes') {
    filterAction = 'delete';
  } else {
    filterAction = 'move';
  }

  if (filter.moveFolder === 'none') {
    moveFolder = undefined;
  } else {
    moveFolder = filter.moveFolder!;
  }

  return {
    actionPriority,
    clause,
    filterAction,
    highPriority,
    moveFolder,
    name,
    rules: rules.map((rule) => ({
      ...omit(rule, '__typename'),
      operator: rule.operator === 'contains-not' ? 'containsNot' : rule.operator,
    })),
  } as FilterFormInterface;
};

export const transformExistingFilters = (filters: FilterEntity[]): FilterForm[] => {
  return filters.map((localState) => {
    const {
      actionDelete,
      actionForward,
      actionMove,
      actionPriority,
      clause,
      highPriority,
      moveFolder,
      name,
      rules,
      forwardList,
    } = localState;

    const filter: FilterForm = {
      name,
      clause,
      actionDelete,
      actionForward,
      actionMove,
      actionPriority,
      highPriority,
      rules: rules.map((rule) => ({
        ...omit(rule, '__typename'),
        operator: rule.operator === 'contains-not' ? 'containsNot' : rule.operator,
      })),
    } as FilterForm;

    if (moveFolder) {
      filter.moveFolder = moveFolder;
    } else {
      filter.moveFolder = 'none';
    }

    filter.actionForward = (actionForward || 'no') as YesOrNoEnum;
    if (forwardList && forwardList.length) {
      filter.forwardList = forwardList;
    } else {
      filter.forwardList = ['none'];
    }

    return filter;
  });
};

export const filtersToEntity = (filters: FilterForm[]): FilterEntity[] =>
  filters.map(
    (filter) =>
      ({
        ...filter,
        __typename: 'FilterEntity',
        rules: filter.rules.map((rule) => ({
          ...rule,
          __typename: 'FilterRulesEntity',
          operator: rule.operator === 'containsNot' ? 'contains-not' : rule.operator,
        })),
      } as FilterEntity)
  );

const transformFilterForm = (localState: FilterFormInterface, newFolderId?: string): FilterForm => {
  const { actionPriority, clause, filterAction, highPriority, moveFolder, newFolder, name, rules } = localState;

  const filter: FilterForm = {
    name,
    actionPriority,
    clause,
    highPriority,
    rules,
  } as FilterForm;

  switch (filterAction) {
    case 'delete':
      filter.actionDelete = 'yes' as YesOrNoEnum;
      filter.actionMove = 'no' as YesOrNoEnum;
      break;
    case 'move':
    default:
      filter.actionMove = 'yes' as YesOrNoEnum;
      filter.actionDelete = 'no' as YesOrNoEnum;
      break;
  }

  if (moveFolder) {
    if (moveFolder === 'createNew') {
      if (newFolderId) {
        filter.moveFolder = newFolderId;
      } else {
        filter.moveFolder = newFolder;
      }
    } else {
      filter.moveFolder = moveFolder;
    }
  }

  filter.actionForward = 'no' as YesOrNoEnum;
  filter.forwardList = [''];

  return filter;
};

export default transformFilterForm;
