import { KiteButton, KiteGrid, KiteGridCell } from '@kite/react-kite';
import styles from 'components/header/styles/MainHeader.module.scss';
import { TrackingHook, useTracking } from 'react-tracking';
import logoutUtil from 'utils/logoutUtil';
import Hamburger from 'components/common/Hamburger';
import Search from 'components/search';
import React, { memo } from 'react';
import isMobileSize from 'components/common/hooks/useMobileSize';
import NavSupportLinks from 'components/nav-support-links';
import { useAppDispatch } from 'hooks';
import { updateIsOpen, updateModalName } from 'slices/app';
import { appConstants } from 'appConstants';
import { getSpectrumNetBaseUrl } from 'app-constants';
import { usePageTranslation } from 'translation/hooks';

const MainHeader: React.FC = () => {
  const isMobile = isMobileSize();
  const { trackEvent }: TrackingHook = useTracking({ page: 'navigation', component: 'MainHeader' });
  const { t } = usePageTranslation('topNavigationCopy');

  const dispatch = useAppDispatch();

  const SpectrumLogoContainer: React.FC = () => (
    <a
      id="loginLogoLink"
      aria-label="spectrum"
      role="link"
      className={styles.loginLogoLink}
      href={getSpectrumNetBaseUrl()}
      onClick={() => trackEvent({ event: 'spectrumLogoLink' })}
      target="_blank"
      rel="noreferrer"
    >
      <img src={appConstants.ui.CHARTER_LOGO_LINK} alt="Spectrum" />
    </a>
  );

  const handleClick = (): void => {
    trackEvent({ event: 'logoutHeader' });
    logoutUtil();
  };

  const handleSpanishComingSoon = (): void => {
    trackEvent({ event: 'spanishButton' });
    dispatch(updateIsOpen(true));
    dispatch(updateModalName('spanishComingSoon'));
  };

  return (
    <>
      <nav className={styles.mainHeader}>
        <KiteGrid className={styles.headerGrid}>
          <KiteGridCell col={4} className={`${styles.headerLeftCell} ${styles.headerCell}`}>
            <span className={styles.spectrumLogoLeft}>
              <SpectrumLogoContainer />
            </span>
            <Hamburger styles={styles} authenticated={true} />
          </KiteGridCell>
          <KiteGridCell col={isMobile ? 8 : 4} className={`${styles.headerMiddleCell} ${styles.headerCell}`}>
            <span className={styles.mobileLogoContainer}>
              <SpectrumLogoContainer />
            </span>
            {!isMobile && <Search />}
          </KiteGridCell>
          <KiteGridCell col={4} className={`${styles.headerRightCell} ${styles.headerCell}`}>
            <NavSupportLinks />
            <KiteButton
              className={styles.spanishButton}
              variant={'borderless'}
              onClick={handleSpanishComingSoon}
              lang="es"
              role="link"
            >
              {t('inSpanish')}
            </KiteButton>
            <span className={styles.verticalSeparator} />
            <KiteButton className={styles.signOutLink} role="link" onClick={handleClick}>
              {t('signOut')}
            </KiteButton>
          </KiteGridCell>
        </KiteGrid>
      </nav>
      {isMobile && <Search isMobile={isMobile} />}
    </>
  );
};

export default memo(MainHeader);
