import Helix from '@charter/helix/build/portals/index.min';
import { HelixEventFn, HelixPayloadInterface } from 'libs/tracking/adapters/helix/helixAdapter';

export interface HelixComposePayloadInterface extends HelixPayloadInterface {
  mail?: 'example';
  type?: string;
  uiName?: string;
  errorMessage?: string;
  errorCode?: string;
  triggeredBy?: string;
  errorType?: string;
}

const submitComposeEvent: HelixEventFn<HelixComposePayloadInterface> = (payload) => {
  const { event, errorMessage, errorCode, triggeredBy, errorType } = payload;
  switch (event) {
    case 'openCompose': {
      Helix.track('WEBMAIL_featureStart_composeEmail', {
        msgCategory: 'composeEmail',
      });
      break;
    }
    case 'addCc': {
      Helix.track('WEBMAIL_selectAction_composeEmail_addCC');
      break;
    }
    case 'addBcc': {
      Helix.track('WEBMAIL_selectAction_composeEmail_addBCC');
      break;
    }
    case 'removeCc': {
      Helix.track('WEBMAIL_selectAction_composeEmail_removeCC');
      break;
    }
    case 'removeBcc': {
      Helix.track('WEBMAIL_selectAction_composeEmail_removeBCC');
      break;
    }
    case 'minimizeCompose': {
      Helix.track('WEBMAIL_selectAction_composeEmail_minimize');
      break;
    }
    case 'restoreCompose': {
      Helix.track('WEBMAIL_selectAction_composeEmail_maximize');
      break;
    }
    case 'insertSignature': {
      Helix.track('WEBMAIL_selectAction_composeEmail_insertSignature');
      break;
    }
    case 'signatureNotFound': {
      Helix.track('WEBMAIL_error_unableToInsertSignature');
      break;
    }
    case 'readReceiptEnabled': {
      Helix.track('WEBMAIL_selectAction_composeEmail_requestReadReceipt');
      break;
    }
    case 'readReceiptDisabled': {
      Helix.track('WEBMAIL_selectAction_composeEmail_removeReadReceipt');
      break;
    }
    case 'closeWindow': {
      Helix.track('WEBMAIL_selectAction_composeEmail_closeWindow');
      break;
    }
    case 'modalViewSaveDraft': {
      Helix.track('WEBMAIL_modalView_saveAsDraft');
      break;
    }
    case 'modalClose': {
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'saveDraftWindow': {
      Helix.track('WEBMAIL_selectAction_composeEmail_saveAsDraft', {
        currPageElemStdName: 'saveDraftFromEmail',
      });
      break;
    }
    case 'sendMail': {
      Helix.track('WEBMAIL_selectAction_composeEmail_sendButton');
      break;
    }
    case 'sendMailValidationError': {
      Helix.track('WEBMAIL_error_unableToSendEmail', {
        appErrorMessage: errorMessage,
      });
      break;
    }
    case 'sendMailSuccess': {
      Helix.track('WEBMAIL_featureStop_composeEmail', {
        msgCategory: 'composeEmail',
        opSuccess: true,
      });
      Helix.track('WEBMAIL_assetDisplayed_messageSentNotification');
      break;
    }
    case 'sendMailFailure': {
      Helix.track('WEBMAIL_featureStop_composeEmail', {
        msgCategory: 'composeEmail',
        opSuccess: false,
      });
      Helix.track('WEBMAIL_assetDisplayed_messageNotSentNotification', {
        currPageElemStrValue: errorMessage,
      });
      break;
    }
    case 'saveDraftModal': {
      Helix.track('WEBMAIL_selectAction_composeEmail_saveAsDraft', {
        currPageElemStdName: 'saveDraftFromModal',
      });
      break;
    }
    case 'discardDraftWindow': {
      Helix.track('WEBMAIL_selectAction_composeEmail_discardDraft', {
        currPageElemStdName: 'discardDraftFromEmail',
      });
      break;
    }
    case 'discardDraftModal': {
      Helix.track('WEBMAIL_selectAction_composeEmail_discardDraft', {
        currPageElemStdName: 'discardDraftFromModal',
      });
      break;
    }
    case 'editorFormatChange': {
      Helix.track('WEBMAIL_selectAction_textEditingIcons', {
        type: payload.type,
        uiName: payload.uiName,
      });
      break;
    }
    case 'composeImagePaste': {
      Helix.track('WEBMAIL_applicationActivity_insertImage_success');
      break;
    }
    case 'addAttachmentSuccess': {
      Helix.track('WEBMAIL_selectAction_composeEmail_addAttachement', {
        opToggleState: true,
      });
      break;
    }
    case 'addAttachmentFailure': {
      Helix.track('WEBMAIL_selectAction_composeEmail_addAttachement', {
        opToggleState: false,
      });
      Helix.track('WEBMAIL_error_unableToAddAttachment', {
        appErrorCode: errorCode,
        appErrorMessage: errorMessage,
      });
      Helix.track('WEBMAIL_assetDisplayed_unableToAddAttachmentMessage', {
        msgTriggeredBy: triggeredBy,
      });
      break;
    }
    case 'deleteAttachment': {
      Helix.track('WEBMAIL_selectAction_composeEmail_removeAttachement');
      break;
    }
    case 'saveDraftSuccess': {
      Helix.track('WEBMAIL_applicationActivity_saveAsDraftSuccess');
      break;
    }
    case 'saveDraftAPIError': {
      Helix.track('WEBMAIL_error_unableToSaveAsDraft', {
        appErrorCode: payload.errorCode,
        appErrorMessage: 'systemError',
      });
      break;
    }
    case 'saveDraftValidationError': {
      Helix.track('WEBMAIL_error_unableToSaveAsDraft', {
        appErrorCode: 'userError',
        appErrorMessage: payload.errorMessage,
      });
      break;
    }
    case 'addressBookErrorModal': {
      Helix.track('WEBMAIL_modalView_addressBook_unableToLoadYourContacts', {
        appErrorCode: errorCode,
      });
      break;
    }
    case 'unableToLoadContacts': {
      Helix.track('WEBMAIL_error_unableToLoadYourContacts', {
        appErrorCode: errorCode,
      });
      break;
    }
    case 'addressBookModalView': {
      Helix.track('WEBMAIL_modalView_addressBook_selectContacts');
      break;
    }
    case 'addressBookNoneSelected': {
      Helix.track('WEBMAIL_modalView_addressBook_selectAtLeastOneContact', {
        appErrorType: 'noneSelected',
      });
      break;
    }
    case 'addContactsSuccess': {
      Helix.track('WEBMAIL_applicationActivity_addressBookAddContacts_success', {
        opSuccess: true,
        visitAdditionalInformation: 'addContacts',
      });
      break;
    }
    case 'selectAll': {
      Helix.track('WEBMAIL_selectAction_addressBook_selectAll');
      break;
    }
    case 'addContacts': {
      Helix.track('WEBMAIL_selectAction_addContacts', {
        modalName: 'addressBook',
        modalType: 'search',
        modalText: 'Select Contacts',
      });
      break;
    }
    case 'close': {
      Helix.track('WEBMAIL_modalClose_user');
      break;
    }
    case 'aupModalView': {
      Helix.track('WEBMAIL_modalView_AUP_errorCodeModal', {
        appErrorType: Number(errorCode) < 500 ? '400' : '500',
        appErrorCode: errorCode,
        modalText: errorType,
      });
      break;
    }
    case 'aupTryAgain': {
      Helix.track('WEBMAIL_selectAction_AUP_errorCodeModal_tryAgain');
      break;
    }
    case 'aupCancel': {
      Helix.track('WEBMAIL_selectAction_AUP_errorCodeModal_cancel');
      Helix.track('WEBMAIL_modalClose');
      Helix.track('WEBMAIL_featureStop_composeEmail', {
        msgCategory: 'composeEmail',
        opSuccess: false,
      });
      break;
    }

    case 'aupOk': {
      Helix.track('WEBMAIL_selectAction_AUP_errorCodeModal_ok');
      Helix.track('WEBMAIL_modalClose');
      Helix.track('WEBMAIL_featureStop_composeEmail', {
        msgCategory: 'composeEmail',
        opSuccess: false,
      });
      break;
    }
    case 'aupClose': {
      Helix.track('WEBMAIL_selectAction_AUP_errorCodeModal_close');
      break;
    }
    default: {
      console.log('Event Case For Compose Not Found', event);
      return null;
    }
  }
  return null;
};

export default submitComposeEvent;
