import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MailboxEntity } from 'generated/graphql';

interface MailboxCollectionResponse {
  success?: MailboxEntity[];
  error?: ApolloError | undefined;
}

interface mailBoxCollectionArg {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMailBoxCollectionHook?: any;
}

export const mailboxCollectionGetThunk = createAsyncThunk(
  'mailBoxCollection',
  async (arg: mailBoxCollectionArg): Promise<MailboxCollectionResponse> => {
    try {
      const response = await arg.getMailBoxCollectionHook();
      return {
        success: response.data.getMailboxCollection,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
