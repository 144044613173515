import { KiteProgressIndicator } from '@kite/react-kite';
import styles from 'containers/styles/LoginContainer.module.scss';
import { useAppSelector } from 'hooks';
import { AuthContext } from 'providers/AuthProvider';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { redirectToPortal } from 'utils/logoutUtil';

const LoginContainer: React.FC = () => {
  const { isLoggedIn, authCode } = useContext(AuthContext);
  const isLoginLoading = useAppSelector((state) => state.loginSlice.isLoginLoading);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/user/canary');
    }
  }, [isLoggedIn]);

  if (!authCode && !isLoginLoading && !isLoggedIn) {
    redirectToPortal();
    return null;
  }

  return (
    <div className={styles.main}>
      <KiteProgressIndicator id={'loading'} useOverlay={true} size="md" className={styles.overlayLoader} />
    </div>
  );
};

export default LoginContainer;
