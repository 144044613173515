import styles from 'components/header/styles/Subheader.module.scss';
import { useAppSelector } from 'hooks';
import { useLocation, useParams } from 'react-router-dom';
import { usePageTranslation } from 'translation/hooks';

const ContactHeader: React.FC = () => {
  const { t } = usePageTranslation('contactViewCopy');
  const { labelId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isSearchEnabled = !!query.get('search');

  const currentLabelSelected = useAppSelector((state) => state.contactSlice.currentLabelSelected);

  let contactLabel = labelId === 'all' ? t('allContacts') : t('listHeader', { label: currentLabelSelected });

  if (isSearchEnabled) {
    contactLabel = t('searchResults');
  }

  return <div className={styles.subheader}>{contactLabel}</div>;
};

export default ContactHeader;
