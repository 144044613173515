import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import { useDeleteMessageMutation } from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import { permanentDeleteMessageThunk } from 'thunks/message-collection/permanentDeleteMessageThunk';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, useDialogTranslation } from 'translation/hooks';
import useNavToPreviousPage from 'components/common/hooks/useNavToPreviousPage';
import useCloseMessage from 'components/common/hooks/useCloseMessage';

interface PermanentDeleteMessageProps {
  messageUid: number;
  mailboxId: string;
  currentMailId?: string;
}

const PermanentDeleteMessage: React.FC<PermanentDeleteMessageProps> = ({ messageUid, mailboxId, currentMailId }) => {
  const { t } = useDialogTranslation('permanentDeleteMessageCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const focusOpener = useTarget('modal');
  const apiError = useAppSelector((state) => state.messageCollectionSlice.apiError);
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');

  const dispatch = useAppDispatch();

  const messageCollection = useAppSelector((state) => state.messageCollectionSlice.messageCollection);
  const [queryParams, currentPage, navToPreviousPage] = useNavToPreviousPage();
  const closeMessage = useCloseMessage();

  const [permanentDeleteMessageHook] = useDeleteMessageMutation();

  useEffect(() => {
    trackEvent({ event: 'deleteMessagesModalView' });
  }, []);

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  const isSaved = async (): Promise<boolean> => {
    trackEvent({ event: 'permanentDeleteButton' });
    const result = await dispatch(
      permanentDeleteMessageThunk({
        permanentDeleteMessageHook,
        payload: { messageUid, mailboxId },
      })
    ).unwrap();

    if (result.success) {
      trackEvent({ event: 'permanentDeleteSuccess' });

      const { limit, total, data } = messageCollection[mailboxId];
      const lastPage = currentPage * limit > total;

      if (data?.length === 1 && Number(currentPage) > 1 && lastPage) {
        navToPreviousPage();
      }

      if (messageUid === parseInt(currentMailId || '')) {
        closeMessage(mailboxId, queryParams);
      }

      dispatch(updateToastMessage({ message: t('successToastSingle'), success: true }));
      dispatch(updateIsOpen(false));

      return true;
    }

    trackEvent({ event: 'permanentDeleteFailure' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'emptyTrashCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t(alertDescription ? 'tryAgain' : 'delete'),
    isDelete: true,
  };

  return (
    <ModalContentWrapper>
      <h5>{t('headerSingle')}</h5>
      <p>{t('descriptionInfo')}</p>
      <p className={`${modalStyle.alertMessage} ${modalStyle.redFont}`}>{t('warning')}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default PermanentDeleteMessage;
