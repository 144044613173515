import { ContactFormInterface } from 'components/contact-form/initialState';
import { ContactAddressEntity, ContactEmailEntity, ContactForm, ContactPhoneEntity } from 'generated/graphql';
import { isEmpty, omit } from 'lodash';
import { transformToOldFormat } from './transformPhoneFormat';

const isEmptyAddress = (address: ContactAddressEntity): boolean => {
  return !(
    !isEmpty(address.streetAddress) ||
    !isEmpty(address.city) ||
    !isEmpty(address.state) ||
    !isEmpty(address.zipCode) ||
    !isEmpty(address.country)
  );
};

const transformAddress = (contactForm: ContactFormInterface): ContactAddressEntity[] => {
  const addresses: ContactAddressEntity[] = [];
  if (contactForm.homeAddress && !isEmptyAddress(contactForm.homeAddress)) {
    addresses.push({ ...omit(contactForm.homeAddress, '__typename'), addressType: 'HOME' });
  }

  if (contactForm.workAddress && !isEmptyAddress(contactForm.workAddress)) {
    addresses.push({ ...omit(contactForm.workAddress, '__typename'), addressType: 'WORK' });
  }

  return addresses;
};

const transformPhoneNumber = (contactForm: ContactFormInterface): ContactPhoneEntity[] => {
  const phoneNumbers: ContactPhoneEntity[] = [];

  if (contactForm.homePhone?.phoneNumber) {
    phoneNumbers.push({
      ...omit(contactForm.homePhone, '__typename'),
      phoneNumberType: 'HOME',
      phoneNumber: transformToOldFormat(contactForm.homePhone.phoneNumber),
    });
  }

  if (contactForm.mobilePhone?.phoneNumber) {
    phoneNumbers.push({
      ...omit(contactForm.mobilePhone, '__typename'),
      phoneNumberType: 'MOBILE',
      phoneNumber: transformToOldFormat(contactForm.mobilePhone.phoneNumber),
    });
  }

  if (contactForm.workPhone?.phoneNumber) {
    phoneNumbers.push({
      ...omit(contactForm.workPhone, '__typename'),
      phoneNumberType: 'WORK',
      phoneNumber: transformToOldFormat(contactForm.workPhone.phoneNumber),
    });
  }

  return phoneNumbers;
};
const transformEmails = ({ emails }: ContactFormInterface): ContactEmailEntity[] => {
  const transformedEmails: ContactEmailEntity[] = [];
  if (emails && emails.length > 0) {
    emails.forEach((email) => {
      transformedEmails.push({ ...omit(email, '__typename') });
    });
  }

  return transformedEmails;
};

const transformContactForm = (localState: ContactFormInterface): ContactForm => {
  const { firstName, lastName, displayName, nickName, title, company, dateOfBirth, url, notes } = localState;

  const contact: ContactForm = {
    firstName,
    lastName,
    displayName,
    nickName: nickName || '',
    title: title || '',
    company: company || '',
    dateOfBirth: dateOfBirth || '',
    url: url || '',
    notes: notes || '',
    phoneNumbers: transformPhoneNumber(localState),
    addresses: transformAddress(localState),
    emails: transformEmails(localState),
  };

  return contact;
};

export default transformContactForm;
