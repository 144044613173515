import { ValidationError } from 'yup';
import React from 'react';
import { contactSchema } from 'libs/validations/contact';
import { FieldValidatorInitialStateInterface } from 'hooks/useContactFieldValidatorError';
import { ContactAddressEntity, ContactEmailEntity, ContactPhoneEntity } from 'generated/graphql';
import { ContactValidatorTransKeys } from 'components/contact-form/sections/translationKeys';
import { ValidationKeys } from 'translation/hooks';

interface ContactMultiInputValidationInterface {
  [key: string]: ContactEmailEntity[] | ContactAddressEntity | ContactPhoneEntity | string | undefined | null;
}

export const getContactValidationErrorMessage = (
  errors: FieldValidatorInitialStateInterface,
  fieldName: string,
  translationKeys: ContactValidatorTransKeys
): ValidationKeys | '' => {
  return translationKeys[errors[fieldName] as keyof ContactValidatorTransKeys] || '';
};

const validateContactField = (
  field: string,
  value: ContactMultiInputValidationInterface,
  errors: FieldValidatorInitialStateInterface,
  setErrors: React.Dispatch<React.SetStateAction<FieldValidatorInitialStateInterface>>
): boolean => {
  try {
    contactSchema.validateSyncAt(field, value);
    setErrors({ ...errors, [field]: '' });
    return true;
  } catch (err) {
    if (err instanceof ValidationError) {
      const error = err.errors[0];
      setErrors({ ...errors, [field]: error });
    }
    return false;
  }
};

export default validateContactField;
