import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MailboxStatusEntity } from 'generated/graphql';

interface GlobalMailboxStatusThunkResponse {
  success?: MailboxStatusEntity[];
  error?: ApolloError | undefined;
}

interface GlobalMailboxStatusThunkArg {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getGlobalMailboxStatusHook?: any;
}

export const globalMailboxStatusThunk = createAsyncThunk(
  'globalMailboxStatus',
  async (arg: GlobalMailboxStatusThunkArg): Promise<GlobalMailboxStatusThunkResponse> => {
    try {
      const response = await arg.getGlobalMailboxStatusHook();
      return {
        success: response.data.getGlobalMailboxStatus,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
