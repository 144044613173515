import React from 'react';
import { KiteGridCell } from '@kite/react-kite';
import styles from 'components/footer/styles/Footer.module.scss';
import { LinkGroupType, LinkItemInterface } from 'app-constants';
import FooterColumnLink from './FooterColumnLink';
import { usePageTranslation } from 'translation/hooks';

const SpectrumColumn: React.FC<LinkGroupType> = ({ title, links }) => {
  const { t } = usePageTranslation('linksCopy');
  const itemsList = links.map((link, index) => (
    <p className={styles.content} key={index}>
      <FooterColumnLink {...(link as LinkItemInterface)} />
    </p>
  ));

  return (
    <KiteGridCell col={2} className={`${styles.linkColumn} ${styles.column}`}>
      <div className={styles.columnSpace}>
        <h2 className={styles.columnHeader}>{t(title || '')}</h2>
        {itemsList}
      </div>
    </KiteGridCell>
  );
};

export default SpectrumColumn;
