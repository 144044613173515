import { createSlice } from '@reduxjs/toolkit';
import { FilterEntity } from 'generated/graphql';
import { updateFilterThunk } from 'thunks/filter/updateFilterThunk';
import { ApolloError } from '@apollo/client';
import { getFilterThunk } from 'thunks/filter/getFilterThunk';
import { filtersToEntity } from 'utils/transformFilterForm';

interface FilterCollectionState {
  filters?: FilterEntity[];
  apiError?: Error | null;
}

const initialState: FilterCollectionState = {};

export const filterCollectionSlice = createSlice({
  name: 'filterCollection',
  initialState,
  reducers: {
    resetFilterApiError: (state: FilterCollectionState) => {
      state.apiError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFilterThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.filters = action.payload.success;
        state.apiError = null;
      }

      if (!action.payload.success) {
        state.apiError = action.payload.error as ApolloError;
      }
    });

    builder.addCase(updateFilterThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.apiError = null;
        state.filters = filtersToEntity(action.payload.filters || []);
      }
      if (!action.payload.success) {
        if (action.payload.success === false) {
          state.apiError = new ApolloError({ errorMessage: 'action.payload.success === false' });
          return;
        }

        state.apiError = action.payload.error as ApolloError;
      }
    });
  },
});

export const { resetFilterApiError } = filterCollectionSlice.actions;

export default filterCollectionSlice.reducer;
