export const emailViewCopy = {
  to: '',
  reply: '',
  replyAll: '',
  forward: '',
  emptyMailbox: '',
  noEmailContent: '',
  from: '',
  date: '',
  me: '',
  read: '',
  unread: '',
  flagged: '',
  subject: '',
  priority: '',
  hideAll: '',
  showAll: '',
  noRecipient: '',
  noSubject: '',
  emailLinkAria: '',
  attachment: '',
  attachments: '',
  today: '',
  notToday: '',
  notFoundHeader: '',
  notFoundDescription: '',
};
