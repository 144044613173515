import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MailForm, SentMailMutationFn, SmtpResponseEntity } from 'generated/graphql';

interface SentMailThunkArg {
  payload: {
    index: string;
    mail: MailForm;
    isReadReceipt?: boolean;
    displayName: string;
    isOpenedFromDraft?: boolean;
    currentFolder: string;
  };
  sentMailHook: SentMailMutationFn;
}

export interface SentMailThunkResponse {
  success?: boolean;
  error?: ApolloError;
  index: string;
  isReadReceipt?: boolean;
  isOpenedFromDraft?: boolean;
  currentFolder?: string;
  smtpResponse?: SmtpResponseEntity;
}

export const sentMailThunk = createAsyncThunk(
  'sentMailThunk',
  async (arg: SentMailThunkArg): Promise<SentMailThunkResponse> => {
    try {
      const response = await arg.sentMailHook({
        variables: { ...arg.payload },
      });

      const smtpResponse = response?.data?.sentMail.smtpResponse as SmtpResponseEntity;

      return {
        index: arg.payload.index,
        success:
          (response.data?.sentMail.isSentToSmtp && smtpResponse?.responseCode && smtpResponse?.responseCode < 400) ||
          false,
        isReadReceipt: arg.payload.isReadReceipt,
        isOpenedFromDraft: arg.payload.isOpenedFromDraft,
        currentFolder: arg.payload.currentFolder,
        smtpResponse,
      };
    } catch (e) {
      return {
        isReadReceipt: arg.payload.isReadReceipt,
        index: arg.payload.index,
        error: e as ApolloError,
      };
    }
  }
);
