export const emailNavigationCopy = {
  compose: '',
  emptyTrash: '',
  folders: '',
  newFolder: '',
  storage: '',
  filled: '',
  newSubfolder: '',
  rename: '',
  delete: '',
};
