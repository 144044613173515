import { KiteAlert, KiteCard, KiteTextInput } from '@kite/react-kite';
import styles from 'components/setting-reply-to/styles/ReplyTo.module.scss';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import SaveCancel from 'components/save-cancel';
import { useAppDispatch, useAppSelector } from 'hooks';
import { resetApiError } from 'slices/setting/setting';
import { updateReplyTo } from 'thunks/setting/replyToThunk';
import { usePatchReplyToMutation } from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import isEmail from 'validator/lib/isEmail';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import useNavBlockingDraft from 'hooks/useNavBlockingDraft';
import { ValidationKeys, usePageTranslation } from 'translation/hooks';

const SettingReplyTo: React.FC = () => {
  const { t } = usePageTranslation('replyToCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'settings' });

  const { replyTo, apiError } = useAppSelector((state) => state.settingSlice);
  const [input, setInput] = useNavBlockingDraft(replyTo);
  const [errorMessage, setErrorMessage] = useState<ValidationKeys | ''>('');

  const dispatch = useAppDispatch();
  const [updateReplyToHook] = usePatchReplyToMutation();

  useMemo(() => {
    trackEvent({ event: 'replyToPageViewInit' });
  }, []);

  useEffect(() => {
    trackEvent({ event: 'replyToPageView' });
  }, []);

  useEffect(() => {
    setInput(replyTo);
  }, [replyTo]);

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInput(event.target.value);
    onValidate(event.target.value);
  };

  const onSave = async (): Promise<boolean> => {
    trackEvent({ event: 'replyToSave' });
    resetError();

    const result = (await dispatch(
      updateReplyTo({
        payload: { value: input },
        updateReplyToHook,
      })
    )) as { payload: { success: boolean } };

    if (result.payload.success) {
      trackEvent({ event: 'replyToSaveSuccess' });
      return true;
    }

    trackEvent({ event: 'replyToSaveError' });
    return false;
  };

  const isChanged = (): boolean => {
    return replyTo !== input;
  };

  const onValidate = (input: string): void => {
    if (!input) {
      return;
    }
    if (!isEmail(input)) {
      trackEvent({ event: 'replyToValidationError' });
      setErrorMessage('invalidEmail');
      return;
    }
    setErrorMessage('');
  };

  const onCancel = (): boolean => {
    trackEvent({ event: 'replyToCancel' });
    setErrorMessage('');
    resetError();
    setInput(replyTo);
    return true;
  };

  const resetError = (): void => {
    dispatch(resetApiError());
  };

  return (
    <KiteCard className={styles.fieldForm}>
      <div className={styles.title}>
        <AutoFocusHeader content={t('title')} />
        <span>{t('description')}</span>
      </div>
      {apiError && (
        <KiteAlert
          className={styles.alert}
          type="error"
          description={t(apiError ? 'unableToSaveErr' : '')}
          level="page"
          dismissible
          onClose={resetError}
        />
      )}
      <KiteTextInput
        className={styles.field}
        value={input}
        onChange={(e) => onChange(e as ChangeEvent<HTMLInputElement>)}
        placeholder={t('placeholder')}
        label={t('label')}
        errorMessage={t(errorMessage)}
        ariaLive="polite"
      />
      <div className={styles.buttonBar}>
        <SaveCancel
          isChanged={isChanged}
          isCancelled={onCancel}
          isSaved={onSave}
          draft={input}
          isValidate={() => !errorMessage}
          shouldDisableCancel={true}
        />
      </div>
    </KiteCard>
  );
};

export default SettingReplyTo;
