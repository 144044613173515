import styles from 'components/inbox-compose-modal/styles/AddressBook.module.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  KiteColumn,
  KiteDataTable,
  KiteTableHeader,
  KiteCheckbox,
  KiteRow,
  KiteCell,
  KiteProgressIndicator,
  KiteButton,
  KiteSelect,
  KiteAlert,
  KiteTextInput,
  KiteMenu,
  KiteMenuItem,
  KiteIcon,
} from '@kite/react-kite';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { Scrollbar } from 'components/common/scrollbar';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import { useEffect, useState } from 'react';
import { ContactEntity } from 'generated/graphql';
import { getDisplayNameAddressBook } from 'components/compose-input-field/utils';
import { ComposeFormInput, addBcc, addCc, addTo } from 'slices/compose';
import { updateIsOpen, updateModalProps } from 'slices/app';
import { EmailAddress } from 'models/Email';
import Highlighted from 'components/common/Highlighted';
import _ from 'lodash';
import { DialogNSTranslationFileKeys, useDialogTranslation } from 'translation/hooks';
import { TrackingHook, useTracking } from 'react-tracking';
import { useTarget } from 'components/common/focus/FocusContext';
import { positionValues } from 'react-custom-scrollbars-2';
interface AddressBookInterface {
  inputField: string;
}

export type AddressBookContact = {
  id: string;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  isSelected: boolean;
  isHidden: boolean;
  isAdded: boolean;
  isQueryMatch: boolean;
  labels: string[];
};

const AddressBook: React.FC<AddressBookInterface> = ({ inputField }) => {
  const { t } = useDialogTranslation('addressBookCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'compose' });
  const [contactList, setContactList] = useState<AddressBookContact[]>([]);
  const [filteredList, setFilteredList] = useState<AddressBookContact[]>([]);
  const [showStickyHeader, setShowStickyHeader] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [filterByLabel, setFilterByLabel] = useState('allContacts');
  const [sortBy, setSortBy] = useState('');
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');
  const apiError = useAppSelector((state) => state.contactAutocompleteSlice.apiError);
  const contacts = useAppSelector((state) => state.contactAutocompleteSlice.contacts);
  const labels = useAppSelector((state) => state.contactAutocompleteSlice.labels);
  const { isAddressBook } = useAppSelector((state) => state.appSlice.modalProps);
  const reloadContactsCollection = useAppSelector((state) => state.contactAutocompleteSlice.reloadContactsCollection);
  const activeComposeTab = useAppSelector(
    (state) => state.composeSlice.composeCollection[state.composeSlice.activeTabIndex]
  );
  const focusOpener = useTarget('modal');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (contacts) {
      trackEvent({ event: 'addressBookModalView' });
      setContactList(generateContactList(contacts));
    }
  }, [contacts, reloadContactsCollection]);

  useEffect(() => {
    if (contacts) {
      if (error) {
        setError('');
      }

      setFilteredList(
        contactList
          .filter(({ isAdded }) => !isAdded)
          .filter(({ labels }) => (filterByLabel !== 'allContacts' ? labels.includes(filterByLabel) : true))
      );
    }
  }, [contactList, filterByLabel]);

  useEffect(() => {
    if (filteredList.length) {
      if (query.length >= 3) {
        setIsSelectedAll(
          filteredList
            .filter(({ isQueryMatch }) => (query.length >= 3 ? isQueryMatch : true))
            .every((contact) => contact.isSelected)
        );
        return;
      }

      setIsSelectedAll(filteredList.every((contact) => contact.isSelected));
    } else {
      setIsSelectedAll(false);
    }
  }, [isSelectedAll, contactList, filteredList, filterByLabel, query]);

  useEffect(() => {
    if (query.length >= 3) {
      searchAddressBook();
    }
  }, [query]);

  useEffect(() => {
    if (apiError && isAddressBook) {
      trackEvent({ event: 'addressBookErrorModal', errorCode: apiError.message });
      trackEvent({ event: 'unableToLoadContacts', errorCode: apiError.message });
      dispatch(updateModalProps({ isAddressBook: false }));
    }
  }, [apiError]);

  const generateContactList = (contactCollection: ContactEntity[]): AddressBookContact[] => {
    const transformed: AddressBookContact[] = [];

    contactCollection.forEach(({ displayName, firstName, emails, id, lastName, labels }) => {
      if (emails?.length) {
        const getIsAdded = (address: string): boolean => {
          const existingTags = (activeComposeTab[inputField as keyof ComposeFormInput] as EmailAddress[]) || [];
          return !!existingTags.some((tag) => tag.address === address);
        };

        emails.forEach(({ emailAddress }, idx) => {
          transformed.push({
            id: `${id}-${idx}`,
            contactId: id,
            emailIndex: idx,
            displayName: displayName || '',
            firstName: firstName || '',
            lastName: lastName || '',
            email: emailAddress,
            isSelected: false,
            isHidden: false,
            isQueryMatch: false,
            isAdded: getIsAdded(emailAddress),
            labels: labels || [],
          } as AddressBookContact);
        });
      }
    });

    return transformed;
  };

  const handleScrollFrame = (values: positionValues): void => {
    setShowStickyHeader(values.scrollTop > 168);
  };

  const handleCheckboxChange = (id: string): void => {
    const toggledContacts = [...contactList];
    const index = toggledContacts.findIndex(({ id: contactId }) => contactId === id);
    toggledContacts[index].isSelected = !toggledContacts[index].isSelected;

    const emailToFilter = toggledContacts[index].email;

    setContactList(
      toggledContacts.map((contact, idx) => {
        if (index === idx) {
          return contact;
        }

        if (emailToFilter === contact.email) {
          return {
            ...contact,
            isHidden: toggledContacts[index].isSelected,
          };
        }
        return contact;
      })
    );
  };

  const handleSelectAll = (): void => {
    trackEvent({ event: 'selectAll' });
    const selectAllList = filteredList
      .filter(({ isQueryMatch }) => (query.length >= 3 ? isQueryMatch : true))
      .map((contact) => ({ ...contact, isSelected: !isSelectedAll }));

    const updatedContactList = [...contactList];
    selectAllList.forEach((contact) => {
      const index = updatedContactList.findIndex((source) => source.id === contact.id);
      updatedContactList[index].isSelected = !isSelectedAll;
    });

    setIsSelectedAll(!isSelectedAll);
    setContactList(updatedContactList);
    setFilteredList(selectAllList);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setQuery(event.target.value);
  };

  const searchAddressBook = (): void => {
    const searchList = filteredList.map((contact) => {
      if (
        contact.displayName.toLowerCase().includes(query.toLowerCase()) ||
        contact.firstName.toLowerCase().includes(query.toLowerCase()) ||
        contact.lastName.toLowerCase().includes(query.toLowerCase()) ||
        contact.email.toLowerCase().includes(query.toLowerCase())
      ) {
        contact.isQueryMatch = true;
        return contact;
      }

      contact.isQueryMatch = false;
      return contact;
    });

    setFilteredList(searchList);
  };

  const handleAddContacts = (): void => {
    trackEvent({ event: 'addContacts' });
    if (!contactList.filter((contact) => contact.isSelected).length) {
      trackEvent({ event: 'addressBookNoneSelected' });
      setError('noneSelected');
      return;
    }

    contactList
      .filter((contact) => contact.isSelected)
      .forEach((contact) => {
        const updatedTag = {
          name: getDisplayNameAddressBook(contact),
          address: contact.email,
        };

        if (inputField === 'to') {
          dispatch(addTo(updatedTag));
        }
        if (inputField === 'cc') {
          dispatch(addCc(updatedTag));
        }
        if (inputField === 'bcc') {
          dispatch(addBcc(updatedTag));
        }
      });

    trackEvent({ event: 'addContactsSuccess' });
    focusOpener();
    dispatch(updateIsOpen(false));
  };

  const handleCancel = (): void => {
    trackEvent({ event: 'close' });
    focusOpener();
    dispatch(updateIsOpen(false));
  };

  const handleFilterByLabel = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setFilterByLabel(event.target.value);
  };

  const contactLabels = labels?.map((label) => (
    <option key={label.id} value={label.id}>
      {label.name}
    </option>
  ));

  const selectedCount = contactList?.reduce((acc, curr) => {
    if (curr.isSelected && !curr.isHidden) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const sortItems = [{ value: 'displayName' }, { value: 'email' }, { value: 'firstName' }, { value: 'lastName' }];

  let renderList = filteredList
    .filter(({ isQueryMatch }) => (query.length >= 3 ? isQueryMatch : true))
    .filter(({ isHidden }) => !isHidden);

  if (sortBy) {
    renderList = _.sortBy(renderList, (contact) => contact[sortBy as keyof AddressBookContact]);
  }

  const highlightText = query.length >= 3 ? query : '';

  if (apiError) {
    return (
      <ModalContentWrapper className={styles.style}>
        <div className={styles.errorHeaderContainer}>
          <KiteIcon className={styles.errorIcon} icon={'ki-caution-circle-f'} size="24px" fill="#d6312b" />
          <AutoFocusHeader content={t('errorHeader')} customStyles={styles.header} />
        </div>
        <div>{t('errorDescription')}</div>
        <div className={styles.ok}>
          <KiteButton onClick={handleCancel}>{t('ok')}</KiteButton>
        </div>
      </ModalContentWrapper>
    );
  }

  return (
    <ModalContentWrapper>
      <AutoFocusHeader content={t('defaultHeader')} customStyles={styles.headerDesktop} />
      <div className={`${styles.columnHeaderSticky} ${showStickyHeader ? styles.showSticky : ''}`}>
        <div className={styles.displayNameColumn}>{t('displayName')}</div>
        <div className={styles.emailColumn}>{t('email')}</div>
        <div className={styles.firstNameColumn}>{t('firstName')}</div>
        <div className={styles.lastNameColumn}>{t('lastName')}</div>
      </div>
      <Scrollbar forceScroll={true} customClass={styles.scrollbar} handleScrollFrame={handleScrollFrame}>
        <AutoFocusHeader content={t('defaultHeader')} customStyles={styles.headerMobile} />
        {error.length ? (
          <KiteAlert className={styles.errorAlert} level={'page'} type={'error'}>
            {t('addressBookNoneSelected')}
          </KiteAlert>
        ) : null}
        <div className={styles.inputWrapper}>
          <KiteSelect
            className={styles.selectLabel}
            id="contactLabels"
            label={t('filterBy')}
            value={filterByLabel}
            name="selectLabel"
            onChange={handleFilterByLabel}
            aria-label={'filter contacts by'}
          >
            <option id="allContacts" value="allContacts">
              {t('allContacts')}
            </option>
            {contactLabels}
          </KiteSelect>
          <div className={styles.searchWrapper}>
            <KiteIcon className={styles.icon} icon={'ki-search'} size="20px" />
            <KiteTextInput
              aria-label={'search contacts'}
              className={styles.searchInput}
              onChange={handleSearchChange}
              placeholder={t('searchContacts')}
            />
          </div>
        </div>
        <div className={styles.resultSortWrapper}>
          <div className={styles.resultCount} aria-live="polite">{`${renderList.length} ${t('found')}`}</div>
          <div className={styles.sortWrapper}>
            <div className={styles.sortLabel}>{t('sortBy')}</div>
            <KiteMenu
              label={t(sortBy as DialogNSTranslationFileKeys<'addressBookCopy'>) || t('firstName')}
              ariaLabel={'sort contacts by'}
            >
              {sortItems.map((item) => (
                <KiteMenuItem key={item.value} onSelect={() => setSortBy(item.value)}>
                  {t(item.value as DialogNSTranslationFileKeys<'addressBookCopy'>)}
                </KiteMenuItem>
              ))}
            </KiteMenu>
          </div>
        </div>
        <div className={styles.selectAllWrapper}>
          <KiteCheckbox
            id={'address-book-select-all'}
            checked={renderList.length ? isSelectedAll : false}
            label={t('selectAll')}
            name={'address-book-select-all'}
            onChange={handleSelectAll}
            disabled={!renderList.length}
            aria-label={'select all contacts'}
          />
        </div>
        <div className={styles.tableContainer}>
          <div className={`${styles.checkboxContainer} ${showStickyHeader ? styles.removeMargin : ''}`}>
            {renderList.map(({ id, isSelected, displayName }, idx) => {
              return (
                <KiteCheckbox
                  key={`address-book-check-${idx}`}
                  className={styles.checkbox}
                  id={id}
                  checked={isSelected}
                  label={''}
                  name={id}
                  onChange={() => handleCheckboxChange(id)}
                  aria-label={`Row ${idx + 1} ${displayName} checkbox ${isSelected ? 'checked' : 'unchecked'}`}
                />
              );
            })}
          </div>
          <div className={`${styles.kiteTableWrapper} ${showStickyHeader ? styles.hideColumns : ''}`}>
            <KiteDataTable title={''} zebraStripe={'striped'}>
              <KiteTableHeader>
                <KiteColumn className={styles.displayNameColumn}>{t('displayName')}</KiteColumn>
                <KiteColumn className={styles.emailColumn}>{t('email')}</KiteColumn>
                <KiteColumn className={styles.firstNameColumn}>{t('firstName')}</KiteColumn>
                <KiteColumn className={styles.lastNameColumn}>{t('lastName')}</KiteColumn>
              </KiteTableHeader>
              <tbody>
                {!contacts ? (
                  <KiteRow>
                    <KiteCell colSpan={6}>
                      <KiteProgressIndicator id="kp1" />
                    </KiteCell>
                  </KiteRow>
                ) : (
                  <>
                    {renderList.map(({ displayName, firstName, lastName, email }, index) => {
                      return (
                        <KiteRow key={'kite-table-row-' + index}>
                          <KiteCell className={styles.displayNameColumn}>
                            <Highlighted text={displayName} highlight={highlightText} />
                          </KiteCell>
                          <KiteCell className={styles.emailColumn}>
                            <Highlighted text={email} highlight={highlightText} />
                          </KiteCell>
                          <KiteCell className={styles.firstNameColumn}>
                            <Highlighted text={firstName} highlight={highlightText} />
                          </KiteCell>
                          <KiteCell className={styles.lastNameColumn}>
                            <Highlighted text={lastName} highlight={highlightText} />
                          </KiteCell>
                        </KiteRow>
                      );
                    })}
                  </>
                )}
              </tbody>
            </KiteDataTable>
          </div>
        </div>
      </Scrollbar>
      <div className={styles.cta}>
        <KiteButton onClick={handleAddContacts}>
          {t(`${selectedCount === 1 ? 'addContact' : 'addContacts'}`, { selectedCount: selectedCount || 0 })}
        </KiteButton>
        <KiteButton variant={'secondary'} onClick={handleCancel}>
          {t('cancel')}
        </KiteButton>
      </div>
    </ModalContentWrapper>
  );
};

export default AddressBook;
