import { createAsyncThunk } from '@reduxjs/toolkit';
import { getServerEndpoint } from 'app-constants';
import axios, { AxiosError } from 'axios';
import buildAccessTokenHeader from 'utils/buildAccessTokenHeader';

import { trackCall } from 'utils/apiTracking';
import buildVisitAndTraceIdHeaders from 'utils/buildVisitAndTraceIdHeaders';

interface uploadAttachmentThunkArgs {
  payload: {
    formData: FormData;
    index: string;
  };
}

interface uploadAttachmentThunkResponse {
  index: string;
  success?: {
    fileSize: number;
    externalRef: string;
    fileName: string;
    id: string;
  };
  error?: AxiosError;
}

export const uploadAttachmentThunk = createAsyncThunk(
  'uploadAttachmentThunk',
  async (arg: uploadAttachmentThunkArgs): Promise<uploadAttachmentThunkResponse> => {
    const startTime = new Date().getTime();

    const visitAndTraceId = buildVisitAndTraceIdHeaders();
    try {
      const result = await axios({
        method: 'post',
        url: `${getServerEndpoint()}/api/v1/storage`,
        data: arg.payload.formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          ...buildAccessTokenHeader(),
          ...visitAndTraceId,
        },
      });
      const responseBodySize = new TextEncoder().encode(JSON.stringify(result.data)).length;
      trackCall(result, startTime, visitAndTraceId['X-WEBMAIL-TRACE-ID'], responseBodySize);

      return {
        index: arg.payload.index,
        success: { ...result.data },
      };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorResponseSize = new TextEncoder().encode(JSON.stringify(err.response?.data)).length;
        trackCall(err, startTime, visitAndTraceId['X-WEBMAIL-TRACE-ID'], errorResponseSize);
      }

      return {
        index: arg.payload.index,
        error: err as AxiosError,
      };
    }
  }
);
