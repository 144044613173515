import React from 'react';
import { Route, Routes, useParams, useLocation } from 'react-router-dom';
import contactLayoutStyles from 'containers/styles/ContactLayoutContainer.module.scss';
import { Allotment } from 'allotment';
import ContactLabelListContainer from 'containers/ContactLabelListContainer';
import ContactCreate from 'components/contact-form/Create';
import ContactUpdate from 'components/contact-form/Update';
import ContactListViewContainer from 'containers/ContactListViewContainer';
import ContactViewPlaceholder from 'components/contact-view/ContactViewPlaceholder';
import ContactView from 'components/contact-view';
import ContactHeader from 'components/header/sub-headers/ContactHeader';
import ContactListOptionSelect from 'components/contact-list-options/ContactListOptionSelect';
import { useAppSelector } from 'hooks';
import ContactViewOption from 'components/contact-list-options/ContactViewOption';
import { appConstants } from 'appConstants';
import getMinMaxSizes from 'utils/getMinMaxSizes';

const DesktopLayout: React.FC = () => {
  const { '*': contactId } = useParams();
  const isThreePaneEnabled = !!contactId;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isReadingPaneExpanded = !!query.get('expand');
  const selectedMessages = useAppSelector((state) => state.contactSlice.selectedContacts) || [];
  const contact = useAppSelector((state) => state.contactSlice.contact);

  return (
    <div className={contactLayoutStyles.contactLayoutContainer}>
      <div className={contactLayoutStyles.contactContentLayout}>
        <Allotment proportionalLayout={true} separator={false} defaultSizes={appConstants.ui.DEFAULT_OUTER_PANE_SIZES}>
          <Allotment.Pane {...getMinMaxSizes(isThreePaneEnabled)[0]} className={contactLayoutStyles.fullHeight}>
            <ContactLabelListContainer />
          </Allotment.Pane>
          <Allotment.Pane className={contactLayoutStyles.fullHeight}>
            <Routes>
              <Route path={'/new-contact'} element={<ContactCreate />} />
              <Route path={`/:contactId/update`} element={<ContactUpdate />} />
              <Route
                path={'/*'}
                element={
                  <>
                    <ContactHeader />
                    <div className={contactLayoutStyles.options}>
                      {!isReadingPaneExpanded ? (
                        <>
                          <ContactListOptionSelect />
                          {contactId && contact && !selectedMessages.length && <ContactViewOption />}
                        </>
                      ) : (
                        <ContactViewOption />
                      )}
                    </div>
                    <Allotment
                      proportionalLayout={true}
                      separator={false}
                      defaultSizes={appConstants.ui.DEFAULT_INNER_PANE_SIZES}
                    >
                      <Allotment.Pane {...getMinMaxSizes(isThreePaneEnabled)[1]} visible={!isReadingPaneExpanded}>
                        <ContactListViewContainer />
                      </Allotment.Pane>
                      <Allotment.Pane {...getMinMaxSizes(isThreePaneEnabled)[2]} visible={isThreePaneEnabled}>
                        <ContactViewPlaceholder />
                        <Routes>
                          <Route path={'/:contactId'} element={<ContactView />} />
                        </Routes>
                      </Allotment.Pane>
                    </Allotment>
                  </>
                }
              />
            </Routes>
          </Allotment.Pane>
        </Allotment>
      </div>
    </div>
  );
};

export default DesktopLayout;
