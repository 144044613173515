import { FieldValidatorInitialStateInterface } from 'hooks/useContactFieldValidatorError';
import React from 'react';

export interface FilterSectionProps<T> {
  updateFormValues: (value: FilterFormInterface[keyof FilterFormInterface], field: keyof FilterFormInterface) => void;
  values: T;
  errors: FieldValidatorInitialStateInterface;
  setErrors: React.Dispatch<React.SetStateAction<FieldValidatorInitialStateInterface>>;
}

export interface FilterFormInterface {
  name: string;
  clause: string;
  highPriority?: string;
  actionPriority?: string;
  filterAction: string;
  moveFolder?: string;
  newFolder?: string;
  rules: FilterFormRule[];
}

export interface FilterFormRule {
  field: string;
  operator: string;
  value: string;
}

export const initialFilterRule: FilterFormRule = {
  field: 'from',
  operator: 'contains',
  value: '',
};

export const initialState: FilterFormInterface = {
  name: '',
  clause: 'all',
  highPriority: 'no',
  actionPriority: 'no',
  filterAction: 'move',
  moveFolder: '',
  rules: [initialFilterRule],
};
