import Helix from '@charter/helix/build/portals/index.min';
import HelixExtensions from '@charter/helix/build/extensions';
import concatUrl from 'utils/concatUrl';
import { RouteInterface } from 'components/router-switch/mainRoutes';
import { LinkItemInterface } from 'app-constants';
import { HelixEventFn, HelixPayloadInterface } from 'libs/tracking/adapters/helix/helixAdapter';

interface HelixNavigationPayloadInterface extends HelixPayloadInterface {
  route?: RouteInterface;
  linkObject?: LinkItemInterface;
}

const submitNavigationEvent: HelixEventFn<HelixNavigationPayloadInterface> = (payload) => {
  const { event } = payload;
  switch (event) {
    case 'navigationTab': {
      Helix.track('SPECTRUM_selectAction_header_localNav', {
        currPageElemStdName: `mainNav-${payload.route?.name}`,
        currPageElemUiName: payload.route?.uiName,
      });
      break;
    }

    case 'hamburgerLink': {
      Helix.track('SPECTRUM_selectAction_header_hamburger', {
        currPageElemUiName: payload.linkObject?.uiName,
        currPageElemStdName: payload.linkObject?.stdName,
      });
      break;
    }
    case 'hamburgerNavLink': {
      Helix.track('SPECTRUM_selectAction_header_hamburger', {
        currPageElemUiName: payload.linkObject?.uiName,
        currPageElemStdName: `hamburger-${payload.linkObject?.name}`,
      });
      break;
    }
    case 'hamburgerOpen': {
      Helix.track('SPECTRUM_selectAction_header_hamburger', {
        currPageElemUiName: 'icon-open',
        currPageElemStdName: 'hamburger-openHamburger',
      });
      break;
    }
    case 'hamburgerClose': {
      Helix.track('SPECTRUM_selectAction_header_hamburger', {
        currPageElemUiName: 'icon-close',
        currPageElemStdName: `hamburger-closeHamburger`,
      });
      break;
    }
    case 'hamburgerSubLink': {
      Helix.track('SPECTRUM_selectAction_header_hamburger', {
        currPageElemUiName: payload.linkObject?.uiName,
        currPageElemStdName: payload.linkObject?.name,
        currPageElemUrl: `${concatUrl(
          payload.linkObject?.baseUrl,
          payload.linkObject?.href
        )}&exVisitID=${HelixExtensions.getVisitId()}`,
      });
      break;
    }
    case 'accordionExpand': {
      Helix.track('SPECTRUM_selectAction_header_hamburger', {
        currPageElemUiName: 'Inbox',
        currPageElemStdName: 'hamburger-expandInbox',
      });
      break;
    }
    case 'accordionCollapse': {
      Helix.track('SPECTRUM_selectAction_header_hamburger', {
        currPageElemUiName: 'Inbox',
        currPageElemStdName: 'hamburger-collapseInbox',
      });
      break;
    }
    case 'navSupportLink': {
      Helix.track('SPECTRUM_selectAction_header_localNav', {
        currPageElemUiName: payload.linkObject?.uiName,
        currPageElemStdName: `mainNav-${payload.linkObject?.name}`,
        currPageElemUrl: `${concatUrl(
          payload.linkObject?.baseUrl,
          payload.linkObject?.href
        )}&exVisitID=${HelixExtensions.getVisitId()}`,
      });
      break;
    }
    case 'subNavLink': {
      Helix.track('SPECTRUM_selectAction_header_hamburger', {
        currPageElemUiName: payload.linkObject?.uiName,
        currPageElemStdName: `hamburger-inbox-${payload.linkObject?.name}`,
      });
      break;
    }
    case 'spectrumLogoLink': {
      Helix.track('SPECTRUM_selectAction_header_logo');
      break;
    }
    case 'logoutHeader': {
      Helix.track('SPECTRUM_selectAction_header_utilityNav', {
        currPageElemStdName: 'utilityNav-signOut',
        currPageElemUiName: 'Sign Out',
      });
      break;
    }
    case 'logoutHamburger': {
      Helix.track('SPECTRUM_selectAction_header_hamburger', {
        currPageElemStdName: 'hamburger-signOut',
        currPageElemUiName: 'Sign Out',
      });
      break;
    }
    case 'accountInfoPageViewInit': {
      Helix.track('WEBMAIL_pageViewInit_settingsAccountInfo', {
        currPageUrl: '/user/setting/account',
      });
      break;
    }
    case 'pageViewInitInbox': {
      Helix.track('WEBMAIL_pageViewInit_email_inbox', {
        currPageUrl: '/user/mail/SU5CT1g%3D',
      });
      break;
    }
    case 'spanishButton': {
      Helix.track('WEBMAIL_selectAction_header_enEspanol');
      break;
    }
    case 'spanishModalView': {
      Helix.track('WEBMAIL_modalView_spanishComingSoon');
      break;
    }
    case 'spanishOk': {
      Helix.track('WEBMAIL_selectAction_spanishComingSoonModal_OK');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    default: {
      return null;
    }
  }
  return null;
};

export default submitNavigationEvent;
