import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateSpamSettingMutationFn } from 'generated/graphql';

export interface UpdateSpamSettingInterface {
  payload: {
    value: string;
  };
  updateSpamSettingHook: UpdateSpamSettingMutationFn;
}

export interface UpdateSpamSettingResponse {
  spam: string;
  success?: boolean;
  error?: ApolloError;
}

export const updateSpamSettingThunk = createAsyncThunk(
  'updateSpamSetting',
  async (arg: UpdateSpamSettingInterface): Promise<UpdateSpamSettingResponse> => {
    try {
      const response = await arg.updateSpamSettingHook({
        variables: {
          spamSetting: arg.payload.value,
        },
      });
      return {
        spam: arg.payload.value,
        success: response.data?.updateSpamSetting.success,
      } as UpdateSpamSettingResponse;
    } catch (e) {
      return {
        spam: arg.payload.value,
        error: e as ApolloError,
      };
    }
  }
);
