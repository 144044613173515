import { Dispatch, SetStateAction, useState } from 'react';

export interface FieldValidatorInitialStateInterface {
  [key: string]: string;
}

type UseContactFieldValidatorErrorInterface = (
  initialState: FieldValidatorInitialStateInterface
) => [FieldValidatorInitialStateInterface, Dispatch<SetStateAction<FieldValidatorInitialStateInterface>>];

const useContactFieldValidatorError: UseContactFieldValidatorErrorInterface = (
  initialState: FieldValidatorInitialStateInterface
) => {
  const [errors, setErrors] = useState(initialState);

  return [errors, setErrors];
};

export default useContactFieldValidatorError;
