import {
  cityMaxContactForm,
  companyMaxContactForm,
  countryMaxContactForm,
  displayNameMaxContactForm,
  firstNameMaxContactForm,
  lastNameMaxContactForm,
  nickNameMaxContactForm,
  notesMaxContactForm,
  stateMaxContactForm,
  streetAddressMaxContactForm,
  titleMaxContactForm,
  urlMaxContactForm,
  zipCodeMaxContactForm,
} from 'appConstants';
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: 'required',
  },
  string: {
    email: 'invalid',
    url: 'invalid',
    max: 'invalid',
  },
});

const phoneSchema = yup
  .object({
    phoneNumber: yup
      .string()
      .optional()
      .nullable()
      .matches(/^[1-9]\d{2}-\d{3}-\d{4}/, {
        message: 'invalid',
        excludeEmptyString: true,
      }),
    isDefault: yup.boolean().nullable(),
    phoneNumberType: yup.string().nullable(),
  })
  .optional();

const addressSchema = yup
  .object({
    streetAddress: yup.string().max(streetAddressMaxContactForm).optional().nullable(),
    city: yup.string().max(cityMaxContactForm).optional().nullable(),
    state: yup.string().max(stateMaxContactForm).optional().nullable(),
    zipCode: yup.string().max(zipCodeMaxContactForm).optional().nullable(),
    country: yup.string().max(countryMaxContactForm).optional().nullable(),
    addressType: yup.string().nullable(),
  })
  .optional();

export const contactSchema = yup.object().shape({
  firstName: yup.string().max(firstNameMaxContactForm).required(),
  lastName: yup.string().max(lastNameMaxContactForm).required(),
  displayName: yup.string().max(displayNameMaxContactForm).required(),
  nickName: yup.string().max(nickNameMaxContactForm).optional().nullable(),
  title: yup.string().max(titleMaxContactForm).optional().nullable(),
  company: yup.string().max(companyMaxContactForm).optional().nullable(),
  emails: yup
    .array()
    .of(
      yup.object({
        emailAddress: yup.string().email().required(),
        isDefault: yup.boolean().nullable(),
        emailAddressType: yup.string().nullable(),
      })
    )
    .min(1)
    .required(),
  homePhone: phoneSchema,
  mobilePhone: phoneSchema,
  workPhone: phoneSchema,
  homeAddress: addressSchema,
  workAddress: addressSchema,
  dateOfBirth: yup
    .string()
    .matches(/^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/, {
      message: 'invalid',
      excludeEmptyString: true,
    })
    .optional()
    .nullable(),
  notes: yup.string().max(notesMaxContactForm).optional().nullable(),
  url: yup.string().max(urlMaxContactForm).optional().nullable(),
});
