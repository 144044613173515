import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateAutoReplyMessageMutationFn, UpdateAutoReplyToggleMutationFn } from 'generated/graphql';

export interface updateAutoReplyArg {
  payload: {
    isVacationModeEnabled: boolean;
    autoReplyMessage?: string;
  };
  updateAutoReplyHook: UpdateAutoReplyMessageMutationFn | UpdateAutoReplyToggleMutationFn;
}

export interface updateAutoReplyResponse {
  success?: boolean;
  isVacationModeEnabled?: boolean;
  autoReplyMessage?: string;
  error?: ApolloError;
}

export const updateAutoReplyMessageThunk = createAsyncThunk(
  'updateAutoReplyMessageThunk',
  async (arg: updateAutoReplyArg): Promise<updateAutoReplyResponse> => {
    try {
      const response = await arg.updateAutoReplyHook({
        variables: arg.payload,
      });
      return {
        success: response.data?.updateAutoReply.success,
        isVacationModeEnabled: arg.payload.isVacationModeEnabled,
        autoReplyMessage: arg.payload.autoReplyMessage,
      } as updateAutoReplyResponse;
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);

export const updateAutoReplyToggleThunk = createAsyncThunk(
  'updateAutoReplyToggleThunk',
  async (arg: updateAutoReplyArg): Promise<updateAutoReplyResponse> => {
    try {
      const response = await arg.updateAutoReplyHook({
        variables: arg.payload,
      });
      return {
        success: response.data?.updateAutoReply.success,
        isVacationModeEnabled: arg.payload.isVacationModeEnabled,
        autoReplyMessage: arg.payload.autoReplyMessage,
      } as updateAutoReplyResponse;
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
