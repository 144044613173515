import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Success, ContactMigrationMutationFn } from 'generated/graphql';

export interface ContactMigrationResponse {
  success?: Success;
  error?: ApolloError | undefined;
}

export interface ContactMigrationArg {
  contactMigrationHook: ContactMigrationMutationFn;
  payload?: {
    syncButton: boolean;
  };
}

export const contactMigrationThunk = createAsyncThunk(
  'contactMigration',
  async (arg: ContactMigrationArg): Promise<ContactMigrationResponse> => {
    try {
      const response = await arg.contactMigrationHook();
      return {
        success: response.data?.contactMigration,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
