import { LabelEntity } from 'generated/graphql';

const isValidLabelName = (labelName: string): boolean => {
  return new RegExp(/^[ A-Za-z0-9-_,']*$/).test(labelName);
};

const isDuplicate = (labelName: string, labelCollection?: LabelEntity[]): boolean => {
  if (!labelCollection) return false;

  return labelCollection.some((label) => label.name.trim() === labelName.trim());
};

export { isValidLabelName, isDuplicate };
