import { KiteIcon } from '@kite/react-kite';
import styles from 'components/compose-chevron/styles/ComposeChevron.module.scss';
import React, { memo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateActiveTab, updateMinimizedIndex } from 'slices/compose';
import ComposeTabHeader from 'components/compose-tab-header';
import useOuterClick from 'hooks/outSideClickHook';
import { usePageTranslation } from 'translation/hooks';

const ComposeChevron: React.FC = () => {
  const { t } = usePageTranslation('composeCopy');

  const composeCollection = useAppSelector((state) => state.composeSlice.composeCollection);
  const activeTabIndex = useAppSelector((state) => state.composeSlice.activeTabIndex);
  const activeMinimizedIndex = useAppSelector((state) => state.composeSlice.activeMinimizedIndex);
  const [isToggled, setIsToggled] = useState(false);

  const dispatch = useAppDispatch();

  const composeCollectionLength = Object.keys(composeCollection).length;

  const handleSelectListItem = (index: string): void => {
    dispatch(updateActiveTab(index));
    dispatch(updateMinimizedIndex(''));
  };

  const closeOnClickOutside = (): void => {
    setIsToggled(false);
  };

  const innerRef = useOuterClick(closeOnClickOutside);

  const handleToggleChevron = (): void => {
    setIsToggled(!isToggled);
  };

  const lastIndex: string =
    (activeMinimizedIndex ? activeMinimizedIndex : Object.keys(composeCollection).slice(-1).pop()) || '';

  const renderDropdownList = (): JSX.Element[] => {
    const list = [];
    for (const index in composeCollection) {
      const isActive = composeCollection[index].index === activeTabIndex;
      const title = composeCollection[index].subject || t('defaultHeader', { count: composeCollection[index].index });
      const className = isActive ? `${styles.dropdownListItem} ${styles.selected}` : styles.dropdownListItem;
      list.push(
        <li
          className={className}
          key={composeCollection[index].index}
          onClick={() => handleSelectListItem(composeCollection[index].index)}
        >
          {isActive && <KiteIcon icon="ki-checkmark" size="15px" className={styles.checkIcon}></KiteIcon>}
          {title}
        </li>
      );
    }

    return list;
  };

  return (
    <div
      className={`${styles.chevronContainer}  ${
        activeTabIndex && !activeMinimizedIndex ? styles.hideChevronMobile : ''
      }  ${activeMinimizedIndex ? styles.marginRight : ''}`}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={innerRef as any}
    >
      {composeCollectionLength && (
        <button onClick={handleToggleChevron} type="button" className={`${styles.chevronButton}`}>
          <div className={styles.chevronPopover}>{Object.keys(composeCollection).length}</div>
          <KiteIcon icon="ki-chevron-left" size="15px" className={styles.chevronIcon} />
        </button>
      )}
      {isToggled && (
        <div className={styles.dropDownContainer}>
          <ul>{renderDropdownList()}</ul>
        </div>
      )}
      {activeMinimizedIndex && (
        <div className={styles.composeTab}>
          <ComposeTabHeader index={lastIndex} />
        </div>
      )}
    </div>
  );
};

export default memo(ComposeChevron);
