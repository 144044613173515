import { useState, useEffect } from 'react';

const useMobileSize = (): boolean => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = (): void => {
    setIsMobile(window.innerWidth < 1024);
  };

  return isMobile;
};

export default useMobileSize;
