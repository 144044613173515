import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import CanaryLandingPageContainer from 'containers/CanaryLandingPageContainer';
import styles from 'components/layout/styles/Layout.module.scss';
import settingLayoutStyles from 'containers/styles/SettingLayoutContainer.module.scss';
import NavContainer from 'containers/NavContainer';
import { KiteGrid } from '@kite/react-kite';
import Authentication from 'components/authentication';
import HeaderLayoutContainer from 'containers/HeaderLayoutContainer';
import SettingContainer from 'containers/SettingContainer';
import InboxLayout from 'components/layout/inbox';
import ContactLayout from 'components/layout/contact';
import LoginContainer from 'containers/LoginContainer';
import { useAppSelector } from 'hooks';
import CanaryTutorialContainer from 'containers/CanaryTutorialContainer';
import PreFetchOptInStatus from 'components/pre-fetch-opt-in-status';

const RouterSwitch: React.FC = ({ children }) => {
  const isCanary = useAppSelector((state) => state.appSlice.isCanary);
  const optInStatus = useAppSelector((state) => state.canarySlice.optInStatus);
  const { isContactMigrationLoading, isSettingMigrationLoading } = useAppSelector((state) => state.migrationSlice);

  return (
    <BrowserRouter>
      <HeaderLayoutContainer />
      <h1 id="mainContent" className="srOnly">
        Spectrum Mail
      </h1>
      {children}
      <Routes>
        <Route path={'/'} element={<Navigate to={'/user/canary'} />} />
        <Route path={'/login'} element={<LoginContainer />} />
        <Route
          path={'/user/*'}
          element={
            <>
              <PreFetchOptInStatus />
              <Routes>
                <Route path="/" element={<Navigate to={'/user/canary'} />} />
                <Route path={'/mail'} element={<Navigate to={'/user/canary'} />} />
                <Route path={'/label'} element={<Navigate to={'/user/label/all'} />} />
              </Routes>
              <Authentication isCanaryRedirect={false}>
                <></>
                <Routes>
                  <Route path={'/canary'} element={<CanaryLandingPageContainer />} />
                  <Route path={'/tutorial'} element={<CanaryTutorialContainer />} />
                </Routes>
              </Authentication>
              <Authentication isCanaryRedirect={true}>
                {!isCanary && optInStatus === 'yes' && !isContactMigrationLoading && !isSettingMigrationLoading ? (
                  <div className={styles.layoutContainer}>
                    <div className={styles.navigationContainerLayout}>
                      <NavContainer />
                    </div>
                    <div className={styles.contentLayout}>
                      <Routes>
                        <Route path={'mail/:folder/*'} element={<InboxLayout />} />
                        <Route path={'label/:labelId/*'} element={<ContactLayout />} />
                        <Route
                          path={'/setting/*'}
                          element={
                            <div className={settingLayoutStyles.settingLayoutContainer}>
                              <div className={settingLayoutStyles.settingContentLayout}>
                                <KiteGrid className={settingLayoutStyles.settingGrid} noGutters={true}>
                                  <SettingContainer />
                                </KiteGrid>
                              </div>
                            </div>
                          }
                        />
                      </Routes>
                    </div>
                  </div>
                ) : null}
              </Authentication>
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterSwitch;
