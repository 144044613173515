export const timeZoneCopy = {
  title: '',
  description: '',
  label: '',
  tZUsEastern: '',
  tZAmericaIndiana: '',
  tZUsCentral: '',
  tZUsMountain: '',
  tZAmericaPhoenix: '',
  tZUsPacific: '',
  tZUsAlaska: '',
  tZUsHawaii: '',
};
