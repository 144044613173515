import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import styles from 'components/contact-modal/styles/ImportContacts.module.scss';
import { KiteAlert, KiteButton, KiteIcon } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState, useRef } from 'react';
import { parseVCards } from 'vcard4-ts';
import { ContactForm, useCreateBulkContactMutation, useGetContactCollectionLazyQuery } from 'generated/graphql';
import { createBulkContactThunk } from 'thunks/contact/createBulkContactThunk';
import { TrackingHook, useTracking } from 'react-tracking';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import { useNavigate } from 'react-router-dom';
import { contactCollectionThunk } from 'thunks/contact/contactCollectionThunk';
import { appConstants } from 'appConstants';
import { updateCurrentLabelSelected } from 'slices/contact';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';

import { ErrorsKeys, useDialogTranslation } from 'translation/hooks';
import buildContactsFromVcf, { ContactFromImport } from 'utils/buildContactsFromVcf';

const ImportContacts: React.FC = () => {
  const { t } = useDialogTranslation('importContactsCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'contacts' });
  const focusOpener = useTarget('modal');
  const apiError = useAppSelector((state) => state.contactSlice.apiError);
  const isAttachmentLoading = useAppSelector((state) => state.composeSlice.isAttachmentLoading);

  const dispatch = useAppDispatch();
  const [getContactCollectionHook] = useGetContactCollectionLazyQuery();
  const [createBulkContactHook] = useCreateBulkContactMutation();

  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const [vcardFileName, setVcardFileName] = useState('');
  const [contactsToUpload, setContactsToUpload] = useState<ContactFromImport[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    trackEvent({ event: 'importContactsModalView' });
  }, []);

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const handleAttachmentClick = (): void => {
    hiddenFileInputRef.current && hiddenFileInputRef.current.click();
  };

  const handleAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    trackEvent({ event: 'importContactsSelectFile' });
    const fileUploaded = event.target.files && event?.target?.files[0];

    if (fileUploaded) {
      setVcardFileName(fileUploaded.name);
      const fileReader: FileReader = new FileReader();
      fileReader.onload = (e) => {
        if (e.target?.result) {
          const vcard = parseVCards(e.target?.result.toString());
          if (vcard.vCards && vcard.vCards.length) {
            const contactsFromImport = buildContactsFromVcf(vcard);
            setContactsToUpload(contactsFromImport);
          }
        }
      };

      fileReader.readAsText(fileUploaded);
    }
  };

  const trackResult = (contactsCreated: number): void => {
    if (contactsCreated < contactsToUpload.length) {
      trackEvent({
        event: 'importContactPartialFailure',
        payload: { uploadCount: contactsCreated, failedOverQuota: contactsToUpload.length - contactsCreated },
      });
      return;
    }
    trackEvent({ event: 'importContactSuccess', payload: { uploadCount: contactsCreated } });
  };

  const isSaved = async (): Promise<boolean> => {
    if (contactsToUpload && contactsToUpload.length) {
      const result = await dispatch(
        createBulkContactThunk({
          createBulkContactHook,
          payload: { contacts: contactsToUpload as ContactForm[] },
        })
      ).unwrap();

      if (result.success) {
        trackResult(result.success.createCount);
        dispatch(
          contactCollectionThunk({
            getContactCollectionHook,
            limit: appConstants.CONTACT_MAX_COUNT,
            isPrefetch: false,
          })
        );
        dispatch(updateCurrentLabelSelected(''));
        navigate('user/label/all');
        dispatch(
          updateToastMessage({
            message: t(result.success.createCount === 1 ? 'successToastSingular' : 'successToast'),
            success: true,
          })
        );
        dispatch(updateIsOpen(false));
        return true;
      }
    }

    trackEvent({ event: 'importContactAPIFailure' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'importContactsCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    trackEvent({ event: 'importContactsSave' });
    return true;
  };

  const isChanged = (): boolean => {
    if (contactsToUpload.length) {
      return true;
    }
    return false;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    isChanged,
    saveButtonText: apiError ? t('tryAgain') : t('confirmBtnText'),
  };

  return (
    <ModalContentWrapper>
      <h5>{t('header')}</h5>
      <p>{t('description')}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <span className={styles.actionIcons}>
        <KiteButton
          variant="borderless"
          loading={isAttachmentLoading}
          disabled={isAttachmentLoading}
          aria-disabled={isAttachmentLoading}
          className={styles.attachmentButton}
          onClick={handleAttachmentClick}
          aria-label={'choose file to import'}
        >
          <KiteIcon icon="ki-attachment" size="18px" />
          <span>{t('fileSelect')}</span>
          <span className={styles.attachmentName}>{vcardFileName}</span>
        </KiteButton>
        <input
          className={styles.hiddenAttachmentIcon}
          type="file"
          ref={hiddenFileInputRef}
          onChange={handleAttachmentChange}
          accept={'text/x-vcard'}
        />
      </span>
      <p className={modalStyle.alertMessage}>{t('warning')}</p>
      <SaveCancel {...saveCancelProps} draft={contactsToUpload} />
    </ModalContentWrapper>
  );
};

export default ImportContacts;
