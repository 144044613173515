import React from 'react';
import { KiteGrid, KiteGridCell, KiteLink } from '@kite/react-kite';
import { FOOTER_LOGO, footerColumns, LinkItemInterface } from 'app-constants';
import styles from 'components/footer/styles/Footer.module.scss';
import FooterColumnLink from 'components/footer/FooterColumnLink';
import { TrackingHook, useTracking } from 'react-tracking';
import { appConstants } from 'appConstants';

interface Props {
  largeFooter: boolean;
}

const Legal: React.FC<Props> = ({ largeFooter }) => {
  const charterCommunicationLogoLink = appConstants.ui.CHARTER_COMMUNICATION_LOGO_LINK;
  const {
    LEGAL: { links },
  } = footerColumns;
  const { trackEvent }: TrackingHook = useTracking({ page: 'login', component: 'Legal' });

  const linksList = links.map((link, index) => (
    <span key={index} className={styles.linkElement}>
      {link.href || link.baseUrl ? <FooterColumnLink {...(link as LinkItemInterface)} /> : link.uiName}
      {index < links.length - 1 && <span className={styles.verticalSeparator} />}
    </span>
  ));

  const isLoggedInStyles = largeFooter ? '' : styles.loggedOut;

  return (
    // The role attribute is listed here because VoiceOver has an issue where the footer landmark role is not announced in the landmark rotor without it. Leave the @ts-ignore until solved
    // @ts-ignore
    <KiteGridCell role="contentinfo" col={12} className={styles.legal}>
      {!largeFooter && <div className={styles.legalDivider} />}
      <KiteGrid className={styles.linksContainer}>
        <KiteGridCell col={12} md={12} className={styles.linksContainer}>
          <p className={`${styles.links} ${isLoggedInStyles}`}>
            {!largeFooter && (
              <KiteLink
                className="focus-outline-primary"
                id="footerLogo"
                href={FOOTER_LOGO.href}
                type="inline"
                data-testid="CharterLogo"
                onClick={() =>
                  trackEvent({
                    event: 'loginFooterLink',
                    url: FOOTER_LOGO.href,
                    linkObject: { uiName: FOOTER_LOGO.uiName, stdName: FOOTER_LOGO.stdName },
                  })
                }
              >
                <img className={styles.logo} src={charterCommunicationLogoLink} alt="Charter" />
              </KiteLink>
            )}
            {linksList}
          </p>
        </KiteGridCell>
      </KiteGrid>
    </KiteGridCell>
  );
};

export default Legal;
