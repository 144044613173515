import { KiteButton, KiteIcon } from '@kite/react-kite';
import { appConstants } from 'appConstants';
import ComposeTabHeaderStyles from 'components/compose-tab-header/styles/ComposeTabHeader.module.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TrackingHook, useTracking } from 'react-tracking';
import { updateIsOpen, updateModalName, updateModalProps } from 'slices/app';
import {
  removeFromTabCollection,
  updateActiveTab,
  updateMaximizeCompose,
  updateMinimizedIndex,
  updatePauseAutoSave,
} from 'slices/compose';
import { usePageTranslation } from 'translation/hooks';
import { isDraftFolder } from 'utils/folderNameValidator';

interface ComposeTabHeaderInterface {
  index: string;
}

const ComposeTabHeader: React.FC<ComposeTabHeaderInterface> = ({ index }) => {
  const { t } = usePageTranslation('composeCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'compose' });

  const activeTabIndex = useAppSelector((state) => state.composeSlice.activeTabIndex);
  const activeMinimizedIndex = useAppSelector((state) => state.composeSlice.activeMinimizedIndex);
  const composeCollection = useAppSelector((state) => state.composeSlice.composeCollection);
  const isMaximized = useAppSelector((state) => state.composeSlice.isMaximized);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { folder } = useParams();

  const lastIndex = Object.entries(composeCollection).slice(-2).pop();

  const isSavePending = composeCollection[activeTabIndex]?.isSavePending;
  const isSendPending = composeCollection[activeTabIndex]?.isSendPending;

  const title = composeCollection[index]?.subject || t('defaultHeader');
  let prefix = '';

  if (isSendPending) {
    prefix = t('sending');
  }
  if (isSavePending) {
    prefix = t('saving');
  }

  const handleMinimize = (index: string): void => {
    trackEvent({ event: 'minimizeCompose' });
    dispatch(updateMinimizedIndex(index));
    dispatch(updateMaximizeCompose(false));
  };

  const handleRestore = (index: string): void => {
    trackEvent({ event: 'restoreCompose' });
    dispatch(updateActiveTab(index));
    dispatch(updateMinimizedIndex(''));
  };

  const handleMaximize = (): void => {
    if (activeMinimizedIndex) {
      dispatch(updateActiveTab(index));
      dispatch(updateMinimizedIndex(''));
    }
    dispatch(updateMaximizeCompose(!isMaximized));
  };

  const handleClose = async (index: string): Promise<void> => {
    trackEvent({ event: 'closeWindow' });

    if (composeCollection[index].isChanged) {
      dispatch(updateModalName('saveDraft'));
      dispatch(updateModalProps({ folder }));
      dispatch(updateIsOpen(true));
      dispatch(updatePauseAutoSave(true));
      return;
    }

    dispatch(updateMinimizedIndex(String(Object.keys(composeCollection).lastIndexOf('index'))));
    dispatch(updateMaximizeCompose(false));
    dispatch(removeFromTabCollection({ activeTabIndex: index, isDraftFolder: isDraftFolder(folder || '') }));

    if (isDraftFolder(folder as string)) {
      navigate(`/user/mail/${encodeURIComponent(appConstants.DRAFT_FOLDER_ID)}${search}`);
    }
  };

  return (
    <div
      className={`${ComposeTabHeaderStyles.headerContainer} ${
        activeMinimizedIndex && ComposeTabHeaderStyles.minimizedWidth
      }`}
    >
      <div className={ComposeTabHeaderStyles.title} role="header">
        {prefix + title || lastIndex}
      </div>
      <div className={ComposeTabHeaderStyles.iconGroupContainer}>
        {activeMinimizedIndex ? (
          <KiteButton
            aria-label="restore"
            className={ComposeTabHeaderStyles.composeButtons}
            onClick={() => handleRestore(index)}
          >
            <KiteIcon className={ComposeTabHeaderStyles.collapseButton} icon={'ki-arrow-up'} size="16px" />
          </KiteButton>
        ) : (
          <KiteButton
            aria-label="minimize"
            className={`${ComposeTabHeaderStyles.composeButtons} ${ComposeTabHeaderStyles.composeButtonsHideMobile}`}
            onClick={() => handleMinimize(index)}
          >
            <KiteIcon className={ComposeTabHeaderStyles.collapseButton} icon={'ki-minimize'} size="16px" />
          </KiteButton>
        )}
        <KiteButton
          aria-label="maximize"
          className={`${ComposeTabHeaderStyles.composeButtons} ${ComposeTabHeaderStyles.composeButtonsHideMobile}`}
          onClick={handleMaximize}
        >
          <KiteIcon
            className={ComposeTabHeaderStyles.collapseButton}
            icon={!isMaximized ? 'ki-expand' : 'ki-collapse'}
            size="16px"
          />
        </KiteButton>
        <KiteButton
          className={ComposeTabHeaderStyles.composeButtons}
          type="button"
          onClick={() => handleClose(index)}
          aria-label={'close'}
          disabled={composeCollection[activeTabIndex] && composeCollection[activeTabIndex].isSavePending}
        >
          <KiteIcon className={ComposeTabHeaderStyles.closeButton} icon="ki-x" size="16px" />
        </KiteButton>
      </div>
    </div>
  );
};

export default ComposeTabHeader;
