import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { OptInResponse } from 'generated/graphql';

export interface GetOptInStatusThunkResponse {
  success?: OptInResponse;
  error?: ApolloError | undefined;
}

export interface GetOptInStatusThunkArg {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOptInStatusHook?: any;
}

export const getOptInStatusThunk = createAsyncThunk(
  'getOptInStatus',
  async (arg: GetOptInStatusThunkArg): Promise<GetOptInStatusThunkResponse> => {
    try {
      const response = await arg.getOptInStatusHook();
      return {
        success: response.data.getOptInStatus,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
