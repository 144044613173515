import { KiteProgressIndicator } from '@kite/react-kite';
import { Editor } from '@tinymce/tinymce-react';
import styles from 'components/text-editor/styles/TextEditor.module.scss';
import { useAppSelector } from 'hooks';
import { HelixComposePayloadInterface } from 'libs/tracking/adapters/helix/events/submitComposeEvent';
import { HelixSettingsPayloadInterface } from 'libs/tracking/adapters/helix/events/submitSettingsEvent';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { TrackingHook, useTracking } from 'react-tracking';
import { useSharedTranslation } from 'translation/hooks';

interface Props {
  initialValue?: string;
  handleChange?: (html: string) => void;
  editorCallback?: (ref: Editor) => void;
  value?: string;
  composeWindow?: boolean;
  page: string;
  editorId?: string;
}

type EditorTrackingPayloads = HelixSettingsPayloadInterface | HelixComposePayloadInterface;

const TextEditor: React.FC<Props> = ({ initialValue, handleChange, editorCallback, value, page, editorId }) => {
  const signature = useAppSelector((state) => state.signatureSlice.signatureEntity?.signature);
  const { trackEvent }: TrackingHook<EditorTrackingPayloads> = useTracking({ page: page as string });
  const [loading, setLoading] = useState(true);
  const { t } = useSharedTranslation();

  const ariaLabel = `text area, edit ${page === 'compose' ? 'email body' : 'signature'} here`;

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <div
      className={`${styles.textEditorContainer} ${
        page === 'settings' ? styles.textEditorSettings : styles.textEditorCompose
      }`}
    >
      {loading && (
        <div className={styles.textEditorLoader}>
          <KiteProgressIndicator id={'loading'} useOverlay={true} size="md" />
        </div>
      )}
      <p tabIndex={0} className={styles.accessibleToolbar}>
        {t('toolbarShortcut')}
      </p>
      {/*@ts-ignore*/}
      <Editor
        id={editorId}
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        onInit={(evt, editor) => {
          if (editorCallback) {
            // @ts-ignore
            editorCallback(editor);
          }
          editor.getBody().setAttribute('aria-label', ariaLabel);
          setLoading(false);
        }}
        initialValue={initialValue}
        value={value}
        inline={page !== 'settings'}
        init={{
          resize: false,
          menubar: false,
          toolbar_persist: true,
          fixed_toolbar_container: '#mceToolbarContainer',
          contextmenu: false,
          browser_spellcheck: true,
          mobile: {
            toolbar_mode: 'floating',
            toolbar:
              'bold italic underline | fontfamily removeformat  | \
            fontsize forecolor alignleft aligncenter alignright alignjustify \
            bullist numlist outdent indent link',
          },
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'help',
            'wordcount',
          ],
          forced_root_block: 'div',
          paste_preprocess: (plugin: any, args: any) => {
            if (page === 'compose' && args.content.includes(`<img src=`)) {
              trackEvent({ event: 'composeImagePaste' });
            }
          },
          toolbar:
            'bold italic underline | fontfamily fontsize forecolor removeformat outdent indent | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist link',
          content_style:
            page === 'settings'
              ? 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; margin:0 }'
              : '#mceToolbarContainer { font-family:Helvetica,Arial,sans-serif; font-size:14px; margin:0; z-index: 0; }',
          inline_boundaries: false,
          height: 200,
          toolbar_location: 'bottom',
          paste_data_images: true,
          statusbar: false,
          branding: false,
          max_width: 582,
          skin: 'webmail',
          link_assume_external_targets: 'https',
          link_target_list: false,
          link_default_target: '_blank',
          setup: (editor) => {
            editor.addCommand('insertSignature', () => {
              const endId = editor.dom.uniqueId();
              editor.dom.add(editor.getBody(), 'span', { id: endId }, 'signature');

              const newNode = editor.dom.select('span#' + endId);
              editor.selection.select(newNode[0]);
              editor.execCommand('mceInsertContent', false, `<div><br><br><br>${signature}</div>`);
            });

            const closeToolbarFloater = (): void => {
              if (mounted.current && editor.quirks && editor.queryCommandState('ToggleToolbarDrawer')) {
                mounted.current && editor.execCommand('ToggleToolbarDrawer');
              }
            };

            document.addEventListener('click', closeToolbarFloater);

            editor.on('destroy', () => {
              document.removeEventListener('click', closeToolbarFloater);
            });

            editor.on('mousedown', () => {
              if (mounted.current && editor.queryCommandState('ToggleToolbarDrawer')) {
                mounted.current && editor.execCommand('ToggleToolbarDrawer');
              }
            });

            const debouncedTrack = debounce((type, format) => {
              trackEvent({
                event: 'editorFormatChange',
                type,
                uiName: format,
              });
            }, 250);

            editor.on('FormatApply', ({ type, format }) => {
              debouncedTrack(type, format);
            });
            editor.on('FormatRemove', ({ type, format }) => {
              trackEvent({
                event: 'editorFormatChange',
                type,
                uiName: format,
              });
            });
            editor.on('ListMutation', ({ type, action }) => {
              trackEvent({
                event: 'editorFormatChange',
                type,
                uiName: action,
              });
            });
          },
        }}
        onEditorChange={handleChange}
      />
    </div>
  );
};

export default TextEditor;
