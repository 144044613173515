import { contactSchema } from 'libs/validations/contact';
import { ValidationError } from 'yup';
import { ContactFormInterface } from 'components/contact-form/initialState';

interface FormError {
  [key: string]: string;
}

export const isContactFormValid = (formValues: ContactFormInterface): [boolean, FormError] => {
  try {
    contactSchema.validateSync(formValues, { recursive: true, abortEarly: false });
  } catch (err) {
    let formErrors: FormError = {};

    if (err instanceof ValidationError) {
      err.inner.forEach((error) => {
        formErrors = { ...formErrors, [error.path as string]: error.message };
      });
    }

    return [false, formErrors];
  }

  return [true, {}];
};
