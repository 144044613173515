import { TFunction, i18n } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CommonCopy, ValidationCopy, ErrorsCopy, pages, dialogs } from 'translation/models';

export interface DialogTranslationReturn<T extends DialogsKeys> {
  t: (key: DialogNSTranslationFileKeys<T>, interpolation?: Interpolation) => string;
  i18n: i18n;
}
export interface PageTranslationReturn<T extends PagesKeys> {
  t: (key: PageNSTranslationFileKeys<T>, interpolation?: Interpolation) => string;
  i18n: i18n;
}
export interface SharedTranslationReturn {
  t: (key: SharedTranslationKeys, interpolation?: Interpolation) => string;
  i18n: i18n;
}

interface Interpolation {
  required?: boolean;
  [key: string]: string | number | boolean | undefined;
}

// Shared
export type CommonKeys = keyof typeof CommonCopy;
export type ValidationKeys = keyof typeof ValidationCopy;
export type ErrorsKeys = keyof typeof ErrorsCopy;
type PagesKeys = keyof Pages;
type DialogsKeys = keyof Dialogs;

type Pages = typeof pages;
type Dialogs = typeof dialogs;

// Keys
export type SharedTranslationKeys = CommonKeys | ValidationKeys | ErrorsKeys | '';
export type PageNSTranslationFileKeys<T extends PagesKeys> = keyof Pages[T] | SharedTranslationKeys | '';
export type DialogNSTranslationFileKeys<T extends DialogsKeys> = keyof Dialogs[T] | SharedTranslationKeys | '';

type UsePageTranslationFN = <P extends PagesKeys>(page: P) => PageTranslationReturn<P>;
type UseDialogTranslationFN = <P extends DialogsKeys>(dialog: P) => DialogTranslationReturn<P>;
type PageTranslationFN<T extends PagesKeys> = PageTranslationReturn<T>['t'];
type DialogTranslationFN<T extends DialogsKeys> = DialogTranslationReturn<T>['t'];

const checkSharedStrings = (key: string, t: TFunction, interpolation: Interpolation = {}): string | null => {
  if (Object.hasOwn(CommonCopy, key)) {
    return t(key, { ...interpolation });
  }
  if (Object.hasOwn(ValidationCopy, key)) {
    return t(key, { ns: 'validation', ...interpolation });
  }
  if (Object.hasOwn(ErrorsCopy, key)) {
    return t(key, { ns: 'errors', ...interpolation });
  }
  return null;
};
const checkEmpty = (key: string): boolean => {
  if (key.length === 0) {
    return true;
  }
  return false;
};

export const usePageTranslation: UsePageTranslationFN = (page) => {
  const { t, i18n } = useTranslation(['common', 'validation', 'errors', 'pages']);

  const translate: PageTranslationFN<typeof page> = (key, interpolation = {}) => {
    const commonString = checkSharedStrings(key as string, t);
    if (checkEmpty(key as string)) {
      return '';
    }
    if (Object.hasOwn(pages[page], key)) {
      return t(`${page}.${key as string}`, { ns: 'pages', ...interpolation });
    }

    if (commonString) {
      return commonString;
    }

    return `Translation for ${key as string} is missing in ${page}`;
  };
  return { t: translate, i18n };
};

export const useDialogTranslation: UseDialogTranslationFN = (dialog) => {
  const { t, i18n } = useTranslation(['common', 'validation', 'errors', 'dialogs']);
  const translate: DialogTranslationFN<typeof dialog> = (key, interpolation = {}) => {
    const commonString = checkSharedStrings(key as string, t);
    if (checkEmpty(key as string)) {
      return '';
    }
    if (Object.hasOwn(dialogs[dialog], key)) {
      return t(`${dialog}.${key as string}`, { ns: 'dialogs', ...interpolation });
    }

    if (commonString) {
      return commonString;
    }
    return `Translation for ${key as string} is missing in ${dialog}`;
  };
  return { t: translate, i18n };
};

export const useSharedTranslation = (): SharedTranslationReturn => {
  const { t, i18n } = useTranslation(['common', 'validation', 'errors']);
  const translate = (key: SharedTranslationKeys, interpolation = {}): string => {
    const commonString = checkSharedStrings(key as string, t, interpolation);
    if (checkEmpty(key as string)) {
      return '';
    }
    if (commonString) {
      return commonString;
    }

    return `Translation for ${key as string} is missing in Shared`;
  };
  return { t: translate, i18n };
};
