import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ContactEntity, LabelEntity } from 'generated/graphql';
import { uniq } from 'lodash';
import { contactCollectionThunk } from 'thunks/contact/contactCollectionThunk';
import { createContactThunk } from 'thunks/contact/createContactThunk';
import { createLabelThunk } from 'thunks/contact/createLabelThunk';
import { deleteLabelThunk } from 'thunks/contact/deleteLabelThunk';
import { labelCollectionThunk } from 'thunks/contact/labelCollectionThunk';
import { patchBulkContactLabelThunk } from 'thunks/contact/patchBulkContactLabelThunk';
import { updateContactThunk } from 'thunks/contact/updateContactThunk';
import { updateLabelThunk } from 'thunks/contact/updateLabelThunk';
import { deleteContactThunk } from 'thunks/contact/deleteContactThunk';
import { deleteBulkContactThunk } from 'thunks/contact/deleteBulkContactThunk';
import { createBulkContactThunk } from 'thunks/contact/createBulkContactThunk';
import { patchContactsLabelThunk } from 'thunks/contact/patchContactsLabelThunk';

interface AutocompleteState {
  contacts?: ContactEntity[];
  labels?: LabelEntity[];
  reloadContactsCollection: boolean;
  apiError?: Error | null;
}

const initialState: AutocompleteState = {
  reloadContactsCollection: false,
};

export const contactAutocompleteSlice = createSlice({
  name: 'contactAutocomplete',
  initialState,
  reducers: {
    updateContactsCollection: (state: AutocompleteState, action: PayloadAction<boolean>) => {
      state.reloadContactsCollection = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(contactCollectionThunk.fulfilled, (state, action) => {
      state.reloadContactsCollection = false;
      if (action.payload.error && action.payload.isPrefetch) {
        state.apiError = action.payload.error;
        return;
      }
      if (action.payload.success && action.payload.isPrefetch) {
        state.contacts = action.payload.success.data;
        return;
      }
    });
    builder.addCase(labelCollectionThunk.fulfilled, (state, action) => {
      if (action.payload.success && action.payload.isPrefetch) {
        state.labels = action.payload.success;
      }
    });
    builder.addCase(updateContactThunk.fulfilled, (state, action) => {
      if (action.payload.success && state.contacts) {
        state.reloadContactsCollection = true;
        const index = state.contacts.findIndex((contact) => {
          return contact.id === action.payload.id;
        });

        let labels: string[] = [];
        if (action.payload.success.labels && action.payload.success.labels.length > 0) {
          labels = action.payload.success.labels.map((label) => {
            return label.id;
          });
        }

        state.contacts[index] = { ...action.payload.success, labels } as ContactEntity;
        return;
      }
    });
    builder.addCase(createLabelThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.reloadContactsCollection = true;
        const newLabels = state.labels?.slice();
        newLabels?.push(action.payload.success as LabelEntity);
        state.labels = newLabels;
      }
    });
    builder.addCase(deleteLabelThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.reloadContactsCollection = true;
        state.labels = state.labels?.filter((label) => label.id !== action.payload.labelId);
      }
    });
    builder.addCase(updateLabelThunk.fulfilled, (state, action) => {
      const index = state.labels?.findIndex((label) => label.id === action.payload.success?.id);
      if (action.payload.success) {
        state.reloadContactsCollection = true;
        state.labels = state.labels?.map((label, idx) => {
          if (idx === index) {
            return action.payload.success;
          }
          return label;
        }) as LabelEntity[];
      }
    });
    builder.addCase(createContactThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.contacts = (state.contacts || []).concat({ ...action.payload.success, labels: [] } as ContactEntity);
        return;
      }
    });
    builder.addCase(deleteContactThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.contacts = state.contacts?.filter((contact) => contact.id !== action.payload.id);
        return;
      }
    });
    builder.addCase(deleteBulkContactThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.contacts = state.contacts?.filter((contact) => !action.payload.ids.includes(contact.id));
        return;
      }
    });
    builder.addCase(createBulkContactThunk.fulfilled, (state) => {
      state.reloadContactsCollection = true;
    });
    builder.addCase(patchBulkContactLabelThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        // update existing contacts if it is selected
        state.reloadContactsCollection = true;
        state.contacts = state.contacts?.map((contact) => {
          if (!action.payload.contacts.includes(contact.id)) {
            return contact;
          }

          const existingLabels = contact.labels || [];

          return {
            ...contact,
            labels: uniq([...existingLabels, ...action.payload.labels]),
          };
        });

        return;
      }
    });
    builder.addCase(patchContactsLabelThunk.fulfilled, (state, action) => {
      const newContactList = state.contacts?.slice().map((contact) => {
        if (contact.id === action.payload.id) {
          return {
            ...contact,
            labels: action.payload.success?.labels.map((label) => label.id),
          };
        }

        return contact;
      }) as ContactEntity[];

      state.contacts = newContactList;
    });
  },
});

export const { updateContactsCollection } = contactAutocompleteSlice.actions;

export default contactAutocompleteSlice.reducer;
