import { GlobalFoldersInterface, appConstants } from 'appConstants';
import { isGlobalFolder } from 'utils/folderNameValidator';

const transformNestedGlobalFolderPath = (name: string, spamFolderId: string): string => {
  const parentFolder = name.split('/')[0];
  if (name.split('/').length > 1 && isGlobalFolder(btoa(parentFolder), spamFolderId)) {
    const newName = name.split('/');
    newName[0] = appConstants.GLOBAL_FOLDER_NAMES[parentFolder as keyof GlobalFoldersInterface];
    return newName.join('/');
  }

  return name;
};

export default transformNestedGlobalFolderPath;
