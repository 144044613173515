import styles from 'components/email-view/styles/EmailView.module.scss';
import { useAppSelector } from 'hooks';
import { appConstants } from 'appConstants';
import { usePageTranslation } from 'translation/hooks';

/**
 * @todo Please do it in better way useLocation()
 */
const EmailViewPlaceholder: React.FC = () => {
  const { t } = usePageTranslation('emailActionsCopy');

  const selectedMessages = useAppSelector((state) => state.messageCollectionSlice.selectedMessages) || [];
  // const messageLoading = useAppSelector((state) => state.messageSlice.messageLoading);
  // const mailFetchError = useAppSelector((state) => state.messageSlice.mailFetchError);

  // let isMessageOpen = false;

  // const location = useLocation().pathname.split('/');
  // const { folder } = useParams();

  // if (location.length > 4) {
  //   isMessageOpen = true;
  // }

  // const handleReloadMessage = (): void => {
  //   dispatch(reloadMessage(true));
  // };

  // const noEmailSelectedPreview = (): JSX.Element => {
  //   let title = t('inbox.selectAnEmailTitle');
  //   let description = t('inbox.selectAnEmailInstructions');

  //   if (folder === appConstants.TRASH_FOLDER_ID) {
  //     title = t('inbox.trashFolderTitle');
  //     description = t('inbox.trashFolderDescription');
  //   }

  //   if (folder === appConstants.SPAM_FOLDER_ID) {
  //     title = t('inbox.spamFolderTitle');
  //     description = t('inbox.spamFolderDescription');
  //   }

  //   if (folder === appConstants.DRAFT_FOLDER_ID) {
  //     title = t('inbox.draftFolderTitle');
  //     description = t('inbox.draftFolderDescription');
  //   }

  //   const generateContent = (): JSX.Element => {
  //     if (messageLoading) {
  //       return <KiteProgressIndicator id={'loading'} useOverlay={false} size="md" />;
  //     }

  //     if (mailFetchError && isMessageOpen) {
  //       return <ServerError retryFunction={handleReloadMessage} />;
  //     }

  //     return (
  //       <div className={styles.selectAnEmailPlaceholder}>
  //         <img className={styles.placeholderIcon} src={appConstants.SELECT_EMAIL_IMG} alt="" />
  //         <h2 className="kite-type-style--title-6">{title}</h2>
  //         <p>{description}</p>
  //       </div>
  //     );
  //   };

  //   return (
  //     <div className={`${styles.emailViewContainer} ${styles.noneSelected}`}>
  //       <KiteCard className={`${styles.emailDetailsCard}  ${styles.noneSelected}`}>{generateContent()}</KiteCard>
  //     </div>
  //   );
  // };

  return (
    <div className={styles.emailViewContainer}>
      <div className={`${styles.emailDetailsCard} ${styles.selectedEmail}`}>
        <div className={styles.selectAnEmailPlaceholder}>
          <img className={styles.selectedContactsIcon} src={appConstants.CONTACT_CARD_IMG} alt="selected contacts" />
          <h2 className={`${styles.selectedEmailsDescription} kite-type-style--title-6`}>
            {t('selected', { amount: selectedMessages.length })}
          </h2>
        </div>
      </div>
    </div>
  );

  // if (!isMessageOpen) {
  //   return noEmailSelectedPreview();
  // }

  // if (folder === appConstants.DRAFT_FOLDER_ID) {
  //   return noEmailSelectedPreview();
  // }
};

export default EmailViewPlaceholder;
