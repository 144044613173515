import ListMessages from 'components/list-messages';
import styles from 'containers/styles/ListViewContainer.module.scss';

const ListViewContainer: React.FC = () => {
  return (
    <div className={styles.listViewContainer} aria-label="mail list">
      <ListMessages />
    </div>
  );
};

export default ListViewContainer;
