import MainHeader from 'components/header/main-header';
import LoginHeader from 'components/header/login-header';
import { isLoggedInLocalStorage } from 'utils/loginLocalStorage';
import { useAppSelector } from 'hooks';
import { useContext } from 'react';
import { AuthContext } from 'providers/AuthProvider';

const HeaderLayoutContainer: React.FC = () => {
  const isCanary = useAppSelector((state) => state.appSlice.isCanary);
  const { isLoggedIn } = useContext(AuthContext);

  if (isCanary || !isLoggedIn) {
    return null;
  }

  if (isLoggedInLocalStorage()) {
    return <MainHeader />;
  }

  return <LoginHeader />;
};

export default HeaderLayoutContainer;
