import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateDisplayNameMutationFn } from 'generated/graphql';

export interface updateDisplayNameArg {
  payload: {
    displayName: string;
  };
  updateDisplayNameHook: UpdateDisplayNameMutationFn;
}
export interface getUpdatedDisplayNameResponse {
  success?: string;
  error?: ApolloError | string;
}

export const updateDisplayNameThunk = createAsyncThunk(
  'updateDisplayNameThunk',
  async (arg: updateDisplayNameArg): Promise<getUpdatedDisplayNameResponse> => {
    try {
      const response = await arg.updateDisplayNameHook({
        variables: {
          value: arg.payload.displayName,
        },
      });

      return {
        success: response?.data?.patchDisplayName?.displayName,
      } as getUpdatedDisplayNameResponse;
    } catch (e) {
      return {
        error: 'unableToSave',
      };
    }
  }
);
