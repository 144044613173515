const splitEmailDisplayName = (stringInput: string): { name?: string; address: string } => {
  const splitNameAddress = /^([^\x00-\x1F\x7F-\x9F\cX]+)</i;
  const displayEmail = stringInput.match(splitNameAddress);

  if (!displayEmail) {
    return {
      address: stringInput,
    };
  }

  const displayName = displayEmail[1];

  const email = stringInput.replace(displayName, '').replace(/(^<|>$)/g, '');

  return { name: displayName.trim().replace(/["']+/g, ''), address: email.trim() };
};

export default splitEmailDisplayName;
