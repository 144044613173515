import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import { useMoveMessagesToNotSpamMutation } from 'generated/graphql';
import { moveMessagesThunk } from 'thunks/message-collection/moveMessagesThunk';
import { appConstants } from 'appConstants';
import { TrackingHook, useTracking } from 'react-tracking';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import getUnseenMessagesCount from 'utils/getUnseenMessagesCount';
import { useTarget } from 'components/common/focus/FocusContext';
import { useDialogTranslation } from 'translation/hooks';
import useNavToPreviousPage from 'components/common/hooks/useNavToPreviousPage';
import useCloseMessage from 'components/common/hooks/useCloseMessage';
import { reloadMessageCollection } from 'slices/message-collection';

interface MarkAsNotSpamProps {
  messageUids: number[];
  currentMailId?: string;
}

const MarkAsNotSpam: React.FC<MarkAsNotSpamProps> = ({ messageUids, currentMailId }) => {
  const { t } = useDialogTranslation('markAsNotSpamCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });

  const apiError = useAppSelector((state) => state.messageCollectionSlice.apiError);
  const messageCollection = useAppSelector((state) => state.messageCollectionSlice.messageCollection);
  const spamFolderId = useAppSelector((state) => state.mailboxCollectionSlice.spamFolderId);
  const focusOpener = useTarget('modal');
  const dispatch = useAppDispatch();
  const [moveMessagesToNotSpamHook] = useMoveMessagesToNotSpamMutation();
  const [queryParams, currentPage, navToPreviousPage] = useNavToPreviousPage();
  const closeMessage = useCloseMessage();

  const isSaved = async (): Promise<boolean> => {
    const unseenMessagesCount = getUnseenMessagesCount(messageCollection[spamFolderId]?.data, messageUids);

    const result = await dispatch(
      moveMessagesThunk({
        moveMessagesHook: moveMessagesToNotSpamHook,
        payload: {
          sourceMailboxId: spamFolderId,
          destinationMailboxId: appConstants.INBOX_MAIL_FOLDER_ID,
          messageUids,
          unseenMessagesCount,
        },
      })
    ).unwrap();

    if (result.success) {
      trackEvent({ event: 'markAsNotSpamSuccess' });

      const { limit, total, data } = messageCollection[spamFolderId];
      const lastPage = currentPage * limit > total;

      if (result.messageUids.length === data?.length && Number(currentPage) > 1 && lastPage) {
        navToPreviousPage();
      }

      if (messageUids.includes(parseInt(currentMailId || ''))) {
        closeMessage(spamFolderId, queryParams);
      }

      dispatch(updateToastMessage({ message: t('successToast'), success: true }));
      dispatch(reloadMessageCollection(true));
      dispatch(updateIsOpen(false));

      return true;
    }

    trackEvent({ event: 'markAsNotSpamFailure' });
    return false;
  };

  const isCancelled = (): boolean => {
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t('tryAgain'),
  };

  return (
    <ModalContentWrapper>
      <h5>{t('header')}</h5>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t('description')} level="page" />
      ) : null}
      <p>{t('description')}</p>
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default MarkAsNotSpam;
