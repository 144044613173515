import { useState, useEffect } from 'react';
import styles from 'components/common/styles/MessageDragDropLayer.module.scss';
import { useDragLayer, useDrop } from 'react-dnd';
import { KiteIcon } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { moveMessagesThunk } from 'thunks/message-collection/moveMessagesThunk';
import { useMoveMessagesMutation } from 'generated/graphql';
import { updateIsDragging, updateIsOpen, updateModalName, updateModalProps, updateToastMessage } from 'slices/app';
import getUnseenMessagesCount from 'utils/getUnseenMessagesCount';
import { useDialogTranslation } from 'translation/hooks';
import { isDraftFolder } from 'utils/folderNameValidator';
import { useParams } from 'react-router-dom';
import useNavToPreviousPage from './hooks/useNavToPreviousPage';
import { reloadMessageCollection } from 'slices/message-collection';
import { TrackingHook, useTracking } from 'react-tracking';
import useCloseMessage from './hooks/useCloseMessage';

interface DragDropResult {
  destinationMailboxId: string;
  messageUids: number[];
  unseenMessagesCount?: number;
  sourceMailboxId: string;
}

const MessageDragDropLayer: React.FC = () => {
  const { t } = useDialogTranslation('moveMessagesCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const [{ x, y }, setMousePos] = useState({ x: 0, y: 0 });
  const emailCount = useAppSelector((state) => state.messageCollectionSlice.selectedMessages)?.length || 1;
  const messageCollection = useAppSelector((state) => state.messageCollectionSlice.messageCollection);
  const [moveMessagesHook] = useMoveMessagesMutation();
  const dispatch = useAppDispatch();
  const canDrop = useAppSelector((state) => state.appSlice.canDrop);
  const { folder: currentFolder, '*': currentMailId } = useParams();
  const [queryParams, currentPage, navToPreviousPage] = useNavToPreviousPage();
  const closeMessage = useCloseMessage();

  const { isDragging, itemType } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType(),
  }));

  const [{ dragDropResult }] = useDrop(() => ({
    accept: 'listMessageItem',
    collect: (monitor) => ({
      dragDropResult: monitor.getDropResult() as DragDropResult,
    }),
  }));

  useEffect(() => {
    if (isDraftFolder(dragDropResult?.sourceMailboxId)) {
      handlePermanentDelete(dragDropResult);
    } else if (dragDropResult) {
      moveMessagesDragAndDrop(dragDropResult);
    }
  }, [dragDropResult]);

  useEffect(() => {
    dispatch(updateIsDragging(isDragging));
  }, [isDragging]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent): void => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('drag', handleMouseMove);

    return () => {
      window.removeEventListener('drag', handleMouseMove);
    };
  }, []);

  const handlePermanentDelete = ({ sourceMailboxId, messageUids }: DragDropResult): void => {
    setMousePos({ x: 0, y: 0 });
    dispatch(updateModalName('permanentDeleteMessageBulk'));
    dispatch(
      updateModalProps({
        mailboxId: sourceMailboxId,
        messageUids,
      })
    );
    dispatch(updateIsOpen(true));
  };

  const moveMessagesDragAndDrop = async ({
    destinationMailboxId,
    sourceMailboxId,
    messageUids,
  }: DragDropResult): Promise<void> => {
    setMousePos({ x: 0, y: 0 });

    const successToastMessage = messageUids.length > 1 ? t('successToast') : t('successToastSingle');

    const result = await dispatch(
      moveMessagesThunk({
        moveMessagesHook,
        payload: {
          destinationMailboxId,
          sourceMailboxId,
          messageUids,
          unseenMessagesCount: getUnseenMessagesCount(messageCollection[currentFolder || '']?.data, messageUids),
        },
      })
    ).unwrap();

    if (result.success) {
      trackEvent({ event: 'moveEmailSuccess' });
      const { limit, total, data } = messageCollection[sourceMailboxId];
      const lastPage = currentPage * limit > total;

      if (result.messageUids.length === data?.length && Number(currentPage) > 1 && lastPage) {
        navToPreviousPage();
      }

      if (messageUids.includes(parseInt(currentMailId || ''))) {
        closeMessage(sourceMailboxId, queryParams);
      }

      dispatch(reloadMessageCollection(true));
      dispatch(updateToastMessage({ message: successToastMessage, success: true }));
    } else {
      trackEvent({ event: 'moveEmailFail', payload: { errorMessage: result.error?.message } });
      dispatch(updateModalName('moveMessages'));
      dispatch(updateIsOpen(true));
      dispatch(
        updateModalProps({
          messageUids: messageUids,
          folder: sourceMailboxId,
          destinationMailboxId: destinationMailboxId,
        })
      );
    }
  };

  const countWidth = emailCount.toString().length;

  return (
    <div className={styles.container}>
      {itemType === 'listMessageItem' && (
        <div
          className={`${styles.item} ${!isDragging || (x === 0 && y === 0) ? styles.hidden : ''}`}
          style={{ transform: `translate(${x}px, ${y}px)` }}
        >
          <div className={styles.iconBackground} />
          <KiteIcon icon={'ki-mail'} size="23px" fill={'#000000'} className={styles.icon} />
          {canDrop ? (
            <div
              className={`${styles.emailCount} ${countWidth > 2 ? styles.wider : countWidth > 1 ? styles.wide : ''}`}
            >
              {emailCount}
            </div>
          ) : (
            <KiteIcon icon={'ki-cancel'} size="18px" fill={'#d6312b'} className={styles.cancel} />
          )}
        </div>
      )}
    </div>
  );
};

export default MessageDragDropLayer;
