import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import { useDeleteFilterMutation } from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import { updateFilterThunk } from 'thunks/filter/updateFilterThunk';
import { transformExistingFilters } from 'utils/transformFilterForm';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { DialogNSTranslationFileKeys, useDialogTranslation } from 'translation/hooks';

interface MoveMessageProps {
  filterId: number;
}

const DeleteFilter: React.FC<MoveMessageProps> = ({ filterId }) => {
  const { t } = useDialogTranslation('deleteFilterCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'settings' });
  const apiError = useAppSelector((state) => state.filterCollectionSlice.apiError);
  const filters = useAppSelector((state) => state.filterCollectionSlice.filters) || [];
  const [alertDescription, setAlertDescription] = useState<DialogNSTranslationFileKeys<'deleteFilterCopy'>>('');
  const focusOpener = useTarget('modal');
  const focusNextFilter = useTarget(`filter-${filterId}`);
  const [updateFiltersHook] = useDeleteFilterMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    trackEvent({ event: 'filterRulesDeleteModalView' });
  }, []);

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  const isSaved = async (): Promise<boolean> => {
    const filtersToSend = transformExistingFilters(filters);
    const filterIndex = filterId;

    trackEvent({ event: 'filterRulesDeleteModalViewDelete' });
    const result = await dispatch(
      updateFilterThunk({
        payload: {
          filters: [...filtersToSend.slice(0, filterIndex), ...filtersToSend.slice(filterIndex + 1)],
        },
        updateFiltersHook,
      })
    );
    //@ts-ignore
    if (result?.payload?.success) {
      trackEvent({ event: 'filterRulesDeleteSuccess' });
      dispatch(updateToastMessage({ message: t('successToast'), success: true }));
      dispatch(updateIsOpen(false));

      focusNextFilter();
      return true;
    }
    trackEvent({ event: 'filterRulesDeleteError' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'filterRulesDeleteModalViewClose' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    if (typeof filterId === 'number') {
      return true;
    }

    return false;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    isDelete: true,
    saveButtonText: t('delete'),
  };

  return (
    <ModalContentWrapper>
      <AutoFocusHeader content={t('header')} />
      <p className={modalStyle.alertMessage}>{t('warning')}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default DeleteFilter;
