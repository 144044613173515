import React, { useCallback, useState } from 'react';
import styles from 'components/compose-actions-toolbar/styles/ComposeActionsToolbar.module.scss';
import { KiteButton, KiteIcon } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { saveDraftThunk } from 'thunks/compose/saveDraftThunk';
import { PriorityEnum, useSaveDraftMutation, useSentMailMutation } from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import {
  removeFromTabCollection,
  updateComposeFormError,
  updateIsReadReceiptEnabled,
  updateIsSendPending,
  updateMaximizeCompose,
  updatePauseAutoSave,
  updatePriority,
} from 'slices/compose';
import { Editor } from '@tinymce/tinymce-react';
import {
  composeMailFormSendValidator,
  composeMailFormHelper,
  composeMailFormDraftValidator,
  composeMailFormToValidator,
  composeMailFormCcValidator,
  composeMailFormBccValidator,
  isValidEmail,
} from 'utils/composeMailFormHelper';
import { updateIsOpen, updateModalName, updateModalProps, updateToastMessage } from 'slices/app';
import { SentMailThunkResponse, sentMailThunk } from 'thunks/compose/sentMailThunk';
import KebabMenu from 'components/common/kebab-menu';
import { useNavigate, useParams } from 'react-router-dom';
import { reloadMessageCollection, removeMessageFromCollection } from 'slices/message-collection';
import { isDraftFolder, isSentMailFolder } from 'utils/folderNameValidator';
import { appConstants } from 'appConstants';
import { updateTotalCountForMailbox } from 'slices/mailbox-collection';
import { ValidationKeys, usePageTranslation } from 'translation/hooks';
import parseGraphQLError from 'utils/parseGraphQLError';
import { ApolloError } from '@apollo/client';
import { updateModalOpener } from 'components/common/focus/FocusContext';

interface ComposeActionToolBarProps {
  editorRef: Editor | null;
  setShowSignatureError: React.Dispatch<boolean>;
  showSignatureError: boolean;
}

const ComposeActionsToolbar: React.FC<ComposeActionToolBarProps> = ({
  editorRef,
  setShowSignatureError,
  showSignatureError,
}) => {
  const { t } = usePageTranslation('composeCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'compose' });

  const signature = useAppSelector((state) => state.signatureSlice.signatureEntity?.signature);
  const displayName = useAppSelector((state) => state.settingSlice.displayName);
  const activeTabIndex = useAppSelector((state) => state.composeSlice.activeTabIndex);
  const composeCollection = useAppSelector((state) => state.composeSlice.composeCollection);
  const isReadReceiptEnabled = useAppSelector(
    (state) => state.composeSlice.composeCollection[activeTabIndex]?.isReadReceiptEnabled
  );
  const priority = useAppSelector((state) => state.composeSlice.composeCollection[activeTabIndex]?.priority);
  const replyToAddress = useAppSelector((state) => state.settingSlice.replyTo);
  const [isToolbarShown, setIsToolbarShown] = useState(true);
  const [maxItems, setMaxItems] = useState(0);
  const updateModalRef = updateModalOpener();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { folder } = useParams<{ folder: string }>();

  const [saveDraftHook, { loading: draftLoading }] = useSaveDraftMutation();
  const [sentMailHook, { loading: sendMailLoading }] = useSentMailMutation();

  const handleSignatureClick = (): void => {
    setShowSignatureError(!showSignatureError);
    trackEvent({ event: 'insertSignature' });

    if (!editorRef) {
      return;
    }

    if (!signature) {
      trackEvent({ event: 'signatureNotFound' });
      dispatch(
        updateComposeFormError({
          type: 'info',
          index: activeTabIndex,
          errorIndex: 'signature_not_found',
          message: 'signatureNotFoundErr',
        })
      );
      return;
    }

    // @ts-ignore
    editorRef.execCommand('insertSignature', false);
  };

  const handleToolbarIconClick = (): void => {
    setIsToolbarShown(!isToolbarShown);
  };

  const handleSaveDraft = async (): Promise<void> => {
    const activeTab = composeCollection[activeTabIndex];
    trackEvent({ event: 'saveDraftWindow' });

    if (replyToAddress && !isValidEmail(replyToAddress)) {
      return;
    }

    if (!composeMailFormDraftValidator(activeTab)) {
      trackEvent({ event: 'saveDraftValidationError', errorMessage: 'toSubjectOrBodyMissing' });
      dispatch(
        updateComposeFormError({
          type: 'error',
          index: activeTabIndex,
          errorIndex: 'compose_form_invalid',
          message: 'composeFormDraftInvalid',
        })
      );
      return;
    }

    if (activeTab.to?.length && !composeMailFormToValidator(activeTab)) {
      handleValidationError('composeFormToEmailsInvalid', false);
      return;
    }

    if (activeTab.cc?.length && !composeMailFormCcValidator(activeTab)) {
      handleValidationError('composeFormCcEmailsInvalid', false);
      return;
    }
    if (activeTab.bcc?.length && !composeMailFormBccValidator(activeTab)) {
      handleValidationError('composeFormBccEmailsInvalid', false);
      return;
    }

    const result = await dispatch(
      saveDraftThunk({
        payload: {
          mail: composeMailFormHelper(activeTab, replyToAddress),
          index: activeTabIndex,
          displayName: displayName || '',
          isSelectedFolderDraft: folder ? isDraftFolder(folder) : false,
        },
        saveDraftHook,
      })
    ).unwrap();

    if (result.success) {
      trackEvent({ event: 'saveDraftSuccess' });
      dispatch(updateToastMessage({ message: t('draftSuccess'), success: true }));
      dispatch(updateMaximizeCompose(false));

      if (!activeTab.isOpenedFromDraft) {
        dispatch(updateTotalCountForMailbox({ mailboxId: appConstants.DRAFT_FOLDER_ID, updateCount: 1 }));
      }

      if (folder && isDraftFolder(folder)) {
        navigate(`/user/mail/${encodeURIComponent(folder)}`);
      }

      dispatch(removeFromTabCollection({ activeTabIndex, isDraftFolder: isDraftFolder(folder || '') }));
      return;
    }

    trackEvent({ event: 'saveDraftAPIError', errorCode: parseGraphQLError(result.error as ApolloError).errorCode });
    dispatch(
      updateComposeFormError({
        type: 'error',
        index: activeTabIndex,
        errorIndex: 'save_draft_failure',
        message: 'saveDraftFailureErr',
      })
    );
  };

  const handleReadReceipt = (): void => {
    !isReadReceiptEnabled ? trackEvent({ event: 'readReceiptEnabled' }) : trackEvent({ event: 'readReceiptDisabled' });
    dispatch(updateIsReadReceiptEnabled(!isReadReceiptEnabled));
  };

  const handlePriority = (priorityValue: PriorityEnum): void => {
    const newPriority = priority !== priorityValue ? priorityValue : PriorityEnum.Normal;
    dispatch(updatePriority(newPriority));
  };

  const handleDiscardDraft = (): void => {
    trackEvent({ event: 'discardDraftWindow' });
    dispatch(updateMaximizeCompose(false));
    if (composeCollection[activeTabIndex].isChanged) {
      dispatch(updatePauseAutoSave(true));
      dispatch(updateIsOpen(true));
      dispatch(updateModalName('discardDraft'));
      dispatch(updateModalProps({ folder }));
      return;
    }

    dispatch(removeFromTabCollection({ activeTabIndex, isDraftFolder: isDraftFolder(folder || '') }));
    if (isDraftFolder(folder as string)) {
      navigate(`/user/mail/${encodeURIComponent(appConstants.DRAFT_FOLDER_ID)}`);
    }
  };

  const handleValidationError = (validationMessage: ValidationKeys, isSendMail: boolean): void => {
    isSendMail
      ? trackEvent({ event: 'sendMailValidationError', errorMessage: 'compose_form_invalid' })
      : trackEvent({ event: 'saveDraftValidationError', errorMessage: 'invalidEmailInToCcOrBcc' });
    dispatch(
      updateComposeFormError({
        type: 'error',
        index: activeTabIndex,
        errorIndex: 'compose_form_invalid',
        message: validationMessage,
      })
    );
  };

  const handleSendMail = async (): Promise<void> => {
    const activeTab = composeCollection[activeTabIndex];
    trackEvent({ event: 'sendMail' });

    if (replyToAddress && !isValidEmail(replyToAddress)) {
      return;
    }

    const requiredFieldErrorMessage = composeMailFormSendValidator(activeTab);

    if (requiredFieldErrorMessage) {
      handleValidationError(requiredFieldErrorMessage, true);
      return;
    }
    if (!composeMailFormToValidator(activeTab)) {
      handleValidationError('composeFormToEmailsInvalid', true);
      return;
    }
    if (activeTab.cc && !composeMailFormCcValidator(activeTab)) {
      handleValidationError('composeFormCcEmailsInvalid', true);
      return;
    }
    if (activeTab.bcc && !composeMailFormBccValidator(activeTab)) {
      handleValidationError('composeFormBccEmailsInvalid', true);
      return;
    }

    dispatch(updateIsSendPending({ isPending: true, index: activeTabIndex }));
    const composeForm = composeMailFormHelper(activeTab, replyToAddress);

    const sendMail = async (): Promise<SentMailThunkResponse> => {
      return await dispatch(
        sentMailThunk({
          payload: {
            mail: composeForm,
            index: activeTabIndex,
            displayName: displayName || '',
            isOpenedFromDraft: activeTab.isFromDraft,
            currentFolder: folder || '',
          },
          sentMailHook,
        })
      ).unwrap();
    };

    const result = await sendMail();

    if (result.success) {
      if (composeCollection[activeTabIndex].isOpenedFromDraft) {
        dispatch(updateTotalCountForMailbox({ mailboxId: appConstants.DRAFT_FOLDER_ID, updateCount: -1 }));
      }
      if (folder && isDraftFolder(folder) && composeForm.uid) {
        navigate(`/user/mail/${encodeURIComponent(folder)}`);
        dispatch(removeMessageFromCollection({ id: composeForm.uid, currentFolder: folder }));
      }
      if (folder && isSentMailFolder(folder)) {
        dispatch(reloadMessageCollection(true));
      }

      dispatch(updateMaximizeCompose(false));
      dispatch(updateToastMessage({ message: t('messageSent'), success: true }));
      trackEvent({ event: 'sendMailSuccess' });
      return;
    }

    dispatch(updateIsSendPending({ isPending: false, index: activeTabIndex }));

    if (result.smtpResponse?.responseCode && result.smtpResponse?.responseCode >= 400) {
      dispatch(updateIsOpen(true));
      dispatch(updateModalName('AUPCode'));
      dispatch(updateModalProps({ sendMail, folder, uid: composeForm.uid }));
      return;
    }

    trackEvent({
      event: 'sendMailFailure',
      errorMessage: result?.error?.message,
    });
  };

  const composeKebabItems = [
    {
      label: !isReadReceiptEnabled ? t('requestReadReceipt') : t('removeReadReceipt'),
      icon: 'ki-mail-mark-read',
      onClick: handleReadReceipt,
      isEnabled: isReadReceiptEnabled,
      mobileIndex: 5,
    },
    {
      label: priority !== PriorityEnum.High ? t('markAsPriority') : t('removeAsPriority'),
      icon: 'ki-exclamation-mark',
      onClick: () => handlePriority(PriorityEnum.High),
      isEnabled: priority === PriorityEnum.High,
      mobileIndex: 4,
    },
    {
      label: t(`signature`),
      icon: 'ki-signature',
      onClick: handleSignatureClick,
      hideOnDesktop: true,
      mobileIndex: 3,
    },
    {
      label: t(`saveDraft`),
      icon: 'ki-save',
      hideOnDesktop: true,
      onClick: handleSaveDraft,
      mobileIndex: 0,
      boldFont: true,
    },
    {
      label: t(`textEditor`),
      icon: 'ki-text-editor',
      onClick: handleToolbarIconClick,
      hideOnDesktop: true,
      mobileIndex: 2,
    },
    {
      label: t('discard'),
      icon: 'ki-trash',
      hideOnDesktop: true,
      onClick: handleDiscardDraft,
      redIcon: true,
      mobileIndex: 1,
      boldFont: true,
    },
  ];

  const handleResize = (optionsContainer: HTMLDivElement): void => {
    if (optionsContainer) {
      const { width } = optionsContainer.getBoundingClientRect();

      switch (true) {
        case width < 360:
          setMaxItems(0);
          break;
        case width < 455:
          setMaxItems(1);
          break;
        case width < 600:
          setMaxItems(2);
          break;
        case width < 750:
          setMaxItems(3);
          break;
        case width < 970:
          setMaxItems(4);
          break;
        default:
          setMaxItems(5);
      }
    }
  };

  const handleResizeRef = useCallback((node: HTMLDivElement) => {
    handleResize(node);
    window.addEventListener('resize', () => handleResize(node));
    return () => window.removeEventListener('resize', () => handleResize(node));
  }, []);

  return (
    <>
      <div
        id="mceToolbarContainer"
        className={`${styles.mceToolbarContainer} ${isToolbarShown ? '' : styles.mceToolbarHide}`}
        ref={handleResizeRef}
      />
      <div className={styles.toolbarContainer}>
        <div className={styles.leftButtons}>
          <div className={styles.sendContainer}>
            <KiteButton
              loading={sendMailLoading}
              className={styles.send}
              onClick={(e) => {
                handleSendMail();
                updateModalRef(e.currentTarget);
              }}
              aria-label={'send mail'}
              disabled={composeCollection[activeTabIndex] && composeCollection[activeTabIndex].isSavePending}
            >
              {t('send')}
            </KiteButton>
          </div>
          <div className={styles.centerButtonList}>
            <KiteButton
              variant="borderless"
              loading={draftLoading}
              className={`${styles.centerButtons} ${draftLoading ? styles.draftLoading : ''}`}
              onClick={handleSaveDraft}
              aria-label={'save draft'}
              disabled={
                composeCollection[activeTabIndex] &&
                (composeCollection[activeTabIndex].isSavePending || composeCollection[activeTabIndex].isSendPending)
              }
            >
              <KiteIcon className={styles.icon} icon="ki-save" size="20px" />
              <span>{t('saveDraft')}</span>
            </KiteButton>
            <KiteButton
              className={`${styles.centerButtons} ${styles.discard}`}
              variant="borderless"
              onClick={handleDiscardDraft}
              aria-label={'discard draft'}
              disabled={composeCollection[activeTabIndex] && composeCollection[activeTabIndex].isSavePending}
            >
              <KiteIcon className={styles.icon} icon="ki-trash" size="20px" />
              <span>{t('discard')}</span>
            </KiteButton>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.rightButtons}>
          <div className={styles.rightButtonList}>
            <KiteButton
              variant="borderless"
              className={`${styles.icons} ${styles.desktopIcons} kite-button--icon-only`}
              onClick={handleToolbarIconClick}
              aria-label={'toggle formatting toolbar'}
            >
              <KiteIcon icon="ki-text-editor" size="24px" />
            </KiteButton>
            <KiteButton
              variant="borderless"
              className={`${styles.icons} ${styles.desktopIcons} kite-button--icon-only`}
              onClick={handleSignatureClick}
              aria-label={'insert signature'}
            >
              <KiteIcon icon="ki-signature" size="24px" />
            </KiteButton>
            {composeKebabItems
              .sort((a, b) => a.mobileIndex - b.mobileIndex)
              .slice(0, maxItems)
              .map((kebabMenuItem, idx) => (
                <React.Fragment key={`composeoptions-button__${kebabMenuItem.label}-${idx}`}>
                  {idx === 2 && <div className={styles.divider} />}
                  <KiteButton
                    variant="borderless"
                    size="shrinkwrap"
                    onClick={kebabMenuItem.onClick}
                    className={`${styles.mobileIcons} ${kebabMenuItem.redIcon ? styles.redIcon : ''} ${
                      kebabMenuItem.boldFont ? styles.boldFont : ''
                    }`}
                  >
                    <div className={styles.composeOptionsButton}>
                      <KiteIcon icon={kebabMenuItem.icon || ''} />
                      <p>{kebabMenuItem.label}</p>
                    </div>
                  </KiteButton>
                </React.Fragment>
              ))}
            {maxItems < composeKebabItems.length && (
              <KebabMenu
                items={composeKebabItems.filter((item) => !item.hideOnDesktop)}
                position="bottom-right"
                ariaLabel={'compose, expand for more options'}
                className={`${styles.kebabMenuDesktop}`}
              />
            )}
            {maxItems < composeKebabItems.length && (
              <KebabMenu
                items={composeKebabItems.slice(maxItems)}
                position="bottom-right"
                ariaLabel={'compose, expand for more options'}
                className={`${styles.kebabMenuMobile}`}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ComposeActionsToolbar;
