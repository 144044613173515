import {
  contactActionsCopy,
  contactFormCopy,
  contactNavigationCopy,
  contactViewCopy,
} from 'translation/models/pages/contacts';
import {
  emailViewCopy,
  composeCopy,
  emailActionsCopy,
  emailNavigationCopy,
  folderActionsCopy,
  printCopy,
} from 'translation/models/pages/email';
import {
  blockedSendersCopy,
  accountInfoCopy,
  autoReplyCopy,
  emailFormatCopy,
  filterRulesCopy,
  filterRuleFormCopy,
  forwardingCopy,
  replyToCopy,
  safeSendersCopy,
  signatureCopy,
  spamManagementCopy,
  timeZoneCopy,
  settingsTabsCopy,
} from 'translation/models/pages/settings';
import { topNavigationCopy } from 'translation/models/pages/top-navigation.model';
import { leftNavigationCopy } from 'translation/models/pages/left-navigation.model';
import { linksCopy } from 'translation/models/pages/links.model';
import { canaryCopy } from 'translation/models/pages/canary.model';

export const pages = {
  canaryCopy,
  linksCopy,
  topNavigationCopy,
  leftNavigationCopy,
  accountInfoCopy,
  autoReplyCopy,
  blockedSendersCopy,
  emailFormatCopy,
  filterRulesCopy,
  filterRuleFormCopy,
  forwardingCopy,
  replyToCopy,
  safeSendersCopy,
  settingsTabsCopy,
  signatureCopy,
  spamManagementCopy,
  timeZoneCopy,
  contactViewCopy,
  contactFormCopy,
  contactActionsCopy,
  contactNavigationCopy,
  emailViewCopy,
  composeCopy,
  emailActionsCopy,
  folderActionsCopy,
  emailNavigationCopy,
  printCopy,
};
