import React, { useEffect, useMemo } from 'react';
import styles from 'components/setting-email-format/styles/EmailFormat.module.scss';
import { KiteAlert, KiteCard } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateSettingThunk } from 'thunks/setting/updateSettingThunk';
import { usePatchSettingMutation } from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import { resetApiError } from 'slices/setting/setting';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import EmailFormatSwitch from 'components/setting-email-format/EmailFormatSwitch';
import { ErrorsKeys, usePageTranslation } from 'translation/hooks';

const SettingEmailFormat: React.FC = () => {
  const { t } = usePageTranslation('emailFormatCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'settings' });

  const {
    isAutoSaveDraftEnabled,
    isDisplayEmailAsHtmlEnabled,
    isDisplayImageEnabled,
    isAutoMailboxReloadEnabled,
    /**
     * Permanent Delete feature
     */

    // isEmailPermanentlyDeleted,
    apiError,
  } = useAppSelector((state) => state.settingSlice);

  const dispatch = useAppDispatch();

  const [updateSettingHook] = usePatchSettingMutation();

  useMemo(() => {
    trackEvent({ event: 'emailFormatPageViewInit' });
  }, []);

  useEffect(() => {
    trackEvent({ event: 'emailFormatPageView' });
  }, []);

  useEffect(() => {
    if (apiError) {
      trackEvent({ event: 'emailFormatError' });
    }
  }, [apiError]);

  const handleAutoSave = async (): Promise<void> => {
    dispatch(resetApiError());
    const result = await dispatch(
      updateSettingThunk({
        updateSettingHook,
        payload: { field: 'auto-save-draft', value: !isAutoSaveDraftEnabled },
      })
    );
    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'emailFormatAutoSave', isToggled: !isAutoSaveDraftEnabled });
      return;
    }
  };
  const handleDisplayAsText = async (): Promise<void> => {
    dispatch(resetApiError());
    const result = await dispatch(
      updateSettingThunk({
        updateSettingHook,
        payload: { field: 'display-html', value: isDisplayEmailAsHtmlEnabled },
      })
    );
    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'emailFormatDisplayAsText', isToggled: isDisplayEmailAsHtmlEnabled });
      return;
    }
  };

  const handleDisplayImage = async (): Promise<void> => {
    dispatch(resetApiError());
    const result = await dispatch(
      updateSettingThunk({
        updateSettingHook,
        payload: { field: 'display-image', value: !isDisplayImageEnabled },
      })
    );
    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'emailFormatDisplayImage', isToggled: !isDisplayImageEnabled });
      return;
    }
  };
  const handleAutoMailboxReload = async (): Promise<void> => {
    dispatch(resetApiError());
    const result = await dispatch(
      updateSettingThunk({
        updateSettingHook,
        payload: { field: 'auto-mailbox-reload', value: !isAutoMailboxReloadEnabled },
      })
    );
    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'emailFormatAutoMailboxReload', isToggled: !isAutoMailboxReloadEnabled });
      return;
    }
  };

  /**
   * Permanent Delete feature
   */

  // const handlePermanentlyDelete = async (): Promise<void> => {
  //   dispatch(resetApiError());
  //   const result = await dispatch(
  //     updateSettingThunk({
  //       updateSettingHook,
  //       payload: { field: 'email-permanent-delete', value: !isEmailPermanentlyDeleted }
  //     })
  //   );
  //   // @ts-ignore
  //   if (result.payload.success) {
  //     trackEvent({ event: 'emailFormatPermanentlyDelete', isToggled: !isEmailPermanentlyDeleted });
  //     return;
  //   }
  // };

  return (
    <div className={styles.emailFormat}>
      <KiteCard className={styles.card}>
        <div className={styles.title}>
          <AutoFocusHeader content={t('emailFormatTitle')} />
        </div>
        {apiError && (
          <KiteAlert className={styles.alert} type="error" description={t(apiError as ErrorsKeys)} level="page" />
        )}
        <div className={styles.form}>
          <ul className={styles.formGroupList}>
            <EmailFormatSwitch
              isOn={isAutoSaveDraftEnabled}
              onClick={handleAutoSave}
              subTitle={t('saveDraftSubtitle')}
              description={t(isAutoSaveDraftEnabled ? 'saveDraftDescriptionOn' : 'saveDraftDescriptionOff')}
            />
            <EmailFormatSwitch
              isOn={isDisplayEmailAsHtmlEnabled}
              onClick={handleDisplayAsText}
              subTitle={t('plainTextSubtitle')}
              description={t(`plainTextDescription${!isDisplayEmailAsHtmlEnabled ? 'On' : 'Off'}`)}
            />
            <EmailFormatSwitch
              isOn={isDisplayImageEnabled}
              isDisabled={isDisplayEmailAsHtmlEnabled}
              onClick={handleDisplayImage}
              subTitle={t('showImagesSubtitle')}
              description={t(`showImagesDescription${isDisplayImageEnabled ? 'On' : 'Off'}`)}
            />
            <EmailFormatSwitch
              isOn={isAutoMailboxReloadEnabled}
              onClick={handleAutoMailboxReload}
              subTitle={t('autoRefreshSubtitle')}
              description={t(isAutoMailboxReloadEnabled ? 'autoRefreshDescriptionOn' : 'autoRefreshDescriptionOff')}
            />

            {/*
            * Permanent Delete feature
            <InlineSwitch
              isOn={isEmailPermanentlyDeleted}
              onClick={handlePermanentlyDelete}
              field={'permanentlyDelete'}
              description="description"
            /> */}
          </ul>
        </div>
      </KiteCard>
    </div>
  );
};

export default SettingEmailFormat;
