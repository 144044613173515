import { ContactAddressEntity, ContactEmailEntity, ContactForm, ContactPhoneEntity } from 'generated/graphql';
import isEmail from 'validator/lib/isEmail';
import { ParsedVCards, SingleVCardProperty } from 'vcard4-ts';

interface PhoneTypeMapInterface {
  CELL: string;
  MOBILE: string;
  HOME: string;
  WORK: string;
  NONE: string;
}

export interface ContactFromImport {
  firstName: string;
  lastName: string;
  displayName: string;
  nickName?: string;
  title?: string;
  emails?: ContactEmailEntity[];
  phoneNumbers?: ContactPhoneEntity[];
  url?: string;
  addressess?: ContactAddressEntity[];
  notes?: string | ContactForm;
  company?: string;
}

const buildContactsFromVcf = (vcard: ParsedVCards): ContactFromImport[] =>
  vcard.vCards?.map((card) => {
    const formatPhoneNumber = (phoneNumberString: string): string => {
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '').slice(-10);
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }

      return '';
    };

    const formatDateOfBirth = (date: string): string | undefined => {
      const sanitized = date.split('-').toString().replace(/\D/g, '');

      return sanitized.length >= 8
        ? `${sanitized.slice(0, 4)}/${sanitized.slice(4, 6)}/${sanitized.slice(6, 8)}`
        : undefined;
    };

    const getPhoneNumberType = (phoneNumber: SingleVCardProperty<string>): string => {
      const phoneTypeMap = {
        CELL: 'MOBILE',
        MOBILE: 'MOBILE',
        HOME: 'HOME',
        WORK: 'WORK',
        NONE: '',
      };

      const phoneType = phoneNumber.parameters?.TYPE?.[0].toUpperCase() || 'NONE';

      return phoneTypeMap[phoneType as keyof PhoneTypeMapInterface];
    };

    const getNickName = (): string => {
      if (!card.NICKNAME) {
        return '';
      }

      return card.NICKNAME[0].value?.[0] || '';
    };

    const getFirstName = (): string => {
      if (!card.N) {
        return '';
      }
      return (
        (card.N.value.givenNames && card.N.value.givenNames[0]) ||
        (card.N.value.additionalNames && card.N.value.additionalNames[0]) ||
        ''
      );
    };

    const getLastName = (): string => {
      if (!card.N) {
        return '';
      }
      return (card.N.value.familyNames && card.N.value.familyNames[0]) || '';
    };

    const getDisplayName = (): string => {
      if (!card.FN) {
        return '';
      }
      return card.FN[0].value || (card.N?.value.additionalNames && card.N.value.additionalNames[0]) || '';
    };

    return {
      firstName: getFirstName(),
      lastName: getLastName(),
      displayName: getDisplayName(),
      nickName: getNickName(),
      company: card.ORG?.[0].value?.[0] || '',
      title: card.TITLE?.[0].value || '',
      emails: card.EMAIL?.map((email, idx) => ({
        emailAddress: isEmail(email.value) ? email.value : '',
        isDefault: idx === 0,
      })).filter((email) => email.emailAddress.length > 0),
      phoneNumbers: card.TEL?.map((phone) => ({
        phoneNumber: formatPhoneNumber(phone.value),
        phoneNumberType: getPhoneNumberType(phone),
      }))
        .slice(0, 3)
        .filter((phone) => phone.phoneNumber.length > 0),
      url: (card.URL && card.URL[0] && card.URL[0].value) || undefined,
      addresses: card.ADR?.map((address) => ({
        addressType: address.parameters?.TYPE?.[0],
        streetAddress: address.value.streetAddress?.[0] || undefined,
        city: address.value.locality?.[0],
        state: address.value.region?.[0],
        zipCode: address.value.postalCode?.[0],
        country: address.value.countryName?.[0],
      })),
      dateOfBirth: card.BDAY && formatDateOfBirth(card.BDAY.value),
      notes: card.NOTE?.[0].value,
    };
  }) as ContactFromImport[];

export default buildContactsFromVcf;
