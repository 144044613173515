import React, { useEffect, useState } from 'react';
import ContactSection from 'components/contact-form/sections/ContactSection';
import { KiteButton, KiteIcon, KiteRadio, KiteRadioButton, KiteTextInput } from '@kite/react-kite';
import styles from 'components/contact-form/styles/ContactCreateContact.module.scss';
import { ContactSectionProps } from 'components/contact-form/initialState';
import { ContactFormInterface, initialEmail } from 'components/contact-form/initialState';
import { ContactEmailEntity } from 'generated/graphql';
import validateContactField, { getContactValidationErrorMessage } from 'utils/validateContactField';
import { TrackingHook, useTracking } from 'react-tracking';
import { KiteInputElems } from 'libs/kite/types';
import { ValidationKeys, usePageTranslation } from 'translation/hooks';
import { emailsTrans } from './translationKeys';

interface Props {
  showMore: boolean;
  isNew: boolean;
}

export const Email: React.FC<Props & ContactSectionProps<Pick<ContactFormInterface, 'emails'>>> = ({
  updateFormValues,
  values,
  showMore,
  isNew,
  errors,
  setErrors,
}) => {
  const { t } = usePageTranslation('contactFormCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'contacts' });

  const [emailFields, setEmailFields] = useState<ContactEmailEntity[]>(values.emails);
  const [isValueLoaded, setIsValueLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!isValueLoaded && !isNew && values.emails && values.emails[0] && values.emails[0].emailAddress.length > 0) {
      setEmailFields(values.emails);
      setIsValueLoaded(true);
    }
  }, [values.emails]);

  useEffect(() => {
    updateFormValues(trimEmailAddresses(emailFields), 'emails');
  }, [emailFields]);

  const trimEmailAddresses = (contactEmailAddresses: ContactEmailEntity[]): ContactEmailEntity[] =>
    contactEmailAddresses.map((contactEmail) => ({ ...contactEmail, emailAddress: contactEmail.emailAddress.trim() }));

  const validate = (index: number, fields?: ContactEmailEntity[]): boolean => {
    return validateContactField(
      `emails[${index}].emailAddress`,
      { emails: trimEmailAddresses(fields || emailFields) },
      errors,
      setErrors
    );
  };

  const editEmail = (value: string, index: number): void => {
    const editedEmails = emailFields.map((email, idx) => {
      if (index === idx) {
        return { ...email, emailAddress: value };
      }
      return email;
    });
    setEmailFields(editedEmails);
    if (getEmailErrorMessage(index)) {
      validate(index, editedEmails);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<KiteInputElems>, index: number): void => {
    const isValid = validate(index);
    if (!isValid && !getEmailErrorMessage(index)) {
      e.currentTarget.focus();
    }
  };

  const selectDefault = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailFields(
      emailFields.map((email) => {
        email.isDefault = email.emailAddress === event.target.value;
        return email;
      })
    );
  };

  const getEmailErrorMessage = (index: number): ValidationKeys | '' => {
    return getContactValidationErrorMessage(errors, `emails[${index}].emailAddress`, emailsTrans);
  };

  const handleRemoveField = (index: number): void => {
    const newEmailFields = [...emailFields];
    newEmailFields.splice(index, 1);
    setEmailFields(newEmailFields);
    updateFormValues(newEmailFields, 'emails');
  };

  const renderEmailFields = (): JSX.Element[] => {
    return emailFields.map((email, index) => {
      const emailErrorMessage = getEmailErrorMessage(index);
      return (
        <div className={styles.fieldContainerWithRadio} key={index}>
          {index !== 0 && (
            <KiteButton
              className={styles.xButton}
              variant={'borderless'}
              icon={'ki-x'}
              onClick={() => handleRemoveField(index)}
              aria-label={t('removeEmailAria')}
            />
          )}
          <KiteTextInput
            className={styles.fullField}
            placeholder={t('emailPlaceholder')}
            label={emailFields.length > 1 ? t('email', { count: index + 1 }) : t('emailReq')}
            onChange={(e) => editEmail(e.target.value, index)}
            onBlur={(e) => handleOnBlur(e, index)}
            value={email.emailAddress || ''}
            errorMessage={t(emailErrorMessage)}
            aria-required={index === 0}
            ariaLive="polite"
          />
          <div className={styles.fieldRadio}>
            <KiteRadioButton
              id={`${email.emailAddress}${index}`}
              label={`${t('default')}?`}
              name={'defaultEmail'}
              value={email.emailAddress}
              checked={email.isDefault || false}
            />
          </div>
        </div>
      );
    });
  };

  const addEmail = (): void => {
    trackEvent({ event: 'addEmail' });
    setEmailFields([...emailFields, { ...initialEmail }]);
  };

  return (
    <ContactSection isAddress>
      <div className={styles.fieldContainerAddress}>
        <KiteIcon className={styles.fieldIcon} icon="ki-mail" />
        <KiteRadio onChange={(e) => selectDefault(e)}>{renderEmailFields()}</KiteRadio>
      </div>
      {showMore && (
        <KiteButton size="sm" icon={'ki-plus'} onClick={addEmail} variant="borderless" aria-label={'add email'}>
          {t('addEmail')}
        </KiteButton>
      )}
    </ContactSection>
  );
};
