import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PatchEmailPerPageMutationFn, SettingEntity } from 'generated/graphql';

export interface UpdateItemsPerPageInterface {
  payload: {
    value: string;
  };
  updateEmailPerPageHook: PatchEmailPerPageMutationFn;
}

export interface updateEmailPerPageResponse {
  success?: SettingEntity;
  error?: ApolloError;
}

export const updateEmailPerPageThunk = createAsyncThunk(
  'updateEmailPerPageThunk',
  async (arg: UpdateItemsPerPageInterface): Promise<updateEmailPerPageResponse> => {
    try {
      const response = await arg.updateEmailPerPageHook({
        variables: {
          value: arg.payload.value,
        },
      });
      return {
        success: response.data?.patchEmailPerPage,
      } as updateEmailPerPageResponse;
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
