export const CommonCopy = {
  required: '',
  on: '',
  off: '',
  save: '',
  cancel: '',
  saved: '',
  loading: '',
  back: '',
  saving: '',
  edit: '',
  options: '',
  menu: '',
  delete: '',
  default: '',
  please: '',
  changesSaved: '',
  Inbox: '',
  Drafts: '',
  'Sent Mail': '',
  Spam: '',
  Trash: '',
  noResults: '',
  noResultsDescription: '',
  tryAgain: '',
  searchResults: '',
  toolbarShortcut: '',
  goBack: '',
  primary: '',
  ok: '',
};
