import { KiteGrid, KiteGridCell, KiteSwitch } from '@kite/react-kite';
import styles from 'components/setting-email-format/styles/EmailFormat.module.scss';
import { useSharedTranslation } from 'translation/hooks';

interface Props {
  isOn: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  subTitle: string;
  description: string;
}

const EmailFormatSwitch: React.FC<Props> = ({ isOn, onClick, subTitle, description, isDisabled = false }) => {
  const { t } = useSharedTranslation();
  return (
    <li>
      <KiteGrid>
        <KiteGridCell col={9}>
          <div className={styles.label}>{subTitle}</div>
          <div className={styles.description}>{description}</div>
        </KiteGridCell>
        <KiteGridCell col={3}>
          <KiteSwitch
            label={isOn ? `${t('on')}` : `${t('off')}`}
            className={styles.switch}
            onClick={onClick}
            selected={isOn}
            disabled={isDisabled}
          ></KiteSwitch>
        </KiteGridCell>
      </KiteGrid>
    </li>
  );
};

export default EmailFormatSwitch;
