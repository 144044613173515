import { createAsyncThunk } from '@reduxjs/toolkit';
import { LdapSettingEntity } from 'generated/graphql';
import { ApolloError } from '@apollo/client';

interface GetLdapSettingArg {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getLdapSettingHook: any;
}

interface GetLdapSettingResponse {
  success?: LdapSettingEntity;
  error?: ApolloError;
}

export const getLdapSettingThunk = createAsyncThunk(
  'getLdapSetting',
  async (arg: GetLdapSettingArg): Promise<GetLdapSettingResponse> => {
    try {
      const response = await arg.getLdapSettingHook();
      return {
        success: response.data.getLdapSetting,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
