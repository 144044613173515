import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
  isOpen: boolean;
  modalName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modalProps: any;
  isDrawerOpen: boolean;
  drawerName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isNavBlocking: boolean;
  toastMessage: string;
  toastIcon: string;
  isCanary: boolean;
  autoFocus?: boolean;
  canDrop: boolean;
  isDragging: boolean;
}

const initialState: AppState = {
  isOpen: false,
  isDrawerOpen: false,
  isNavBlocking: false,
  toastMessage: '',
  toastIcon: 'ki-checkmark',
  isCanary: false,
  modalProps: {},
  canDrop: true,
  isDragging: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateIsDrawerOpen: (state: AppState, action: PayloadAction<boolean>) => {
      state.isDrawerOpen = action.payload;
    },
    updateDrawerName: (state: AppState, action: PayloadAction<string>) => {
      state.drawerName = action.payload;
    },
    updateIsOpen: (state: AppState, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
      if (!action.payload) {
        state.modalProps = {};
        state.modalName = '';
      }
    },
    updateNavBlocking: (state: AppState, action: PayloadAction<boolean>) => {
      state.isNavBlocking = action.payload;
    },
    updateToastMessage: (state: AppState, action: PayloadAction<{ message: string; success: boolean }>) => {
      state.toastMessage = action.payload.message;
      state.toastIcon = action.payload.success ? 'ki-checkmark' : 'ki-x';
    },
    updateIsCanary: (state: AppState, action: PayloadAction<boolean>) => {
      state.isCanary = action.payload;
    },
    updateModalName: (state: AppState, action: PayloadAction<string>) => {
      state.modalName = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateModalProps: (state: AppState, action: PayloadAction<any>) => {
      state.modalProps = action.payload;
    },
    updateAutoFocus: (state: AppState, action: PayloadAction<any>) => {
      state.autoFocus = action.payload;
    },
    updateCanDrop: (state: AppState, action: PayloadAction<boolean>) => {
      state.canDrop = action.payload;
    },
    updateIsDragging: (state: AppState, action: PayloadAction<boolean>) => {
      state.isDragging = action.payload;
    },
  },
});

export const {
  updateIsOpen,
  updateNavBlocking,
  updateToastMessage,
  updateModalName,
  updateModalProps,
  updateIsCanary,
  updateIsDrawerOpen,
  updateDrawerName,
  updateAutoFocus,
  updateCanDrop,
  updateIsDragging,
} = appSlice.actions;

export default appSlice.reducer;
