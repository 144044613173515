import { MutableRefObject, useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useOuterClick = (callback: any): MutableRefObject<undefined> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callbackRef = useRef<any>(null); // initialize mutable ref, which stores callback
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const innerRef = useRef<any>(null); // returned to client, who marks "border" element

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (e: any): void => {
      if (innerRef.current && callbackRef.current && !innerRef?.current?.contains(e.target)) callbackRef?.current(e);
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
};

export default useOuterClick;
