import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import { useSentMailMutation } from 'generated/graphql';
import { sentMailThunk } from 'thunks/compose/sentMailThunk';
import { v4 as uuidv4 } from 'uuid';
import { TrackingHook, useTracking } from 'react-tracking';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, useDialogTranslation } from 'translation/hooks';
import getReadReceiptReplyToAddress from 'utils/getReadReceiptReplyToAddress';
import { useParams } from 'react-router-dom';

interface ReadReceiptProps {
  subject: string;
  readReceiptReplyTo: string;
  to: {
    name?: string;
    address: string;
  };
}

const ReadReceipt: React.FC<ReadReceiptProps> = ({ subject, readReceiptReplyTo, to }) => {
  const { t } = useDialogTranslation('readReceiptCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const focusOpener = useTarget('modal');
  const apiError = useAppSelector((state) => state.messageSlice.apiError);
  const displayName = useAppSelector((state) => state.settingSlice.displayName);
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const { folder: currentFolder } = useParams();

  const dispatch = useAppDispatch();

  const [sentMailHook] = useSentMailMutation();

  useEffect(() => {
    trackEvent({ event: 'readReceiptModalView' });
  }, []);

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  const readReceiptBody = (): string => {
    const body = [
      to?.name ? `To: ${to.name} <${to.address}>` : `To: ${to.address}`,
      `Subject: ${subject ?? t('noSubject')}`,
      `Sent: ${new Date().toUTCString()}`,
    ];
    return body.join('<br/>');
  };

  const isSaved = async (): Promise<boolean> => {
    const result = await dispatch(
      sentMailThunk({
        payload: {
          mail: {
            to: [{ address: getReadReceiptReplyToAddress(readReceiptReplyTo) }],
            subject: `Read: ${subject ?? t('noSubject')}`,
            html: readReceiptBody(),
          },
          displayName: displayName || '',
          index: uuidv4(),
          isReadReceipt: true,
          currentFolder: currentFolder || '',
        },
        sentMailHook,
      })
    ).unwrap();

    if (result?.success) {
      trackEvent({ event: 'readReceiptSent' });
      dispatch(updateToastMessage({ message: t('successToast'), success: true }));
      dispatch(updateIsOpen(false));
      return true;
    }
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'readReceiptCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t('confirmBtnText'),
    cancelLabel: t('noText'),
  };

  return (
    <ModalContentWrapper>
      <h5>{t('header')}</h5>
      <p>{`${readReceiptReplyTo} ${t('description')}`}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <div className={modalStyle.selectOption}></div>
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default ReadReceipt;
