import React from 'react';
import ContactSection from 'components/contact-form/sections/ContactSection';
import { KiteIcon, KiteTextInput } from '@kite/react-kite';
import styles from 'components/contact-form/styles/ContactCreateContact.module.scss';
import { ContactSectionProps } from 'components/contact-form/initialState';
import { ContactFormInterface } from 'components/contact-form/initialState';
import validateContactField, { getContactValidationErrorMessage } from 'utils/validateContactField';
import { KiteInputElems } from 'libs/kite/types';
import { urlTrans } from './translationKeys';
import { usePageTranslation } from 'translation/hooks';

export const URL: React.FC<ContactSectionProps<Pick<ContactFormInterface, 'url'>>> = ({
  updateFormValues,
  values,
  errors,
  setErrors,
}) => {
  const { t } = usePageTranslation('contactFormCopy');
  const urlErrorMessage = getContactValidationErrorMessage(errors, 'url', urlTrans);

  const handleUrlChange = (e: React.ChangeEvent<KiteInputElems>): void => {
    updateFormValues(e.target.value, 'url');
    validateContactField('url', { url: e.target.value }, errors, setErrors);
  };

  return (
    <ContactSection>
      <KiteIcon className={styles.fieldIcon} icon="ki-internet" />
      <div className={styles.fieldContainer}>
        <KiteTextInput
          className={styles.fullField}
          placeholder={t('urlPlaceholder')}
          label={t('url')}
          onChange={(e) => handleUrlChange(e)}
          value={values.url || ''}
          errorMessage={t(urlErrorMessage)}
          aria-label={'enter URL'}
          ariaLive="polite"
        />
      </div>
    </ContactSection>
  );
};
