export const setAccessToken = (accessToken: string): void => {
  localStorage.setItem('webmail_access_token', accessToken);
};

export const getAccessToken = (): string | null => {
  return localStorage.getItem('webmail_access_token');
};

export const setRefreshToken = (refreshToken: string): void => {
  localStorage.setItem('webmail_refresh_token', refreshToken);
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem('webmail_refresh_token');
};

export const getLoggedInEmail = (): string | null => {
  return localStorage.getItem('webmail_email');
};

export const getLoggedInFootprint = (): string | null => {
  return localStorage.getItem('webmail_footprint');
};

export const getLoggedInAccountNumber = (): string | null => {
  return localStorage.getItem('webmail_account_number');
};

export const getLoggedInNamespace = (): string | null => {
  return localStorage.getItem('webmail_namespace');
};

export const getIdToken = (): string | null => {
  return localStorage.getItem('webmail_id_token');
};

export const setLoggedInEmail = (email: string): void => {
  localStorage.setItem('webmail_email', email);
};

export const setLoggedInFootprint = (footprint: string): void => {
  localStorage.setItem('webmail_footprint', footprint);
};

export const setLoggedInAccountNumber = (accountNumber: string): void => {
  localStorage.setItem('webmail_account_number', accountNumber);
};

export const setLoggedInNamespace = (namespace: string): void => {
  localStorage.setItem('webmail_namespace', namespace);
};

export const getLoggedInVisitIdStorage = (): string | null => {
  return localStorage.getItem('webmail_visit_id');
};

export const setLoggedInVisitIdStorage = (visitId: string): void => {
  localStorage.setItem('webmail_visit_id', visitId);
};

export const getLoggedInAccountGuid = (): string | null => {
  return localStorage.getItem('webmail_account_guid');
};

export const setLoggedInAccountGuid = (accountGuid: string): void => {
  localStorage.setItem('webmail_account_guid', accountGuid);
};

export const setIdToken = (namespace: string): void => {
  localStorage.setItem('webmail_id_token', namespace);
};

export const setExpiresIn = (expiresIn: number): void => {
  localStorage.setItem('webmail_expires_at', new Date(new Date().getTime() + expiresIn * 1000).getTime().toString());
};

export const getExpiresIn = (): number => {
  return parseInt(localStorage.getItem('webmail_expires_at') || '');
};

export const setAuthCode = (authCode: string): void => {
  localStorage.setItem('webmail_auth_code', authCode);
};

export const getAuthCode = (): string | null => {
  return localStorage.getItem('webmail_auth_code');
};

export const removeAuthCode = (): void => {
  return localStorage.removeItem('webmail_auth_code');
};

export const getOptInStatusStorage = (): string | null => {
  return localStorage.getItem('webmail_opt_in_status');
};

export const setOptInStatusStorage = (optInStatus: string): void => {
  localStorage.setItem('webmail_opt_in_status', optInStatus);
};

export const getOptInTypeStorage = (): string | null => {
  return localStorage.getItem('webmail_opt_in_type');
};

export const setOptInTypeStorage = (optInType: string): void => {
  localStorage.setItem('webmail_opt_in_type', optInType);
};

export const removeSessionTokens = (): void => {
  localStorage.removeItem('webmail_access_token');
  localStorage.removeItem('webmail_email');
  localStorage.removeItem('webmail_footprint');
  localStorage.removeItem('webmail_account_number');
  localStorage.removeItem('webmail_namespace');
  localStorage.removeItem('webmail_id_token');
  localStorage.removeItem('webmail_expires_at');
  localStorage.removeItem('webmail_refresh_token');
  localStorage.removeItem('webmail_auth_code');
  localStorage.removeItem('webmail_visit_id');
  localStorage.removeItem('webmail_account_guid');
  localStorage.removeItem('webmail_opt_in_status');
  localStorage.removeItem('webmail_opt_in_type');
};
