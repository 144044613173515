import { GlobalFoldersInterface, appConstants } from 'appConstants';
import { FilterEntity } from 'generated/graphql';
import { PageTranslationReturn } from 'translation/hooks';

export const getFilterDescription = (
  filter: FilterEntity,
  t: PageTranslationReturn<'filterRulesCopy'>['t']
): string => {
  const join = filter.clause === 'all' ? t('filterAnd') : t('filterOr');

  const description = [];
  const rules = filter.rules.map(({ field, value, operator }) => {
    return t(operator === 'contains' ? 'filterRuleContains' : 'filterRuleDoesNotContain', { field, value });
  });

  if (rules && rules.length > 0) {
    description.push(rules.join(` ${join} `));
  }

  let priorities = '';
  if (filter.actionPriority && filter.actionPriority === 'yes') {
    if (filter.highPriority === 'yes') {
      priorities = t('filterHigh');
    } else if (filter.highPriority === 'no') {
      priorities = t('filterNotHigh');
    }
  }

  if (priorities && priorities.length > 0) {
    if (description.length > 0) {
      description.push(t('filterAnd'));
    }

    description.push(priorities);
  }

  description.push('Then');
  let firstAction = true;
  if (filter.actionMove && filter.actionMove === 'yes') {
    const folderName = atob(filter.moveFolder || '');
    description.push(
      t('filterMove', {
        folder: appConstants.GLOBAL_FOLDER_NAMES[folderName as keyof GlobalFoldersInterface] ?? folderName,
      })
    );
    firstAction = false;
  }

  if (filter.actionDelete && filter.actionDelete === 'yes') {
    if (!firstAction) {
      description.push('AND');
    }
    description.push(t('filterDelete'));
    firstAction = false;
  }

  if (filter.actionForward && filter.actionForward === 'yes') {
    if (!firstAction) {
      description.push('AND');
    }
    description.push(t('filterForward', { list: filter.forwardList?.join(',') }));
  }

  return 'IF ' + description.join(' ').trim();
};
