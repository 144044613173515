export const filterRulesCopy = {
  title: '',
  action: '',
  details: '',
  createNewRule: '',
  filterIf: '',
  filterRuleContains: '',
  filterRuleDoesNotContain: '',
  filterAnd: '',
  filterThen: '',
  filterOr: '',
  filterHigh: '',
  filterNotHigh: '',
  filterMove: '',
  filterDelete: '',
  filterForward: '',
};
