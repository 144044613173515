import Helix from '@charter/helix/build/portals/index.min';
import { HelixEventFn, HelixPayloadInterface } from 'libs/tracking/adapters/helix/helixAdapter';

interface HelixContactsPayloadInterface extends HelixPayloadInterface {
  contacts?: 'example';
  payload?: {
    totalSelected?: number;
    uploadCount?: number;
    keyword?: string;
    searchResultCount?: number;
    failedOverQuota?: number;
  };
}

const submitContactsEvent: HelixEventFn<HelixContactsPayloadInterface> = ({ event, payload }) => {
  switch (event) {
    case 'contactLabelClick': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_selectLabel');
      break;
    }
    case 'allContactsPageViewInit': {
      Helix.track('WEBMAIL_pageViewInit_contacts_allContacts', {
        currPageUrl: '/user/label/all',
      });
      break;
    }
    case 'allContactsPageView': {
      Helix.track('WEBMAIL_pageView_contacts_allContacts', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'selectContacts': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_selectContact', {
        currPageElemIntValue: payload?.totalSelected,
      });
      break;
    }
    case 'deselectContacts': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_deselectContact', {
        currPageElemIntValue: payload?.totalSelected,
      });
      break;
    }
    case 'selectAllContacts': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_selectAllContacts');
      break;
    }
    case 'sendEmail': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_sendEmail');
      break;
    }
    case 'deleteContacts': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_deleteContacts');
      break;
    }
    case 'exportContacts': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_exportContacts');
      break;
    }
    case 'deleteModalView': {
      Helix.track('WEBMAIL_modalView_deleteContact');
      break;
    }
    case 'deleteContactSave': {
      Helix.track('WEBMAIL_selectAction_deleteContactModal_deleteContact');
      break;
    }
    case 'deleteContactCancel': {
      Helix.track('WEBMAIL_selectAction_deleteContactModal_keepContact');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'deleteContactSuccess': {
      Helix.track('WEBMAIL_applicationActivity_deleteContactSuccess');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'deleteContactErrorApi': {
      Helix.track('WEBMAIL_error_deleteContactError', {
        appErrorMessage: 'unableToDeleteContact',
        appErrorType: 'generic',
      });
      break;
    }
    case 'contactViewLinkClicked': {
      Helix.track('WEBMAIL_assetDisplayed_contactDetail');
      break;
    }
    case 'contactViewSelect': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_selectContact', {
        currPageElemIntValue: 1,
      });
      break;
    }
    case 'closeContactView': {
      Helix.track('WEBMAIL_selectAction_contactDetailsView_close');
      break;
    }
    case 'editContact': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_editContact');
      break;
    }
    case 'updateContactPageViewInit': {
      Helix.track('WEBMAIL_pageViewInit_contacts_editContact', {
        currPageUrl: '/update',
      });
      break;
    }
    case 'updateContactPageView': {
      Helix.track('WEBMAIL_pageView_contacts_editContact', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'addEmail': {
      Helix.track('WEBMAIL_selectAction_editContactView_addEmailAddress');
      break;
    }
    case 'cancelUpdate': {
      Helix.track('WEBMAIL_selectAction_editContactView_cancel');
      break;
    }
    case 'saveUpdate': {
      Helix.track('WEBMAIL_selectAction_editContactView_save');
      break;
    }
    case 'saveUpdateError': {
      Helix.track('WEBMAIL_error_editContactError');
      break;
    }
    case 'saveUpdateSuccess': {
      Helix.track('WEBMAIL_applicationActivity_editContactSuccess');
      break;
    }
    case 'assignLabelNav': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_assignLabels');
      break;
    }
    case 'assignLabelModalView': {
      Helix.track('WEBMAIL_modalView_assignLabels');
      break;
    }
    case 'assignLabelModalSave': {
      Helix.track('WEBMAIL_selectAction_assignLabelsModal_Save');
      break;
    }
    case 'assignLabelModalCancel': {
      Helix.track('WEBMAIL_selectAction_assignLabelsModal_Cancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'assignLabelSuccess': {
      Helix.track('WEBMAIL_modalClose');
      Helix.track('WEBMAIL_applicationActivity_assignLabelSuccess', {
        operationIntValue: payload?.totalSelected || 0,
      });
      break;
    }
    case 'assignLabelApiError': {
      Helix.track('WEBMAIL_error_assignLabelsModal');
      break;
    }
    case 'printContacts': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_printContacts');
      break;
    }
    case 'exportContactNavigation': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_exportContacts');
      break;
    }
    case 'exportContactSuccess': {
      Helix.track('WEBMAIL_applicationActivity_exportContacts_success');
      break;
    }
    case 'exportContactApiError': {
      Helix.track('WEBMAIL_modalView_exportContactFailure');
      break;
    }
    case 'exportContactErrorCancel': {
      Helix.track('WEBMAIL_selectAction_exportContactModal_cancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'exportContactTryAgain': {
      Helix.track('WEBMAIL_selectAction_exportContactModal_tryAgain');
      break;
    }
    case 'importContactsNav': {
      Helix.track('WEBMAIL_selectAction_contacts_import');
      break;
    }
    case 'importContactsModalView': {
      Helix.track('WEBMAIL_modalView_importContacts');
      break;
    }
    case 'importContactsSelectFile': {
      Helix.track('WEBMAIL_selectAction_importContactModal_selectFile');
      break;
    }
    case 'importContactsCancel': {
      Helix.track('WEBMAIL_selectAction_importContactModal_cancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'importContactsSave': {
      Helix.track('WEBMAIL_selectAction_importContactModal_import');
      break;
    }
    case 'importContactSuccess': {
      Helix.track('WEBMAIL_applicationActivity_importContacts_success', {
        operationIntValue: payload?.uploadCount,
      });
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'importContactPartialFailure': {
      Helix.track('WEBMAIL_error_importContacts', {
        appErrorExtras: { errorMsg: `Over: ${payload?.failedOverQuota} | Success: ${payload?.uploadCount}` },
      });
      break;
    }
    case 'importContactAPIFailure': {
      Helix.track('WEBMAIL_error_importContacts', {
        appErrorExtras: { errorMsg: 'apiError' },
      });
      break;
    }
    case 'searchStart': {
      Helix.track('WEBMAIL_selectAction_searchStarted', {
        searchType: 'keyword',
        searchId: payload?.keyword,
      });
      break;
    }
    case 'searchClose': {
      Helix.track('WEBMAIL_searchClosed');
      break;
    }
    case 'searchResultReturned': {
      Helix.track('WEBMAIL_searched_keyword', {
        searchElem: payload?.searchResultCount,
        searchText: payload?.keyword,
        searchResultsMs: 10,
      });
      break;
    }
    case 'searchResultSelected': {
      Helix.track('WEBMAIL_selectAction_searchResultSelected_keyword');
      break;
    }
    case 'searchNoResults': {
      Helix.track('WEBMAIL_searched_keyword_noResults', {
        searchElem: 0,
        searchText: payload?.keyword,
        searchResultsMs: 10,
      });
      Helix.track('WEBMAIL_assetDisplayed_searched_noResultsFound');
      break;
    }
    case 'newContactPageViewComplete': {
      Helix.track('WEBMAIL_pageView_contacts_addNewContact', {
        msgEventType: 'pageViewCompleted',
      });
      break;
    }
    case 'saveContact': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_saveContact');
      break;
    }
    case 'saveContactSuccess': {
      Helix.track('WEBMAIL_applicationActivity_addOrEditContact_success', {
        visitAdditionalInformation: 'addContact',
      });
      break;
    }
    case 'saveContactValidationError': {
      Helix.track('WEBMAIL_error_contacts_addContactError', {
        appErrorMessage: 'validationError',
      });
      break;
    }
    case 'saveContactAPIError': {
      Helix.track('WEBMAIL_error_contacts_addContactError', {
        appErrorMessage: 'unableToSave',
      });
      break;
    }
    case 'saveContactCancel': {
      Helix.track('WEBMAIL_selectAction_contactsNavigation_cancelSaveContact');
      break;
    }
    case 'contactReSyncClicked': {
      Helix.track('WEBMAIL_selectAction_syncContacts');
      break;
    }
    case 'contactReSyncModalView': {
      Helix.track('WEBMAIL_modalView_syncContacts', {
        modalText: 'Sync Contacts?',
      });
      break;
    }
    case 'contactReSyncModalCancel': {
      Helix.track('WEBMAIL_selectAction_syncContactsModals_cancel');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    case 'contactReSyncButtonClicked': {
      Helix.track('WEBMAIL_selectAction_syncContactsModal_syncContacts');
      Helix.track('WEBMAIL_modalView_contactsSyncingModal', {
        modalText: 'Sync Contacts In Progress',
      });
      break;
    }
    case 'contactReSyncSuccess': {
      Helix.track('WEBMAIL_modalView_contactSyncSuccess', {
        modalText: 'Contacts Synced',
      });
      break;
    }
    case 'contactReSyncFailure': {
      Helix.track('WEBMAIL_modalView_contactSyncFailure', {
        modalText: 'Unable to sync Contacts',
      });
      break;
    }
    case 'contactReSyncOkClicked': {
      Helix.track('WEBMAIL_selectAction_syncContactsStatusModal_ok');
      Helix.track('WEBMAIL_modalClose');
      break;
    }
    default: {
      console.log('Event Case For Contacts Not Found', event);
      return null;
    }
  }
  return null;
};

export default submitContactsEvent;
