import React, { useContext, useEffect } from 'react';
import 'libs/globals';
import 'components/app/App.scss';
import { useAppDispatch } from 'hooks';
import { useGetOptInStatusLazyQuery } from 'generated/graphql';
import { getOptInStatusThunk } from 'thunks/canary/getOptInStatusThunk';
import { AuthContext } from 'providers/AuthProvider';

const PreFetchOptInStatus: React.FC = () => {
  const dispatch = useAppDispatch();
  const [getOptInStatusHook] = useGetOptInStatusLazyQuery();
  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getOptInStatusThunk({ getOptInStatusHook }));
    }
  }, [isLoggedIn]);

  return <></>;
};

export default PreFetchOptInStatus;
