import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import styles from 'components/inbox-messages-modal/styles/MoveMessages.module.scss';
import { KiteAlert, KiteSelect, KiteProgressIndicator } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import {
  useGetGlobalMailBoxStatusLazyQuery,
  useGetMailBoxCollectionLazyQuery,
  useMoveMessagesMutation,
} from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import { moveMessagesThunk } from 'thunks/message-collection/moveMessagesThunk';
import { GlobalFoldersInterface, appConstants } from 'appConstants';
import { reloadMessageCollection } from 'slices/message-collection';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { isGlobalFolder, isInboxFolder, isSentMailFolder } from 'utils/folderNameValidator';
import getUnseenMessagesCount from 'utils/getUnseenMessagesCount';
import { mailboxCollectionGetThunk } from 'thunks/mailbox-collection/mailboxCollectionThunk';
import { globalMailboxStatusThunk } from 'thunks/mailbox-collection/globalMailboxStatusThunk';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, useDialogTranslation } from 'translation/hooks';
import useNavToPreviousPage from 'components/common/hooks/useNavToPreviousPage';
import useCloseMessage from 'components/common/hooks/useCloseMessage';
import transformNestedGlobalFolderPath from 'utils/transformNestedGlobalFolderPath';

interface MoveMessageProps {
  sourceMailboxId: string;
  messageUids: number[];
  currentMailId?: string;
  destinationMailboxId?: string;
}
const MoveMessages: React.FC<MoveMessageProps> = ({
  sourceMailboxId,
  messageUids,
  currentMailId,
  destinationMailboxId,
}) => {
  const { t } = useDialogTranslation('moveMessagesCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const apiError = useAppSelector((state) => state.messageCollectionSlice.apiError);
  const mailboxCollection = useAppSelector((state) => state.mailboxCollectionSlice.mailboxCollection);
  const messageCollection = useAppSelector((state) => state.messageCollectionSlice.messageCollection);
  const { mailboxLoading } = useAppSelector((state) => state.mailboxCollectionSlice);
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const [kiteSelectValue, setKiteSelectValue] = useState(destinationMailboxId || '');
  const focusOpener = useTarget('modal');
  const dispatch = useAppDispatch();
  const [moveMessagesHook] = useMoveMessagesMutation();
  const [getGlobalMailboxStatusHook] = useGetGlobalMailBoxStatusLazyQuery();
  const [getMailBoxCollectionHook] = useGetMailBoxCollectionLazyQuery();
  const [queryParams, currentPage, navToPreviousPage] = useNavToPreviousPage();
  const spamFolderId = useAppSelector((state) => state.mailboxCollectionSlice.spamFolderId);
  const closeMessage = useCloseMessage();

  useEffect(() => {
    trackEvent({ event: 'moveEmailsModal' });
  }, []);

  const dispatchMailboxCollection = async (): Promise<void> => {
    await dispatch(globalMailboxStatusThunk({ getGlobalMailboxStatusHook }));
    await dispatch(mailboxCollectionGetThunk({ getMailBoxCollectionHook }));
  };

  useEffect(() => {
    if (!(mailboxCollection && mailboxCollection.length)) {
      dispatchMailboxCollection();
    }
  }, [mailboxCollection]);

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  const headerText = messageUids?.length > 1 ? t('header') : t('headerSingle');
  const successToastMessage = messageUids?.length > 1 ? t('successToast') : t('successToastSingle');

  const isSaved = async (): Promise<boolean> => {
    trackEvent({ event: 'moveEmailModalMove' });
    let unseenMessagesCount;

    if (isInboxFolder(kiteSelectValue) || isInboxFolder(sourceMailboxId)) {
      unseenMessagesCount = getUnseenMessagesCount(messageCollection[sourceMailboxId || '']?.data, messageUids);
    }

    const result = await dispatch(
      moveMessagesThunk({
        moveMessagesHook,
        payload: {
          sourceMailboxId,
          destinationMailboxId: destinationMailboxId || kiteSelectValue,
          messageUids: messageUids,
          unseenMessagesCount,
        },
      })
    ).unwrap();

    if (result.success) {
      trackEvent({ event: 'moveEmailSuccess' });

      const { limit, total, data } = messageCollection[sourceMailboxId];
      const lastPage = currentPage * limit > total;

      if (result.messageUids.length === data?.length && Number(currentPage) > 1 && lastPage) {
        navToPreviousPage();
      }

      if (messageUids.includes(parseInt(currentMailId || ''))) {
        closeMessage(sourceMailboxId, queryParams);
      }

      dispatch(updateToastMessage({ message: successToastMessage, success: true }));
      dispatch(reloadMessageCollection(true));
      dispatch(updateIsOpen(false));

      return true;
    }

    trackEvent({ event: 'moveEmailFail', payload: { errorMessage: result.error?.message } });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'moveEmailCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    if (destinationMailboxId) {
      return true;
    }

    if (kiteSelectValue && kiteSelectValue.length > 1) {
      return true;
    }

    if (messageUids && messageUids.length > 1) {
      return true;
    }

    return false;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const createMoveList = (): JSX.Element | null => {
    if (!mailboxCollection) {
      return null;
    }

    const invalidFolders = [appConstants.SENT_MAIL_FOLDER_ID, appConstants.DRAFT_FOLDER_ID, sourceMailboxId];

    if (isSentMailFolder(sourceMailboxId)) {
      invalidFolders.push(spamFolderId);
    }
    const globalFolders = mailboxCollection?.filter(
      (mailbox) => isGlobalFolder(mailbox.id, spamFolderId) && !invalidFolders.includes(mailbox.id)
    );

    const customFolders = mailboxCollection?.filter((mailbox) => !isGlobalFolder(mailbox.id, spamFolderId));

    return (
      <>
        {globalFolders.map(({ id, name }) => (
          <option key={id} value={id}>
            {appConstants.GLOBAL_FOLDER_NAMES[name as keyof GlobalFoldersInterface]}
          </option>
        ))}
        {customFolders.map(({ id, name }) => (
          <option key={id} value={id}>
            {transformNestedGlobalFolderPath(name, spamFolderId)}
          </option>
        ))}
      </>
    );
  };

  const handleSelectValue = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setKiteSelectValue(e.target.value);
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t('confirmBtnText'),
  };

  return (
    <ModalContentWrapper>
      {mailboxLoading && (
        <KiteProgressIndicator className={styles.spinner} id={'loading'} useOverlay={true} size="md" />
      )}
      <h5>{headerText}</h5>
      <p>{t('description')}</p>
      {apiError && (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      )}
      <div className={modalStyle.selectOption}>
        <KiteSelect
          id={'destinationFolderListId'}
          label={t('selectLabel')}
          name={'destinationFolderList'}
          value={destinationMailboxId || kiteSelectValue}
          onChange={handleSelectValue}
          placeholder={t('selectDefault')}
          className={'select'}
        >
          {sourceMailboxId === appConstants.TRASH_FOLDER_ID ? (
            <option key={appConstants.INBOX_MAIL_FOLDER_ID} value={appConstants.INBOX_MAIL_FOLDER_ID}>
              {t('inboxOption')}
            </option>
          ) : (
            createMoveList()
          )}
        </KiteSelect>
      </div>
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default MoveMessages;
