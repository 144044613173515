import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateLabelMutationFn, LabelEntity } from 'generated/graphql';

interface UpdateLabelResponse {
  success?: Partial<LabelEntity>;
  error?: ApolloError | undefined;
}

interface UpdateLabelArg {
  payload: {
    id: string;
    label: {
      name: string;
    };
  };
  updateLabelHook: UpdateLabelMutationFn;
}

export const updateLabelThunk = createAsyncThunk(
  'updateLabel',
  async (arg: UpdateLabelArg): Promise<UpdateLabelResponse> => {
    try {
      const response = await arg.updateLabelHook({ variables: arg.payload });
      return {
        success: response.data?.updateLabel,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
