import { KiteButton } from '@kite/react-kite';
import { useEffect, useState } from 'react';
import styles from 'components/save-cancel/styles/SaveCancel.module.scss';
import { useAppDispatch } from 'hooks';
import { updateIsOpen, updateModalName, updateModalProps } from 'slices/app';
import { useSharedTranslation } from 'translation/hooks';

interface SaveCancelPropsInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  draft: any;
  isChanged: () => boolean;
  isSaved: () => Promise<boolean>;
  isValidate: () => boolean;
  isCancelled: () => boolean;
  isJustSaved?: () => boolean;
  saveButtonText?: string;
  successButtonText?: string;
  isDelete?: boolean;
  cancelLabel?: string;
  shouldDisableCancel?: boolean;
}

const SaveCancel: React.FC<SaveCancelPropsInterface> = ({
  draft,
  isChanged,
  isSaved,
  isValidate,
  isCancelled,
  isJustSaved,
  saveButtonText,
  successButtonText,
  isDelete = false,
  cancelLabel,
  shouldDisableCancel = false,
}) => {
  const { t } = useSharedTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSavedState, setIsSavedState] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    isChanged() ? setIsButtonDisabled(false) : setIsButtonDisabled(true);

    isJustSaved && isJustSaved() ? setIsSavedState(true) : setIsSavedState(false);
  }, [draft]);

  const closeModal = (): void => {
    dispatch(updateIsOpen(false));
    dispatch(updateModalName(''));
    dispatch(updateModalProps({}));
  };

  const onCancel = (): void => {
    closeModal();
    isCancelled();

    setIsButtonDisabled(true);
    setIsLoading(false);
    setIsSavedState(false);
  };

  const onSave = async (): Promise<boolean> => {
    if (!isChanged()) {
      return false;
    }

    setIsLoading(true);
    if (!isValidate()) {
      setIsLoading(false);
      return false;
    }

    if (await isSaved()) {
      setIsLoading(false);
      setIsSavedState(true);
      setIsButtonDisabled(true);
      closeModal();
      return true;
    }

    setIsSavedState(false);
    setIsLoading(false);
    return false;
  };

  const saveLabel = saveButtonText ?? t('save');
  const successLabel = successButtonText ?? t('changesSaved');
  const cancelText = cancelLabel ?? t('cancel');

  return (
    <div className={styles.buttonGroupContainer}>
      <KiteButton
        className={`${styles.saveButton} ${isDelete ? styles.delete : ''} ${isSavedState ? styles.success : ''} `}
        loading={isLoading}
        disabled={isButtonDisabled}
        icon={`${isSavedState ? 'ki-checkmark' : ''}`}
        onClick={onSave}
        aria-disabled={isButtonDisabled}
        aria-label={'save'}
      >
        {isSavedState ? successLabel : saveLabel}
      </KiteButton>
      <KiteButton
        className={styles.cancelButton}
        variant="secondary"
        disabled={shouldDisableCancel ? isButtonDisabled : isLoading}
        aria-disabled={shouldDisableCancel ? isButtonDisabled : isLoading}
        onClick={onCancel}
        aria-label={'cancel'}
      >
        {cancelText}
      </KiteButton>
    </div>
  );
};

export default SaveCancel;
