import { createSlice } from '@reduxjs/toolkit';
import { getSettingThunk } from 'thunks/setting/getSettingThunk';
import { updateTimeZoneThunk } from 'thunks/setting/updateTimeZoneThunk';
import { updateSettingThunk } from 'thunks/setting/updateSettingThunk';
import { updateReplyTo } from 'thunks/setting/replyToThunk';
import { getLdapSettingThunk } from 'thunks/setting/getLdapSettingThunk';
import { updateMailForwardThunk } from 'thunks/setting/updateMailForwardThunk';
import { updateDisplayNameThunk } from 'thunks/setting/updateDisplayNameThunk';
import { updateEmailPerPageThunk } from 'thunks/setting/updateEmailPerPageThunk';
import { ErrorsKeys } from 'translation/hooks';

interface SettingState {
  displayName: string;
  timeZone: string;
  emailPerPage: number;
  replyTo: string;
  preferredLanguage: string;
  isAutoSaveDraftEnabled: boolean;
  isAutoMailboxReloadEnabled: boolean;
  isDisplayImageEnabled: boolean;
  isDisplayEmailAsHtmlEnabled: boolean;
  isEmailPermanentlyDeleted: boolean;
  isSettingLoading: boolean;
  isSettingDownloaded: boolean;
  apiError: ErrorsKeys | null;
  mailForwardingToggleLoading: boolean;
  isMailForwardingEnabled: boolean;
  mailForwardingAddress: string[];
}

const initialState: SettingState = {
  displayName: '',
  timeZone: '',
  emailPerPage: 20,
  replyTo: '',
  preferredLanguage: 'english',
  isAutoSaveDraftEnabled: false,
  isAutoMailboxReloadEnabled: false,
  isDisplayImageEnabled: false,
  isDisplayEmailAsHtmlEnabled: false,
  isEmailPermanentlyDeleted: false,
  isSettingLoading: false,
  isSettingDownloaded: false,
  mailForwardingToggleLoading: false,
  isMailForwardingEnabled: false,
  mailForwardingAddress: [],
  apiError: null,
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    resetApiError: (state) => {
      state.apiError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSettingThunk.fulfilled, (state, action) => {
      state.isSettingLoading = false;
      if (action.payload.success) {
        state.displayName = action.payload.success.displayName || '';
        state.timeZone = action.payload.success.timeZone;
        state.emailPerPage = action.payload.success.emailPerPage;
        state.replyTo = action.payload.success.replyTo || '';
        state.preferredLanguage = action.payload.success.preferredLanguage;
        state.isAutoSaveDraftEnabled = action.payload.success.isAutoSaveDraftEnabled;
        state.isAutoMailboxReloadEnabled = action.payload.success.isAutoMailboxReloadEnabled;
        state.isDisplayImageEnabled = action.payload.success.isDisplayImageEnabled;
        state.isDisplayEmailAsHtmlEnabled = !action.payload.success.isDisplayEmailAsHtmlEnabled;
        state.isEmailPermanentlyDeleted = action.payload.success.isEmailPermanentlyDeleted;
        state.isSettingDownloaded = true;

        return;
      }

      if (action.payload.error) {
        state.apiError = 'unableToSaveErr';
        return;
      }
    });

    builder.addCase(updateEmailPerPageThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.emailPerPage = action.payload.success.emailPerPage;

        return;
      }
    });

    builder.addCase(updateDisplayNameThunk.fulfilled, (state, action) => {
      state.isSettingLoading = false;

      if (action.payload.error) {
        state.apiError = 'unableToSaveErr';
        return;
      }

      state.displayName = action.payload.success || '';
      return;
    });

    builder.addCase(updateTimeZoneThunk.fulfilled, (state, action) => {
      state.isSettingLoading = false;
      if (action.payload.success) {
        state.timeZone = action.payload.success.timeZone as string;
        return;
      }

      if (action.payload.error) {
        state.apiError = 'unableToSaveErr';
        return;
      }
    });

    builder.addCase(updateSettingThunk.fulfilled, (state, action) => {
      state.isSettingLoading = false;
      if (action.payload.success) {
        state.displayName = action.payload.success.displayName || '';
        state.timeZone = action.payload.success.timeZone;
        state.emailPerPage = action.payload.success.emailPerPage || 25;
        state.replyTo = action.payload.success.replyTo || '';
        state.preferredLanguage = action.payload.success.preferredLanguage;
        state.isAutoSaveDraftEnabled = action.payload.success.isAutoSaveDraftEnabled;
        state.isAutoMailboxReloadEnabled = action.payload.success.isAutoMailboxReloadEnabled;
        state.isDisplayImageEnabled = action.payload.success.isDisplayImageEnabled;
        state.isDisplayEmailAsHtmlEnabled = !action.payload.success.isDisplayEmailAsHtmlEnabled;
        state.isEmailPermanentlyDeleted = action.payload.success.isEmailPermanentlyDeleted;
        state.isSettingDownloaded = true;
      }
      if (action.payload.error) {
        state.apiError = 'unableToSaveErr';
        return;
      }
    });
    builder.addCase(updateReplyTo.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.replyTo = action.payload.success.replyTo as string;
        return;
      }

      if (action.payload.error) {
        state.apiError = 'unableToSaveErr';
        return;
      }
    });
    builder.addCase(getLdapSettingThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isMailForwardingEnabled = action.payload.success.mailUserPreferences.mailForwarding || false;
        state.mailForwardingAddress = action.payload.success.mailUserPreferences.mailForwardingAddress || [];
      }
    });
    builder.addCase(updateMailForwardThunk.pending, (state, _action) => {
      state.mailForwardingToggleLoading = true;
    });

    builder.addCase(updateMailForwardThunk.fulfilled, (state, action) => {
      state.mailForwardingToggleLoading = false;
      if (action.payload.success) {
        state.apiError = null;
        state.isMailForwardingEnabled = false;
        state.mailForwardingAddress = [];
      }

      if (action.payload.error) {
        state.apiError = 'unableToSaveErr';
      }
    });
  },
});

export const { resetApiError } = settingSlice.actions;

export default settingSlice.reducer;
