import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert, KiteCheckbox } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import {
  ContactWithLabelEntity,
  useGetLabelCollectionLazyQuery,
  usePatchContactLabelMutation,
} from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import styles from 'components/contact-modal/styles/AssignLabelToContact.module.scss';
import { labelCollectionThunk } from 'thunks/contact/labelCollectionThunk';
import { patchContactsLabelThunk } from 'thunks/contact/patchContactsLabelThunk';
import { removeContactFromCollection } from 'slices/contact';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { Scrollbar } from 'components/common/scrollbar';
import { ErrorsKeys, useDialogTranslation } from 'translation/hooks';

interface AssignLabelToContactPropsInterface {
  contactId: string;
  labelId: string;
}

const AssignLabelToContact: React.FC<AssignLabelToContactPropsInterface> = ({ contactId, labelId }) => {
  const { t } = useDialogTranslation('assignLabelToContactCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'contacts' });
  const apiError = useAppSelector((state) => state.contactSlice.apiError);
  const contact = useAppSelector((state) => state.contactSlice.contact);
  const labels = useAppSelector((state) => state.labelSlice.labelCollection);
  const focusOpener = useTarget('modal');

  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');

  const [patchContactLabelHook] = usePatchContactLabelMutation();
  const [getLabelCollectionHook] = useGetLabelCollectionLazyQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    }
  }, [apiError]);

  useEffect(() => {
    trackEvent({ event: 'assignLabelModalView' });
    if (!labels || labels.length === 0) {
      dispatch(
        labelCollectionThunk({
          getLabelCollectionHook,
        })
      );
    }

    if (contact && contact.labels && contact.labels.length > 0) {
      const preSelected = contact.labels.map((label) => {
        return label.id;
      });

      setSelectedLabels(preSelected);
    }
  }, []);

  const isSaved = async (): Promise<boolean> => {
    const result = await dispatch(
      patchContactsLabelThunk({
        patchContactLabelHook,
        payload: { id: contactId, labels: selectedLabels },
      })
    );

    // @ts-ignore
    const success: ContactWithLabelEntity = result.payload.success;
    if (success) {
      trackEvent({ event: 'assignLabelSuccess', payload: { totalSelected: selectedLabels.length } });
      dispatch(updateToastMessage({ message: t('successToast'), success: true }));

      if (labelId !== 'all') {
        const labelIds = success.labels.flatMap((label) => label.id);
        if (!labelIds.includes(labelId)) {
          dispatch(removeContactFromCollection(contactId));
          navigate(search ? `/user/label/${labelId}${search}` : `/user/label/${labelId}`);
        }
      }
      return true;
    }

    trackEvent({ event: 'assignLabelApiError' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'assignLabelModalCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    trackEvent({ event: 'assignLabelModalSave' });

    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
  };

  if (!labels || labels.length <= 0) {
    return <>Hey you need labels before you can assign any</>;
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.target.checked
      ? setSelectedLabels([...selectedLabels, e.target.id])
      : setSelectedLabels(selectedLabels.filter((val) => val !== e.target.id));
  };

  const labelList = (): JSX.Element[] => {
    return labels.map((label) => {
      return (
        <li key={label.id}>
          <KiteCheckbox
            checked={selectedLabels.includes(label.id)}
            id={label.id}
            label={label.name}
            name={'label'}
            onChange={handleOnChange}
            aria-label={label.name}
          />
        </li>
      );
    });
  };

  return (
    <div className={styles.assignLabelToContact}>
      <ModalContentWrapper>
        <h5>{t('header')}</h5>
        <p>{t('description')}</p>
        {apiError ? (
          <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
        ) : null}
        <Scrollbar customClass={styles.simpleBar} forceScroll={true}>
          <ul className={styles.labelsList}>{labelList()}</ul>
        </Scrollbar>
        <SaveCancel {...saveCancelProps} />
      </ModalContentWrapper>
    </div>
  );
};

export default AssignLabelToContact;
