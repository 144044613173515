import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateLabelMutationFn, LabelEntity } from 'generated/graphql';

interface CreateLabelResponse {
  success?: Partial<LabelEntity>;
  error?: ApolloError | undefined;
}

interface createLabelArg {
  payload: {
    name: string;
  };
  createLabelHook: CreateLabelMutationFn;
}

export const createLabelThunk = createAsyncThunk(
  'createLabel',
  async (arg: createLabelArg): Promise<CreateLabelResponse> => {
    try {
      const response = await arg.createLabelHook({ variables: arg.payload });
      return {
        success: response.data?.createLabel,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
