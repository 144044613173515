import { ApolloLink } from '@apollo/client';
import submitApiEvent from 'libs/tracking/adapters/helix/events/submitApiEvent';
import { OperationDefinitionNode } from 'graphql';
import { getServerEndpoint } from 'app-constants';
import { get } from 'lodash';

const loggerLink = (): ApolloLink =>
  new ApolloLink((operation, forward) => {
    const startTime = new Date().getTime();

    return forward(operation).map((result) => {
      const operationType = operation.query.definitions[0] as OperationDefinitionNode;
      const elapsed = new Date().getTime() - startTime;
      const traceId = get(operation.getContext().headers, 'X-WEBMAIL-TRACE-ID');

      const responseBodySize = new TextEncoder().encode(JSON.stringify(result.data)).length;

      if (result.errors?.length) {
        const errorMessages = result.errors.map((error) => get(error, 'message')).join('');

        submitApiEvent({
          event: 'apiFailure',
          page: 'app',
          apiPath: '/graphql',
          httpVerb: operationType.operation === 'query' ? 'GET' : 'POST',
          responseCode: '200',
          responseTime: elapsed,
          apiHost: getServerEndpoint(),
          traceId,
          operationName: operation.operationName,
          operationType: operationType.operation,
          gqlErrorCode: errorMessages,
          gqlQuery: operation.query.loc?.source.body,
          gqlError: errorMessages,
          responseSize: responseBodySize,
        });

        return result;
      }

      submitApiEvent({
        event: 'apiSuccess',
        page: 'app',
        apiPath: '/graphql',
        httpVerb: operationType.operation === 'query' ? 'GET' : 'POST',
        responseCode: '200',
        responseTime: elapsed,
        apiHost: getServerEndpoint(),
        traceId,
        operationName: operation.operationName,
        operationType: operationType.operation,
        gqlQuery: operation.query.loc?.source.body,
        responseSize: responseBodySize,
      });

      return result;
    });
  });

export default loggerLink;
