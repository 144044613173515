import { KiteCard } from '@kite/react-kite';
import styles from 'components/setting-auto-reply/styles/AutoReply.module.scss';
import { useUpdateAutoReplyMessageMutation, useUpdateAutoReplyToggleMutation } from 'generated/graphql';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { resetAutoReplyError } from 'slices/setting/autoReply';
import { updateAutoReplyMessageThunk, updateAutoReplyToggleThunk } from 'thunks/setting/autoReplyThunk';
import SettingSwitch from 'components/setting-switch';
import SaveCancel from 'components/save-cancel';
import { TrackingHook, useTracking } from 'react-tracking';
import { HelixSettingsPayloadInterface } from 'libs/tracking/adapters/helix/events/submitSettingsEvent';
import useNavBlockingDraft from 'hooks/useNavBlockingDraft';
import { ErrorsKeys, usePageTranslation } from 'translation/hooks';

const SettingAutoReply: React.FC = () => {
  const { t } = usePageTranslation('autoReplyCopy');
  const { trackEvent }: TrackingHook<HelixSettingsPayloadInterface> = useTracking({ page: 'settings' });

  const {
    autoReplyToggleLoading,
    autoReplyError,
    autoReplyEntity: { autoReplyMode, autoReplyMessage },
  } = useAppSelector((state) => state.autoReplySlice);

  const [draft, setDraft] = useNavBlockingDraft(autoReplyMessage || '');
  const [hasError, setHasError] = useState(false);
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');

  const dispatch = useAppDispatch();
  const [updateAutoReplyMessageHook] = useUpdateAutoReplyMessageMutation();
  const [updateAutoReplyToggleHook] = useUpdateAutoReplyToggleMutation();

  useMemo(() => {
    trackEvent({ event: 'autoReplyPageViewInit' });
  }, []);

  useEffect(() => {
    trackEvent({ event: 'autoReplyPageView' });
  }, []);

  useEffect(() => {
    if (autoReplyMessage) {
      setDraft(autoReplyMessage);
    }
  }, [autoReplyMessage]);

  useEffect(() => {
    if (autoReplyError?.message) {
      setHasError(true);
      setAlertDescription('unableToSaveErr');
      trackEvent({ event: 'autoReplyError' });
    }
  }, [autoReplyError]);

  const isAutoReplyEnabled = autoReplyMode === 'vacation';

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setDraft(e.target.value);
    dispatch(resetAutoReplyError());
  };

  const handleSwitch = async (): Promise<void> => {
    const result = await dispatch(
      updateAutoReplyToggleThunk({
        payload: {
          isVacationModeEnabled: !isAutoReplyEnabled,
          autoReplyMessage: autoReplyMessage,
        },
        updateAutoReplyHook: updateAutoReplyToggleHook,
      })
    );
    // @ts-ignore
    if (result.payload.success) {
      setHasError(false);
      trackEvent({ event: 'autoReplyToggle', isToggled: !isAutoReplyEnabled });
    }
  };

  const isChanged = (): boolean => {
    if (!autoReplyMessage && draft === '') {
      return false;
    }

    if (!autoReplyMessage) {
      return true;
    }

    return autoReplyMessage !== draft;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'autoReplyCancel' });
    dispatch(resetAutoReplyError());
    setDraft(autoReplyMessage ? autoReplyMessage : '');
    return true;
  };

  const isSaved = async (): Promise<boolean> => {
    trackEvent({ event: 'autoReplySave' });
    const result = await dispatch(
      updateAutoReplyMessageThunk({
        payload: {
          isVacationModeEnabled: draft !== '' ? isAutoReplyEnabled : false,
          autoReplyMessage: draft !== '' ? draft : undefined,
        },
        updateAutoReplyHook: updateAutoReplyMessageHook,
      })
    );

    // @ts-ignore
    if (result.payload.success) {
      setHasError(false);
      setAlertDescription('');
      return true;
    }

    return false;
  };

  return (
    <div className={styles.autoReplyStatusTray}>
      <SettingSwitch
        disabled={!autoReplyMessage || autoReplyToggleLoading}
        aria-disabled={!autoReplyMessage || autoReplyToggleLoading}
        loading={autoReplyToggleLoading}
        description={t(isAutoReplyEnabled ? 'descriptionOn' : 'descriptionOff')}
        title={t('title')}
        selected={isAutoReplyEnabled}
        handleSwitch={handleSwitch}
        alert={hasError ? t(alertDescription) : ''}
        ariaLabel={`auto reply`}
        onClose={() => setHasError(false)}
      />

      <KiteCard className={styles.textAreaCard}>
        <label htmlFor="autoReplyInput" className={`${styles.label}`}>
          {t('label')}
        </label>
        <textarea id="autoReplyInput" aria-label={t('label')} value={draft} onChange={handleChange} />

        <SaveCancel
          draft={draft}
          isChanged={isChanged}
          isSaved={isSaved}
          isValidate={() => true}
          isCancelled={isCancelled}
          shouldDisableCancel={true}
        />
      </KiteCard>
    </div>
  );
};

export default SettingAutoReply;
