import { isDraftFolder, isInboxFolder, isSentMailFolder, isSpamFolder, isTrashFolder } from 'utils/folderNameValidator';

const isMoveMessagesAllowed = (sourceMailboxId: string, destinationMailboxId: string): boolean => {
  if (destinationMailboxId === sourceMailboxId) {
    return false;
  }

  if (isTrashFolder(sourceMailboxId) && !isInboxFolder(destinationMailboxId)) {
    return false;
  }

  if (isInboxFolder(sourceMailboxId) || isSpamFolder(sourceMailboxId) || isTrashFolder(sourceMailboxId)) {
    if (isDraftFolder(destinationMailboxId) || isSentMailFolder(destinationMailboxId)) {
      return false;
    }
  }

  if (isDraftFolder(sourceMailboxId)) {
    if (!isTrashFolder(destinationMailboxId)) {
      return false;
    }
  }

  if (isSentMailFolder(sourceMailboxId) && isSpamFolder(destinationMailboxId)) {
    return false;
  }

  if (isDraftFolder(destinationMailboxId)) {
    return false;
  }

  return true;
};

export default isMoveMessagesAllowed;
