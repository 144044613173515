import { ValidationKeys } from 'translation/hooks';

export type FilterValidatorTransKeys = {
  invalid?: ValidationKeys;
  required?: ValidationKeys;
  duplicate?: ValidationKeys;
  folderCharacterLimit?: ValidationKeys;
};

const typeTranslationKey = (translationKeys: FilterValidatorTransKeys): FilterValidatorTransKeys => translationKeys;

export const moveFolderTrans = typeTranslationKey({ required: 'moveFolderReq' });
export const filterActionTrans = typeTranslationKey({ required: 'required' });
export const newFolderTrans = typeTranslationKey({
  invalid: 'invalidCharacters',
  required: 'newFolderReq',
  duplicate: 'folderDuplicate',
  folderCharacterLimit: 'folderCharacterLimit',
});
export const nameTrans = typeTranslationKey({ required: 'invalidFilterRuleName' });
export const emailAddressTrans = typeTranslationKey({ required: 'required' });
