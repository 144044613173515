import { SkipLinkI } from 'appConstants';
import styles from 'components/skip-links/styles/SkipLinks.module.scss';
import { usePageTranslation } from 'translation/hooks';

const SkipLink: React.FC<SkipLinkI> = ({ href, section }) => {
  const { t } = usePageTranslation('topNavigationCopy');
  return <a className={styles.SkipLink} href={href}>{`${t('skipLinkPrefix')} ${t(section)}`}</a>;
};

export default SkipLink;
