import { appConstants } from 'appConstants';
import { MessageEntity, MessageListEntity } from 'generated/graphql';

export const isMessageFlagged = (message: MessageListEntity | MessageEntity): boolean =>
  message.flags?.includes(appConstants.MESSAGE_FLAG_FLAGGED) || false;

export const isMessageUnflagged = (message: MessageListEntity | MessageEntity): boolean | undefined =>
  !message.flags?.includes(appConstants.MESSAGE_FLAG_FLAGGED) || false;

export const isMessageRead = (message: MessageListEntity | MessageEntity): boolean | undefined =>
  message.flags?.includes(appConstants.MESSAGE_FLAG_READ) || false;

export const isMessageUnread = (message: MessageListEntity | MessageEntity): boolean | undefined =>
  !message.flags?.includes(appConstants.MESSAGE_FLAG_READ) || false;

export const hasEveryFlagFromSelectedMessages = (
  selectedMessages: string[],
  messageCollection: MessageListEntity[],
  isFlagged: (message: MessageListEntity | MessageEntity) => boolean | undefined
): boolean =>
  messageCollection
    ?.filter((message) => selectedMessages.map((uid) => Number(uid)).includes(message.uid))
    .every((message) => isFlagged(message));
