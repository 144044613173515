import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import { TrackingHook, useTracking } from 'react-tracking';
import { UpdateBlockedSenderResponse, updateBlockSenderThunk } from 'thunks/setting/updateBlockedSenderThunk';
import { useUpdateBlockedSenderMutation } from 'generated/graphql';
import { appConstants } from 'appConstants';
import { uniq } from 'lodash';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { useDialogTranslation } from 'translation/hooks';
import { isValidEmail } from 'utils/composeMailFormHelper';

interface BlockSendersInterface {
  isBlockSendersBulk: boolean;
  currentFolder: string;
}

const BlockSenders: React.FC<BlockSendersInterface> = ({ isBlockSendersBulk, currentFolder }) => {
  const { t } = useDialogTranslation('blockSendersCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const focusOpener = useTarget('modal');
  const apiError = useAppSelector((state) => state.messageSlice.apiError);
  const message = useAppSelector((state) => state.messageSlice.message);
  const blockSenderList = useAppSelector((state) => state.blockSenderSlice.senderList);
  const messageCollection = useAppSelector((state) => state.messageCollectionSlice.messageCollection);
  const selectedMessages = useAppSelector((state) => state.messageCollectionSlice.selectedMessages) || [];
  const [maxCountError, setMaxCountError] = useState(false);

  const dispatch = useAppDispatch();
  const [updateBlockedSenderHook] = useUpdateBlockedSenderMutation();

  useEffect(() => {
    trackEvent({ event: 'blockSenderModalView' });
  }, []);

  const uniqueAddresses = uniq(
    messageCollection[currentFolder]?.data
      .filter((email) => selectedMessages.includes(String(email.uid)))
      .map((message) => message.from && message.from[0].address)
  );

  const headerText = uniqueAddresses.length > 1 ? t('headerPlural') : t('header');
  const descriptionText =
    uniqueAddresses.length > 1
      ? t('descriptionA', { addressCount: uniqueAddresses.length })
      : t('descriptionASingle', {
          address: uniqueAddresses[0] || (message?.from && message?.from[0].address) || '',
        });
  const confirmText = uniqueAddresses.length > 1 ? t('confirmBtnText') : t('confirmBtnTextSingle');
  const confirmOrTryAgain = !apiError ? confirmText : t('tryAgain');

  const isSaved = async (): Promise<boolean> => {
    !apiError ? trackEvent({ event: 'blockSenderSave' }) : trackEvent({ event: 'blockSenderTryAgain' });

    const blockedEmails: string[] = [];

    if (isBlockSendersBulk) {
      selectedMessages.forEach((uid: string) => {
        messageCollection[currentFolder]?.data.forEach((message) => {
          const email = message.from ? message.from[0].address : '';
          if (email && message.uid === parseInt(uid)) {
            blockedEmails.push(email);
          }
        });
      });
    } else if (message?.from && message.from[0].address && !blockSenderList.includes(message.from[0].address)) {
      blockedEmails.push(message.from[0].address);
    }

    const validBlockedEmails: string[] = [];
    blockedEmails.forEach((email) => {
      if (email && isValidEmail(email)) {
        validBlockedEmails.push(email);
      }
    });

    const uniqueEmails = uniq(validBlockedEmails);

    if (blockSenderList.concat(uniqueEmails).length > appConstants.APPROVE_BLOCK_SENDER_LIST_MAX_COUNT) {
      setMaxCountError(true);
      return false;
    }

    const result = (await dispatch(
      updateBlockSenderThunk({
        updateBlockedSenderHook,
        payload: {
          blockedSender: { added: { emails: [...uniqueEmails], domains: [] }, removed: { emails: [], domains: [] } },
        },
        fromInbox: true,
      })
    )) as { payload: UpdateBlockedSenderResponse };

    if (result.payload.success) {
      const successToastMessage = uniqueAddresses.length > 1 ? t('successToast') : t('successToastSingle');
      trackEvent({ event: 'blockSenderSuccess' });
      dispatch(updateToastMessage({ message: successToastMessage, success: true }));
      return true;
    }

    trackEvent({ event: 'blockSenderFailure' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'blockSenderCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: confirmOrTryAgain,
  };

  return (
    <ModalContentWrapper>
      <h5>{headerText}</h5>
      <p>{descriptionText}</p>
      <p>{t('descriptionB')}</p>
      {apiError || maxCountError ? (
        <KiteAlert
          className={modalStyle.alert}
          type="error"
          description={apiError ? t('unableToSaveErr') : t('maxBlockedListCountError')}
          level="page"
        />
      ) : null}
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default BlockSenders;
