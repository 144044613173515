import { Dispatch, SetStateAction, useState } from 'react';

export interface UseModalInterface {
  toggle: () => void;
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
}

const useModal = (): UseModalInterface => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const toggle = (): void => {
    setIsShown(!isShown);
  };

  return {
    toggle,
    isShown,
    setIsShown,
  };
};

export default useModal;
