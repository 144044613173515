import React from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import mailLayoutStyles from 'containers/styles/MailLayoutContainer.module.scss';
import FolderListContainer from 'containers/FolderListContainer';
import ListViewContainer from 'containers/ListViewContainer';
import EmailView from 'components/email-view';
import Compose from 'components/compose';
import MessageDragDropLayer from 'components/common/MessageDragDropLayer';
import { Allotment } from 'allotment';
import { useAppSelector } from 'hooks';
import MessageListOptions from 'components/message-options/MessageListOptions';
import EmailHeader from 'components/header/sub-headers/EmailHeader';
import EmailOptions from 'components/message-options/EmailOptions';
import getMinMaxSizes from 'utils/getMinMaxSizes';
import { appConstants } from 'appConstants';

const DesktopLayout: React.FC = () => {
  const { folder, '*': mailId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isReadingPaneExpanded = query.get('expand') && mailId;
  const selectedMessages = useAppSelector((state) => state.messageCollectionSlice.selectedMessages) || [];
  const message = useAppSelector((state) => state.messageSlice.message);
  const isComposeMaximized = useAppSelector((state) => state.composeSlice.isMaximized);
  const isThreePaneEnabled = !!mailId;

  return (
    <>
      <div className={mailLayoutStyles.mailLayoutContainer}>
        <MessageDragDropLayer />
        <div className={mailLayoutStyles.mailContentLayout}>
          <Allotment
            proportionalLayout={true}
            separator={false}
            defaultSizes={appConstants.ui.DEFAULT_OUTER_PANE_SIZES}
          >
            <Allotment.Pane {...getMinMaxSizes(isThreePaneEnabled)[0]}>
              <FolderListContainer />
            </Allotment.Pane>
            <Allotment.Pane className={mailLayoutStyles.fullHeight}>
              {isComposeMaximized ? (
                <Compose />
              ) : (
                <>
                  <EmailHeader />
                  <div className={mailLayoutStyles.options}>
                    {!isReadingPaneExpanded ? (
                      <>
                        <MessageListOptions />
                        {mailId && message && !selectedMessages.length && <EmailOptions />}
                      </>
                    ) : (
                      <>{mailId && message && folder && <EmailOptions />}</>
                    )}
                  </div>
                  <Allotment
                    proportionalLayout={true}
                    separator={false}
                    defaultSizes={appConstants.ui.DEFAULT_INNER_PANE_SIZES}
                  >
                    <Allotment.Pane {...getMinMaxSizes(isThreePaneEnabled)[1]} visible={!isReadingPaneExpanded}>
                      <ListViewContainer />
                    </Allotment.Pane>
                    <Allotment.Pane visible={isThreePaneEnabled} {...getMinMaxSizes(isThreePaneEnabled)[2]}>
                      <Routes>
                        <Route path={'/:mailId'} element={<EmailView />} />
                      </Routes>
                    </Allotment.Pane>
                  </Allotment>
                </>
              )}
            </Allotment.Pane>
          </Allotment>
        </div>
        {!isComposeMaximized && <Compose />}
      </div>
    </>
  );
};

export default DesktopLayout;
