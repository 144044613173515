import { SenderItemInterface } from 'slices/setting/approveSender';
import { appConstants } from 'appConstants';

export const getApprovedBlockedSendersRemaining = (list?: SenderItemInterface): number => {
  if (list) {
    const approved = Object.keys(list);
    return (
      appConstants.APPROVE_BLOCK_SENDER_LIST_MAX_COUNT - approved.filter((sender) => !list[sender].isDeleted).length
    );
  }
  return appConstants.APPROVE_BLOCK_SENDER_LIST_MAX_COUNT;
};
