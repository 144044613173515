import isValidDomain from 'is-valid-domain';
import { SenderItemInterface } from 'slices/setting/approveSender';
import isEmail from 'validator/lib/isEmail';

export const isValidDomainOrEmail = (userInput: string): boolean => {
  if (userInput.length === 0) {
    return false;
  }

  if (isEmail(userInput)) {
    return true;
  }

  return isValidDomain(userInput);
};

export const isDuplicate = (key: string, senderItem: SenderItemInterface): boolean => {
  return !!senderItem[key.toLowerCase()]?.value;
};

export const checkSenderConflict = (value: string, senders: string[]): boolean => senders.includes(value);
