import { useContext, useEffect } from 'react';
import 'libs/globals';
import 'components/app/App.scss';
import RouterSwitch from 'components/router-switch';
import { useAppSelector } from 'hooks';
import Toast from 'components/common/Toast';
import RootModal from 'components/common/root-modal';
import Drawer from 'components/common/drawer';
import useMobileSize from 'components/common/hooks/useMobileSize';
import useDocumentTitle from 'components/common/hooks/useDocumentTitle';
import { getLoggedInEmail, getLoggedInNamespace } from 'utils/accessTokenLocalStorage';
import FocusProvider from 'components/common/focus';
import { AuthContext } from 'providers/AuthProvider';
import { KiteProgressIndicator } from '@kite/react-kite';
import SkipLinks from 'components/skip-links';
import LoginError from 'components/login-error/LoginError';

const App: React.FC = () => {
  const { isLoggedIn, isLoginLoading, loginFailed } = useContext(AuthContext);

  const shouldLockScrolling = useAppSelector((state) => state.appSlice.isDrawerOpen || state.appSlice.isOpen);
  const isComposeOpen = useAppSelector(
    (state) => state.composeSlice.activeTabIndex && !state.composeSlice.activeMinimizedIndex
  );
  const isMobile = useMobileSize();
  const shouldMobileLockScrolling = isMobile && isComposeOpen;

  const setDocumentTitle = useDocumentTitle();

  useEffect(() => {
    if (!isLoggedIn) {
      setDocumentTitle('Spectrum: Webmail');
    } else {
      setDocumentTitle(`${getLoggedInEmail()} - Webmail | ${getLoggedInNamespace()}`);
    }
  }, [isLoggedIn]);

  if (loginFailed) {
    return <LoginError />;
  }

  if (isLoginLoading) {
    return <KiteProgressIndicator id={'app'} />;
  }

  return (
    <div className={`app ${shouldLockScrolling || shouldMobileLockScrolling ? 'no-scroll' : ''}`}>
      <SkipLinks />
      <FocusProvider>
        <RouterSwitch>
          <Toast />
          <RootModal />
          <Drawer />
        </RouterSwitch>
      </FocusProvider>
    </div>
  );
};

export default App;
