import { MutableRefObject, createContext, useContext, useEffect } from 'react';

export type TargetRefT<T extends HTMLElement = HTMLElement> = { clear: () => void; target: React.RefObject<T> };
type FocusWTarget = () => void;
type FocusWOTarget = (targetKey: keyof Targets) => void;
type FocusTarget = FocusWTarget | FocusWOTarget;

export type AssignUniqueReturn<T> = {
  assign: (node: T) => void;
  clear: () => void;
};

export interface FocusPreviousContextI {
  focus: FocusWOTarget;
  createTargetRef: <T extends HTMLElement = HTMLElement>(targetKey: keyof Targets) => TargetRefT<T>;
  assignUnique: <T extends HTMLElement = HTMLElement>(target: UniqueTargets) => AssignUniqueReturn<T>;
}

export interface Targets {
  [key: string]: React.RefObject<HTMLElement> | MutableRefObject<HTMLElement | undefined>;
  modal: MutableRefObject<HTMLElement | null>;
}

export type UniqueTargets = keyof Pick<Targets, 'modal'>;

const FocusContext = createContext<FocusPreviousContextI>({
  focus: function (targetKey: keyof Targets): void {
    throw new Error(`Function not implemented. ${targetKey} was not focused`);
  },
  createTargetRef: function <T extends HTMLElement = HTMLElement>(targetKey: string | number): TargetRefT<T> {
    throw new Error(`Function not implemented. ${targetKey} was not added`);
  },
  assignUnique: function <T extends HTMLElement = HTMLElement>(target: 'modal'): AssignUniqueReturn<T> {
    throw new Error(`Function not implemented. Item was not added for ${target}`);
  },
});

export const createTargetRef = <T extends HTMLElement>(targetKey: keyof Targets): TargetRefT<T>['target'] => {
  const { createTargetRef } = useContext(FocusContext);

  const { clear, target } = createTargetRef<T>(targetKey);

  useEffect(() => {
    return clear;
  }, []);

  return target;
};

export const updateModalOpener = <T extends HTMLElement>(): ((node: T) => void) => {
  const { assignUnique } = useContext(FocusContext);

  const { assign, clear } = assignUnique('modal');

  useEffect(() => {
    return clear;
  }, []);

  return (node): void => {
    assign(node);
  };
};

export function useTarget(targetKey: keyof Targets): FocusWTarget;
export function useTarget(): FocusWOTarget;
export function useTarget(targetKey?: keyof Targets): FocusTarget {
  const { focus } = useContext(FocusContext);

  const focusTarget = (targetKey: keyof Targets): void => {
    focus(targetKey);
  };

  if (targetKey) {
    return (() => focus(targetKey)) as FocusWTarget;
  }

  return focusTarget as FocusWOTarget;
}

export default FocusContext;
