import { appConstants } from 'appConstants';

const autoSaveDraftTimerHelper = (): number => {
  const dateTime = new Date();
  dateTime.setSeconds(dateTime.getSeconds() + appConstants.app.AUTO_SAVE_DRAFT_INTERVAL);

  return dateTime.getTime();
};

export default autoSaveDraftTimerHelper;
