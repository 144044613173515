import React from 'react';
import ContactSection from 'components/contact-form/sections/ContactSection';
import { KiteIcon, KiteTextInput } from '@kite/react-kite';
import { ContactSectionProps } from 'components/contact-form/initialState';
import { ContactFormInterface } from 'components/contact-form/initialState';
import styles from 'components/contact-form/styles/ContactCreateContact.module.scss';
import validateContactField, { getContactValidationErrorMessage } from 'utils/validateContactField';
import { KiteInputElems } from 'libs/kite/types';
import { displayNameTrans, nickNameTrans } from 'components/contact-form/sections/translationKeys';
import { usePageTranslation } from 'translation/hooks';

type AltNameFormKey = keyof Pick<ContactFormInterface, 'nickName' | 'displayName'>;

type HandleAltNameChange = (formKey: AltNameFormKey, e: React.ChangeEvent<KiteInputElems>) => void;

export const AltName: React.FC<ContactSectionProps<Pick<ContactFormInterface, 'displayName' | 'nickName'>>> = ({
  updateFormValues,
  values,
  errors,
  setErrors,
}) => {
  const { t } = usePageTranslation('contactFormCopy');

  const displayNameErrorMessage = getContactValidationErrorMessage(errors, 'displayName', displayNameTrans);
  const nickNameErrorMessage = getContactValidationErrorMessage(errors, 'nickName', nickNameTrans);

  const validateField = (formKey: AltNameFormKey, value: string): void => {
    validateContactField(formKey, { [formKey]: value }, errors, setErrors);
  };

  const handleOnChange: HandleAltNameChange = (formKey, e) => {
    updateFormValues(e.target.value, formKey);
    validateField(formKey, e.target.value);
  };

  return (
    <ContactSection>
      <KiteIcon className={styles.fieldIcon} icon="ki-info-circle" />
      <div className={styles.fieldContainer}>
        <KiteTextInput
          className={styles.halfField}
          placeholder={t('displayNamePlaceholder')}
          label={t('displayName')}
          onChange={(e) => handleOnChange('displayName', e)}
          onBlur={(e) => validateField('displayName', e.target.value)}
          value={values.displayName || ''}
          errorMessage={t(displayNameErrorMessage)}
          ariaLive="polite"
        />
        <KiteTextInput
          className={styles.halfField}
          label={t('nickname')}
          onChange={(e) => handleOnChange('nickName', e)}
          value={values.nickName || ''}
          errorMessage={t(nickNameErrorMessage)}
          ariaLive="polite"
        />
      </div>
    </ContactSection>
  );
};
