import { memo, useEffect, useState } from 'react';
import styles from 'components/compose/styles/Compose.module.scss';

import ComposeForm from 'components/compose-form';
import ComposeTab from 'components/compose-chevron';
import { useAppSelector } from 'hooks';
import { size } from 'lodash';

const Compose: React.FC = () => {
  const composeCollection = useAppSelector((state) => state.composeSlice.composeCollection);
  const isMaximized = useAppSelector((state) => state.composeSlice.isMaximized);
  const hasActiveChangedTabs = Object.values(composeCollection).some((tab) => tab.isChanged);

  const [abortController, setAbortController] = useState<AbortController>(new AbortController());

  useEffect(() => {
    const unloadFunc = (e: BeforeUnloadEvent): string | null => {
      if (hasActiveChangedTabs && e) {
        e.preventDefault();
        return (e.returnValue = '');
      }

      return null;
    };

    abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    window.addEventListener('beforeunload', unloadFunc, { signal: newAbortController.signal });
  }, [hasActiveChangedTabs]);

  if (size(composeCollection) < 1) {
    return null;
  }

  return (
    <div className={`${styles.composeMessageContainer} ${isMaximized ? styles.maximized : ''}`}>
      {!isMaximized && <ComposeTab />}
      <ComposeForm />
    </div>
  );
};

export default memo(Compose);
