import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PatchReplyToMutationFn, SettingEntity } from 'generated/graphql';

export interface UpdateReplyToInterface {
  payload: {
    value: string;
  };
  updateReplyToHook: PatchReplyToMutationFn;
}

export interface UpdateReplyToResponse {
  success?: SettingEntity;
  error?: ApolloError;
}

export const updateReplyTo = createAsyncThunk(
  'updateReplyTo',
  async (arg: UpdateReplyToInterface): Promise<UpdateReplyToResponse> => {
    try {
      const response = await arg.updateReplyToHook({
        variables: arg.payload,
      });
      return {
        success: response.data?.patchReplyTo,
      } as UpdateReplyToResponse;
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
