import React from 'react';
import { KiteRadio, KiteRadioButton } from '@kite/react-kite';
import { FilterFormInterface, FilterSectionProps } from 'components/setting-filter-form/initialState';
import styles from 'components/setting-filter-form/styles/SettingFilterForm.module.scss';
import { usePageTranslation } from 'translation/hooks';

export const Clause: React.FC<FilterSectionProps<Pick<FilterFormInterface, 'clause'>>> = ({
  updateFormValues,
  values,
}) => {
  const { t } = usePageTranslation('filterRuleFormCopy');

  return (
    <KiteRadio
      label={`${t('matchingTitle')}:`}
      onChange={(e) => updateFormValues(e.target.value, 'clause')}
      className={styles.fieldContainerWithRadio}
    >
      <KiteRadioButton
        id={'filterClause-all'}
        label={`${t('matchingAllLabel')}`}
        name={'all'}
        value={'all'}
        checked={values.clause === 'all'}
      />
      <p className={styles.radioButtonDetails}>{t('matchingAllDescription')}</p>
      <KiteRadioButton
        id={'filterClause-any'}
        label={`${t('matchingAnyLabel')}`}
        name={'any'}
        value={'any'}
        checked={values.clause === 'any'}
      />
      <p className={styles.radioButtonDetails}>{t('matchingAnyDescription')}</p>
    </KiteRadio>
  );
};
