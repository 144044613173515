import { FetchAccessToken, HandleError, TokenRefreshLink } from 'apollo-link-token-refresh';
import { getServerEndpoint } from 'app-constants';
import { appConstants } from 'appConstants';
import {
  getAccessToken,
  getExpiresIn,
  setAccessToken,
  getRefreshToken,
  setExpiresIn,
  setRefreshToken,
} from 'utils/accessTokenLocalStorage';
import { trackCall, trackRefreshTokenLogin } from 'utils/apiTracking';
import buildVisitAndTraceIdHeaders from 'utils/buildVisitAndTraceIdHeaders';
import { isLoggedInLocalStorage } from 'utils/loginLocalStorage';
import logoutUtil from 'utils/logoutUtil';

type TokenPaylaod = {
  refreshToken: string;
  accessToken: string;
  expiresIn: number;
  authReportingId?: string;
  accountNumber?: string;
  accountGuid?: string;
  footprint?: string;
};

const isTokenValidOrUndefined = (): boolean => {
  if (!getRefreshToken()) {
    return false;
  }
  if (!isLoggedInLocalStorage()) {
    return true;
  }
  const token = getAccessToken();
  if (!token) {
    return true;
  }

  return Date.now() < getExpiresIn();
};

const fetchAccessToken: FetchAccessToken = (): Promise<Response> => {
  const startTime = new Date().getTime();
  const visitAndTraceId = buildVisitAndTraceIdHeaders();

  return fetch(`${getServerEndpoint()}${appConstants.LOGIN_URL_PATH}`, {
    method: 'POST',
    body: JSON.stringify({
      code: getRefreshToken(),
      grantType: 'refresh_token',
    }),
    headers: {
      ...visitAndTraceId,
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then((response) => {
    const responseBodySize = new TextEncoder().encode(JSON.stringify(response.body)).length;
    trackCall(
      Object.assign(response, { method: 'post' }),
      startTime,
      visitAndTraceId['X-WEBMAIL-TRACE-ID'],
      responseBodySize
    );
    return response;
  });
};

const handleFetch = (accessToken: string): void => {
  setAccessToken(accessToken);
};

const handleError: HandleError = () => {
  trackRefreshTokenLogin(false);
  logoutUtil();
};

const handleResponse = () => (body: Response) => {
  return body.json().then((json: TokenPaylaod) => {
    if (json.expiresIn && json.refreshToken && json.accessToken) {
      setExpiresIn(json.expiresIn);
      setRefreshToken(json.refreshToken);
      setAccessToken(json.accessToken);
      trackRefreshTokenLogin(true, {
        authReportingId: json.authReportingId,
        accountGuid: json.accountGuid,
        accountNumber: json.accountNumber,
        footprint: json.footprint,
      });
    }
    return json;
  });
};

const refreshLink = (): TokenRefreshLink<string> =>
  new TokenRefreshLink({
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined,
    fetchAccessToken,
    handleResponse,
    handleFetch,
    handleError,
  });

export default refreshLink;
