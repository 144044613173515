import { createAsyncThunk } from '@reduxjs/toolkit';
import { getServerEndpoint } from 'app-constants';
import axios, { AxiosError } from 'axios';
import buildAccessTokenHeader from 'utils/buildAccessTokenHeader';

import { trackCall } from 'utils/apiTracking';
import buildVisitAndTraceIdHeaders from 'utils/buildVisitAndTraceIdHeaders';

interface uploadAttachmentThunkArgs {
  payload: {
    folderId: string;
    messageUid: number;
  };
}

interface uploadAttachmentThunkResponse {
  success?: {
    data: string;
  };
  error?: AxiosError;
}

export const rawMessageStreamThunk = createAsyncThunk(
  'rawMessageStreamThunk',
  async (arg: uploadAttachmentThunkArgs): Promise<uploadAttachmentThunkResponse> => {
    const startTime = new Date().getTime();

    const visitAndTraceId = buildVisitAndTraceIdHeaders();
    try {
      const result = await axios({
        method: 'get',
        url: `${getServerEndpoint()}/api/v1/imap/${arg.payload.folderId}/messages/${arg.payload.messageUid}/raw`,
        headers: {
          ...buildAccessTokenHeader(),
          ...visitAndTraceId,
        },
      });

      const responseBodySize = new TextEncoder().encode(JSON.stringify(result.data)).length;
      trackCall(result, startTime, visitAndTraceId['X-WEBMAIL-TRACE-ID'], responseBodySize);

      return {
        success: { data: result.data },
      };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorResponseSize = new TextEncoder().encode(JSON.stringify(err.response?.data)).length;
        trackCall(err as AxiosError, startTime, visitAndTraceId['X-WEBMAIL-TRACE-ID'], errorResponseSize);
      }

      return {
        error: err as AxiosError,
      };
    }
  }
);
