import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MoveMessagesMutationFn } from 'generated/graphql';

interface MoveMessagesResponse {
  success?: boolean;
  error?: ApolloError | undefined;
  messageUids: number[];
  sourceMailboxId?: string;
  destinationMailboxId?: string;
  unseenMessagesCount?: number;
}

interface MoveMessagesArg {
  payload: {
    sourceMailboxId: string;
    destinationMailboxId: string;
    messageUids: number[];
    unseenMessagesCount?: number;
  };
  moveMessagesHook: MoveMessagesMutationFn;
}

export const moveMessagesThunk = createAsyncThunk(
  'moveMessages',
  async (arg: MoveMessagesArg): Promise<MoveMessagesResponse> => {
    try {
      const response = await arg.moveMessagesHook({
        variables: {
          sourceMailboxId: arg.payload.sourceMailboxId,
          destinationMailboxId: arg.payload.destinationMailboxId,
          messageUids: arg.payload.messageUids,
        },
      });
      return {
        success: response?.data?.moveMessages.success || false,
        messageUids: arg.payload.messageUids,
        sourceMailboxId: arg.payload.sourceMailboxId,
        destinationMailboxId: arg.payload.destinationMailboxId,
        unseenMessagesCount: arg.payload.unseenMessagesCount,
      };
    } catch (e) {
      return {
        messageUids: arg.payload.messageUids,
        error: e as ApolloError,
      };
    }
  }
);
