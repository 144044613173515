import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import styles from 'components/contact-form/styles/ContactCreateContact.module.scss';
import { ContactFormInterface, initialState } from 'components/contact-form/initialState';
import { Address, AltName, DOB, Email, Employment, Name, Notes, Phone, URL } from 'components/contact-form/sections';
import { KiteAlert, KiteButton, KiteCard, KiteIcon } from '@kite/react-kite';
import { isEqual } from 'lodash';
import { useCreateContactMutation } from 'generated/graphql';
import { useAppDispatch } from 'hooks';
import { createContactThunk } from 'thunks/contact/createContactThunk';
import { updateNavBlocking, updateToastMessage } from 'slices/app';
import { resetContactApiError } from 'slices/contact';
import useContactFieldValidatorError from 'hooks/useContactFieldValidatorError';
import transformContactForm from 'utils/transformContactForm';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isContactFormValid } from 'components/contact-form/helpers';
import { TrackingHook, useTracking } from 'react-tracking';
import useNavBlockingDraft from 'hooks/useNavBlockingDraft';
import { createTargetRef } from 'components/common/focus/FocusContext';
import { Scrollbar } from 'components/common/scrollbar';
import { ErrorsKeys, usePageTranslation } from 'translation/hooks';

export interface UseLocationState {
  state: {
    previousPage: string;
    search: Location;
  };
}

const ContactCreate: React.FC = () => {
  const { t } = usePageTranslation('contactFormCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'contacts' });
  const [formValues, setFormValues] = useNavBlockingDraft(initialState);
  const [showMore, setShowMore] = useState(false);
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const [errors, setErrors] = useContactFieldValidatorError({});
  const { state } = useLocation() as UseLocationState;
  const [navigationTo, setNavigationTo] = useState('');
  const firstChildRef = createTargetRef<HTMLInputElement>('contactCreate');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createContactHook] = useCreateContactMutation();

  useEffect(() => {
    trackEvent({ event: 'newContactPageViewComplete' });
  }, []);

  useEffect(() => {
    if (navigationTo) {
      navigate(`${navigationTo}${state.search}`);
    }
  }, [navigationTo]);

  useEffect(() => {
    setAlertDescription('');
  }, [formValues]);

  const updateFormValues = (
    value: ContactFormInterface[keyof ContactFormInterface],
    field: keyof ContactFormInterface
  ): void => {
    setFormValues({ ...formValues, [field]: value });
  };

  const isSaved = async (): Promise<boolean> => {
    trackEvent({ event: 'saveContact' });
    const result = await dispatch(
      createContactThunk({
        payload: {
          contact: transformContactForm(formValues),
        },
        createContactHook,
      })
    );

    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'saveContactSuccess' });
      dispatch(updateToastMessage({ message: t('createdSuccessToast'), success: true }));
      dispatch(resetContactApiError());
      dispatch(updateNavBlocking(false));
      if (state?.previousPage) {
        setNavigationTo(state?.previousPage);
      } else {
        setNavigationTo('user/label/all');
      }
      setAlertDescription('');
      return true;
    }

    trackEvent({ event: 'saveContactAPIError' });
    setAlertDescription('unableToSaveErr');
    return false;
  };

  const isValid = (): boolean => {
    const [isValid, contactFormErrors] = isContactFormValid(formValues);
    if (isValid) {
      return true;
    }

    setErrors(contactFormErrors);
    setAlertDescription('validationErr');
    trackEvent({ event: 'saveContactValidationError' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'saveContactCancel' });
    dispatch(updateNavBlocking(false));
    setNavigationTo(state.previousPage);
    return true;
  };

  return (
    <div className={styles.contactFormContainer}>
      <Link to={`${state?.previousPage}${state ? state.search : ''}`} className={styles.backButton}>
        <KiteIcon icon={'ki-chevron-left'} />
        {t('backBtn')}
      </Link>
      <div className={styles.contactForm}>
        <Scrollbar customClass={styles.scrollbar} forceScroll={false}>
          <KiteCard className={styles.newContact}>
            <h2 className="kite-type-style--title-6">{t('newHeader')}</h2>
            <p>{t('newDesc')}</p>
            {alertDescription && (
              <KiteAlert
                className={styles.alert}
                type="error"
                description={t(alertDescription)}
                level="page"
                autoFocus
                dismissible
                onClose={() => setAlertDescription('')}
              />
            )}
            <form className={styles.formFields}>
              <Name
                updateFormValues={updateFormValues}
                values={{ firstName: formValues.firstName, lastName: formValues.lastName }}
                errors={errors}
                setErrors={setErrors}
                ref={firstChildRef}
              />
              <AltName
                updateFormValues={updateFormValues}
                values={{ displayName: formValues.displayName, nickName: formValues.nickName }}
                errors={errors}
                setErrors={setErrors}
              />
              {showMore && (
                <Employment
                  updateFormValues={updateFormValues}
                  values={{ title: formValues.title, company: formValues.company }}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              <Email
                isNew={true}
                showMore={showMore}
                updateFormValues={updateFormValues}
                values={{ emails: formValues.emails }}
                errors={errors}
                setErrors={setErrors}
              />
              {showMore && (
                <>
                  <Phone
                    updateFormValues={updateFormValues}
                    values={{
                      homePhone: formValues.homePhone,
                      workPhone: formValues.workPhone,
                      mobilePhone: formValues.mobilePhone,
                    }}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <Address
                    updateFormValues={updateFormValues}
                    values={{
                      homeAddress: formValues.homeAddress,
                      workAddress: formValues.workAddress,
                    }}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <DOB
                    updateFormValues={updateFormValues}
                    values={{
                      dateOfBirth: formValues.dateOfBirth,
                    }}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <URL
                    updateFormValues={updateFormValues}
                    values={{
                      url: formValues.url,
                    }}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <Notes
                    updateFormValues={updateFormValues}
                    values={{
                      notes: formValues.notes,
                    }}
                    errors={errors}
                    setErrors={setErrors}
                  />
                </>
              )}
            </form>
            <div className={styles.buttonBar}>
              {!showMore && (
                <KiteButton
                  className={styles.showMoreButton}
                  size={'sm'}
                  variant="borderless"
                  icon={'ki-chevron-down'}
                  onClick={() => setShowMore(true)}
                >
                  {t('newShowMore')}
                </KiteButton>
              )}
              <SaveCancel
                draft={formValues}
                isChanged={() => !isEqual(formValues, initialState)}
                isSaved={isSaved}
                isValidate={isValid}
                isCancelled={isCancelled}
              />
            </div>
          </KiteCard>
        </Scrollbar>
      </div>
    </div>
  );
};

export default ContactCreate;
