import * as linksConfig from 'app-constants';
import styles from 'components/header/styles/SpectrumLinkGroup.module.scss';
import concatUrl from 'utils/concatUrl';
import { TrackingHook, useTracking } from 'react-tracking';
import { KiteLink } from '@kite/react-kite';
import { usePageTranslation } from 'translation/hooks';

interface propsInterface {
  customStyle?: { readonly [key: string]: string };
}

const SpectrumLinkGroup: React.FC<propsInterface> = ({ customStyle }) => {
  const { trackEvent }: TrackingHook = useTracking({ page: 'login', component: 'SpectrumLinkGroup' });
  const { t } = usePageTranslation('linksCopy');
  const { ACCOUNT_SUPPORT_BURGER, GET_SUPPORT, WATCH_TV_BURGER } = linksConfig;
  const links = [ACCOUNT_SUPPORT_BURGER, GET_SUPPORT, WATCH_TV_BURGER];

  return (
    <div className={styles.linkGroup}>
      {links.map((link, idx) => (
        <h3 className={`${styles.linkTitle} ${customStyle && customStyle.mainHamburgerLinkTitle}`} key={`link${idx}`}>
          <KiteLink
            className={styles.linkAnchor}
            href={concatUrl(link.baseUrl, link.href)}
            target="_blank"
            rel="noreferrer"
            onClick={() => trackEvent({ event: 'hamburgerLink', linkObject: { ...link, uiName: t(link.uiName) } })}
            aria-label={t(link.label || link.uiName)}
          >
            {t(link.uiName)}
          </KiteLink>
        </h3>
      ))}
    </div>
  );
};

export default SpectrumLinkGroup;
