export const moveMessagesCopy = {
  header: '',
  headerSingle: '',
  description: '',
  selectLabel: '',
  selectDefault: '',
  confirmBtnText: '',
  inboxOption: '',
  successToast: '',
  successToastSingle: '',
};
