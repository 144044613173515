import Helix from '@charter/helix/build/portals/index.min';
import { HelixPayloadInterface } from 'libs/tracking/adapters/helix/helixAdapter';

interface HelixApiPayloadInterface extends HelixPayloadInterface {
  responseTime: number;
  responseCode: string;
  httpVerb: string;
  apiPath: string;
  apiHost: string;
  traceId: string;
  operationName?: string;
  operationType?: string;
  gqlErrorCode?: string;
  gqlQuery?: string;
  gqlError?: string;
  responseSize?: number;
}

const submitApiEvent = (payload: HelixApiPayloadInterface): null => {
  const {
    event,
    apiPath,
    responseTime,
    httpVerb,
    responseCode,
    apiHost,
    traceId,
    operationName,
    operationType,
    gqlErrorCode,
    gqlQuery,
    gqlError,
    responseSize,
  } = payload;

  switch (event) {
    case 'apiSuccess': {
      Helix.track('WEBMAIL_apiCall_success', {
        appApiResponseCode: responseCode,
        appApiResponseTimeMs: responseTime,
        appApiHost: apiHost,
        appApiPath: apiPath,
        appApiHttpVerb: httpVerb,
        appApiTraceId: traceId,
        gqlOperationName: operationName,
        gqlOperationType: operationType,
        appApiQueryParameters: gqlQuery,
        appApiResponseSize: responseSize,
        clientServiceResult: 'success',
      });
      break;
    }
    case 'apiFailure': {
      Helix.track('WEBMAIL_apiCall_failure', {
        appApiResponseCode: 200,
        appApiResponseTimeMs: responseTime,
        appApiHost: apiHost,
        appApiPath: apiPath,
        appApiHttpVerb: httpVerb,
        appApiTraceId: traceId,
        gqlOperationName: operationName,
        gqlOperationType: operationType,
        gqlErrorCode: gqlErrorCode,
        appApiQueryParameters: gqlQuery,
        clientServiceResult: 'partialFailure',
        appApiResponseSize: responseSize,
        appApiResponseText: gqlError,
      });
      break;
    }
    case 'restApiSuccess': {
      Helix.track('WEBMAIL_rest_apiCall_success', {
        appApiResponseCode: responseCode,
        appApiResponseTimeMs: responseTime,
        appApiHost: apiHost,
        appApiPath: apiPath,
        appApiHttpVerb: httpVerb,
        appApiTraceId: traceId,
        appApiResponseSize: responseSize,
      });
      break;
    }
    case 'restApiFailure': {
      Helix.track('WEBMAIL_rest_apiCall_failure', {
        appApiResponseCode: responseCode,
        appApiResponseTimeMs: responseTime,
        appApiHost: apiHost,
        appApiPath: apiPath,
        appApiHttpVerb: httpVerb,
        appApiTraceId: traceId,
        appApiResponseSize: responseSize,
      });
      break;
    }

    default: {
      console.log('Event Case For API Not Found', event);
      return null;
    }
  }
  return null;
};

export default submitApiEvent;
