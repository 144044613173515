import { ApolloError } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageListEntity, Sort } from 'generated/graphql';
import { markMessagesReadThunk } from 'thunks/message-collection/markMessagesReadThunk';
import { markMessagesUnreadThunk } from 'thunks/message-collection/markMessageUnreadThunk';
import { getMessageCollectionThunk } from 'thunks/message-collection/messageCollectionThunk';
import { moveMessagesThunk } from 'thunks/message-collection/moveMessagesThunk';
import { getMessageThunk } from 'thunks/message/messageThunk';
import { saveDraftThunk } from 'thunks/compose/saveDraftThunk';
import { markMessagesFlagThunk } from 'thunks/message-collection/markMessageFlagThunk';
import { markMessagesUnflagThunk } from 'thunks/message-collection/markMessageUnflagThunk';
import { appConstants } from 'appConstants';
import { uniq } from 'lodash';
import { isMessageFlagged, isMessageUnread } from 'utils/messageFlagsUtils';
import { permanentDeleteMessageThunk } from 'thunks/message-collection/permanentDeleteMessageThunk';
import { permanentDeleteMessageBulkThunk } from 'thunks/message-collection/permanentDeleteMessageBulkThunk';
import { updateIsOpen } from 'slices/app';
import { sentMailThunk } from 'thunks/compose/sentMailThunk';
import { isDraftFolder, isGlobalFolder, isSentMailFolder } from 'utils/folderNameValidator';
import { deleteMailboxThunk } from 'thunks/mailbox/deleteMailboxThunk';
import { renameMailboxThunk } from 'thunks/mailbox/renameMailboxThunk';
import updateFolderNames from 'utils/updateFolderNames';

type MessageCollectionQueryPayload = {
  sort: Sort;
  limit: number;
  page: number;
  search?: string;
};

export type MessageCollectionType = {
  [key: string]: {
    total: number;
    currentPage: number;
    limit: number;
    data: MessageListEntity[];
  };
};

type TotalsType = {
  [key: string]: number;
};

interface MessageCollectionState {
  messageCollection: MessageCollectionType;
  selectedMessages?: string[];
  messageCollectionLoading: boolean;
  apiError?: Error | null;
  reloadMessageCollection: boolean;
  messageCollectionQuery: MessageCollectionQueryPayload;
  totals: TotalsType;
  getMailLoading: boolean;
  deleteButtonLoading: boolean;
  messageCollectionFetchError?: Error | null;
}

const initialState: MessageCollectionState = {
  messageCollection: {},
  messageCollectionLoading: true,
  reloadMessageCollection: false,
  getMailLoading: false,
  deleteButtonLoading: false,
  messageCollectionQuery: {
    sort: Sort.DateDesc,
    limit: 20,
    page: 1,
    search: '',
  },
  totals: {},
};

export const messageCollectionSlice = createSlice({
  name: 'messageCollection',
  initialState,
  reducers: {
    updateSelectedMessages: (state: MessageCollectionState, action: PayloadAction<string>) => {
      if (!state.selectedMessages?.length) {
        state.selectedMessages = [action.payload];
        return;
      }

      if (state.selectedMessages.includes(action.payload)) {
        state.selectedMessages = state.selectedMessages.filter((message) => message !== action.payload);
        return;
      }

      state.selectedMessages = [...state.selectedMessages, action.payload];
    },
    updateSelectAllMessages: (
      state: MessageCollectionState,
      action: PayloadAction<{ clearSelected: boolean; currentFolder: string }>
    ) => {
      if (action.payload.clearSelected) {
        state.selectedMessages = undefined;
        return;
      }
      state.selectedMessages = state?.messageCollection[action.payload.currentFolder].data.map((message) =>
        message.uid.toString()
      );
    },
    updateSelectMultipleMessages: (state: MessageCollectionState, action: PayloadAction<string[]>) => {
      if (!state.selectedMessages?.length) {
        state.selectedMessages = action.payload;
        return;
      }

      state.selectedMessages = uniq([...state.selectedMessages, ...action.payload]);
    },
    removeMessageFromCollection: (
      state: MessageCollectionState,
      action: PayloadAction<{ id: number; currentFolder: string }>
    ) => {
      if (state.messageCollection[action.payload.currentFolder]?.data.length > 0) {
        const newCollection = { ...state.messageCollection };
        newCollection[action.payload.currentFolder].data = newCollection[action.payload.currentFolder]?.data.filter(
          (message) => {
            return message.uid !== action.payload.id;
          }
        );

        state.messageCollection = newCollection;
      }
    },
    clearMessageCollection: (state: MessageCollectionState, action: PayloadAction<string>) => {
      const newCollection = { ...state.messageCollection };
      delete newCollection[action.payload];
      state.messageCollection = newCollection;
    },
    reloadMessageCollection: (state: MessageCollectionState, action: PayloadAction<boolean>) => {
      state.reloadMessageCollection = action.payload;
    },
    updateGetMailLoading: (state: MessageCollectionState, action: PayloadAction<boolean>) => {
      state.getMailLoading = action.payload;
    },
    updateMessageCollectionSort: (state: MessageCollectionState, action: PayloadAction<Sort>) => {
      state.messageCollectionQuery = {
        ...state.messageCollectionQuery,
        sort: action.payload,
        page: 1,
      };
      state.reloadMessageCollection = true;
    },
    updateDeleteButtonLoading: (state: MessageCollectionState, action: PayloadAction<boolean>) => {
      state.deleteButtonLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMessageCollectionThunk.pending, (state) => {
      state.messageCollectionLoading = true;
      state.reloadMessageCollection = false;
    });

    builder.addCase(getMessageCollectionThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.messageCollectionFetchError = undefined;
        state.messageCollectionLoading = false;
        const { limit, currentPage, total } = action.payload.success;
        const newMessageCollection = { ...state.messageCollection } as MessageCollectionType;
        newMessageCollection[action.payload.mailboxId as string] = action.payload.success;
        state.messageCollection = newMessageCollection;
        state.messageCollectionQuery.limit = limit as number;
        state.messageCollectionQuery.page = currentPage as number;
        state.messageCollectionQuery.search = action.payload.search;
        const newTotals = { ...state.totals };
        newTotals[action.payload.mailboxId as string] = total;
        state.totals = newTotals;
        state.selectedMessages = [];
        return;
      }
      if (action.payload.error) {
        state.messageCollectionFetchError = action.payload.error;
        state.messageCollectionLoading = false;
        state.getMailLoading = false;
        return;
      }
      state.messageCollectionLoading = false;
      state.getMailLoading = false;
    });

    builder.addCase(moveMessagesThunk.fulfilled, (state, action) => {
      state.deleteButtonLoading = false;

      if (action.payload.success) {
        const newCollection = { ...state.messageCollection };
        newCollection[action.payload.sourceMailboxId || ''].data = newCollection[
          action.payload.sourceMailboxId || ''
        ]?.data.filter((message) => {
          return !action.payload.messageUids?.includes(message.uid);
        });

        delete newCollection[action.payload.destinationMailboxId as string];
        state.messageCollection = newCollection;

        state.selectedMessages = [];
        state.apiError = undefined;
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(permanentDeleteMessageThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        const newCollection = { ...state.messageCollection };
        newCollection[action.payload.mailboxId || ''].data.filter((message) => {
          return action.payload.messageUid !== message.uid;
        });
        state.messageCollection = newCollection;
        state.apiError = undefined;
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(markMessagesReadThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        const newCollection = { ...state.messageCollection };
        newCollection[action.payload.mailboxId || '']?.data.forEach((message) => {
          if (action.payload.messageUids?.includes(message.uid) && isMessageUnread(message)) {
            message.flags?.push(appConstants.MESSAGE_FLAG_READ);
          }
        });

        state.messageCollection = newCollection;
        state.selectedMessages = [];
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(getMessageThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        const newCollection = { ...state.messageCollection };
        newCollection[action.payload.mailboxId || '']?.data.forEach((message) => {
          if (message.uid === action.payload.success?.uid && isMessageUnread(message)) {
            message.flags?.push(appConstants.MESSAGE_FLAG_READ);
          }
        });
        state.messageCollection = newCollection;
      }
    });

    builder.addCase(markMessagesUnreadThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        const newCollection = { ...state.messageCollection };
        newCollection[action.payload.mailboxId || '']?.data.forEach((message) => {
          if (action.payload.messageUids?.includes(message.uid)) {
            message.flags = message.flags?.filter((flag) => flag !== appConstants.MESSAGE_FLAG_READ);
          }
        });
        state.messageCollection = newCollection;
        if (state.selectedMessages && state.selectedMessages.length > 0) {
          state.selectedMessages = [];
        }
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(markMessagesFlagThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        const newCollection = { ...state.messageCollection };
        newCollection[action.payload.mailboxId || '']?.data.forEach((message) => {
          if (action.payload.messageUids?.includes(message.uid)) {
            message.flags?.push(appConstants.MESSAGE_FLAG_FLAGGED);
          }
        });
        state.messageCollection = newCollection;
        if (state.selectedMessages && state.selectedMessages.length > 0) {
          state.selectedMessages = [];
        }
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(markMessagesUnflagThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        const newCollection = { ...state.messageCollection };
        newCollection[action.payload.mailboxId || '']?.data.forEach((message) => {
          if (action.payload.messageUids?.includes(message.uid) && isMessageFlagged(message)) {
            message.flags = message.flags?.filter((flag) => flag !== appConstants.MESSAGE_FLAG_FLAGGED);
          }
        });
        state.messageCollection = newCollection;
        if (state.selectedMessages && state.selectedMessages.length > 0) {
          state.selectedMessages = [];
        }
      }

      if (action.payload.error) {
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });

    builder.addCase(saveDraftThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.reloadMessageCollection = action.payload.isSelectedFolderDraft;
        state.apiError = undefined;

        if (state.messageCollection[appConstants.DRAFT_FOLDER_ID]) {
          const newCollection = { ...state.messageCollection };
          delete newCollection[appConstants.DRAFT_FOLDER_ID];
          state.messageCollection = newCollection;
        }
      }
    });
    builder.addCase(permanentDeleteMessageBulkThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.deleteButtonLoading = false;
        state.reloadMessageCollection = true;
        state.apiError = undefined;
      }

      if (action.payload.error) {
        state.deleteButtonLoading = false;
        state.apiError = action.payload.error as ApolloError;
        return;
      }
    });
    builder.addCase(updateIsOpen, (state, action) => {
      if (action.payload === false) {
        state.apiError = undefined;
      }
    });
    builder.addCase(sentMailThunk.fulfilled, (state, action) => {
      if (
        action.payload.success &&
        state.messageCollection[appConstants.SENT_MAIL_FOLDER_ID] &&
        !isSentMailFolder(action.payload.currentFolder || '')
      ) {
        const newCollection = { ...state.messageCollection };
        delete newCollection[appConstants.SENT_MAIL_FOLDER_ID];
        state.messageCollection = newCollection;
      }

      if (
        action.payload.success &&
        state.messageCollection[appConstants.DRAFT_FOLDER_ID] &&
        action.payload.isOpenedFromDraft &&
        !isDraftFolder(action.payload.currentFolder || '')
      ) {
        const newCollection = { ...state.messageCollection };
        delete newCollection[appConstants.DRAFT_FOLDER_ID];
        state.messageCollection = newCollection;
      }
    });
    builder.addCase(deleteMailboxThunk.fulfilled, (state, action) => {
      if (action.payload.success && !isGlobalFolder(action.payload.mailboxId, action.payload.spamFolderId || '')) {
        const newCollection = { ...state.messageCollection };
        delete newCollection[action.payload.mailboxId];
        state.messageCollection = newCollection;
      }
    });
    builder.addCase(renameMailboxThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        const newCollection = { ...state.messageCollection };
        newCollection[action.payload.success.id] = { ...newCollection[action.payload.oldMailboxId] };
        delete newCollection[action.payload.oldMailboxId];
        const newFolderName = atob(action.payload.success.id);
        const oldMailboxName = atob(action.payload.oldMailboxId);
        state.messageCollection = updateFolderNames(newFolderName, oldMailboxName, newCollection);
      }
    });
  },
});

export const {
  updateSelectedMessages,
  updateSelectAllMessages,
  updateSelectMultipleMessages,
  clearMessageCollection,
  removeMessageFromCollection,
  reloadMessageCollection,
  updateGetMailLoading,
  updateMessageCollectionSort,
  updateDeleteButtonLoading,
} = messageCollectionSlice.actions;

export default messageCollectionSlice.reducer;
