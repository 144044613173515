import { MailboxEntity, MailboxStatusEntity } from 'generated/graphql';
import { get } from 'lodash';

export interface Mailbox extends MailboxEntity {
  icon?: string;
  totalMessageCount?: number;
  newMessageCount?: number;
  unseenMessageCount?: number;
  children?: Mailbox[];
  order?: number;
  count?: number;
  isTrash?: boolean;
  fullPath?: string;
}

export interface FolderCollectionHelperInterface {
  globalFolder: Mailbox[];
  userFolder: Mailbox[];
}

interface FolderMapInterface {
  [key: string]: {
    icon: string;
    order: number;
    countParam: string;
    isTrash: boolean;
  };
}

const folderCollectionHelper = (
  mailboxCollection: MailboxEntity[],
  globalMailboxStatus: MailboxStatusEntity[]
): FolderCollectionHelperInterface => {
  const folderMap: FolderMapInterface = {
    INBOX: { icon: 'ki-mail', order: 0, countParam: 'unseenMessageCount', isTrash: false },
    Drafts: { icon: 'ki-edit', order: 1, countParam: 'totalMessageCount', isTrash: false },
    SentMail: { icon: 'ki-send', order: 2, countParam: 'totalMessageCount', isTrash: false },
    JunkMail: { icon: 'ki-cancel', order: 3, countParam: 'totalMessageCount', isTrash: false },
    'Junk Mail': { icon: 'ki-cancel', order: 3, countParam: 'totalMessageCount', isTrash: false },
    Trash: { icon: 'ki-trash', order: 4, countParam: 'totalMessageCount', isTrash: true },
  };

  const isGlobalFolder = (mailboxId: string): boolean =>
    globalMailboxStatus.some((mailbox) => mailbox.id === mailboxId) as boolean;

  const getFolderStatus = (mailboxId: string): MailboxStatusEntity | undefined =>
    globalMailboxStatus.find((mailbox) => mailbox.id === mailboxId);

  const allFolders: Mailbox[] = [];
  const globalFolderCollection: Mailbox[] = [];
  const userFolderCollection: Mailbox[] = [];

  mailboxCollection.forEach((mailbox) => {
    allFolders.push(mailbox);
  });

  allFolders.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  const insert = (children = [] as Mailbox[], [head, ...tail]: string[], id: string, fullPath: string): [] => {
    let child = children.find((child) => child.name === head);
    if (!child) children.push((child = { name: head, children: [], id, delimiter: '/', fullPath }));
    if (tail.length > 0) insert(child.children, tail, id, fullPath);
    return children as [];
  };

  const nestedFolders = allFolders
    .map((mailbox) => mailbox.name.split('/').concat([mailbox.id]))
    .reduce((children, path) => {
      const mailboxId = path.pop();
      const fullPath = path.join('/');
      return insert(children, path, mailboxId as string, fullPath);
    }, [] as Mailbox[]);

  nestedFolders.forEach((folder) => {
    if (isGlobalFolder(folder.id)) {
      const folderStatus = getFolderStatus(folder.id);

      const newMailbox: Mailbox = {
        ...folder,
        icon: folderMap[folder.name]?.icon,
        totalMessageCount: folderStatus?.totalMessageCount || 0,
        newMessageCount: folderStatus?.newMessageCount || 0,
        unseenMessageCount: folderStatus?.unseenMessageCount || 0,
        order: folderMap[folder.name]?.order,
        count: get(folderStatus, folderMap[folder.name]?.countParam),
        isTrash: folderMap[folder.name]?.isTrash,
        fullPath: folder.name,
      };

      globalFolderCollection.push(newMailbox);
      return;
    }

    userFolderCollection.push(folder);
  });

  globalFolderCollection.sort((a, b) => {
    return (a.order || 0) - (b.order || 0);
  });

  return {
    globalFolder: globalFolderCollection,
    userFolder: userFolderCollection,
  };
};

export default folderCollectionHelper;
