import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Success, SettingMigrationMutationFn } from 'generated/graphql';

interface SettingMigrationResponse {
  success?: Success;
  error?: ApolloError | undefined;
}

export interface MigrationArg {
  settingMigrationHook: SettingMigrationMutationFn;
}

export const settingMigrationThunk = createAsyncThunk(
  'settingMigration',
  async (arg: MigrationArg): Promise<SettingMigrationResponse> => {
    try {
      const response = await arg.settingMigrationHook();
      return {
        success: response.data?.settingMigration,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
