import React from 'react';
import { KiteGrid, KiteGridCell } from '@kite/react-kite';
import styles from 'components/footer/styles/Footer.module.scss';
import SocialMedia from 'components/footer/SocialMedia';
import { footerColumns } from 'app-constants';
import SpectrumColumn from './SpectrumColumn';

interface SpectrumLinksProps {
  slim?: boolean;
}

const SpectrumLinks: React.FC<SpectrumLinksProps> = () => (
  <KiteGridCell col={12}>
    <KiteGrid className={styles.footerColumns}>
      <SpectrumColumn {...footerColumns.SUPPORT} />
      <SpectrumColumn {...footerColumns.WATCH_TV} />
      <SocialMedia />
    </KiteGrid>
  </KiteGridCell>
);

export default SpectrumLinks;
