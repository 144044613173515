import React, { memo, useEffect, useRef, useState } from 'react';
import styles from 'components/common/kebab-menu/styles/KebabMenu.module.scss';
import { KiteMenu, KiteMenuItem } from '@kite/react-kite';
import { RkMenuPosition } from '@kite/react-kite/dist/menu/KiteMenu/KiteMenu';
import { updateModalOpener } from 'components/common/focus/FocusContext';
export interface KebabMenuItemsInterface {
  onClick: () => void;
  icon?: string;
  label: string;
  className?: string;
  hideOnDesktop?: boolean;
  isMenuItemDisabled?: boolean;
  isMenuItemHidden?: boolean;
  ariaLabel?: string;
  ariaDescribedBy?: string;
  opensModal?: boolean;
  redIcon?: boolean;
}

interface KebabMenuPropsInterface {
  items: KebabMenuItemsInterface[];
  className?: string;
  position?: RkMenuPosition;
  disabled?: boolean;
  onKebabToggle?: (expanded: boolean) => void;
  isScrollContainer?: boolean;
  ariaLabel?: string;
  ariaDescribedBy?: string;
}

const getYPosition = (currentRef: HTMLDivElement, maxScrollY: number): RkMenuPosition => {
  if (currentRef.getBoundingClientRect().top < maxScrollY) {
    return 'top-right';
  }
  return 'bottom-right';
};

const KebabMenu: React.FC<KebabMenuPropsInterface> = ({
  items,
  className,
  position,
  disabled,
  onKebabToggle,
  isScrollContainer,
  ariaLabel,
  ariaDescribedBy,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [totalPos, setTotalPos] = useState<RkMenuPosition>(position || 'top-right');
  const menuRef = useRef<HTMLElement>();
  const updateModalRef = updateModalOpener();

  useEffect(() => {
    if (isScrollContainer && ref.current) {
      const scrollContainer = ref.current.closest('.scrollbar-container > div');
      if (scrollContainer) {
        scrollContainer!.addEventListener('scroll', () => {
          if (ref.current) {
            const { height, y } = scrollContainer.getBoundingClientRect();
            setTotalPos(getYPosition(ref.current, height + y - (28 + items.length * 50)));
          }
        });

        const { height, y } = scrollContainer.getBoundingClientRect();
        setTotalPos(getYPosition(ref.current, height + y - (28 + items.length * 50)));
      }
    }
  }, [ref.current]);

  const renderMenuItems = (): JSX.Element[] =>
    items
      .filter((item) => !item.isMenuItemHidden)
      .map((item, index) => (
        <KiteMenuItem
          key={index}
          className={`${item.hideOnDesktop ? styles.hideOnDesktop : ''} ${item.className || ''} ${
            item.redIcon ? styles.redIcon : ''
          }`}
          onClick={() => {
            if (item.opensModal) {
              updateModalRef(menuRef.current!);
            }
            item.onClick();
          }}
          icon={item.icon}
          disabled={item?.isMenuItemDisabled}
          aria-disabled={item?.isMenuItemDisabled}
          aria-label={item.ariaLabel || item.label}
          aria-describedby={item.ariaDescribedBy || null}
          destructive={item.redIcon}
        >
          {item.label}
        </KiteMenuItem>
      ));

  return (
    <div className={`${styles.labelKebabMenuContainer} ${className || ''}`} ref={ref}>
      <KiteMenu
        variant="flow"
        menuIcon="ki-more-vert"
        position={totalPos || 'top-right'}
        disabled={disabled}
        aria-disabled={disabled}
        onToggle={(expanded: boolean) => {
          if (onKebabToggle) {
            onKebabToggle(expanded);
          }
        }}
        ariaLabel={ariaLabel}
        aria-describedby={ariaDescribedBy}
        triggerRef={menuRef}
      >
        {renderMenuItems()}
      </KiteMenu>
    </div>
  );
};

export default memo(KebabMenu);
