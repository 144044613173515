import FolderListContainerStyles from 'containers/styles/FolderListContainer.module.scss';
import { KiteButton, KiteCard, KiteMeter } from '@kite/react-kite';
import { TrackingHook, useTracking } from 'react-tracking';
import { useAppDispatch, useAppSelector } from 'hooks';
import { addTabCollection, updateMinimizedIndex } from 'slices/compose';
import FolderList from 'components/folder-list';
import { createTargetRef } from 'components/common/focus/FocusContext';
import { usePageTranslation } from 'translation/hooks';

const FolderListContainer: React.FC = () => {
  const { trackEvent }: TrackingHook = useTracking({ page: 'compose' });
  const { t } = usePageTranslation('emailNavigationCopy');
  const composeButtonRef = createTargetRef<HTMLButtonElement>('composeBtn');

  const isAutoInsertSignature = useAppSelector((state) => state.signatureSlice.signatureEntity?.autoInsert);
  const signature = useAppSelector((state) => state.signatureSlice.signatureEntity?.signature) || '';
  const { percentageUsed, limit, usage } = useAppSelector((state) => state.quotaSlice);

  const dispatch = useAppDispatch();

  const handleOpenCompose = (): void => {
    trackEvent({ event: 'openCompose' });
    dispatch(addTabCollection(isAutoInsertSignature ? signature : null));
    dispatch(updateMinimizedIndex(''));
  };

  return (
    <KiteCard className={FolderListContainerStyles.folderContainer} ariaLabel="mailbox navigation">
      <section className={FolderListContainerStyles.composeButtonSection}>
        <KiteButton
          type="button"
          className={FolderListContainerStyles.composeButton}
          onClick={handleOpenCompose}
          aria-label="compose"
          ref={composeButtonRef}
        >
          {t('compose')}
        </KiteButton>
      </section>
      <section className={FolderListContainerStyles.sectionItemList}>
        <FolderList />
      </section>
      <div className={FolderListContainerStyles.inboxMeter}>
        <KiteMeter
          type="linear"
          size="small"
          label={t('storage')}
          status="neutral"
          value={percentageUsed}
          valueLabel={t('filled', { usage, limit })}
        />
      </div>
    </KiteCard>
  );
};

export default FolderListContainer;
