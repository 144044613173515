import Helix from '@charter/helix/build/portals/index.min';
import getAppVersion from 'libs/get.app.version';
import { appConstants } from 'appConstants';

interface ConfigInterface {
  collector: {
    domain: string;
    appName: string;
    startSession: string;
    requirementsVersion: string;
    appVersion: string;
    appEnvName: string;
    appType: string;
  };
  quantum: {
    endpoint: string;
  };
}

export const helixConfig: ConfigInterface = {
  collector: {
    domain: appConstants.helix.HELIX_DOMAIN,
    appName: appConstants.helix.HELIX_APP_NAME,
    startSession: appConstants.helix.HELIX_START_SESSION,
    requirementsVersion: appConstants.helix.HELIX_REQ_VERSION,
    appVersion: appConstants.helix.HELIX_APP_VERSION,
    appEnvName: appConstants.helix.HELIX_ENV,
    appType: appConstants.helix.HELIX_APP_TYPE,
  },
  quantum: {
    endpoint: appConstants.helix.HELIX_ENDPOINT,
  },
};

export default class HelixService {
  config: ConfigInterface;

  constructor(config: ConfigInterface) {
    this.config = config;
  }

  async init(): Promise<{ success: boolean; visitId?: string }> {
    try {
      const {
        quantum: { endpoint },
        collector: { startSession },
      } = this.config;

      Helix.set('endpoint', endpoint);

      this.config.collector.appVersion = await getAppVersion();

      const result = await Helix.init(this.config.collector).then(
        async (): Promise<{ success: boolean; visitId?: string }> => {
          if (Helix.get('currentState') === 'blank') {
            await Helix.trackStartSession(startSession);

            return { success: true, visitId: Helix.get('visitId') };
          }
          return { success: false };
        }
      );

      return result;
    } catch (err) {
      console.error(err);
      return { success: false };
    }
  }
}
