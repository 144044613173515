import { createRef, useRef } from 'react';
import FocusContext, {
  Targets,
  TargetRefT,
  UniqueTargets,
  AssignUniqueReturn,
} from 'components/common/focus/FocusContext';

const FocusProvider: React.FC = ({ children }) => {
  const modalOpenerRef = useRef<HTMLElement>(null);
  const targets = useRef<Targets>({ modal: modalOpenerRef });

  const focusAfterRender = (targetKey: keyof Targets): void => {
    if (targets.current[targetKey]) {
      targets.current[targetKey].current?.focus();

      return;
    }
  };

  const focus = (targetKey: keyof Targets): void => {
    setTimeout(focusAfterRender, 0, targetKey);
  };

  const addTargetRef = <T extends HTMLElement = HTMLElement>(targetKey: keyof Targets): TargetRefT<T>['target'] => {
    const target = createRef<T>();
    targets.current[targetKey] = target;
    return target;
  };

  const deleteTargetRef = (targetKey: keyof Targets): void => {
    delete targets.current[targetKey];
  };

  const assignUnique = <T extends HTMLElement = HTMLElement>(target: UniqueTargets): AssignUniqueReturn<T> => {
    const assign = (node: T): void => {
      targets.current[target].current = node;
    };
    const clear = (): void => {
      targets.current[target].current = null;
    };
    return { assign, clear };
  };

  const createTargetRef = <T extends HTMLElement = HTMLElement>(targetKey: keyof Targets): TargetRefT<T> => {
    const target = addTargetRef<T>(targetKey);
    const clear = (): void => deleteTargetRef(targetKey);

    return { target, clear };
  };

  return <FocusContext.Provider value={{ createTargetRef, focus, assignUnique }}>{children}</FocusContext.Provider>;
};

export default FocusProvider;
