import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteStorageMutationFn } from 'generated/graphql';

interface deleteAttachmentThunkArgs {
  payload: {
    id: string;
    index: string;
  };
  deleteAttachmentHook: DeleteStorageMutationFn;
}

interface deleteAttachmentThunkResponse {
  index: string;
  success?: {
    id: string;
  };
  error?: Error;
}

export const deleteAttachmentThunk = createAsyncThunk(
  'deleteAttachmentThunk',
  async (arg: deleteAttachmentThunkArgs): Promise<deleteAttachmentThunkResponse> => {
    try {
      await arg.deleteAttachmentHook({
        variables: arg.payload,
      });

      return {
        index: arg.payload.index,
        success: { id: arg.payload.id },
      };
    } catch (err) {
      return {
        index: arg.payload.index,
        error: err as Error,
      };
    }
  }
);
