import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PatchTimeZoneMutationFn, SettingEntity } from 'generated/graphql';

export interface UpdateTimeZoneInterface {
  payload: {
    value: string;
  };
  updateTimeZoneHook: PatchTimeZoneMutationFn;
}

export interface UpdateTimeZoneResponse {
  success?: SettingEntity;
  error?: ApolloError;
}

export const updateTimeZoneThunk = createAsyncThunk(
  'updateTimeZoneThunk',
  async (arg: UpdateTimeZoneInterface): Promise<UpdateTimeZoneResponse> => {
    try {
      const response = await arg.updateTimeZoneHook({
        variables: arg.payload,
      });
      return {
        success: response.data?.patchTimeZone,
      } as UpdateTimeZoneResponse;
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
