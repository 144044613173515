import { useAppDispatch } from 'hooks';
import { createContext, useEffect, useState } from 'react';
import { loginSsoThunk, LoginSsoThunkSuccess } from 'thunks/login/loginSsoThunk';
import { isLoggedInLocalStorage } from 'utils/loginLocalStorage';
import { TrackingHook, useTracking } from 'react-tracking';
import { get } from 'lodash';
interface AuthContextInterface {
  isLoggedIn: boolean;
  authCode: string | null;
  setAuthCode: React.Dispatch<React.SetStateAction<string | null>>;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  isLoginLoading: boolean;
  loginFailed: boolean;
}

export const AuthContext = createContext<AuthContextInterface>({
  isLoggedIn: false,
  authCode: null,
  setAuthCode: () => null,
  setIsLoggedIn: () => null,
  isLoginLoading: false,
  loginFailed: false,
});

export const getAuthCode = (): string | null => {
  const params = new URLSearchParams(window.location.search);
  return params.get('code');
};

export const getIsLoggedIn = (): boolean => {
  if (isLoggedInLocalStorage() && getAuthCode()) {
    return false;
  }
  return isLoggedInLocalStorage();
};

const AuthProvider: React.FC = ({ children }) => {
  const { trackEvent }: TrackingHook = useTracking({ page: 'login' });
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(getIsLoggedIn());
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [authCode, setAuthCode] = useState<string | null>(getAuthCode());
  const [loginFailed, setLoginFailed] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const login = async (authCode: string): Promise<void> => {
    trackEvent({ event: 'loginStart' });
    setIsLoginLoading(true);
    const result = await dispatch(loginSsoThunk({ payload: { code: authCode, grantType: 'authorization_code' } }));
    const success: LoginSsoThunkSuccess = get(result.payload, 'success');

    if (success) {
      setIsLoggedIn(true);
      setIsLoginLoading(false);
      setAuthCode(null);

      trackEvent({
        event: 'loginStopSuccess',
        authReportingId: success.authReportingId,
        accountGuid: success.accountGuid,
        accountNumber: success.accountNumber,
        footprint: success.footprint,
        authSource: 'Portals',
      });
    } else {
      // @ts-ignore
      trackEvent({ event: 'loginStopFailure', errorCode: result.payload.statusCode, authSource: 'Portals' });
      setIsLoginLoading(false);
      setLoginFailed(true);
    }
  };

  useEffect(() => {
    if (!isLoggedIn && authCode) {
      login(authCode);
    }
  }, [isLoggedIn, authCode]);

  return (
    <AuthContext.Provider value={{ setAuthCode, setIsLoggedIn, isLoggedIn, authCode, isLoginLoading, loginFailed }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
