import React from 'react';
import ContactSection from 'components/contact-form/sections/ContactSection';
import { KiteIcon, KiteTextInput } from '@kite/react-kite';
import styles from 'components/contact-form/styles/ContactCreateContact.module.scss';
import { ContactSectionProps } from 'components/contact-form/initialState';
import { ContactFormInterface } from 'components/contact-form/initialState';
import validateContactField, { getContactValidationErrorMessage } from 'utils/validateContactField';
import { KiteInputElems } from 'libs/kite/types';
import { companyTrans, titleTrans } from './translationKeys';
import { usePageTranslation } from 'translation/hooks';

type CompanyFields = Pick<ContactFormInterface, 'title' | 'company'>;
type HandleCompanyChange = (e: React.ChangeEvent<KiteInputElems>, formKey: keyof CompanyFields) => void;

export const Employment: React.FC<ContactSectionProps<CompanyFields>> = ({
  updateFormValues,
  values,
  errors,
  setErrors,
}) => {
  const { t } = usePageTranslation('contactFormCopy');

  const titleErrorMessage = getContactValidationErrorMessage(errors, 'title', titleTrans);
  const companyErrorMessage = getContactValidationErrorMessage(errors, 'company', companyTrans);

  const handleCompanyChange: HandleCompanyChange = (e, formKey) => {
    updateFormValues(e.target.value, formKey);
    validateContactField(formKey, { [formKey]: e.target.value }, errors, setErrors);
  };

  return (
    <ContactSection>
      <KiteIcon className={styles.fieldIcon} icon="ki-pdf" />
      <div className={styles.fieldContainer}>
        <KiteTextInput
          className={styles.halfField}
          placeholder={t('titlePlaceholder')}
          label={t('title')}
          onChange={(e) => handleCompanyChange(e, 'title')}
          value={values.title || ''}
          errorMessage={titleErrorMessage ? t(titleErrorMessage) : ''}
          aria-label={'enter job title'}
          ariaLive="polite"
        />
        <KiteTextInput
          className={styles.halfField}
          placeholder={t('companyPlaceholder')}
          label={t('company')}
          onChange={(e) => handleCompanyChange(e, 'company')}
          value={values.company || ''}
          errorMessage={companyErrorMessage ? t(companyErrorMessage) : ''}
          aria-label={'enter company name'}
          ariaLive="polite"
        />
      </div>
    </ContactSection>
  );
};
