import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { FC, HTMLAttributes } from 'react';

const ModalContentWrapper: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => {
  return (
    <div className={`${modalStyle.modal} ${className}`} role="dialog" aria-modal>
      {children}
    </div>
  );
};

export default ModalContentWrapper;
