import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PostMarkMessagesAsFlagMutationFn } from 'generated/graphql';

interface markMessagesFlagResponse {
  success?: boolean;
  error?: ApolloError | undefined;
  messageUids?: number[];
  mailboxId?: string;
}

interface markMessageFlagArgs {
  payload: {
    mailboxId: string;
    messageUids: number[];
  };
  markMessagesFlagHook: PostMarkMessagesAsFlagMutationFn;
}

export const markMessagesFlagThunk = createAsyncThunk(
  'markMessagesFlag',
  async (arg: markMessageFlagArgs): Promise<markMessagesFlagResponse> => {
    try {
      const response = await arg.markMessagesFlagHook({
        variables: {
          mailboxId: arg.payload.mailboxId,
          messageUids: arg.payload.messageUids,
        },
      });
      return {
        success: response?.data?.markMessagesImportant.success || false,
        messageUids: arg.payload.messageUids,
        mailboxId: arg.payload.mailboxId,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
      };
    }
  }
);
