import { ApolloError } from '@apollo/client';
import parseGraphQLError from 'utils/parseGraphQLError';

interface NormalizeErrorInterface {
  errorCode: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  normalizedError: any;
}

const normalizeFormErrors = (error: ApolloError): NormalizeErrorInterface => {
  const { errorList, errorCode } = { ...parseGraphQLError(error) };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normalizedError: any = {};

  errorList?.forEach((errorObj) => {
    if (errorObj.path) {
      normalizedError[errorObj.path[0]] = `${errorObj.path[0]}.${errorObj.type}` as string;
    }
  });

  return {
    errorCode: errorCode as string,
    normalizedError,
  };
};

export default normalizeFormErrors;
