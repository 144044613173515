import { KiteCard, KiteIcon } from '@kite/react-kite';
import SpectrumLinkGroup from 'components/header/SpectrumLinkGroup';
import { useState } from 'react';
import { TrackingHook, useTracking } from 'react-tracking';
import loginDrawerContainerStyles from 'containers/styles/LoginDrawerContainer.module.scss';
import { UseModalInterface } from 'hooks/modalHook';
import { appConstants } from 'appConstants';

interface LoginDrawerContainerProps {
  toggle: UseModalInterface['toggle'];
  isShown: UseModalInterface['isShown'];
}

const LoginDrawerContainer: React.FC<LoginDrawerContainerProps> = ({ toggle }) => {
  const [dynamicClass, setDynamicClass] = useState<'open' | 'close'>('open');
  const { trackEvent }: TrackingHook = useTracking({ page: 'login', component: 'LoginDrawerContainer' });

  const headerMenuBackground = appConstants.ui.HEADER_MENU_BACKGROUND;

  const cardClass =
    dynamicClass === 'open' ? loginDrawerContainerStyles.openCard : loginDrawerContainerStyles.closeCard;

  const delayedClose = (): void => {
    setDynamicClass('close');
    setTimeout(() => {
      toggle();
      setDynamicClass('open');
    }, appConstants.ui.MENU_ANIMATION_BUFFER);
  };

  return (
    <div style={{ display: 'none' }}>
      <div className={loginDrawerContainerStyles.backgroundCard}>
        <img
          alt="bg"
          className={`${loginDrawerContainerStyles.backgroundImg} ${cardClass}`}
          src={headerMenuBackground}
        />
        <KiteCard className={`${loginDrawerContainerStyles.content} ${cardClass}`}>
          <button
            autoFocus={true}
            type="button"
            onClick={() => {
              trackEvent({
                event: 'hamburgerClose',
              });
              delayedClose();
            }}
            className={loginDrawerContainerStyles.xIconButton}
          >
            <KiteIcon icon="ki-x" className={loginDrawerContainerStyles.xIcon} />
          </button>
          <SpectrumLinkGroup />
        </KiteCard>
      </div>
    </div>
  );
};

export default LoginDrawerContainer;
