import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteContactMutationFn } from 'generated/graphql';

interface DeleteContactArg {
  payload: {
    id: string;
  };
  deleteContactHook: DeleteContactMutationFn;
}

interface DeleteContactResponse {
  success?: boolean;
  error?: ApolloError;
  id: string;
}

export const deleteContactThunk = createAsyncThunk(
  'deleteContactThunk',
  async (arg: DeleteContactArg): Promise<DeleteContactResponse> => {
    try {
      const response = await arg.deleteContactHook({
        variables: arg.payload,
      });
      return {
        success: response.data?.deleteContact.success || false,
        id: arg.payload.id,
      };
    } catch (e) {
      return {
        error: e as ApolloError,
        id: arg.payload.id,
      };
    }
  }
);
