import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ContactAddressEntity = {
  __typename?: 'ContactAddressEntity';
  streetAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressType?: Maybe<Scalars['String']>;
};

export type ContactAddressForm = {
  streetAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  addressType?: Maybe<Scalars['String']>;
};

export type ContactBulkCreateEntity = {
  __typename?: 'ContactBulkCreateEntity';
  success: Scalars['Boolean'];
  createCount: Scalars['Float'];
};

export type ContactBulkDeleteEntity = {
  __typename?: 'ContactBulkDeleteEntity';
  success: Scalars['Boolean'];
  deleteCount: Scalars['Float'];
};

export type ContactBulkUpdateEntity = {
  __typename?: 'ContactBulkUpdateEntity';
  success: Scalars['Boolean'];
};

export type ContactCollection = {
  __typename?: 'ContactCollection';
  total: Scalars['Float'];
  currentPage: Scalars['Float'];
  limit: Scalars['Float'];
  data: Array<ContactEntity>;
};

export type ContactEmailEntity = {
  __typename?: 'ContactEmailEntity';
  emailAddress: Scalars['String'];
  emailAddressType?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type ContactEmailForm = {
  emailAddress: Scalars['String'];
  emailAddressType?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type ContactEntity = {
  __typename?: 'ContactEntity';
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<ContactEmailEntity>>;
  phoneNumbers?: Maybe<Array<ContactPhoneEntity>>;
  addresses?: Maybe<Array<ContactAddressEntity>>;
  labels?: Maybe<Array<Scalars['String']>>;
};

export type ContactExportForm = {
  __typename?: 'ContactExportForm';
  vCard: Array<Scalars['String']>;
};

export type ContactForm = {
  title?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  displayName: Scalars['String'];
  nickName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<ContactEmailForm>>;
  phoneNumbers?: Maybe<Array<ContactPhoneForm>>;
  addresses?: Maybe<Array<ContactAddressForm>>;
  labels?: Maybe<Array<Scalars['String']>>;
};

export type ContactPhoneEntity = {
  __typename?: 'ContactPhoneEntity';
  phoneNumber: Scalars['String'];
  phoneNumberType?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type ContactPhoneForm = {
  phoneNumber: Scalars['String'];
  phoneNumberType?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type ContactReSyncEntity = {
  __typename?: 'ContactReSyncEntity';
  success: Scalars['Boolean'];
  successContactCount: Scalars['Float'];
  successLabelCount: Scalars['Float'];
  errorContactCount: Scalars['Float'];
  errorLabelCount: Scalars['Float'];
};

export type ContactResyncAllowedEntity = {
  __typename?: 'ContactResyncAllowedEntity';
  lastReSyncDate?: Maybe<Scalars['String']>;
  isAllowed: Scalars['Boolean'];
};

export type ContactSearchArg = {
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
};

export type ContactWithLabelEntity = {
  __typename?: 'ContactWithLabelEntity';
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<ContactEmailEntity>>;
  phoneNumbers?: Maybe<Array<ContactPhoneEntity>>;
  addresses?: Maybe<Array<ContactAddressEntity>>;
  labels: Array<LabelEntity>;
};

export type Count = {
  __typename?: 'Count';
  count: Scalars['String'];
};

export type EligibilityStatus = {
  __typename?: 'EligibilityStatus';
  isEligible: Scalars['Boolean'];
};

export type EmailAddressEntity = {
  __typename?: 'EmailAddressEntity';
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type EmailAddressForm = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type FilterEntity = {
  __typename?: 'FilterEntity';
  name: Scalars['String'];
  clause: Scalars['String'];
  highPriority?: Maybe<Scalars['String']>;
  actionPriority?: Maybe<Scalars['String']>;
  actionDelete?: Maybe<Scalars['String']>;
  actionForward?: Maybe<Scalars['String']>;
  actionMove?: Maybe<Scalars['String']>;
  moveFolder?: Maybe<Scalars['String']>;
  forwardList?: Maybe<Array<Scalars['String']>>;
  rules: Array<FilterRulesEntity>;
};

export type FilterForm = {
  name: Scalars['String'];
  clause: ClauseEnum;
  highPriority: YesOrNoEnum;
  actionPriority: YesOrNoEnum;
  actionDelete: YesOrNoEnum;
  actionForward: YesOrNoEnum;
  actionMove: YesOrNoEnum;
  moveFolder?: Maybe<Scalars['String']>;
  forwardList?: Maybe<Array<Scalars['String']>>;
  rules: Array<FilterRulesForm>;
};

export type FilterRulesEntity = {
  __typename?: 'FilterRulesEntity';
  field: Scalars['String'];
  operator: Scalars['String'];
  value: Scalars['String'];
};

export type FilterRulesForm = {
  field: FieldEnum;
  operator: OperatorEnum;
  value: Scalars['String'];
};

export type ImapAttachmentForm = {
  fileName: Scalars['String'];
  externalRef: Scalars['String'];
  id: Scalars['String'];
  fileSize: Scalars['Float'];
};

export type ImapAttachmentListEntity = {
  __typename?: 'ImapAttachmentListEntity';
  fileName: Scalars['String'];
  fileSize: Scalars['Float'];
  id: Scalars['String'];
  externalRef: Scalars['String'];
};

export type ImapHeaderForm = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type InboxMailBoxStatus = {
  __typename?: 'InboxMailBoxStatus';
  status: Scalars['String'];
  unreadEmailCount: Scalars['Float'];
  unreadEmails: Scalars['Boolean'];
  allowEmailOptIn: Scalars['Boolean'];
  emailServiceUrl: Scalars['String'];
};

export type LabelDeleteStatus = {
  __typename?: 'LabelDeleteStatus';
  success: Scalars['Boolean'];
  totalContactsUpdated: Scalars['Float'];
};

export type LabelEntity = {
  __typename?: 'LabelEntity';
  id: Scalars['String'];
  name: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type LabelForm = {
  name: Scalars['String'];
};

export type LdapApprovedSenderForm = {
  added?: Maybe<LdapSenderControlInput>;
  removed?: Maybe<LdapSenderControlInput>;
};

export type LdapAutoReplyForm = {
  isVacationModeEnabled: Scalars['Boolean'];
  autoReplyMessage?: Maybe<Scalars['String']>;
};

export type LdapBlockedSenderForm = {
  added?: Maybe<LdapSenderControlInput>;
  removed?: Maybe<LdapSenderControlInput>;
};

export type LdapEmailForwardingForm = {
  mailForwardingAddress: Array<Scalars['String']>;
  mailForwarding: Scalars['Boolean'];
};

export type LdapMailPreferenceEntity = {
  __typename?: 'LdapMailPreferenceEntity';
  mailboxStatus: Scalars['String'];
  mailBlockSendersActive: Scalars['Boolean'];
  asfDeliveryActionDirty: Scalars['String'];
  mailAutoReplyMode: Scalars['String'];
  mailForwarding: Scalars['Boolean'];
  mailForwardingAddress: Array<Scalars['String']>;
  mailDeliveryOption: Scalars['String'];
};

export type LdapMailboxStatusForm = {
  mailboxStatus: Status;
};

export type LdapSenderControlEntity = {
  __typename?: 'LdapSenderControlEntity';
  approvedSenders: Array<Scalars['String']>;
  blockedSenders: Array<Scalars['String']>;
};

export type LdapSenderControlInput = {
  emails?: Maybe<Array<Scalars['String']>>;
  domains?: Maybe<Array<Scalars['String']>>;
};

export type LdapSettingEntity = {
  __typename?: 'LdapSettingEntity';
  mailUserPreferences: LdapMailPreferenceEntity;
  senderControl: LdapSenderControlEntity;
  mxos: MxosMailReceiptEntity;
};

export type LdapSpamManagementForm = {
  spamSetting: Scalars['String'];
};

export type MailForm = {
  to?: Maybe<Array<EmailAddressForm>>;
  cc?: Maybe<Array<EmailAddressForm>>;
  bcc?: Maybe<Array<EmailAddressForm>>;
  replyTo?: Maybe<EmailAddressForm>;
  subject?: Maybe<Scalars['String']>;
  priority?: Maybe<PriorityEnum>;
  messageId?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Scalars['String']>>;
  html?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<ImapAttachmentForm>>;
  headers?: Maybe<Array<ImapHeaderForm>>;
  uid?: Maybe<Scalars['Float']>;
  readReceipt?: Maybe<Scalars['Boolean']>;
};

export type MailSearchQueryForm = {
  sort?: Maybe<Sort>;
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  minDate?: Maybe<Scalars['String']>;
  maxDate?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type MailboxDeleteEntity = {
  __typename?: 'MailboxDeleteEntity';
  mailboxDeleteStatus: Array<MailboxDeleteStatusEntity>;
};

export type MailboxDeleteStatusEntity = {
  __typename?: 'MailboxDeleteStatusEntity';
  mailbox: Scalars['String'];
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  isGlobal: Scalars['Boolean'];
};

export type MailboxEntity = {
  __typename?: 'MailboxEntity';
  id: Scalars['String'];
  delimiter: Scalars['String'];
  name: Scalars['String'];
};

export type MailboxForm = {
  parentMailboxId?: Maybe<Scalars['String']>;
  mailbox: Scalars['String'];
};

export type MailboxStatusEntity = {
  __typename?: 'MailboxStatusEntity';
  id: Scalars['String'];
  name: Scalars['String'];
  totalMessageCount: Scalars['Float'];
  newMessageCount: Scalars['Float'];
  unseenMessageCount: Scalars['Float'];
};

export type MessageAppendResponseEntity = {
  __typename?: 'MessageAppendResponseEntity';
  isSavedToImap: Scalars['Boolean'];
  isSentToSmtp: Scalars['Boolean'];
  uid?: Maybe<Scalars['Float']>;
  smtpResponse?: Maybe<SmtpResponseEntity>;
};

export type MessageBodyEntity = {
  __typename?: 'MessageBodyEntity';
  html?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  noImageHtml?: Maybe<Scalars['String']>;
  fullText?: Maybe<Scalars['String']>;
};

export type MessageCollection = {
  __typename?: 'MessageCollection';
  total: Scalars['Float'];
  currentPage: Scalars['Float'];
  limit: Scalars['Float'];
  data: Array<MessageListEntity>;
};

export type MessageEntity = {
  __typename?: 'MessageEntity';
  from?: Maybe<Array<EmailAddressEntity>>;
  to?: Maybe<Array<EmailAddressEntity>>;
  cc?: Maybe<Array<EmailAddressEntity>>;
  bcc?: Maybe<Array<EmailAddressEntity>>;
  replyTo?: Maybe<Array<EmailAddressEntity>>;
  inReplyTo?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Scalars['String']>>;
  priority?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<MessageBodyEntity>;
  date?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<ImapAttachmentListEntity>>;
  flags?: Maybe<Array<Scalars['String']>>;
  uid?: Maybe<Scalars['Float']>;
  readReceipt?: Maybe<ReadReceiptEntity>;
};

export type MessageListEntity = {
  __typename?: 'MessageListEntity';
  to?: Maybe<Array<EmailAddressEntity>>;
  from?: Maybe<Array<EmailAddressEntity>>;
  seqNo: Scalars['Float'];
  messageId: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  priority?: Maybe<Scalars['String']>;
  flags?: Maybe<Array<Scalars['String']>>;
  uid: Scalars['Float'];
  attachmentCount: Scalars['Float'];
  readReceipt?: Maybe<ReadReceiptEntity>;
};

export type MessageRawEntity = {
  __typename?: 'MessageRawEntity';
  uid: Scalars['Float'];
  raw: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createLabel: LabelEntity;
  updateLabel: LabelEntity;
  deleteLabel: LabelDeleteStatus;
  postOptInStatus: OptIn;
  saveDraft: MessageAppendResponseEntity;
  sentMail: MessageAppendResponseEntity;
  deleteMessage: Success;
  deleteMessageBulk: Success;
  moveMessages: Success;
  markMessagesSeen: Success;
  markMessagesUnseen: Success;
  markMessagesImportant: Success;
  markMessagesUnimportant: Success;
  createSignature: SignatureEntity;
  updateSignature: SignatureEntity;
  patchAutoInsertSignature: SignatureEntity;
  patchTitleSignature: SignatureEntity;
  patchSignature: SignatureEntity;
  deleteSignature: Success;
  patchSetting: SettingEntity;
  patchDisplayName: SettingEntity;
  patchTimeZone: SettingEntity;
  patchReplyTo: SettingEntity;
  patchPreferredLanguage: SettingEntity;
  patchEmailPerPage: SettingEntity;
  patchAutoSaveDraft: SettingEntity;
  pathAutoMailboxReload: SettingEntity;
  patchIsDisplayImage: SettingEntity;
  patchIsDisplayHtml: SettingEntity;
  patchIsEmailPermanentDelete: SettingEntity;
  updateMailForwarding: Success;
  updateApprovedSender: Success;
  updateBlockedSender: Success;
  updateSpamSetting: Success;
  updateMailboxStatus: Success;
  updateAutoReply: Success;
  createMailbox: MailboxEntity;
  renameMailbox: MailboxEntity;
  deleteMailbox: MailboxDeleteEntity;
  deleteStorage: Success;
  createContact: ContactWithLabelEntity;
  updateContact: ContactWithLabelEntity;
  createBulkContact: ContactBulkCreateEntity;
  deleteBulkContact: ContactBulkDeleteEntity;
  deleteContact: Success;
  patchContactLabel: ContactWithLabelEntity;
  putBulkContactLabel: ContactBulkUpdateEntity;
  reSyncContact: ContactReSyncEntity;
  updateFilters: Success;
  contactMigration: Success;
  settingMigration: Success;
  contactRollback: Success;
  settingRollback: Success;
};


export type MutationCreateLabelArgs = {
  label: LabelForm;
};


export type MutationUpdateLabelArgs = {
  label: LabelForm;
  id: Scalars['String'];
};


export type MutationDeleteLabelArgs = {
  id: Scalars['String'];
};


export type MutationPostOptInStatusArgs = {
  optin: OptInForm;
};


export type MutationSaveDraftArgs = {
  displayName?: Maybe<Scalars['String']>;
  mail: MailForm;
};


export type MutationSentMailArgs = {
  displayName?: Maybe<Scalars['String']>;
  mail: MailForm;
};


export type MutationDeleteMessageArgs = {
  messageUid: Scalars['Float'];
  mailboxId: Scalars['String'];
};


export type MutationDeleteMessageBulkArgs = {
  messageUids: Array<Scalars['Float']>;
  mailboxId: Scalars['String'];
};


export type MutationMoveMessagesArgs = {
  messageUids: Array<Scalars['Float']>;
  destinationMailboxId: Scalars['String'];
  sourceMailboxId: Scalars['String'];
};


export type MutationMarkMessagesSeenArgs = {
  messageUids: Array<Scalars['Float']>;
  mailboxId: Scalars['String'];
};


export type MutationMarkMessagesUnseenArgs = {
  messageUids: Array<Scalars['Float']>;
  mailboxId: Scalars['String'];
};


export type MutationMarkMessagesImportantArgs = {
  messageUids: Array<Scalars['Float']>;
  mailboxId: Scalars['String'];
};


export type MutationMarkMessagesUnimportantArgs = {
  messageUids: Array<Scalars['Float']>;
  mailboxId: Scalars['String'];
};


export type MutationCreateSignatureArgs = {
  signature: SignatureForm;
};


export type MutationUpdateSignatureArgs = {
  signature: SignatureForm;
  id: Scalars['String'];
};


export type MutationPatchAutoInsertSignatureArgs = {
  value: Scalars['String'];
  id: Scalars['String'];
};


export type MutationPatchTitleSignatureArgs = {
  value: Scalars['String'];
  id: Scalars['String'];
};


export type MutationPatchSignatureArgs = {
  value: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteSignatureArgs = {
  id: Scalars['String'];
};


export type MutationPatchSettingArgs = {
  value: Scalars['String'];
  field: Scalars['String'];
};


export type MutationPatchDisplayNameArgs = {
  value: Scalars['String'];
};


export type MutationPatchTimeZoneArgs = {
  value: Scalars['String'];
};


export type MutationPatchReplyToArgs = {
  value: Scalars['String'];
};


export type MutationPatchPreferredLanguageArgs = {
  value: Scalars['String'];
};


export type MutationPatchEmailPerPageArgs = {
  value: Scalars['String'];
};


export type MutationPatchAutoSaveDraftArgs = {
  value: Scalars['String'];
};


export type MutationPathAutoMailboxReloadArgs = {
  value: Scalars['String'];
};


export type MutationPatchIsDisplayImageArgs = {
  value: Scalars['String'];
};


export type MutationPatchIsDisplayHtmlArgs = {
  value: Scalars['String'];
};


export type MutationPatchIsEmailPermanentDeleteArgs = {
  value: Scalars['String'];
};


export type MutationUpdateMailForwardingArgs = {
  mailForwarding: LdapEmailForwardingForm;
};


export type MutationUpdateApprovedSenderArgs = {
  approvedSender: LdapApprovedSenderForm;
};


export type MutationUpdateBlockedSenderArgs = {
  blockedSender: LdapBlockedSenderForm;
};


export type MutationUpdateSpamSettingArgs = {
  spam: LdapSpamManagementForm;
};


export type MutationUpdateMailboxStatusArgs = {
  mailbox: LdapMailboxStatusForm;
};


export type MutationUpdateAutoReplyArgs = {
  autoReply: LdapAutoReplyForm;
};


export type MutationCreateMailboxArgs = {
  mailbox: MailboxForm;
};


export type MutationRenameMailboxArgs = {
  mailbox: MailboxForm;
  oldMailboxId: Scalars['String'];
};


export type MutationDeleteMailboxArgs = {
  deleteMessage: Scalars['Boolean'];
  mailboxId: Scalars['String'];
};


export type MutationDeleteStorageArgs = {
  id: Scalars['String'];
};


export type MutationCreateContactArgs = {
  contact: ContactForm;
};


export type MutationUpdateContactArgs = {
  contact: ContactForm;
  id: Scalars['String'];
};


export type MutationCreateBulkContactArgs = {
  contacts: Array<ContactForm>;
};


export type MutationDeleteBulkContactArgs = {
  id: Array<Scalars['String']>;
};


export type MutationDeleteContactArgs = {
  id: Scalars['String'];
};


export type MutationPatchContactLabelArgs = {
  labels: Array<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationPutBulkContactLabelArgs = {
  labels: Array<Scalars['String']>;
  contacts: Array<Scalars['String']>;
};


export type MutationUpdateFiltersArgs = {
  filters: Array<FilterForm>;
};

export type MxosMailReceiptEntity = {
  __typename?: 'MxosMailReceiptEntity';
  autoReplyMode: Scalars['String'];
  autoReplyMessage: Scalars['String'];
};

export type OptIn = {
  __typename?: 'OptIn';
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  id: Scalars['String'];
  email: Scalars['String'];
  userId: Scalars['String'];
  optInType: Scalars['String'];
  optInStatus: Scalars['String'];
};

export type OptInForm = {
  optInType: Scalars['String'];
  isOptInYes: Scalars['Boolean'];
};

export type OptInResponse = {
  __typename?: 'OptInResponse';
  eligibility?: Maybe<EligibilityStatus>;
  status?: Maybe<OptIn>;
};

export type Query = {
  __typename?: 'Query';
  getLabelCollection: Array<LabelEntity>;
  getLabel: LabelEntity;
  getOptInStatus: OptInResponse;
  getEligibilityStatus: EligibilityStatus;
  getContactReSyncAllowed: ContactResyncAllowedEntity;
  getQuota: QuotaEntity;
  getMessageCollection: MessageCollection;
  getMessage: MessageEntity;
  getRawMessage: MessageRawEntity;
  getSignatureCollection: Array<SignatureEntity>;
  getSignature: SignatureEntity;
  getSetting: SettingEntity;
  getLdapSetting: LdapSettingEntity;
  getMxosMailReceipt: MxosMailReceiptEntity;
  getMailboxCollection: Array<MailboxEntity>;
  getGlobalMailboxStatus: Array<MailboxStatusEntity>;
  getMailboxStatus: MailboxStatusEntity;
  getInboxMailBoxStatus: InboxMailBoxStatus;
  getContactCollection: ContactCollection;
  getContact: ContactWithLabelEntity;
  getContactCount: Count;
  exportContact: ContactExportForm;
  getFilters: Array<FilterEntity>;
};


export type QueryGetLabelArgs = {
  id: Scalars['String'];
};


export type QueryGetMessageCollectionArgs = {
  query: MailSearchQueryForm;
  mailboxId: Scalars['String'];
};


export type QueryGetMessageArgs = {
  messageUid: Scalars['Float'];
  mailboxId: Scalars['String'];
};


export type QueryGetRawMessageArgs = {
  messageUid: Scalars['Float'];
  mailboxId: Scalars['String'];
};


export type QueryGetSignatureArgs = {
  id: Scalars['String'];
};


export type QueryGetMailboxStatusArgs = {
  mailboxId: Scalars['String'];
};


export type QueryGetContactCollectionArgs = {
  labelId?: Maybe<Scalars['String']>;
  search?: Maybe<ContactSearchArg>;
};


export type QueryGetContactArgs = {
  id: Scalars['String'];
};


export type QueryExportContactArgs = {
  ids: Array<Scalars['String']>;
};

export type QuotaEntity = {
  __typename?: 'QuotaEntity';
  usage: Scalars['String'];
  limit: Scalars['String'];
};

export type ReadReceiptEntity = {
  __typename?: 'ReadReceiptEntity';
  readReceipt: Scalars['Boolean'];
  readReceiptReplyTo?: Maybe<Scalars['String']>;
};

export type SettingEntity = {
  __typename?: 'SettingEntity';
  displayName?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
  emailPerPage: Scalars['Float'];
  replyTo?: Maybe<Scalars['String']>;
  preferredLanguage: Scalars['String'];
  isAutoSaveDraftEnabled: Scalars['Boolean'];
  isAutoMailboxReloadEnabled: Scalars['Boolean'];
  isDisplayImageEnabled: Scalars['Boolean'];
  isDisplayEmailAsHtmlEnabled: Scalars['Boolean'];
  isEmailPermanentlyDeleted: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type SignatureEntity = {
  __typename?: 'SignatureEntity';
  id: Scalars['String'];
  title: Scalars['String'];
  signature: Scalars['String'];
  autoInsert: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type SignatureForm = {
  title?: Maybe<Scalars['String']>;
  signature: Scalars['String'];
  autoInsert: Scalars['Boolean'];
};

export type SmtpResponseEntity = {
  __typename?: 'SmtpResponseEntity';
  messageId?: Maybe<Scalars['String']>;
  response: Scalars['String'];
  responseCode?: Maybe<Scalars['Float']>;
  rejectedErrors?: Maybe<Array<SmtpResponseRejectedErrorEntity>>;
};

export type SmtpResponseRejectedErrorEntity = {
  __typename?: 'SmtpResponseRejectedErrorEntity';
  response: Scalars['String'];
  responseCode: Scalars['Float'];
  command?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['String']>;
};

/** Mailbox status */
export enum Status {
  A = 'A',
  L = 'L'
}

export type Success = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};

/** Clause */
export enum ClauseEnum {
  Any = 'any',
  All = 'all'
}

/** Field Enum */
export enum FieldEnum {
  From = 'from',
  To = 'to',
  Cc = 'cc',
  Recipients = 'recipients',
  Subject = 'subject'
}

/** Operator Enum */
export enum OperatorEnum {
  Contains = 'contains',
  ContainsNot = 'containsNot'
}

/** Priority levels */
export enum PriorityEnum {
  High = 'high',
  Normal = 'normal',
  Low = 'low'
}

/** Mail Sort */
export enum Sort {
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  FromAsc = 'FROM_ASC',
  FromDesc = 'FROM_DESC',
  ArrivalAsc = 'ARRIVAL_ASC',
  ArrivalDesc = 'ARRIVAL_DESC',
  CcAsc = 'CC_ASC',
  CcDesc = 'CC_DESC',
  ToAsc = 'TO_ASC',
  ToDesc = 'TO_DESC',
  SubjectAsc = 'SUBJECT_ASC',
  SubjectDesc = 'SUBJECT_DESC',
  SizeAsc = 'SIZE_ASC',
  SizeDesc = 'SIZE_DESC',
  FlaggedAsc = 'FLAGGED_ASC',
  FlaggedDesc = 'FLAGGED_DESC',
  Read = 'READ',
  ReadDesc = 'READ_DESC'
}

/** Yes/No status */
export enum YesOrNoEnum {
  No = 'no',
  Yes = 'yes'
}

export type DeleteLabelMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteLabelMutation = (
  { __typename?: 'Mutation' }
  & { deleteLabel: (
    { __typename?: 'LabelDeleteStatus' }
    & Pick<LabelDeleteStatus, 'success' | 'totalContactsUpdated'>
  ) }
);

export type DeleteSignatureMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSignatureMutation = (
  { __typename?: 'Mutation' }
  & { deleteSignature: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type GetContactCollectionQueryVariables = Exact<{
  search?: Maybe<ContactSearchArg>;
  labelId?: Maybe<Scalars['String']>;
}>;


export type GetContactCollectionQuery = (
  { __typename?: 'Query' }
  & { getContactCollection: (
    { __typename?: 'ContactCollection' }
    & Pick<ContactCollection, 'total' | 'currentPage' | 'limit'>
    & { data: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'id' | 'firstName' | 'lastName' | 'displayName' | 'labels' | 'url' | 'profileImage' | 'nickName' | 'notes' | 'title' | 'middleName' | 'company' | 'dateOfBirth'>
      & { emails?: Maybe<Array<(
        { __typename?: 'ContactEmailEntity' }
        & Pick<ContactEmailEntity, 'emailAddress' | 'emailAddressType' | 'isDefault'>
      )>>, phoneNumbers?: Maybe<Array<(
        { __typename?: 'ContactPhoneEntity' }
        & Pick<ContactPhoneEntity, 'phoneNumber' | 'phoneNumberType' | 'isDefault'>
      )>>, addresses?: Maybe<Array<(
        { __typename?: 'ContactAddressEntity' }
        & Pick<ContactAddressEntity, 'addressType' | 'state' | 'city' | 'zipCode' | 'streetAddress'>
      )>> }
    )> }
  ) }
);

export type GetContactCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactCountQuery = (
  { __typename?: 'Query' }
  & { getContactCount: (
    { __typename?: 'Count' }
    & Pick<Count, 'count'>
  ) }
);

export type ExportContactQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type ExportContactQuery = (
  { __typename?: 'Query' }
  & { exportContact: (
    { __typename?: 'ContactExportForm' }
    & Pick<ContactExportForm, 'vCard'>
  ) }
);

export type GetContactReSyncAllowedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactReSyncAllowedQuery = (
  { __typename?: 'Query' }
  & { getContactReSyncAllowed: (
    { __typename?: 'ContactResyncAllowedEntity' }
    & Pick<ContactResyncAllowedEntity, 'lastReSyncDate' | 'isAllowed'>
  ) }
);

export type GetContactQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetContactQuery = (
  { __typename?: 'Query' }
  & { getContact: (
    { __typename?: 'ContactWithLabelEntity' }
    & Pick<ContactWithLabelEntity, 'id' | 'firstName' | 'lastName' | 'displayName' | 'url' | 'profileImage' | 'nickName' | 'notes' | 'title' | 'middleName' | 'company' | 'dateOfBirth' | 'createdAt' | 'updatedAt'>
    & { emails?: Maybe<Array<(
      { __typename?: 'ContactEmailEntity' }
      & Pick<ContactEmailEntity, 'emailAddress' | 'emailAddressType' | 'isDefault'>
    )>>, phoneNumbers?: Maybe<Array<(
      { __typename?: 'ContactPhoneEntity' }
      & Pick<ContactPhoneEntity, 'phoneNumber' | 'phoneNumberType' | 'isDefault'>
    )>>, addresses?: Maybe<Array<(
      { __typename?: 'ContactAddressEntity' }
      & Pick<ContactAddressEntity, 'addressType' | 'state' | 'city' | 'zipCode' | 'streetAddress' | 'country'>
    )>>, labels: Array<(
      { __typename?: 'LabelEntity' }
      & Pick<LabelEntity, 'id' | 'name'>
    )> }
  ) }
);

export type GetFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFiltersQuery = (
  { __typename?: 'Query' }
  & { getFilters: Array<(
    { __typename?: 'FilterEntity' }
    & Pick<FilterEntity, 'name' | 'clause' | 'highPriority' | 'actionPriority' | 'actionDelete' | 'actionForward' | 'actionMove' | 'moveFolder' | 'forwardList'>
    & { rules: Array<(
      { __typename?: 'FilterRulesEntity' }
      & Pick<FilterRulesEntity, 'operator' | 'value' | 'field'>
    )> }
  )> }
);

export type GetGlobalMailBoxStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGlobalMailBoxStatusQuery = (
  { __typename?: 'Query' }
  & { getGlobalMailboxStatus: Array<(
    { __typename?: 'MailboxStatusEntity' }
    & Pick<MailboxStatusEntity, 'id' | 'name' | 'totalMessageCount' | 'newMessageCount' | 'unseenMessageCount'>
  )> }
);

export type GetLabelCollectionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLabelCollectionQuery = (
  { __typename?: 'Query' }
  & { getLabelCollection: Array<(
    { __typename?: 'LabelEntity' }
    & Pick<LabelEntity, 'id' | 'name'>
  )> }
);

export type GetLdapSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLdapSettingQuery = (
  { __typename?: 'Query' }
  & { getLdapSetting: (
    { __typename?: 'LdapSettingEntity' }
    & { mailUserPreferences: (
      { __typename?: 'LdapMailPreferenceEntity' }
      & Pick<LdapMailPreferenceEntity, 'mailboxStatus' | 'mailBlockSendersActive' | 'asfDeliveryActionDirty' | 'mailAutoReplyMode' | 'mailForwarding' | 'mailForwardingAddress' | 'mailDeliveryOption'>
    ), mxos: (
      { __typename?: 'MxosMailReceiptEntity' }
      & Pick<MxosMailReceiptEntity, 'autoReplyMode' | 'autoReplyMessage'>
    ), senderControl: (
      { __typename?: 'LdapSenderControlEntity' }
      & Pick<LdapSenderControlEntity, 'approvedSenders' | 'blockedSenders'>
    ) }
  ) }
);

export type GetMailBoxCollectionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMailBoxCollectionQuery = (
  { __typename?: 'Query' }
  & { getMailboxCollection: Array<(
    { __typename?: 'MailboxEntity' }
    & Pick<MailboxEntity, 'id' | 'name' | 'delimiter'>
  )> }
);

export type GetMailBoxStatusQueryVariables = Exact<{
  mailboxId: Scalars['String'];
}>;


export type GetMailBoxStatusQuery = (
  { __typename?: 'Query' }
  & { getMailboxStatus: (
    { __typename?: 'MailboxStatusEntity' }
    & Pick<MailboxStatusEntity, 'id' | 'name' | 'totalMessageCount' | 'newMessageCount' | 'unseenMessageCount'>
  ) }
);

export type GetMessageCollectionQueryVariables = Exact<{
  mailboxId: Scalars['String'];
  query: MailSearchQueryForm;
}>;


export type GetMessageCollectionQuery = (
  { __typename?: 'Query' }
  & { getMessageCollection: (
    { __typename?: 'MessageCollection' }
    & Pick<MessageCollection, 'total' | 'currentPage' | 'limit'>
    & { data: Array<(
      { __typename?: 'MessageListEntity' }
      & Pick<MessageListEntity, 'subject' | 'seqNo' | 'date' | 'attachmentCount' | 'uid' | 'flags' | 'priority'>
      & { to?: Maybe<Array<(
        { __typename?: 'EmailAddressEntity' }
        & Pick<EmailAddressEntity, 'name' | 'address'>
      )>>, from?: Maybe<Array<(
        { __typename?: 'EmailAddressEntity' }
        & Pick<EmailAddressEntity, 'name' | 'address'>
      )>> }
    )> }
  ) }
);

export type GetMessageQueryVariables = Exact<{
  mailboxId: Scalars['String'];
  messageUid: Scalars['Float'];
  html: Scalars['Boolean'];
  noImageHtml: Scalars['Boolean'];
}>;


export type GetMessageQuery = (
  { __typename?: 'Query' }
  & { getMessage: (
    { __typename?: 'MessageEntity' }
    & Pick<MessageEntity, 'inReplyTo' | 'messageId' | 'references' | 'priority' | 'subject' | 'date' | 'flags' | 'uid'>
    & { from?: Maybe<Array<(
      { __typename?: 'EmailAddressEntity' }
      & Pick<EmailAddressEntity, 'name' | 'address'>
    )>>, to?: Maybe<Array<(
      { __typename?: 'EmailAddressEntity' }
      & Pick<EmailAddressEntity, 'address' | 'name'>
    )>>, cc?: Maybe<Array<(
      { __typename?: 'EmailAddressEntity' }
      & Pick<EmailAddressEntity, 'address' | 'name'>
    )>>, bcc?: Maybe<Array<(
      { __typename?: 'EmailAddressEntity' }
      & Pick<EmailAddressEntity, 'address' | 'name'>
    )>>, replyTo?: Maybe<Array<(
      { __typename?: 'EmailAddressEntity' }
      & Pick<EmailAddressEntity, 'name' | 'address'>
    )>>, body?: Maybe<(
      { __typename?: 'MessageBodyEntity' }
      & MakeOptional<Pick<MessageBodyEntity, 'html' | 'noImageHtml' | 'fullText'>, 'html' | 'noImageHtml'>
    )>, attachments?: Maybe<Array<(
      { __typename?: 'ImapAttachmentListEntity' }
      & Pick<ImapAttachmentListEntity, 'fileName' | 'fileSize' | 'id' | 'externalRef'>
    )>>, readReceipt?: Maybe<(
      { __typename?: 'ReadReceiptEntity' }
      & Pick<ReadReceiptEntity, 'readReceipt' | 'readReceiptReplyTo'>
    )> }
  ) }
);

export type GetOptInStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOptInStatusQuery = (
  { __typename?: 'Query' }
  & { getOptInStatus: (
    { __typename?: 'OptInResponse' }
    & { status?: Maybe<(
      { __typename?: 'OptIn' }
      & Pick<OptIn, 'optInStatus' | 'optInType' | 'email' | 'userId'>
    )>, eligibility?: Maybe<(
      { __typename?: 'EligibilityStatus' }
      & Pick<EligibilityStatus, 'isEligible'>
    )> }
  ) }
);

export type GetQuotaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuotaQuery = (
  { __typename?: 'Query' }
  & { getQuota: (
    { __typename?: 'QuotaEntity' }
    & Pick<QuotaEntity, 'usage' | 'limit'>
  ) }
);

export type GetRawMessageQueryVariables = Exact<{
  mailboxId: Scalars['String'];
  messageUid: Scalars['Float'];
}>;


export type GetRawMessageQuery = (
  { __typename?: 'Query' }
  & { getRawMessage: (
    { __typename?: 'MessageRawEntity' }
    & Pick<MessageRawEntity, 'uid' | 'raw'>
  ) }
);

export type GetSignatureCollectionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSignatureCollectionQuery = (
  { __typename?: 'Query' }
  & { getSignatureCollection: Array<(
    { __typename?: 'SignatureEntity' }
    & Pick<SignatureEntity, 'id' | 'title' | 'signature' | 'autoInsert'>
  )> }
);

export type GetSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSettingQuery = (
  { __typename?: 'Query' }
  & { getSetting: (
    { __typename?: 'SettingEntity' }
    & Pick<SettingEntity, 'displayName' | 'timeZone' | 'emailPerPage' | 'replyTo' | 'preferredLanguage' | 'isAutoSaveDraftEnabled' | 'isAutoMailboxReloadEnabled' | 'isDisplayImageEnabled' | 'isDisplayEmailAsHtmlEnabled' | 'isEmailPermanentlyDeleted'>
  ) }
);

export type PatchEmailPerPageMutationVariables = Exact<{
  value: Scalars['String'];
}>;


export type PatchEmailPerPageMutation = (
  { __typename?: 'Mutation' }
  & { patchEmailPerPage: (
    { __typename?: 'SettingEntity' }
    & Pick<SettingEntity, 'emailPerPage'>
  ) }
);

export type PatchReplyToMutationVariables = Exact<{
  value: Scalars['String'];
}>;


export type PatchReplyToMutation = (
  { __typename?: 'Mutation' }
  & { patchReplyTo: (
    { __typename?: 'SettingEntity' }
    & Pick<SettingEntity, 'replyTo'>
  ) }
);

export type UpdateAutoInsertSignatureMutationVariables = Exact<{
  id: Scalars['String'];
  value: Scalars['String'];
}>;


export type UpdateAutoInsertSignatureMutation = (
  { __typename?: 'Mutation' }
  & { patchAutoInsertSignature: (
    { __typename?: 'SignatureEntity' }
    & Pick<SignatureEntity, 'id' | 'title' | 'signature' | 'autoInsert' | 'createdAt' | 'updatedAt'>
  ) }
);

export type PostOptInStatusMutationVariables = Exact<{
  optInType: Scalars['String'];
  isOptInYes: Scalars['Boolean'];
}>;


export type PostOptInStatusMutation = (
  { __typename?: 'Mutation' }
  & { postOptInStatus: (
    { __typename?: 'OptIn' }
    & Pick<OptIn, 'email' | 'optInStatus' | 'optInType'>
  ) }
);

export type ContactMigrationMutationVariables = Exact<{ [key: string]: never; }>;


export type ContactMigrationMutation = (
  { __typename?: 'Mutation' }
  & { contactMigration: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type ReSyncContactMutationVariables = Exact<{ [key: string]: never; }>;


export type ReSyncContactMutation = (
  { __typename?: 'Mutation' }
  & { reSyncContact: (
    { __typename?: 'ContactReSyncEntity' }
    & Pick<ContactReSyncEntity, 'success' | 'successContactCount' | 'successLabelCount' | 'errorContactCount' | 'errorLabelCount'>
  ) }
);

export type ContactRollbackMutationVariables = Exact<{ [key: string]: never; }>;


export type ContactRollbackMutation = (
  { __typename?: 'Mutation' }
  & { contactRollback: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type CreateBulkContactMutationVariables = Exact<{
  contacts: Array<ContactForm> | ContactForm;
}>;


export type CreateBulkContactMutation = (
  { __typename?: 'Mutation' }
  & { createBulkContact: (
    { __typename?: 'ContactBulkCreateEntity' }
    & Pick<ContactBulkCreateEntity, 'success' | 'createCount'>
  ) }
);

export type CreateContactMutationVariables = Exact<{
  contact: ContactForm;
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact: (
    { __typename?: 'ContactWithLabelEntity' }
    & Pick<ContactWithLabelEntity, 'id' | 'title' | 'firstName' | 'middleName' | 'lastName' | 'displayName' | 'nickName' | 'company' | 'url' | 'dateOfBirth' | 'notes' | 'profileImage'>
    & { emails?: Maybe<Array<(
      { __typename?: 'ContactEmailEntity' }
      & Pick<ContactEmailEntity, 'emailAddress' | 'emailAddressType' | 'isDefault'>
    )>>, phoneNumbers?: Maybe<Array<(
      { __typename?: 'ContactPhoneEntity' }
      & Pick<ContactPhoneEntity, 'phoneNumber' | 'phoneNumberType' | 'isDefault'>
    )>>, addresses?: Maybe<Array<(
      { __typename?: 'ContactAddressEntity' }
      & Pick<ContactAddressEntity, 'addressType' | 'state' | 'city' | 'zipCode' | 'streetAddress' | 'country'>
    )>>, labels: Array<(
      { __typename?: 'LabelEntity' }
      & Pick<LabelEntity, 'name' | 'id'>
    )> }
  ) }
);

export type CreateFilterMutationVariables = Exact<{
  filters: Array<FilterForm> | FilterForm;
}>;


export type CreateFilterMutation = (
  { __typename?: 'Mutation' }
  & { updateFilters: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type CreateLabelMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateLabelMutation = (
  { __typename?: 'Mutation' }
  & { createLabel: (
    { __typename?: 'LabelEntity' }
    & Pick<LabelEntity, 'id' | 'name'>
  ) }
);

export type CreateMailboxMutationVariables = Exact<{
  mailbox: MailboxForm;
}>;


export type CreateMailboxMutation = (
  { __typename?: 'Mutation' }
  & { createMailbox: (
    { __typename?: 'MailboxEntity' }
    & Pick<MailboxEntity, 'id' | 'delimiter' | 'name'>
  ) }
);

export type CreateSignatureMutationVariables = Exact<{
  signature: Scalars['String'];
  autoInsert: Scalars['Boolean'];
}>;


export type CreateSignatureMutation = (
  { __typename?: 'Mutation' }
  & { createSignature: (
    { __typename?: 'SignatureEntity' }
    & Pick<SignatureEntity, 'id' | 'title' | 'signature' | 'autoInsert' | 'createdAt' | 'updatedAt'>
  ) }
);

export type DeleteBulkContactMutationVariables = Exact<{
  id: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteBulkContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteBulkContact: (
    { __typename?: 'ContactBulkDeleteEntity' }
    & Pick<ContactBulkDeleteEntity, 'success' | 'deleteCount'>
  ) }
);

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteContact: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type DeleteFilterMutationVariables = Exact<{
  filters: Array<FilterForm> | FilterForm;
}>;


export type DeleteFilterMutation = (
  { __typename?: 'Mutation' }
  & { updateFilters: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type DeleteMailboxMutationVariables = Exact<{
  mailboxId: Scalars['String'];
  deleteMessage: Scalars['Boolean'];
}>;


export type DeleteMailboxMutation = (
  { __typename?: 'Mutation' }
  & { deleteMailbox: (
    { __typename?: 'MailboxDeleteEntity' }
    & { mailboxDeleteStatus: Array<(
      { __typename?: 'MailboxDeleteStatusEntity' }
      & Pick<MailboxDeleteStatusEntity, 'mailbox' | 'success' | 'error' | 'isGlobal'>
    )> }
  ) }
);

export type DeleteMessageMutationVariables = Exact<{
  messageUid: Scalars['Float'];
  mailboxId: Scalars['String'];
}>;


export type DeleteMessageMutation = (
  { __typename?: 'Mutation' }
  & { deleteMessage: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type DeleteStorageMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteStorageMutation = (
  { __typename?: 'Mutation' }
  & { deleteStorage: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type PostMarkMessagesAsFlagMutationVariables = Exact<{
  messageUids: Array<Scalars['Float']> | Scalars['Float'];
  mailboxId: Scalars['String'];
}>;


export type PostMarkMessagesAsFlagMutation = (
  { __typename?: 'Mutation' }
  & { markMessagesImportant: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type PostMarkMessagesAsSeenMutationVariables = Exact<{
  mailboxId: Scalars['String'];
  messageUids: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type PostMarkMessagesAsSeenMutation = (
  { __typename?: 'Mutation' }
  & { markMessagesSeen: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type PostMarkMessagesAsUnflagMutationVariables = Exact<{
  messageUids: Array<Scalars['Float']> | Scalars['Float'];
  mailboxId: Scalars['String'];
}>;


export type PostMarkMessagesAsUnflagMutation = (
  { __typename?: 'Mutation' }
  & { markMessagesUnimportant: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type PostMarkMessagesAsUnseenMutationVariables = Exact<{
  mailboxId: Scalars['String'];
  messageUids: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type PostMarkMessagesAsUnseenMutation = (
  { __typename?: 'Mutation' }
  & { markMessagesUnseen: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type PatchBulkContactLabelMutationVariables = Exact<{
  contacts: Array<Scalars['String']> | Scalars['String'];
  labels: Array<Scalars['String']> | Scalars['String'];
}>;


export type PatchBulkContactLabelMutation = (
  { __typename?: 'Mutation' }
  & { putBulkContactLabel: (
    { __typename?: 'ContactBulkUpdateEntity' }
    & Pick<ContactBulkUpdateEntity, 'success'>
  ) }
);

export type PatchContactLabelMutationVariables = Exact<{
  id: Scalars['String'];
  labels: Array<Scalars['String']> | Scalars['String'];
}>;


export type PatchContactLabelMutation = (
  { __typename?: 'Mutation' }
  & { patchContactLabel: (
    { __typename?: 'ContactWithLabelEntity' }
    & Pick<ContactWithLabelEntity, 'id' | 'firstName' | 'lastName' | 'displayName' | 'url' | 'profileImage' | 'nickName' | 'notes' | 'title' | 'middleName' | 'company' | 'dateOfBirth' | 'createdAt' | 'updatedAt'>
    & { emails?: Maybe<Array<(
      { __typename?: 'ContactEmailEntity' }
      & Pick<ContactEmailEntity, 'emailAddress' | 'emailAddressType' | 'isDefault'>
    )>>, phoneNumbers?: Maybe<Array<(
      { __typename?: 'ContactPhoneEntity' }
      & Pick<ContactPhoneEntity, 'phoneNumber' | 'phoneNumberType' | 'isDefault'>
    )>>, addresses?: Maybe<Array<(
      { __typename?: 'ContactAddressEntity' }
      & Pick<ContactAddressEntity, 'addressType' | 'state' | 'city' | 'zipCode' | 'streetAddress' | 'country'>
    )>>, labels: Array<(
      { __typename?: 'LabelEntity' }
      & Pick<LabelEntity, 'id' | 'name'>
    )> }
  ) }
);

export type MoveMessagesToNotSpamMutationVariables = Exact<{
  sourceMailboxId: Scalars['String'];
  destinationMailboxId: Scalars['String'];
  messageUids: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type MoveMessagesToNotSpamMutation = (
  { __typename?: 'Mutation' }
  & { moveMessages: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type MoveMessagesToSpamMutationVariables = Exact<{
  sourceMailboxId: Scalars['String'];
  destinationMailboxId: Scalars['String'];
  messageUids: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type MoveMessagesToSpamMutation = (
  { __typename?: 'Mutation' }
  & { moveMessages: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type MoveMessagesToTrashMutationVariables = Exact<{
  sourceMailboxId: Scalars['String'];
  destinationMailboxId: Scalars['String'];
  messageUids: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type MoveMessagesToTrashMutation = (
  { __typename?: 'Mutation' }
  & { moveMessages: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type MoveMessagesMutationVariables = Exact<{
  sourceMailboxId: Scalars['String'];
  destinationMailboxId: Scalars['String'];
  messageUids: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type MoveMessagesMutation = (
  { __typename?: 'Mutation' }
  & { moveMessages: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type PermanentDeleteMessageBulkMutationVariables = Exact<{
  mailboxId: Scalars['String'];
  messageUids: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type PermanentDeleteMessageBulkMutation = (
  { __typename?: 'Mutation' }
  & { deleteMessageBulk: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type RenameMailboxMutationVariables = Exact<{
  mailbox: MailboxForm;
  oldMailboxId: Scalars['String'];
}>;


export type RenameMailboxMutation = (
  { __typename?: 'Mutation' }
  & { renameMailbox: (
    { __typename?: 'MailboxEntity' }
    & Pick<MailboxEntity, 'id' | 'delimiter' | 'name'>
  ) }
);

export type SaveDraftMutationVariables = Exact<{
  mail: MailForm;
  displayName?: Maybe<Scalars['String']>;
}>;


export type SaveDraftMutation = (
  { __typename?: 'Mutation' }
  & { saveDraft: (
    { __typename?: 'MessageAppendResponseEntity' }
    & Pick<MessageAppendResponseEntity, 'isSavedToImap' | 'isSentToSmtp' | 'uid'>
  ) }
);

export type SentMailMutationVariables = Exact<{
  mail: MailForm;
  displayName?: Maybe<Scalars['String']>;
}>;


export type SentMailMutation = (
  { __typename?: 'Mutation' }
  & { sentMail: (
    { __typename?: 'MessageAppendResponseEntity' }
    & Pick<MessageAppendResponseEntity, 'isSavedToImap' | 'isSentToSmtp' | 'uid'>
    & { smtpResponse?: Maybe<(
      { __typename?: 'SmtpResponseEntity' }
      & Pick<SmtpResponseEntity, 'messageId' | 'response' | 'responseCode'>
      & { rejectedErrors?: Maybe<Array<(
        { __typename?: 'SmtpResponseRejectedErrorEntity' }
        & Pick<SmtpResponseRejectedErrorEntity, 'command' | 'recipient' | 'response' | 'responseCode'>
      )>> }
    )> }
  ) }
);

export type SettingMigrationMutationVariables = Exact<{ [key: string]: never; }>;


export type SettingMigrationMutation = (
  { __typename?: 'Mutation' }
  & { settingMigration: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type SettingRollbackMutationVariables = Exact<{ [key: string]: never; }>;


export type SettingRollbackMutation = (
  { __typename?: 'Mutation' }
  & { settingRollback: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type UpdateApprovedSenderMutationVariables = Exact<{
  approvedSender: LdapApprovedSenderForm;
}>;


export type UpdateApprovedSenderMutation = (
  { __typename?: 'Mutation' }
  & { updateApprovedSender: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type UpdateAutoReplyMessageMutationVariables = Exact<{
  isVacationModeEnabled: Scalars['Boolean'];
  autoReplyMessage?: Maybe<Scalars['String']>;
}>;


export type UpdateAutoReplyMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateAutoReply: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type UpdateAutoReplyToggleMutationVariables = Exact<{
  isVacationModeEnabled: Scalars['Boolean'];
  autoReplyMessage?: Maybe<Scalars['String']>;
}>;


export type UpdateAutoReplyToggleMutation = (
  { __typename?: 'Mutation' }
  & { updateAutoReply: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type UpdateBlockedSenderMutationVariables = Exact<{
  blockedSender: LdapBlockedSenderForm;
}>;


export type UpdateBlockedSenderMutation = (
  { __typename?: 'Mutation' }
  & { updateBlockedSender: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['String'];
  contact: ContactForm;
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact: (
    { __typename?: 'ContactWithLabelEntity' }
    & Pick<ContactWithLabelEntity, 'id' | 'title' | 'firstName' | 'middleName' | 'lastName' | 'displayName' | 'nickName' | 'company' | 'url' | 'dateOfBirth' | 'notes' | 'profileImage'>
    & { emails?: Maybe<Array<(
      { __typename?: 'ContactEmailEntity' }
      & Pick<ContactEmailEntity, 'emailAddress' | 'emailAddressType' | 'isDefault'>
    )>>, phoneNumbers?: Maybe<Array<(
      { __typename?: 'ContactPhoneEntity' }
      & Pick<ContactPhoneEntity, 'phoneNumber' | 'phoneNumberType' | 'isDefault'>
    )>>, addresses?: Maybe<Array<(
      { __typename?: 'ContactAddressEntity' }
      & Pick<ContactAddressEntity, 'addressType' | 'state' | 'city' | 'zipCode' | 'streetAddress' | 'country'>
    )>>, labels: Array<(
      { __typename?: 'LabelEntity' }
      & Pick<LabelEntity, 'id' | 'name'>
    )> }
  ) }
);

export type UpdateDisplayNameMutationVariables = Exact<{
  value: Scalars['String'];
}>;


export type UpdateDisplayNameMutation = (
  { __typename?: 'Mutation' }
  & { patchDisplayName: (
    { __typename?: 'SettingEntity' }
    & Pick<SettingEntity, 'displayName'>
  ) }
);

export type UpdateFiltersMutationVariables = Exact<{
  filters: Array<FilterForm> | FilterForm;
}>;


export type UpdateFiltersMutation = (
  { __typename?: 'Mutation' }
  & { updateFilters: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type UpdateLabelMutationVariables = Exact<{
  id: Scalars['String'];
  label: LabelForm;
}>;


export type UpdateLabelMutation = (
  { __typename?: 'Mutation' }
  & { updateLabel: (
    { __typename?: 'LabelEntity' }
    & Pick<LabelEntity, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateMailForwardingMutationVariables = Exact<{
  mailForwarding: Scalars['Boolean'];
  mailForwardingAddress: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateMailForwardingMutation = (
  { __typename?: 'Mutation' }
  & { updateMailForwarding: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type PatchSettingMutationVariables = Exact<{
  field: Scalars['String'];
  value: Scalars['String'];
}>;


export type PatchSettingMutation = (
  { __typename?: 'Mutation' }
  & { patchSetting: (
    { __typename?: 'SettingEntity' }
    & Pick<SettingEntity, 'displayName' | 'replyTo' | 'preferredLanguage' | 'emailPerPage' | 'timeZone' | 'isDisplayImageEnabled' | 'isAutoSaveDraftEnabled' | 'isEmailPermanentlyDeleted' | 'isAutoMailboxReloadEnabled' | 'isDisplayEmailAsHtmlEnabled' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateSignatureMutationVariables = Exact<{
  id: Scalars['String'];
  signature: SignatureForm;
}>;


export type UpdateSignatureMutation = (
  { __typename?: 'Mutation' }
  & { updateSignature: (
    { __typename?: 'SignatureEntity' }
    & Pick<SignatureEntity, 'signature' | 'autoInsert' | 'id'>
  ) }
);

export type UpdateSpamSettingMutationVariables = Exact<{
  spamSetting: Scalars['String'];
}>;


export type UpdateSpamSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateSpamSetting: (
    { __typename?: 'Success' }
    & Pick<Success, 'success'>
  ) }
);

export type PatchTimeZoneMutationVariables = Exact<{
  value: Scalars['String'];
}>;


export type PatchTimeZoneMutation = (
  { __typename?: 'Mutation' }
  & { patchTimeZone: (
    { __typename?: 'SettingEntity' }
    & Pick<SettingEntity, 'timeZone'>
  ) }
);


export const DeleteLabelDocument = gql`
    mutation DeleteLabel($id: String!) {
  deleteLabel(id: $id) {
    success
    totalContactsUpdated
  }
}
    `;
export type DeleteLabelMutationFn = Apollo.MutationFunction<DeleteLabelMutation, DeleteLabelMutationVariables>;

/**
 * __useDeleteLabelMutation__
 *
 * To run a mutation, you first call `useDeleteLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLabelMutation, { data, loading, error }] = useDeleteLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLabelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLabelMutation, DeleteLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLabelMutation, DeleteLabelMutationVariables>(DeleteLabelDocument, options);
      }
export type DeleteLabelMutationHookResult = ReturnType<typeof useDeleteLabelMutation>;
export type DeleteLabelMutationResult = Apollo.MutationResult<DeleteLabelMutation>;
export type DeleteLabelMutationOptions = Apollo.BaseMutationOptions<DeleteLabelMutation, DeleteLabelMutationVariables>;
export const DeleteSignatureDocument = gql`
    mutation DeleteSignature($id: String!) {
  deleteSignature(id: $id) {
    success
  }
}
    `;
export type DeleteSignatureMutationFn = Apollo.MutationFunction<DeleteSignatureMutation, DeleteSignatureMutationVariables>;

/**
 * __useDeleteSignatureMutation__
 *
 * To run a mutation, you first call `useDeleteSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSignatureMutation, { data, loading, error }] = useDeleteSignatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSignatureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSignatureMutation, DeleteSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSignatureMutation, DeleteSignatureMutationVariables>(DeleteSignatureDocument, options);
      }
export type DeleteSignatureMutationHookResult = ReturnType<typeof useDeleteSignatureMutation>;
export type DeleteSignatureMutationResult = Apollo.MutationResult<DeleteSignatureMutation>;
export type DeleteSignatureMutationOptions = Apollo.BaseMutationOptions<DeleteSignatureMutation, DeleteSignatureMutationVariables>;
export const GetContactCollectionDocument = gql`
    query GetContactCollection($search: ContactSearchArg, $labelId: String) {
  getContactCollection(search: $search, labelId: $labelId) {
    data {
      id
      firstName
      lastName
      displayName
      emails {
        emailAddress
        emailAddressType
        isDefault
      }
      phoneNumbers {
        phoneNumber
        phoneNumberType
        isDefault
      }
      addresses {
        addressType
        state
        city
        zipCode
        streetAddress
      }
      labels
      url
      profileImage
      nickName
      notes
      title
      middleName
      company
      dateOfBirth
    }
    total
    currentPage
    limit
  }
}
    `;

/**
 * __useGetContactCollectionQuery__
 *
 * To run a query within a React component, call `useGetContactCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactCollectionQuery({
 *   variables: {
 *      search: // value for 'search'
 *      labelId: // value for 'labelId'
 *   },
 * });
 */
export function useGetContactCollectionQuery(baseOptions?: Apollo.QueryHookOptions<GetContactCollectionQuery, GetContactCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactCollectionQuery, GetContactCollectionQueryVariables>(GetContactCollectionDocument, options);
      }
export function useGetContactCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactCollectionQuery, GetContactCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactCollectionQuery, GetContactCollectionQueryVariables>(GetContactCollectionDocument, options);
        }
export type GetContactCollectionQueryHookResult = ReturnType<typeof useGetContactCollectionQuery>;
export type GetContactCollectionLazyQueryHookResult = ReturnType<typeof useGetContactCollectionLazyQuery>;
export type GetContactCollectionQueryResult = Apollo.QueryResult<GetContactCollectionQuery, GetContactCollectionQueryVariables>;
export const GetContactCountDocument = gql`
    query GetContactCount {
  getContactCount {
    count
  }
}
    `;

/**
 * __useGetContactCountQuery__
 *
 * To run a query within a React component, call `useGetContactCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactCountQuery(baseOptions?: Apollo.QueryHookOptions<GetContactCountQuery, GetContactCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactCountQuery, GetContactCountQueryVariables>(GetContactCountDocument, options);
      }
export function useGetContactCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactCountQuery, GetContactCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactCountQuery, GetContactCountQueryVariables>(GetContactCountDocument, options);
        }
export type GetContactCountQueryHookResult = ReturnType<typeof useGetContactCountQuery>;
export type GetContactCountLazyQueryHookResult = ReturnType<typeof useGetContactCountLazyQuery>;
export type GetContactCountQueryResult = Apollo.QueryResult<GetContactCountQuery, GetContactCountQueryVariables>;
export const ExportContactDocument = gql`
    query ExportContact($ids: [String!]!) {
  exportContact(ids: $ids) {
    vCard
  }
}
    `;

/**
 * __useExportContactQuery__
 *
 * To run a query within a React component, call `useExportContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportContactQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useExportContactQuery(baseOptions: Apollo.QueryHookOptions<ExportContactQuery, ExportContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportContactQuery, ExportContactQueryVariables>(ExportContactDocument, options);
      }
export function useExportContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportContactQuery, ExportContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportContactQuery, ExportContactQueryVariables>(ExportContactDocument, options);
        }
export type ExportContactQueryHookResult = ReturnType<typeof useExportContactQuery>;
export type ExportContactLazyQueryHookResult = ReturnType<typeof useExportContactLazyQuery>;
export type ExportContactQueryResult = Apollo.QueryResult<ExportContactQuery, ExportContactQueryVariables>;
export const GetContactReSyncAllowedDocument = gql`
    query GetContactReSyncAllowed {
  getContactReSyncAllowed {
    lastReSyncDate
    isAllowed
  }
}
    `;

/**
 * __useGetContactReSyncAllowedQuery__
 *
 * To run a query within a React component, call `useGetContactReSyncAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactReSyncAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactReSyncAllowedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactReSyncAllowedQuery(baseOptions?: Apollo.QueryHookOptions<GetContactReSyncAllowedQuery, GetContactReSyncAllowedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactReSyncAllowedQuery, GetContactReSyncAllowedQueryVariables>(GetContactReSyncAllowedDocument, options);
      }
export function useGetContactReSyncAllowedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactReSyncAllowedQuery, GetContactReSyncAllowedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactReSyncAllowedQuery, GetContactReSyncAllowedQueryVariables>(GetContactReSyncAllowedDocument, options);
        }
export type GetContactReSyncAllowedQueryHookResult = ReturnType<typeof useGetContactReSyncAllowedQuery>;
export type GetContactReSyncAllowedLazyQueryHookResult = ReturnType<typeof useGetContactReSyncAllowedLazyQuery>;
export type GetContactReSyncAllowedQueryResult = Apollo.QueryResult<GetContactReSyncAllowedQuery, GetContactReSyncAllowedQueryVariables>;
export const GetContactDocument = gql`
    query GetContact($id: String!) {
  getContact(id: $id) {
    id
    firstName
    lastName
    displayName
    emails {
      emailAddress
      emailAddressType
      isDefault
    }
    phoneNumbers {
      phoneNumber
      phoneNumberType
      isDefault
    }
    addresses {
      addressType
      state
      city
      zipCode
      streetAddress
      country
    }
    labels {
      id
      name
    }
    url
    profileImage
    nickName
    notes
    title
    middleName
    company
    dateOfBirth
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactQuery(baseOptions: Apollo.QueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
      }
export function useGetContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
        }
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
export type GetContactQueryResult = Apollo.QueryResult<GetContactQuery, GetContactQueryVariables>;
export const GetFiltersDocument = gql`
    query GetFilters {
  getFilters {
    name
    rules {
      operator
      value
      field
    }
    clause
    highPriority
    actionPriority
    actionDelete
    actionForward
    actionMove
    moveFolder
    forwardList
  }
}
    `;

/**
 * __useGetFiltersQuery__
 *
 * To run a query within a React component, call `useGetFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFiltersQuery(baseOptions?: Apollo.QueryHookOptions<GetFiltersQuery, GetFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFiltersQuery, GetFiltersQueryVariables>(GetFiltersDocument, options);
      }
export function useGetFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFiltersQuery, GetFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFiltersQuery, GetFiltersQueryVariables>(GetFiltersDocument, options);
        }
export type GetFiltersQueryHookResult = ReturnType<typeof useGetFiltersQuery>;
export type GetFiltersLazyQueryHookResult = ReturnType<typeof useGetFiltersLazyQuery>;
export type GetFiltersQueryResult = Apollo.QueryResult<GetFiltersQuery, GetFiltersQueryVariables>;
export const GetGlobalMailBoxStatusDocument = gql`
    query GetGlobalMailBoxStatus {
  getGlobalMailboxStatus {
    id
    name
    totalMessageCount
    newMessageCount
    unseenMessageCount
  }
}
    `;

/**
 * __useGetGlobalMailBoxStatusQuery__
 *
 * To run a query within a React component, call `useGetGlobalMailBoxStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalMailBoxStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalMailBoxStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalMailBoxStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetGlobalMailBoxStatusQuery, GetGlobalMailBoxStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlobalMailBoxStatusQuery, GetGlobalMailBoxStatusQueryVariables>(GetGlobalMailBoxStatusDocument, options);
      }
export function useGetGlobalMailBoxStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalMailBoxStatusQuery, GetGlobalMailBoxStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlobalMailBoxStatusQuery, GetGlobalMailBoxStatusQueryVariables>(GetGlobalMailBoxStatusDocument, options);
        }
export type GetGlobalMailBoxStatusQueryHookResult = ReturnType<typeof useGetGlobalMailBoxStatusQuery>;
export type GetGlobalMailBoxStatusLazyQueryHookResult = ReturnType<typeof useGetGlobalMailBoxStatusLazyQuery>;
export type GetGlobalMailBoxStatusQueryResult = Apollo.QueryResult<GetGlobalMailBoxStatusQuery, GetGlobalMailBoxStatusQueryVariables>;
export const GetLabelCollectionDocument = gql`
    query GetLabelCollection {
  getLabelCollection {
    id
    name
  }
}
    `;

/**
 * __useGetLabelCollectionQuery__
 *
 * To run a query within a React component, call `useGetLabelCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelCollectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLabelCollectionQuery(baseOptions?: Apollo.QueryHookOptions<GetLabelCollectionQuery, GetLabelCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLabelCollectionQuery, GetLabelCollectionQueryVariables>(GetLabelCollectionDocument, options);
      }
export function useGetLabelCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabelCollectionQuery, GetLabelCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLabelCollectionQuery, GetLabelCollectionQueryVariables>(GetLabelCollectionDocument, options);
        }
export type GetLabelCollectionQueryHookResult = ReturnType<typeof useGetLabelCollectionQuery>;
export type GetLabelCollectionLazyQueryHookResult = ReturnType<typeof useGetLabelCollectionLazyQuery>;
export type GetLabelCollectionQueryResult = Apollo.QueryResult<GetLabelCollectionQuery, GetLabelCollectionQueryVariables>;
export const GetLdapSettingDocument = gql`
    query GetLdapSetting {
  getLdapSetting {
    mailUserPreferences {
      mailboxStatus
      mailBlockSendersActive
      asfDeliveryActionDirty
      mailAutoReplyMode
      mailForwarding
      mailForwardingAddress
      mailDeliveryOption
    }
    mxos {
      autoReplyMode
      autoReplyMessage
    }
    senderControl {
      approvedSenders
      blockedSenders
    }
  }
}
    `;

/**
 * __useGetLdapSettingQuery__
 *
 * To run a query within a React component, call `useGetLdapSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLdapSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLdapSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLdapSettingQuery(baseOptions?: Apollo.QueryHookOptions<GetLdapSettingQuery, GetLdapSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLdapSettingQuery, GetLdapSettingQueryVariables>(GetLdapSettingDocument, options);
      }
export function useGetLdapSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLdapSettingQuery, GetLdapSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLdapSettingQuery, GetLdapSettingQueryVariables>(GetLdapSettingDocument, options);
        }
export type GetLdapSettingQueryHookResult = ReturnType<typeof useGetLdapSettingQuery>;
export type GetLdapSettingLazyQueryHookResult = ReturnType<typeof useGetLdapSettingLazyQuery>;
export type GetLdapSettingQueryResult = Apollo.QueryResult<GetLdapSettingQuery, GetLdapSettingQueryVariables>;
export const GetMailBoxCollectionDocument = gql`
    query GetMailBoxCollection {
  getMailboxCollection {
    id
    name
    delimiter
  }
}
    `;

/**
 * __useGetMailBoxCollectionQuery__
 *
 * To run a query within a React component, call `useGetMailBoxCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMailBoxCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMailBoxCollectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMailBoxCollectionQuery(baseOptions?: Apollo.QueryHookOptions<GetMailBoxCollectionQuery, GetMailBoxCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMailBoxCollectionQuery, GetMailBoxCollectionQueryVariables>(GetMailBoxCollectionDocument, options);
      }
export function useGetMailBoxCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMailBoxCollectionQuery, GetMailBoxCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMailBoxCollectionQuery, GetMailBoxCollectionQueryVariables>(GetMailBoxCollectionDocument, options);
        }
export type GetMailBoxCollectionQueryHookResult = ReturnType<typeof useGetMailBoxCollectionQuery>;
export type GetMailBoxCollectionLazyQueryHookResult = ReturnType<typeof useGetMailBoxCollectionLazyQuery>;
export type GetMailBoxCollectionQueryResult = Apollo.QueryResult<GetMailBoxCollectionQuery, GetMailBoxCollectionQueryVariables>;
export const GetMailBoxStatusDocument = gql`
    query GetMailBoxStatus($mailboxId: String!) {
  getMailboxStatus(mailboxId: $mailboxId) {
    id
    name
    totalMessageCount
    newMessageCount
    unseenMessageCount
  }
}
    `;

/**
 * __useGetMailBoxStatusQuery__
 *
 * To run a query within a React component, call `useGetMailBoxStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMailBoxStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMailBoxStatusQuery({
 *   variables: {
 *      mailboxId: // value for 'mailboxId'
 *   },
 * });
 */
export function useGetMailBoxStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMailBoxStatusQuery, GetMailBoxStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMailBoxStatusQuery, GetMailBoxStatusQueryVariables>(GetMailBoxStatusDocument, options);
      }
export function useGetMailBoxStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMailBoxStatusQuery, GetMailBoxStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMailBoxStatusQuery, GetMailBoxStatusQueryVariables>(GetMailBoxStatusDocument, options);
        }
export type GetMailBoxStatusQueryHookResult = ReturnType<typeof useGetMailBoxStatusQuery>;
export type GetMailBoxStatusLazyQueryHookResult = ReturnType<typeof useGetMailBoxStatusLazyQuery>;
export type GetMailBoxStatusQueryResult = Apollo.QueryResult<GetMailBoxStatusQuery, GetMailBoxStatusQueryVariables>;
export const GetMessageCollectionDocument = gql`
    query GetMessageCollection($mailboxId: String!, $query: MailSearchQueryForm!) {
  getMessageCollection(mailboxId: $mailboxId, query: $query) {
    total
    currentPage
    limit
    data {
      to {
        name
        address
      }
      from {
        name
        address
      }
      subject
      seqNo
      date
      attachmentCount
      uid
      flags
      priority
    }
  }
}
    `;

/**
 * __useGetMessageCollectionQuery__
 *
 * To run a query within a React component, call `useGetMessageCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageCollectionQuery({
 *   variables: {
 *      mailboxId: // value for 'mailboxId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetMessageCollectionQuery(baseOptions: Apollo.QueryHookOptions<GetMessageCollectionQuery, GetMessageCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageCollectionQuery, GetMessageCollectionQueryVariables>(GetMessageCollectionDocument, options);
      }
export function useGetMessageCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageCollectionQuery, GetMessageCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageCollectionQuery, GetMessageCollectionQueryVariables>(GetMessageCollectionDocument, options);
        }
export type GetMessageCollectionQueryHookResult = ReturnType<typeof useGetMessageCollectionQuery>;
export type GetMessageCollectionLazyQueryHookResult = ReturnType<typeof useGetMessageCollectionLazyQuery>;
export type GetMessageCollectionQueryResult = Apollo.QueryResult<GetMessageCollectionQuery, GetMessageCollectionQueryVariables>;
export const GetMessageDocument = gql`
    query GetMessage($mailboxId: String!, $messageUid: Float!, $html: Boolean!, $noImageHtml: Boolean!) {
  getMessage(mailboxId: $mailboxId, messageUid: $messageUid) {
    from {
      name
      address
    }
    to {
      address
      name
    }
    cc {
      address
      name
    }
    bcc {
      address
      name
    }
    replyTo {
      name
      address
    }
    inReplyTo
    messageId
    references
    priority
    subject
    body {
      html @include(if: $html)
      noImageHtml @include(if: $noImageHtml)
      fullText
    }
    date
    attachments {
      fileName
      fileSize
      id
      externalRef
    }
    flags
    uid
    readReceipt {
      readReceipt
      readReceiptReplyTo
    }
  }
}
    `;

/**
 * __useGetMessageQuery__
 *
 * To run a query within a React component, call `useGetMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageQuery({
 *   variables: {
 *      mailboxId: // value for 'mailboxId'
 *      messageUid: // value for 'messageUid'
 *      html: // value for 'html'
 *      noImageHtml: // value for 'noImageHtml'
 *   },
 * });
 */
export function useGetMessageQuery(baseOptions: Apollo.QueryHookOptions<GetMessageQuery, GetMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageQuery, GetMessageQueryVariables>(GetMessageDocument, options);
      }
export function useGetMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageQuery, GetMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageQuery, GetMessageQueryVariables>(GetMessageDocument, options);
        }
export type GetMessageQueryHookResult = ReturnType<typeof useGetMessageQuery>;
export type GetMessageLazyQueryHookResult = ReturnType<typeof useGetMessageLazyQuery>;
export type GetMessageQueryResult = Apollo.QueryResult<GetMessageQuery, GetMessageQueryVariables>;
export const GetOptInStatusDocument = gql`
    query GetOptInStatus {
  getOptInStatus {
    status {
      optInStatus
      optInType
      email
      userId
    }
    eligibility {
      isEligible
    }
  }
}
    `;

/**
 * __useGetOptInStatusQuery__
 *
 * To run a query within a React component, call `useGetOptInStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOptInStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptInStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOptInStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetOptInStatusQuery, GetOptInStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOptInStatusQuery, GetOptInStatusQueryVariables>(GetOptInStatusDocument, options);
      }
export function useGetOptInStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOptInStatusQuery, GetOptInStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOptInStatusQuery, GetOptInStatusQueryVariables>(GetOptInStatusDocument, options);
        }
export type GetOptInStatusQueryHookResult = ReturnType<typeof useGetOptInStatusQuery>;
export type GetOptInStatusLazyQueryHookResult = ReturnType<typeof useGetOptInStatusLazyQuery>;
export type GetOptInStatusQueryResult = Apollo.QueryResult<GetOptInStatusQuery, GetOptInStatusQueryVariables>;
export const GetQuotaDocument = gql`
    query GetQuota {
  getQuota {
    usage
    limit
  }
}
    `;

/**
 * __useGetQuotaQuery__
 *
 * To run a query within a React component, call `useGetQuotaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuotaQuery(baseOptions?: Apollo.QueryHookOptions<GetQuotaQuery, GetQuotaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuotaQuery, GetQuotaQueryVariables>(GetQuotaDocument, options);
      }
export function useGetQuotaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuotaQuery, GetQuotaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuotaQuery, GetQuotaQueryVariables>(GetQuotaDocument, options);
        }
export type GetQuotaQueryHookResult = ReturnType<typeof useGetQuotaQuery>;
export type GetQuotaLazyQueryHookResult = ReturnType<typeof useGetQuotaLazyQuery>;
export type GetQuotaQueryResult = Apollo.QueryResult<GetQuotaQuery, GetQuotaQueryVariables>;
export const GetRawMessageDocument = gql`
    query GetRawMessage($mailboxId: String!, $messageUid: Float!) {
  getRawMessage(mailboxId: $mailboxId, messageUid: $messageUid) {
    uid
    raw
  }
}
    `;

/**
 * __useGetRawMessageQuery__
 *
 * To run a query within a React component, call `useGetRawMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRawMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRawMessageQuery({
 *   variables: {
 *      mailboxId: // value for 'mailboxId'
 *      messageUid: // value for 'messageUid'
 *   },
 * });
 */
export function useGetRawMessageQuery(baseOptions: Apollo.QueryHookOptions<GetRawMessageQuery, GetRawMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRawMessageQuery, GetRawMessageQueryVariables>(GetRawMessageDocument, options);
      }
export function useGetRawMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRawMessageQuery, GetRawMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRawMessageQuery, GetRawMessageQueryVariables>(GetRawMessageDocument, options);
        }
export type GetRawMessageQueryHookResult = ReturnType<typeof useGetRawMessageQuery>;
export type GetRawMessageLazyQueryHookResult = ReturnType<typeof useGetRawMessageLazyQuery>;
export type GetRawMessageQueryResult = Apollo.QueryResult<GetRawMessageQuery, GetRawMessageQueryVariables>;
export const GetSignatureCollectionDocument = gql`
    query GetSignatureCollection {
  getSignatureCollection {
    id
    title
    signature
    autoInsert
  }
}
    `;

/**
 * __useGetSignatureCollectionQuery__
 *
 * To run a query within a React component, call `useGetSignatureCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignatureCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignatureCollectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSignatureCollectionQuery(baseOptions?: Apollo.QueryHookOptions<GetSignatureCollectionQuery, GetSignatureCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignatureCollectionQuery, GetSignatureCollectionQueryVariables>(GetSignatureCollectionDocument, options);
      }
export function useGetSignatureCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignatureCollectionQuery, GetSignatureCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignatureCollectionQuery, GetSignatureCollectionQueryVariables>(GetSignatureCollectionDocument, options);
        }
export type GetSignatureCollectionQueryHookResult = ReturnType<typeof useGetSignatureCollectionQuery>;
export type GetSignatureCollectionLazyQueryHookResult = ReturnType<typeof useGetSignatureCollectionLazyQuery>;
export type GetSignatureCollectionQueryResult = Apollo.QueryResult<GetSignatureCollectionQuery, GetSignatureCollectionQueryVariables>;
export const GetSettingDocument = gql`
    query GetSetting {
  getSetting {
    displayName
    timeZone
    emailPerPage
    replyTo
    preferredLanguage
    isAutoSaveDraftEnabled
    isAutoMailboxReloadEnabled
    isDisplayImageEnabled
    isDisplayEmailAsHtmlEnabled
    isEmailPermanentlyDeleted
  }
}
    `;

/**
 * __useGetSettingQuery__
 *
 * To run a query within a React component, call `useGetSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSettingQuery(baseOptions?: Apollo.QueryHookOptions<GetSettingQuery, GetSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSettingQuery, GetSettingQueryVariables>(GetSettingDocument, options);
      }
export function useGetSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettingQuery, GetSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSettingQuery, GetSettingQueryVariables>(GetSettingDocument, options);
        }
export type GetSettingQueryHookResult = ReturnType<typeof useGetSettingQuery>;
export type GetSettingLazyQueryHookResult = ReturnType<typeof useGetSettingLazyQuery>;
export type GetSettingQueryResult = Apollo.QueryResult<GetSettingQuery, GetSettingQueryVariables>;
export const PatchEmailPerPageDocument = gql`
    mutation PatchEmailPerPage($value: String!) {
  patchEmailPerPage(value: $value) {
    emailPerPage
  }
}
    `;
export type PatchEmailPerPageMutationFn = Apollo.MutationFunction<PatchEmailPerPageMutation, PatchEmailPerPageMutationVariables>;

/**
 * __usePatchEmailPerPageMutation__
 *
 * To run a mutation, you first call `usePatchEmailPerPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchEmailPerPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchEmailPerPageMutation, { data, loading, error }] = usePatchEmailPerPageMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function usePatchEmailPerPageMutation(baseOptions?: Apollo.MutationHookOptions<PatchEmailPerPageMutation, PatchEmailPerPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchEmailPerPageMutation, PatchEmailPerPageMutationVariables>(PatchEmailPerPageDocument, options);
      }
export type PatchEmailPerPageMutationHookResult = ReturnType<typeof usePatchEmailPerPageMutation>;
export type PatchEmailPerPageMutationResult = Apollo.MutationResult<PatchEmailPerPageMutation>;
export type PatchEmailPerPageMutationOptions = Apollo.BaseMutationOptions<PatchEmailPerPageMutation, PatchEmailPerPageMutationVariables>;
export const PatchReplyToDocument = gql`
    mutation PatchReplyTo($value: String!) {
  patchReplyTo(value: $value) {
    replyTo
  }
}
    `;
export type PatchReplyToMutationFn = Apollo.MutationFunction<PatchReplyToMutation, PatchReplyToMutationVariables>;

/**
 * __usePatchReplyToMutation__
 *
 * To run a mutation, you first call `usePatchReplyToMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchReplyToMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchReplyToMutation, { data, loading, error }] = usePatchReplyToMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function usePatchReplyToMutation(baseOptions?: Apollo.MutationHookOptions<PatchReplyToMutation, PatchReplyToMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchReplyToMutation, PatchReplyToMutationVariables>(PatchReplyToDocument, options);
      }
export type PatchReplyToMutationHookResult = ReturnType<typeof usePatchReplyToMutation>;
export type PatchReplyToMutationResult = Apollo.MutationResult<PatchReplyToMutation>;
export type PatchReplyToMutationOptions = Apollo.BaseMutationOptions<PatchReplyToMutation, PatchReplyToMutationVariables>;
export const UpdateAutoInsertSignatureDocument = gql`
    mutation UpdateAutoInsertSignature($id: String!, $value: String!) {
  patchAutoInsertSignature(id: $id, value: $value) {
    id
    title
    signature
    autoInsert
    createdAt
    updatedAt
  }
}
    `;
export type UpdateAutoInsertSignatureMutationFn = Apollo.MutationFunction<UpdateAutoInsertSignatureMutation, UpdateAutoInsertSignatureMutationVariables>;

/**
 * __useUpdateAutoInsertSignatureMutation__
 *
 * To run a mutation, you first call `useUpdateAutoInsertSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoInsertSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoInsertSignatureMutation, { data, loading, error }] = useUpdateAutoInsertSignatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateAutoInsertSignatureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAutoInsertSignatureMutation, UpdateAutoInsertSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAutoInsertSignatureMutation, UpdateAutoInsertSignatureMutationVariables>(UpdateAutoInsertSignatureDocument, options);
      }
export type UpdateAutoInsertSignatureMutationHookResult = ReturnType<typeof useUpdateAutoInsertSignatureMutation>;
export type UpdateAutoInsertSignatureMutationResult = Apollo.MutationResult<UpdateAutoInsertSignatureMutation>;
export type UpdateAutoInsertSignatureMutationOptions = Apollo.BaseMutationOptions<UpdateAutoInsertSignatureMutation, UpdateAutoInsertSignatureMutationVariables>;
export const PostOptInStatusDocument = gql`
    mutation postOptInStatus($optInType: String!, $isOptInYes: Boolean!) {
  postOptInStatus(optin: {optInType: $optInType, isOptInYes: $isOptInYes}) {
    email
    optInStatus
    optInType
  }
}
    `;
export type PostOptInStatusMutationFn = Apollo.MutationFunction<PostOptInStatusMutation, PostOptInStatusMutationVariables>;

/**
 * __usePostOptInStatusMutation__
 *
 * To run a mutation, you first call `usePostOptInStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostOptInStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postOptInStatusMutation, { data, loading, error }] = usePostOptInStatusMutation({
 *   variables: {
 *      optInType: // value for 'optInType'
 *      isOptInYes: // value for 'isOptInYes'
 *   },
 * });
 */
export function usePostOptInStatusMutation(baseOptions?: Apollo.MutationHookOptions<PostOptInStatusMutation, PostOptInStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostOptInStatusMutation, PostOptInStatusMutationVariables>(PostOptInStatusDocument, options);
      }
export type PostOptInStatusMutationHookResult = ReturnType<typeof usePostOptInStatusMutation>;
export type PostOptInStatusMutationResult = Apollo.MutationResult<PostOptInStatusMutation>;
export type PostOptInStatusMutationOptions = Apollo.BaseMutationOptions<PostOptInStatusMutation, PostOptInStatusMutationVariables>;
export const ContactMigrationDocument = gql`
    mutation ContactMigration {
  contactMigration {
    success
  }
}
    `;
export type ContactMigrationMutationFn = Apollo.MutationFunction<ContactMigrationMutation, ContactMigrationMutationVariables>;

/**
 * __useContactMigrationMutation__
 *
 * To run a mutation, you first call `useContactMigrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactMigrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactMigrationMutation, { data, loading, error }] = useContactMigrationMutation({
 *   variables: {
 *   },
 * });
 */
export function useContactMigrationMutation(baseOptions?: Apollo.MutationHookOptions<ContactMigrationMutation, ContactMigrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactMigrationMutation, ContactMigrationMutationVariables>(ContactMigrationDocument, options);
      }
export type ContactMigrationMutationHookResult = ReturnType<typeof useContactMigrationMutation>;
export type ContactMigrationMutationResult = Apollo.MutationResult<ContactMigrationMutation>;
export type ContactMigrationMutationOptions = Apollo.BaseMutationOptions<ContactMigrationMutation, ContactMigrationMutationVariables>;
export const ReSyncContactDocument = gql`
    mutation ReSyncContact {
  reSyncContact {
    success
    successContactCount
    successLabelCount
    errorContactCount
    errorLabelCount
  }
}
    `;
export type ReSyncContactMutationFn = Apollo.MutationFunction<ReSyncContactMutation, ReSyncContactMutationVariables>;

/**
 * __useReSyncContactMutation__
 *
 * To run a mutation, you first call `useReSyncContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReSyncContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reSyncContactMutation, { data, loading, error }] = useReSyncContactMutation({
 *   variables: {
 *   },
 * });
 */
export function useReSyncContactMutation(baseOptions?: Apollo.MutationHookOptions<ReSyncContactMutation, ReSyncContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReSyncContactMutation, ReSyncContactMutationVariables>(ReSyncContactDocument, options);
      }
export type ReSyncContactMutationHookResult = ReturnType<typeof useReSyncContactMutation>;
export type ReSyncContactMutationResult = Apollo.MutationResult<ReSyncContactMutation>;
export type ReSyncContactMutationOptions = Apollo.BaseMutationOptions<ReSyncContactMutation, ReSyncContactMutationVariables>;
export const ContactRollbackDocument = gql`
    mutation ContactRollback {
  contactRollback {
    success
  }
}
    `;
export type ContactRollbackMutationFn = Apollo.MutationFunction<ContactRollbackMutation, ContactRollbackMutationVariables>;

/**
 * __useContactRollbackMutation__
 *
 * To run a mutation, you first call `useContactRollbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactRollbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactRollbackMutation, { data, loading, error }] = useContactRollbackMutation({
 *   variables: {
 *   },
 * });
 */
export function useContactRollbackMutation(baseOptions?: Apollo.MutationHookOptions<ContactRollbackMutation, ContactRollbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactRollbackMutation, ContactRollbackMutationVariables>(ContactRollbackDocument, options);
      }
export type ContactRollbackMutationHookResult = ReturnType<typeof useContactRollbackMutation>;
export type ContactRollbackMutationResult = Apollo.MutationResult<ContactRollbackMutation>;
export type ContactRollbackMutationOptions = Apollo.BaseMutationOptions<ContactRollbackMutation, ContactRollbackMutationVariables>;
export const CreateBulkContactDocument = gql`
    mutation createBulkContact($contacts: [ContactForm!]!) {
  createBulkContact(contacts: $contacts) {
    success
    createCount
  }
}
    `;
export type CreateBulkContactMutationFn = Apollo.MutationFunction<CreateBulkContactMutation, CreateBulkContactMutationVariables>;

/**
 * __useCreateBulkContactMutation__
 *
 * To run a mutation, you first call `useCreateBulkContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkContactMutation, { data, loading, error }] = useCreateBulkContactMutation({
 *   variables: {
 *      contacts: // value for 'contacts'
 *   },
 * });
 */
export function useCreateBulkContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateBulkContactMutation, CreateBulkContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBulkContactMutation, CreateBulkContactMutationVariables>(CreateBulkContactDocument, options);
      }
export type CreateBulkContactMutationHookResult = ReturnType<typeof useCreateBulkContactMutation>;
export type CreateBulkContactMutationResult = Apollo.MutationResult<CreateBulkContactMutation>;
export type CreateBulkContactMutationOptions = Apollo.BaseMutationOptions<CreateBulkContactMutation, CreateBulkContactMutationVariables>;
export const CreateContactDocument = gql`
    mutation CreateContact($contact: ContactForm!) {
  createContact(contact: $contact) {
    id
    title
    firstName
    middleName
    lastName
    displayName
    nickName
    company
    url
    dateOfBirth
    notes
    profileImage
    emails {
      emailAddress
      emailAddressType
      isDefault
    }
    phoneNumbers {
      phoneNumber
      phoneNumberType
      isDefault
    }
    addresses {
      addressType
      state
      city
      zipCode
      streetAddress
      country
    }
    labels {
      name
      id
    }
  }
}
    `;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const CreateFilterDocument = gql`
    mutation CreateFilter($filters: [FilterForm!]!) {
  updateFilters(filters: $filters) {
    success
  }
}
    `;
export type CreateFilterMutationFn = Apollo.MutationFunction<CreateFilterMutation, CreateFilterMutationVariables>;

/**
 * __useCreateFilterMutation__
 *
 * To run a mutation, you first call `useCreateFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFilterMutation, { data, loading, error }] = useCreateFilterMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCreateFilterMutation(baseOptions?: Apollo.MutationHookOptions<CreateFilterMutation, CreateFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFilterMutation, CreateFilterMutationVariables>(CreateFilterDocument, options);
      }
export type CreateFilterMutationHookResult = ReturnType<typeof useCreateFilterMutation>;
export type CreateFilterMutationResult = Apollo.MutationResult<CreateFilterMutation>;
export type CreateFilterMutationOptions = Apollo.BaseMutationOptions<CreateFilterMutation, CreateFilterMutationVariables>;
export const CreateLabelDocument = gql`
    mutation CreateLabel($name: String!) {
  createLabel(label: {name: $name}) {
    id
    name
  }
}
    `;
export type CreateLabelMutationFn = Apollo.MutationFunction<CreateLabelMutation, CreateLabelMutationVariables>;

/**
 * __useCreateLabelMutation__
 *
 * To run a mutation, you first call `useCreateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLabelMutation, { data, loading, error }] = useCreateLabelMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateLabelMutation(baseOptions?: Apollo.MutationHookOptions<CreateLabelMutation, CreateLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLabelMutation, CreateLabelMutationVariables>(CreateLabelDocument, options);
      }
export type CreateLabelMutationHookResult = ReturnType<typeof useCreateLabelMutation>;
export type CreateLabelMutationResult = Apollo.MutationResult<CreateLabelMutation>;
export type CreateLabelMutationOptions = Apollo.BaseMutationOptions<CreateLabelMutation, CreateLabelMutationVariables>;
export const CreateMailboxDocument = gql`
    mutation CreateMailbox($mailbox: MailboxForm!) {
  createMailbox(mailbox: $mailbox) {
    id
    delimiter
    name
  }
}
    `;
export type CreateMailboxMutationFn = Apollo.MutationFunction<CreateMailboxMutation, CreateMailboxMutationVariables>;

/**
 * __useCreateMailboxMutation__
 *
 * To run a mutation, you first call `useCreateMailboxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMailboxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMailboxMutation, { data, loading, error }] = useCreateMailboxMutation({
 *   variables: {
 *      mailbox: // value for 'mailbox'
 *   },
 * });
 */
export function useCreateMailboxMutation(baseOptions?: Apollo.MutationHookOptions<CreateMailboxMutation, CreateMailboxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMailboxMutation, CreateMailboxMutationVariables>(CreateMailboxDocument, options);
      }
export type CreateMailboxMutationHookResult = ReturnType<typeof useCreateMailboxMutation>;
export type CreateMailboxMutationResult = Apollo.MutationResult<CreateMailboxMutation>;
export type CreateMailboxMutationOptions = Apollo.BaseMutationOptions<CreateMailboxMutation, CreateMailboxMutationVariables>;
export const CreateSignatureDocument = gql`
    mutation CreateSignature($signature: String!, $autoInsert: Boolean!) {
  createSignature(signature: {signature: $signature, autoInsert: $autoInsert}) {
    id
    title
    signature
    autoInsert
    createdAt
    updatedAt
  }
}
    `;
export type CreateSignatureMutationFn = Apollo.MutationFunction<CreateSignatureMutation, CreateSignatureMutationVariables>;

/**
 * __useCreateSignatureMutation__
 *
 * To run a mutation, you first call `useCreateSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignatureMutation, { data, loading, error }] = useCreateSignatureMutation({
 *   variables: {
 *      signature: // value for 'signature'
 *      autoInsert: // value for 'autoInsert'
 *   },
 * });
 */
export function useCreateSignatureMutation(baseOptions?: Apollo.MutationHookOptions<CreateSignatureMutation, CreateSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSignatureMutation, CreateSignatureMutationVariables>(CreateSignatureDocument, options);
      }
export type CreateSignatureMutationHookResult = ReturnType<typeof useCreateSignatureMutation>;
export type CreateSignatureMutationResult = Apollo.MutationResult<CreateSignatureMutation>;
export type CreateSignatureMutationOptions = Apollo.BaseMutationOptions<CreateSignatureMutation, CreateSignatureMutationVariables>;
export const DeleteBulkContactDocument = gql`
    mutation DeleteBulkContact($id: [String!]!) {
  deleteBulkContact(id: $id) {
    success
    deleteCount
  }
}
    `;
export type DeleteBulkContactMutationFn = Apollo.MutationFunction<DeleteBulkContactMutation, DeleteBulkContactMutationVariables>;

/**
 * __useDeleteBulkContactMutation__
 *
 * To run a mutation, you first call `useDeleteBulkContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBulkContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBulkContactMutation, { data, loading, error }] = useDeleteBulkContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBulkContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBulkContactMutation, DeleteBulkContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBulkContactMutation, DeleteBulkContactMutationVariables>(DeleteBulkContactDocument, options);
      }
export type DeleteBulkContactMutationHookResult = ReturnType<typeof useDeleteBulkContactMutation>;
export type DeleteBulkContactMutationResult = Apollo.MutationResult<DeleteBulkContactMutation>;
export type DeleteBulkContactMutationOptions = Apollo.BaseMutationOptions<DeleteBulkContactMutation, DeleteBulkContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: String!) {
  deleteContact(id: $id) {
    success
  }
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const DeleteFilterDocument = gql`
    mutation DeleteFilter($filters: [FilterForm!]!) {
  updateFilters(filters: $filters) {
    success
  }
}
    `;
export type DeleteFilterMutationFn = Apollo.MutationFunction<DeleteFilterMutation, DeleteFilterMutationVariables>;

/**
 * __useDeleteFilterMutation__
 *
 * To run a mutation, you first call `useDeleteFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFilterMutation, { data, loading, error }] = useDeleteFilterMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDeleteFilterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFilterMutation, DeleteFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFilterMutation, DeleteFilterMutationVariables>(DeleteFilterDocument, options);
      }
export type DeleteFilterMutationHookResult = ReturnType<typeof useDeleteFilterMutation>;
export type DeleteFilterMutationResult = Apollo.MutationResult<DeleteFilterMutation>;
export type DeleteFilterMutationOptions = Apollo.BaseMutationOptions<DeleteFilterMutation, DeleteFilterMutationVariables>;
export const DeleteMailboxDocument = gql`
    mutation DeleteMailbox($mailboxId: String!, $deleteMessage: Boolean!) {
  deleteMailbox(mailboxId: $mailboxId, deleteMessage: $deleteMessage) {
    mailboxDeleteStatus {
      mailbox
      success
      error
      isGlobal
    }
  }
}
    `;
export type DeleteMailboxMutationFn = Apollo.MutationFunction<DeleteMailboxMutation, DeleteMailboxMutationVariables>;

/**
 * __useDeleteMailboxMutation__
 *
 * To run a mutation, you first call `useDeleteMailboxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMailboxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMailboxMutation, { data, loading, error }] = useDeleteMailboxMutation({
 *   variables: {
 *      mailboxId: // value for 'mailboxId'
 *      deleteMessage: // value for 'deleteMessage'
 *   },
 * });
 */
export function useDeleteMailboxMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMailboxMutation, DeleteMailboxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMailboxMutation, DeleteMailboxMutationVariables>(DeleteMailboxDocument, options);
      }
export type DeleteMailboxMutationHookResult = ReturnType<typeof useDeleteMailboxMutation>;
export type DeleteMailboxMutationResult = Apollo.MutationResult<DeleteMailboxMutation>;
export type DeleteMailboxMutationOptions = Apollo.BaseMutationOptions<DeleteMailboxMutation, DeleteMailboxMutationVariables>;
export const DeleteMessageDocument = gql`
    mutation DeleteMessage($messageUid: Float!, $mailboxId: String!) {
  deleteMessage(messageUid: $messageUid, mailboxId: $mailboxId) {
    success
  }
}
    `;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      messageUid: // value for 'messageUid'
 *      mailboxId: // value for 'mailboxId'
 *   },
 * });
 */
export function useDeleteMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, options);
      }
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<DeleteMessageMutation, DeleteMessageMutationVariables>;
export const DeleteStorageDocument = gql`
    mutation DeleteStorage($id: String!) {
  deleteStorage(id: $id) {
    success
  }
}
    `;
export type DeleteStorageMutationFn = Apollo.MutationFunction<DeleteStorageMutation, DeleteStorageMutationVariables>;

/**
 * __useDeleteStorageMutation__
 *
 * To run a mutation, you first call `useDeleteStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStorageMutation, { data, loading, error }] = useDeleteStorageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStorageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStorageMutation, DeleteStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStorageMutation, DeleteStorageMutationVariables>(DeleteStorageDocument, options);
      }
export type DeleteStorageMutationHookResult = ReturnType<typeof useDeleteStorageMutation>;
export type DeleteStorageMutationResult = Apollo.MutationResult<DeleteStorageMutation>;
export type DeleteStorageMutationOptions = Apollo.BaseMutationOptions<DeleteStorageMutation, DeleteStorageMutationVariables>;
export const PostMarkMessagesAsFlagDocument = gql`
    mutation PostMarkMessagesAsFlag($messageUids: [Float!]!, $mailboxId: String!) {
  markMessagesImportant(messageUids: $messageUids, mailboxId: $mailboxId) {
    success
  }
}
    `;
export type PostMarkMessagesAsFlagMutationFn = Apollo.MutationFunction<PostMarkMessagesAsFlagMutation, PostMarkMessagesAsFlagMutationVariables>;

/**
 * __usePostMarkMessagesAsFlagMutation__
 *
 * To run a mutation, you first call `usePostMarkMessagesAsFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostMarkMessagesAsFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postMarkMessagesAsFlagMutation, { data, loading, error }] = usePostMarkMessagesAsFlagMutation({
 *   variables: {
 *      messageUids: // value for 'messageUids'
 *      mailboxId: // value for 'mailboxId'
 *   },
 * });
 */
export function usePostMarkMessagesAsFlagMutation(baseOptions?: Apollo.MutationHookOptions<PostMarkMessagesAsFlagMutation, PostMarkMessagesAsFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostMarkMessagesAsFlagMutation, PostMarkMessagesAsFlagMutationVariables>(PostMarkMessagesAsFlagDocument, options);
      }
export type PostMarkMessagesAsFlagMutationHookResult = ReturnType<typeof usePostMarkMessagesAsFlagMutation>;
export type PostMarkMessagesAsFlagMutationResult = Apollo.MutationResult<PostMarkMessagesAsFlagMutation>;
export type PostMarkMessagesAsFlagMutationOptions = Apollo.BaseMutationOptions<PostMarkMessagesAsFlagMutation, PostMarkMessagesAsFlagMutationVariables>;
export const PostMarkMessagesAsSeenDocument = gql`
    mutation PostMarkMessagesAsSeen($mailboxId: String!, $messageUids: [Float!]!) {
  markMessagesSeen(mailboxId: $mailboxId, messageUids: $messageUids) {
    success
  }
}
    `;
export type PostMarkMessagesAsSeenMutationFn = Apollo.MutationFunction<PostMarkMessagesAsSeenMutation, PostMarkMessagesAsSeenMutationVariables>;

/**
 * __usePostMarkMessagesAsSeenMutation__
 *
 * To run a mutation, you first call `usePostMarkMessagesAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostMarkMessagesAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postMarkMessagesAsSeenMutation, { data, loading, error }] = usePostMarkMessagesAsSeenMutation({
 *   variables: {
 *      mailboxId: // value for 'mailboxId'
 *      messageUids: // value for 'messageUids'
 *   },
 * });
 */
export function usePostMarkMessagesAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<PostMarkMessagesAsSeenMutation, PostMarkMessagesAsSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostMarkMessagesAsSeenMutation, PostMarkMessagesAsSeenMutationVariables>(PostMarkMessagesAsSeenDocument, options);
      }
export type PostMarkMessagesAsSeenMutationHookResult = ReturnType<typeof usePostMarkMessagesAsSeenMutation>;
export type PostMarkMessagesAsSeenMutationResult = Apollo.MutationResult<PostMarkMessagesAsSeenMutation>;
export type PostMarkMessagesAsSeenMutationOptions = Apollo.BaseMutationOptions<PostMarkMessagesAsSeenMutation, PostMarkMessagesAsSeenMutationVariables>;
export const PostMarkMessagesAsUnflagDocument = gql`
    mutation PostMarkMessagesAsUnflag($messageUids: [Float!]!, $mailboxId: String!) {
  markMessagesUnimportant(messageUids: $messageUids, mailboxId: $mailboxId) {
    success
  }
}
    `;
export type PostMarkMessagesAsUnflagMutationFn = Apollo.MutationFunction<PostMarkMessagesAsUnflagMutation, PostMarkMessagesAsUnflagMutationVariables>;

/**
 * __usePostMarkMessagesAsUnflagMutation__
 *
 * To run a mutation, you first call `usePostMarkMessagesAsUnflagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostMarkMessagesAsUnflagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postMarkMessagesAsUnflagMutation, { data, loading, error }] = usePostMarkMessagesAsUnflagMutation({
 *   variables: {
 *      messageUids: // value for 'messageUids'
 *      mailboxId: // value for 'mailboxId'
 *   },
 * });
 */
export function usePostMarkMessagesAsUnflagMutation(baseOptions?: Apollo.MutationHookOptions<PostMarkMessagesAsUnflagMutation, PostMarkMessagesAsUnflagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostMarkMessagesAsUnflagMutation, PostMarkMessagesAsUnflagMutationVariables>(PostMarkMessagesAsUnflagDocument, options);
      }
export type PostMarkMessagesAsUnflagMutationHookResult = ReturnType<typeof usePostMarkMessagesAsUnflagMutation>;
export type PostMarkMessagesAsUnflagMutationResult = Apollo.MutationResult<PostMarkMessagesAsUnflagMutation>;
export type PostMarkMessagesAsUnflagMutationOptions = Apollo.BaseMutationOptions<PostMarkMessagesAsUnflagMutation, PostMarkMessagesAsUnflagMutationVariables>;
export const PostMarkMessagesAsUnseenDocument = gql`
    mutation PostMarkMessagesAsUnseen($mailboxId: String!, $messageUids: [Float!]!) {
  markMessagesUnseen(mailboxId: $mailboxId, messageUids: $messageUids) {
    success
  }
}
    `;
export type PostMarkMessagesAsUnseenMutationFn = Apollo.MutationFunction<PostMarkMessagesAsUnseenMutation, PostMarkMessagesAsUnseenMutationVariables>;

/**
 * __usePostMarkMessagesAsUnseenMutation__
 *
 * To run a mutation, you first call `usePostMarkMessagesAsUnseenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostMarkMessagesAsUnseenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postMarkMessagesAsUnseenMutation, { data, loading, error }] = usePostMarkMessagesAsUnseenMutation({
 *   variables: {
 *      mailboxId: // value for 'mailboxId'
 *      messageUids: // value for 'messageUids'
 *   },
 * });
 */
export function usePostMarkMessagesAsUnseenMutation(baseOptions?: Apollo.MutationHookOptions<PostMarkMessagesAsUnseenMutation, PostMarkMessagesAsUnseenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostMarkMessagesAsUnseenMutation, PostMarkMessagesAsUnseenMutationVariables>(PostMarkMessagesAsUnseenDocument, options);
      }
export type PostMarkMessagesAsUnseenMutationHookResult = ReturnType<typeof usePostMarkMessagesAsUnseenMutation>;
export type PostMarkMessagesAsUnseenMutationResult = Apollo.MutationResult<PostMarkMessagesAsUnseenMutation>;
export type PostMarkMessagesAsUnseenMutationOptions = Apollo.BaseMutationOptions<PostMarkMessagesAsUnseenMutation, PostMarkMessagesAsUnseenMutationVariables>;
export const PatchBulkContactLabelDocument = gql`
    mutation PatchBulkContactLabel($contacts: [String!]!, $labels: [String!]!) {
  putBulkContactLabel(contacts: $contacts, labels: $labels) {
    success
  }
}
    `;
export type PatchBulkContactLabelMutationFn = Apollo.MutationFunction<PatchBulkContactLabelMutation, PatchBulkContactLabelMutationVariables>;

/**
 * __usePatchBulkContactLabelMutation__
 *
 * To run a mutation, you first call `usePatchBulkContactLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchBulkContactLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchBulkContactLabelMutation, { data, loading, error }] = usePatchBulkContactLabelMutation({
 *   variables: {
 *      contacts: // value for 'contacts'
 *      labels: // value for 'labels'
 *   },
 * });
 */
export function usePatchBulkContactLabelMutation(baseOptions?: Apollo.MutationHookOptions<PatchBulkContactLabelMutation, PatchBulkContactLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchBulkContactLabelMutation, PatchBulkContactLabelMutationVariables>(PatchBulkContactLabelDocument, options);
      }
export type PatchBulkContactLabelMutationHookResult = ReturnType<typeof usePatchBulkContactLabelMutation>;
export type PatchBulkContactLabelMutationResult = Apollo.MutationResult<PatchBulkContactLabelMutation>;
export type PatchBulkContactLabelMutationOptions = Apollo.BaseMutationOptions<PatchBulkContactLabelMutation, PatchBulkContactLabelMutationVariables>;
export const PatchContactLabelDocument = gql`
    mutation PatchContactLabel($id: String!, $labels: [String!]!) {
  patchContactLabel(id: $id, labels: $labels) {
    id
    firstName
    lastName
    displayName
    emails {
      emailAddress
      emailAddressType
      isDefault
    }
    phoneNumbers {
      phoneNumber
      phoneNumberType
      isDefault
    }
    addresses {
      addressType
      state
      city
      zipCode
      streetAddress
      country
    }
    labels {
      id
      name
    }
    url
    profileImage
    nickName
    notes
    title
    middleName
    company
    dateOfBirth
    createdAt
    updatedAt
  }
}
    `;
export type PatchContactLabelMutationFn = Apollo.MutationFunction<PatchContactLabelMutation, PatchContactLabelMutationVariables>;

/**
 * __usePatchContactLabelMutation__
 *
 * To run a mutation, you first call `usePatchContactLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchContactLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchContactLabelMutation, { data, loading, error }] = usePatchContactLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      labels: // value for 'labels'
 *   },
 * });
 */
export function usePatchContactLabelMutation(baseOptions?: Apollo.MutationHookOptions<PatchContactLabelMutation, PatchContactLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchContactLabelMutation, PatchContactLabelMutationVariables>(PatchContactLabelDocument, options);
      }
export type PatchContactLabelMutationHookResult = ReturnType<typeof usePatchContactLabelMutation>;
export type PatchContactLabelMutationResult = Apollo.MutationResult<PatchContactLabelMutation>;
export type PatchContactLabelMutationOptions = Apollo.BaseMutationOptions<PatchContactLabelMutation, PatchContactLabelMutationVariables>;
export const MoveMessagesToNotSpamDocument = gql`
    mutation MoveMessagesToNotSpam($sourceMailboxId: String!, $destinationMailboxId: String!, $messageUids: [Float!]!) {
  moveMessages(
    sourceMailboxId: $sourceMailboxId
    destinationMailboxId: $destinationMailboxId
    messageUids: $messageUids
  ) {
    success
  }
}
    `;
export type MoveMessagesToNotSpamMutationFn = Apollo.MutationFunction<MoveMessagesToNotSpamMutation, MoveMessagesToNotSpamMutationVariables>;

/**
 * __useMoveMessagesToNotSpamMutation__
 *
 * To run a mutation, you first call `useMoveMessagesToNotSpamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveMessagesToNotSpamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveMessagesToNotSpamMutation, { data, loading, error }] = useMoveMessagesToNotSpamMutation({
 *   variables: {
 *      sourceMailboxId: // value for 'sourceMailboxId'
 *      destinationMailboxId: // value for 'destinationMailboxId'
 *      messageUids: // value for 'messageUids'
 *   },
 * });
 */
export function useMoveMessagesToNotSpamMutation(baseOptions?: Apollo.MutationHookOptions<MoveMessagesToNotSpamMutation, MoveMessagesToNotSpamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveMessagesToNotSpamMutation, MoveMessagesToNotSpamMutationVariables>(MoveMessagesToNotSpamDocument, options);
      }
export type MoveMessagesToNotSpamMutationHookResult = ReturnType<typeof useMoveMessagesToNotSpamMutation>;
export type MoveMessagesToNotSpamMutationResult = Apollo.MutationResult<MoveMessagesToNotSpamMutation>;
export type MoveMessagesToNotSpamMutationOptions = Apollo.BaseMutationOptions<MoveMessagesToNotSpamMutation, MoveMessagesToNotSpamMutationVariables>;
export const MoveMessagesToSpamDocument = gql`
    mutation MoveMessagesToSpam($sourceMailboxId: String!, $destinationMailboxId: String!, $messageUids: [Float!]!) {
  moveMessages(
    sourceMailboxId: $sourceMailboxId
    destinationMailboxId: $destinationMailboxId
    messageUids: $messageUids
  ) {
    success
  }
}
    `;
export type MoveMessagesToSpamMutationFn = Apollo.MutationFunction<MoveMessagesToSpamMutation, MoveMessagesToSpamMutationVariables>;

/**
 * __useMoveMessagesToSpamMutation__
 *
 * To run a mutation, you first call `useMoveMessagesToSpamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveMessagesToSpamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveMessagesToSpamMutation, { data, loading, error }] = useMoveMessagesToSpamMutation({
 *   variables: {
 *      sourceMailboxId: // value for 'sourceMailboxId'
 *      destinationMailboxId: // value for 'destinationMailboxId'
 *      messageUids: // value for 'messageUids'
 *   },
 * });
 */
export function useMoveMessagesToSpamMutation(baseOptions?: Apollo.MutationHookOptions<MoveMessagesToSpamMutation, MoveMessagesToSpamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveMessagesToSpamMutation, MoveMessagesToSpamMutationVariables>(MoveMessagesToSpamDocument, options);
      }
export type MoveMessagesToSpamMutationHookResult = ReturnType<typeof useMoveMessagesToSpamMutation>;
export type MoveMessagesToSpamMutationResult = Apollo.MutationResult<MoveMessagesToSpamMutation>;
export type MoveMessagesToSpamMutationOptions = Apollo.BaseMutationOptions<MoveMessagesToSpamMutation, MoveMessagesToSpamMutationVariables>;
export const MoveMessagesToTrashDocument = gql`
    mutation MoveMessagesToTrash($sourceMailboxId: String!, $destinationMailboxId: String!, $messageUids: [Float!]!) {
  moveMessages(
    sourceMailboxId: $sourceMailboxId
    destinationMailboxId: $destinationMailboxId
    messageUids: $messageUids
  ) {
    success
  }
}
    `;
export type MoveMessagesToTrashMutationFn = Apollo.MutationFunction<MoveMessagesToTrashMutation, MoveMessagesToTrashMutationVariables>;

/**
 * __useMoveMessagesToTrashMutation__
 *
 * To run a mutation, you first call `useMoveMessagesToTrashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveMessagesToTrashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveMessagesToTrashMutation, { data, loading, error }] = useMoveMessagesToTrashMutation({
 *   variables: {
 *      sourceMailboxId: // value for 'sourceMailboxId'
 *      destinationMailboxId: // value for 'destinationMailboxId'
 *      messageUids: // value for 'messageUids'
 *   },
 * });
 */
export function useMoveMessagesToTrashMutation(baseOptions?: Apollo.MutationHookOptions<MoveMessagesToTrashMutation, MoveMessagesToTrashMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveMessagesToTrashMutation, MoveMessagesToTrashMutationVariables>(MoveMessagesToTrashDocument, options);
      }
export type MoveMessagesToTrashMutationHookResult = ReturnType<typeof useMoveMessagesToTrashMutation>;
export type MoveMessagesToTrashMutationResult = Apollo.MutationResult<MoveMessagesToTrashMutation>;
export type MoveMessagesToTrashMutationOptions = Apollo.BaseMutationOptions<MoveMessagesToTrashMutation, MoveMessagesToTrashMutationVariables>;
export const MoveMessagesDocument = gql`
    mutation MoveMessages($sourceMailboxId: String!, $destinationMailboxId: String!, $messageUids: [Float!]!) {
  moveMessages(
    sourceMailboxId: $sourceMailboxId
    destinationMailboxId: $destinationMailboxId
    messageUids: $messageUids
  ) {
    success
  }
}
    `;
export type MoveMessagesMutationFn = Apollo.MutationFunction<MoveMessagesMutation, MoveMessagesMutationVariables>;

/**
 * __useMoveMessagesMutation__
 *
 * To run a mutation, you first call `useMoveMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveMessagesMutation, { data, loading, error }] = useMoveMessagesMutation({
 *   variables: {
 *      sourceMailboxId: // value for 'sourceMailboxId'
 *      destinationMailboxId: // value for 'destinationMailboxId'
 *      messageUids: // value for 'messageUids'
 *   },
 * });
 */
export function useMoveMessagesMutation(baseOptions?: Apollo.MutationHookOptions<MoveMessagesMutation, MoveMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveMessagesMutation, MoveMessagesMutationVariables>(MoveMessagesDocument, options);
      }
export type MoveMessagesMutationHookResult = ReturnType<typeof useMoveMessagesMutation>;
export type MoveMessagesMutationResult = Apollo.MutationResult<MoveMessagesMutation>;
export type MoveMessagesMutationOptions = Apollo.BaseMutationOptions<MoveMessagesMutation, MoveMessagesMutationVariables>;
export const PermanentDeleteMessageBulkDocument = gql`
    mutation permanentDeleteMessageBulk($mailboxId: String!, $messageUids: [Float!]!) {
  deleteMessageBulk(mailboxId: $mailboxId, messageUids: $messageUids) {
    success
  }
}
    `;
export type PermanentDeleteMessageBulkMutationFn = Apollo.MutationFunction<PermanentDeleteMessageBulkMutation, PermanentDeleteMessageBulkMutationVariables>;

/**
 * __usePermanentDeleteMessageBulkMutation__
 *
 * To run a mutation, you first call `usePermanentDeleteMessageBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermanentDeleteMessageBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permanentDeleteMessageBulkMutation, { data, loading, error }] = usePermanentDeleteMessageBulkMutation({
 *   variables: {
 *      mailboxId: // value for 'mailboxId'
 *      messageUids: // value for 'messageUids'
 *   },
 * });
 */
export function usePermanentDeleteMessageBulkMutation(baseOptions?: Apollo.MutationHookOptions<PermanentDeleteMessageBulkMutation, PermanentDeleteMessageBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PermanentDeleteMessageBulkMutation, PermanentDeleteMessageBulkMutationVariables>(PermanentDeleteMessageBulkDocument, options);
      }
export type PermanentDeleteMessageBulkMutationHookResult = ReturnType<typeof usePermanentDeleteMessageBulkMutation>;
export type PermanentDeleteMessageBulkMutationResult = Apollo.MutationResult<PermanentDeleteMessageBulkMutation>;
export type PermanentDeleteMessageBulkMutationOptions = Apollo.BaseMutationOptions<PermanentDeleteMessageBulkMutation, PermanentDeleteMessageBulkMutationVariables>;
export const RenameMailboxDocument = gql`
    mutation RenameMailbox($mailbox: MailboxForm!, $oldMailboxId: String!) {
  renameMailbox(mailbox: $mailbox, oldMailboxId: $oldMailboxId) {
    id
    delimiter
    name
  }
}
    `;
export type RenameMailboxMutationFn = Apollo.MutationFunction<RenameMailboxMutation, RenameMailboxMutationVariables>;

/**
 * __useRenameMailboxMutation__
 *
 * To run a mutation, you first call `useRenameMailboxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameMailboxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameMailboxMutation, { data, loading, error }] = useRenameMailboxMutation({
 *   variables: {
 *      mailbox: // value for 'mailbox'
 *      oldMailboxId: // value for 'oldMailboxId'
 *   },
 * });
 */
export function useRenameMailboxMutation(baseOptions?: Apollo.MutationHookOptions<RenameMailboxMutation, RenameMailboxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameMailboxMutation, RenameMailboxMutationVariables>(RenameMailboxDocument, options);
      }
export type RenameMailboxMutationHookResult = ReturnType<typeof useRenameMailboxMutation>;
export type RenameMailboxMutationResult = Apollo.MutationResult<RenameMailboxMutation>;
export type RenameMailboxMutationOptions = Apollo.BaseMutationOptions<RenameMailboxMutation, RenameMailboxMutationVariables>;
export const SaveDraftDocument = gql`
    mutation SaveDraft($mail: MailForm!, $displayName: String) {
  saveDraft(mail: $mail, displayName: $displayName) {
    isSavedToImap
    isSentToSmtp
    uid
  }
}
    `;
export type SaveDraftMutationFn = Apollo.MutationFunction<SaveDraftMutation, SaveDraftMutationVariables>;

/**
 * __useSaveDraftMutation__
 *
 * To run a mutation, you first call `useSaveDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDraftMutation, { data, loading, error }] = useSaveDraftMutation({
 *   variables: {
 *      mail: // value for 'mail'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useSaveDraftMutation(baseOptions?: Apollo.MutationHookOptions<SaveDraftMutation, SaveDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDraftMutation, SaveDraftMutationVariables>(SaveDraftDocument, options);
      }
export type SaveDraftMutationHookResult = ReturnType<typeof useSaveDraftMutation>;
export type SaveDraftMutationResult = Apollo.MutationResult<SaveDraftMutation>;
export type SaveDraftMutationOptions = Apollo.BaseMutationOptions<SaveDraftMutation, SaveDraftMutationVariables>;
export const SentMailDocument = gql`
    mutation SentMail($mail: MailForm!, $displayName: String) {
  sentMail(mail: $mail, displayName: $displayName) {
    isSavedToImap
    isSentToSmtp
    smtpResponse {
      messageId
      rejectedErrors {
        command
        recipient
        response
        responseCode
      }
      response
      responseCode
    }
    uid
  }
}
    `;
export type SentMailMutationFn = Apollo.MutationFunction<SentMailMutation, SentMailMutationVariables>;

/**
 * __useSentMailMutation__
 *
 * To run a mutation, you first call `useSentMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSentMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sentMailMutation, { data, loading, error }] = useSentMailMutation({
 *   variables: {
 *      mail: // value for 'mail'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useSentMailMutation(baseOptions?: Apollo.MutationHookOptions<SentMailMutation, SentMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SentMailMutation, SentMailMutationVariables>(SentMailDocument, options);
      }
export type SentMailMutationHookResult = ReturnType<typeof useSentMailMutation>;
export type SentMailMutationResult = Apollo.MutationResult<SentMailMutation>;
export type SentMailMutationOptions = Apollo.BaseMutationOptions<SentMailMutation, SentMailMutationVariables>;
export const SettingMigrationDocument = gql`
    mutation SettingMigration {
  settingMigration {
    success
  }
}
    `;
export type SettingMigrationMutationFn = Apollo.MutationFunction<SettingMigrationMutation, SettingMigrationMutationVariables>;

/**
 * __useSettingMigrationMutation__
 *
 * To run a mutation, you first call `useSettingMigrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingMigrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingMigrationMutation, { data, loading, error }] = useSettingMigrationMutation({
 *   variables: {
 *   },
 * });
 */
export function useSettingMigrationMutation(baseOptions?: Apollo.MutationHookOptions<SettingMigrationMutation, SettingMigrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingMigrationMutation, SettingMigrationMutationVariables>(SettingMigrationDocument, options);
      }
export type SettingMigrationMutationHookResult = ReturnType<typeof useSettingMigrationMutation>;
export type SettingMigrationMutationResult = Apollo.MutationResult<SettingMigrationMutation>;
export type SettingMigrationMutationOptions = Apollo.BaseMutationOptions<SettingMigrationMutation, SettingMigrationMutationVariables>;
export const SettingRollbackDocument = gql`
    mutation SettingRollback {
  settingRollback {
    success
  }
}
    `;
export type SettingRollbackMutationFn = Apollo.MutationFunction<SettingRollbackMutation, SettingRollbackMutationVariables>;

/**
 * __useSettingRollbackMutation__
 *
 * To run a mutation, you first call `useSettingRollbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingRollbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingRollbackMutation, { data, loading, error }] = useSettingRollbackMutation({
 *   variables: {
 *   },
 * });
 */
export function useSettingRollbackMutation(baseOptions?: Apollo.MutationHookOptions<SettingRollbackMutation, SettingRollbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingRollbackMutation, SettingRollbackMutationVariables>(SettingRollbackDocument, options);
      }
export type SettingRollbackMutationHookResult = ReturnType<typeof useSettingRollbackMutation>;
export type SettingRollbackMutationResult = Apollo.MutationResult<SettingRollbackMutation>;
export type SettingRollbackMutationOptions = Apollo.BaseMutationOptions<SettingRollbackMutation, SettingRollbackMutationVariables>;
export const UpdateApprovedSenderDocument = gql`
    mutation UpdateApprovedSender($approvedSender: LdapApprovedSenderForm!) {
  updateApprovedSender(approvedSender: $approvedSender) {
    success
  }
}
    `;
export type UpdateApprovedSenderMutationFn = Apollo.MutationFunction<UpdateApprovedSenderMutation, UpdateApprovedSenderMutationVariables>;

/**
 * __useUpdateApprovedSenderMutation__
 *
 * To run a mutation, you first call `useUpdateApprovedSenderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovedSenderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovedSenderMutation, { data, loading, error }] = useUpdateApprovedSenderMutation({
 *   variables: {
 *      approvedSender: // value for 'approvedSender'
 *   },
 * });
 */
export function useUpdateApprovedSenderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApprovedSenderMutation, UpdateApprovedSenderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApprovedSenderMutation, UpdateApprovedSenderMutationVariables>(UpdateApprovedSenderDocument, options);
      }
export type UpdateApprovedSenderMutationHookResult = ReturnType<typeof useUpdateApprovedSenderMutation>;
export type UpdateApprovedSenderMutationResult = Apollo.MutationResult<UpdateApprovedSenderMutation>;
export type UpdateApprovedSenderMutationOptions = Apollo.BaseMutationOptions<UpdateApprovedSenderMutation, UpdateApprovedSenderMutationVariables>;
export const UpdateAutoReplyMessageDocument = gql`
    mutation UpdateAutoReplyMessage($isVacationModeEnabled: Boolean!, $autoReplyMessage: String) {
  updateAutoReply(
    autoReply: {isVacationModeEnabled: $isVacationModeEnabled, autoReplyMessage: $autoReplyMessage}
  ) {
    success
  }
}
    `;
export type UpdateAutoReplyMessageMutationFn = Apollo.MutationFunction<UpdateAutoReplyMessageMutation, UpdateAutoReplyMessageMutationVariables>;

/**
 * __useUpdateAutoReplyMessageMutation__
 *
 * To run a mutation, you first call `useUpdateAutoReplyMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoReplyMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoReplyMessageMutation, { data, loading, error }] = useUpdateAutoReplyMessageMutation({
 *   variables: {
 *      isVacationModeEnabled: // value for 'isVacationModeEnabled'
 *      autoReplyMessage: // value for 'autoReplyMessage'
 *   },
 * });
 */
export function useUpdateAutoReplyMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAutoReplyMessageMutation, UpdateAutoReplyMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAutoReplyMessageMutation, UpdateAutoReplyMessageMutationVariables>(UpdateAutoReplyMessageDocument, options);
      }
export type UpdateAutoReplyMessageMutationHookResult = ReturnType<typeof useUpdateAutoReplyMessageMutation>;
export type UpdateAutoReplyMessageMutationResult = Apollo.MutationResult<UpdateAutoReplyMessageMutation>;
export type UpdateAutoReplyMessageMutationOptions = Apollo.BaseMutationOptions<UpdateAutoReplyMessageMutation, UpdateAutoReplyMessageMutationVariables>;
export const UpdateAutoReplyToggleDocument = gql`
    mutation UpdateAutoReplyToggle($isVacationModeEnabled: Boolean!, $autoReplyMessage: String) {
  updateAutoReply(
    autoReply: {isVacationModeEnabled: $isVacationModeEnabled, autoReplyMessage: $autoReplyMessage}
  ) {
    success
  }
}
    `;
export type UpdateAutoReplyToggleMutationFn = Apollo.MutationFunction<UpdateAutoReplyToggleMutation, UpdateAutoReplyToggleMutationVariables>;

/**
 * __useUpdateAutoReplyToggleMutation__
 *
 * To run a mutation, you first call `useUpdateAutoReplyToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoReplyToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoReplyToggleMutation, { data, loading, error }] = useUpdateAutoReplyToggleMutation({
 *   variables: {
 *      isVacationModeEnabled: // value for 'isVacationModeEnabled'
 *      autoReplyMessage: // value for 'autoReplyMessage'
 *   },
 * });
 */
export function useUpdateAutoReplyToggleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAutoReplyToggleMutation, UpdateAutoReplyToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAutoReplyToggleMutation, UpdateAutoReplyToggleMutationVariables>(UpdateAutoReplyToggleDocument, options);
      }
export type UpdateAutoReplyToggleMutationHookResult = ReturnType<typeof useUpdateAutoReplyToggleMutation>;
export type UpdateAutoReplyToggleMutationResult = Apollo.MutationResult<UpdateAutoReplyToggleMutation>;
export type UpdateAutoReplyToggleMutationOptions = Apollo.BaseMutationOptions<UpdateAutoReplyToggleMutation, UpdateAutoReplyToggleMutationVariables>;
export const UpdateBlockedSenderDocument = gql`
    mutation UpdateBlockedSender($blockedSender: LdapBlockedSenderForm!) {
  updateBlockedSender(blockedSender: $blockedSender) {
    success
  }
}
    `;
export type UpdateBlockedSenderMutationFn = Apollo.MutationFunction<UpdateBlockedSenderMutation, UpdateBlockedSenderMutationVariables>;

/**
 * __useUpdateBlockedSenderMutation__
 *
 * To run a mutation, you first call `useUpdateBlockedSenderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlockedSenderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlockedSenderMutation, { data, loading, error }] = useUpdateBlockedSenderMutation({
 *   variables: {
 *      blockedSender: // value for 'blockedSender'
 *   },
 * });
 */
export function useUpdateBlockedSenderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlockedSenderMutation, UpdateBlockedSenderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlockedSenderMutation, UpdateBlockedSenderMutationVariables>(UpdateBlockedSenderDocument, options);
      }
export type UpdateBlockedSenderMutationHookResult = ReturnType<typeof useUpdateBlockedSenderMutation>;
export type UpdateBlockedSenderMutationResult = Apollo.MutationResult<UpdateBlockedSenderMutation>;
export type UpdateBlockedSenderMutationOptions = Apollo.BaseMutationOptions<UpdateBlockedSenderMutation, UpdateBlockedSenderMutationVariables>;
export const UpdateContactDocument = gql`
    mutation UpdateContact($id: String!, $contact: ContactForm!) {
  updateContact(id: $id, contact: $contact) {
    id
    title
    firstName
    middleName
    lastName
    displayName
    nickName
    company
    url
    dateOfBirth
    notes
    profileImage
    emails {
      emailAddress
      emailAddressType
      isDefault
    }
    phoneNumbers {
      phoneNumber
      phoneNumberType
      isDefault
    }
    addresses {
      addressType
      state
      city
      zipCode
      streetAddress
      country
    }
    labels {
      id
      name
    }
  }
}
    `;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const UpdateDisplayNameDocument = gql`
    mutation UpdateDisplayName($value: String!) {
  patchDisplayName(value: $value) {
    displayName
  }
}
    `;
export type UpdateDisplayNameMutationFn = Apollo.MutationFunction<UpdateDisplayNameMutation, UpdateDisplayNameMutationVariables>;

/**
 * __useUpdateDisplayNameMutation__
 *
 * To run a mutation, you first call `useUpdateDisplayNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisplayNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisplayNameMutation, { data, loading, error }] = useUpdateDisplayNameMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateDisplayNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDisplayNameMutation, UpdateDisplayNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDisplayNameMutation, UpdateDisplayNameMutationVariables>(UpdateDisplayNameDocument, options);
      }
export type UpdateDisplayNameMutationHookResult = ReturnType<typeof useUpdateDisplayNameMutation>;
export type UpdateDisplayNameMutationResult = Apollo.MutationResult<UpdateDisplayNameMutation>;
export type UpdateDisplayNameMutationOptions = Apollo.BaseMutationOptions<UpdateDisplayNameMutation, UpdateDisplayNameMutationVariables>;
export const UpdateFiltersDocument = gql`
    mutation UpdateFilters($filters: [FilterForm!]!) {
  updateFilters(filters: $filters) {
    success
  }
}
    `;
export type UpdateFiltersMutationFn = Apollo.MutationFunction<UpdateFiltersMutation, UpdateFiltersMutationVariables>;

/**
 * __useUpdateFiltersMutation__
 *
 * To run a mutation, you first call `useUpdateFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFiltersMutation, { data, loading, error }] = useUpdateFiltersMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUpdateFiltersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFiltersMutation, UpdateFiltersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFiltersMutation, UpdateFiltersMutationVariables>(UpdateFiltersDocument, options);
      }
export type UpdateFiltersMutationHookResult = ReturnType<typeof useUpdateFiltersMutation>;
export type UpdateFiltersMutationResult = Apollo.MutationResult<UpdateFiltersMutation>;
export type UpdateFiltersMutationOptions = Apollo.BaseMutationOptions<UpdateFiltersMutation, UpdateFiltersMutationVariables>;
export const UpdateLabelDocument = gql`
    mutation UpdateLabel($id: String!, $label: LabelForm!) {
  updateLabel(id: $id, label: $label) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;
export type UpdateLabelMutationFn = Apollo.MutationFunction<UpdateLabelMutation, UpdateLabelMutationVariables>;

/**
 * __useUpdateLabelMutation__
 *
 * To run a mutation, you first call `useUpdateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabelMutation, { data, loading, error }] = useUpdateLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useUpdateLabelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLabelMutation, UpdateLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLabelMutation, UpdateLabelMutationVariables>(UpdateLabelDocument, options);
      }
export type UpdateLabelMutationHookResult = ReturnType<typeof useUpdateLabelMutation>;
export type UpdateLabelMutationResult = Apollo.MutationResult<UpdateLabelMutation>;
export type UpdateLabelMutationOptions = Apollo.BaseMutationOptions<UpdateLabelMutation, UpdateLabelMutationVariables>;
export const UpdateMailForwardingDocument = gql`
    mutation UpdateMailForwarding($mailForwarding: Boolean!, $mailForwardingAddress: [String!]!) {
  updateMailForwarding(
    mailForwarding: {mailForwarding: $mailForwarding, mailForwardingAddress: $mailForwardingAddress}
  ) {
    success
  }
}
    `;
export type UpdateMailForwardingMutationFn = Apollo.MutationFunction<UpdateMailForwardingMutation, UpdateMailForwardingMutationVariables>;

/**
 * __useUpdateMailForwardingMutation__
 *
 * To run a mutation, you first call `useUpdateMailForwardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMailForwardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMailForwardingMutation, { data, loading, error }] = useUpdateMailForwardingMutation({
 *   variables: {
 *      mailForwarding: // value for 'mailForwarding'
 *      mailForwardingAddress: // value for 'mailForwardingAddress'
 *   },
 * });
 */
export function useUpdateMailForwardingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMailForwardingMutation, UpdateMailForwardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMailForwardingMutation, UpdateMailForwardingMutationVariables>(UpdateMailForwardingDocument, options);
      }
export type UpdateMailForwardingMutationHookResult = ReturnType<typeof useUpdateMailForwardingMutation>;
export type UpdateMailForwardingMutationResult = Apollo.MutationResult<UpdateMailForwardingMutation>;
export type UpdateMailForwardingMutationOptions = Apollo.BaseMutationOptions<UpdateMailForwardingMutation, UpdateMailForwardingMutationVariables>;
export const PatchSettingDocument = gql`
    mutation PatchSetting($field: String!, $value: String!) {
  patchSetting(field: $field, value: $value) {
    displayName
    replyTo
    preferredLanguage
    emailPerPage
    timeZone
    isDisplayImageEnabled
    isAutoSaveDraftEnabled
    isEmailPermanentlyDeleted
    isAutoMailboxReloadEnabled
    isDisplayEmailAsHtmlEnabled
    createdAt
    updatedAt
  }
}
    `;
export type PatchSettingMutationFn = Apollo.MutationFunction<PatchSettingMutation, PatchSettingMutationVariables>;

/**
 * __usePatchSettingMutation__
 *
 * To run a mutation, you first call `usePatchSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchSettingMutation, { data, loading, error }] = usePatchSettingMutation({
 *   variables: {
 *      field: // value for 'field'
 *      value: // value for 'value'
 *   },
 * });
 */
export function usePatchSettingMutation(baseOptions?: Apollo.MutationHookOptions<PatchSettingMutation, PatchSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchSettingMutation, PatchSettingMutationVariables>(PatchSettingDocument, options);
      }
export type PatchSettingMutationHookResult = ReturnType<typeof usePatchSettingMutation>;
export type PatchSettingMutationResult = Apollo.MutationResult<PatchSettingMutation>;
export type PatchSettingMutationOptions = Apollo.BaseMutationOptions<PatchSettingMutation, PatchSettingMutationVariables>;
export const UpdateSignatureDocument = gql`
    mutation UpdateSignature($id: String!, $signature: SignatureForm!) {
  updateSignature(id: $id, signature: $signature) {
    signature
    autoInsert
    id
  }
}
    `;
export type UpdateSignatureMutationFn = Apollo.MutationFunction<UpdateSignatureMutation, UpdateSignatureMutationVariables>;

/**
 * __useUpdateSignatureMutation__
 *
 * To run a mutation, you first call `useUpdateSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSignatureMutation, { data, loading, error }] = useUpdateSignatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useUpdateSignatureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSignatureMutation, UpdateSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSignatureMutation, UpdateSignatureMutationVariables>(UpdateSignatureDocument, options);
      }
export type UpdateSignatureMutationHookResult = ReturnType<typeof useUpdateSignatureMutation>;
export type UpdateSignatureMutationResult = Apollo.MutationResult<UpdateSignatureMutation>;
export type UpdateSignatureMutationOptions = Apollo.BaseMutationOptions<UpdateSignatureMutation, UpdateSignatureMutationVariables>;
export const UpdateSpamSettingDocument = gql`
    mutation UpdateSpamSetting($spamSetting: String!) {
  updateSpamSetting(spam: {spamSetting: $spamSetting}) {
    success
  }
}
    `;
export type UpdateSpamSettingMutationFn = Apollo.MutationFunction<UpdateSpamSettingMutation, UpdateSpamSettingMutationVariables>;

/**
 * __useUpdateSpamSettingMutation__
 *
 * To run a mutation, you first call `useUpdateSpamSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpamSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpamSettingMutation, { data, loading, error }] = useUpdateSpamSettingMutation({
 *   variables: {
 *      spamSetting: // value for 'spamSetting'
 *   },
 * });
 */
export function useUpdateSpamSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpamSettingMutation, UpdateSpamSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpamSettingMutation, UpdateSpamSettingMutationVariables>(UpdateSpamSettingDocument, options);
      }
export type UpdateSpamSettingMutationHookResult = ReturnType<typeof useUpdateSpamSettingMutation>;
export type UpdateSpamSettingMutationResult = Apollo.MutationResult<UpdateSpamSettingMutation>;
export type UpdateSpamSettingMutationOptions = Apollo.BaseMutationOptions<UpdateSpamSettingMutation, UpdateSpamSettingMutationVariables>;
export const PatchTimeZoneDocument = gql`
    mutation PatchTimeZone($value: String!) {
  patchTimeZone(value: $value) {
    timeZone
  }
}
    `;
export type PatchTimeZoneMutationFn = Apollo.MutationFunction<PatchTimeZoneMutation, PatchTimeZoneMutationVariables>;

/**
 * __usePatchTimeZoneMutation__
 *
 * To run a mutation, you first call `usePatchTimeZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchTimeZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchTimeZoneMutation, { data, loading, error }] = usePatchTimeZoneMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function usePatchTimeZoneMutation(baseOptions?: Apollo.MutationHookOptions<PatchTimeZoneMutation, PatchTimeZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchTimeZoneMutation, PatchTimeZoneMutationVariables>(PatchTimeZoneDocument, options);
      }
export type PatchTimeZoneMutationHookResult = ReturnType<typeof usePatchTimeZoneMutation>;
export type PatchTimeZoneMutationResult = Apollo.MutationResult<PatchTimeZoneMutation>;
export type PatchTimeZoneMutationOptions = Apollo.BaseMutationOptions<PatchTimeZoneMutation, PatchTimeZoneMutationVariables>;
export type ContactAddressEntityKeySpecifier = ('streetAddress' | 'city' | 'state' | 'zipCode' | 'country' | 'addressType' | ContactAddressEntityKeySpecifier)[];
export type ContactAddressEntityFieldPolicy = {
	streetAddress?: FieldPolicy<any> | FieldReadFunction<any>,
	city?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	zipCode?: FieldPolicy<any> | FieldReadFunction<any>,
	country?: FieldPolicy<any> | FieldReadFunction<any>,
	addressType?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBulkCreateEntityKeySpecifier = ('success' | 'createCount' | ContactBulkCreateEntityKeySpecifier)[];
export type ContactBulkCreateEntityFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	createCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBulkDeleteEntityKeySpecifier = ('success' | 'deleteCount' | ContactBulkDeleteEntityKeySpecifier)[];
export type ContactBulkDeleteEntityFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactBulkUpdateEntityKeySpecifier = ('success' | ContactBulkUpdateEntityKeySpecifier)[];
export type ContactBulkUpdateEntityFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactCollectionKeySpecifier = ('total' | 'currentPage' | 'limit' | 'data' | ContactCollectionKeySpecifier)[];
export type ContactCollectionFieldPolicy = {
	total?: FieldPolicy<any> | FieldReadFunction<any>,
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	limit?: FieldPolicy<any> | FieldReadFunction<any>,
	data?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactEmailEntityKeySpecifier = ('emailAddress' | 'emailAddressType' | 'isDefault' | ContactEmailEntityKeySpecifier)[];
export type ContactEmailEntityFieldPolicy = {
	emailAddress?: FieldPolicy<any> | FieldReadFunction<any>,
	emailAddressType?: FieldPolicy<any> | FieldReadFunction<any>,
	isDefault?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactEntityKeySpecifier = ('createdAt' | 'updatedAt' | 'id' | 'title' | 'firstName' | 'middleName' | 'lastName' | 'displayName' | 'nickName' | 'company' | 'url' | 'dateOfBirth' | 'notes' | 'profileImage' | 'emails' | 'phoneNumbers' | 'addresses' | 'labels' | ContactEntityKeySpecifier)[];
export type ContactEntityFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	middleName?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	displayName?: FieldPolicy<any> | FieldReadFunction<any>,
	nickName?: FieldPolicy<any> | FieldReadFunction<any>,
	company?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>,
	dateOfBirth?: FieldPolicy<any> | FieldReadFunction<any>,
	notes?: FieldPolicy<any> | FieldReadFunction<any>,
	profileImage?: FieldPolicy<any> | FieldReadFunction<any>,
	emails?: FieldPolicy<any> | FieldReadFunction<any>,
	phoneNumbers?: FieldPolicy<any> | FieldReadFunction<any>,
	addresses?: FieldPolicy<any> | FieldReadFunction<any>,
	labels?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactExportFormKeySpecifier = ('vCard' | ContactExportFormKeySpecifier)[];
export type ContactExportFormFieldPolicy = {
	vCard?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactPhoneEntityKeySpecifier = ('phoneNumber' | 'phoneNumberType' | 'isDefault' | ContactPhoneEntityKeySpecifier)[];
export type ContactPhoneEntityFieldPolicy = {
	phoneNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	phoneNumberType?: FieldPolicy<any> | FieldReadFunction<any>,
	isDefault?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactReSyncEntityKeySpecifier = ('success' | 'successContactCount' | 'successLabelCount' | 'errorContactCount' | 'errorLabelCount' | ContactReSyncEntityKeySpecifier)[];
export type ContactReSyncEntityFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	successContactCount?: FieldPolicy<any> | FieldReadFunction<any>,
	successLabelCount?: FieldPolicy<any> | FieldReadFunction<any>,
	errorContactCount?: FieldPolicy<any> | FieldReadFunction<any>,
	errorLabelCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactResyncAllowedEntityKeySpecifier = ('lastReSyncDate' | 'isAllowed' | ContactResyncAllowedEntityKeySpecifier)[];
export type ContactResyncAllowedEntityFieldPolicy = {
	lastReSyncDate?: FieldPolicy<any> | FieldReadFunction<any>,
	isAllowed?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactWithLabelEntityKeySpecifier = ('createdAt' | 'updatedAt' | 'id' | 'title' | 'firstName' | 'middleName' | 'lastName' | 'displayName' | 'nickName' | 'company' | 'url' | 'dateOfBirth' | 'notes' | 'profileImage' | 'emails' | 'phoneNumbers' | 'addresses' | 'labels' | ContactWithLabelEntityKeySpecifier)[];
export type ContactWithLabelEntityFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	middleName?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	displayName?: FieldPolicy<any> | FieldReadFunction<any>,
	nickName?: FieldPolicy<any> | FieldReadFunction<any>,
	company?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>,
	dateOfBirth?: FieldPolicy<any> | FieldReadFunction<any>,
	notes?: FieldPolicy<any> | FieldReadFunction<any>,
	profileImage?: FieldPolicy<any> | FieldReadFunction<any>,
	emails?: FieldPolicy<any> | FieldReadFunction<any>,
	phoneNumbers?: FieldPolicy<any> | FieldReadFunction<any>,
	addresses?: FieldPolicy<any> | FieldReadFunction<any>,
	labels?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CountKeySpecifier = ('count' | CountKeySpecifier)[];
export type CountFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>
};
export type EligibilityStatusKeySpecifier = ('isEligible' | EligibilityStatusKeySpecifier)[];
export type EligibilityStatusFieldPolicy = {
	isEligible?: FieldPolicy<any> | FieldReadFunction<any>
};
export type EmailAddressEntityKeySpecifier = ('name' | 'address' | EmailAddressEntityKeySpecifier)[];
export type EmailAddressEntityFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	address?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FilterEntityKeySpecifier = ('name' | 'clause' | 'highPriority' | 'actionPriority' | 'actionDelete' | 'actionForward' | 'actionMove' | 'moveFolder' | 'forwardList' | 'rules' | FilterEntityKeySpecifier)[];
export type FilterEntityFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	clause?: FieldPolicy<any> | FieldReadFunction<any>,
	highPriority?: FieldPolicy<any> | FieldReadFunction<any>,
	actionPriority?: FieldPolicy<any> | FieldReadFunction<any>,
	actionDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	actionForward?: FieldPolicy<any> | FieldReadFunction<any>,
	actionMove?: FieldPolicy<any> | FieldReadFunction<any>,
	moveFolder?: FieldPolicy<any> | FieldReadFunction<any>,
	forwardList?: FieldPolicy<any> | FieldReadFunction<any>,
	rules?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FilterRulesEntityKeySpecifier = ('field' | 'operator' | 'value' | FilterRulesEntityKeySpecifier)[];
export type FilterRulesEntityFieldPolicy = {
	field?: FieldPolicy<any> | FieldReadFunction<any>,
	operator?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ImapAttachmentListEntityKeySpecifier = ('fileName' | 'fileSize' | 'id' | 'externalRef' | ImapAttachmentListEntityKeySpecifier)[];
export type ImapAttachmentListEntityFieldPolicy = {
	fileName?: FieldPolicy<any> | FieldReadFunction<any>,
	fileSize?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	externalRef?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InboxMailBoxStatusKeySpecifier = ('status' | 'unreadEmailCount' | 'unreadEmails' | 'allowEmailOptIn' | 'emailServiceUrl' | InboxMailBoxStatusKeySpecifier)[];
export type InboxMailBoxStatusFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	unreadEmailCount?: FieldPolicy<any> | FieldReadFunction<any>,
	unreadEmails?: FieldPolicy<any> | FieldReadFunction<any>,
	allowEmailOptIn?: FieldPolicy<any> | FieldReadFunction<any>,
	emailServiceUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LabelDeleteStatusKeySpecifier = ('success' | 'totalContactsUpdated' | LabelDeleteStatusKeySpecifier)[];
export type LabelDeleteStatusFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	totalContactsUpdated?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LabelEntityKeySpecifier = ('id' | 'name' | 'createdAt' | 'updatedAt' | LabelEntityKeySpecifier)[];
export type LabelEntityFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LdapMailPreferenceEntityKeySpecifier = ('mailboxStatus' | 'mailBlockSendersActive' | 'asfDeliveryActionDirty' | 'mailAutoReplyMode' | 'mailForwarding' | 'mailForwardingAddress' | 'mailDeliveryOption' | LdapMailPreferenceEntityKeySpecifier)[];
export type LdapMailPreferenceEntityFieldPolicy = {
	mailboxStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	mailBlockSendersActive?: FieldPolicy<any> | FieldReadFunction<any>,
	asfDeliveryActionDirty?: FieldPolicy<any> | FieldReadFunction<any>,
	mailAutoReplyMode?: FieldPolicy<any> | FieldReadFunction<any>,
	mailForwarding?: FieldPolicy<any> | FieldReadFunction<any>,
	mailForwardingAddress?: FieldPolicy<any> | FieldReadFunction<any>,
	mailDeliveryOption?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LdapSenderControlEntityKeySpecifier = ('approvedSenders' | 'blockedSenders' | LdapSenderControlEntityKeySpecifier)[];
export type LdapSenderControlEntityFieldPolicy = {
	approvedSenders?: FieldPolicy<any> | FieldReadFunction<any>,
	blockedSenders?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LdapSettingEntityKeySpecifier = ('mailUserPreferences' | 'senderControl' | 'mxos' | LdapSettingEntityKeySpecifier)[];
export type LdapSettingEntityFieldPolicy = {
	mailUserPreferences?: FieldPolicy<any> | FieldReadFunction<any>,
	senderControl?: FieldPolicy<any> | FieldReadFunction<any>,
	mxos?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MailboxDeleteEntityKeySpecifier = ('mailboxDeleteStatus' | MailboxDeleteEntityKeySpecifier)[];
export type MailboxDeleteEntityFieldPolicy = {
	mailboxDeleteStatus?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MailboxDeleteStatusEntityKeySpecifier = ('mailbox' | 'success' | 'error' | 'isGlobal' | MailboxDeleteStatusEntityKeySpecifier)[];
export type MailboxDeleteStatusEntityFieldPolicy = {
	mailbox?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	isGlobal?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MailboxEntityKeySpecifier = ('id' | 'delimiter' | 'name' | MailboxEntityKeySpecifier)[];
export type MailboxEntityFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	delimiter?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MailboxStatusEntityKeySpecifier = ('id' | 'name' | 'totalMessageCount' | 'newMessageCount' | 'unseenMessageCount' | MailboxStatusEntityKeySpecifier)[];
export type MailboxStatusEntityFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	totalMessageCount?: FieldPolicy<any> | FieldReadFunction<any>,
	newMessageCount?: FieldPolicy<any> | FieldReadFunction<any>,
	unseenMessageCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MessageAppendResponseEntityKeySpecifier = ('isSavedToImap' | 'isSentToSmtp' | 'uid' | 'smtpResponse' | MessageAppendResponseEntityKeySpecifier)[];
export type MessageAppendResponseEntityFieldPolicy = {
	isSavedToImap?: FieldPolicy<any> | FieldReadFunction<any>,
	isSentToSmtp?: FieldPolicy<any> | FieldReadFunction<any>,
	uid?: FieldPolicy<any> | FieldReadFunction<any>,
	smtpResponse?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MessageBodyEntityKeySpecifier = ('html' | 'text' | 'noImageHtml' | 'fullText' | MessageBodyEntityKeySpecifier)[];
export type MessageBodyEntityFieldPolicy = {
	html?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	noImageHtml?: FieldPolicy<any> | FieldReadFunction<any>,
	fullText?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MessageCollectionKeySpecifier = ('total' | 'currentPage' | 'limit' | 'data' | MessageCollectionKeySpecifier)[];
export type MessageCollectionFieldPolicy = {
	total?: FieldPolicy<any> | FieldReadFunction<any>,
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	limit?: FieldPolicy<any> | FieldReadFunction<any>,
	data?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MessageEntityKeySpecifier = ('from' | 'to' | 'cc' | 'bcc' | 'replyTo' | 'inReplyTo' | 'messageId' | 'references' | 'priority' | 'subject' | 'body' | 'date' | 'attachments' | 'flags' | 'uid' | 'readReceipt' | MessageEntityKeySpecifier)[];
export type MessageEntityFieldPolicy = {
	from?: FieldPolicy<any> | FieldReadFunction<any>,
	to?: FieldPolicy<any> | FieldReadFunction<any>,
	cc?: FieldPolicy<any> | FieldReadFunction<any>,
	bcc?: FieldPolicy<any> | FieldReadFunction<any>,
	replyTo?: FieldPolicy<any> | FieldReadFunction<any>,
	inReplyTo?: FieldPolicy<any> | FieldReadFunction<any>,
	messageId?: FieldPolicy<any> | FieldReadFunction<any>,
	references?: FieldPolicy<any> | FieldReadFunction<any>,
	priority?: FieldPolicy<any> | FieldReadFunction<any>,
	subject?: FieldPolicy<any> | FieldReadFunction<any>,
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	attachments?: FieldPolicy<any> | FieldReadFunction<any>,
	flags?: FieldPolicy<any> | FieldReadFunction<any>,
	uid?: FieldPolicy<any> | FieldReadFunction<any>,
	readReceipt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MessageListEntityKeySpecifier = ('to' | 'from' | 'seqNo' | 'messageId' | 'subject' | 'date' | 'priority' | 'flags' | 'uid' | 'attachmentCount' | 'readReceipt' | MessageListEntityKeySpecifier)[];
export type MessageListEntityFieldPolicy = {
	to?: FieldPolicy<any> | FieldReadFunction<any>,
	from?: FieldPolicy<any> | FieldReadFunction<any>,
	seqNo?: FieldPolicy<any> | FieldReadFunction<any>,
	messageId?: FieldPolicy<any> | FieldReadFunction<any>,
	subject?: FieldPolicy<any> | FieldReadFunction<any>,
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	priority?: FieldPolicy<any> | FieldReadFunction<any>,
	flags?: FieldPolicy<any> | FieldReadFunction<any>,
	uid?: FieldPolicy<any> | FieldReadFunction<any>,
	attachmentCount?: FieldPolicy<any> | FieldReadFunction<any>,
	readReceipt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MessageRawEntityKeySpecifier = ('uid' | 'raw' | MessageRawEntityKeySpecifier)[];
export type MessageRawEntityFieldPolicy = {
	uid?: FieldPolicy<any> | FieldReadFunction<any>,
	raw?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('createLabel' | 'updateLabel' | 'deleteLabel' | 'postOptInStatus' | 'saveDraft' | 'sentMail' | 'deleteMessage' | 'deleteMessageBulk' | 'moveMessages' | 'markMessagesSeen' | 'markMessagesUnseen' | 'markMessagesImportant' | 'markMessagesUnimportant' | 'createSignature' | 'updateSignature' | 'patchAutoInsertSignature' | 'patchTitleSignature' | 'patchSignature' | 'deleteSignature' | 'patchSetting' | 'patchDisplayName' | 'patchTimeZone' | 'patchReplyTo' | 'patchPreferredLanguage' | 'patchEmailPerPage' | 'patchAutoSaveDraft' | 'pathAutoMailboxReload' | 'patchIsDisplayImage' | 'patchIsDisplayHtml' | 'patchIsEmailPermanentDelete' | 'updateMailForwarding' | 'updateApprovedSender' | 'updateBlockedSender' | 'updateSpamSetting' | 'updateMailboxStatus' | 'updateAutoReply' | 'createMailbox' | 'renameMailbox' | 'deleteMailbox' | 'deleteStorage' | 'createContact' | 'updateContact' | 'createBulkContact' | 'deleteBulkContact' | 'deleteContact' | 'patchContactLabel' | 'putBulkContactLabel' | 'reSyncContact' | 'updateFilters' | 'contactMigration' | 'settingMigration' | 'contactRollback' | 'settingRollback' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	createLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	updateLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	postOptInStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	saveDraft?: FieldPolicy<any> | FieldReadFunction<any>,
	sentMail?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteMessageBulk?: FieldPolicy<any> | FieldReadFunction<any>,
	moveMessages?: FieldPolicy<any> | FieldReadFunction<any>,
	markMessagesSeen?: FieldPolicy<any> | FieldReadFunction<any>,
	markMessagesUnseen?: FieldPolicy<any> | FieldReadFunction<any>,
	markMessagesImportant?: FieldPolicy<any> | FieldReadFunction<any>,
	markMessagesUnimportant?: FieldPolicy<any> | FieldReadFunction<any>,
	createSignature?: FieldPolicy<any> | FieldReadFunction<any>,
	updateSignature?: FieldPolicy<any> | FieldReadFunction<any>,
	patchAutoInsertSignature?: FieldPolicy<any> | FieldReadFunction<any>,
	patchTitleSignature?: FieldPolicy<any> | FieldReadFunction<any>,
	patchSignature?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteSignature?: FieldPolicy<any> | FieldReadFunction<any>,
	patchSetting?: FieldPolicy<any> | FieldReadFunction<any>,
	patchDisplayName?: FieldPolicy<any> | FieldReadFunction<any>,
	patchTimeZone?: FieldPolicy<any> | FieldReadFunction<any>,
	patchReplyTo?: FieldPolicy<any> | FieldReadFunction<any>,
	patchPreferredLanguage?: FieldPolicy<any> | FieldReadFunction<any>,
	patchEmailPerPage?: FieldPolicy<any> | FieldReadFunction<any>,
	patchAutoSaveDraft?: FieldPolicy<any> | FieldReadFunction<any>,
	pathAutoMailboxReload?: FieldPolicy<any> | FieldReadFunction<any>,
	patchIsDisplayImage?: FieldPolicy<any> | FieldReadFunction<any>,
	patchIsDisplayHtml?: FieldPolicy<any> | FieldReadFunction<any>,
	patchIsEmailPermanentDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	updateMailForwarding?: FieldPolicy<any> | FieldReadFunction<any>,
	updateApprovedSender?: FieldPolicy<any> | FieldReadFunction<any>,
	updateBlockedSender?: FieldPolicy<any> | FieldReadFunction<any>,
	updateSpamSetting?: FieldPolicy<any> | FieldReadFunction<any>,
	updateMailboxStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	updateAutoReply?: FieldPolicy<any> | FieldReadFunction<any>,
	createMailbox?: FieldPolicy<any> | FieldReadFunction<any>,
	renameMailbox?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteMailbox?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteStorage?: FieldPolicy<any> | FieldReadFunction<any>,
	createContact?: FieldPolicy<any> | FieldReadFunction<any>,
	updateContact?: FieldPolicy<any> | FieldReadFunction<any>,
	createBulkContact?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteBulkContact?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteContact?: FieldPolicy<any> | FieldReadFunction<any>,
	patchContactLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	putBulkContactLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	reSyncContact?: FieldPolicy<any> | FieldReadFunction<any>,
	updateFilters?: FieldPolicy<any> | FieldReadFunction<any>,
	contactMigration?: FieldPolicy<any> | FieldReadFunction<any>,
	settingMigration?: FieldPolicy<any> | FieldReadFunction<any>,
	contactRollback?: FieldPolicy<any> | FieldReadFunction<any>,
	settingRollback?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MxosMailReceiptEntityKeySpecifier = ('autoReplyMode' | 'autoReplyMessage' | MxosMailReceiptEntityKeySpecifier)[];
export type MxosMailReceiptEntityFieldPolicy = {
	autoReplyMode?: FieldPolicy<any> | FieldReadFunction<any>,
	autoReplyMessage?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OptInKeySpecifier = ('createdAt' | 'updatedAt' | 'id' | 'email' | 'userId' | 'optInType' | 'optInStatus' | OptInKeySpecifier)[];
export type OptInFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	userId?: FieldPolicy<any> | FieldReadFunction<any>,
	optInType?: FieldPolicy<any> | FieldReadFunction<any>,
	optInStatus?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OptInResponseKeySpecifier = ('eligibility' | 'status' | OptInResponseKeySpecifier)[];
export type OptInResponseFieldPolicy = {
	eligibility?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('getLabelCollection' | 'getLabel' | 'getOptInStatus' | 'getEligibilityStatus' | 'getContactReSyncAllowed' | 'getQuota' | 'getMessageCollection' | 'getMessage' | 'getRawMessage' | 'getSignatureCollection' | 'getSignature' | 'getSetting' | 'getLdapSetting' | 'getMxosMailReceipt' | 'getMailboxCollection' | 'getGlobalMailboxStatus' | 'getMailboxStatus' | 'getInboxMailBoxStatus' | 'getContactCollection' | 'getContact' | 'getContactCount' | 'exportContact' | 'getFilters' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	getLabelCollection?: FieldPolicy<any> | FieldReadFunction<any>,
	getLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	getOptInStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	getEligibilityStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	getContactReSyncAllowed?: FieldPolicy<any> | FieldReadFunction<any>,
	getQuota?: FieldPolicy<any> | FieldReadFunction<any>,
	getMessageCollection?: FieldPolicy<any> | FieldReadFunction<any>,
	getMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	getRawMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	getSignatureCollection?: FieldPolicy<any> | FieldReadFunction<any>,
	getSignature?: FieldPolicy<any> | FieldReadFunction<any>,
	getSetting?: FieldPolicy<any> | FieldReadFunction<any>,
	getLdapSetting?: FieldPolicy<any> | FieldReadFunction<any>,
	getMxosMailReceipt?: FieldPolicy<any> | FieldReadFunction<any>,
	getMailboxCollection?: FieldPolicy<any> | FieldReadFunction<any>,
	getGlobalMailboxStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	getMailboxStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	getInboxMailBoxStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	getContactCollection?: FieldPolicy<any> | FieldReadFunction<any>,
	getContact?: FieldPolicy<any> | FieldReadFunction<any>,
	getContactCount?: FieldPolicy<any> | FieldReadFunction<any>,
	exportContact?: FieldPolicy<any> | FieldReadFunction<any>,
	getFilters?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QuotaEntityKeySpecifier = ('usage' | 'limit' | QuotaEntityKeySpecifier)[];
export type QuotaEntityFieldPolicy = {
	usage?: FieldPolicy<any> | FieldReadFunction<any>,
	limit?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ReadReceiptEntityKeySpecifier = ('readReceipt' | 'readReceiptReplyTo' | ReadReceiptEntityKeySpecifier)[];
export type ReadReceiptEntityFieldPolicy = {
	readReceipt?: FieldPolicy<any> | FieldReadFunction<any>,
	readReceiptReplyTo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SettingEntityKeySpecifier = ('displayName' | 'timeZone' | 'emailPerPage' | 'replyTo' | 'preferredLanguage' | 'isAutoSaveDraftEnabled' | 'isAutoMailboxReloadEnabled' | 'isDisplayImageEnabled' | 'isDisplayEmailAsHtmlEnabled' | 'isEmailPermanentlyDeleted' | 'createdAt' | 'updatedAt' | SettingEntityKeySpecifier)[];
export type SettingEntityFieldPolicy = {
	displayName?: FieldPolicy<any> | FieldReadFunction<any>,
	timeZone?: FieldPolicy<any> | FieldReadFunction<any>,
	emailPerPage?: FieldPolicy<any> | FieldReadFunction<any>,
	replyTo?: FieldPolicy<any> | FieldReadFunction<any>,
	preferredLanguage?: FieldPolicy<any> | FieldReadFunction<any>,
	isAutoSaveDraftEnabled?: FieldPolicy<any> | FieldReadFunction<any>,
	isAutoMailboxReloadEnabled?: FieldPolicy<any> | FieldReadFunction<any>,
	isDisplayImageEnabled?: FieldPolicy<any> | FieldReadFunction<any>,
	isDisplayEmailAsHtmlEnabled?: FieldPolicy<any> | FieldReadFunction<any>,
	isEmailPermanentlyDeleted?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SignatureEntityKeySpecifier = ('id' | 'title' | 'signature' | 'autoInsert' | 'createdAt' | 'updatedAt' | SignatureEntityKeySpecifier)[];
export type SignatureEntityFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	signature?: FieldPolicy<any> | FieldReadFunction<any>,
	autoInsert?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SmtpResponseEntityKeySpecifier = ('messageId' | 'response' | 'responseCode' | 'rejectedErrors' | SmtpResponseEntityKeySpecifier)[];
export type SmtpResponseEntityFieldPolicy = {
	messageId?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	responseCode?: FieldPolicy<any> | FieldReadFunction<any>,
	rejectedErrors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SmtpResponseRejectedErrorEntityKeySpecifier = ('response' | 'responseCode' | 'command' | 'recipient' | SmtpResponseRejectedErrorEntityKeySpecifier)[];
export type SmtpResponseRejectedErrorEntityFieldPolicy = {
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	responseCode?: FieldPolicy<any> | FieldReadFunction<any>,
	command?: FieldPolicy<any> | FieldReadFunction<any>,
	recipient?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SuccessKeySpecifier = ('success' | SuccessKeySpecifier)[];
export type SuccessFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TypedTypePolicies = TypePolicies & {
	ContactAddressEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactAddressEntityKeySpecifier | (() => undefined | ContactAddressEntityKeySpecifier),
		fields?: ContactAddressEntityFieldPolicy,
	},
	ContactBulkCreateEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBulkCreateEntityKeySpecifier | (() => undefined | ContactBulkCreateEntityKeySpecifier),
		fields?: ContactBulkCreateEntityFieldPolicy,
	},
	ContactBulkDeleteEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBulkDeleteEntityKeySpecifier | (() => undefined | ContactBulkDeleteEntityKeySpecifier),
		fields?: ContactBulkDeleteEntityFieldPolicy,
	},
	ContactBulkUpdateEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactBulkUpdateEntityKeySpecifier | (() => undefined | ContactBulkUpdateEntityKeySpecifier),
		fields?: ContactBulkUpdateEntityFieldPolicy,
	},
	ContactCollection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactCollectionKeySpecifier | (() => undefined | ContactCollectionKeySpecifier),
		fields?: ContactCollectionFieldPolicy,
	},
	ContactEmailEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactEmailEntityKeySpecifier | (() => undefined | ContactEmailEntityKeySpecifier),
		fields?: ContactEmailEntityFieldPolicy,
	},
	ContactEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactEntityKeySpecifier | (() => undefined | ContactEntityKeySpecifier),
		fields?: ContactEntityFieldPolicy,
	},
	ContactExportForm?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactExportFormKeySpecifier | (() => undefined | ContactExportFormKeySpecifier),
		fields?: ContactExportFormFieldPolicy,
	},
	ContactPhoneEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactPhoneEntityKeySpecifier | (() => undefined | ContactPhoneEntityKeySpecifier),
		fields?: ContactPhoneEntityFieldPolicy,
	},
	ContactReSyncEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactReSyncEntityKeySpecifier | (() => undefined | ContactReSyncEntityKeySpecifier),
		fields?: ContactReSyncEntityFieldPolicy,
	},
	ContactResyncAllowedEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactResyncAllowedEntityKeySpecifier | (() => undefined | ContactResyncAllowedEntityKeySpecifier),
		fields?: ContactResyncAllowedEntityFieldPolicy,
	},
	ContactWithLabelEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactWithLabelEntityKeySpecifier | (() => undefined | ContactWithLabelEntityKeySpecifier),
		fields?: ContactWithLabelEntityFieldPolicy,
	},
	Count?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CountKeySpecifier | (() => undefined | CountKeySpecifier),
		fields?: CountFieldPolicy,
	},
	EligibilityStatus?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | EligibilityStatusKeySpecifier | (() => undefined | EligibilityStatusKeySpecifier),
		fields?: EligibilityStatusFieldPolicy,
	},
	EmailAddressEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | EmailAddressEntityKeySpecifier | (() => undefined | EmailAddressEntityKeySpecifier),
		fields?: EmailAddressEntityFieldPolicy,
	},
	FilterEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FilterEntityKeySpecifier | (() => undefined | FilterEntityKeySpecifier),
		fields?: FilterEntityFieldPolicy,
	},
	FilterRulesEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FilterRulesEntityKeySpecifier | (() => undefined | FilterRulesEntityKeySpecifier),
		fields?: FilterRulesEntityFieldPolicy,
	},
	ImapAttachmentListEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ImapAttachmentListEntityKeySpecifier | (() => undefined | ImapAttachmentListEntityKeySpecifier),
		fields?: ImapAttachmentListEntityFieldPolicy,
	},
	InboxMailBoxStatus?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InboxMailBoxStatusKeySpecifier | (() => undefined | InboxMailBoxStatusKeySpecifier),
		fields?: InboxMailBoxStatusFieldPolicy,
	},
	LabelDeleteStatus?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LabelDeleteStatusKeySpecifier | (() => undefined | LabelDeleteStatusKeySpecifier),
		fields?: LabelDeleteStatusFieldPolicy,
	},
	LabelEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LabelEntityKeySpecifier | (() => undefined | LabelEntityKeySpecifier),
		fields?: LabelEntityFieldPolicy,
	},
	LdapMailPreferenceEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LdapMailPreferenceEntityKeySpecifier | (() => undefined | LdapMailPreferenceEntityKeySpecifier),
		fields?: LdapMailPreferenceEntityFieldPolicy,
	},
	LdapSenderControlEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LdapSenderControlEntityKeySpecifier | (() => undefined | LdapSenderControlEntityKeySpecifier),
		fields?: LdapSenderControlEntityFieldPolicy,
	},
	LdapSettingEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LdapSettingEntityKeySpecifier | (() => undefined | LdapSettingEntityKeySpecifier),
		fields?: LdapSettingEntityFieldPolicy,
	},
	MailboxDeleteEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MailboxDeleteEntityKeySpecifier | (() => undefined | MailboxDeleteEntityKeySpecifier),
		fields?: MailboxDeleteEntityFieldPolicy,
	},
	MailboxDeleteStatusEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MailboxDeleteStatusEntityKeySpecifier | (() => undefined | MailboxDeleteStatusEntityKeySpecifier),
		fields?: MailboxDeleteStatusEntityFieldPolicy,
	},
	MailboxEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MailboxEntityKeySpecifier | (() => undefined | MailboxEntityKeySpecifier),
		fields?: MailboxEntityFieldPolicy,
	},
	MailboxStatusEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MailboxStatusEntityKeySpecifier | (() => undefined | MailboxStatusEntityKeySpecifier),
		fields?: MailboxStatusEntityFieldPolicy,
	},
	MessageAppendResponseEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MessageAppendResponseEntityKeySpecifier | (() => undefined | MessageAppendResponseEntityKeySpecifier),
		fields?: MessageAppendResponseEntityFieldPolicy,
	},
	MessageBodyEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MessageBodyEntityKeySpecifier | (() => undefined | MessageBodyEntityKeySpecifier),
		fields?: MessageBodyEntityFieldPolicy,
	},
	MessageCollection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MessageCollectionKeySpecifier | (() => undefined | MessageCollectionKeySpecifier),
		fields?: MessageCollectionFieldPolicy,
	},
	MessageEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MessageEntityKeySpecifier | (() => undefined | MessageEntityKeySpecifier),
		fields?: MessageEntityFieldPolicy,
	},
	MessageListEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MessageListEntityKeySpecifier | (() => undefined | MessageListEntityKeySpecifier),
		fields?: MessageListEntityFieldPolicy,
	},
	MessageRawEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MessageRawEntityKeySpecifier | (() => undefined | MessageRawEntityKeySpecifier),
		fields?: MessageRawEntityFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	MxosMailReceiptEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MxosMailReceiptEntityKeySpecifier | (() => undefined | MxosMailReceiptEntityKeySpecifier),
		fields?: MxosMailReceiptEntityFieldPolicy,
	},
	OptIn?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OptInKeySpecifier | (() => undefined | OptInKeySpecifier),
		fields?: OptInFieldPolicy,
	},
	OptInResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OptInResponseKeySpecifier | (() => undefined | OptInResponseKeySpecifier),
		fields?: OptInResponseFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	QuotaEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QuotaEntityKeySpecifier | (() => undefined | QuotaEntityKeySpecifier),
		fields?: QuotaEntityFieldPolicy,
	},
	ReadReceiptEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ReadReceiptEntityKeySpecifier | (() => undefined | ReadReceiptEntityKeySpecifier),
		fields?: ReadReceiptEntityFieldPolicy,
	},
	SettingEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SettingEntityKeySpecifier | (() => undefined | SettingEntityKeySpecifier),
		fields?: SettingEntityFieldPolicy,
	},
	SignatureEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SignatureEntityKeySpecifier | (() => undefined | SignatureEntityKeySpecifier),
		fields?: SignatureEntityFieldPolicy,
	},
	SmtpResponseEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SmtpResponseEntityKeySpecifier | (() => undefined | SmtpResponseEntityKeySpecifier),
		fields?: SmtpResponseEntityFieldPolicy,
	},
	SmtpResponseRejectedErrorEntity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SmtpResponseRejectedErrorEntityKeySpecifier | (() => undefined | SmtpResponseRejectedErrorEntityKeySpecifier),
		fields?: SmtpResponseRejectedErrorEntityFieldPolicy,
	},
	Success?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SuccessKeySpecifier | (() => undefined | SuccessKeySpecifier),
		fields?: SuccessFieldPolicy,
	}
};