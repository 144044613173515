import { KiteButton, KiteIcon } from '@kite/react-kite';
import styles from 'components/common/styles/BackButton.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSharedTranslation } from 'translation/hooks';

interface BackButtonInterface {
  isFolderDrawer?: boolean;
  label?: string;
  disableLabel?: boolean;
  path?: string;
  customStyle?: string;
  onClick?: () => void;
}

const BackButton: React.FC<BackButtonInterface> = ({
  isFolderDrawer,
  label,
  path,
  onClick,
  disableLabel,
  customStyle,
}) => {
  const navigate = useNavigate();
  const { t } = useSharedTranslation();

  const location = useLocation();
  const backUrl = location.pathname.split('/').slice(0, -1).join('/');

  const handleClick = (): void => {
    if (onClick) {
      onClick();
      return;
    }

    navigate(path || backUrl);
  };

  return (
    <KiteButton
      size="sm"
      variant="borderless"
      onClick={handleClick}
      className={`${'e2e_back_button'} ${customStyle || ''}`}
    >
      <div className={styles.backButton}>
        <KiteIcon icon={isFolderDrawer ? 'ki-folder' : 'ki-chevron-left'} />
        <p> {disableLabel ? '' : label || t('back')}</p>
      </div>
    </KiteButton>
  );
};

export default BackButton;
