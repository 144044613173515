import { getAppEnvironment } from 'app-constants/appConfig';
import { getAccessToken, getIdToken } from 'utils/accessTokenLocalStorage';

interface AuthHeaderInterface {
  [key: string]: string;
}

const buildAccessTokenHeader = (): AuthHeaderInterface => {
  if (getAppEnvironment() === 'dev') {
    return {
      'X-USER-JWT': getIdToken() ?? '',
    };
  }

  return {
    authorization: `bearer ${getAccessToken()}`,
  };
};

export default buildAccessTokenHeader;
