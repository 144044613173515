import { KiteButton } from '@kite/react-kite';
import NavigationLinkGroup from 'components/header/main-header/NavigationLinkGroup';
import { TrackingHook, useTracking } from 'react-tracking';
import mainDrawerContainerStyles from 'containers/styles/MainDrawerContainer.module.scss';
import logoutUtil from 'utils/logoutUtil';
import { usePageTranslation } from 'translation/hooks';

interface MainDrawerContainerProps {
  handleDrawerClose?: () => void;
}

const MainDrawerContainer: React.FC<MainDrawerContainerProps> = ({ handleDrawerClose }) => {
  const { trackEvent }: TrackingHook = useTracking({ page: 'navigation', component: 'MainDrawerContainer' });
  const { t } = usePageTranslation('topNavigationCopy');

  const handleClick = (): void => {
    trackEvent({ event: 'logoutHamburger' });
    logoutUtil();
  };

  const delayedClose = (): void => {
    handleDrawerClose && handleDrawerClose();
  };

  return (
    <>
      <div className={mainDrawerContainerStyles.content}>
        <NavigationLinkGroup delayedClose={delayedClose} />
        <div className={mainDrawerContainerStyles.signOut}>
          <KiteButton className={mainDrawerContainerStyles.signOutButton} onClick={handleClick}>
            {t('signOut')}
          </KiteButton>
        </div>
      </div>
    </>
  );
};

export default MainDrawerContainer;
