export const ValidationCopy = {
  alphaNumeric: '',
  invalidEmail: '',
  duplicateSender: '',
  blockSenderConflict: '',
  safeSenderConflict: '',
  invalidPhoneNumber: '',
  invalidURL: '',
  invalidDOB: '',
  invalidFirstName: '',
  contactFirstNameReq: '',
  invalidLastName: '',
  contactLastNameReq: '',
  invalidDisplayName: '',
  contactDisplayNameReq: '',
  invalidNickname: '',
  invalidStreetAddress: '',
  invalidCity: '',
  invalidState: '',
  invalidZip: '',
  invalidCountry: '',
  notesLimit: '',
  invalidTitle: '',
  invalidCompany: '',
  needField: '',
  composeFormDraftInvalid: '',
  composeFormSendInvalid: '',
  composeFormDuplicateEmails: '',
  composeFormToEmailsInvalid: '',
  composeFormCcEmailsInvalid: '',
  composeFormBccEmailsInvalid: '',
  fileSizeExceeded: '',
  fileTypeInvalid: '',
  composeFormMissingAddress: '',
  composeFormMissingBody: '',
  composeFormMissingAddressAndBody: '',
  invalidCharacters: '',
  labelDuplicate: '',
  labelCharacterLimit: '',
  folderDuplicate: '',
  invalidFilterRuleName: '',
  moveFolderReq: '',
  required: '',
  newFolderReq: '',
  folderCharacterLimit: '',
  replyToInvalidAddress: '',
  addressBookNoneSelected: '',
};
