import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MessageEntity } from 'generated/graphql';

interface MessageResponse {
  success?: MessageEntity;
  error?: ApolloError | undefined;
  mailboxId?: string;
}

interface MessageArg {
  payload: {
    mailboxId: string;
    messageUid: number;
    noImageHtml: boolean;
    html: boolean;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMessageLazyQueryHook: any;
}

export const getMessageThunk = createAsyncThunk('getMessage', async (arg: MessageArg): Promise<MessageResponse> => {
  try {
    const response = await arg.getMessageLazyQueryHook({
      variables: {
        mailboxId: arg.payload.mailboxId,
        messageUid: arg.payload.messageUid,
        noImageHtml: arg.payload.noImageHtml,
        html: arg.payload.html,
      },
    });
    return {
      success: response.data.getMessage,
      mailboxId: arg.payload.mailboxId,
    };
  } catch (e) {
    return {
      error: e as ApolloError,
    };
  }
});
