import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import { usePermanentDeleteMessageBulkMutation } from 'generated/graphql';
import { TrackingHook, useTracking } from 'react-tracking';
import { permanentDeleteMessageBulkThunk } from 'thunks/message-collection/permanentDeleteMessageBulkThunk';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, useDialogTranslation } from 'translation/hooks';
import useNavToPreviousPage from 'components/common/hooks/useNavToPreviousPage';
import useCloseMessage from 'components/common/hooks/useCloseMessage';

interface PermanentDeleteMessageBulkProps {
  messageUids: number[];
  mailboxId: string;
  currentMailId?: string;
}

const PermanentDeleteMessageBulk: React.FC<PermanentDeleteMessageBulkProps> = ({
  messageUids,
  mailboxId,
  currentMailId,
}) => {
  const { t } = useDialogTranslation('permanentDeleteMessageCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'messages' });
  const focusOpener = useTarget('modal');
  const apiError = useAppSelector((state) => state.messageCollectionSlice.apiError);
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');

  const dispatch = useAppDispatch();
  const closeMessage = useCloseMessage();
  const [permanentDeleteMessageBulkHook] = usePermanentDeleteMessageBulkMutation();

  const messageCollection = useAppSelector((state) => state.messageCollectionSlice.messageCollection);
  const [queryParams, currentPage, navToPreviousPage] = useNavToPreviousPage();

  const headerText = messageUids?.length > 1 ? t('header') : t('headerSingle');
  const successToastMessage = messageUids?.length > 1 ? t('successToast') : t('successToastSingle');

  useEffect(() => {
    trackEvent({ event: 'deleteMessagesModalView' });
  }, []);

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('unableToSaveErr');
    } else {
      setAlertDescription('');
    }
  }, [apiError]);

  const isSaved = async (): Promise<boolean> => {
    trackEvent({ event: 'permanentDeleteButton' });

    const result = await dispatch(
      permanentDeleteMessageBulkThunk({
        permanentDeleteMessageBulkHook,
        payload: { messageUids, mailboxId },
      })
    ).unwrap();

    if (result.success) {
      trackEvent({ event: 'permanentDeleteSuccess' });

      const { limit, total, data } = messageCollection[mailboxId];
      const lastPage = currentPage * limit > total;

      if (result.messageUids.length === data?.length && Number(currentPage) > 1 && lastPage) {
        navToPreviousPage();
      }

      if (messageUids.includes(parseInt(currentMailId || ''))) {
        closeMessage(mailboxId, queryParams);
      }

      dispatch(updateToastMessage({ message: successToastMessage, success: true }));
      dispatch(updateIsOpen(false));

      return true;
    }

    trackEvent({ event: 'permanentDeleteFailure' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'permanentDeleteCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t(alertDescription ? 'tryAgain' : 'delete'),
    isDelete: true,
  };

  return (
    <ModalContentWrapper>
      <h5>{headerText}</h5>
      <p>{t('descriptionInfo')}</p>
      <p className={`${modalStyle.alertMessage} ${modalStyle.redFont}`}>{t('warning')}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default PermanentDeleteMessageBulk;
