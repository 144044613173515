import modalStyle from 'components/common/root-modal/styles/RootModalChild.module.scss';
import { KiteAlert } from '@kite/react-kite';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsOpen, updateToastMessage } from 'slices/app';
import SaveCancel from 'components/save-cancel';
import React, { useEffect, useState } from 'react';
import { useDeleteMailboxMutation } from 'generated/graphql';
import { deleteMailboxThunk } from 'thunks/mailbox/deleteMailboxThunk';
import { TrackingHook, useTracking } from 'react-tracking';
import { clearMessageCollection } from 'slices/message-collection';
import ModalContentWrapper from 'components/common/root-modal/ModalContentWrapper';
import { useTarget } from 'components/common/focus/FocusContext';
import { ErrorsKeys, useDialogTranslation } from 'translation/hooks';
import { appConstants } from 'appConstants';

interface EmptyTrashPropsInterface {
  folderId: string;
  activeFolderId: string;
}

const EmptyTrash: React.FC<EmptyTrashPropsInterface> = ({ folderId, activeFolderId }) => {
  const { t } = useDialogTranslation('emptyTrashCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'folders' });
  const apiError = useAppSelector((state) => state.mailboxCollectionSlice.apiError);
  const spamFolderId = useAppSelector((state) => state.mailboxCollectionSlice.spamFolderId);
  const [alertDescription, setAlertDescription] = useState<ErrorsKeys | ''>('');
  const [deleteMailboxHook] = useDeleteMailboxMutation();
  const dispatch = useAppDispatch();
  const focusOpener = useTarget('modal');

  useEffect(() => {
    if (apiError?.message) {
      setAlertDescription('emptyTrashErr');
    }
  }, [apiError]);

  const isSaved = async (): Promise<boolean> => {
    trackEvent({ event: alertDescription ? 'emptyTrashTryAgain' : 'emptyTrashOk' });
    const result = await dispatch(
      deleteMailboxThunk({
        deleteMailboxHook,
        payload: { mailboxId: folderId ?? '', deleteMessage: true, spamFolderId },
      })
    );
    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'emptyTrashSuccess' });
      dispatch(updateToastMessage({ message: t('successToast'), success: true }));
      if (folderId === activeFolderId) {
        dispatch(clearMessageCollection(appConstants.TRASH_FOLDER_ID));
      }
      return true;
    }
    // @ts-ignore
    trackEvent({ event: 'emptyTrashErrorApi' });
    return false;
  };

  const isCancelled = (): boolean => {
    trackEvent({ event: 'emptyTrashCancel' });
    dispatch(updateIsOpen(false));
    focusOpener();
    return true;
  };

  const isValidate = (): boolean => {
    return true;
  };

  const isChanged = (): boolean => {
    return true;
  };

  const saveCancelProps = {
    isSaved,
    isCancelled,
    isValidate,
    draft: '',
    isChanged,
    saveButtonText: t(alertDescription ? 'tryAgain' : 'confirmBtnText'),
    isDelete: false,
  };

  return (
    <ModalContentWrapper>
      <h5>{t('header')}</h5>
      <p>{t('description')}</p>
      {apiError ? (
        <KiteAlert className={modalStyle.alert} type="error" description={t(alertDescription)} level="page" />
      ) : null}
      <SaveCancel {...saveCancelProps} />
    </ModalContentWrapper>
  );
};

export default EmptyTrash;
