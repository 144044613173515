/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from 'components/compose-input-field/styles/ComposeInputField.module.scss';
import { useAppSelector, useAppDispatch } from 'hooks';
import { updateSubject } from 'slices/compose';
import { AutoSaveDraftHandler } from 'components/compose-form';
import { KiteIcon } from '@kite/react-kite';
import { PriorityEnum } from 'slices/compose/index';
import { usePageTranslation } from 'translation/hooks';

const SubjectField: React.FC<AutoSaveDraftHandler> = ({ autoSaveDraftHandler }) => {
  const { t } = usePageTranslation('composeCopy');
  const composeCollection = useAppSelector((state) => state.composeSlice.composeCollection);
  const activeTabIndex = useAppSelector((state) => state.composeSlice.activeTabIndex);
  const priority = useAppSelector((state) => state.composeSlice.composeCollection[activeTabIndex]?.priority);
  const initialSubject = useAppSelector((state) => state.composeSlice.composeCollection[activeTabIndex]?.subject);
  const dispatch = useAppDispatch();

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const subject = e.target.value;
    dispatch(updateSubject(subject));

    if (subject !== initialSubject && !composeCollection[activeTabIndex].isSavePending) {
      autoSaveDraftHandler('subject', subject, composeCollection[activeTabIndex]);
    }
  };

  return (
    <span className={styles.inputFieldContainer}>
      <span className={styles.label}>
        <label className={styles.label} htmlFor="composeSubject">
          {t('subjectField')}
        </label>
      </span>
      <span className={`${styles.inputField} ${styles.subject}`}>
        <input
          id="composeSubject"
          className={styles.subject}
          type="text"
          value={initialSubject || ''}
          onChange={handleSubjectChange}
        />
      </span>
      {priority === PriorityEnum.high && (
        <KiteIcon
          ariaLabel={t('priorityAria')}
          className={styles.priority}
          fill="#0073D1"
          icon={'ki-exclamation-mark'}
        />
      )}
    </span>
  );
};

export default SubjectField;
