import React from 'react';
import ContactSection from 'components/contact-form/sections/ContactSection';
import { KiteIcon, KiteTextInput } from '@kite/react-kite';
import { ContactSectionProps } from 'components/contact-form/initialState';
import { ContactFormInterface } from 'components/contact-form/initialState';
import styles from 'components/contact-form/styles/ContactCreateContact.module.scss';
import validateContactField, { getContactValidationErrorMessage } from 'utils/validateContactField';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { KiteInputElems } from 'libs/kite/types';
import { dateOfBirthTrans } from 'components/contact-form/sections/translationKeys';
import { usePageTranslation } from 'translation/hooks';

export const DOB: React.FC<ContactSectionProps<Pick<ContactFormInterface, 'dateOfBirth'>>> = ({
  updateFormValues,
  values,
  errors,
  setErrors,
}) => {
  const { t } = usePageTranslation('contactFormCopy');

  const dobErrorMessage = getContactValidationErrorMessage(errors, 'dateOfBirth', dateOfBirthTrans);
  const validate = (value: string): boolean =>
    validateContactField('dateOfBirth', { dateOfBirth: value }, errors, setErrors);

  const handleOnChange = ({ formattedValue }: NumberFormatValues): void => {
    updateFormValues(formattedValue, 'dateOfBirth');
    if (dobErrorMessage) {
      validate(formattedValue);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<KiteInputElems>): void => {
    const isValid = validate(e.currentTarget.value);
    if (!isValid && !dobErrorMessage) {
      e.currentTarget.focus();
    }
  };
  return (
    <ContactSection>
      <KiteIcon className={styles.fieldIcon} icon="ki-date-of-birth" />
      <div className={styles.fieldContainer}>
        <NumberFormat
          format="##/##/####"
          className={styles.fullField}
          placeholder={t('dateOfBirthPlaceholder')}
          label={t('dateOfBirth')}
          onValueChange={(v) => handleOnChange(v)}
          onBlur={(e: React.FocusEvent<KiteInputElems>) => handleOnBlur(e)}
          value={values.dateOfBirth || ''}
          errorMessage={t(dobErrorMessage)}
          customInput={KiteTextInput}
          aria-label={'enter date of birth'}
          ariaLive="polite"
        />
      </div>
    </ContactSection>
  );
};
